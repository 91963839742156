import {forwardRef, Fragment, useState, useEffect, useContext } from "react";
import MainContext from "../../MainContext";

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import theme from "../../Theme";
import { ButtonBase, Typography, Box} from "@mui/material";

export default function SearchResults({searchResults,sx}) {

    //Effects
    useEffect(() => {
        //on mount
        console.log('Search results mounted'); 

        //on unmount
        return ()=>{

        }

    }, []);

    //RENDER
    return (
        <Box sx={{ ...sx}}>       
            <Typography sx={{mb:'16px'}} variant='h3'>Search results</Typography>
            {searchResults.length > 0 ?
            searchResults.map((result)=>(
                <Box key={result.document.id} sx={{display:'flex',flexDirection:'row',gap:'8px'}}>
                    <Typography variant='h6'>
                        {result.document.GrantName}
                    </Typography>
                    <Typography variant='body2'>
                        {result.document.AwardOrg}
                    </Typography>
                    <ButtonBase onClick={()=>window.open(result.document?.GrantURL,'_blank')}>
                        <OpenInNewIcon  sx={{height:'14px',width:'14px',color:'gray'}} />
                    </ButtonBase>
                </Box>
            )): (
                <Box>
                    <Typography>
                        No Results
                    </Typography>
                </Box>
            )}
        </Box>
    );
}