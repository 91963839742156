import React, {useState, useEffect, useContext } from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingBackdrop = ({open}) => {
    return(
        <Backdrop
            sx={{ bgcolor: 'rgba(255,255,255,0.5)', zIndex: 10}}
            open = {open}
        >
            <CircularProgress />
        </Backdrop>
    )
}

export default LoadingBackdrop;