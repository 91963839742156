import React, {useState, useEffect, useContext } from "react";

import MainContext from "../../MainContext"; 

import { Box } from "@mui/material";

import SelectFormInput from "../../components/formInputs/SelectFormInput";

import { allUSSchoolIncomeDemos, allUSSchoolTitle1Programs, allUSSchoolLocales} from "../../Util";
import FormTitle from "../../components/formInputs/FormTitle";

const SchoolDemographicInput = ({formData,setDisableContinueButton})=>{
    
    const mainService = useContext(MainContext);

    let userObject = formData.userObject;

    useEffect(()=>{
        let disableButton = !(userObject && userObject.schoolincomedemo && userObject.schoollocale 
            && userObject.schooltitle1); 
        if(setDisableContinueButton)
            setDisableContinueButton(disableButton); 
    }, [formData]);

    return(
    <React.Fragment>

        <Box sx={{flexGrow:1, maxWidth:'510px', height:'100%', display:'flex', alignItems:'stretch',  flexDirection:'column',}}>
            <Box sx={{height:'10%'}}></Box>
            
            <FormTitle text="Tell us a little about your school's demographic."/>
            <Box sx={{display:'flex', alignItems:'stretch', flexDirection:'column' }}>

                <SelectFormInput 
                    title={'Does your school make use of title 1 programs?'}
                    value={userObject.schooltitle1}
                    onChange={(v)=>mainService.profileService.setSchoolTitle1(v)}
                    options={allUSSchoolTitle1Programs}
                />

                <SelectFormInput 
                    title={'What is the income demographic of the local area?'}
                    value={userObject.schoolincomedemo}
                    onChange={(v)=>mainService.profileService.setSchoolIncomeDemo(v)}
                    options={allUSSchoolIncomeDemos}
                />

                <SelectFormInput 
                    title={'What type of area is the school in?'}
                    value={userObject.schoollocale}
                    onChange={(v)=>mainService.profileService.setSchoolLocale(v)}
                    options={allUSSchoolLocales}
                />

                {/*
                <Box sx={{mx:'16px', my:'16px', display:'flex', flexDirection:{xs:'column', sm:'row'}, alignItems:'center' }}>
                    <Typography sx={{width:'150px'}}>Is your school considered to be within a low income area</Typography>
                    <Checkbox value={userObject.schoolisreligious} onChange={(e)=>handleSchoolIsReligiousChange(e.target.value)}/>
                </Box>    
                */}
            </Box>

        </Box>
    </React.Fragment>
    )
}

export default SchoolDemographicInput;