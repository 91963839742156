import React, {useState, useEffect, useContext } from "react";
import { useNavigate, createSearchParams, useSearchParams } from "react-router-dom";

import Theme from '../Theme';

import MainService from "../MainService"; 
import MainContext from "../MainContext"; 

import { ThemeProvider } from  '@mui/system';
import { Box, Typography } from "@mui/material";

import UserTypeInput from "./gettingStarted/UserTypeInput";
import NameInput from "./gettingStarted/NameInput";
import SchoolInput from "./gettingStarted/SchoolInput"; 
import SchoolMoreInput from "./gettingStarted/SchoolMoreInput"; 
import SchoolDemographicInput from "./gettingStarted/SchoolDemographicInput"; 
import SchoolRoleInput from "./gettingStarted/SchoolRoleInput"; 
import PageForm from "../components/PageForm";
import OrgNameInput from "./gettingStarted/OrgNameInput"; 
import ProfileInput from "./gettingStarted/ProfileInput";
import SchoolWebInput from "./gettingStarted/SchoolWebInput";
import RoleInput from "./gettingStarted/RoleInput";
import Paper from "../components/Paper";


const Profile = ({userObject}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    
    useEffect(()=> {
        if(searchParams.has("module")){
            searchParams.delete('module');
            setSearchParams(searchParams);
        }
    },[userObject])

    const formData = {
        
        startNode: process.env.NODE_ENV == 'development' ? 'userTypeNode' : 
                   userObject.type!='thirdparty' ? 'nameNode':'orgNameNode',

        userObject: userObject,

        nodes:{
            userTypeNode:{
                component:UserTypeInput,
                next:()=>userObject.type!='thirdparty' ? 'nameNode': 'roleNode',
            },

            nameNode:{
                component:NameInput,
                next:()=>'schoolNode',
            },
            schoolNode:{
                component:SchoolInput,
                next:()=>'schoolMoreNode',
            },
            schoolMoreNode:{
                component:SchoolMoreInput, 
                next:()=>'schoolDemographicNode'
            },
            schoolDemographicNode:{
                component:SchoolDemographicInput,
                next:()=>'schoolRoleInput',
            },
            schoolRoleInput:{
                component:SchoolRoleInput,
                next:()=>'schoolWebInput',
            },
            schoolWebInput:{
                component:SchoolWebInput,
                next:()=>'profileNode',
            },
            roleNode:{
                component:RoleInput,
                next:()=>'orgNameNode',
            },
            orgNameNode:{
                component:OrgNameInput,
                next:()=>'profileNode',
            },
            profileNode:{
                component:ProfileInput,
                end:true,
            },

        }
    }
    

    return (
    <ThemeProvider theme={Theme}>
        <Box sx={{position:'absolute', width:'100%', height:'100%', overflow:'auto', display:'flex', justifyContent:'center',}}>
        <Box sx={{display:'flex', flexGrow:1, flexDirection:'row', justifyContent:'center',}}>
        <Box sx={{display:'flex', flexGrow:1, maxWidth:'1200px', flexDirection:'column',}}>
            <Paper sx={{display:'flex', py:'32px', m:'32px', flexDirection:'column' }}>
                <PageForm formData={formData}/>
            </Paper>
        </Box>
        </Box>
        </Box>
    </ThemeProvider>
    )
}

export default Profile; 