import {useState, useEffect, useContext,forwardRef } from "react";

import MainContext from '../../MainContext';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Skeleton from "@mui/material/Skeleton";
import Link from "@mui/material/Link";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import theme from "../../Theme"
import { CircularProgress, Typography, Button, Tab, Tabs, Box, Table, TextField, Select, MenuItem} from "@mui/material";

import CustomPagination from "../components/CustomPagination";
import EnhancedTableHead from "../components/EnhancedTableHead";
import ProductListingEditor from "../../modules/productListings/ProductListingEditor";
import Paper from "../../components/Paper";

import { toDateString } from '../../Util';
import ResponsiveDialog from "../../components/ResponsiveDialog";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MetadataEditor = ({pid,data}) =>{
  const mainService = useContext(MainContext);
  return (
    <Box sx={{display:'flex',flexDirection:'column',p:'16px'}}>
      <Typography gutterBottom variant="h5">Title</Typography>
      <Typography sx={{mb:'16px'}}>{data?.title ?? ''}</Typography>
      <Typography gutterBottom variant="h5">Created date</Typography>
      <Typography sx={{mb:'16px'}}>{data?.createdDate ? toDateString(data.createdDate) :  '' }</Typography>
      <Typography gutterBottom variant="h5">Creation stage</Typography>
      <Select
        id="select-creationstage"
        value={data?.creationStage ?? ''}
        variant="standard"
        onChange={(e)=>mainService.intellectibaseService.setCreationStage(pid,e.target.value)}
        sx={{mb:'16px'}}
      >
        <MenuItem value={'flow'}>Flow</MenuItem>
        <MenuItem value={'editor'}>Editor</MenuItem>
      </Select>
      <Typography gutterBottom variant="h5">Billing Id</Typography>
      <TextField onChange={(e)=>mainService.intellectibaseService.setBillingId(pid,e.target.value)} sx={{mb:'16px'}} variant='standard' value={data?.billingId ?? ''}/>

      <Typography gutterBottom variant="h5">Owner Id</Typography>
    </Box>
  )
}

function ProductListing({pid, open, onClose, ownerEmail}){

    const [data, setData] = useState(null);
    const [draftData, setDraftData] = useState(null);
    const [alertOpen,setAlertOpen] = useState(false);
    const [alertMsg,setAlertMsg] = useState("");
    const [alertSeverity,setAlertSeverity] = useState("success");
    const [productCollectionData,setProductCollectionData] = useState(null);
    const [productCollectionId,setProductCollectionId] = useState(null);
    const [tabValue,setTabValue] = useState(0);

    const mainService = useContext(MainContext);

    const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
  };

    const handleSetAlert = (msg,severity="success") => {
      if(msg){
          setAlertMsg(msg);
          setAlertSeverity(severity);
          setAlertOpen(true);
      }
    }

    const handleClose = () => {
      setProductCollectionData(null);
      setProductCollectionId(null);
      /*if(pid){
        mainService.removeDataListener('productListingMetadata/'+pid, listenProductMetadata);
        mainService.removeDataListener('productListingDrafts/'+pid, listenProductDraft);
      }
      if(ownerEmail){
        mainService.productListingService.removeUserProductCollectionRelationshipListener(listenUserProductCollectionRelationships,ownerEmail);
      }
      if(productCollectionId){
        mainService.productListingService.removeProductCollectionListener(productCollectionId,listenProductCollection);
      }*/
      onClose()
    }

    const listenProductMetadata = (d)=>{
        setData(d);
    }

    const listenProductDraft = (d)=>{
        setDraftData(d);
    }

    useEffect(() => {
        //on mount
        if(pid){
            console.log('setting up product data listeners'); 
            mainService.addDataListener('productListingMetadata/'+pid, listenProductMetadata); 
            mainService.addDataListener('productListingDrafts/'+pid, listenProductDraft); 
        }

        //on unmount
        return ()=>{
            if(pid){
                console.log('Removing product data listeners');
                mainService.removeDataListener('productListingMetadata/'+pid, listenProductMetadata);
                mainService.removeDataListener('productListingDrafts/'+pid, listenProductDraft);
            }
        }
    }, [pid]);

    const listenUserProductCollectionRelationships = async (data) => {
      console.log('user product data', JSON.stringify(data))
      if(data){
          let collid = Object.keys(data)[0];
          console.log('collid',collid);
          setProductCollectionId(collid)
      }
    }

    useEffect(()=>{
      if(ownerEmail){
        //get collection id
        console.log('setting up user product collection listener')
        mainService.productListingService.addUserProductCollectionRelationshipListener(listenUserProductCollectionRelationships,ownerEmail);
    }

    return(()=>{
        if(ownerEmail){
            console.log('removing user product collection listener')
            mainService.productListingService.removeUserProductCollectionRelationshipListener(listenUserProductCollectionRelationships,ownerEmail);
        }
    })
    },[ownerEmail])

    const listenProductCollection = (data) => {
      console.log('collectionData',JSON.stringify(data))
      setProductCollectionData(data)
  }

   useEffect(()=>{
      if(productCollectionId){
          console.log('adding product collection listener');
          mainService.productListingService.addProductCollectionListener(productCollectionId,listenProductCollection);
      }
      return (()=>{
          if(productCollectionId){
              console.log('removing product collection listener');
              mainService.productListingService.removeProductCollectionListener(productCollectionId,listenProductCollection);
          }
      })

  },[productCollectionId])

    return (
      <>
    <ResponsiveDialog
      open={open}
      onClose={handleClose}
      maxWidth={'xl'}
    >
      <Box sx={{display:'flex',p:'4px',alignItems:'center'}}>
        <Box>
          <Typography variant='h3'>Status: {data?.status}</Typography>
          <Typography>The component below is the same one as the user gets. Once the listing is in review use publish button that appears on the top right to make it public</Typography>
         {productCollectionId && <><Typography component={'span'}>Products page link: </Typography><Link target='_blank' href={`https://app.intellectible.com/products?collection=${productCollectionId}`}>https://app.intellectible.com/products?collection={productCollectionId}</Link></>}
        </Box>
        <Box sx={{flexGrow:1}}/>
        {data?.status == 'review' && <Button onClick={()=>mainService.intellectibaseService.publishListing(pid)} variant='contained'>Publish</Button>}
      </Box>
      <Tabs centered value={tabValue} onChange={handleTabChange} aria-label="data tabs">
          <Tab label="Listing data" />
          <Tab label="Metadata" />
        </Tabs>
      <Paper sx={{minWidth:'1200px',minHeight:'900px',bgcolor: data?.status === 'published' ? '#edfcf4' : data?.status === 'review' ? '#ffeee6' : '#f5f0fb'}}>
        {draftData && tabValue === 0 &&
          <ProductListingEditor 
              listingId={pid} 
              listingData={draftData}
              onAlert={handleSetAlert} 
              onLoading={()=>{}} 
              collectionId={productCollectionId}
              collectionData={productCollectionData}
          />
        }
        {data && tabValue === 1 &&
          <MetadataEditor pid={pid} data={data}/>
        }
      </Paper>
    </ResponsiveDialog>
    <Snackbar open={alertOpen} autoHideDuration={3000} onClose={()=>{setAlertOpen(false)}}>       
        <Alert onClose={()=>{setAlertOpen(false)}} severity={alertSeverity} sx={{ width: '100%' }}>        
            {alertMsg}           
        </Alert>       
    </Snackbar>
    </>
    )
}


const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Id',
  },
  {
    id: 'Name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'Owner',
    numeric: false,
    disablePadding: false,
    label: 'Owner',
  },
  {
    id: 'Status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'Date',
    numeric: false,
    disablePadding: false,
    label: 'Created Date',
  },

];

export default function ProductListingsTable() {
    
    //Use
    const mainService = useContext(MainContext);


    const [loading,setLoading] = useState(false);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('Date');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows,setRows] = useState([]);
    const [pid, setPid] = useState('');
    const [ownerEmail,setOwnerEmail] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false); 

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
        const newSelected = rows.map((n) => n.id);
        setSelected(newSelected);
        return;
        }
        setSelected([]);
    };

    const handleProductListingClose = () => {
      setPid(null);
      setOwnerEmail(null);
      setDialogOpen(false)
    }

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
        );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event,direction) => {
        let newPage = 0;
        if(direction==="forward"){
          newPage = page
          newPage++;
        }
          
        else if(direction==="backward" && page > 0){
          newPage = page
          newPage--;
        }

        setLoading(true);
        try{
          mainService.intellectibaseService.unsubscribeProductListings();
          mainService.intellectibaseService.listenProductListings(orderBy,rowsPerPage,newPage,direction,listenDocData);
        }
        catch(error){
          console.error(error)
          setLoading(false);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        const value = parseInt(event.target.value, 10);
        setLoading(true);
        try{
          mainService.intellectibaseService.unsubscribeProductListings();
          mainService.intellectibaseService.listenProductListings(orderBy,value,0,"forward",listenDocData);
          setRowsPerPage(value);
        }
        catch(error){
          console.error(error)
          setLoading(false);
        }       
        
    };

    const handleChangeOrderby = (event,value) => {
      setLoading(true);
      try{
        mainService.intellectibaseService.unsubscribeProductListings();
        mainService.intellectibaseService.listenProductListings(value,rowsPerPage,0,"forward",listenDocData);
        setOrderBy(value);
      }
      catch(error){
        console.error(error)
        setLoading(false);
      }       
      
    };

  //Listeners
  const listenDocData = async (data,p) => {
    if(data){
      let newData = []
      let promList = [];
      for(let doc of data){
        let newDoc = {...doc};
        let prom = mainService.intellectibaseService.getProductOwner(doc.id).then((em)=> {newDoc['ownerEmail'] = em; newData.push(newDoc);})
        promList.push(prom);
      }
      await Promise.all(promList);

      setRows(newData);
      setPage(p);
      setLoading(false);
    }
  }

  //Effects
  useEffect(() => {
    //on mount
    setLoading(true);
    mainService.intellectibaseService.listenProductListings(orderBy,rowsPerPage,0,"forward",listenDocData)

    //on unmount
    return ()=>{
      mainService.intellectibaseService.clearLastVisibleProducts();
      mainService.intellectibaseService.unsubscribeProductListings();
    }

  }, []);

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - rows.length;

    return (

        <Box sx={{ width: '100%'}}>       
         {loading && !rows ? 
         (<Paper sx={{ width: '100%', mb: 2}}>
            <Box sx={{p:theme.spacing(2),left:"50%",top:0,width:"100%",display:"flex",justifyContent:"center"}}>
              <CircularProgress/>
            </Box>
          </Paper>):

          (<Paper sx={{ width: '100%', mb: 2,overflow: 'hidden'}}>

            
            <TableContainer sx={{maxHeight:"650px"}}>
            <Table
                stickyHeader 
                sx={{ minWidth: 750}}
                aria-labelledby="tableTitle"
                size='small'
            >
              <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleChangeOrderby}
                  rowCount={rows.length}   
                  headCells={headCells} 
              />
                
                <TableBody >                                              
                {
                    rows
                    .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                        <TableRow
                        hover
                        
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        >
                        <TableCell padding="checkbox">
                            <Checkbox
                            onClick={(event) => handleClick(event, row.id)}
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                                'aria-labelledby': labelId,
                            }}
                            />
                        </TableCell>
                        <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                        >
                            {loading ? <Skeleton width={210} /> : row.id}
                        </TableCell>
                        <TableCell align="left">
                        {loading ? <Skeleton width={210} /> : (<Link color="#324ab2" href="#" onClick={()=>{
                            setPid(row.id);
                            setOwnerEmail(row.ownerEmail.trim().toLowerCase().replace(new RegExp("\\.","g"),"%2E"));
                            setDialogOpen(true);
                        }}>{row.title}</Link>)}
                        </TableCell>
                        <TableCell align="left">{loading ? <Skeleton width={210}/> : row.ownerEmail}</TableCell>
                        <TableCell align="left">{loading ? <Skeleton width={210}/> : row.status}</TableCell>
                        <TableCell  align="left">{loading ? <Skeleton width={210}/> : toDateString(row.createdDate)}</TableCell>
                        </TableRow>
                    );
                    })                   
                    }
                  {emptyRows > 0 && (
                      <TableRow
                      style={{
                          height: (33) * emptyRows,
                      }}
                      >
                      <TableCell colSpan={6} />
                      </TableRow>
                  )}
                </TableBody>
            </Table>
            </TableContainer>
            <CustomPagination
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsLength={rows.length}
            />
        </Paper>)}
        <ProductListing key={pid} pid={pid} open={dialogOpen} onClose={handleProductListingClose} ownerEmail={ownerEmail}/>
        </Box>

    );
}