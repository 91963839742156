import React, {useState, useEffect, useContext } from "react";

import { Box, Typography, MenuItem, Select, FormControl, ButtonBase, CircularProgress,  } from "@mui/material";

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import StyledTextField from '../StyledTextField'; 
import BorderPulsate from "../BorderPulsate";


const TextFormInput = ({value, title, onChange, smallPara, largePara, startAnimation, sx, readOnly, multiline, maxRows,minRows,rows, descriptionText,startAdornment, error, helperText})=>{
        
    const handleChange = (val)=>{
        onChange(val); 
    }
    //inputProps={ { maxLength: maxLength }}
    return(
        <>
        <Typography align='left' sx={{mt:'16px', mb:descriptionText?'0px':'16px', ml:'16px'}}>{title}</Typography>
        {descriptionText && <Typography variant='body2' sx={{mb:'16px', ml:'16px'}}>{descriptionText}</Typography>} 
        <BorderPulsate startAnimation={startAnimation} sx={{alignSelf:'stretch',mx:'16px',  display:'flex', flexDirection:'column'}}>
            <StyledTextField 
                InputProps={{
                    style:{marginLeft:'0px'},
                    startAdornment:startAdornment
                }}
                disabled={readOnly}
                value={value ? value : ''}
                onChange={(e)=>handleChange(e.target.value)}
                variant="outlined"
                sx={{ ml:0,flexGrow:1, ...sx }}
                multiline={(smallPara || largePara) ? true : multiline}
                rows={smallPara ? 5 : largePara ? 9 : rows}
                maxRows={maxRows}
                minRows={minRows}
                error = {error}
                helperText = {helperText}
            />
        </BorderPulsate>
        </>
    )
}

export default TextFormInput;