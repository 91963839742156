import React, {useState, useEffect, useContext } from "react";

import Theme from '../Theme';

import MainContext from "../MainContext"; 
import { useNavigate, createSearchParams, useSearchParams } from "react-router-dom";

import { ThemeProvider } from  '@mui/system';
import { Box, Typography } from "@mui/material";

import useQuery from "../useQuery";

import FormFlow from '../components/FlowForm'; 

import UserTypeInput from "./gettingStarted/UserTypeInput";
import NameInput from "./gettingStarted/NameInput";
import SchoolInput from "./gettingStarted/SchoolInput"; 
import SchoolMoreInput from "./gettingStarted/SchoolMoreInput"; 
import SchoolDemographicInput from "./gettingStarted/SchoolDemographicInput"; 
import SchoolRoleInput from "./gettingStarted/SchoolRoleInput"; 
import OrgNameInput from "./gettingStarted/OrgNameInput";
import SchoolWebInput from "./gettingStarted/SchoolWebInput";
import ProfileInput from "./gettingStarted/ProfileInput";
import Paper from "../components/Paper";
import FormTitle from "../components/formInputs/FormTitle";
import RoleInput from "./gettingStarted/RoleInput";




const Start = ({formData,setDisableContinueButton})=>{

    const mainService = useContext(MainContext);

    useEffect(()=>{
        if (setDisableContinueButton)
            setDisableContinueButton(true);
        if(formData.userObject){
            if(formData.userObject.type){
                if(setDisableContinueButton){
                    setDisableContinueButton(false)
                }
            }
        }

    },[formData])


    return(
    <React.Fragment>
        <Box sx={{ height:'100%', flexGrow:1, display:'flex', alignItems:'stretch', flexDirection:'column',maxWidth:'600px'}}>

            <Box sx={{height:'10%'}}></Box>
            <Typography gutterBottom align='center' variant="h2">Welcome to Intellectible!</Typography>
            <Box sx={{height:'10%'}}></Box>
            <Box sx={{my:'16px', display:'flex', flexDirection:'column', alignItems:'center'}}>
                <Typography gutterBottom align='center' variant="h3">Before you get started we need to grab a few details from you in order to set up your account</Typography>    
                <Typography align='center' variant="body1">This will only take a few minutes.</Typography>                 
            </Box>

        </Box>
    </React.Fragment>
    )
}

const Finish = ({formData})=>{
    
    let query = useQuery();
    const mainService = useContext(MainContext);
    const renderFinishedText = () => {
        if(query.get("module")==="applications"){
            return `to finish your application!`;
        }

        else {
            return `to go your shiny new dashboard.`
        }
    }
    return(
    <React.Fragment>
        <Box sx={{ height:'100%', flexGrow:1, display:'flex', alignItems:'center', flexDirection:'column',}}>
            <Box sx={{height:'10%'}}></Box>
            <FormTitle text='All done!'/>
            <Typography align='center' variant="body">{`You have provided everything we need to get started, click continue ${renderFinishedText()}`}</Typography>                    
        </Box>
    </React.Fragment>
    )
}

const GetStarted = ({userObject}) => {
    
    const mainService = useContext(MainContext);

    //
    const handleFinish = ()=>{
        mainService.profileService.setUserAccountStarted(true); 
    }

    //RENDER 
    const formData = {
        
        startNode: 'startNode',

        userObject: userObject,

        nodes:{
            startNode:{
                component:Start,
                next:()=> 'nameNode'/*!userObject?.type ? 'userTypeNode' : 'nameNode'*/,
            },
            /*
            userTypeNode:{
                component:UserTypeInput,
                next:()=>'nameNode',
            },*/
            nameNode:{
                component:NameInput,
                next:()=>userObject.type != 'thirdparty' ? 'schoolNode': 'roleNode',
            },
            schoolNode:{
                component:SchoolInput,
                next:()=>'schoolMoreNode',
            },
            schoolMoreNode:{
                component:SchoolMoreInput, 
                next:()=>'schoolDemographicNode'
            },
            schoolDemographicNode:{
                component:SchoolDemographicInput,
                next:()=>'schoolRoleInput',
            },
            schoolRoleInput:{
                component:SchoolRoleInput,
                next:()=>'schoolWebInput',
            },
            schoolWebInput:{
                component:SchoolWebInput,
                next:()=>'finishNode',
            },
            roleNode:{
                component:RoleInput,
                next:()=>'orgNameNode',
            },
            orgNameNode:{
                component:OrgNameInput,
                next:()=>'profileNode',
            },
            profileNode:{
                component:ProfileInput,
                next:()=>'finishNode',
            },
            finishNode:{
                component:Finish,
                end:true,
            },
        }
    }

    return (
    <ThemeProvider theme={Theme}>
        <Box sx={{overflowX:"hidden",position:'relative', width:'100%', height:'100%', display:'flex', justifyContent:'center',}}>
        <Paper sx={{flexGrow:1, overflowX:"hidden",position:'relative', display:'flex', flexDirection:'column', m:'32px', maxWidth:'1200px'}}>
            <FormFlow onFinish={handleFinish} formData={formData}/>

        </Paper>
        </Box>
    </ThemeProvider>
    )
}

export default GetStarted; 