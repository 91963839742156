import {
    getAuth, } from 'firebase/auth';

import { 
    getDatabase, 
    ref, 
    set, 
    get, 
    push, 
    update, 
    onValue, 
    off } from 'firebase/database';

import { API_URL } from './Config';

import { sha256, sha224 } from 'js-sha256'; 

/*
DB objects:

chatConvos
    $id
        latestMessage
        createdDate
chatConvoMessages
    $id
        $mid
            value
            sender
            timestamp
chatConvoMembers
    $id
        $email:'admin'|'member'
userChatConvoRelationships
    $email
        $id:true

        
*/


export default class MessageService {


    constructor(mainService){
        this.mainService = mainService; 
        this.convos = new Map(); 
        this.userChatConvoRelationshipsListner = null; 
    }



    async sendMessage(convoId, val, convoObject){

        const db = getDatabase();

        let uid = this.mainService.getUid();

        let message = {
            value: val,
            timestamp: Date.now(), 
            sender: uid,
        };

        let R = push( ref( getDatabase(), 'chatConvoMessages/' + convoId ) );  

        let updates = {}; 
        updates['chatConvoMessages/' + convoId + '/' + R.key] = message; 
        updates["chatConvos/" + convoId + '/latestMessage'] = message;
        updates["chatConvoNewMessage/" + uid + '/' + convoId] = null; 
        if(convoObject && convoObject.members ){
            for(let m of convoObject.members){
                if(m && m.uid && m.uid != uid)
                    updates["chatConvoNewMessage/" + m.uid + '/' + convoId] = true; 
            }
        }
        await update( ref(db), updates); 
    }

    async setReadLatest(convoId){
        const db = getDatabase();
        let uid = this.mainService.getUid();
        let updates = {}; 
        updates["chatConvoNewMessage/" + uid + '/' + convoId] = null; 
        await update( ref(db), updates); 
    }

    async createConv(userObject, newConvoMembersValue, existingConvos){
        
        //console.log(existingConvos)
        //console.log(newConvoMembersValue)
        //console.log(JSON.stringify(newConvoMembersValue))

        //create a hash for the members of the convo. This will be the convo id
        let mIds = [];
        for(let m of newConvoMembersValue){
            mIds.push(m.uid); 
        }
        mIds = mIds.sort(); 
        let newKey = sha256(mIds.join(''));


        const db = getDatabase();
        let uid = this.mainService.getUid();

        let chatConvo = {
            createdDate: Date.now(), 
            latestMessage:'',
        }; 


        let chatConvoMembers = {}; 
        chatConvoMembers[uid] = {
            role:'owner', 
            name:'' + userObject.firstname + ' ' + userObject.lastname, 
        }; 

        for(let member of newConvoMembersValue){
            chatConvoMembers[member.uid] = {
                role:'member',
                name:member.name,
                photoURL:member?.photoURL ?? null, 
            };
        }

        await set( ref(db, "chatConvoMembers/" + newKey), chatConvoMembers) //must write to members first for permissions to write to other stuff 
        
        let updates = {}
        updates["chatConvos/"+newKey] = chatConvo; 
        updates["userChatConvoRelationships/" + uid + "/" + newKey] = true; 
        for(let member of newConvoMembersValue){
            updates["userChatConvoRelationships/" + member.uid + "/" + newKey] = true; 
        }
        await update( ref(db), updates);
        
        let convoObj = {
            id:newKey,
            members:newConvoMembersValue,
        }

        return convoObj; 
    }

    async deleteConv(convoId){
        const db = getDatabase();
        let uid = this.mainService.getUid();
        await set( ref(db, "chatConvos/" + convoId), null);
        await set( ref(db, "chatConvoMessages/" + convoId), null);
        let usrRelSnap = await get( ref(db, "chatConvoMembers/" + convoId)); 
        if(usrRelSnap.exists()){
            let usrs = usrRelSnap.val(); 
            for(let usr in usrs){
                await set( ref(db, "userChatConvoRelationships/" + usr + "/" + convoId), null);
                await set(ref(db, 'chatConvoNewMessage/' + usr + '/' + convoId), null); 
            }
        }
        
        await set( ref(db, "chatConvoMembers/" + convoId), null) //must write to members last  for permissions to write to other stuff 
       
    }

    async leaveConv(convoId){
        const db = getDatabase();
        let uid = this.mainService.getUid();
        
        await set( ref(db, "userChatConvoRelationships/" + uid + "/" + convoId), null); 
        
        await set( ref(db, "chatConvoMembers/" + convoId + "/" + uid), null) //must write to members last for permissions to write to other stuff
    }
    

    addConvosListner(listner){
        let uid = this.mainService.getUid();

        this.userChatConvoRelationshipsListner = (data)=>{

            //Remove all listners for individual convos
            for(let [cid, convo] of this.convos){
                this.mainService.removeDataListener('chatConvos/' + cid, convo.listner); 
                this.mainService.removeDataListener('chatConvoMembers/' + cid, convo.membersListner); 
            }
            this.convos = new Map(); 

            //add a listner for each convo
            let convoCount = 0; 
            for(let cid in data ){
                
                let convo = {
                    data:null, 
                    id:cid,
                    
                    listner:(convoData)=>{
                        convo.latestMessage = convoData?.latestMessage ;
                        listner(this.convos); 
                    }, 
                    membersListner:(membersData)=>{
                        convo.members = []; 
                        for(let uid in membersData){
                            let m = membersData[uid]; 
                            m.uid = uid; 
                            convo.members.push(m);  
                        }

                        listner(this.convos);
                    },
                }

                this.convos.set(cid, convo); 
                this.mainService.addDataListener('chatConvos/' + cid, convo.listner); 
                this.mainService.addDataListener('chatConvoMembers/' + cid, convo.membersListner); 

                convoCount++;
            }

            //If its empty fire the lisnter once! (else it gets fired by sub listners)
            if(!convoCount)
                listner(this.convos);  
        }

        this.mainService.addDataListener('userChatConvoRelationships/' + uid, this.userChatConvoRelationshipsListner); 
        
    }

    removeConvosListner(listner){
        let uid = this.mainService.getUid();

        //Remove all listners for individual convos
        for(let [cid, convo] of this.convos){
            this.mainService.removeDataListener('chatConvos/' + cid, convo.listner); 
        }

        //remove user rel listner 
        this.mainService.removeDataListener('userChatConvoRelationships/' + uid, this.userChatConvoRelationshipsListner); 
    
    }



    addMessagesListner(convoId, listner){
        this.mainService.addDataListener('chatConvoMessages/' + convoId, listner); 
    }

    removeMessagesListner(convoId, listner){
        this.mainService.removeDataListener('chatConvoMessages/' + convoId, listner); 
    }



    addNewMessageListner(listner){
        let uid = this.mainService.getUid();
        this.mainService.addDataListener('chatConvoNewMessage/' + uid, listner); 
    }

    removeNewMessageListner(listner){
        let uid = this.mainService.getUid();
        this.mainService.removeDataListener('chatConvoNewMessage/' + uid, listner); 
    }

    //returns an array of contacts (the users chat contacts)
    async getContactsData(){
        try{
            const token = await this.mainService.getUserToken();
            const res = await fetch(API_URL + `/getusercontacts`,{
                headers: {
                    Authorization:`${token}`,
                }
            })
            const data = await res.json();
            if(data && data.contacts && Array.isArray(data.contacts)){
                return data.contacts; 
            }
            return []; 
        }
        catch(err){
            console.error(err); 
            return []; 
        }
    }

    async getSingleContactData(cid){
        try{
            const token = await this.mainService.getUserToken();
            
            const res = await fetch(API_URL + `/getsingleusercontact?` + new URLSearchParams({
                cid: cid,
            }),{
                headers: {
                  Authorization: `${token}`
                }
            }); 

            const data = await res.json();
            
            if(data && data.contact){
                return data.contact; 
            }

            return null; 
        }
        catch(err){
            console.error(err); 
            return null; 
        }
    }

}