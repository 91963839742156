import { Routes, Router, MemoryRouter, BrowserRouter, Switch, Route ,Navigate} from "react-router-dom";

import React from 'react';


import PrivateRoute from "./PrivateRoute";


import Home from "./pages/Home"; 
import Login from "./pages/Login";
import EmailLogin from "./pages/EmailLogin";
import Products from "./pages/Products";
import Product from "./pages/Product";
import IntellectibaseMain from "./intellectibase/IntellectibaseMain";


const AllRoutes = () => {
    return(
    <BrowserRouter >
        <Routes>
            <Route path="/" exact={true} element={<PrivateRoute><Home/></PrivateRoute>} />
            <Route path="/home" exact={true} element={<PrivateRoute><Home/></PrivateRoute>} />
            <Route path="/signup" element={<Login title="Sign up"/>} />
            <Route path="/login" element={<Login title="Log in"/>} />
            <Route path="/email-login" element={<EmailLogin/>} />
            <Route path="/products" exact={true} element={<Products/>} />
            <Route path="/product" element={<Product/>} />
          
            <Route path="/admin-console" element={<PrivateRoute><IntellectibaseMain/></PrivateRoute>} />

        </Routes>
    </BrowserRouter>
    )
}

export default AllRoutes;