import React, {useState, useEffect, useContext } from "react";
import { useNavigate, createSearchParams, useSearchParams } from "react-router-dom";
import useQuery from "../useQuery";

import MainContext from "../MainContext"; 

import Theme from '../Theme';

import { ThemeProvider } from  '@mui/system';
import { Box, Typography, ButtonBase, Badge, CircularProgress } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MessageIcon from '@mui/icons-material/Message';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SellIcon from '@mui/icons-material/Sell';
import { Icon } from "@iconify/react";

import TopBar from '../components/TopBar';


import Applications from '../modules/Applications';
import Dashboard from '../modules/Dashboard';
import PPDashboard from "../modules/PPDashboard";
import ProductListings from "../modules/ProductListings";
import GetStarted from '../modules/GetStarted'; 
import Profile from '../modules/Profile'; 
import Messages from "../modules/Messages";
import Campaigns from "../modules/Campaigns";
import MessagingPopup from "../components/MessagingPopup";
import Paper from "../components/Paper";

const Home = () => {
    //Use
	const mainService = useContext(MainContext);
	const navigate = useNavigate(); 
    let query = useQuery();
    const [searchParams, setSearchParams] = useSearchParams();

    //STATES
    const [loading, setLoading] = useState(true); 
    const [userObj, setUserObj] = useState(null);
    const [queryModulePicked, setQueryModulePicked] = useState(false); 
    const [moduleSelection, setModuleSelection] = useState("dashboard"); 
    const [messagingPopupOpen, setMessagingPopupOpen] = useState(false); 
    const [newMessageCount, setNewMessageCount] = useState(0); 
    const [userAccess, setUserAccess] = useState({loading:true}); //{loading:true|null, appAccess:true|false|null} 
    const [upgradeOpen,setUpgradeOpen] = useState(false);
    const [activeBillingId, setActiveBillingId] = useState('');

    //HANDLERS
    const handleToolbarChange = (selection)=>{
        setQueryModulePicked(true); 
        setModuleSelection(selection);
    }

    const handleOpenUpgrade = () => {
        setUpgradeOpen(true)
    }

    const handleCloseUpgrade = (event,reason) => {
        if (reason !== 'backdropClick') {
            setUpgradeOpen(false)
        }
    }

    const getBillingId = async () => {
        //gets a billing id if the userObject does not have an active one already. We need a billing id for pps to link to a product
        const billingId = await mainService.accountService.getBillingId(userObj);
        setActiveBillingId(billingId);
    }


    //LISTNERS
    let listenUserChanged = (userObj)=>{
        setUserObj(userObj); 
        setLoading(false); 
    }

    let listenNewUnreadMessages = (newMessages)=>{
        if(newMessages)
            setNewMessageCount( Object.entries(newMessages).length ); 
        else
            setNewMessageCount( 0 ); 
    }

    let listenAccessChanged = (access)=>{
        setUserAccess(access); 
    }

    //Effects
	useEffect(() => {
		//on mount
        setLoading(true); //start loading while getting first user object        
        mainService.messageService.addNewMessageListner(listenNewUnreadMessages);

		//on unmount
		return ()=>{
            //mainService.removeUserListner(listenUserChanged, userUid); //We dont need to do this as logout auto removes all user listners
            mainService.messageService.removeNewMessageListner(listenNewUnreadMessages);
		}
	}, []);

    useEffect(()=>{
        if(loading){
            mainService.addUserListner(listenUserChanged); 
            mainService.addAccessListner(listenAccessChanged); 
            mainService.updateLastActivityDate();
        }
    }, [loading]);

    useEffect(()=>{

        if(userObj && userObj.type){

            //add specific access - only adds if access doesn't exist, and adds teacher acces for all accounts 
            if(userObj.type !== 'thirdparty')
                mainService.addTeacherAccess();
           
            
            //set billing id to listen to billing plan

            if(userObj.activeBillingId){
                setActiveBillingId(userObj.activeBillingId)
            }
            else {
                getBillingId();
            }
        }

        //if the user doesn't have a type, set to teacher as default
        if(userObj && !userObj.type){
            mainService.profileService.setUserType('teacher'); 
        }


    }, [userObj]);

    useEffect(()=>{
        
        if(userObj && userObj.accountstarted){
            //start query actions
            if(searchParams.get("module")==="applications" && userObj.type !== "thirdparty"){
                handleToolbarChange("applications");
            }
            else if(searchParams.get("module")==="products" && userObj.type === "thirdparty"){
                handleToolbarChange("products");
            }
            else if(searchParams.get("module")==="messages" ){
                handleToolbarChange("messages");
            }
            else if(searchParams.get("module")==="profile" ){
                handleToolbarChange("profile");
                searchParams.delete('module'); 
            }
        }

    },[userObj, searchParams])

    


    //RENDER
    let toolbarElements = [];

    if(userObj && userObj.type == 'thirdparty'){
        toolbarElements = [{
            icon:<DashboardIcon sx={{}} color="action" />,
            text:'Dashboard',
            id:'dashboard',
        }, {       
            icon:<SellIcon sx={{}} color="action" />,
            text:'Product listings',
            id:'products',
        },/* {
            icon:<MessageIcon sx={{}} color="action" />,
            text:'Messages',
            id:'messages',
        }, /*{
            icon:<AccountCircle sx={{}} color="action" />,
            text:'Profile',
            id:'profile',
        }*/ 
        ]
    }
    else {
        toolbarElements = [{
            icon:<DashboardIcon sx={{}} color="action" />,
            text:'Dashboard',
            id:'dashboard',
        }, {
            icon:<BorderColorIcon sx={{}} color="action" />,
            text:'Applications',
            id:'applications',
        },/* {    
            icon:<GroupsIcon sx={{}} color="action" />,
            text:'Campaigns',
            id:'campaigns',
        },{
            icon:<MessageIcon sx={{}} color="action" />,
            text:'Messages',
            id:'messages',
        },/* {
            icon:<AccountCircle sx={{}} color="action" />,
            text:'Profile',
            id:'profile',
        }, */
        ] 
    }
 

    return (
        <ThemeProvider theme={Theme}>
            
            <Box sx={{position:'relative', width:'100%', height:'100%', display:'flex', flexDirection:'column',overflow:'hidden'}}>
                
                <TopBar thinMode={toolbarElements.length <= 1} authed userObject={userObj} onOpenUpgrade={handleOpenUpgrade}>
                {toolbarElements.length > 1 && toolbarElements.map((t)=>(
                    <ButtonBase disabled={!(userAccess && userAccess.appAccess)} key={t.id}  onClick={(e)=>{handleToolbarChange(t.id); }} sx={{ display:'flex', alignSelf:'stretch', alignItems:'stretch'}}>
                        <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', px:'16px',
                                backgroundColor: moduleSelection == t.id ? 'rgba(187, 229, 213, 0.7)' : 'default', '&:hover': {backgroundColor: moduleSelection == t.id ? 'rgba(187, 229, 213, 0.7)' :'#f3f4f9'}, }}> 
                            <Badge badgeContent={t.id == 'messages' ? newMessageCount : 0} color="secondary" anchorOrigin={{vertical: 'top', horizontal: 'right',}}>
                            {t.icon}
                            </Badge>
                            <Typography variant='body1' color={'#5f748d'} sx={{ml:'16px', fontWeight:500, fontSize:'13px'}}>{t.text}</Typography>
                        </Box>
                    </ButtonBase>
                ))}
                </TopBar>
                
                {loading || (userAccess && userAccess.loading) ? (
                    <Box sx={{flexGrow:1, display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                        <CircularProgress color="inherit" />
                    </Box>
                ):!(userObj && userObj.accountstarted) ? (
                    <>
                        <Box sx={{position:'relative', backgroundImage:'url("assets/patternBlur.svg")', flexGrow:1}}>
                            <GetStarted userObject={userObj}/>
                        </Box>
                    </>
                ):!(userAccess && userAccess.appAccess) ? (
                    <Box sx={{overflowX:"hidden",position:'relative', width:'100%', height:'100%', display:'flex', justifyContent:'center', backgroundImage:'url("assets/patternBlur.svg")', }}>
                    <Paper sx={{flexGrow:1, overflowX:"hidden",position:'relative', display:'flex', flexDirection:'column', m:'32px', maxWidth:'1200px'}}>
                        <Box flexGrow={1}/>
                        <Typography variant="h1" align="center">
                            Oops, looks like you don't have access to Intellectible.
                        </Typography>
                        <Box flexGrow={1}/>
                    </Paper>
                    </Box>
                ):!(userObj && userObj.accountstarted && userAccess.appAccess && (( userAccess.teacherAccess) || (userObj.type == 'thirdparty' && userAccess.productProAccess) )) ? (
                    <Box sx={{overflowX:"hidden",position:'relative', width:'100%', height:'100%', display:'flex', justifyContent:'center', backgroundImage:'url("assets/patternBlur.svg")', }}>
                    <Paper sx={{flexGrow:1, overflowX:"hidden",position:'relative', display:'flex', flexDirection:'column', m:'32px', maxWidth:'1200px'}}>
                        <Box flexGrow={1}/>
                        <Typography sx={{mx:'32px'}} variant="h1" align="center">
                            Thank you for registering your interest in Intellectible. To learn how you can gain access contact hello@intellectible.com
                        </Typography>
                        <Box flexGrow={1}/>
                    </Paper>
                    </Box>
                ):(  
                    <Box sx={{display:'flex', flexGrow:1}}>
                        
                        { false &&
                        <Box sx={{position:'relative', width:'220px'}}>
                            <Box sx={{borderRight:`1px solid ${Theme.palette.grey[300]}`,position:'absolute', width:'100%', height:'100%', display:'flex', alignItems:'stretch', flexDirection:'column', pt:'24px', px:'16px', /*borderRight:`1px solid ${Theme.palette.grey[300]}`*/}}>
                                {toolbarElements.map((t)=>(

                                    <ButtonBase key={t.id}  onClick={(e)=>{handleToolbarChange(t.id); }} sx={{height:'50px', display:'flex', alignItems:'center', borderRadius:'8px', mb:'4px', }}>
                                        <Box sx={{position:'absolute', width:'100%', height:'100%', display:'flex', alignItems:'center', borderRadius:'8px',
                                                backgroundColor: moduleSelection == t.id ? 'rgba(187, 229, 213, 0.7)' : 'default', '&:hover': {backgroundColor: moduleSelection == t.id ? 'rgba(187, 229, 213, 0.7)' :'#f3f4f9'}, }}> 
                                            <Badge badgeContent={t.id == 'messages' ? newMessageCount : 0} color="secondary" anchorOrigin={{vertical: 'top', horizontal: 'right',}}>
                                            {t.icon}
                                            </Badge>
                                            <Typography variant='body1' color={'#5f748d'} sx={{ml:'22px', fontWeight:500, fontSize:'13px'}}>{t.text}</Typography>
                                        </Box>
                                    </ButtonBase>

                                ))}
                            </Box>
                        </Box>
                        }

                        <Box sx={{position:'relative', flexGrow:1, backgroundImage:'url("assets/patternBlur.svg")', }}>

                            {moduleSelection === 'dashboard' && userObj && userObj.type != 'thirdparty' ? (
                                <Dashboard userObject={userObj} onOpenUpgrade={handleOpenUpgrade} onOpenMessaging={()=>setMessagingPopupOpen(true)}/>
                            ): moduleSelection === 'dashboard' && userObj && userObj.type == 'thirdparty' ? (
                                <PPDashboard userObject={userObj} billingId={activeBillingId} openChatPopup={()=>setMessagingPopupOpen(true)} />
                            ): moduleSelection === 'applications' ? (
                                <Applications userObject={userObj} onOpenUpgrade={handleOpenUpgrade} />
                            ): moduleSelection === 'products' ? (
                                <ProductListings userObject={userObj}  />
                            ): moduleSelection === 'campaigns' ? (
                                <Campaigns/>
                            ): moduleSelection === 'messages' ? (
                                <Messages userObject={userObj}/>
                            ): moduleSelection === 'profile' ?(
                                <Profile userObject={userObj}/>
                            ): 
                            (null)}

                            {/*
                            <Outlet context={{ userObject:userObj, }}/>
                            */}

                        </Box>
                    </Box>
                )}
            </Box>
            {/* moduleSelection !== 'messages' && userObj && userObj.accountstarted && 
            <MessagingPopup userObject={userObj} unreadCount={newMessageCount} open={messagingPopupOpen} onOpen={()=>setMessagingPopupOpen(true)} onClose={()=>setMessagingPopupOpen(false)}/>
            */}
        </ThemeProvider>
    )
}

export default Home; 