import React, {useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import MainContext from "../MainContext"; 

import theme from '../Theme';

import { Box, Typography, IconButton} from '@mui/material';

import ListingCard from "./products/ListingCard";
import MainService from "../MainService";

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import Carousel from 'react-material-ui-carousel'
import TopBar from "../components/TopBar";
import FeaturedProduct from "./products/FeaturedProduct";

import useQuery from "../useQuery";


const Products = () => {

    //Use
	const mainService = useContext(MainContext);
	const navigate = useNavigate(); 
    let query =  useQuery();

    //STATES
    const [authed, setAuthed] = useState(false)
    const [collectionId,setCollectionId] = useState(null);
    const [listingIds,setListingIds] = useState(null);
    const [featuredIds,setFeaturedIds] = useState(null);
    const [userObj,setUserObj] = useState(null);
    const [showError,setShowError] = useState(false);
    const [providerName,setProviderName] = useState(null)
    
    //HANDLERS
  
    const handleAuthStateChanged = () => {
        const authState = mainService.getAuthedState() === 2 ? true : false;
        setAuthed(authState);
    }

    const handleListingOpen = (id) => {
        navigate(`/product?listingid=${id}&collection=${collectionId}`);
    }

    //Effects
	useEffect(() => {

		//on mount
		console.log('Products mounted'); 

        //initially get auth state
        const authState = mainService.getAuthedState() === MainService.AUTHED ? true : false;
        setAuthed(authState);

		mainService.addAuthChangeCallback(handleAuthStateChanged); 

        //on mount
		if(query.has("collection")){
			const collid = query.get("collection");
            setCollectionId(collid);
		}
        //mainService.productListingService.addPublicListingsListener(listenListingsChanged);

		//on unmount
		return ()=>{
			console.log('Products unmounted');
			mainService.removeAuthChangeCallback(handleAuthStateChanged); 
            //mainService.productListingService.removePublicListingListener(listenListingsChanged);
		}

	}, []);

    const listenCollectionChanged = (data) => {
        if(data.products){
            const lids = Object.keys(data.products);
            const fids = lids.filter(el => data.products[el] === 'featured');
            setListingIds(lids);
            setFeaturedIds(fids.length == 0 ? lids : fids);
        }
        setProviderName(data.providerName)
    }

    useEffect(()=>{
        if(collectionId)
            mainService.productListingService.addProductCollectionListener(collectionId, listenCollectionChanged);
        
        return(()=>{
            if(collectionId)
                mainService.productListingService.removeProductCollectionListener(collectionId, listenCollectionChanged);
        })

    },[collectionId])

	let listenUserChanged = (userObj)=>{
		setUserObj(userObj); 
	}

    useEffect(()=>{
		if(authed)
        	mainService.addUserListner(listenUserChanged);

		return ()=> {
			if(authed)
				mainService.removeUserListner(listenUserChanged);
		}
	},[authed]);

    useEffect(()=>{
        let timer = null;
        if(!listingIds){
            timer = setTimeout(()=>{
                setShowError(true)
            },2000)
        }
        else{
            setShowError(false)
        }
        return(()=>{
            if(timer)   
                clearTimeout(timer);
        })
    },[listingIds])


    const renderGrid = () => {
        const cols = 3;
        const listingGrid = listingIds ? [...Array(cols).keys()].map(c => listingIds.filter((_, i) => i % cols === c)) : null;
        console.log(listingGrid)
        return (
            <>
            {listingGrid &&
            <Box sx={{display: {xs:"block", sm:"flex"}}}>
                <Box sx={{flex: 1}}>
                    {listingGrid[0].map((id)=>{
                        return (
                        <ListingCard
                            key={id}
                            listingId={id}
                            sx={{mr:theme.spacing(2),mb:theme.spacing(2)}}
                            onClick={()=>{handleListingOpen(id)}}
                        />)
                    })}
                </Box>
                <Box sx={{flex: 1}}>
                    {listingGrid[1].map((id)=>{
                        return (
                        <ListingCard
                            key={id}
                            listingId={id}
                            sx={{mr:theme.spacing(2),mb:theme.spacing(2)}}
                            onClick={()=>{handleListingOpen(id)}}
                        />)
                    })}
                </Box>
                <Box sx={{flex: 1}}>
                    {listingGrid[2].map((id)=>{
                        return (
                        <ListingCard
                            key={id}
                            listingId={id}
                            sx={{mr:theme.spacing(2),mb:theme.spacing(2)}}
                            onClick={()=>{handleListingOpen(id)}}
                        />)
                    })}
                </Box>
            </Box>}
            </>
        );

    }

    return (
                                     
        <Box sx={{position:'relative', width:'100%', height:'100%', display:'flex', flexDirection:'column'}}>

            <TopBar
                userObject={userObj}
                authed={authed}
                dashboard
            />
            
            <Box sx={{flexGrow:1,display:"flex",flexDirection:"column", alignItems:"center",overflowY:"overlay"}}>
                {showError ? (
                    <Typography variant='h2' sx={{mt:'128px'}}>Sorry we couldn't find any products, please double check the page link with your Product Provider</Typography>
                ) : (
                    <>
                    <Box sx={{position:'relative', display:"flex", flexDirection:'column', justifyContent:'center', width:"100%", backgroundColor:'#ffffff'}}>
                        {providerName && 
                        <Typography align='center' sx={{mx:theme.spacing(4),my:theme.spacing(2)}} variant="h1">
                            Apply for {providerName}'s products in minutes with Intellectible
                        </Typography>}
                        <Carousel 
                            animation='slide' 
                            navButtonsAlwaysVisible={true}
                            indicators={false}
                            duration={500} 
                            sx={{ position:'relative', flexGrow:1, display:'flex', height:'500px', flexDirection:'column'}}
                            navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                                style: {
                                    backgroundColor:'rgba(0, 0, 0, 0)',
                                    '&:hover': { 
                                        backgroundColor:'rgba(0, 0, 0, 0.1)',
                                    }
                                },
                                
                            }} 
                            navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
                                style: {
                                    //top: 'unset'
                                },
                            }} 

                            NavButton={({onClick, className, style, next, prev}) => {
                                // Other logic
                        
                                return (
                                    <IconButton onClick={onClick} className={className} style={style} sx={{ 
                                        width:'64px', 
                                        height:'64px', 
                                        left:prev? {xs:'24px', xl:'200px'} : 'unset', 
                                        right:next? {xs:'24px', xl:'200px'} :'unset', 
                                        top: "calc(50% - 20px) !important",
                                        backgroundColor:'rgba(0, 0, 0, 0)',
                                        '&:hover': { 
                                            backgroundColor:'rgba(0, 0, 0, 0)',
                                            filter: "brightness(120%)",
                                            opacity: "0.4"
                                        }
                                    }}>
                                        {next && <NavigateNextIcon sx={{width:'32px', height:'32px'}}/>}
                                        {prev && <NavigateBeforeIcon sx={{width:'32px', height:'32px'}}/>}
                                    </IconButton>
                                )
                            }}
                        >
                        {featuredIds && featuredIds.map((id)=>(
                            <FeaturedProduct key={id} listingId={id} onButtonClick={()=>handleListingOpen(id)}/>
                        ))}
                        </Carousel>
                </Box>



                <Box sx={{ mt:'64px', mx:theme.spacing(4),display:"flex",flexDirection:"column"}}>
                    <Typography sx={{mb:theme.spacing(4), fontSize:'22px',  fontWeight:'600'}} align="left" variant = "body"> 
                        Browse our products
                    </Typography>
                    {renderGrid()}
                </Box>
                 </>       
                )}
                
            </Box>
        </Box>
    )
}

export default Products; 