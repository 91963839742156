import React, {useState, useEffect, useContext } from "react";

import Theme from '../../Theme';

import MainContext from "../../MainContext"; 

import { ThemeProvider } from  '@mui/system';
import { Box, Typography, IconButton, Dialog, Button, Tab, Tabs } from "@mui/material";

import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import LoadingFull from "../../components/LoadingFull";

import ProductCreationForm from "./ProductCreationForm";
import FundingEligibilityForm from "./FundingEligibilityForm";
import BudgetGeneratorForm from "./BudgetGeneratorForm";
import ApplicationMain from "./ApplicationMain";


import YesNoDialog from "../../components/YesNoDialog";

const ApplicationSettingsMenu = ({userObject, applicationMetadata, appId, onAppDeleted, onAppDeleteStarted, onClose, open})=>{
    
    const mainService = useContext(MainContext);

    const [ynOpen, setYnOpen] = useState(false); 
    const [ynText, setYnText] = useState('Are you sure?'); 

    const handleDeleteClick = ()=>{
        setYnText('Are you sure you would like to delete this application? This cannot be undone!');
        setYnOpen(true); 
    }

    const handleDelete = async ()=>{
        setYnOpen(false);
        onClose(); 

        //Do the deletion here!
        onAppDeleteStarted();
        await mainService.applicationService.deleteApplication(appId, applicationMetadata.productId); 
        await new Promise(resolve => setTimeout(resolve, 1000));
        onAppDeleted(); 
    }

    return (
        <Dialog onClose={onClose} open={open} >
            <Box sx={{display:'flex', flexDirection:'column', p:'16px'}}>
                <Typography variant='subtitle1' gutterBottom >Application Settings</Typography>
                
                <Button onClick={handleDeleteClick}>Delete Application</Button>

            </Box>

            <YesNoDialog text={ynText} onNo={()=>setYnOpen(false)} onYes={handleDelete} open={ynOpen}/>
        </Dialog>
    )
}

const Application = ({userObject, appId, onAppMenuClick, tabValue, onTabChange}) => {
    
    const mainService = useContext(MainContext);

    const [loadingMetadata, setLoadingMetadata] = useState(true); 
    const [appSettingsMenuOpen, setAppSettingsMenuOpen] = useState(false); 
    const [applicationMetadata, setApplicationMetadata] = useState(null); 
    const [productData,setProductData] = useState(null);
    const [budgetSummary,setBudgetSummary] = useState(null);
    const [budgetSections,setBudgetSections] = useState(null);
    const [budgetLines,setBudgetLines] = useState(null);

    //HANDLERS

    const handleAppSettingsMenuClose = ()=>{
        setAppSettingsMenuOpen(false);
    }

    const handleAppDeleted = ()=>{
        onAppMenuClick(); 
    }

    const handleChangeTab = (event, newValue) => {
        onTabChange(newValue);
    };

    const handleFinishProduct = () => {
        mainService.applicationService.setEligibilityProjectSkills(appId,productData?.skills ?? null);
        mainService.applicationService.setApplicationStage(appId, 'eligibility'); 
    }

    //TODO - also move get funding recs here as well?
    const handleCreateApplication = () => {
        console.log('creating application'); 
        mainService.applicationService.setFundingRecommendation(appId, null);//clear previous recommendations 
        mainService.applicationService.setApplicationStage(appId, 'budget'); 
        mainService.applicationService.pushInitialApplicationSectionsAndContent(appId, userObject, applicationMetadata, productData);
    }

    const handleFinishBudget = () => {
        //TODO might just remove this 
        mainService.applicationService.setBudgetStage(appId,'created');
        //
        mainService.applicationService.setApplicationStage(appId, 'superapp'); 
    }    
    //listners
    const listnerAppMetadataChanged = (value)=>{
        console.log('Application data changed:' + appId); 
        setApplicationMetadata(value); 
        setLoadingMetadata(false); 
    }

    const listenProductDataChanged = (value)=> {
        setProductData(value);
    }

    const listenBudgetSummaryChanged = (val) => {
        setBudgetSummary(val);
    }

    const listenBudgetSectionsChanged = (val) => {
        setBudgetSections(val);
    }

    const listenBudgetLinesChanged = (val) => {
        setBudgetLines(val);
    }
    

    //EFFECTS 
    useEffect(() => {
        console.log('Application mounted:' + appId); 

        if(appId){
            mainService.applicationService.addApplicationMetadataListner(appId, listnerAppMetadataChanged); 
            mainService.applicationService.addBudgetSummaryListener(appId, listenBudgetSummaryChanged); 
            mainService.applicationService.addBudgetSectionsListener(appId, listenBudgetSectionsChanged); 
            mainService.applicationService.addBudgetLinesListener(appId, listenBudgetLinesChanged); 
        }

        return ()=>{
            console.log('Application unmounted' + appId);
            if(appId){
                mainService.applicationService.removeApplicationMetadataListner(appId, listnerAppMetadataChanged); 
                mainService.applicationService.removeBudgetSummaryListener(appId, listenBudgetSummaryChanged); 
                mainService.applicationService.removeBudgetSectionsListener(appId, listenBudgetSectionsChanged);
                mainService.applicationService.removeBudgetLinesListener(appId, listenBudgetLinesChanged);
            }
		}

	}, [appId]);

    //EFFECTS 
    useEffect(() => {
 
        if(applicationMetadata && applicationMetadata.productId){
            mainService.applicationService.addProductDataListener(applicationMetadata.productId, listenProductDataChanged); 
        }
        else {
            mainService.applicationService.addApplicationProductDataListener(appId,listenProductDataChanged);
        }

		return ()=>{
            if(applicationMetadata && applicationMetadata.productId){
                mainService.applicationService.removeProductDataListener(applicationMetadata.productId, listenProductDataChanged); 
            }
            else {
                mainService.applicationService.removeApplicationProductDataListener(appId,listenProductDataChanged);
            }
		}
	}, [applicationMetadata]);   

    //RENDER
    return (
    <ThemeProvider theme={Theme}>
        <Box sx={{overflowX:"hidden",position:'relative', width:'100%', height:'100%', display:'flex', flexDirection:'column'}}>

            {!loadingMetadata && applicationMetadata &&
            <Box sx={{height: '50px', mt:'12px', display:'flex', justifyContent:'center', px:'18px', /*borderBottom:`1px solid ${Theme.palette.grey[300]}`*/}}>
                <Box sx={{flexGrow:1, maxWidth:'1200px',display:'flex',flexDirection:'column'}}>

                <Box sx={{mx:'16px', display:'flex', alignItems:'center',justifyContent:'space-between'}}>
                    <IconButton sx={{mr:'16px'}} onClick={onAppMenuClick}>
                        <MenuIcon/>
                    </IconButton>

                    {applicationMetadata.applicationStage == 'superapp' &&
                    (<Box sx={{ mb:'12px',alignSelf:'center'}}>
                    <Tabs value={tabValue} onChange={handleChangeTab} sx={{p:0}} aria-label="application-tabs">                       
                        <Tab sx={{textTransform:'none'}} label="Application" id='tab-0' />
                        {/*<Tab sx={{textTransform:'none'}} label="Application content" id='tab-1' />*/}
                        <Tab sx={{textTransform:'none'}} label="Budget" id='tab-2' />
                        <Tab sx={{textTransform:'none'}} label="Funding" id='tab-3' />
                        <Tab sx={{textTransform:'none'}} label="DonorsChoose" id='tab-4' />
                    </Tabs>
                    </Box>) 
                }
                    <IconButton onClick={()=>setAppSettingsMenuOpen(true)}>
                        <MoreVertIcon/>
                    </IconButton>
                </Box>
                </Box>
            </Box>
            }

            <Box sx={{overflowX:"hidden", position:'relative', flexGrow:1, display:'flex', justifyContent:'center', }}>
                {!applicationMetadata ? (
                    <LoadingFull/>
                ):
                (!applicationMetadata.applicationStage && !applicationMetadata.productId) || applicationMetadata.applicationStage == 'product' ? (
                    <ProductCreationForm 
                        productData={productData}
                        userObject={userObject} 
                        appId={appId} 
                        applicationMetadata={applicationMetadata} 
                        loading={loadingMetadata}
                        onFinish={handleFinishProduct}
                    />
                ):                
                (!applicationMetadata.applicationStage && applicationMetadata.productId) || applicationMetadata.applicationStage == 'eligibility' ? (
                    <FundingEligibilityForm 
                        productData={productData}
                        userObject={userObject} 
                        appId={appId} 
                        applicationMetadata={applicationMetadata} 
                        loading={loadingMetadata}
                        onFinish={handleCreateApplication}
                    />
                ): applicationMetadata.applicationStage == 'budget' ? (
                    <BudgetGeneratorForm 
                        userOject={userObject} 
                        applicationMetadata={applicationMetadata} 
                        appId={appId}
                        budgetLines={budgetLines}
                        budgetSummary={budgetSummary}
                        budgetSections={budgetSections}
                        productData={productData}
                        onFinish={handleFinishBudget}
                    />
                ): applicationMetadata.applicationStage == 'superapp' ? (
                    <ApplicationMain
                        productData={productData}
                        applicationMetadata={applicationMetadata}
                        userObject={userObject} 
                        budgetLines={budgetLines}
                        budgetSummary={budgetSummary}
                        budgetSections={budgetSections}
                        appId={appId}
                        tabValue={tabValue}
                        onTabChange={onTabChange}
                    />
                ):(
                    null
                )}
            </Box>
        </Box>

        {applicationMetadata && 
        <ApplicationSettingsMenu open={appSettingsMenuOpen} onClose={handleAppSettingsMenuClose} 
            applicationMetadata={applicationMetadata} userObject={userObject} appId={appId} 
            onAppDeleted={handleAppDeleted} onAppDeleteStarted={()=>setLoadingMetadata(true)}/>
        }

    </ThemeProvider>
    )
}

export default Application; 