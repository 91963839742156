import React, {useState, useEffect, useContext } from "react";

import { Box, Typography, InputBase, ButtonBase } from "@mui/material";

import {Add,Remove} from '@mui/icons-material'

import Theme from "../../Theme";



const NumberFormInput = ({value, title, onChange, increment=1, isCurrency, align='left', sx})=>{
        
    const handleChange = (val)=>{
        onChange(val); 
    }

    const addOne = () => {
        console.log(value)
        let intval = parseInt(value);

        let newVal = 0;
        if(!isNaN(intval) && intval !== 'undefined' && intval !== 'null')
            newVal = intval+increment;
        else 
            newVal=0;

        newVal = newVal.toString();

        onChange(newVal);
    }

    const subtractOne = () => {
        let intval = parseInt(value);
        let newval = 0;
        if(intval>increment)
            newval = intval-increment;
        else if(intval==0 || (intval-increment)<= 0){
            newval = 0;
        }
        else
            newval = value;
        newval =  newval.toString();

        onChange(newval);
    }

    return(
    <React.Fragment>
        <Typography align={align} sx={{my:'16px', mx:'16px'}}>{title}</Typography>
        <Box  sx={{mx:'16px', mb:'16px', alignSelf:'stretch', display:'flex', justifyContent: align=='center' ? 'center' : null, gap:'8px', ...sx }}>
            <ButtonBase  
                onClick={subtractOne}   
                sx={{
                    bgcolor:Theme.palette.primary.main, 
                    borderRadius:'50px',
                    '&:hover':{bgcolor:Theme.palette.primary.dark}
                }}>
                <Remove sx={{width:'36px',height:'36px',color:'white'}}/>
            </ButtonBase>
            <InputBase 
                value={value ? value : '0'}
                onChange={(e)=>handleChange(e.target.value)}
                variant="outlined"
                type='number'
                startAdornment={isCurrency ? '$' : ''}
                sx={{
                    align: 'center',
                    textAlign: 'center',
                    '& input': {
                        textAlign: 'center'},
                    width:'64px',
                    '& input[type=number]': {
                        '-moz-appearance': 'textfield'
                    },
                    '& input[type=number]::-webkit-outer-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0
                    },
                    '& input[type=number]::-webkit-inner-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0
                    }}}
            /> 
            <ButtonBase 
                onClick={addOne}     
                sx={{
                    bgcolor:Theme.palette.primary.main, 
                    borderRadius:'50px',
                    '&:hover':{bgcolor:Theme.palette.primary.dark}
                }}>
                <Add sx={{width:'36px',height:'36px',color:'white'}}/>
            </ButtonBase>
        </Box>

    </React.Fragment>
    )
}

export default NumberFormInput;
