import * as React from 'react';
import { Avatar, Box, Typography} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

const ProfileAvatar = ({photoURL, name, isPro, sx})=> {
  return (
    <>
    {isPro ? (
      (
      <Box sx={{background:'linear-gradient(to right, #ffeee6, #f5f0fb)' ,p:'4px',borderRadius:'50px',position:'relative'}}>
        <Avatar sx={sx} color="action" src={photoURL ? photoURL : null} imgProps={{ referrerPolicy: "no-referrer" }}>
        {!photoURL && name ? (name.charAt(0)) : (<PersonIcon/>)} 
        </Avatar>
        <Box sx={{position:'absolute',right:-20,bottom:2, background:'linear-gradient(to right, #ffeee6, #f5f0fb)',px:'2px',borderRadius:'50px'}}><Typography variant='body2' sx={{color:'black',mb:'2px',color:'#555555'}}>pro</Typography></Box>
      </Box>
      )
    ) :
      (<Avatar sx={sx} color="action" src={photoURL ? photoURL : null} imgProps={{ referrerPolicy: "no-referrer" }}>
        {!photoURL && name ? (name.charAt(0)) : (<PersonIcon/>)} 
      </Avatar>)
    }
    </>
  );
}

//boxShadow: rgba(255, 255, 255, 0.2) 0px 0px 8px 0px;
//boxShadow: rgba(255, 255, 255, 0.2) 0px 7px 29px 0px;

export default ProfileAvatar;