import React, {useState, useEffect, useContext } from "react";

import MainContext from "../../MainContext"; 

import { Box, Typography, } from "@mui/material";

import StyledTextField from '../../components/StyledTextField'; 
import FormTitle from "../../components/formInputs/FormTitle";



const NameInput = ({formData, setDisableContinueButton})=>{
    
    const mainService = useContext(MainContext);
    
    const handleUserFirstNameChange = (val)=>{
        mainService.profileService.setUserFirstName(val); 
    }

    const handleUserLastNameChange = (val)=>{
        mainService.profileService.setUserLastName(val); 
    }

    let userObject = formData.userObject;

    useEffect(()=>{
        let disableButton = !(formData && formData.userObject && formData.userObject.firstname && formData.userObject.lastname); 
        if(setDisableContinueButton)
            setDisableContinueButton(disableButton); 
    }, [formData]);

    return(
    <>
        <Box sx={{ flexGrow:1, maxWidth:'510px', height:'100%',  display:'flex', alignItems:'stretch', flexDirection:'column',}}>
            <Box sx={{height:'20%'}}></Box>
            
            <FormTitle text='What is your name?'/>
            <Box sx={{display:'flex', alignItems:'center', flexDirection:{xs:'column', sm:'row'} }}>
                
                <StyledTextField 
                    value={userObject.firstname ? userObject.firstname : ''}
                    onChange={(e)=>handleUserFirstNameChange(e.target.value)}
                    label="First Name"
                    variant="outlined"
                    autoComplete="given-name"
                    sx={{mx:'16px', my:'16px'}}
                /> 
                
                <StyledTextField 
                    value={userObject.lastname ? userObject.lastname : ''}
                    onChange={(e)=>handleUserLastNameChange(e.target.value)}
                    label="Last Name"
                    variant="outlined"
                    autoComplete="family-name"
                    sx={{mx:'16px', my:'16px'}}
                /> 

            </Box>

        </Box>
    </>
    )
}

export default NameInput; 