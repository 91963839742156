import React, {useState, useEffect, useContext } from "react";

import Theme from '../../Theme';

import MainContext from "../../MainContext"; 


import { Box, Typography, InputAdornment, TextField, TableCell, Table, TableHead, TableBody,TableRow, Radio, IconButton, ButtonBase, CircularProgress} from "@mui/material";

import FormFlow from '../../components/FlowForm'; 
import SimpleAutocomplete from "../../components/formInputs/SimpleAutocomplete";
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import StyledSquareButton from "../../components/StyledSquareButton";
import { styled } from '@mui/material/styles';
import Paper from "../../components/Paper";

import { WorkQueue,unitOptions,getFormattedNumberString } from "../../Util";

const workQueue = new WorkQueue(); 

const newLineData = {
    item:'',
    quantity:0,
    unitPrice:0,
    unitType:'unit', //TODO add options to PP
    description:'0 x $0/unit',
    total: 0
};

const UnStyledTableCell = styled(TableCell)(({}) => ({
    padding:'4px',
    margin:'0px',
}));



const BudgetLinePicker = ({formData,budgetLineId,budgetSectionId,disabled}) => {

    const mainService = useContext(MainContext);
    //setBudgetValueAndDescription(appId,budgetLineId,quantity,unitPrice,unitType,budgetSectionId,currentLineTotal,currentSubtotal,currentGrandTotal)
    const appId = formData.appId;
    const quant = formData.budgetLines[budgetLineId].quantity;
    const unitPrice = formData.budgetLines[budgetLineId].unitPrice;
    const unitType = formData.budgetLines[budgetLineId].unitType;
    const lineTotal = formData.budgetLines[budgetLineId].total;
    const subtotal = formData.budgetSections[budgetSectionId].total;
    const grandtotal = formData.budgetSummary.total;
    const currency = formData?.budgetSummary?.currency ?? 'USD'

    return (
        
        <TableRow>
        <UnStyledTableCell size='small' align='left' sx={{verticalAlign:'bottom',width:'220px'}}>
            <TextField 
                fullWidth
                disabled = {disabled}
                value={formData.budgetLines[budgetLineId].item ?? ''}
                placeholder="Add an item"
                variant="standard"
                onChange={(e)=>mainService.applicationService.setBudgetLineValue(appId,budgetLineId,'item',e.target.value)}
                InputProps={{disableUnderline:true,sx:{...Theme.typography.body2,'&:hover':{bgcolor:Theme.palette.grey[100]},borderRadius:'5px'}}}
                inputProps={{style:{paddingBottom:0}}}
            />
        </UnStyledTableCell>
        <UnStyledTableCell size='small' align='left' sx={{verticalAlign:'bottom'}}>
            <TextField 
                disabled = {disabled}
                value={formData.budgetLines[budgetLineId].quantity ?? ''}
                placeholder="add a quantity"
                variant="standard"
                onChange={(e)=>mainService.applicationService.setBudgetValueAndDescription(appId,budgetLineId,e.target.value,currency,unitPrice,unitType,budgetSectionId,lineTotal,subtotal,grandtotal)}
                InputProps={{disableUnderline:true,sx:{...Theme.typography.body2,'&:hover':{bgcolor:Theme.palette.grey[100]},borderRadius:'5px'}}}
                inputProps={{style:{paddingBottom:0}}}
            />
        </UnStyledTableCell>
        <UnStyledTableCell size='small' align='left' sx={{verticalAlign:'bottom'}} >
            <TextField 
                disabled = {disabled}
                value={formData.budgetLines[budgetLineId].unitPrice ?? ''}
                placeholder="Add a unit price"
                variant="standard"
                onChange={(e)=>mainService.applicationService.setBudgetValueAndDescription(appId,budgetLineId,quant,currency,e.target.value,unitType,budgetSectionId,lineTotal,subtotal,grandtotal)}
                InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>,disableUnderline:true,sx:{...Theme.typography.body2,'&:hover':{bgcolor:Theme.palette.grey[100]},borderRadius:'5px'}}}
                inputProps={{style:{padding:0}}}
            />
        </UnStyledTableCell>
        <UnStyledTableCell size='small'>
        <SimpleAutocomplete  
            small
            freeSolo
            variant='standard'
            disabled = {disabled}
            value={formData.budgetLines[budgetLineId].unitType ?? ''} 
            options={unitOptions} 
            sx={{width:'100px',' .MuiInputBase-input': {
                height: '1rem'
              }}}
            inputProps={{style:{width:'200px'}}}
            valueName="name" 
            labelName="name" 
            onChange={(v)=>mainService.applicationService.setBudgetValueAndDescription(appId,budgetLineId,quant,currency,unitPrice,v,budgetSectionId,lineTotal,subtotal,grandtotal)}

        />
        </UnStyledTableCell>       
        <UnStyledTableCell size='small' align='left' sx={{verticalAlign:'bottom'}}>
       <ButtonBase 
            disabled = {disabled}
            onClick={()=>mainService.applicationService.removeBudgetLine(appId,budgetLineId,formData.budgetSections,budgetSectionId,lineTotal,subtotal,grandtotal)}
            sx={{height:'24px',width:'24px',borderRadius:'50px'}}
        >
            <DeleteForeverIcon sx={{color:disabled && Theme.palette.grey[400]}}/>
        </ButtonBase>
        </UnStyledTableCell>
        </TableRow>

        )
    
    }
//removeBudgetLine(appId,budgetLineId,sections,sectionId,total,sectionTotal,grandTotal)
const Start = ({formData})=>{
    
    const mainService = useContext(MainContext);
    const [loading,setLoading] = useState(true);

    //EFFECTS 
    useEffect(() => {
        let timer = null;
        if(formData.appId){
            console.log('setting timer');
            timer =  
            setTimeout(() => {
                setLoading(false);
            }, "1000");
        }

        return (()=>{
            if(timer && formData.appId){
                console.log('clearing timer');
                clearTimeout(timer);
            }
        })

    }, [formData.appId]);

    return(

    <Box sx={{ height:'100%', flexGrow:1, display:'flex', alignItems:'stretch', flexDirection:'column'}}>
        {loading ? (
        <Box sx={{width:'100%',height:'100%',flexGrow:1,display:'flex',alignItems:'center',justifyContent:'center'}}>
            <CircularProgress/>
        </Box>) : (
        <React.Fragment>
            <Box sx={{height:'10%'}}></Box>
            <Typography gutterBottom align='center' variant="h2">Now, let's create a budget for your project!</Typography>
            <Box sx={{my:'16px', display:'flex', flexDirection:'column', alignItems:'center'}}>
                <Typography gutterBottom align='center' variant="h4">Complete this short form flow to build a budget sheet into your application</Typography>                     
            </Box>
        </React.Fragment>
        )}


    </Box>

    )
}

const BaseLineBudget = ({formData}) => {

    const [loading,setLoading] = useState(true);
    
    const budgetSummary = formData.budgetSummary;
    const budgetSections = formData.budgetSections;
    const budgetLines = formData.budgetLines;

    const handleChange = (e) => {
        formData.onRadioChange(e.target.value)
    }

    //EFFECTS 
    useEffect(() => {
        let timer = null;
        if(formData.appId){
            console.log('setting timer');
            timer =  
            setTimeout(() => {
                setLoading(false);
            }, "1000");
        }

        return (()=>{
            if(timer && formData.appId){
                console.log('clearing timer');
                clearTimeout(timer);
            }
        })

    }, [formData.appId]);

    return(
        <Box sx={{ height:'100%', maxWidth:'510px',  flexGrow:1, display:'flex', alignItems:'stretch', flexDirection:'column'}}>  
            {loading ? (
            <Box sx={{width:'100%',height:'100%',flexGrow:1,display:'flex',alignItems:'center',justifyContent:'center'}}>
                <CircularProgress/>
            </Box>) : (
            <>
            <Box sx={{height:'10%'}}></Box>
            <Typography variant='h3'  sx={{mx:'16px'}} gutterBottom align='left'>Here is your baseline budget</Typography>
            <Typography sx={{mb:'24px', mx:'16px'}} align='left' variant="body2">This is calculated based on the number of students participating in the project and your chosen product(s)</Typography>

            <Box sx={{m:'16px', display:'flex', flexDirection:'column', alignItems:'stretch', gap:'16px'}}>
            <Paper sx={{display:'flex', flexDirection:'column', overflow:'hidden', p:'4px', mb:'24px'}}>
        
            <Table aria-label="budget-summary" >
            <TableHead>
                <TableRow>
                    <UnStyledTableCell align='left'>
                        <Typography variant="h6">Item</Typography>
                    </UnStyledTableCell>
                    <UnStyledTableCell align='left'>
                        <Typography variant="h6">Description</Typography>
                    </UnStyledTableCell>
                    <UnStyledTableCell align='right' >
                        <Typography variant="h6">Total cost</Typography>
                    </UnStyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {budgetSummary && budgetSummary?.sections && budgetSummary.sections.map((sId)=> (
                <React.Fragment>
                {/*<TableRow>
                    <UnStyledTableCell align="left" colSpan={4} size='small'>
                        {budgetSections[sId]?.name}
                    </UnStyledTableCell>
                    </TableRow>*/}
                {budgetSections && budgetLines && budgetSections[sId]?.lines && budgetSections[sId]?.lines.map((lId)=>(
                    <TableRow>
                        <UnStyledTableCell align="left" size='small'>
                            {budgetLines[lId]?.item}
                        </UnStyledTableCell>
                        <UnStyledTableCell align="left" size='small'>
                            {budgetLines[lId]?.description}
                        </UnStyledTableCell>
                        <UnStyledTableCell align="right"  size='small'>
                            ${getFormattedNumberString(budgetLines[lId]?.total)}
                        </UnStyledTableCell>
                    </TableRow>
                ))}
            </React.Fragment>
            ))}
            <TableRow>
                <UnStyledTableCell align="left" colSpan={2} size='small'>
                    Grand total:
                </UnStyledTableCell>
                <UnStyledTableCell align="right" colSpan={2} size='small'>
                    ${getFormattedNumberString(budgetSummary?.total)}
                </UnStyledTableCell>
            </TableRow>
            </TableBody>
        </Table>  
        </Paper>

        <Typography align='left'>Would you like to add any additional items?</Typography>
        <Typography sx={{mb:'24px',}} align='left' variant="body2">
            Project budgets often contain extra costs like additional staff wages, venue hire, or any extra supplies you need. But don't worry if you can't think of anything now, you can always update this later.
        </Typography>
            
            <Box sx={{display:'flex',gap:'8px'}}>
                <Box sx={{width:'251px',display:'flex',flexDirection:'column',alignItems:'flex-start',justifyContent:'space-between'}}>
                    <Typography align='left' variant="body2">
                        I'm happy with this for now, please take me to my application.
                    </Typography>
                    <Radio
                        checked={formData.addMoreRadio === 'done'}
                        onChange={handleChange}
                        value="done"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'Done' }}
                    />
                </Box>
                
                <Box sx={{width:'251px',display:'flex',flexDirection:'column',flexDirection:'column',alignItems:'flex-start',justifyContent:'space-between'}}>
                    <Typography align='left' variant="body2">
                        I want to add some more items to my budget.
                    </Typography>
                    <Radio
                        checked={formData.addMoreRadio === 'continue'}
                        onChange={handleChange}
                        value="continue"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'Continue' }}
                    />
                </Box>
                </Box>

        </Box>
        </>)}
        </Box>
        )

}

const AddMoreItems = ({formData}) => {

    const handleChange = (e) => {
        formData.onRadioChange(e.target.value)
    }
    return(
        <Box sx={{ height:'100%', maxWidth:'510px',  flexGrow:1, display:'flex', alignItems:'stretch', flexDirection:'column'}}>
            <Box sx={{height:'10%'}}></Box>
            <Typography variant='h3'  sx={{mx:'16px'}} gutterBottom align='left'>Would you like to add any additional items?</Typography>
            <Typography sx={{mb:'48px', mx:'16px'}} align='left' variant="body2">
                Project budgets often contain extra costs like additional staff wages, venue hire, or any extra supplies you need. But don't worry if you can't think of anything now, you can always update this later.
            </Typography>
            <Box sx={{m:'16px', display:'flex', flexDirection:'column', alignItems:'stretch', gap:'16px'}}>
            <Box sx={{display:'flex',gap:'8px'}}>
                <Box sx={{width:'251px',display:'flex',flexDirection:'column',alignItems:'flex-start',justifyContent:'space-between'}}>
                    <Typography align='left' variant="body2">
                        I'm happy with this for now, please take me to my application.
                    </Typography>
                    <Radio
                        checked={formData.addMoreRadio === 'done'}
                        onChange={handleChange}
                        value="done"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'Done' }}
                    />
                </Box>
                
                <Box sx={{width:'251px',display:'flex',flexDirection:'column',flexDirection:'column',alignItems:'flex-start',justifyContent:'space-between'}}>
                    <Typography align='left' variant="body2">
                        I want to add some more items to my budget.
                    </Typography>
                    <Radio
                        checked={formData.addMoreRadio === 'continue'}
                        onChange={handleChange}
                        value="continue"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'Continue' }}
                    />
                </Box>
            </Box>
            </Box>
        </Box>
    )

}

const SuppliesInput = ({formData})=>{

    const mainService = useContext(MainContext);

    const budgetSectionId = formData.budgetSummary?.sections && formData.budgetSections ? 
        formData.budgetSummary.sections.find(el => formData.budgetSections[el].name === 'Supplies & materials') : null;
    const budgetSection = budgetSectionId ? formData.budgetSections[budgetSectionId] : null;
    const sectionLines = budgetSection ? budgetSection?.lines : null;

    return(
        <Box sx={{flexGrow:1, height:'100%',  display:'flex', maxWidth:'600px',alignItems:'center', flexDirection:'column',}}>

            <Box sx={{height:'10%'}}></Box>
            <Box sx={{maxWidth:'510px',mx:'16px',mb:'48px'}}>
            <Typography variant='h3' gutterBottom align='left'>Do you need any more project supplies?</Typography>
            <Typography align='left' variant="body2">
                Add any other supplies you think you might need to carry out your project, this could range from ipads to notepads. If you're not sure right now or don't need anything else you can skip this by clicking continue. You can always add more later. 
            </Typography>
            </Box>   


            <Box sx={{my:'16px', display:'flex', flexDirection:'column', alignItems:'stretch', gap:'16px',width:'510px'}}>

               {sectionLines &&
               (<React.Fragment>
                <Box sx={{height:'150px',position:'relative',overflow:'hidden'}} >
                <Box sx={{position:'absolute',width:'100%',height:'100%',overflow:'auto'}}>
               <Table stickyHeader>
                <TableHead>
                <TableRow>
                    <UnStyledTableCell align='left'>
                        <Typography variant="h6">Item</Typography>
                    </UnStyledTableCell>
                    <UnStyledTableCell align='left'>
                        <Typography variant="h6">Quantity</Typography>
                    </UnStyledTableCell>
                    <UnStyledTableCell align='left' >
                        <Typography variant="h6">Price</Typography>
                    </UnStyledTableCell>
                    <UnStyledTableCell colSpan={2} align='left' >
                        <Typography variant="h6">Unit</Typography>
                    </UnStyledTableCell>
                </TableRow>
                </TableHead>
                {sectionLines.map((id,i)=>(<BudgetLinePicker key={id} disabled = {i==0} formData={formData} budgetLineId={id} budgetSectionId={budgetSectionId}/>))}
                </Table>
                </Box>
                </Box>
                </React.Fragment>)}
                <Box sx={{width:'100%',display:'flex',flexDirection:'column', alignItems:'center', mt:'12px'}}>
                    <StyledSquareButton
                        onClick={()=>mainService.applicationService.addBudgetLine(formData.appId,formData.budgetSections,budgetSectionId,formData?.budgetSummary?.currency ?? 'USD')}
                    >
                        <AddIcon/>
                    </StyledSquareButton>
                    <Typography variant='body2'>
                        Add new line
                    </Typography>
                </Box>

            </Box>
        </Box>
    )
}

const ProDevInput = ({formData})=>{

    const mainService = useContext(MainContext);
    const budgetSectionId = formData.budgetSummary?.sections && formData.budgetSections ? 
        formData.budgetSummary.sections.find(el => formData.budgetSections[el].name === 'Professional Development') : null;
    const sectionLines = budgetSectionId ? formData.budgetSections[budgetSectionId].lines : null;

    return(
        <Box sx={{flexGrow:1, height:'100%',  display:'flex', maxWidth:'600px',alignItems:'center', flexDirection:'column',}}>
        <Box sx={{height:'10%'}}></Box>

        <Box sx={{maxWidth:'510px',mx:'16px',mb:'28px'}}>
        <Typography variant='h3' gutterBottom align='left'>Do you want to add any professional development costs?</Typography>
        <Typography align='left' variant="body2">
            Some product providers provide professional development courses to prepare teachers to deliver their projects. If you want to add any professional development include it here, or just skip if it doesn't apply. If you're in doubt get in touch with your product provider.
        </Typography>
        </Box>  
        <Box sx={{mb:'32px'}}>
        <Typography variant='body2' gutterBottom align='left'>For example:</Typography>
        <Table sx={{width:'510px'}}>
            <TableRow>
                <UnStyledTableCell align='left'>
                    <Typography variant="h6" sx={{color:Theme.palette.grey[600]}}>Item</Typography>
                </UnStyledTableCell>
                <UnStyledTableCell align='left'>
                    <Typography variant="h6" sx={{color:Theme.palette.grey[600]}}>Description</Typography>
                </UnStyledTableCell>
                <UnStyledTableCell align='left' >
                    <Typography variant="h6"sx={{color:Theme.palette.grey[600]}}>Total</Typography>
                </UnStyledTableCell>
            </TableRow>
            <TableRow>
                <UnStyledTableCell align='left'>
                    <Typography variant="body2">CS education training course</Typography>
                </UnStyledTableCell>
                <UnStyledTableCell align='left'>
                    <Typography variant="body2">2 x $150/course</Typography>
                </UnStyledTableCell>
                <UnStyledTableCell align='left' >
                    <Typography variant="body2">$300</Typography>
                </UnStyledTableCell>
            </TableRow>
        </Table>
        </Box>
        
        <Box sx={{my:'16px', display:'flex', flexDirection:'column', alignItems:'stretch', gap:'16px',width:'510px'}}>
            {sectionLines &&  
            (<React.Fragment>
            
            <Box sx={{height:'150px',position:'relative',overflow:'hidden'}} >
            <Box sx={{position:'absolute',width:'100%',height:'100%',overflow:'auto'}}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <UnStyledTableCell align='left'>
                            <Typography variant="h6">Item</Typography>
                        </UnStyledTableCell>
                        <UnStyledTableCell align='left'>
                            <Typography variant="h6">Quantity</Typography>
                        </UnStyledTableCell>
                        <UnStyledTableCell align='left' >
                            <Typography variant="h6">Price</Typography>
                        </UnStyledTableCell>
                        <UnStyledTableCell colSpan={2} align='left' >
                            <Typography variant="h6">Unit</Typography>
                        </UnStyledTableCell>
                    </TableRow>
                </TableHead>
            {sectionLines.map((id)=>(
                <BudgetLinePicker key={id} formData={formData} budgetLineId={id} budgetSectionId={budgetSectionId}/>
            ))}  
            </Table>   
            </Box> 
            </Box>
            </React.Fragment>)}              
            <Box sx={{width:'100%',display:'flex',flexDirection:'column', alignItems:'center', mt:'8px',gap:'8px'}}>
                <StyledSquareButton
                    onClick={()=>mainService.applicationService.addBudgetLine(formData.appId,formData.budgetSections,budgetSectionId,formData?.budgetSummary?.currency ?? 'USD','Professional Development')}
                >
                    <AddIcon/>
                </StyledSquareButton>
                <Typography variant='body2'>
                    Add new line
                </Typography>
            </Box>
        </Box>
        </Box>
    )
}

const SalaryInput = ({formData})=>{

    const mainService = useContext(MainContext);
    const budgetSectionId = formData.budgetSummary?.sections && formData.budgetSections ? 
        formData.budgetSummary.sections.find(el => formData.budgetSections[el].name === 'Salary & Wages') : null;
    const sectionLines = budgetSectionId ? formData.budgetSections[budgetSectionId].lines : null;

    return(
    <Box sx={{flexGrow:1, height:'100%',  display:'flex', maxWidth:'600px',alignItems:'center', flexDirection:'column',}}>

        <Box sx={{height:'10%'}}></Box>

        <Box sx={{maxWidth:'510px',mx:'16px',mb:'48px'}}>
        <Typography variant='h3' gutterBottom align='left'>Do you need to cover any extra wages or salary?</Typography>
        <Typography align='left' variant="body2">
            If you need to add any extra salary costs add them here. If you don't know yet you can always edit your budget later.
        </Typography>
        </Box>  

        <Box sx={{mb:'32px'}}>
        <Typography variant='body2' gutterBottom align='left'>For example:</Typography>
        <Table sx={{width:'510px'}}>
            <TableRow>
                <UnStyledTableCell align='left'>
                    <Typography variant="h6"  sx={{color:Theme.palette.grey[600]}}>Item</Typography>
                </UnStyledTableCell>
                <UnStyledTableCell align='left'>
                    <Typography variant="h6"  sx={{color:Theme.palette.grey[600]}}>Description</Typography>
                </UnStyledTableCell>
                <UnStyledTableCell align='left' >
                    <Typography variant="h6"  sx={{color:Theme.palette.grey[600]}}>Total</Typography>
                </UnStyledTableCell>
            </TableRow>
            <TableRow>
                <UnStyledTableCell align='left'>
                    <Typography variant="body2">Extra teaching aide</Typography>
                </UnStyledTableCell>
                <UnStyledTableCell align='left'>
                    <Typography variant="body2">30 x $150/day</Typography>
                </UnStyledTableCell>
                <UnStyledTableCell align='left' >
                    <Typography variant="body2">$4,500</Typography>
                </UnStyledTableCell>
            </TableRow>
        </Table>
        </Box>

        <Box sx={{my:'16px', display:'flex', flexDirection:'column', alignItems:'stretch', gap:'16px',width:'510px'}}>
            {(sectionLines && 
            <React.Fragment>
                <Box sx={{height:'150px',position:'relative',overflow:'hidden'}} >
                <Box sx={{position:'absolute',width:'100%',height:'100%',overflow:'auto'}}>
               <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <UnStyledTableCell align='left'>
                            <Typography variant="h6">Item</Typography>
                        </UnStyledTableCell>
                        <UnStyledTableCell align='left'>
                            <Typography variant="h6">Quantity</Typography>
                        </UnStyledTableCell>
                        <UnStyledTableCell align='left' >
                            <Typography variant="h6">Price</Typography>
                        </UnStyledTableCell>
                        <UnStyledTableCell colSpan={2} align='left' >
                            <Typography variant="h6">Rate</Typography>
                        </UnStyledTableCell>
                    </TableRow>
                </TableHead>
            {sectionLines && sectionLines.map((id)=>(
                <BudgetLinePicker key={id} formData={formData} budgetLineId={id} budgetSectionId={budgetSectionId}/>
            ))}  
            </Table>
            </Box>
            </Box>
            </React.Fragment>)}               
            <Box sx={{width:'100%',display:'flex',flexDirection:'column', alignItems:'center', mt:'12px',gap:'8px'}}>
                <StyledSquareButton
                    onClick={()=>mainService.applicationService.addBudgetLine(formData.appId,formData.budgetSections,budgetSectionId,formData?.budgetSummary?.currency ?? 'USD','Salary & Wages')}
                >
                    <AddIcon/>
                </StyledSquareButton>
                <Typography variant='body2'>
                    Add new line
                </Typography>
            </Box>
        </Box>
    </Box>
    )
}

const OtherInput = ({formData})=>{
    
    const mainService = useContext(MainContext);
    const budgetSectionId = formData.budgetSummary?.sections && formData.budgetSections ? 
        formData.budgetSummary.sections.find(el => formData.budgetSections[el].name === 'Other operating costs') : null;
    const sectionLines = budgetSectionId ? formData.budgetSections[budgetSectionId].lines : null;

    return(
        <Box sx={{flexGrow:1, height:'100%',  display:'flex', maxWidth:'600px',alignItems:'center', flexDirection:'column',}}>

        <Box sx={{height:'10%'}}></Box>

        <Box sx={{maxWidth:'510px',mx:'16px',mb:'48px'}}>
            <Typography variant='h3' gutterBottom align='left'>Are there any additional costs you want to add?</Typography>
            <Typography align='left' variant="body2">
                Almost done! Add any extra costs you think you might need to cover here. If you don't need anything skip this part.
            </Typography>
        </Box>  

        <Box sx={{mb:'32px'}}>
        <Typography variant='body2' gutterBottom align='left'>For example:</Typography>
        <Table sx={{width:'510px'}}>
            <TableRow>
                <UnStyledTableCell align='left'>
                    <Typography variant="h6" sx={{color:Theme.palette.grey[600]}}>Item</Typography>
                </UnStyledTableCell>
                <UnStyledTableCell align='left'>
                    <Typography variant="h6" sx={{color:Theme.palette.grey[600]}}>Description</Typography>
                </UnStyledTableCell>
                <UnStyledTableCell align='left' >
                    <Typography variant="h6"sx={{color:Theme.palette.grey[600]}}>Total</Typography>
                </UnStyledTableCell>
            </TableRow>
            <TableRow>
                <UnStyledTableCell align='left'>
                    <Typography variant="body2">After school venue hire</Typography>
                </UnStyledTableCell>
                <UnStyledTableCell align='left'>
                    <Typography variant="body2">25 x $100/evening</Typography>
                </UnStyledTableCell>
                <UnStyledTableCell align='left' >
                    <Typography variant="body2">$2,500</Typography>
                </UnStyledTableCell>
            </TableRow>
        </Table>
        </Box>

        <Box sx={{my:'16px', display:'flex', flexDirection:'column', alignItems:'stretch', gap:'16px',width:'510px'}}>
        {(sectionLines && 
            <React.Fragment>
                <Box sx={{height:'150px',position:'relative',overflow:'hidden'}} >
                <Box sx={{position:'absolute',width:'100%',height:'100%',overflow:'auto'}}>
               <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <UnStyledTableCell align='left'>
                            <Typography variant="h6">Item</Typography>
                        </UnStyledTableCell>
                        <UnStyledTableCell align='left'>
                            <Typography variant="h6">Quantity</Typography>
                        </UnStyledTableCell>
                        <UnStyledTableCell align='left' >
                            <Typography variant="h6">Price</Typography>
                        </UnStyledTableCell>
                        <UnStyledTableCell colSpan={2} align='left' >
                            <Typography variant="h6">Unit</Typography>
                        </UnStyledTableCell>
                    </TableRow>
                </TableHead>
            {sectionLines && sectionLines.map((id)=>(
                <BudgetLinePicker key={id} formData={formData} budgetLineId={id} budgetSectionId={budgetSectionId}/>
            ))}   
            </Table> 
            </Box>
            </Box>
            </React.Fragment>)}               
            <Box sx={{width:'100%',display:'flex',flexDirection:'column', alignItems:'center', mt:'12px',gap:'8px'}}>
                <StyledSquareButton
                    onClick={()=>mainService.applicationService.addBudgetLine(formData.appId,formData.budgetSections,budgetSectionId,formData?.budgetSummary?.currency ?? 'USD','Other operating costs')}
                >
                    <AddIcon/>
                </StyledSquareButton>
                <Typography variant='body2'>
                    Add new line
                </Typography>
            </Box>
        </Box>

        </Box>
    )
}




const Finish = ({formData})=>{

    const mainService = useContext(MainContext);
   
    return(
    <React.Fragment>
        <Box sx={{ height:'100%', flexGrow:1, display:'flex', alignItems:'center', flexDirection:'column'}}>

            <Box sx={{height:'10%'}}></Box>

            <Typography gutterBottom align='center' variant="h2">All Done!</Typography>
            <Box sx={{my:'16px', display:'flex', flexDirection:'column', alignItems:'center'}}>
                <Typography align='center' variant="h4">{`Click continue to view you grant application and budget, simple!`}</Typography>                    
            </Box>

        </Box>
    </React.Fragment>
    )
}

const BudgetGeneratorForm = ({userObject, applicationMetadata, appId, budgetSummary, budgetSections, budgetLines, productData, onFinish}) => {
    
    const mainService = useContext(MainContext);

    const [addMoreRadio,setAddMoreRadio] = useState('done');

    const createInitialBudget = async () => {
        return mainService.applicationService.createInitialBudget(appId,applicationMetadata,productData);
    } 

    //EFFECTS 
    useEffect(() => {
        console.log('Budget mounted:' + appId); 

        if(!applicationMetadata.budgetStage || applicationMetadata.budgetStage == 'notStarted'){
            console.log('creating budget')
            workQueue.push(createInitialBudget)
        }

	}, [appId]);

    
    /*const handleFinish = ()=>{
        mainService.applicationService.setBudgetStage(appId,'created');
    }*/

    //RENDER 
    const formData = {
        
        startNode: 'baselineNode',

        userObject,

        applicationMetadata,

        appId,

        budgetLines,

        budgetSections,

        budgetSummary,

        addMoreRadio,

        onRadioChange: (v) => setAddMoreRadio(v),

        nodes:{
            /*startNode:{
                component:Start,
                next:()=>'baselineNode',
            },*/
            baselineNode: {
                component:BaseLineBudget,
                next:()=> addMoreRadio == 'done' ? 'finishNode' : 'suppliesNode'
            },
            /*addMoreItemsNode:{
                component:AddMoreItems,
                next:()=> addMoreRadio == 'done' ? 'finishNode' : 'suppliesNode'
            },*/
            suppliesNode:{
                component:SuppliesInput,
                next:()=>'proDevNode',
            },
            proDevNode:{
                component:ProDevInput,
                next:()=>'salaryNode',
            },
            salaryNode:{
                component:SalaryInput,
                next:()=>'otherNode',
            },
            otherNode:{
                component:OtherInput,
                next:()=>'finishNode',
            },
            finishNode:{
                component:Finish,
                end:true,
            },
        }
    }

    return (
        <Paper sx={{flexGrow:1, overflowX:"hidden",position:'relative', display:'flex', flexDirection:'column', mb:'32px', mx:'32px', maxWidth:'1200px'}}>
            <FormFlow onFinish={onFinish} formData={formData}/>
        </Paper>
    )
}

export default BudgetGeneratorForm; 