import { Box, IconButton, Link, Tooltip } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CopyLink = ({link,onCopy,sx}) => {
    const handleCopyToClipboard = ()=> {
        navigator.clipboard.writeText(link);
        if(onCopy)
            onCopy();
    }
    return (
        <Box sx={{display:'flex',gap:'8px',alignItems:'center',...sx}}>
            <Link>{link}</Link>
            <Tooltip title='Copy link'>
            <IconButton size="small" onClick={handleCopyToClipboard}>
                <ContentCopyIcon />
            </IconButton>
            </Tooltip>
        </Box>
    )
}

export default CopyLink;