import React, {useState, useEffect, useContext } from "react";

import MainService from "../../MainService"; 
import MainContext from "../../MainContext"; 

import { ThemeProvider } from  '@mui/system';
import { Box, Typography, ButtonBase, } from "@mui/material";
import FormTitle from "../../components/formInputs/FormTitle";

import Theme from '../../Theme'; 

const UserTypeInput = ({formData, setDisableContinueButton})=>{
    
    const mainService = useContext(MainContext);
    
    const handleUserTypeChange = (e, val)=>{
        mainService.profileService.setUserType(val); 
    }

    let userObject = formData.userObject; 
    let isTeacher = userObject && userObject.type != 'thirdparty'; 
    let isThirdParty = userObject && userObject.type == 'thirdparty'; 

    useEffect(()=>{
        let disableButton = !(formData && formData.userObject && formData.userObject.type); 
        if(setDisableContinueButton)
            setDisableContinueButton(disableButton); 
    }, [formData]);

    return(
    <React.Fragment>
    
        <Box sx={{ height:'100%', display:'flex', alignItems:'stretch', flexDirection:'column',}}>
            <Box sx={{height:'20%'}}></Box>
            
            <FormTitle text='Are you a...'/>
            <Box sx={{display:'flex', alignItems:'center' }}>
                
                <ButtonBase sx={{
                    position:'relative',
                    mr:'16px',
                    borderStyle:"solid", 
                    borderRadius:"5px", 
                    borderWidth:"1px", 
                    borderColor:Theme.palette.grey[400],
                    "&:hover": {borderColor: 'rgb(0,0,0)'}, 
                    width:'300px', 
                    height:'260px', 
                    display:'flex',
                }} onClick={(e)=>handleUserTypeChange(e, 'teacher')}
                >
                    
                    <Box sx={{position:'absolute', width:'100%', height:'100%', flexDirection:"column",display:'flex', justifyContent:'center', alignItems:'center', borderRadius:"5px", backgroundColor: isTeacher ? '#F3F4F6' : 'default',}}>
                        <Box sx={{width:"190px",height:"190px"}}>
                            <img style={{width:"100%",height:"100%"}} src="/assets/teacher.png"/>
                        </Box>
                        <Typography variant="h6">Teacher</Typography>
                    </Box>

                </ButtonBase>

                <Typography align='center' variant="body">a</Typography>

                <ButtonBase sx={{
                    position:'relative',
                    ml:'16px',
                    borderStyle:"solid", 
                    borderRadius:"5px", 
                    borderWidth:"1px", 
                    borderColor:Theme.palette.grey[400],
                    "&:hover": {borderColor: 'rgb(0,0,0)'},
                    width:'300px', 
                    height:'260px', 
                    display:'flex',
                }} onClick={(e)=>handleUserTypeChange(e, 'thirdparty')}>

                    <Box sx={{position:'absolute', width:'100%', height:'100%', flexDirection:"column", display:'flex', justifyContent:'center', alignItems:'center', borderRadius:"5px", backgroundColor: isThirdParty ? '#F3F4F6' : 'default',}}>
                        <Box sx={{width:"190px",height:"190px"}}>
                            <img style={{width:"100%",height:"100%"}} src="/assets/programmer.png"/>
                        </Box>
                        <Typography variant="h6">Product Provider</Typography>
                    </Box>

                </ButtonBase>

            </Box>

        </Box>
    </React.Fragment>
    )
}

export default UserTypeInput; 