import { IconButton, Tooltip } from "@mui/material";

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CopyButton = ({onCopy,text,copyText}) => {
    const handleCopyToClipboard = ()=> {
        navigator.clipboard.writeText(text);
        if(onCopy)
            onCopy();
    }
    return (
        <Tooltip title={copyText}>
        <IconButton size="small" onClick={handleCopyToClipboard}>
            <ContentCopyIcon />
        </IconButton>
        </Tooltip>
    )
}

export default CopyButton;