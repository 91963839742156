import React, {useState, useEffect, useContext } from "react";
import MainContext from "../../MainContext"; 

import {Box,Tab,Tabs,Typography,ButtonBase,CircularProgress,IconButton, Snackbar, TextField} from '@mui/material';
import Paper from "../../components/Paper";
import Theme from "../../Theme";
import fadein from "../../AnimationKeyframes/FadeIn";
import StyledButton from "../../components/StyledButton";
import StyledSquareButton from "../../components/StyledSquareButton";
import AnimatedTypography from "../../components/AnimatedTypography";
import { WorkQueue } from "../../Util";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ItemMenu from "../../components/ItemMenu";
import FundingRecommender from "./FundingRecommender";
import ApplicationView from "./ApplicationView";
import FundingDashboard from "./FundingDashboard";
import MyFunding from "./MyFunding";
import AutoTextFlow from "../../components/AutoTextFlow";
import MuiAlert from '@mui/material/Alert';
import Budget from "./Budget";
import AddIcon from '@mui/icons-material/Add';
import ResponsiveDialog from "../../components/ResponsiveDialog";
import Campaign from "./Campaign";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const workQueue = new WorkQueue(); 

const SectionMenuItem = ({sectionName,onSelect,selected,onDelete}) => {
    //States
    const [itemMenuAnchorEl, setItemMenuAnchorEl] = useState(null);
    const [itemMenuOpen, setItemMenuOpen] = useState(false);

    //HANDLERS
    const handleThreeDotsClick = (e)=>{
        setItemMenuAnchorEl(e.currentTarget);
        setItemMenuOpen(true);
    }

    const handleThreeDotsClose = (e)=>{
        setItemMenuOpen(false);
    }

    return (
        <Box sx={{display:'flex',flexDirection:'row'}}>
            <ButtonBase 
                sx={{position:'relative', display:'flex', flexGrow:1, alignItems:'center', height:'40px', justifyContent:'flex-start', 
                    backgroundColor: selected ? 'rgba(187, 229, 213, 0.7)' : 'default',
                    '&:hover':{backgroundColor:'rgba(187, 229, 213, 0.7)'},
                    p:'16px'
                }}
                
                onClick={onSelect}
            >
                {selected && <Box sx={{width:'5px', height:'100%', position:'absolute', left:0, top:0, backgroundColor:Theme.palette.primary.main}}></Box>}
                <Typography variant="body2">{sectionName}</Typography>
            </ButtonBase>
            <IconButton onClick={handleThreeDotsClick}>
                <MoreVertIcon sx={{color:'#5f748d'}}/>
            </IconButton>
            <ItemMenu 
                open={itemMenuOpen} 
                anchorEl={itemMenuAnchorEl}
                handleClose={handleThreeDotsClose}
                onDelete={onDelete}
            />
        </Box>
    )
}

const Start = ({animation}) => {
    return (
        (<Typography sx={{animation}} variant='h1'>Sit tight while we get things ready for you...</Typography>)
    )
}

const End = ({}) => {
    return(
        <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
            <CircularProgress size='100px' sx={{mb:'48px'}}/>
            <AnimatedTypography 
                texts={[
                    'Checking your details.', 
                    'Analysing the product.', 
                    'Processing.', 
                    'Thinking.',
                    'Writing your application.', 
                    'Refining.', 
                ]}
            />
        </Box>
    )
}

const ApplicationLoading = ({playing,onFinish}) => {

    //RENDER 
    const data = [
        {},
        {component:Start,},
        {component:End}
    ]

    return (
        <Paper 
            sx={{
                flexGrow:1, 
                display:'flex', 
                flexDirection:'column', 
                justifyContent:'center', 
                alignItems:'center', 
                backgroundColor:'#FFFFFF', 
                position:'relative',
            }}
        >
            <AutoTextFlow data={data} playing={playing} iterations={1} autostop={false} onFinish={()=>{onFinish()}}/>
        </Paper>
    )

}


const ApplicationMain = ({userObject, applicationMetadata, appId, productData, budgetSummary, budgetSections, budgetLines, tabValue, onTabChange})=>{
    const mainService = useContext(MainContext);

    //const [tabValue, setTabValue] = useState(0);
    const [playingFlow, setPlayingFlow] = useState(false);
    const [alertOpen,setAlertOpen] = useState(false);
    const [alertMsg,setAlertMsg] = useState('');
    const [alertSeverity,setAlertSeverity] = useState('success');

    const handleSetAlert = (msg,severity="success") => {
        if(msg){
            setAlertMsg(msg);
            setAlertSeverity(severity);
            setAlertOpen(true);
        }
    }

    const handleChange = (event, newValue) => {
        onTabChange(newValue);
    };

    const getRecommendations = async ()=>{
        setPlayingFlow(true);
        let rs = await mainService.applicationService.getFundingRecommendation(userObject, applicationMetadata); 
        if(Array.isArray(rs)){
            let recObjs = [];
            rs.forEach((r, inx)=>{
                recObjs.push({selected:false, data:r, stage:1});
            });

            if(recObjs.length == 0){
                recObjs = 'NORES'; 
            }

            //This is hack until we improve grant search and rank
            let recsSlice = recObjs.slice(0,2);

            mainService.applicationService.setFundingRecommendation(appId, recsSlice); 
            console.log('getRecommendations DONE');
        }
        await new Promise(resolve => setTimeout(()=>resolve(), 21000));
        setPlayingFlow(false);
    }

    useEffect(() => {
		//on mount
		console.log('ApplicationMain mounted');

        console.log(tabValue);

        //get recommendations
        if(applicationMetadata && !applicationMetadata.fundingRecommendations ){
            console.log('getting recommendations');           
            getRecommendations();
            
        }

        //create application content and sections
        /*if(applicationMetadata && !applicationMetadata.appCreated) {
            console.log('creating app');
            workQueue.push(createApplication)
        }*/

		//on unmount
		return ()=>{
			console.log('ApplicationMain unmounted');
		}

	}, [appId]);

    return (
        <Box sx={{display:'flex', flexGrow:1, flexDirection:'row', justifyContent:'center',}}>
        <Box sx={{display:'flex', flexGrow:1, maxWidth:'1200px', flexDirection:'column',mx:'32px', mt:'16px', mb: tabValue === 0 && !playingFlow ? 0 :'16px', overflow:'hidden'}}>


            {playingFlow && <ApplicationLoading playing={playingFlow} onFinish={()=>{/*setPlayingFlow(false)*/}}/>}

                
            {(applicationMetadata?.fundingRecommendations && applicationMetadata?.appCreated && !playingFlow) && (
                <React.Fragment>
                    {tabValue === 0 &&
                        <ApplicationView appId={appId} productData={productData} onAlert={handleSetAlert}/>                        
                    }
                    {tabValue === 1 &&
                        <Budget 
                            productData={productData} 
                            userObject={userObject} 
                            applicationMetadata={applicationMetadata} 
                            budgetSummary={budgetSummary} 
                            budgetSections={budgetSections} 
                            budgetLines={budgetLines} 
                            appId={appId} 
                            onAlert={handleSetAlert}
                        />
                    }
                    {tabValue=== 2 &&
                        <MyFunding
                            productData={productData} 
                            userObject={userObject} 
                            applicationMetadata={applicationMetadata} 
                            budgetSummary={budgetSummary} 
                            budgetSections={budgetSections} 
                            budgetLines={budgetLines} 
                            appId={appId} 
                            onAlert={handleSetAlert}
                        />
                    }
                    {tabValue=== 3 && 
                        <Campaign
                            appId={appId} 
                            onAlert={handleSetAlert}
                        />
                    }
                </React.Fragment>
            )}
            
        </Box>
        <Snackbar open={alertOpen} autoHideDuration={3000} onClose={()=>{setAlertOpen(false)}}>       
            <Alert onClose={()=>{setAlertOpen(false)}} severity={alertSeverity} sx={{ width: '100%' }}>        
                {alertMsg}           
            </Alert>       
        </Snackbar>
        </Box>
    )
}

export default ApplicationMain; 