import React, {useState, useEffect, useContext } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import useQuery from "../useQuery";

import MainService from "../MainService"; 
import MainContext from "../MainContext"; 

import isEmail from 'validator/lib/isEmail';

import CircularProgress from '@mui/material/CircularProgress';
import { Box, Button, DialogActions, DialogContent, DialogTitle, getDialogContentTextUtilityClass, InputBase, Typography } from "@mui/material";

import ResponsiveDialog from "../components/ResponsiveDialog";

const EmailLogin = (props) => {
    //Use
	const mainService = useContext(MainContext);
	const navigate = useNavigate(); 
    let query = useQuery();

    const [email,setEmail] = useState(window.localStorage.getItem('emailForSignIn'));
    const [textValue,setTextvalue] = useState("");
    const [inputIsEmail,setInputIsEmail] = useState(false);
    const [dialogOpen,setDialogOpen] = useState(false);
    const [dialogContent,setDialogContent] = useState("email");
    const [errorMessage,setErrorMessage] = useState("");

    const handleSetTextValue = (value)=>{
        if(isEmail(value)){
            setInputIsEmail(true);
        }
        setTextvalue(value);
    }

	//Effects
	useEffect(() => {
		//on mount
        //check is link - if not navigate home, which will redirect to login if not authed
        if(!mainService.checkIsSigninWithEmailLink())
            navigate(`/${`?${query.toString()}`}`);

        //listen for valid email
        if(email) {
            console.log("handling email sign in");
            mainService.emailLinkSigninUser(email)
            .then(()=>{
                query.delete("apiKey");
                query.delete("oobCode");
                query.delete("mode");
                query.delete("lang");
                navigate(`/${`?${query.toString()}`}`);
            })
            .catch((error)=>{
                //dialog?
                console.error(error);
                setErrorMessage(error.message);
                setDialogContent("error");
                setDialogOpen(true);    
            });
        }
        else{
            setDialogContent("email");
            setDialogOpen(true);           
        }
		//on unmount
		return ()=>{

		}

	}, [email]);

    const renderDialogContent = ()=>{
        let content = null;
        if(dialogContent==="email"){
            content = (
                <React.Fragment>
                    <DialogTitle>Please confirm your email</DialogTitle>
                    <DialogContent>
                    <InputBase 
                        value={textValue}
                        onChange={(e)=>{handleSetTextValue(e.target.value)}}
                        placeholder="Enter your email address"
                        type="email"
                        sx={{                       
                            borderStyle:"solid",
                            borderRadius:"5px",
                            height:"36px",
                            width:"300px",
                            p:"4px 10px",
                            borderColor: inputIsEmail? "#4BB543":"#808080",
                            borderWidth:"1px",
                            "&:hover": {
                                borderColor: inputIsEmail? '#3c9136':'rgb(0,0,0)'
                            },
                        }}
                    />  
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={!inputIsEmail} onClick={()=>{setEmail(textValue);setDialogOpen(false)}} size="small" variant="outlined">
                            Confirm
                        </Button>
                    </DialogActions>
                </React.Fragment>
            );
        }

        else if(dialogContent==="error"){
            content =  (
                <React.Fragment>
                    <DialogTitle>An error occurred</DialogTitle>
                    <DialogContent>
                        {errorMessage}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>{setDialogOpen(false); navigate("/home")}} size="small" variant="outlined">
                            OK
                        </Button>
                    </DialogActions>
                </React.Fragment>
            );
        }
        
        return content;
            
    }

    return(
        <React.Fragment>
        <Box sx={{width:"100%",height:"100%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
            <Typography variant="h6" sx={{mb:"8px"}}>Verifying email login</Typography>
            <CircularProgress sx={{}}/>
        </Box>
        <ResponsiveDialog open = {dialogOpen} >
            {renderDialogContent()}
        </ResponsiveDialog>
        </React.Fragment>
    );
}

export default EmailLogin;