//Inspired by: https://medium.com/@650egor/simple-drag-and-drop-file-upload-in-react-2cb409d88929
import React, {useState, useEffect, useContext, useRef} from "react";

import theme from '../Theme';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PublishIcon from '@mui/icons-material/Publish';
import ButtonBase from '@mui/material/ButtonBase';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const FileDragAndDrop = (props) => {

    //STATES

    const [drag,setDrag] = useState(false);
    const [alertOpen,setAlertOpen] = useState(false);
    const [alertMessage,setAlertMessage] = useState("");
    const [fileList,setFileList] = useState([]);

    let dragCounter = 0;
    let dropRef = useRef(null);
    let fileInput = null;

    //HANDLERS
 
    const handleDrag =(e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const handleDragIn = (e) => {
        //console.log("dragin");
        e.preventDefault();
        e.stopPropagation();
        dragCounter++;
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDrag(true);
        }
    }
    const handleDragOut = (e) => {
        //console.log("dragout");
        e.preventDefault();
        e.stopPropagation();
        dragCounter--;
        if (dragCounter === 0) {
            setDrag(false);
        }
    }

    const handleDrop = (e) => {
        //console.log("drop");
        e.preventDefault();
        e.stopPropagation();
        setDrag(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            processFiles(e.dataTransfer.files); 
            e.dataTransfer.clearData();        
        }
        dragCounter = 0; 
    }

    const handleClick = (e) => {

        if(fileInput){
            fileInput.onchange = (e) => { 
                if (e.target.files && e.target.files.length > 0) {
                    processFiles(e.target.files); 
                }
            }
            fileInput.click(); 
        }
    }

    const processFiles = (fileListObj) => {
        //validate file data to check size of each file and count and add to list 
        let valid = true; 
        let partFileList = []; 

        if(props.filesLimit && fileListObj.length > props.filesLimit){
            valid = false; 
            setAlertMessage("too many files!");
            setAlertOpen(true);    
        }
        else{

            for(let i = 0; i < fileListObj.length; i++){
                    let file = fileListObj.item(i); 

                    if(props.maxFileSize && file.size > props.maxFileSize){
                        valid = false; 
                        setAlertMessage("file too big!");
                        setAlertOpen(true);    
                        break; 
                    }

                    if(props.acceptedFiles && Array.isArray(props.acceptedFiles) && !props.acceptedFiles.includes(file.type) ){
                        valid = false; 
                        setAlertMessage("file wrong type!");
                        setAlertOpen(true);  
                        break; 
                    }
                    
                    partFileList.push(file); 
            }
        }

        if(valid){

            let newFileList = [...fileList];

            for(let i = 0; i < partFileList.length; i++){
                newFileList.push(partFileList[i]); 
            }
            
            setFileList(newFileList);
            console.log("FILES ADDED");
            props.onChange(newFileList); 
        }
    }

    const removeFile = (file) => {
        let newFileList = [];
        for(let i = 0; i < fileList.length; i++){
            if(fileList[i] != file){
                newFileList.push(fileList[i]);
            }
        }        
        setFileList(newFileList);
    }

    //Effects
	useEffect(()=>{
		//on mount
        let box = dropRef.current
        box.addEventListener('dragenter', handleDragIn);
        box.addEventListener('dragleave', handleDragOut);
        box.addEventListener('dragover', handleDrag);
        box.addEventListener('drop', handleDrop);

        setFileList([]);
		//on unmount
		return ()=> {
            box.removeEventListener('dragenter', handleDragIn)
            box.removeEventListener('dragleave', handleDragOut)
            box.removeEventListener('dragover', handleDrag)
            box.removeEventListener('drop', handleDrop)
		}

	}, []);

    let sx = props.sx;
    if(typeof sx === 'undefined')
        sx = {}; 

    let dropzoneText = props.dropzoneText;
    if(typeof dropzoneText === 'undefined')
        dropzoneText = 'drop here, or click'; 

    return (
    <Box
        sx={{display: 'flex', position: 'relative', ...sx}}
        ref={dropRef}
    >
        <Box 
        sx={{position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0, 
            right: 0,
            border: 'dashed grey 2px',
            borderRadius: "4px",
            backgroundColor: drag ? 'rgba(180, 180, 180, .8)' : 'rgba(255,255,255,.8)',
            display: 'flex',
            flexDirection:'column',
        }}>
        <input id="fileInput" multiple type="file" ref={(ref) => fileInput = ref} style={{ display: 'none' }} />
        
        {fileList.length > 0 &&
            <Box sx={{flexGrow: 1, position:'relative'}}>
                <Box sx={{position:'absolute', width:'100%', height:'100%', overflowY:'auto'}}>
                    <Box sx={{ flexGrow: 1, display:'flex', flexDirection:'column'}}>
                    {fileList.map((file,i)=>(
                        <Box key={file.lastModified+i} sx={{display:'flex', alignItems:"center",justifyContent:'space-between', m:'4px',borderBottom:`1px solid ${theme.palette.grey[600]}`}}>
                            <Typography variant="body2">{file.name}</Typography>
                            <IconButton size="small" aria-label="delete" sx={{}} onClick={(e)=>{removeFile(file);} }>
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    ))}
                    </Box>
                </Box>
            </Box>	
        }	  
        {fileList.length < props.filesLimit && 
        < ButtonBase 
            sx={{
            textAlign: 'center',
            color: 'grey',
            fontSize: 36,
            display: 'flex', 
            flexDirection:'column',
            flexGrow: fileList.length == 0 ? 1 : 'default',
            }}
            onClick={handleClick}
        >
            <PublishIcon/>
            <Typography align = "center">{dropzoneText}</Typography>
        </ButtonBase>}
        </Box>
        
        <Snackbar open={alertOpen} autoHideDuration={3000} onClose={()=>{setAlertOpen(false)}}>       
            <Alert onClose={()=>{setAlertOpen(false)}} severity="error" sx={{ width: '100%' }}>        
                {alertMessage}           
            </Alert>       
        </Snackbar>

        {props.children}
    </Box>
)
  
}
export default FileDragAndDrop; 