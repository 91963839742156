import { 
    getMetadata,
    getBytes,
    getStorage,
    uploadBytes,
    deleteObject,
    getDownloadURL 
} from "firebase/storage";
import {ref as sRef} from "firebase/storage";

import { 
    getDatabase, 
    ref, 
    set,
    update, 
    get,
} from 'firebase/database';

import {
    getAuth, 
} from 'firebase/auth';

import { v4 as uuidv4 } from 'uuid';

import { WorkQueue } from "./Util";

export default class ProfileService {
    
    constructor(mainService){
        this.mainService = mainService; 
    }

    async setUserType(type){
        const db = getDatabase();
        const auth = getAuth(); 
        await set( ref(db, 'users/' + auth.currentUser.uid + '/type'), type);
    }

    async setUserAccountStarted(started){
        const db = getDatabase();
        const auth = getAuth(); 
        await set( ref(db, 'users/' + auth.currentUser.uid + '/accountstarted'), started);
    }

    async setUserFirstName(val){
        const db = getDatabase();
        const auth = getAuth(); 
        await set( ref(db, 'users/' + auth.currentUser.uid + '/firstname'), val);
    }

    async setUserLastName(val){
        const db = getDatabase();
        const auth = getAuth(); 
        await set( ref(db, 'users/' + auth.currentUser.uid + '/lastname'), val);
    }

    async setSchoolName(val){
        const db = getDatabase();
        const auth = getAuth(); 
        await set( ref(db, 'users/' + auth.currentUser.uid + '/schoolname'), val);
    }

    async setSchoolStreetAddress(val){
        const db = getDatabase();
        const auth = getAuth(); 
        await set( ref(db, 'users/' + auth.currentUser.uid + '/schoolstreetaddress'), val);
    }

    async setSchoolCity(val){
        const db = getDatabase();
        const auth = getAuth(); 
        await set( ref(db, 'users/' + auth.currentUser.uid + '/schoolcity'), val);
    }

    async setSchoolState(val){
        const db = getDatabase();
        const auth = getAuth(); 
        await set( ref(db, 'users/' + auth.currentUser.uid + '/schoolstate'), val);
    }

    async setSchoolZipCode(val){
        const db = getDatabase();
        const auth = getAuth(); 
        await set( ref(db, 'users/' + auth.currentUser.uid + '/schoolzipcode'), val);
    }

    async setSchoolType(val){
        const db = getDatabase();
        const auth = getAuth(); 
        await set( ref(db, 'users/' + auth.currentUser.uid + '/schooltype'), val);
    }

    async setSchoolGradeLow(val){
        const db = getDatabase();
        const auth = getAuth(); 
        await set( ref(db, 'users/' + auth.currentUser.uid + '/schoolgradelow'), val);
    }

    async setSchoolGradeHigh(val){
        const db = getDatabase();
        const auth = getAuth(); 
        await set( ref(db, 'users/' + auth.currentUser.uid + '/schoolgradehigh'), val);
    }

    async setSchoolStudentCount(val){
        const db = getDatabase();
        const auth = getAuth(); 
        await set( ref(db, 'users/' + auth.currentUser.uid + '/schoolstudentcount'), val);
    }

    async setSchoolTeacherCount(val){
        const db = getDatabase();
        const auth = getAuth(); 
        await set( ref(db, 'users/' + auth.currentUser.uid + '/schoolteachercount'), val);
    }

    async setSchoolIncomeDemo(val){
        const db = getDatabase();
        const auth = getAuth(); 
        await set( ref(db, 'users/' + auth.currentUser.uid + '/schoolincomedemo'), val);
    }

    async setPercentFreeLunch(val){
        const db = getDatabase();
        const auth = getAuth(); 
        await set( ref(db, 'users/' + auth.currentUser.uid + '/schoolpercentfreelunch'), val);
    }

    async setSchoolTitle1(val){
        const db = getDatabase();
        const auth = getAuth(); 
        await set( ref(db, 'users/' + auth.currentUser.uid + '/schooltitle1'), val);
    }

    async setSchoolLocale(val){
        const db = getDatabase();
        const auth = getAuth(); 
        await set( ref(db, 'users/' + auth.currentUser.uid + '/schoollocale'), val);
    }

    async setTeacherRole(val){
        const db = getDatabase();
        const auth = getAuth(); 
        await set( ref(db, 'users/' + auth.currentUser.uid + '/teacherrole'), val);
    }

    async setTeacherSubjects(val){
        const db = getDatabase();
        const auth = getAuth(); 
        await set( ref(db, 'users/' + auth.currentUser.uid + '/teachersubjects'), val);
    }

    async setTeacherGradeLow(val){
        const db = getDatabase();
        const auth = getAuth(); 
        await set( ref(db, 'users/' + auth.currentUser.uid + '/teachergradelow'), val);
    }

    async setTeacherGradeHigh(val){
        const db = getDatabase();
        const auth = getAuth(); 
        await set( ref(db, 'users/' + auth.currentUser.uid + '/teachergradehigh'), val);
    }

    async setOrgName(val){
        const db = getDatabase();
        const auth = getAuth(); 
        await set( ref(db, 'users/' + auth.currentUser.uid + '/orgname'), val);
    }

    async setOrgRole(val){
        const db = getDatabase();
        const auth = getAuth(); 
        await set( ref(db, 'users/' + auth.currentUser.uid + '/role'), val);
    }
    
    async setOrgWebsite(val){
        const db = getDatabase();
        const auth = getAuth(); 
        await set( ref(db, 'users/' + auth.currentUser.uid + '/orgwebsite'), val);
    }

    async setSchoolWebsite(val){
        const db = getDatabase();
        const auth = getAuth();
        await set( ref(db, 'users/' + auth.currentUser.uid + '/schoolwebsite'), val);
    }

    async setUserBio(val){
        const db = getDatabase();
        const auth = getAuth(); 
        await set( ref(db, 'users/' + auth.currentUser.uid + '/userbio'), val);
    }

    //methods for image uploads and downloads
    async uploadCustomProfilePic(userObject,imgFile){  
        console.log('uploading profile pic');     	
        const auth = getAuth(); 
        const db = getDatabase();
        const storage = getStorage();

        //Generate uuid 
		const key = uuidv4(); 
		const customMetadata = {key: key}; 

		let metadata = {customMetadata: customMetadata}; 
		metadata['contentType'] = imgFile.type;

		//Add file to storage
		try{      
            console.log('adding to storage');     
            const storageRef = sRef(storage,`userProfilePics/${auth.currentUser.uid}/${key}`); 

            //delete image if already exists otherwise does nothing
            await this.deleteCustomProfilePic(userObject);     

            const uploadTask = await uploadBytes(storageRef,imgFile, metadata);

            //once uploaded get download URL
            const url = await getDownloadURL(storageRef);
            console.log(url);
            const updates = {};
            updates[`users/${auth.currentUser.uid}/photoURL`]=url;
            updates[`users/${auth.currentUser.uid}/photoSource`]='custom';
            updates[`users/${auth.currentUser.uid}/photoKey`]=key;
            await update(ref(db),updates);
            console.log('updates done')
        }
        catch(error){
            throw error;
        }
    }  

    async deleteCustomProfilePic(userObject) {
        const db = getDatabase();
        const auth = getAuth(); 
        const storage = getStorage();
        const imgKey = userObject?.photoKey ?? null;
        try{
            if(imgKey){
                console.log('deleting old pic')
                //delete the img               
                const storageRef = sRef(storage, `userProfilePics/${auth.currentUser.uid}/${imgKey}`);
                await deleteObject(storageRef);
                const updates = {};
                updates[`users/${auth.currentUser.uid}/photoURL`]=null;
                updates[`users/${auth.currentUser.uid}/photoSource`]=null;
                updates[`users/${auth.currentUser.uid}/photoKey`]=null;
                await update(ref(db),updates);
            }
        }
        catch(error){
            throw error
        }
    }

    async uploadLogo(userObject,img){
        console.log('uploading logo');     	
        const auth = getAuth(); 
        const db = getDatabase();
        const storage = getStorage();

        //Generate uuid 
		const key = uuidv4(); 
		const customMetadata = {key: key}; 
		let metadata = {customMetadata: customMetadata}; 
        metadata['contentType'] = img.type;

 		//Add file to storage
		try{      
            console.log('adding to storage');     
            const storageRef = sRef(storage,`logos/${auth.currentUser.uid}/${key}`); 

            //delete image if already exists otherwise does nothing
            await this.deleteLogo(userObject);     
            const uploadTask = await uploadBytes(storageRef,img, metadata);

            //once uploaded get download URL
            const url = await getDownloadURL(storageRef);
            console.log(url);
            const updates = {};
            updates[`users/${auth.currentUser.uid}/logoURL`]=url;
            updates[`users/${auth.currentUser.uid}/logoKey`]=key;
            await update(ref(db),updates);
            console.log('updates done');
        }
        catch(error){
            throw error;
        }
    }

    async getLogoBytesAndMetadata(userObject){
        if(!userObject.logoKey)
            return null;
        
        const auth = getAuth(); 
        const key = userObject.logoKey;
        const storage = getStorage();
        //get file from storage
        try{
            console.log('getting file from storage');
            const storageRef = sRef(storage,`logos/${auth.currentUser.uid}/${key}`); 
            const imgFile =  getBytes(storageRef);
            const imgMetadata =  getMetadata(storageRef);
            const result = await Promise.all([imgFile,imgMetadata])
            return {file:result[0],metadata:result[1]};
        }
        catch(error){
            console.log('error getting file');
            throw error;
        }
    }

    async deleteLogo(userObject) {
        const db = getDatabase();
        const auth = getAuth(); 
        const storage = getStorage();
        const imgKey = userObject?.logoKey ?? null;
        try{
            if(imgKey){
                console.log('deleting old pic')
                //delete the img               
                const storageRef = sRef(storage, `logos/${auth.currentUser.uid}/${imgKey}`);
                await deleteObject(storageRef);
                const updates = {};
                updates[`users/${auth.currentUser.uid}/logoURL`]=null;
                updates[`users/${auth.currentUser.uid}/logoKey`]=null;
                await update(ref(db),updates);
            }
        }
        catch(error){
            throw error
        }
    }

    async uploadSchoolLogo(userObject,img){
        console.log('uploading logo');     	
        const auth = getAuth(); 
        const db = getDatabase();
        const storage = getStorage();

        //Generate uuid 
		const key = uuidv4(); 
		const customMetadata = {key: key}; 
		let metadata = {customMetadata: customMetadata}; 
        metadata['contentType'] = img.type;

 		//Add file to storage
		try{      
            console.log('adding to storage');     
            const storageRef = sRef(storage,`schoolLogos/${auth.currentUser.uid}/${key}`); 

            //delete image if already exists otherwise does nothing
            await this.deleteLogo(userObject);     
            const uploadTask = await uploadBytes(storageRef,img, metadata);

            //once uploaded get download URL
            const url = await getDownloadURL(storageRef);
            console.log(url);
            const updates = {};
            updates[`users/${auth.currentUser.uid}/schoolLogoURL`]=url;
            updates[`users/${auth.currentUser.uid}/schoolLogoKey`]=key;
            await update(ref(db),updates);
            console.log('updates done');
        }
        catch(error){
            throw error;
        }
    }

    async deleteSchoolLogo(userObject) {
        const db = getDatabase();
        const auth = getAuth(); 
        const storage = getStorage();
        const imgKey = userObject?.logoKey ?? null;
        try{
            if(imgKey){
                console.log('deleting old pic')
                //delete the img               
                const storageRef = sRef(storage, `schoolLogos/${auth.currentUser.uid}/${imgKey}`);
                await deleteObject(storageRef);
                const updates = {};
                updates[`users/${auth.currentUser.uid}/schoolLogoURL`]=null;
                updates[`users/${auth.currentUser.uid}/schoolLogoKey`]=null;
                await update(ref(db),updates);
            }
        }
        catch(error){
            throw error
        }
    }
}