import { Box, Typography, ButtonBase, Select,MenuItem} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import theme from '../../Theme';


const CustomPagination = ({onPageChange,onRowsPerPageChange,page,rowsPerPage,rowsLength}) => {

    //HANDLERS
    const handleChangePage = async (event,direction) => {
        onPageChange(event,direction);
    };

    const handleChangeRowsPerPage = async (event) => {
        onRowsPerPageChange(event);        
    };

    //RENDER


    return (
           
        <Box sx={{height:"50px",display:"flex",flexDirection:"row-reverse",alignItems:"center"}}>
            <ButtonBase onClick={(e)=>{handleChangePage(e,"forward")}} sx={{borderRadius:"50%",p:"4px",mr:theme.spacing(1)}}>
                <ArrowForwardIosIcon style={{height:"16px",width:"16px"}}/>
            </ButtonBase>
            <ButtonBase onClick={(e)=>{handleChangePage(e,"backward")}}  disabled={page===0} sx={{borderRadius:"50%",p:"4px",mr:theme.spacing(1),color:page===0 && theme.palette.grey[400]}}>
                <ArrowBackIosIcon style={{height:"16px",width:"16px"}}/>
            </ButtonBase>
            <Typography variant="body2" sx={{mr:theme.spacing(2)}}>{`${page===0?1:(rowsPerPage*page)+1}-${page===0?rowsLength:(rowsPerPage*page)+rowsLength}`}</Typography>
            <Select sx={{mr:theme.spacing(1),fontSize:"14px"}} value = {rowsPerPage} onChange={handleChangeRowsPerPage} variant="standard">
                <MenuItem  sx={{fontSize:"14px"}} value="10">10</MenuItem>
                <MenuItem sx={{fontSize:"14px"}} value="20">20</MenuItem>
                <MenuItem sx={{fontSize:"14px"}} value="30">30</MenuItem>
            </Select>
            <Typography variant="body2" sx={{mr:theme.spacing(1)}}>Rows per page:</Typography>               
        </Box>         
    )
}

export default CustomPagination; 