import {useState, useEffect, useContext,forwardRef } from "react";

import MainContext from '../../MainContext';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Skeleton from "@mui/material/Skeleton";
import Link from "@mui/material/Link";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Tooltip, { TextField } from "@mui/material";

import theme from "../../Theme"
import { CircularProgress, Typography, Select, MenuItem, InputBase, ButtonBase, Button} from "@mui/material";

import CustomPagination from "../components/CustomPagination";
import EnhancedTableHead from "../components/EnhancedTableHead";
import ProductListingEditor from "../../modules/productListings/ProductListingEditor";
import Paper from "../../components/Paper";

import { toDateString } from '../../Util';
import ResponsiveDialog from "../../components/ResponsiveDialog";

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddMember = ({onAdd})=>{

  const [email,setEmail] = useState('');
  const [access,setAccess] = useState('member');

  const handleAddMember = () => {
    if(onAdd)
      onAdd(email,access)
  }

  return(
    <Box sx={{p:'16px'}}>
    <Typography variant="h4">Add a member to an account</Typography>
    <Box sx={{display:'flex', alignItems:'center', gap:'8px', my:'16px'}}>

      <TextField variant='standard' sx={{flexGrow:1,minWidth:'200px'}} value={email} onChange={(e)=>setEmail(e.target.value)}/>
      <Select
        id="select-plan"
        value={access}
        variant="standard"
        onChange={(e)=>setAccess(e.target.value)}
      >        
        <MenuItem value={'admin'}>Admin</MenuItem>
        <MenuItem value={'member'}>Member</MenuItem>
      </Select>
    </Box>
    <Button variant='contained' fullWidth onClick={handleAddMember}>Add member</Button>
    </Box>
  )
}

const EditBilling = ({bid, open, onClose, ownerEmail}) => {

  const mainService = useContext(MainContext);

  const [alertOpen,setAlertOpen] = useState(false);
  const [alertMsg,setAlertMsg] = useState("");
  const [alertSeverity,setAlertSeverity] = useState("success");
  const [planData,setPlanData] = useState(null);
  const [membersData,setMembersData] = useState(null);
  const [dlgOpen,setDlgOpen] = useState(false);

  const handleClose = () => {
    onClose();
  }

  const handleDlgClose = () => {
    setDlgOpen(false);
  }

  const listenBillingPlan = (data) => {
    setPlanData(data);
  }

  const listenBillingMembers = (data) => {
    console.log(JSON.stringify(data))
    setMembersData(data);
  }

  const handleAddMember = (email,access) => {
      setDlgOpen(false);
      mainService.intellectibaseService.addBillingMember(bid,email,access);
  }

  useEffect(() => {
    //on mount
    if(bid){
        console.log('setting up billing data listeners'); 
        mainService.addDataListener('billingPlan/'+ bid, listenBillingPlan);
        mainService.addDataListener('billingMembers/'+ bid, listenBillingMembers); 
    }

    //on unmount
    return ()=>{
        if(bid){
            console.log('Removing billing data listeners');
            mainService.removeDataListener('billingPlan/'+ bid, listenBillingPlan);
            mainService.removeDataListener('billingMembers/'+ bid, listenBillingMembers);
        }
    }
}, [bid]);

  return (
    <>
    <ResponsiveDialog
      open={open}
      onClose={handleClose}
      maxWidth={'xl'}
    >
      <Box sx={{py:'28px',px:'32px'}}>
        <Typography variant="h3" sx={{mb:'16px'}}>Edit Product Provider Account</Typography>
        {planData && (
          <Box sx={{display:'flex',flexDirection:'column',gap:'8px'}}>
          <Typography variant="h4" sx={{mt:'8px'}}>Details</Typography>
          <InputBase value={planData?.name ?? 'No name'} onChange={(e)=>mainService.intellectibaseService.setBillingPlanName(bid,e.target.value)}/>
          <InputBase value={planData?.websiteurl ?? 'No website'} onChange={(e)=>mainService.intellectibaseService.setBillingPlanWebsiteUrl(bid,e.target.value)}/>
          <Select
            id="select-plan"
            value={planData?.plan}
            onChange={(e)=>mainService.intellectibaseService.setBillingPlanPlan(bid,e.target.value)}
            variant="standard"
          >
            <MenuItem value={'productfree'}>Free</MenuItem>
            <MenuItem value={'productpro'}>Pro</MenuItem>

          </Select>

          <Typography variant="h4" sx={{mt:'16px'}}>Members</Typography>
          {membersData && Object.entries(membersData).map((member)=>(
             <Box key={member[0]} sx={{display:'flex', alignItems:'center', gap:'8px'}}>
                <Typography>{decodeURIComponent(member[0])}</Typography>
                <Select
                  id="select-plan"
                  value={member[1]}
                  variant="standard"
                  onChange={(e)=>mainService.intellectibaseService.setBillingMemberAccess(bid,member[0],e.target.value)}
                >
                  <MenuItem value={'admin'}>Admin</MenuItem>
                  <MenuItem value={'member'}>Member</MenuItem>
                </Select>
                <ButtonBase onClick={()=>mainService.intellectibaseService.removeBillingMember(bid,member[0])}><CloseIcon/></ButtonBase>
             </Box>
          ))}
          
          <ButtonBase onClick={()=>setDlgOpen(true)}><AddIcon/><Typography>Add member</Typography></ButtonBase>
          <Button variant="contained" color="error" sx={{mt:'32px'}} onClick={()=>mainService.intellectibaseService.removeBillingAccount(bid,membersData)}>Delete Billing Account</Button>
          </Box>
        )}
      </Box>
    </ResponsiveDialog>
    <Snackbar open={alertOpen} autoHideDuration={3000} onClose={()=>{setAlertOpen(false)}}>       
        <Alert onClose={()=>{setAlertOpen(false)}} severity={alertSeverity} sx={{ width: '100%' }}>        
            {alertMsg}           
        </Alert>       
    </Snackbar>
    <ResponsiveDialog open={dlgOpen} onClose={handleDlgClose}>
      <AddMember onAdd={handleAddMember}/>
    </ResponsiveDialog>
    </>
  )
}


const headCells = [
  {
    id: 'Id',
    numeric: false,
    disablePadding: true,
    label: 'Id',
  },
  {
    id: 'Icon',
    numeric: false,
    disablePadding: true,
    label: '',
  },
  {
    id: 'Name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'Website',
    numeric: false,
    disablePadding: false,
    label: 'Website',
  },
  {
    id: 'Owner',
    numeric: false,
    disablePadding: false,
    label: 'Owner',
  },
  {
    id: 'Plan',
    numeric: false,
    disablePadding: false,
    label: 'Plan',
  }
];

export default function PPAccountTable() {
    
    //Use
    const mainService = useContext(MainContext);

    const [loading,setLoading] = useState(false);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('Date');
    const [selected, setSelected] = useState([]);
    const [rows,setRows] = useState([]);
    const [bid, setBid] = useState('');
    const [ownerEmail,setOwnerEmail] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false); 

    const handleDialogClose = ()=>{
      setDialogOpen(false);
      setBid(null);
      setOwnerEmail(null);
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
        const newSelected = rows.map((n) => n.id);
        setSelected(newSelected);
        return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
        );
        }

        setSelected(newSelected);
    };

    /*const handleChangePage = (event,direction) => {
        let newPage = 0;
        if(direction==="forward"){
          newPage = page
          newPage++;
        }
          
        else if(direction==="backward" && page > 0){
          newPage = page
          newPage--;
        }

        setLoading(true);
        try{
          mainService.intellectibaseService.unsubscribeBillingPlan();
          mainService.intellectibaseService.listenBillingPlanProductPro(orderBy,rowsPerPage,newPage,direction,listenDocData);
        }
        catch(error){
          console.error(error)
          setLoading(false);
        }
    };*/

    /*const handleChangeRowsPerPage = (event) => {
        const value = parseInt(event.target.value, 10);
        setLoading(true);
        try{
          mainService.intellectibaseService.unsubscribeBillingPlan();
          mainService.intellectibaseService.listenBillingPlanProductPro(orderBy,value,0,"forward",listenDocData);
          setRowsPerPage(value);
        }
        catch(error){
          console.error(error)
          setLoading(false);
        }       
        
    };*/

    const handleChangeOrderby = (event,value) => {
      setLoading(true);
      try{
        mainService.intellectibaseService.unsubscribeBillingPlan();
        mainService.intellectibaseService.listenBillingPlanProductPro(listenDocData);
        setOrderBy(value);
      }
      catch(error){
        console.error(error)
        setLoading(false);
      }       
      
    };

  //Listeners
  const listenDocData = async (data) => {
    if(data){
      let newData = []
      let promList = [];
      for(let doc of data){
        let newDoc = {...doc};
        let prom = mainService.intellectibaseService.getBillingOwner(doc.id).then((em)=> {newDoc['ownerEmail'] = em; newData.push(newDoc);})
        promList.push(prom);
      }
      await Promise.all(promList);

      setRows(newData);
      setLoading(false);
    }
  }

  //Effects
  useEffect(() => {
    //on mount
    setLoading(true);
    mainService.intellectibaseService.listenBillingPlanProductPro(listenDocData)

    //on unmount
    return ()=>{
      mainService.intellectibaseService.unsubscribeBillingPlan();
    }

  }, []);

    const isSelected = (name) => selected.indexOf(name) !== -1;

    return (
        <>
        <Box sx={{ width: '100%'}}>       
         {loading && !rows ? 
         (<Paper sx={{ width: '100%', mb: 2}}>
            <Box sx={{p:theme.spacing(2),left:"50%",top:0,width:"100%",display:"flex",justifyContent:"center"}}>
              <CircularProgress/>
            </Box>
          </Paper>):

          (<Paper sx={{ width: '100%', mb: 2,overflow: 'hidden'}}>

            
            <TableContainer sx={{maxHeight:"650px"}}>
            <Table
                stickyHeader 
                sx={{ minWidth: 750}}
                aria-labelledby="tableTitle"
                size='small'
            >
              <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleChangeOrderby}
                  rowCount={rows.length}   
                  headCells={headCells} 
              />
                
                <TableBody >                                              
                {
                    rows
                    .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                        <TableRow
                        hover
                        
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        >
                        <TableCell padding="checkbox">
                            <Checkbox
                            onClick={(event) => handleClick(event, row.id)}
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                                'aria-labelledby': labelId,
                            }}
                            />
                        </TableCell>
                        <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                        >
                          {row.id}
                        </TableCell>
                        <TableCell align="left">{row?.logourl ? <img src={row.logourl}/> : ''}</TableCell>
                        <TableCell align="left">
                        <Link color="#324ab2" href="#" onClick={()=>{setBid(row.id);setOwnerEmail(row.ownerEmail);setDialogOpen(true)}}>
                          {row?.name ?? 'No name'}
                        </Link>
                        </TableCell>
                        <TableCell align="left">
                        <Link color="#324ab2" href={row?.websiteurl ?? '/'} target="_blank">
                          {row?.websiteurl ?? 'No website'}
                        </Link>
                        </TableCell>
                        <TableCell align="left">{row?.ownerEmail ?? ''}</TableCell>
                        <TableCell align="left">{row.plan}</TableCell>
                        </TableRow>
                    );
                    })                   
                    }
                </TableBody>
            </Table>
            </TableContainer>
            {/*<CustomPagination
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsLength={rows.length}
                />*/}
        </Paper>)}
        </Box>
        <EditBilling open={dialogOpen} onClose={handleDialogClose} bid={bid} ownerEmail={ownerEmail}/>
        </>
    );
}