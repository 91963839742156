import React, {useState, Fragment } from "react";

import { Box, Typography, ButtonBase, Tooltip, Chip, Skeleton } from "@mui/material";

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import BoxSkeleton from "../../components/BoxSkeleton";

import { getNextDeadline } from "../../Util";

import Theme from "../../Theme";



const GrantDetails = ({fundingData,coverImg,ogData})=>{
    const [imgLoading,setImgLoading] = useState(true);

    return (
        <Box sx={{display:'flex',flexDirection:'column',width:'600px',height:'800px',}}>
            {coverImg &&
                (<Box sx={{width:'600px',height:'300px',position:'relative'}}>
                    {coverImg && imgLoading &&
                        <BoxSkeleton sx={{minWidth:"100%",minHeight:"100%",position:"absolute"}}/>
                    }                   
                    {coverImg !== 'loading' && 
                    <Fragment>
                        <Box sx={{width:'100%',height:'100%',position:'absolute'}}>
                            <img  loading="lazy" onError={()=>{console.log('error loading image');setImgLoading(false)}} onLoad = {()=>{console.log("img loaded"); setImgLoading(false)}} src={coverImg} style={{position:'abosolute', width:"100%",height:"100%",objectFit:"cover",filter:'blur(30px)'}}/>
                        </Box>
                        <Box sx={{width:'100%',height:'100%',position:'absolute'}}>
                            <img  loading="lazy" onLoad = {()=>{console.log("img loaded"); setImgLoading(false)}} src={coverImg} style={{position:'abosolute', width:"100%",height:"100%",objectFit:"contain"}}/>
                        </Box>
                    </Fragment>
                    }               
                </Box>

            )}
            <Box sx={{display:'flex',flexDirection:'column',backgroundColor:'white',p:'16px',zIndex:1000,borderBottom:`1px solid ${Theme.palette.primary.light}`}}>
                <Typography variant="h2" sx={{mb:'8px'}}>
                    {fundingData.AwardOrg}
                </Typography>
                <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',gap:"4px",mb:'8px'}}>
                    <Typography variant="h3" sx={{flexGrow:imgLoading && 1}}>
                        {fundingData.GrantName}
                    </Typography>
                    {fundingData.GrantURL &&
                    <Tooltip title="Go to grant website" arrow>
                        <ButtonBase >
                            <OpenInNewIcon onClick={()=>window.open(fundingData.GrantURL,'_blank')} sx={{width:'16px',height:'16px'}}/>
                        </ButtonBase>
                    </Tooltip>}
                </Box>
                {ogData && ogData.ogDescription &&
                <Typography variant="h4" sx={{mb:'8px',fontStyle: 'italic',color:'#5f748d'}}>
                    {coverImg && imgLoading ? <Skeleton/> : ogData.ogDescription}
                </Typography>
                }
                {fundingData.AwardMin || fundingData.AwardMax ? (
                <Typography variant="h5" sx={{mb:'4px'}}>
                    {coverImg && imgLoading ? <Skeleton/>                   
                    : `Awards ${fundingData.AwardMin ? `from $${fundingData.AwardMin.toLocaleString()}` : `up`}${fundingData.AwardMax && ` to $${fundingData.AwardMax.toLocaleString()}`}`
                    }
                </Typography>) : (
                <Typography variant="h5" sx={{mb:'4px'}}>
                    {`Exact funding amount not specified.`}
                </Typography>
                )}
                <Typography variant="h5" sx={{mb:'4px'}}>
                    {
                    `Next deadline: ${fundingData.SubmissionRanges ? `${getNextDeadline(fundingData.SubmissionRanges).toLocaleDateString()}` : ` rolls throughout year`}`
                    }
                </Typography>
                {fundingData.SubmissionMethod &&
                <Typography variant="h5">
                    {`Submission method: ${fundingData.SubmissionMethod}`}
                </Typography>
                }
            </Box>
            {fundingData.granteeCriteria &&
            <Box sx={{display:'flex',flexDirection:'column',p:'16px',borderBottom:`1px solid ${Theme.palette.primary.light}`}}>
                <Typography variant="h3" sx={{mb:'8px'}}>
                    Applicant criteria
                </Typography>
                <Box sx={{display:'flex',flexDirection:'row',alignItems:'flex-start',gap:'4px',flexWrap:'wrap'}}>
                    {Object.keys(fundingData.granteeCriteria).map((critType)=>
                        (Object.keys(fundingData.granteeCriteria[critType]).map((crit)=>(
                            <Chip label={decodeURIComponent(crit)} key={crit} color="primary"></Chip>
                        )))
                    )}
                </Box>
            </Box>}

            {fundingData.projectCriteria && 
            <Box sx={{display:'flex',flexDirection:'column',p:'16px',mb:'16px'}}>
                <Typography variant="h3" sx={{mb:'8px'}}>
                    Project criteria
                </Typography>
                <Box sx={{display:'flex',flexDirection:'row',alignItems:'flex-start',gap:'4px',flexWrap:'wrap'}}>
                    {Object.keys(fundingData.projectCriteria).map((critType)=>
                        (Object.keys(fundingData.projectCriteria[critType]).map((crit)=>(
                            <Chip label={decodeURIComponent(crit)} key={crit} color="primary"></Chip>
                        )))
                    )}
                </Box>
            </Box>}
        </Box>
    )    
}

export default GrantDetails; 