import React, {useState, useEffect, useContext } from "react";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingFull = ({sx}) => {
    return(
        <Box sx={{ position: 'absolute', width:'100%', height:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', ...sx}}>
            <CircularProgress color="inherit" />
        </Box>
    )
}

export default LoadingFull;