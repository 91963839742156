import { 
    getStorage,
    uploadBytes,
    deleteObject,
    getDownloadURL, 
    list
} from "firebase/storage";

import {ref as sRef} from "firebase/storage";

import { 
    getDatabase, 
    ref, 
    update,
    set, 
    get, 
    push, 
} from 'firebase/database';

import { v4 as uuidv4 } from 'uuid';

import { API_URL } from './Config';

export default class ProductListingService {
    
    constructor(mainService){
        this.mainService = mainService; 

        this.productListingListListeners = new Map();
        this.myProductListingMetadataListeners = new Map();
        this.myListingMetadatas = new Map();
    }

    async createNewProductListing(userObject){

        const db = getDatabase();
        const safeEmail = this.mainService.getEncodedEmail(); 

        const newListingRef = push( ref(db, "productListingMetadata") );
        const newListingKey = newListingRef.key;
        
        const datetime = this.mainService.getDatetimeString();
        const title = `Untitled ${datetime}`;
        const billingId = userObject?.activeBillingId ?? null;

        const productListingMetadata = {
            title,
            status:'draft',
            createdDate: Date.now(),
            creationStage:'flow',
            billingId        
        }; 

        const providerName = userObject && userObject?.orgname ? userObject.orgname : null;

        let productListingDrafts = {
            title:`Untitled ${datetime}`, 
            ownerId:this.mainService.getUid(),
            providerName: providerName,
            billingId,
        };

        let productListingCollaborators = {}; 
        productListingCollaborators[safeEmail] = 'owner'; 
        
        await set( ref(db, "productListingCollaborators/" + newListingKey), productListingCollaborators) //must write to collaborators first for permissions to write to other stuff 
        await set( newListingRef, productListingMetadata);
        await set( ref(db, "productListingDrafts/" + newListingKey), productListingDrafts );
        await set( ref(db, "userProductListingRelationships/" + safeEmail + "/" + newListingKey), true); 
        if(billingId)
            await set( ref(db, "billingProductRelationship/" + billingId + "/" + newListingKey), true); 

        //admin notif: name,email,productname,status
        this.sendAdminNotification(userObject.firstname, userObject.email, newListingKey, 'draft');

        return newListingKey;
    }

    async getMetadata(key){
        const db = getDatabase();
        const R = ref(db, "productListingMetadata/" + key);
        let snapshot = await get(R)
        return snapshot.val(); 
    }

    addListListener(listener){

        //do nothing if already registred
        if(this.productListingListListeners.has(listener)){
            return; 
        }

        //When the product listing relationship changes
        let relChange = async (val)=>{

            //remove old metadata listeners
            this.removeMetadataListeners(listener);
            //reset listings obj
            this.myListingMetadatas.set(listener, {});
            let metadataListeners = {};  
            
            for(const listingKey in val){
                //test
                const metadataCallback = (val) => {
                    
                    if(this.myProductListingMetadataListeners.has(listener)){
                        let listingsObj =  this.myListingMetadatas.get(listener)
                        listingsObj[listingKey] = val;
                        this.myListingMetadatas.set(listener,listingsObj);
                        listener(listingsObj); 
                    }
                }

                metadataListeners[listingKey] = metadataCallback;
                this.mainService.addDataListener('productListingMetadata/' + listingKey, metadataCallback); 

            }
            this.myProductListingMetadataListeners.set(listener,metadataListeners);
        }

        //add the data listener
        let safeEmail = this.mainService.getEncodedEmail(); 
        this.mainService.addDataListener('userProductListingRelationships/' + safeEmail, relChange); 

        this.productListingListListeners.set(listener, relChange); 


    }

    removeListListener(listener){
        if(!this.productListingListListeners.has(listener)){
            return; 
        }

        const relChange = this.productListingListListeners.get(listener); 
        const safeEmail = this.mainService.getEncodedEmail(); 

        this.mainService.removeDataListener('userProductListingRelationships/' + safeEmail, relChange); 
        this.productListingListListeners.delete(listener); 

        //test
        this.removeMetadataListeners(listener);      
    }

    addOrgListListener(bid,listener){

        //do nothing if already registred
        if(this.productListingListListeners.has(listener)){
            return; 
        }

        //When the product listing relationship changes
        let relChange = async (val)=>{

            //remove old metadata listeners
            this.removeMetadataListeners(listener);
            //reset listings obj
            this.myListingMetadatas.set(listener, {});
            let metadataListeners = {};  
            
            for(const listingKey in val){
                //test
                const metadataCallback = (val) => {
                    
                    if(this.myProductListingMetadataListeners.has(listener)){
                        let listingsObj =  this.myListingMetadatas.get(listener)
                        listingsObj[listingKey] = val;
                        this.myListingMetadatas.set(listener,listingsObj);
                        listener(listingsObj); 
                    }
                }

                metadataListeners[listingKey] = metadataCallback;
                this.mainService.addDataListener('productListingMetadata/' + listingKey, metadataCallback); 

            }
            this.myProductListingMetadataListeners.set(listener,metadataListeners);
        }

        //add the data listener
        this.mainService.addDataListener('billingProductRelationship/' + bid, relChange); 

        this.productListingListListeners.set(listener, relChange); 


    }

    removeOrgListListener(bid,listener){
        if(!this.productListingListListeners.has(listener)){
            return; 
        }

        const relChange = this.productListingListListeners.get(listener); 

        this.mainService.removeDataListener('billingProductRelationship/' + bid, relChange); 
        this.productListingListListeners.delete(listener); 

        //test
        this.removeMetadataListeners(listener);      
    }

    removeMetadataListeners(listener){
        console.log("removing metadata listeners")
        if(!this.myProductListingMetadataListeners.has(listener)){
            return; 
        }
        const listeners = this.myProductListingMetadataListeners.get(listener);
        for (const [id, callback] of Object.entries(listeners)) {
            this.mainService.removeDataListener('productListingMetadata/' + id, callback); 
        }
        this.myProductListingMetadataListeners.delete(listener);
        if(this.myListingMetadatas.has(listener))
            this.myListingMetadatas.delete(listener);
    }

    addMetadataListener(listingId, listener){
        this.mainService.addDataListener(`productListingMetadata/${listingId}` , listener); 
    }

    removeMetadataListener(listingId, listener){
        this.mainService.removeDataListener(`productListingMetadata/${listingId}`, listener); 
    }

    addListingListener(listingId, listener){
        this.mainService.addDataListener(`productListingDrafts/${listingId}` , listener); 
    }

    removeListingListener(listingId, listener){
        this.mainService.removeDataListener(`productListingDrafts/${listingId}`, listener); 
    }

    addPublicListingListener(listingId, listener){
        this.mainService.addDataListener(`productListingPublished/${listingId}` , listener); 
    }

    removePublicListingListener(listingId, listener){
        this.mainService.removeDataListener(`productListingPublished/${listingId}`, listener); 
    }

    addPublicListingsListener(listener){
        this.mainService.addDataListener(`productListingPublished` , listener); 
    }

    removePublicListingListener(listener){
        this.mainService.removeDataListener(`productListingPublished`, listener); 
    }

    addEmailCopyListener(listingId,listener){
        this.mainService.addDataListener(`productListingEmail/${listingId}` , listener); 
    }

    removeEmailCopyListener(listingId,listener){
        this.mainService.removeDataListener(`productListingEmail/${listingId}`, listener); 
    }

    writeEmailCopySubject(listingId,value){         
        set( ref(getDatabase(), `productListingEmail/${listingId}/subject`), value);
    }

    writeEmailCopyBody(listingId,value){
        set( ref(getDatabase(), `productListingEmail/${listingId}/body`), value);
    }

    async writeListingCreationStage(listingId,value){
        const db = getDatabase();          
        await set( ref(db, `productListingMetadata/${listingId}/creationStage`), value);
    }

    async writeListingTitle(listingId, title){
        const db = getDatabase();
        try {           
            const setDraft = set( ref(db, `productListingDrafts/${listingId}/title`), title);
            const setMetadata= set( ref(db, `productListingMetadata/${listingId}/title`), title);
            const promiseList = [setDraft,setMetadata]
            await Promise.all(promiseList);
        }
        catch(error){
            throw error
        }
        
    }

    async writeListingURL(listingId, value){
        const db = getDatabase();          
        await set( ref(db, `productListingDrafts/${listingId}/websiteurl`), value);  
    }

    //for updating text based values in product listing: e.g. strapline, pitch, website url...
    async writeListingTextvalue(listingId, name, value){
        const db = getDatabase();
        try {           
            const setDraft = await set( ref(db, `productListingDrafts/${listingId}/${name}`), value);
        }
        catch(error){
            throw error
        }       
    }

    //for updating base pricing info
    async writeListingBasePriceAmount(listingId, value){
        const db = getDatabase();          
        const setDraft = await set( ref(db, `productListingDrafts/${listingId}/basePricing/amount`), value);      
    }
    async writeListingBasePriceMaxQuant(listingId, value){
        const db = getDatabase();          
        const setDraft = await set( ref(db, `productListingDrafts/${listingId}/basePricing/maxQuant`), value);      
    }
    async writeListingBasePriceMinQuant(listingId, value){
        const db = getDatabase();          
        const setDraft = await set( ref(db, `productListingDrafts/${listingId}/basePricing/minQuant`), value);      
    }

    async writeListingBasePriceUnitType(listingId,value){
        const db = getDatabase();          
        const setDraft = await set( ref(db, `productListingDrafts/${listingId}/basePricing/unitType`), value);  
    }

    async writeListingBasePriceQuantPerStudent(listingId,value){
        const db = getDatabase();          
        const setDraft = await set( ref(db, `productListingDrafts/${listingId}/basePricing/quantPerStudent`), value);  
    }

    async writeListingSkills(listingId,value){
        const db = getDatabase();
        await set(ref(db,`productListingDrafts/${listingId}/skills`),value);
    }

    async writeProjectTitles(listingId,value){
        const db = getDatabase();
        await set(ref(db,`productListingDrafts/${listingId}/projectTitles`),value)
    }

    async writeProblemStatements(listingId,value){
        const db = getDatabase();
        await set(ref(db,`productListingDrafts/${listingId}/problemStatements`),value)
    }

    async writeSampleProjectDescription(listingId,value){
        const db = getDatabase();
        await set(ref(db,`productListingDrafts/${listingId}/sampleProjectDescription`),value)
    }

    async writeListingProviderName(listingId, value){
        const db = getDatabase();
        try {           
            await set( ref(db, `productListingDrafts/${listingId}/providerName`), value);
        }
        catch(error){
            throw error
        }   
    }

    async writeListingProviderTerms(listingId,value){
        const db = getDatabase();
        try {           
            await set( ref(db, `productListingDrafts/${listingId}/providerTerms`), value);
        }
        catch(error){
            throw error
        }  
    }

    async writeListingGenerated(listingId,value){
        const db = getDatabase();
        await set(ref(db,`productListingMetadata/${listingId}/contentgenerated`),value)
    }

    async writeListingGenerationError(listingId,value){
        const db = getDatabase();
        await set(ref(db,`productListingMetadata/${listingId}/errorGeneratingContent`),value)
    }

    //methods for image uploads and downloads
    async uploadProductListingImage(listingId,fileName,imgFile,imgType="featureImage", isImageArray){       	

        //Generate uuid 
		const key = uuidv4(); 
		const customMetadata = {key: key}; 

		let metadata = {customMetadata: customMetadata}; 
		metadata['contentType'] = imgFile.type;

		//make filename good for firebase
		const encodedfileName = encodeURIComponent(fileName).replace( new RegExp("\\.","g") , '%2E');
		//Invert with decodeURIComponent( encodedfileName.replace(/%2E/g, '.') );

		//Add file to storage
		try{

            const db = getDatabase();
            let listingRef = ref(db, `productListingDrafts/${listingId}/${imgType}`);

            if(isImageArray){ //if the image is part of a collection of images, push!
                let newImgRef = push( listingRef );
                imgType = imgType + '/' + newImgRef.key;
                listingRef = newImgRef;
            }

            const storage = getStorage();
            const storageRef = sRef(storage,`productListingDrafts/${listingId}/${imgType}/${key}`); 

            //delete image if already exists otherwise does nothing
            if(!isImageArray)
                await this.deleteProductListingImage(listingId,imgType);            

            const uploadTask = await uploadBytes(storageRef,imgFile, metadata);
            //once uploaded get download URL
            const url = await getDownloadURL(storageRef);
    
            const dbdat = {fileName: encodedfileName, key, url};
            
            await set(listingRef,dbdat);
        }
        catch(error){
            throw error;
        }

		return {key: key, name:encodedfileName}; 
    }


    async deleteProductListingImage(listingId,imgType="featureImage") {
        const db = getDatabase();
        const dbRef = ref( db, `productListingDrafts/${listingId}/${imgType}`);

        try{
            //check the db has feature image
            const snapshot = await get(dbRef);

            if(snapshot.exists()){
                const imgKey = snapshot.val().key;

                if(!imgKey)
                    return
                    
                //delete the img
                const storage = getStorage();
                const storageRef = sRef(storage, `productListingDrafts/${listingId}/${imgType}/${imgKey}`);

                await deleteObject(storageRef);

                //set the db value to false
                await set(dbRef, null);
            }
        }
        catch(error){
            throw error
        }

    }

    async deleteProductListing(listingId) {
        const db = getDatabase();  
 
        try{
            const prodCollaboratorsSnap = await get(ref(db,`productListingCollaborators/${listingId}`));
            const safeEmail = Object.keys(prodCollaboratorsSnap.val()).find(el => prodCollaboratorsSnap.val()[el] === 'owner');
            //const safeEmail = this.mainService.getEncodedEmail(); 
            const emailUserSnap = await get(ref(db,`emailUidRelationship/${safeEmail}`));
            const uid = emailUserSnap.val();
            const userSnap = await get(ref(db,`users/${uid}`));
            const billingId = userSnap.val()?.activeBillingId ?? null;
            const userCollectionSnap = await get(ref(db,`userProductCollectionRelationships/${safeEmail}`));
            const collectionId = userCollectionSnap.exists() ?  Object.keys(userCollectionSnap.val())[0] : null;

            //delete storage if any (do before deleting other stuff otherwise we'll have no referece to image)
            await this.deleteProductListingImage(listingId,"featureImage");
            await this.deleteProductListingImage(listingId,"logoImage");
            /*try{
                await this.deleteProductListingImage(listingId,"featureImage");
            }
            catch(error){
                console.log('problem deleting feature image')
            }
            try{
                await this.deleteProductListingImage(listingId,"logoImage");
            }
            catch(error){
                console.log('problem deleting logo image')
            }*/
            

            //delete the list of product images
            let productImagesSnap = await get(ref(db, "productListingDrafts/" + listingId + "/productImages"))
            if(productImagesSnap.exists()){
                let pList = []; 
                for(const imgKey in Object.keys(productImagesSnap.val())){
                    pList.push( this.deleteProductListingImage(listingId, "productImages/" + imgKey) );
                }
                await Promise.all(pList); 
            }

            //delete every thing under listing
            const updates = {};

            updates[`productListingMetadata/${listingId}`] = null;
            updates[`productListingDrafts/${listingId}`] = null;
            updates[`productListingPublished/${listingId}`] = null;
            updates[`userProductListingRelationships/${safeEmail}/${listingId}`] = null;
            updates[`productApplicationRelationship/${listingId}`] = null;
            updates[`productListingEmail/${listingId}`] = null;
            if(billingId)
                updates[`billingProductRelationship/${billingId}/${listingId}`] = null;
            if(collectionId)
                updates[`productCollections/${collectionId}/products/${listingId}`] = null;

            await update(ref(db), updates);

            await set( ref(db, "productListingCollaborators/" + listingId), null); //must delete collaborators last
            console.log("deleted product collaborators");

        }
        catch(error){
            throw error;
        }
    }

    async cloneProductListing(listingId,userObject){
        const db = getDatabase();

        //get data
        const metadataSnap = await get(ref(db,`productListingMetadata/${listingId}`));
        if(!metadataSnap.exists()){
            throw new Error('Missing metadata');
        }
        const metadata = metadataSnap.val();

        const listingDataSnap = await get(ref(db,`productListingDrafts/${listingId}`));
        if(!listingDataSnap.exists()){
            throw new Error('missing listing data');
        }
        const listingData = listingDataSnap.val();
        const featureImgData = listingData?.featureImage ?? null;
        const logoImgData = listingData?.logoImage ?? null;
        const productImageDatas = listingData?.productImages ? Object.keys(listingData.productImages).map(el=>listingData.productImages[el]) : null;

        //create new listing
        const safeEmail = this.mainService.getEncodedEmail(); 

        const newListingRef = push( ref(db, "productListingMetadata") );
        const newListingKey = newListingRef.key;
        
        const title = `Copy of ${metadata.title}`;
        const billingId = userObject?.activeBillingId ?? null;

        const productListingMetadata = {
            title,
            status:'draft',
            createdDate: Date.now(),
            creationStage:'editor',
            billingId        
        }; 

        let productListingDrafts = {...listingData};
        //Make sure all published/review keys are false
        productListingDrafts['inReview'] = false;
        productListingDrafts['isPublished'] = false;
        productListingDrafts['ownerId'] = this.mainService.getUid();
        productListingDrafts['logoImage'] = null;
        productListingDrafts['featureImage'] = null;
        productListingDrafts['productImages'] = null;

        
        let productListingCollaborators = {}; 
        productListingCollaborators[safeEmail] = 'owner'; 
        
        await set( ref(db, "productListingCollaborators/" + newListingKey), productListingCollaborators) //must write to collaborators first for permissions to write to other stuff 
        await set( newListingRef, productListingMetadata);
        await set( ref(db, "productListingDrafts/" + newListingKey), productListingDrafts );
        await set( ref(db, "userProductListingRelationships/" + safeEmail + "/" + newListingKey), true); 
        if(billingId)
            await set( ref(db, "billingProductRelationship/" + billingId + "/" + newListingKey), true); 

        const imgJobs = [];

        imgJobs.push(
            this.getImageData(logoImgData?.url).then((img)=>{this.uploadProductListingImage(newListingKey,logoImgData?.fileName,img,'logoImage')})
        )

        imgJobs.push(
            this.getImageData(featureImgData?.url).then((img)=>{this.uploadProductListingImage(newListingKey,featureImgData?.fileName,img,'featureImage')})
        )

        for(const data of productImageDatas){
            imgJobs.push(
                this.getImageData(data?.url).then((img)=>{this.uploadProductListingImage(newListingKey,data?.fileName,img,'productImages',true)})
            )
        }

        await Promise.all(imgJobs);

        //admin notif: name,email,productname,status
        this.sendAdminNotification(userObject.firstname, userObject.email, newListingKey, 'draft');

        return newListingKey;
    }

    async reviewListing(listingId, shouldReview, isPublished) {
        const db = getDatabase();       
        const mdRef = ref( db,`productListingMetadata/${listingId}/status`);
        const draftRef = ref( db, `productListingDrafts/${listingId}/inReview`);
        try {
            if(shouldReview){
                //send admin notif
                await Promise.all([set(mdRef, 'review'), set(draftRef, shouldReview)]);
                //admin notif: name,email,productname,status
                this.sendAdminNotification('', this.mainService.getEmail(), listingId, 'review');
            }
            else
                await Promise.all([set(mdRef, isPublished?'published':'draft'), set(draftRef, null)]);
        }
        catch(error){
            throw error;
        }
    }

    /*
    Product Collections
    */

    async getProductCollectionId() {
        const safeEmail = this.mainService.getEncodedEmail(); 
        const snap = await get(ref(getDatabase(),`userProductCollectionRelationships/${safeEmail}`));
        if(!snap.exists())
            return null;
        return Object.keys(snap.val())[0];
    }

    async addToProductCollection(listingId,collectionId=null,) {
        const db = getDatabase();

        if(!collectionId){
            const prodCollaboratorsSnap = await get(ref(db,`productListingCollaborators/${listingId}`))
            const safeEmail = Object.keys(prodCollaboratorsSnap.val()).find(el => prodCollaboratorsSnap.val()[el] === 'owner');
            console.log(safeEmail);
    
            const newCollectionRef = push( ref(db, "productCollectionCollaborators") );
            const newCollectionKey = newCollectionRef.key;
    
            const listingData = await get(ref(db,`productListingPublished/${listingId}`));
            const providerName = listingData.val().providerName;
    
            let productCollection = {
                enabled:true,
                providerName,
                products:{
                }
            };

            productCollection.products[listingId] = 'featured'
    
            let productCollectionCollaborators = {}; 
            productCollectionCollaborators[safeEmail] = 'owner'; 
            
            await set( ref(db, `productCollectionCollaborators/${newCollectionKey}`), productCollectionCollaborators) //must write to collaborators first for permissions to write to other stuff 
            await set( ref(db, `userProductCollectionRelationships/${safeEmail}/${newCollectionKey}`), true); 
            await set( ref(db, `productCollections/${newCollectionKey}`), productCollection );
            

            return newCollectionKey;
        }
        else{
            await set( ref(db, `productCollections/${collectionId}/products/${listingId}`), 'featured' );
        }
    }

    removeFromProductCollection(listingId,collectionId) {
        if(collectionId && listingId)
            set( ref(getDatabase(), `productCollections/${collectionId}/products/${listingId}`), null );
    }

    toggleFeaturedInProductCollection(listingId,collectionId,featured) {
        const value = featured ? 'featured' : 'standard'
        set( ref(getDatabase(), `productCollections/${collectionId}/products/${listingId}`), value );
    }

    setProductCollectionEnabled(collectionId,val) {
        console.log('collection enabled',collectionId+' '+val )
        set( ref(getDatabase(), `productCollections/${collectionId}/enabled`), val );
    }

    addUserProductCollectionRelationshipListener(listener,ownerEmail) {
        const safeEmail = ownerEmail ? ownerEmail : this.mainService.getEncodedEmail(); 
        console.log(safeEmail);
        this.mainService.addDataListener(`userProductCollectionRelationships/${safeEmail}` , listener); 
    }

    removeUserProductCollectionRelationshipListener(listener,ownerEmail) {
        const safeEmail = ownerEmail ? ownerEmail : this.mainService.getEncodedEmail(); 
        this.mainService.removeDataListener(`userProductCollectionRelationships/${safeEmail}` , listener); 
    }

    addProductCollectionListener(collectionId, listener) {
        this.mainService.addDataListener(`productCollections/${collectionId}` , listener); 
    }

    removeProductCollectionListener(collectionId, listener) {
        this.mainService.removeDataListener(`productCollections/${collectionId}`, listener); 
    }

    /*
    API requests    
    */

    //listingId,filename,blob,'logoImage',false
    async getImageData(url){
        console.log('getting image');
        try{
            const token = await this.mainService.getUserToken();
            let data = {token,url}; 

            const res = await fetch( API_URL+'/getimagedata', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            const imgData = await res.blob();
            return imgData;
        }
        catch(error){
            throw error;
        }
    }

    async generateProjectTitle(listingId,listingData){
        console.log('requesting project title');
        //send request for content data to be created
        try{
            const userToken = await this.mainService.getUserToken();
            
            let data = {userToken,listingId,listingData}; 

            const res = await fetch( API_URL+'/generateprojecttitle', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            const json = await res.json();
            return json;
        }
        catch(err){
            console.error('Content generation Error:', err);
        };
    }


    async generateProblemStatement(listingId,listingData){
        console.log('requesting problem statement')
        //send request for content data to be created
        try{
            const userToken = await this.mainService.getUserToken();
            
            let data = {userToken,listingId,listingData}; 

            const res = await fetch( API_URL+'/generateproblemstatement', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            const json = await res.json();
            return json;
        }
        catch(err){
            console.error('Content generation Error:', err);
        };
    }

    async getProductListingData(url,listingid){
        console.log('generateProductListingData'); 
        try{
            const token = await this.mainService.getUserToken();
            const res = await fetch (API_URL+'/generateproductdata',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({url,id:listingid,token,type:'provider'}),
            });
            let data = await res.json(); 
            if(!data.ok){
                console.log(data.message);
                this.writeListingGenerationError(listingid,true);
            }
        }
        catch(error){
            console.error(error);
            this.writeListingGenerationError(listingid,true);
        }
    }

    async getWebsiteImages(url){
        try{
            const token = await this.mainService.getUserToken();
            const res = await fetch(API_URL + `/getwebsiteimages?` + new URLSearchParams({
                url
            }),{
                headers: {
                  Authorization: `${token}`
                }
            });
            const data = await res.json();
            if(!data.ok){
                throw new Error(data.message);
            }
            return data.imgurls;
        }
        catch(error){
            console.error(error);
            return null;
        }
    }

    async getProductListingEmailCopy(listingid,listingData){
        console.log('generateProductListingEmailCopy'); 
        try{
            const token = await this.mainService.getUserToken();
            const res = await fetch (API_URL+'/generateproductemailcopy',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({id:listingid,token,productData:listingData}),
            });
            let data = await res.json(); 
            if(!data.ok){
                console.log(data.message);
            }
        }
        catch(error){
            console.error(error);
        }
    }

    async sendAdminNotification(name,email,productname,status){
        console.log('sendAdminNotification'); 
        try{
            const isAdmin = await this.mainService.isAdmin();
            if(isAdmin || process.env.NODE_ENV ===  'development'){
                throw new Error('not sending as is admin/ in testing');
            }
            const token = await this.mainService.getUserToken();
            const res = await fetch (API_URL+'/newproductlistingnotification',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({name,email,productname,status,token}),
            });
            let data = await res.json(); 
            if(!data.ok){
                throw new Error(data.message)
            }
        }
        catch(error){
            console.error(error);
        }
    }


}