import React, {useState, useEffect, useContext } from "react";

import theme from '../Theme';

import { ThemeProvider,keyframes } from  '@mui/system';
import {Box} from '@mui/material';

const BoxSkeleton = ({sx}) => {

    if(!sx)
        {sx={}}
    //STATES

    const pulsate = keyframes`
        0% {
        opacity: 1;
        }
        50% {
            opacity: 0.4;
        }
        100% {
            opacity: 1;
        }
    `;
    return(
        <ThemeProvider theme={theme}>

            <Box 
                sx={{
                    backgroundColor:"rgba(0, 0, 0, 0.11)",
                    animation: `${pulsate} 1.5s ease-in-out 0.5s infinite`,
                    ...sx}}
            >
            </Box>
        </ThemeProvider>
    )
}

export default BoxSkeleton;