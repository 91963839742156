import {
    getAuth, } from 'firebase/auth';

import { 
    getDatabase, 
    ref, 
    set, 
    get, 
    push, 
    update, 
    onValue, 
    orderByKey,
    limitToFirst,
    off, 
    query as rtQuery,
    orderByChild,
    equalTo} from 'firebase/database';

import { API_URL } from './Config';

import { round } from 'lodash';

import { getCurrencyAdornment } from './Util';

export default class ApplicationService {

    static defaultPageSettings = {
        py:25.4, 
        px:25.4, 
        titleFontSize:24, 
        subtitleFontSize:16, 
        paragraphFontSize:11
    };


    constructor(mainService){
        this.mainService = mainService; 
        this.applicationListListners = new Map();
    }

    addListListener(listner){

        //do nothing if already registred
        if(this.applicationListListners.has(listner)){
            return; 
        }

        //When the userapplication reletionship changes
        let relChange = async (val)=>{

            //get data from each metadata and add to list data 
            let listData = [];   
            let promiseList = [];          
            for(let appKey in val){

                let addMetaData = async ()=>{
                    console.log("add metadata:" + appKey);
                    let metadata = await this.getMetadata(appKey);
                    listData.push({
                        id: appKey, 
                        name: metadata?.name ?? '', 
                        createdDate: metadata?.createdDate ?? '',
                    }); 
                }
                promiseList.push( addMetaData() );
            }

            await Promise.all(promiseList); 

            //make sure the listners still around
            if(this.applicationListListners.has(listner)){
                listner(listData); 
            }
        }

        //add the data listner
        let safeEmail = this.mainService.getEncodedEmail(); 
        this.mainService.addDataListener('userApplicationRelationships/' + safeEmail, relChange); 

        this.applicationListListners.set(listner, relChange); 
    }

    removeListListener(listner){
        if(!this.applicationListListners.has(listner)){
            return; 
        }

        let relChange = this.applicationListListners.get(listner); 
        let safeEmail = this.mainService.getEncodedEmail(); 

        this.mainService.removeDataListener('userApplicationRelationships/' + safeEmail, relChange); 
        this.applicationListListners.delete(listner); 
    }

    async addUidToPPContacts(productId){

        console.log('adding to pp contacts'); 

        const db = getDatabase();

        const uid = this.mainService.getUid(); 

        if(uid && productId){

            let ownerIdSnap = await get(ref(db, `productListingPublished/${productId}/ownerId` ) ); 
            if(!ownerIdSnap.exists() || !ownerIdSnap.val())
                return; 
            
            try{
                await set( ref(db, `chatConvoContacts/${ownerIdSnap.val()}/${uid}`), true)
            }
            catch(err){
                console.log('failed to add to contacts ', err); 
            }
        }
    }

    async getMetadata(key ){
        const db = getDatabase();
        const R = ref(db, "applicationMetadata/" + key);
        let snapshot = await get(R)
        return snapshot.val(); 
    }

    async createNewApplication(title, userObject, productId){

        const db = getDatabase();
        let productData = null;
        let productOwnerId = null;
        let productBillingId = null;
        let applicationProductData = null;

        //if we have a product id then get product data
        if(productId){
            //get the product provider id to start (which also checks if the product actually exists)
            const productSnapshot = await get(ref(db, `productListingPublished/${productId}` ) );

            if(!productSnapshot.exists()){
                throw new Error(`no product found with id ${productId}`); 
            }

            productData = productSnapshot.val();
            productOwnerId = productData.ownerId; 
            productBillingId = productData?.billingId ?? null;//if the product has a billing id add this to the applicatioin object too

        }

        else {
            applicationProductData = {createdDate:Date.now()};
        }

        //create data 
        
        let safeEmail = this.mainService.getEncodedEmail(); 
        let uid = this.mainService.getUid();

        const newAppRef = push( ref(db, "applicationMetadata") );
        const newAppKey = newAppRef.key;
        const stage = productId ? 'eligibility' : 'product';

        let applicationMetadata = {
            name:title, 
            productId:productId ?? null,
            ownerId:uid,
            productOwnerId:productOwnerId,
            productBillingId,
            createdDate: Date.now(), 
            budgetStage:'notStarted',
            applicationStage:stage,
        }; 

        let applicationDashboardData = {
            name:title, 
            productId:productId ?? null,
            ownerId:uid,
            productOwnerId:productOwnerId,
            productBillingId,
            createdDate: Date.now(), 
        }; 

        //autofills
        if(userObject.teachersubjects)
            applicationMetadata['eligibilitySubjectFocus'] = userObject.teachersubjects;
        if(userObject.teachergradelow)
            applicationMetadata['eligibilityGradeMin'] = userObject.teachergradelow;
        if(userObject.teachergradehigh)
            applicationMetadata['eligibilityGradeMax'] = userObject.teachergradehigh;
        if(productData?.skills)
            applicationMetadata['eligibilityProjectSkills'] = productData.skills;

        applicationMetadata['eligibilityStudentCount'] = "0";
        applicationMetadata['eligibilityProductCount'] = "0";

        //is this still relevant??
        let applicationBody = {
            pageSettings:ApplicationService.defaultPageSettings,
        };

        let applicationCollaborators = {}; 
        applicationCollaborators[safeEmail] = 'owner'; 
        
        await set( ref(db, "applicationCollaborators/" + newAppKey), applicationCollaborators) //must write to collaborators first for permissions to write to other stuff 
        await set( newAppRef, applicationMetadata);
        await set( ref(db, "applicationDashboardData/" + newAppKey), applicationDashboardData); 
        await set( ref(db, "applicationBody/" + newAppKey), applicationBody );
        await set( ref(db, "userApplicationRelationships/" + safeEmail + "/" + newAppKey), true); 
        if(productId){
            await set( ref(db, "productApplicationRelationship/" + productId + "/" + newAppKey), true); 
            await set( ref(db, "userProductProviderRelationship/" + uid + "/" + productOwnerId + "/" + newAppKey), true);       
            await set( ref(db, "userOrgRelationship/" + uid + "/" + productBillingId ), true);     
            await this.addUidToPPContacts(productId); 
        }
        else {
            await set( ref(db, "applicationProduct/" + newAppKey), applicationProductData); 
        }
        
        return newAppKey; 
    }

    async deleteApplication(appId, productId){

        const db = getDatabase();
        let safeEmail = this.mainService.getEncodedEmail(); 

        let uid = this.mainService.getUid(); 

        console.log("product id " + productId);

        let productOwnerId = await get(ref(db, `applicationMetadata/${appId}/productOwnerId` ) ); 
        if(productOwnerId.val()){
            await set( ref(db, "userProductProviderRelationship/" + uid + "/" + productOwnerId.val() + "/" + appId), null); 
        }

        let updates = {}
        updates["applicationMetadata/" + appId] = null; 
        updates["applicationDashboardData/" + appId] = null; 
        updates["applicationBody/" + appId] = null; 
        updates["userApplicationRelationships/" + safeEmail + "/" + appId] = null; 
        updates["productApplicationRelationship/" + productId + "/" + appId] = null; 
        updates["applicationSections/" + appId] = null; 
        updates["applicationContent/" + appId] = null; 
        updates["applicationStyle/" + appId] = null; 
        updates["applicationModifiers/" + appId] = null; 
        updates["applicationProduct/" + appId] = null; 
        updates["budgetSummary/" + appId] = null; 
        updates["budgetSections/" + appId] = null; 
        updates["budgetLines/" + appId] = null; 
        await update(ref(db), updates); 
        await set( ref(db, "applicationCollaborators/" + appId), null); //must write to collaborators last for permissions to write to other stuff 
/*
        await set( ref(db, "applicationMetadata/" + appId), null);
        await set( ref(db, "applicationDashboardData/" + appId), null);
        await set( ref(db, "applicationBody/" + appId), null );
        await set( ref(db, "userApplicationRelationships/" + safeEmail + "/" + appId), null); 
        await set( ref(db, "productApplicationRelationship/" + productId + "/" + appId), null); 
        await set( ref(db, "superApplication/" + appId), null); 
        await set( ref(db, "superApplicationAnswers/" + appId), null); 
        await set( ref(db, "superApplicationStats/" + appId), null); 
        await set( ref(db, "applicationSections/" + appId), null);
        await set( ref(db, "applicationContent/" + appId), null);
        await set( ref(db, "applicationStyle/" + appId), null);
        await set( ref(db, "applicationModifiers/" + appId), null);
        await set( ref(db, "applicationProduct/" + appId), null);
        await set( ref(db, "budgetSummary/" + appId), null);
        await set( ref(db, "budgetSections/" + appId), null);
        await set( ref(db, "budgetLines/" + appId), null);
        await set( ref(db, "applicationCollaborators/" + appId), null) //must write to collaborators last for permissions to write to other stuff 
*/
    }

    async setApplicationName(appId, val){
        console.log('updating application name')
        await set( ref( getDatabase(), 'applicationMetadata/' + appId + '/name'), val);
    }

    async setEligibilitySubjectFocus(appId, val){
        await set( ref( getDatabase(), 'applicationMetadata/' + appId + '/eligibilitySubjectFocus'), val);
    }

    async setEligibilityProjectFocus(appId, val){
        await set( ref( getDatabase(), 'applicationMetadata/' + appId + '/eligibilityProjectFocus'), val);
    }

    async setEligibilityProjectSkills(appId, val){
        await set( ref( getDatabase(), 'applicationMetadata/' + appId + '/eligibilityProjectSkills'), val);
    }

    async setEligibilityProjectSetting(appId, val){
        await set( ref( getDatabase(), 'applicationMetadata/' + appId + '/eligibilityProjectSetting'), val);
    }

    async setEligibilityProjectStyle(appId, val){
        await set( ref( getDatabase(), 'applicationMetadata/' + appId + '/eligibilityProjectStyle'), val);
    }

    async setEligibilityStudentFocus(appId, val){
        await set( ref( getDatabase(), 'applicationMetadata/' + appId + '/eligibilityStudentFocus'), val);
    }

    async setEligibilityGradeMin(appId, val){
        await set( ref( getDatabase(), 'applicationMetadata/' + appId + '/eligibilityGradeMin'), val);
    }

    async setEligibilityGradeMax(appId, val){
        await set( ref( getDatabase(), 'applicationMetadata/' + appId + '/eligibilityGradeMax'), val);
    }

    async setEligibilityStudentCount(appId, val){
        await set( ref( getDatabase(), 'applicationMetadata/' + appId + '/eligibilityStudentCount'), val);
        await set( ref( getDatabase(), 'applicationDashboardData/' + appId + '/studentCount'), val);
    }

    async setEligibilityProblemStatement(appId, val){
        await set( ref( getDatabase(), 'applicationMetadata/' + appId + '/eligibilityProblemStatement'), val);
    }

    async setLearningObjectives (appId,val){
        await set( ref( getDatabase(), 'applicationMetadata/' + appId + '/learningObjectives' ), val);
    }

    async setEligibilityOutcomeMeasure (appId,val){
        await set( ref( getDatabase(), 'applicationMetadata/' + appId + '/eligibilityOutcomesMeasures' ), val);
    }

    async setEligibilityProductCount (appId,val){
        await set( ref( getDatabase(), 'applicationMetadata/' + appId + '/eligibilityProductCount'), val);
        await set( ref( getDatabase(), 'applicationMetadata/' + appId + '/productCountSet'), true);
        await set( ref( getDatabase(), 'applicationDashboardData/' + appId + '/productCount'), val);
    }

    async setEligibilityProjectStart (appId,val){
        await set( ref( getDatabase(), 'applicationMetadata/' + appId + '/eligibilityProjectStart'), val);
    }

    async setEligibilityProjectTitle(appId, val){
        await set( ref( getDatabase(), 'applicationMetadata/' + appId + '/eligibilityTitle'), val);
        await set( ref( getDatabase(), 'applicationDashboardData/' + appId + '/projectTitle'), val);
    }

    async setEligibilityShortDescription(appId, val){
        await set( ref( getDatabase(), 'applicationMetadata/' + appId + '/eligibilityShortDescription'), val);
    }

    async setApplicationStage(appId, val){
        await set( ref( getDatabase(), 'applicationMetadata/' + appId + '/applicationStage'), val);
        await set( ref( getDatabase(), 'applicationDashboardData/' + appId + '/applicationStage'), val);
    }

    async setFundingRecommendation(appId, val){
        await set( ref( getDatabase(), 'applicationMetadata/' + appId + '/fundingRecommendations'), val);
    }

    async setFundingRecommendationSelected(appId, fundingId, val){
        await set( ref( getDatabase(), 'applicationMetadata/' + appId + '/fundingRecommendations/' + fundingId + '/selected'), val);
    }

    async setFundingRecommendationStage(appId,fundingId,val){
        console.log('setting funding stage ' + val);
        await set( ref( getDatabase(), 'applicationMetadata/' + appId + '/fundingRecommendations/' + fundingId + '/stage'), val);
        await set( ref( getDatabase(), 'applicationDashboardData/' + appId + '/fundingStage'), val);
    }

    async setNewFundingRecommendation(appId,fundingId,data) {
        await set( ref( getDatabase(), 'applicationMetadata/' + appId + '/fundingRecommendations/' + fundingId), data);
    }

    async removeFundingRecommendation(appId,fundingId,val) {
        await set(ref(getDatabase(), 'applicationMetadata/' + appId + '/fundingRecommendations/' + fundingId ), null);
    }

    async setFundingSelected(appId,val){
        await set( ref( getDatabase(), 'applicationMetadata/' + appId + '/fundingSelected'), val);
    }

    async setEligibilityProjectLength(appId,val) {
        await set( ref( getDatabase(), 'applicationMetadata/' + appId + '/eligibilityProjectLength'), val);
    }

    //for dashboard
    addMostRecentAppListener(listener){
        const db = getDatabase();
        //get email
        const safeEmail = this.mainService.getEncodedEmail();
        let rtq = rtQuery(ref(db,`userApplicationRelationships/${safeEmail}`),orderByKey());
        this.mainService.addDataListener(`userApplicationRelationships/${safeEmail}`,listener, rtq);
    }

    removeMostRecentAppListener(listener){
        //get email
        const safeEmail = this.mainService.getEncodedEmail();
        this.mainService.removeDataListener(`userApplicationRelationships/${safeEmail}` , listener);
    }

    async pushInitialApplicationSectionsAndContent(appId,userObject,applicationMetadata,productData) {

        console.log('requesting new application');

        //send request for application data to be created
        try{
            const userToken = await this.mainService.getUserToken();
            
            let data = {userToken,appId,applicationMetadata,userObject,productData}; 

            const res = await fetch( API_URL+'/createapplication', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            const json = await res.json();
            if(!json.ok){
                throw new Error(json.message)
            }

        }
        catch(err){
            console.error('Content generation Error:', err);
        };

    }

    //add a new section to an application
    async pushApplicationSection(appId, sections){
        const newSectionData = {name:'New section',contentLocations:[],editable:true};
        const newSections = [...sections];
        newSections.push(newSectionData);
        await set(ref(getDatabase(),`applicationSections/${appId}`),newSections);
    }

    async deleteAppSection(appId,sectionId,sections,contentLocations){
        console.log('deleting app section')
        const db = getDatabase();
        let newSections = [...sections];
        newSections.splice(sectionId,1);
        const updates = {}
        updates[`applicationSections/${appId}`] = newSections;
        for(const cid of contentLocations){
            updates[`applicationContent/${appId}/${cid}`] = null;
        }       
        return update(ref(db), updates);
    }

    async setSectionName(appId,sectionId,val){
        await set(ref(getDatabase(),`applicationSections/${appId}/${sectionId}/name`),val);
    }

    //push a new question to a section of an application
/*
    async pushApplicationContent(appId, sectionId, contentLocations){
        const db = getDatabase();
        const newContentData = {content:'',input:'textLarge',title:'',editable:true,userCreationStage:'title',contentType:'customQuestion'};
        const newContentId = push( ref(db, `applicationContent/${appId}`) ).key;
        const newContentLocations = contentLocations ? [...contentLocations] : []
        newContentLocations.push(newContentId);
        const updates = {}
        updates[`applicationContent/${appId}/${newContentId}`] = newContentData;
        updates[`applicationSections/${appId}/${sectionId}/contentLocations`] = newContentLocations;
        return update(ref(db), updates);
    }

    async deleteApplicationContent(appId,contentId,contentInx,sectionId,contentLocations){
        console.log('deleting app content')
        const db = getDatabase();
        let newContentLocations = [...contentLocations];
        newContentLocations.splice(contentInx,1);
        const updates = {}
        updates[`applicationContent/${appId}/${contentId}`] = null;
        updates[`applicationSections/${appId}/${sectionId}/contentLocations`] = newContentLocations;
        return update(ref(db), updates);
    }

    async resetContent(appId,contentId){
        const db = getDatabase();
        const snapshot = await get(ref(db,`generatedContent/${contentId}/text`))
        if(snapshot.exists()){
            const text = snapshot.val();
            await set(ref(db,`applicationContent/${appId}/${contentId}/content`),text);
        }
    }

    async setApplicationContent(appId, contentId, val){
        await set( ref( getDatabase(), 'applicationContent/' + appId + '/' + contentId + '/content'), val);
    }

    async setApplicationSubContent(appId, contentId, subcontentId, val) {
        await set( ref( getDatabase(), 'applicationContent/' + appId + '/' + contentId + '/content/' + subcontentId), val);
    }

    async setApplicationContentTitle(appId,contentId,val){
        await set(ref(getDatabase(),`applicationContent/${appId}/${contentId}/title`),val)
    }

    async setApplicationContentInput(appId,contentId,val){
        await set(ref(getDatabase(),`applicationContent/${appId}/${contentId}/input`),val)
    }

    async setApplicationContentCreationStage(appId,contentId,val){
        await set(ref(getDatabase(),`applicationContent/${appId}/${contentId}/userCreationStage`),val)
    }

    async setApplicationContentType(appId,contentId,val){
        await set(ref(getDatabase(),`applicationContent/${appId}/${contentId}/contentType`),val)
    }

    async setApplicationContentGenStatus(appId,contentId,val){
        await set(ref(getDatabase(),`applicationContent/${appId}/${contentId}/generationStatus`),val)
    }
*/
    //application product

    async setApplicationProductWebsiteURL(appId,val){
        await set(ref(getDatabase(),`applicationProduct/${appId}/websiteurl`),val)
    }

    async setApplicationProductTitle(appId,val){
        await set(ref(getDatabase(),`applicationProduct/${appId}/title`),val)
    }

    async setApplicationProductProvider(appId,val){
        await set(ref(getDatabase(),`applicationProduct/${appId}/providerName`),val)
    }

    async setApplicationProductStrapline(appId,val){
        await set(ref(getDatabase(),`applicationProduct/${appId}/strapline`),val)
    }

    async setApplicationProductDescription(appId,val){
        await set(ref(getDatabase(),`applicationProduct/${appId}/description`),val);
    }

    async setApplicationProductGenerated(appId,val){
        await set(ref(getDatabase(),`applicationProduct/${appId}/contentgenerated`),val);
    }

    async setApplicationProductError(appId,val){
        await set(ref(getDatabase(),`applicationProduct/${appId}/errorGeneratingContent`),val);
    }

    async setApplicationProductPrice(appId,val){
        await set(ref(getDatabase(),`applicationProduct/${appId}/basePricing/amount`),val);
    }

    async setApplicationProductStudentsPerUnit(appId,val){
        await set(ref(getDatabase(),`applicationProduct/${appId}/basePricing/quantPerStudent`),val);
    }

    //listeners

    addApplicationMetadataListner(appId, listener){
        this.mainService.addDataListener('applicationMetadata/' + appId, listener); 
    }

    removeApplicationMetadataListner(appId, listener){
        this.mainService.removeDataListener('applicationMetadata/' + appId, listener); 
    }

    addProductDataListener(productId,listener){
        this.mainService.addDataListener('productListingPublished/' + productId, listener);
    }

    removeProductDataListener(productId, listener){
        this.mainService.removeDataListener('productListingPublished/' + productId, listener);
    }

    addApplicationSectionsListener(appId,listener){
        this.mainService.addDataListener('applicationSections/' + appId, listener);
    }

    removeApplicationSectionsListener(appId,listener){
        this.mainService.removeDataListener('applicationSections/' + appId, listener); 
    }

    addApplicationContentListener(appId,contentId,listener){
        this.mainService.addDataListener('/applicationContent/' + appId + '/' + contentId, listener);
    }

    removeApplicationContentListener(appId,contentId,listener){
        this.mainService.removeDataListener('/applicationContent/' + appId + '/' + contentId, listener);
    }

    addApplicationAllContentListener(appId,listener){
        this.mainService.addDataListener('/applicationContent/' + appId, listener);
    }

    removeApplicationAllContentListener(appId,listener){
        this.mainService.removeDataListener('/applicationContent/' + appId, listener);
    }

    addApplicationAllStyleListener(appId,listener){
        this.mainService.addDataListener('/applicationStyle/' + appId, listener);
    }

    removeApplicationAllStyleListener(appId,listener){
        this.mainService.removeDataListener('/applicationStyle/' + appId, listener);
    }

    addApplicationProductDataListener(appId,listener){
        this.mainService.addDataListener('applicationProduct/' + appId, listener);
    }

    removeApplicationProductDataListener(appId, listener){
        this.mainService.removeDataListener('applicationProduct/' + appId, listener);
    }


    //Campaign

    addCampaignLoadingListener(appId, listener){
        this.mainService.addDataListener('applicationCampaign/'+appId+'/loading/', listener);
    }

    removeCampaignLoadingListener(appId, listener){
        this.mainService.removeDataListener('applicationCampaign/'+appId+'/loading/', listener);
    }

    addCampaignTitleListener(appId, listener){
        this.mainService.addDataListener('applicationCampaign/'+appId+'/title/', listener);
    }

    removeCampaignTitleListener(appId, listener){
        this.mainService.removeDataListener('applicationCampaign/'+appId+'/title/', listener);
    }

    addCampaignStraplineListener(appId, listener){
        this.mainService.addDataListener('applicationCampaign/'+appId+'/strapline/', listener);
    }

    removeCampaignStraplineListener(appId, listener){
        this.mainService.removeDataListener('applicationCampaign/'+appId+'/strapline/', listener);
    }

    addCampaignDescriptionListener(appId, listener){
        this.mainService.addDataListener('applicationCampaign/'+appId+'/description/', listener);
    }

    removeCampaignDescriptionListener(appId, listener){
        this.mainService.removeDataListener('applicationCampaign/'+appId+'/description/', listener);
    }

    addCampaignThankyouListener(appId, listener){
        this.mainService.addDataListener('applicationCampaign/'+appId+'/thankyou/', listener);
    }

    removeCampaignThankyouListener(appId, listener){
        this.mainService.removeDataListener('applicationCampaign/'+appId+'/thankyou/', listener);
    }

    async setCampaignData(appId, sectionId, value){
        const db = getDatabase();
        await set( ref( db, `applicationCampaign/${appId}/${sectionId}`), value);
    }


    //Application edits

    async generateQuestionAnswer(appId,applicationMetadata,contentId,contentData,userObject,productData,summary){
        //send request for content data to be created
        try{
            const userToken = await this.mainService.getUserToken();
            
            let data = {userToken,appId,applicationMetadata,contentId,contentData,userObject,productData,summary}; 

            const res = await 
            fetch( API_URL+'/generatequestionanswer', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            const resJson = await res.json();

            return resJson;
        }
        catch(err){
            console.error('Content generation Error:', err);
        };
    }

    async getProjectSummary(appId){        
        let nodeData = await get( ref( getDatabase(), `applicationContent/${appId}`) ); 
        if(!nodeData.val())
            return ; 
        nodeData = nodeData.val(); 

        let firstNode = this.queryFirstNode(nodeData, {tags:['projectSummary']})
        let summary = this.queryText(nodeData, firstNode.key); 
        summary = summary.replace(/(\r\n|\n|\r)/gm, "");
        return summary; 
    }

    async getFundingRecommendation(userObject, applicationMetadata){
        
        console.log('getFundingRecommendation'); 

        try{
            const userToken = await this.mainService.getUserToken();
            
            let inputData = {userObject, applicationMetadata, userToken}; 

            let response = await fetch( API_URL+'/getfundingrecommendation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(inputData),
            })
            let data = await response.json(); 
            
            console.log(data);

            /*if(process.env.NODE_ENV == 'development'){
                console.log('Waiting for 5 second');
                await new Promise(resolve => setTimeout(resolve, 5000));
            }
            else
                await new Promise(resolve => setTimeout(resolve, 7500));*/

            return data; 
        }
        catch(err){
            console.error('Funding Recommendation Error:', err);
            return {};
        };

    }

    async getProductData(url,appId){
        console.log('generateProductData'); 
        try{
            const token = await this.mainService.getUserToken();
            const res = await fetch (API_URL+'/generateproductdata',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({url,id:appId,token,type:'teacher'}),
            });
            let data = await res.json(); 
            if(!data.ok){
                console.log(data.message);
                this.setApplicationProductError(appId,true);
            }
        }
        catch(error){
            console.error(error);
        }
    }

    async getProblemsAndTitles(title,strapline,providerName,appId){
        console.log('generateproblemsandtitles'); 
        try{
            const token = await this.mainService.getUserToken();

            const res = await fetch (API_URL+'/generateproblemsandtitles',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({title,strapline,providerName,appId,token}),
            });
            let data = await res.json(); 
            console.log(data);
        }
        catch(error){
            console.error(error);
        }
    }

    async getLearningObjectives(applicationMetadata,userObject,productData,appId){
        console.log('generate learning objectives'); 
        try{
            const token = await this.mainService.getUserToken();

            const res = await fetch (API_URL+'/generatelearningobjectives',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({applicationMetadata,userObject,productData,appId,token}),
            });
            let data = await res.json(); 
            console.log(data);
        }
        catch(error){
            console.error(error);
        }
    }

    //
    //budget
    //
    
    addBudgetSummaryListener(appId,listener){
        this.mainService.addDataListener('/budgetSummary/' + appId , listener);
    }

    removeBudgetSummaryListener(appId,listener){
        this.mainService.removeDataListener('/budgetSummary/' + appId , listener);
    }

    addBudgetLinesListener(appId,listener){
        this.mainService.addDataListener('/budgetLines/' + appId , listener);
    }

    removeBudgetLinesListener(appId,listener){
        this.mainService.removeDataListener('/budgetLines/' + appId , listener);
    }    

    addBudgetSectionsListener(appId,listener){
        this.mainService.addDataListener('/budgetSections/' + appId , listener);
    }

    removeBudgetSectionsListener(appId,listener){
        this.mainService.removeDataListener('/budgetSections/' + appId , listener);
    }  
    
    //TODO make so always a blank line by integrating new line data into here
    async addBudgetLine(appId,sections,sectionId,currency,sectionName='',pos=null,unitType='unit'){
        console.log('adding new budget line');
        const db = getDatabase();

        const data = {
            item:'',
            quantity:0,
            unitPrice:0,
            unitType, //TODO add options to PP
            description:`0 x ${getCurrencyAdornment(currency)}0/unit`,
            total: 0
        };

        let newSectionId='';
        if(!sectionId){
            newSectionId = push( ref(db, `budgetSections/${appId}`) ).key;
        }

        //add new line and add to section
        const newBudgetLineKey = push( ref(db, `budgetLines/${appId}`) ).key;
        let sectionLines = sectionId && sections && sections[sectionId]?.lines ?  [...sections[sectionId].lines] : [];
        
        if(pos !== null){
            console.log('splicing line')
            sectionLines.splice(pos,0,newBudgetLineKey);
        }                 
        else{
            console.log('pushing line')
            sectionLines.push(newBudgetLineKey);
        }
           
        //do batch of updates
        const updates = {}
        updates[`budgetLines/${appId}/${newBudgetLineKey}`] = data;
        if(sectionId)
            updates[`budgetSections/${appId}/${sectionId}/lines`] = sectionLines;

        else {
            let newSections = Object.keys(sections);
            newSections.push(newSectionId)
            updates[`budgetSections/${appId}/${newSectionId}/name`] = sectionName;
            updates[`budgetSections/${appId}/${newSectionId}/lines`] = sectionLines;
            updates[`budgetSections/${appId}/${newSectionId}/total`] = data.total ?? 0;
            updates[`budgetSections/${appId}/${newSectionId}/open`] = true;
            updates[`budgetSummary/${appId}/sections`] = newSections;
        }

        return update(ref(db), updates);
    }

    async removeBudgetLine(appId,budgetLineId,sections,sectionId,total,sectionTotal,grandTotal){
        console.log('removing budget line');

        const db = getDatabase();

        //remove line from section lines
        let sectionLines = [...sections[sectionId].lines];
        let inx = sectionLines.indexOf(budgetLineId);
        sectionLines.splice(inx,1);

        const newSectionTotal = sectionTotal - total;
        const newGrantTotal = grandTotal - total;
        
        //do batch of updates
        const updates = {}
        updates[`budgetLines/${appId}/${budgetLineId}`] = null;
        updates[`budgetSections/${appId}/${sectionId}/lines`] = sectionLines;
        updates[`budgetSections/${appId}/${sectionId}/total`] = newSectionTotal;
        updates[`budgetSummary/${appId}/total`] = newGrantTotal;
        
        await update(ref(db), updates);
    }

    async setBudgetLineValue(appId,budgetLineId,name,val){

        await set( ref( getDatabase(), `budgetLines/${appId}/${budgetLineId}/${name}`), val);
    }

    //generates description and updates budget totals
    async setBudgetValueAndDescription(appId,budgetLineId,quantity,currency,unitPrice,unitType,budgetSectionId,currentLineTotal,currentSubtotal,currentGrandTotal){
        const db = getDatabase();
        const description = `${quantity} x ${getCurrencyAdornment(currency)}${unitPrice}/${unitType}`;
        
        const newLineTotal = round(quantity*unitPrice,2)
        const newSubtotal = currentSubtotal - currentLineTotal + newLineTotal;
        const newGrandTotal = currentGrandTotal -currentSubtotal + newSubtotal;
        
        const updates = {}
        updates[`budgetLines/${appId}/${budgetLineId}/quantity`] = quantity ?? 0;
        updates[`budgetLines/${appId}/${budgetLineId}/unitPrice`] = unitPrice ?? 0;
        updates[`budgetLines/${appId}/${budgetLineId}/unitType`] = unitType ?? 'unit';
        updates[`budgetLines/${appId}/${budgetLineId}/description`] = description;
        updates[`budgetLines/${appId}/${budgetLineId}/total`] = newLineTotal;
        updates[`budgetSections/${appId}/${budgetSectionId}/total`] = newSubtotal;
        updates[`budgetSummary/${appId}/total`] = newGrandTotal;

        return update(ref(db), updates);

    }    

    async setSectionValue(appId,sectionId,name,val){
        await set( ref( getDatabase(), `budgetSections/${appId}/${sectionId}/${name}`), val);
    }

    async setBudgetStage(appId, val){
        await set( ref( getDatabase(), 'applicationMetadata/' + appId + '/budgetStage'), val);
    }

    addBudgetSection(appId,sectionids,pos=null){
        console.log('adding new section');
        const db = getDatabase();
        //add new section and add to summary
        const data = {
            name:'New section',
            lines:[],
            total:0,
            open:true
        }
        
        //change to list to preserve order
        const newSectionId = push( ref(db, `budgetSections/${appId}`) ).key;
        let newSectionIds = [...sectionids]
        
        if(typeof pos !== 'null')
            newSectionIds.splice(pos,0,newSectionId);
        else
            newSectionIds.push(newSectionId);
        
        
        const updates = {}
        updates[`budgetSections/${appId}/${newSectionId}`] = data;
        updates[`budgetSummary/${appId}/sections`] = newSectionIds;
        return update(ref(db), updates);
    }

    removeBudgetSection(appId,sectionIds,sectionId,sectionTotal,sectionLines,grandTotal){
        console.log('removing section');
        const db = getDatabase();
               
        //recalculate grand total
        const newGrandTotal = grandTotal - sectionTotal;

        //update lines
        let newSectionIds = [...sectionIds];

        const inx = newSectionIds.indexOf(sectionId);
        newSectionIds.splice(inx,1);

        const updates = {};

        //remove all lines
        for(const lid of sectionLines ? sectionLines : []) {
            updates[`budgetLines/${appId}/${lid}`] = null;
        }
        
        //remove section data
        updates[`budgetSections/${appId}/${sectionId}`] = null;

        //remove id from budget summary lines
        updates[`budgetSummary/${appId}/sections`] = newSectionIds;

        //update budget summary grand total
        updates[`budgetSummary/${appId}/total`] = newGrandTotal;

        return update(ref(db), updates);
    
    }

    async createInitialBudget(appId,applicationMetadata,productData){

        console.log('creating budget objects');

        const db = getDatabase();

        //delete any existing content before continuing
        const deletes = {}
        deletes[`budgetSummary/${appId}`] = null;
        deletes[`budgetSections/${appId}`] = null;
        deletes[`budgetLines/${appId}`] = null;

        await update(ref(db), deletes);

        const item = applicationMetadata.name;
        const quantity = parseInt(applicationMetadata.eligibilityProductCount);  
        const unitType = productData?.basePricing?.unitType ? productData.basePricing.unitType : 'unit'  
        const unitPrice = parseInt(productData.basePricing.amount);
        const currency = productData?.basePricing?.currency ?? 'USD';
        const description = `${quantity} x ${getCurrencyAdornment(currency)}${unitPrice}/${unitType}`;
        const total = parseInt(applicationMetadata.eligibilityProductCount) * parseInt(productData.basePricing.amount);

        const budgetLineData = {
            item,
            quantity,
            unitPrice,
            unitType,
            description,
            total: total ?? 0
        }

        //push new budget line & section key
        const budgetLineKey = push( ref(db, `budgetLines/${appId}`) ).key;
        const budgetSectionKey = push(ref(db, `budgetSections/${appId}`)).key;

        //create budget sections data with new key
        const budgetSectionsData = {
            name:'Supplies & materials',
            lines:[budgetLineKey],
            open:true,
            total: total
        }

        const budgetSummaryData = {
            open:true,
            currency,
            total,
            sections:[budgetSectionKey]
        }

        //do batch of updates
        const updates = {}
        updates[`budgetLines/${appId}/${budgetLineKey}`] = budgetLineData;
        updates[`budgetSections/${appId}/${budgetSectionKey}`] = budgetSectionsData;
        updates[`budgetSummary/${appId}`] = budgetSummaryData;
        updates[`applicationMetadata/${appId}/budgetStage`] = 'inProgress';
        updates[`applicationDashboardData/${appId}/unitPrice`] = unitPrice;

        return update(ref(db), updates);
    }

    async updateSourcesInApp(appId){
        const db = getDatabase();

        //get the node data
        let nodeData = await get( ref( db, `applicationContent/${appId}`) ); 
        if(!nodeData.val())
            return ; 
        nodeData = nodeData.val(); 

        //get the application sources
        let sources = await get( ref( db, `applicationSources/${appId}`) ); 
        if(!sources.val())
            return ; 
        sources = sources.val(); 

        //get the source section in the application
        let sourcesSectionQ = this.queryFirstNode(nodeData,{tags:['sources']}); 
        if(!sourcesSectionQ.value){
            return; 
        }
        let sourcesSectionId = sourcesSectionQ.key;

        //get the container for the source 
        let sourcesContainerQ = this.queryFirstNode(nodeData,{types:['container']}, sourcesSectionId);
        if(!sourcesContainerQ.value){
            return; 
        }
        let sourcesContainerId = sourcesContainerQ.key; 
        let sourcesContainer = sourcesContainerQ.value;

        //delete any nodes recursivly inside the container
        if(sourcesContainer.children && Array.isArray(sourcesContainer.children)){
            for(let childid of sourcesContainer.children){
                await this.deleteContentNode(appId, childid,true); 
            }
        }

        //check contense of sources 
        if(!sources || !sources.sourceList || !Array.isArray(sources.sourceList)){
            return;
        }

        //addd sources 
        let applicationContentUpdates = {}

        applicationContentUpdates[sourcesContainerId] = sourcesContainer;
        sourcesContainer.loading = false; 
        sourcesContainer.children = [];

        let contentK = ()=>push( ref(db, `applicationContent/${appId}`) ).key;

        //create the list 
        let list = {type:'list', style:{lineSpacing:1, beforeSpacing:8,}, children:[]};
        let listK = contentK(); 
        applicationContentUpdates[listK] = list;

        sourcesContainer.children.push(listK);

        //itterate over each source and add to a list of sources
        const addItem = (list, data, fontSize=12, fontWeight='400', color='#222222')=>{
            let paragraph = {type:'paragraph',  children:[], style:{lineSpacing:1.5, beforeSpacing:0, afterSpacing:0, }}; 
            let paragraphK = contentK(); 
            applicationContentUpdates[paragraphK] = paragraph;

            list.children.push(paragraphK); 

            let text = {type:'text', data:data, style:{fontFace:'Inter', fontSize:fontSize, fontWeight:fontWeight, color:color }}; 
            let textK = contentK(); 
            applicationContentUpdates[textK] = text;

            paragraph.children.push(textK); 
        }

        for(let source of sources.sourceList){
            addItem(list, source.data); 
        }

        //Update fb
        await update(ref(db, 'applicationContent/' + appId), applicationContentUpdates);
    }

    async updateBudgetInApp(appId){

        const db = getDatabase();

        //get the node data
        let nodeData = await get( ref( getDatabase(), `applicationContent/${appId}`) ); 
        if(!nodeData.val())
            return ; 
        nodeData = nodeData.val(); 

        //get the budget node
        let budgetSectionQ = this.queryFirstNode(nodeData,{tags:['budget']}); 
        if(!budgetSectionQ.value){
            return; 
        }
        let budgetSectionId = budgetSectionQ.key;

        //get budget container node
        let budgetContainerQ = this.queryFirstNode(nodeData,{types:['container']}, budgetSectionId);
        if(!budgetContainerQ.value){
            return; 
        }
        let budgetContainerId = budgetContainerQ.key; 
        let budgetContainer = budgetContainerQ.value;

        //delete any nodes recursivly inside the container
        if(budgetContainer.children && Array.isArray(budgetContainer.children)){
            for(let childid of budgetContainer.children){
                await this.deleteContentNode(appId, childid,true); 
            }
        }
        
        //aquire and denormalize the budget data to be rendered 
        let budgetData = {
            total:0, 
            sections: [],
        }; 

        let budgetSummary = await get( ref( getDatabase(), `budgetSummary/${appId}`) ); 
        if(!budgetSummary.val())
            return ; 
        budgetSummary = budgetSummary.val();

        let budgetSections = await get( ref( getDatabase(), `budgetSections/${appId}`) ); 
        if(!budgetSections.val())
            return ; 
        budgetSections = budgetSections.val();

        let budgetLines = await get( ref( getDatabase(), `budgetLines/${appId}`) ); 
        if(!budgetLines.val())
            return ; 
        budgetLines = budgetLines.val();
        
        if(budgetSummary && budgetSummary.sections && Array.isArray(budgetSummary.sections)){

            budgetData.total = budgetSummary.total;

            for(let sectionId of budgetSummary.sections){
                
                if(!budgetSections || typeof sectionId != 'string')
                    continue; 

                let section = budgetSections[sectionId]; 

                if(!section)
                    continue; 

                let denormSection = {lines:[], name:section.name};

                if(section && section.lines && Array.isArray(section.lines)){
                    for(let lineId of section.lines){
                        
                        if(!budgetLines || typeof lineId != 'string')
                            continue; 

                        let line = budgetLines[lineId]; 

                        if(line)
                            denormSection.lines.push(line); 
                    }
                }

                budgetData.sections.push(denormSection)
            }
        }

        //Build node data in application from budget data
        let applicationContentUpdates = {}

        applicationContentUpdates[budgetContainerId] = budgetContainer;
        budgetContainer.loading = false; 
        budgetContainer.children = [];

        let contentK = ()=>push( ref(db, `applicationContent/${appId}`) ).key;

        let table = {type:'table', style:{lineSpacing:1, x:24.5, width:'100%', beforeSpacing:8, afterSpacing:4}, children:[]};
        let tableK = contentK(); 
        applicationContentUpdates[tableK] = table;

        budgetContainer.children.push(tableK); 

        const addRow = (backgroundColor='#dc7b42', height=11) =>{
            let tableRow = {type:'table_row', style:{backgroundColor:backgroundColor, height:height}, children:[]};
            let tableRowK = contentK(); 
            applicationContentUpdates[tableRowK] = tableRow;

            table.children.push(tableRowK); 
            return tableRow;
        }

        const addCell = (row, text, fontSize=13, fontWeight='400', color='#222222', rowSpan=0)=>{

            let tableCell = {type:'table_cell', style:{ paddingLeft:3, paddingTop:0 }, children:[]}; 
            if(rowSpan)
                tableCell.rowSpan = rowSpan; 
            let tableCellK = contentK(); 
            applicationContentUpdates[tableCellK] = tableCell;

            row.children.push(tableCellK); 

            let cellParagraph = {type:'paragraph',  children:[], style:{lineSpacing:1.5, beforeSpacing:0, afterSpacing:0, }}; 
            let cellParagraphK = contentK(); 
            applicationContentUpdates[cellParagraphK] = cellParagraph;

            tableCell.children.push(cellParagraphK); 

            let cellText = {type:'text', data:text, style:{fontFace:'Inter', fontSize:fontSize, fontWeight:fontWeight, color:color }}; 
            let cellTextK = contentK(); 
            applicationContentUpdates[cellTextK] = cellText;

            cellParagraph.children.push(cellTextK); 
        }

        //heading 
        let headingRow = addRow(); 
        addCell(headingRow, 'Budget Item', 13, '400', '#ffffff'); 
        addCell(headingRow, 'Description', 13, '400', '#ffffff'); 
        addCell(headingRow, 'Total Cost', 13, '400', '#ffffff'); 
        
        for(let section of budgetData.sections){
            let sectionRow = addRow('#eeeeee'); 
            addCell(sectionRow, section.name, 13, '500', '#222222', 3);

            for(let line of section.lines){
                let lineRow = addRow('#eeeeee'); 
                addCell(lineRow, line.item, 12, '400', '#222222'); 
                addCell(lineRow, line.description, 12, '400', '#222222'); 
                addCell(lineRow, '$'+line.total, 12, '400', '#222222'); 
            }
        }

        let totalRow = addRow('#eeeeee');
        addCell(totalRow, 'Total', 12, '500', '#222222', 2); 
        addCell(totalRow, '$'+budgetData.total, 12, '500', '#222222');

        //update fb
        await update(ref(db, 'applicationContent/' + appId), applicationContentUpdates);
        
    }


    //Application content 

    addModifiersListner(appId, listener){
        this.mainService.addDataListener('applicationModifiers/' + appId, listener);
    }

    removeModifiersListner(appId, listener){
        this.mainService.removeDataListener('applicationModifiers/' + appId, listener);
    }

    buildNodeParentMap(nodeData, startNodeId){
        let parentMap = new Map(); 

        if(!nodeData || !nodeData[startNodeId])
            return parentMap;

        let startNode = nodeData[startNodeId]; 

        const processNode = (node, nodeid)=>{
            let children = node.children;
            
            if(!children)
                return; 

            for(let childid of children){
                let child = nodeData[childid];
                if(child){
                    parentMap.set(childid, nodeid); 
                    processNode(child, childid);
                } 
            }
        }

        processNode(startNode, startNodeId); 

        return parentMap; 
    }

    
    /*
    async purgeStylesAndNodes = ()=>{

    }*/

    async addModifier(appId, modifier, textSelection, nodeData){
        //create modifier in database
        const db = getDatabase();
        let key = push( ref(db, `applicationModifiers/${appId}`) ).key;
       
        
        //create new origiona nodes and new nodes children to replace parant
        let modifierNodes = []; 
        let modifierNewNode = ''; 
        let nodesUpdate = {}; 
        for(let nodeInfo of textSelection.nodes){

            //get node and parent in selection
            let nodeId = nodeInfo.id; 
            let parentId = nodeInfo.parentId; 
            let start = nodeInfo.start; 
            let end = nodeInfo.end; 

            //get node and parent
            let node = nodeData[nodeId]; 
            let parent = nodeData[parentId]; 

            let text = node.data; 

            //if the selection in between the start and the end of the node, split it in the middle
            console.log('start '+start + ' end ' + end +' text.length ' + text.length)
            //if(start >= 0 && end <= text.length)
            {
                let startText = text.substring(0, start);
                let startNode = {...node}; //clone node
                startNode.data = startText; //det new text
                nodesUpdate[nodeId] = startText.length > 0 ? startNode : null; 

                //create a new node for the modifier/selected part of the text 
                let midText = text.substring(start, end);
                let midNode = {...node}; //clone node
                midNode.data = midText; 
                let midNodeKey = push( ref(db, `applicationContent/${appId}`) ).key;
                nodesUpdate[midNodeKey] = midNode; 
                let inx = parent.children.indexOf(nodeId); 
                parent.children.splice(inx+1, 0, midNodeKey); 
                nodesUpdate[parentId] = parent; 
                modifierNodes.push(midNodeKey); 

                //create a new node for the modifier/selected when it is enabled 
                let midNewText = '---Prcessing---';
                let midNewNode = {...node}; //clone node
                midNewNode.data = midNewText; 
                midNewNode.hidden = true; 
                let midNewNodeKey = push( ref(db, `applicationContent/${appId}`) ).key;
                nodesUpdate[midNewNodeKey] = midNewNode; 
                parent.children.splice(inx+2, 0, midNewNodeKey); 
                modifierNewNode = midNewNodeKey; 

                //create a new node for the end part of the text 
                let endText = text.substring(end, text.length);
                let endNode = {...node}; //clone node
                endNode.data = endText; 
                let endNodeKey = push( ref(db, `applicationContent/${appId}`) ).key;
                if(endText.length > 0){
                    nodesUpdate[endNodeKey] = endNode; 
                    parent.children.splice(inx+3, 0, endNodeKey); 
                    nodesUpdate[parentId] = parent; 
                }

               
            }
            
        }

        console.log(nodesUpdate)

        await update(ref(db, 'applicationContent/' + appId), nodesUpdate);

        modifier.origNodes = modifierNodes; 
        modifier.newNode = modifierNewNode; 
        await set( ref( db, `applicationModifiers/${appId}/${key}`), modifier);

        //request modifier content from server
        try{
            const userToken = await this.mainService.getUserToken();
            
            let data = {userToken, appId, modifierId:key}; 

            const res = await fetch( API_URL+'/createmodifiercontent', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            const json = await res.json();

            if(!json.ok){
                console.log(json); 
                throw json.message;
            }
        }
        catch(err){
            console.error('Content generation Error:', err);
            this.deleteModifier(appId, key)
        };
    }
    
    async applyModifier(appId, modifierId){
        let modifier = await get( ref( getDatabase(), `applicationModifiers/${appId}/${modifierId}`) );
        if(!modifier.val())
            return; 
        modifier = modifier.val(); 

        let nodeData = await get( ref( getDatabase(), `applicationContent/${appId}`) ); 
        if(!nodeData.val())
            return ; 
        nodeData = nodeData.val(); 
        

        //get all new nodes of the modifier and remove them from parent and content
        let parentMap = this.buildNodeParentMap(nodeData, 'rootNode'); 

        let nodeUpdates = {}; 

        //get all old nodes and delete them
        let origNodesIds = modifier.origNodes; 
        for (let origNodeId of origNodesIds){
            nodeUpdates[origNodeId] = null; 
            let newNodeParentId = parentMap.get(origNodeId); 
            let parentNode = nodeData[newNodeParentId]; 
            let nodeInx = parentNode.children.indexOf(origNodeId); 
            parentNode.children.splice(nodeInx, 1); 
            nodeUpdates[newNodeParentId] = parentNode;
        }

        //get new node
        let newnodeId = modifier.newNode; 
        let newNodeParentId = parentMap.get(newnodeId); 
        let parentNode = nodeData[newNodeParentId]; 

        //loop over all children in the parent, if the node is not in the orig nodes, continue and set default node, else add orig node text to default
        let inx = 0;
        let initNodeId = '';
        let initNode = null;  
        let newChildren = []; 
        let prevNodeWasNew = false; 
        for(let childid of parentNode.children){

            let childnode = nodeData[childid];
            if(!childnode)
                continue; 
            
            //TODO match styles
            if(inx != 0 && childid == newnodeId || prevNodeWasNew){
                 
                if(childnode && childnode.data)
                    initNode.data += childnode.data; //combine data for nodes (roll them together)
                nodeUpdates[initNodeId] = initNode; 
            }
            else {
                initNodeId = childid;
                initNode = nodeData[childid]; 
                newChildren.push(childid);
            }

            prevNodeWasNew = childid == newnodeId ? true : false; 

            inx++;
        }

        //delete all children node not in newNodes (ones that got rolled up)
        for(let childid of parentNode.children ){
            if(newChildren.indexOf(childid) < 0){
                nodeUpdates[childid] = null; 
            }
        }

        //make sure all the child nodes are visible
        for(let childid of newChildren ){
            let node =nodeData[childid];
            if(node){
                node.hidden = null; 
                nodeUpdates[childid] = node; 
            }     
        }

        //update children for parent node
        parentNode.children = newChildren; 
        nodeUpdates[newNodeParentId] = parentNode; 
        
        let promiseList = []; 
        promiseList.push( set( ref( getDatabase(), `applicationModifiers/${appId}/${modifierId}`), null) );
        promiseList.push( update( ref( getDatabase(), `applicationContent/${appId}`), nodeUpdates) );

        await Promise.all(promiseList); 
    }

    async deleteModifier(appId, modifierId){
        let modifier = await get( ref( getDatabase(), `applicationModifiers/${appId}/${modifierId}`) );
        if(!modifier.val())
            return; 
        modifier = modifier.val(); 

        let nodeData = await get( ref( getDatabase(), `applicationContent/${appId}`) ); 
        if(!nodeData.val())
            return ; 
        nodeData = nodeData.val(); 
        

        //get all new nodes of the modifier and remove them from parent and content
        let parentMap = this.buildNodeParentMap(nodeData, 'rootNode'); 

        let nodeUpdates = {}; 

        //get new node and delete it 
        let newnodeId = modifier.newNode; 
        nodeUpdates[newnodeId] = null; 
        //find the parent node and remove the new node from its children 
        let newNodeParentId = parentMap.get(newnodeId); 
        
        let parentNode = nodeData[newNodeParentId]; 
        
        let newNodeInx = parentNode.children.indexOf(newnodeId); 
        parentNode.children.splice(newNodeInx, 1); 
        nodeUpdates[newNodeParentId] = parentNode;

        //get orig nodes and add the n back to the first node, then delete them
        let origNodesIds = modifier.origNodes; 
        let origNodesIdsSet = new Set(origNodesIds); 
        let parentFinishedSet = new Set(); 

        console.log(modifier)
        
        //loop over all origNode ids 
        for (let origNodeId of origNodesIds){
            let origNodeParentId = parentMap.get(origNodeId); 

            if(parentFinishedSet.has(origNodeParentId)){
                continue; 
            }
            parentFinishedSet.add(origNodeParentId); 

            let origParentNode = nodeData[origNodeParentId]; 
            console.log(origParentNode); 
            
            
            //loop over all children in the parent, if the node is not in the orig nodes, continue and set default node, else add orig node text to default
            let inx = 0;
            let initNodeId = '';
            let initNode = null;  
            let newChildren = []; 
            let prevNodeWasOrig = false; 
            for(let childid of origParentNode.children){

                let childnode = nodeData[childid];
                if(!childnode){
                    continue; 
                }
                
                //TODO match styles
                if(inx != 0 && origNodesIdsSet.has(childid) || prevNodeWasOrig){
                     
                    if(childnode.data)
                        initNode.data += childnode.data; //combine data for nodes (roll them together)
                    nodeUpdates[initNodeId] = initNode; 
                }
                else {
                    initNodeId = childid;
                    initNode = nodeData[childid]; 
                    newChildren.push(childid);
                }

                prevNodeWasOrig = origNodesIdsSet.has(childid) ? true : false; 

                inx++;
            }

            //delete all children node not in newNodes (ones that got rolled up)
            for(let childid of origParentNode.children ){
                if(newChildren.indexOf(childid) < 0){
                    nodeUpdates[childid] = null; 
                }
            }

            //make sure all the child nodes are visible
            for(let childid of newChildren ){
                let node =nodeData[childid];
                if(node){
                    node.hidden = null; 
                    nodeUpdates[childid] = node; 
                }     
            }

            console.log(newChildren)

            //update children for parent node
            origParentNode.children = newChildren; 
            nodeUpdates[origNodeParentId] = origParentNode; 
        }
        
        let promiseList = []; 
        promiseList.push( set( ref( getDatabase(), `applicationModifiers/${appId}/${modifierId}`), null) );
        promiseList.push( update( ref( getDatabase(), `applicationContent/${appId}`), nodeUpdates) );

        await Promise.all(promiseList); 
    }

    async setModifierToggle(appId, modifierId, value){
        let modifierSnap = await get( ref( getDatabase(), `applicationModifiers/${appId}/${modifierId}`), );
        if(!modifierSnap.val())
            return; 

        let modifier = modifierSnap.val(); 

        let origNodesIds = modifier.origNodes; 
        let newNodeId = modifier.newNode; 

        let promiseList = []
        promiseList.push( set( ref( getDatabase(), `applicationContent/${appId}/${newNodeId}/hidden`), value?null:true) );
        for(let origNodeId of origNodesIds){
            promiseList.push( set( ref( getDatabase(), `applicationContent/${appId}/${origNodeId}/hidden`), value ? true:null) ); 
        }
        
        promiseList.push( set( ref( getDatabase(), `applicationModifiers/${appId}/${modifierId}/enabled`), value?true:false) );
        
        await Promise.all(promiseList); 
    }

    async deleteContentNode(appId, nodeid, recursive=false){
        let nodeData = await get( ref( getDatabase(), `applicationContent/${appId}`) ); 
        if(!nodeData.val())
            return ; 
        nodeData = nodeData.val(); 
        let contentUpdates = {} 

        //get parent
        let parentMap = this.buildNodeParentMap(nodeData, 'rootNode');

        //get the parent node 
        let parentid = parentMap.get(nodeid); 
        if(parentid){
            let parentNode = nodeData[parentid];

            //get the index of the nid in the parent and splice it out of the child list
            const index = parentNode.children.indexOf(nodeid);
            if(index >= 0){ 
                parentNode.children.splice(index, 1); 
                contentUpdates[parentid] = parentNode; 
            }
        }
        
        const deleteNode = (nid)=>{
            //get node
            let node = nodeData[nid];

            if(node){

                //delete node 
                contentUpdates[nid] = null; 

                //recure over children
                if(recursive && node.children && Array.isArray(node.children)){
                    for(let cid of node.children){
                        deleteNode(cid); 
                    }
                }
            }
        }

        deleteNode(nodeid); 
        await update( ref( getDatabase(), `applicationContent/${appId}`), contentUpdates); 
    }

    async moveContentNode(appId, nodeid, moveDirection){
        let nodeData = await get( ref( getDatabase(), `applicationContent/${appId}`) ); 
        if(!nodeData.val())
            return ; 
        nodeData = nodeData.val(); 
        

        //get parent
        let parentMap = this.buildNodeParentMap(nodeData, 'rootNode');
        let newNodeParentId = parentMap.get(nodeid); 
        if(!newNodeParentId){
            return; 
        }

        let parentNode = nodeData[newNodeParentId];

        //move 
        let promiseList = []
        const index = parentNode.children.indexOf(nodeid);
        if(index < 0)
            return; 

        parentNode.children.splice(index, 1);
        
        if(moveDirection == 'up' && index > 0){
            parentNode.children.splice(index-1, 0, nodeid); 
            promiseList.push( set( ref( getDatabase(), `applicationContent/${appId}/${newNodeParentId}`), parentNode) );
        }
        else if(moveDirection == 'down' && index < parentNode.children.length){
            parentNode.children.splice(index+1, 0, nodeid); 
            promiseList.push( set( ref( getDatabase(), `applicationContent/${appId}/${newNodeParentId}`), parentNode) );
        }

        await Promise.all(promiseList); 
    }

    async getContentNodeText(appId, nodeid){
        let nodeData = await get( ref( getDatabase(), `applicationContent/${appId}`) ); 
        if(!nodeData.val())
            return ; 
        nodeData = nodeData.val(); 

        let getText = (id, depth=0)=>{
            let text = '';
            let node = nodeData[id];
            if(!node)
                return text; 

            if(node.type == 'text' && node.data){
                text += node.data 
                return text; 
            }

            if(depth > 50)
                return text; 

            if(node.children && Array.isArray(node.children)){
                for(let i = 0; i < node.children.length; i++){
                    let childid = node.children[i]; 
                    text += getText(childid, depth+1); 
                    if(i < node.children.length-1){
                        text += '\n'; 
                    }
                }
            }
            return text; 
        }

        return getText(nodeid)
    }

    async addNewParagraph(appId, parentid, index){
        let nodeData = await get( ref( getDatabase(), `applicationContent/${appId}`) ); 
        if(!nodeData.val())
            return ; 
        nodeData = nodeData.val(); 
        
        //get parent node
        if(!parentid){
            return; 
        }
        let parentNode = nodeData[parentid];

        //create a new node with default values etc. of type
        let children = parentNode.children; 

        if (!children || !Array.isArray(children)){
            return; 
        }

        if(index < 0 || index > children.length)
            return; 

        const db = getDatabase();  
        
        let promiseList = []

        //create style objects 
        let textNodeStyle = {fontFace:'Inter', fontSize:13, fontWeight:'400', color:'#222222'}; 
        let KTextStyle = push( ref(db, `applicationStyle/${appId}`) ).key;
        promiseList.push( set( ref( db, '/applicationStyle/' + appId + '/' + KTextStyle), textNodeStyle));
        
        let pNodeStyle = {lineSpacing:1.5, beforeSpacing:0, afterSpacing:8, }; 
        let KPStyle = push( ref(db, `applicationStyle/${appId}`) ).key;
        promiseList.push( set( ref( db,'/applicationStyle/' + appId + '/' + KPStyle), pNodeStyle));

        //create a paragraph to insert into the document 
        let textNode = {type:'text', data: 'NEW PARAGRAPH', style:KTextStyle}; 
        let KText = push( ref(db, `applicationContent/${appId}`) ).key;
        promiseList.push( set( ref( db, '/applicationContent/' + appId + '/' + KText), textNode));
        
        let pNode = {type:'paragraph', children:[KText], style:KPStyle, movable:true, deletable:true, editable:true, aiEditable:true}; 
        let KP = push( ref(db, `applicationContent/${appId}`) ).key; 
        promiseList.push( set( ref( db, '/applicationContent/' + appId + '/' + KP), pNode));


        parentNode.children.splice(index, 0, KP); 

        
        promiseList.push( set( ref( db, `applicationContent/${appId}/${parentid}`), parentNode) );
        await Promise.all(promiseList); 
    }

    async addNewSection(appId, parentid, index, title='NEW SECTION', paragraphData=['NEW PARAGRAPH'], loading=false){
        let nodeData = await get( ref( getDatabase(), `applicationContent/${appId}`) ); 
        if(!nodeData.val())
            return ; 
        nodeData = nodeData.val(); 
        
        //get parent node
        if(!parentid){
            return; 
        }
        let parentNode = nodeData[parentid];

        //create a new node with default values etc. of type
        let children = parentNode.children; 

        if (!children || !Array.isArray(children)){
            return; 
        }

        if(index < 0 || index > children.length)
            return; 

        const db = getDatabase();  
        
        let promiseList = []


        let applicationContent = {}
        let applicationStyle = {}
        //create style objects for text and h TODO
        let titleTextNodeStyle = {fontFace:'Inter', fontSize:18, fontWeight:'500', color:'#DC7B42'};  
        let KTitleTextStyle = push( ref(db, `applicationStyle/${appId}`) ).key;
        applicationStyle[KTitleTextStyle] = titleTextNodeStyle; 
        
        let hNodeStyle = {lineSpacing:1.5, beforeSpacing:0, afterSpacing:8, };
        let KHStyle = push( ref(db, `applicationStyle/${appId}`) ).key;
        applicationStyle[KHStyle] = hNodeStyle; 

        //create node for the title of the section 
        let titleTextNode = {type:'text', data: title, style:KTitleTextStyle}; 
        let KTitleText = push( ref(db, `applicationContent/${appId}`) ).key;
        applicationContent[KTitleText] = titleTextNode; 

        let titleNode = {type:'heading1', children:[KTitleText],style:KHStyle, tag:'title', editable:true}; 
        let KTitle = push( ref(db, `applicationContent/${appId}`) ).key; 
        applicationContent[KTitle] = titleNode;

        //create container node for body of section
        let sectionContainerNode = {type:'container', loading:loading, children:[]};
        let KContainer = push( ref(db, `applicationContent/${appId}`) ).key;
        applicationContent[KContainer] = sectionContainerNode;

        //create a section node
        let sectionNode = {type:'section', children:[KTitle, KContainer], movable:true, deletable:true, }; 
        let KSection = push( ref(db, `applicationContent/${appId}`) ).key;
        applicationContent[KSection] = sectionNode; 


        //add paragraphs
        for(let data of paragraphData){
            //create style objects 
            let textNodeStyle = {fontFace:'Inter', fontSize:13, fontWeight:'400', color:'#222222'}; 
            let KTextStyle = push( ref(db, `applicationStyle/${appId}`) ).key;
            applicationStyle[KTextStyle] = textNodeStyle;
            
            let pNodeStyle = {lineSpacing:1.5, beforeSpacing:0, afterSpacing:8, }; 
            let KPStyle = push( ref(db, `applicationStyle/${appId}`) ).key;
            applicationStyle[KPStyle] = pNodeStyle; 

            //create a paragraph to insert into the document 
            let textNode = {type:'text', data: data, style:KTextStyle}; 
            let KText = push( ref(db, `applicationContent/${appId}`) ).key;
            applicationContent[KText] = textNode;
            
            let pNode = {type:'paragraph', children:[KText], style:KPStyle, movable:true, deletable:true, editable:true, aiEditable:true}; 
            let KP = push( ref(db, `applicationContent/${appId}`) ).key; 
            applicationContent[KP] = pNode;

            sectionContainerNode.children.push(KP); 
        }


        parentNode.children.splice(index, 0, KSection); 

        promiseList.push( update( ref( db, `applicationStyle/${appId}`), applicationStyle) );
        promiseList.push( update( ref( db, `applicationContent/${appId}`), applicationContent) );
        promiseList.push( set( ref( db, `applicationContent/${appId}/${parentid}`), parentNode) );
        await Promise.all(promiseList); 

        return {containerId:KContainer, sectionId:KSection}; 
    }
    
    async addNewQuestionAnswer(appId, applicationMetadata, userObject, productData, parentid, index, questionText, paragraphData=['NEW PARAGRAPH']){
        let newSectionData = await this.addNewSection(appId, parentid, index, questionText, paragraphData, true); 
        if(!newSectionData)
            return; 

        let containerId = newSectionData.containerId; 

        //grab data
        const summary = await this.getProjectSummary(appId);
        const contentData = {title:questionText, contentType:'question',}

        //send request for content data to be created
        try{
            const userToken = await this.mainService.getUserToken();
            
            let data = {userToken, appId, applicationMetadata, containerId, contentData, userObject, productData, summary}; 

            const res = await 
            fetch( API_URL+'/generatequestionanswer', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            const resJson = await res.json();

            return resJson;
        }
        catch(err){
            console.error('Content generation Error:', err);
        };

    }

    typeChar(appId, selection, nodeData, newChar){
        if(!selection || !selection.nodes || selection.nodes.length == 0)
                return;

        //get the first node
        let nodeInfo = selection.nodes[0]; 

        let id = nodeInfo.id; 
        let start = nodeInfo.start; 

        //get the node text 
        if(!nodeData || !nodeData[id] || start < 0)
            return; 
        let text = nodeData[id].data; 
        if(!text)
            text = ''; 

        if(typeof text != 'string')
            text = ''; 

        //insert char 
        if(newChar == 'backspace'){
            let a = start - 1
            if(a<0)
                a = 0; 
            let newText = text.slice(0, a) + text.slice(start);
            //write to db
            const db = getDatabase(); 
            set( ref( db, `applicationContent/${appId}/${id}/data`), newText)
            
            return -1;
        }
        if(newChar == 'delete'){
            let a = start + 1
            if(a>text.length)
                a = text.length; 
            let newText = text.slice(0, start) + text.slice(a);
            //write to db
            const db = getDatabase(); 
            set( ref( db, `applicationContent/${appId}/${id}/data`), newText)
            
            return -1;
        }
        else{
            let newText = text.slice(0, start) + newChar + text.slice(start);
            //write to db
            const db = getDatabase(); 
            set( ref( db, `applicationContent/${appId}/${id}/data`), newText)
            
            return 1;
        }
    }

    async getNodeData(appId){
        let nodeData = await get( ref( getDatabase(), `applicationContent/${appId}`) ); 
        if(!nodeData.val())
            return null; 
        nodeData = nodeData.val(); 
    }

    queryNodes(nodeData, {types=[], tags=[]}, rootid='rootNode', firstOnly=false){
        
        //check node data is avalible and there is a root node
        if(!nodeData || !nodeData[rootid])
            return; 

        let rootNode = nodeData[rootid]; 

        //helper filter valid
        let filterArrayProcess = (filter)=>{
            let filterSet; 
            let hasFilter = filter && Array.isArray(filter) && filter.length > 0 ? true : false; 
            if(hasFilter)
                filterSet = new Set(filter); 
            else 
                filterSet = new Set(); 
            return {hasFilter, filterSet}; 
        }

        //build sets 
        let typesFilterObj = filterArrayProcess(types); 
        let tagsFilterObj = filterArrayProcess(tags); 
         
        let matchingNodes = new Map();
        let nodeTouched = new Set(); 
        let kill = false; 

        let searchNode = (nodeid)=>{
            //preflight 
            if(kill)
                return; 

            let node = nodeData[nodeid];
            if(!node)
                return; 

            //check the node has not been visited before (inf loop)
            if(nodeTouched.has(nodeid))
                return
            nodeTouched.add(nodeid); 

            //check node matches
            let match = (!typesFilterObj.hasFilter || typesFilterObj.filterSet.has(node.type)) && 
                        (!tagsFilterObj.hasFilter || tagsFilterObj.filterSet.has(node.tag)); 

            if(match){
                matchingNodes.set(nodeid, node); 
                if(firstOnly){
                    kill = true; 
                    return; 
                }
            }

            //recure
            let children = node.children; 
            if(!children || !Array.isArray(children))
                return; 
            for(let childid of children){
                searchNode(childid);
            }
        }  

        searchNode(rootid);  

        return matchingNodes; 
    }

    queryFirstNode(nodeData, {types=[], tags=[]}, rootid='rootNode'){
        let nodeSet = this.queryNodes(nodeData, {types, tags}, rootid, true); 

        let entry = nodeSet.entries().next().value; 
        if(entry && Array.isArray(entry) && entry.length == 2){
            return {key:entry[0], value:entry[1]}
        }
        return {key:'', value:null}; 
    }

    queryText(nodeData, nodeid){
       
        let getText = (id, depth=0)=>{
            let text = '';
            
            if(!id)
                return text; 

            let node = nodeData[id];
            if(!node)
                return text; 

            if(node.type == 'text' && node.data){
                text += node.data 
                return text; 
            }

            if(depth > 50)
                return text; 

            if(node.children && Array.isArray(node.children)){
                for(let i = 0; i < node.children.length; i++){
                    let childid = node.children[i]; 
                    text += getText(childid, depth+1); 
                    if(i < node.children.length-1){
                        text += '\n'; 
                    }
                }
            }
            return text; 
        }

        return getText(nodeid)
    }

    getParent(nodeData, nodeid){
       
        //get parent
        let parentMap = this.buildNodeParentMap(nodeData, 'rootNode');
        let newNodeParentId = parentMap.get(nodeid); 
        if(!newNodeParentId){
            return ''; 
        }
        return newNodeParentId;
    }


    findCommonParent(nodeData, nodeids){
        let parentMap = this.buildNodeParentMap(nodeData, 'rootNode');
        
        let parentSet = new Set(); 

        for(let nodeid of nodeids ){
            let parentid = parentMap.get(nodeid); 
        }
    }

    findSibling(nodeData, nodeids){
        let parentMap = this.buildNodeParentMap(nodeData, 'rootNode');
        
        let parentSet = new Set(); 

        for(let nodeid of nodeids ){
            let parentid = parentMap.get(nodeid); 
        }
    }


}