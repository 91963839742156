import React, {useState, useEffect, useContext } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import useQuery from '../useQuery'

import MainContext from "../MainContext"; 

import { Box, Typography, IconButton, Tab, Tabs, Button, InputBase, CircularProgress } from '@mui/material';
import theme from '../Theme';

import LogoutIcon from '@mui/icons-material/Logout';
import HelpIcon from '@mui/icons-material/Help';

import LoadingBackdrop from "../components/LoadingBackdrop";

import TabPanel from "./components/TabPanel";
import SchoolsTable from "./dashboardComponents/SchoolsTable";
import GrantsTable from "./dashboardComponents/GrantsTable";
import SearchBar from '../components/SearchBar'
import SearchResults from "./dashboardComponents/SearchResults";
import ProductListingsTable from "./dashboardComponents/ProductListingsTable";
import GeneratedContentTable from "./dashboardComponents/GeneratedContentTable";
import PPAccountTable from "./dashboardComponents/PPAccountTable";


const IntellectibaseMain = () => {
    //Use
	const mainService = useContext(MainContext);
    const navigate = useNavigate(); 
    let query = useQuery();
    query.append("schoolname","x")

    //STATES
    const [loading,setLoading] = useState(false);
    const [tabValue,setTabValue] = useState(0);
    const [view,setView] = useState('table');
    const [searchResults,setSearchResults] = useState([]);
    const [isSuperAdmin,setIsSuperAdmin] = useState(false);


    //HANDLERS
    const handleLogout = async ()=>{

        setLoading(true);
        try{
            await mainService.logout();
            setLoading(false);
            navigate("/login");           
        }
        catch(error){
            console.error(error);
        }        
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleStartSearch = (val) => {
        setSearchResults([]);
        if(val)
            setView('loading');     
        else{
            setView('table');
        }
            
    }

    const handleSearchResults = (results) => {
        setView('search'); 
        if(results){          
            setSearchResults(results);
        }
            
    }

    const handleClearSearch = () => {
        //
        setView('table');
        
    }

    const getIsSuperAdmin = async () => {
        const result = await mainService.intellectibaseService.isSuperAdminUser();
        setIsSuperAdmin(result);
    }

    //LISTNERS

    //Effects
	useEffect(() => {
		//on mount
		console.log('Main mounted'); 
        getIsSuperAdmin();

		//on unmount
		return ()=>{
			console.log('Main unmounted');
		}

	}, []);

        //Effects
	useEffect(() => {
		//on mount
        let timer = null;
        if(!isSuperAdmin){
            timer = setTimeout(()=>{
                if(!isSuperAdmin)
                    navigate("/home")
            },2500)
        }

		//on unmount
		return ()=>{
			console.log('Main unmounted');
            if(timer)
                clearTimeout(timer);
		}

	}, [isSuperAdmin]);

 
    //RENDER


    return (
        <>

        {isSuperAdmin &&
        (<Box sx={{position:'relative', width:'100%', height:'100%', display:'flex', flexDirection:'column',gap:'4px',justifyContent:'center'}}>
            <LoadingBackdrop loading={loading}/>
            <Box
                sx={{
                    //backgroundColor:theme.palette.grey[200],
                    p:theme.spacing(1),
                    minHeight:"50px",
                    width:"100%",
                    overflow:"hidden",
                    borderBottom:theme.palette.grey[600],
                    display:"flex",
                    alignItems:"center",
                    boxShadow:`rgba(22, 45, 61, 0.12) 0px 2px 4px`
                }}
            >
                <Typography sx={{ml:'8px',color:theme.palette.primary.main}} variant='h4' >Intellectibase</Typography>

                <Box sx={{flexGrow:1,display:'flex',flexDirection:'row',justifyContent:'center'}}>
                    <Box sx={{width:'300px'}}>
                        <SearchBar type={tabValue === 0 ? 'grants' : 'schools'} displayText={tabValue === 0 ? 'grants' : 'schools'} onResults={handleSearchResults} onClear={handleClearSearch} onSearch={handleStartSearch}/>
                    </Box>
                </Box>
                <IconButton onClick={()=>{}}>
                    <HelpIcon/>
                </IconButton>
                <IconButton onClick={handleLogout}>
                    <LogoutIcon/>
                </IconButton>
            </Box>


            <Box sx={{ borderBottom: 1, borderColor: 'divider', flexGrow:1, overflow:"auto"}}>
                <Box>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="data tabs">
                        <Tab label="Grants" {...a11yProps(0)} />
                        <Tab label="Schools" {...a11yProps(1)} />
                        <Tab label="Product Listings" {...a11yProps(2)} />
                        <Tab label="Generated content" {...a11yProps(3)} />
                        <Tab label="Organization Accounts" {...a11yProps(4)} />
                    </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0} sx={{display:'flex',flexDirection:'column'}}>
                    {view === 'table' ? (
                        <GrantsTable/>): view === 'search' ? (
                        <SearchResults searchResults={searchResults} sx={{flexGrow:1,width:'100%',height:'100%'}}/>                       
                    ) : (
                        <CircularProgress/>
                    )}
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <SchoolsTable/>
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <ProductListingsTable/>
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                    <GeneratedContentTable/>
                </TabPanel>
                <TabPanel value={tabValue} index={4}>
                    <PPAccountTable/>
                </TabPanel>
            </Box>
        </Box>)}      
        </>    
    )
}

export default IntellectibaseMain; 