import React, {useState, useEffect, useContext } from "react";

import MainContext from "../../MainContext"; 

import { Box } from "@mui/material";

import {  allUSSchoolGrades, allUSSchoolTypes } from "../../Util";

import NumberFormInput from "../../components/formInputs/NumberFormInput";
import SelectFormInput from "../../components/formInputs/SelectFormInput";
import SelectRangeFormInput from "../../components/formInputs/SelectRangeFormInput";
import FormTitle from "../../components/formInputs/FormTitle";


const SchoolMoreInput = ({formData,setDisableContinueButton})=>{
    
    const mainService = useContext(MainContext);

    let userObject = formData.userObject;

    const isNumberGreaterThanZero = n => (!isNaN(parseInt(n)) && parseInt(n) > 0);

    useEffect(()=>{
        let disableButton = !(userObject && userObject.schooltype && userObject.schoolgradehigh 
            && userObject.schoolgradelow && isNumberGreaterThanZero(userObject.schoolstudentcount) 
            && isNumberGreaterThanZero(userObject.schoolteachercount)); 
        if(setDisableContinueButton)
            setDisableContinueButton(disableButton); 
    }, [formData]);

    return(
    <React.Fragment>

        <Box sx={{ flexGrow:1, maxWidth:'510px', height:'100%', display:'flex', alignItems:'stretch', flexDirection:'column',}}>
            <Box sx={{height:'10%'}}></Box>
            
            <FormTitle text='Tell us a little more about your school.'/>
            <Box sx={{display:'flex', alignItems:'left', flexDirection:'column' }}>


                <SelectFormInput 
                    title={'What type of school is it?'}
                    value={userObject.schooltype}
                    onChange={(v)=>mainService.profileService.setSchoolType(v)}
                    options={allUSSchoolTypes}
                />

                <SelectRangeFormInput
                    title={'What grades are taught there?'}
                    options={allUSSchoolGrades}
                    valueLow={userObject.schoolgradelow}
                    onLowChange={(v)=>mainService.profileService.setSchoolGradeLow(v)}
                    valueHigh={userObject.schoolgradehigh}
                    onHighChange={(v)=>mainService.profileService.setSchoolGradeHigh(v)}
                />


                <NumberFormInput
                    title={'How many Students are there in the school?'}
                    value={userObject.schoolstudentcount}
                    onChange={(v)=>mainService.profileService.setSchoolStudentCount(v)}
                />
                <NumberFormInput
                    title={'How many Teachers are there in the school?'}
                    value={userObject.schoolteachercount}
                    onChange={(v)=> mainService.profileService.setSchoolTeacherCount(v)}
                />

            </Box>

        </Box>
    </React.Fragment>
    )
}


export default SchoolMoreInput;