
import React, {useState, useEffect } from "react";

import Theme from '../Theme';
import { ThemeProvider } from  '@mui/system';

import { Box } from "@mui/material";
import Paper from "./Paper";



const BottomSlidingPanel = ({ open, children}) => {

    const appMenuTransitionTime = 0.4; 
    const MENU_CLOSED = 0; 
    const MENU_OPENING = 1;
    const MENU_OPEN = 2; 
    const MENU_CLOSING = 3; 

    //STATES    
    const [appMenuVisible, setAppMenuVisible] = useState(MENU_OPEN); 

    //FUNCTIONS
   
    const openAppMenu = ()=>{
        setAppMenuVisible(MENU_OPENING); 
        setTimeout(()=>setAppMenuVisible(MENU_OPEN), 1000*appMenuTransitionTime)
    }

    const closeAppMenu = ()=>{
        setAppMenuVisible(MENU_CLOSING); 
        setTimeout(()=>setAppMenuVisible(MENU_CLOSED), 1000*appMenuTransitionTime)
    }

    useEffect(()=>{
        if(!open)
            closeAppMenu(); 
        else
            openAppMenu(); 
    }, [open]);

    return (
    <ThemeProvider theme={Theme}>
        
        <Box sx={{
            pointerEvents: 'none',
            //backdropFilter:(appMenuVisible == MENU_CLOSED)?'default':'blur('+( (appMenuVisible == MENU_OPEN || appMenuVisible == MENU_OPENING)?'16px':'0')+') opacity(1)', 
            backdropFilter:(appMenuVisible == MENU_CLOSED)?'default':'opacity('+( (appMenuVisible == MENU_OPEN || appMenuVisible == MENU_OPENING)?'1':'0')+')', 
            //backdropFilter:'blur('+'16px'+')',
            //opacity:(appMenuVisible == MENU_OPEN || appMenuVisible == MENU_OPENING) ? '1':'0',
            position:'absolute', width:'100%', height:'100%', zIndex:2, 
            transition: 'backdrop-filter '+appMenuTransitionTime*0.8+'s' + ',opacity '+appMenuTransitionTime*0.8+'s',
            transitionTimingFunction:'ease-in',  
        }}/>
        
        <Box sx={{position:'absolute', width:'100%', height:'100%', display:'flex', justifyContent:'center', zIndex:2,
                    transform: 'scaleY('+( (appMenuVisible == MENU_OPEN || appMenuVisible == MENU_OPENING)?'100%':'0%')+')',
                    transformOrigin:'bottom',
                    transition: 'transform '+appMenuTransitionTime+'s',}}
        >
            <Paper sx={{flexGrow:1, maxWidth:'1200px', display:'flex', flexDirection:'column', m:'32px'}}>
                {children} 
            </Paper>
        </Box>
        

    </ThemeProvider>
    )
}


export default BottomSlidingPanel; 