import  {Fragment, useState, useEffect, useContext } from "react";
import { camelCase } from 'lodash';

import MainContext from "../../MainContext";

import { 
    Box, 
    Typography,
    CircularProgress, 
    ButtonBase, 
    IconButton,
    } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/Upload';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';

import theme from "../../Theme";

import NewGrantRequirement from "./NewGrantRequirement";
import EditGrantRequirement from "./EditGrantRequirement";
import { grantRequirementNames } from "../../Util";

export default function GrantRequirements({gid}) {
    //Use
	const mainService = useContext(MainContext);

    //STATES
    const [loading,setLoading] = useState(false);

    const [reqsData,setReqsData] = useState([]);

    const [newReqDialogOpen,setNewReqDialogOpen] = useState(false);
    const [newReqData,setNewReqData] = useState({});
    const [newReqNameOptions,setNewReqNameOptions] = useState([]);
    const [newReqAutoCompleteVal,setNewReqAutoCompleteVal] = useState("");

    const [editReqDialogOpen,setEditReqDialogOpen] = useState(false);
    const [editReqData,setEditReqData] = useState({});
    const [editRid,setEditRid] = useState(null);
     


    //handlers
    const handleOpenDialog = () => {
        setNewReqData({});
        setNewReqAutoCompleteVal("");
        setNewReqDialogOpen(true)
    }

    const handleCloseDialog = () => {
        setNewReqDialogOpen(false)
        
    }

    const handleOpenEditDialog = (reqData,rId) => {
        setEditReqData(reqData);
        setEditRid(rId);
        setEditReqDialogOpen(true);
    }

    const handleCloseEditDialog = ()=>{
        setEditReqData({});
        setEditRid(null);
        setEditReqDialogOpen(false)
    }


    const handleSetReqType = (e) => {
        const val = e.target.value;
        if(val){
            let data = {...newReqData};
            data["type"] = val;
            setNewReqData(data);        
            setNewReqNameOptions(grantRequirementNames[val]);
        }  
    }

    const handleSetReqName = (val) => {
        setNewReqAutoCompleteVal(val)
        if(val){
            const nameObj = newReqNameOptions.find(element => element.label === val);
            let name = ""
            if(nameObj){
                name = nameObj.value
            }
            else{
                name= camelCase(val);
            }
            let data = {...newReqData};
            data["name"] = name;
            setNewReqData(data);  
        }
    }

    const handleSetReqData = (val,field) => {
        let data = {...newReqData};
        if(val){           
            data[field] = val;                 
        }  
        else {
            if(data[field]){
                delete data[field];
            }
                
        }
        setNewReqData(data); 
    }


    const handleAddRequirement = async () => {
        setLoading(true);
        setNewReqDialogOpen(false);
        try{
            await mainService.intellectibaseService.addToSubcollection("grants",gid,"applicationRequirements",newReqData)
        }
        catch(error){
            console.error(error);
        }
        setNewReqData({});
        setNewReqAutoCompleteVal("");
        setLoading(false);
        
    }

    const handleUpdateRequirement = async (rId,value,fieldName) => {
        let data = {};
        data[fieldName]=value;
        try{
            await mainService.intellectibaseService.updateDocInSubcollection('grants',gid,'applicationRequirements',rId,data);

        }
        catch(error){
            console.error(error);
        }
    }

    const handleDeleteRequirement = async (id) => {
        setLoading(true);
        try{
            await mainService.intellectibaseService.deleteDocFromSubcollection("grants",gid,"applicationRequirements",id);
        }
        catch(error){
            console.error(error);
        }
        setLoading(false);
    }
    

    //Listeners
    const listenRequirementsChanged = (docs) =>{
        if(docs){
            console.log("got docs")
            let reqsList = [];
            docs.forEach((doc) => {
                reqsList.push({id:doc.id,data:doc.data()})
            });
            setReqsData(reqsList);
        }
        else{
            setReqsData([]);
        }
    }

    //Effects
	useEffect(() => {
		//on mount
		console.log('Grant details mounted'); 
        mainService.intellectibaseService.listenRequirements(gid,listenRequirementsChanged);
		//on unmount
		return ()=>{
			console.log('Grant details unmounted');
            mainService.intellectibaseService.unsubscribeRequirements();
		}

	}, []);
    
  
    return (
        <Fragment>
        <Box sx={{display:"flex",flexDirection:"Column",position:"relative",minWidth:"600px",overflow:'hidden'}}>

            {loading && (<Box sx={{ width:"100%",height:"100%", position:"absolute",backgroundColor:"rgba(255,255,255,0.5)",display:"flex",alignItems:"center",flexGrow:1,justifyContent:"center"}}>
                <CircularProgress/>
            </Box>)}

            <Box sx={{width:"100%",height:"100%",filter:loading && "blur(3px)", display:"flex",flexDirection:"Column",overflow:"hidden",mb:theme.spacing(1)}}>

                <Typography sx={{m:theme.spacing(1)}} variant="h4">Application requirements</Typography>

                <Box sx={{display:"flex",flexDirection:"row",gap:"4px"}}>
                    <ButtonBase sx={{width:"100%",borderRadius:"5px",display:"flex",justifyContent:"center",backgroundColor:"#E8E8E8",p:"4px"}} onClick={handleOpenDialog}>
                        <AddIcon/>
                    </ButtonBase>
                    <ButtonBase sx={{width:"100%",borderRadius:"5px",display:"flex",justifyContent:"center",backgroundColor:"#E8E8E8",p:"4px"}}>
                        <UploadIcon/>
                    </ButtonBase>
                </Box>

                <Box sx={{mt:'16px'}}>
                    <Box sx={{
                            mx:"8px",
                            display:"flex",
                            flexDirection:"row",
                            alignItems:"center",
                            position:"relative",
                            minWidth:"300px",
                            gap:"16px",
                            borderBottom:`solid 1px ${theme.palette.grey[400]}`}}
                        >
                            <Typography sx={{width:'100px',fontWeight:800}} variant="subtitle1">Type</Typography>
                            <Typography sx={{width:'170px',fontWeight:800}} variant="subtitle1">Name</Typography>
                            <Typography sx={{width:'60px',fontWeight:800}} variant="subtitle1">Input</Typography>
                    </Box>
                    <Box sx={{maxHeight:"600px",overflow:"auto"}}>
                    {reqsData.map((req,i)=>(
                        <Box sx={{
                            mx:"8px",
                            display:"flex",
                            flexDirection:"row",
                            alignItems:"center",
                            position:"relative",
                            minWidth:"300px",
                            gap:"16px",
                            borderBottom:`solid 1px ${theme.palette.grey[400]}`}}
                            key={req.id}
                        >
                            <Typography sx={{width:'100px'}}  variant="body1">{req.data.type}</Typography>
                            <Typography sx={{width:'170px'}} noWrap  variant="body1">{req.data.name}</Typography>
                            <Typography sx={{width:'60px'}}  variant="body1">{req.data.input}</Typography>
                            <Box sx={{flexGrow:1}}></Box>
                            <IconButton onClick={()=>handleOpenEditDialog(req.data,req.id)}><EditIcon/></IconButton>
                            <IconButton onClick={()=>handleDeleteRequirement(req.id)}><ClearIcon/></IconButton>
                        </Box>
                    ))}
                    </Box>
                </Box> 

            </Box>                     
        </Box>
        <NewGrantRequirement
            open={newReqDialogOpen}
            onClose={handleCloseDialog}
            loading={loading}
            newReqData={newReqData}
            onSetReqData={handleSetReqData}
            onSetReqName={handleSetReqName}
            onSetReqType={handleSetReqType}
            onAdd={handleAddRequirement}
            newReqNameOptions={newReqNameOptions}
            newReqAutoCompleteVal={newReqAutoCompleteVal}
        />
        <EditGrantRequirement
            open={editReqDialogOpen}
            onClose={handleCloseEditDialog}
            loading={loading}
            reqsData={reqsData}
            rId={editRid}
            onEditReq={handleUpdateRequirement}
        />
        </Fragment>
    );
}