import React, {useState, useEffect, useContext } from "react";

import Theme from '../Theme';
import MainContext from "../MainContext"; 

import { Box, Typography, ButtonBase, Avatar,Popper, Fade, MenuItem, Link, IconButton, Table, TableContainer, TableBody} from "@mui/material";
import FormFlow from './FlowForm'; 
import TextFormInput from "./formInputs/TextFormInput";
import NumberFormInput from "./formInputs/NumberFormInput";
import DateFormPickerInput from "./formInputs/DateFormPickerInput";
import DateRangePicker from "./formInputs/DateRangePicker";
import StyledTableCell from "./StyledTableCell";
import StyledTableRow from "./StyledTableRow";
import ResponsiveDialog from './ResponsiveDialog';
import Paper from "./Paper";
import StyledSquareButton from "./StyledSquareButton";

import DoneIcon from '@mui/icons-material/Done';
import LinkIcon from '@mui/icons-material/Link';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import SearchBar from './SearchBar';
import { getNextDeadline, getNextDateOccurrence, getFormattedNumberString } from "../Util";

const TeacherStart = ({formData})=>{

    const handleSetGrantType = (type) => {
        formData.setGrantType(type);
        if(type=='internal'){
            formData.setGrantName('Internal funding opportunity');
            formData.setGrantOrg(formData.userObject.schoolname);
        }
        else{
            formData.setGrantName('');
            formData.setGrantOrg('');
        }
    }

    return(
    
        <Box sx={{ height:'100%', width:'100%',display:'flex', alignItems:'stretch', flexDirection:'column',justifyContent:'center'}}>
            <Box sx={{height:'15%'}}></Box>
            <Typography variant='h3' align='center' gutterBottom>Is the funding source...</Typography>
            <Box sx={{mx:'16px',display:'flex', alignItems:'center', justifyContent:'center',flexGrow:1}}>
                
                <ButtonBase sx={{
                    position:'relative',
                    borderStyle:"solid", 
                    borderRadius:"8px", 
                    borderWidth:"1px", 
                    borderColor:Theme.palette.grey[400],
                    "&:hover": {borderColor: 'rgb(0,0,0)'}, 
                    width:'300px', 
                    height:'260px', 
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'stretch',
                    justifyContent:'center'
                }} onClick={(e)=>handleSetGrantType('internal')}
                >
                    <Box 
                        sx={{flexGrow:1,display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <Typography  align='center' variant="h3">Internal</Typography>
                    </Box>
                    
                    <Box 
                        sx={{
                            bgcolor: formData.grantType == 'internal' ?  '#46AC37' : Theme.palette.grey[400],
                            height:'56px',
                            borderRadius:'0px 0px 8px 8px',
                            display:'flex',alignItems:'center',
                            justifyContent:'center'}}                       
                    >
                        <Avatar sx={{ bgcolor: 'white',width:'32px',height:'32px'}}>
                            {formData.grantType == 'internal' && <DoneIcon sx={{color:'#46AC37',width:'18px',height:'18px'}}/>}
                        </Avatar>                       
                    </Box>
                   

                </ButtonBase>

                <Typography align='center' variant="body2" sx={{mx:'16px'}}>or an</Typography>

                <ButtonBase sx={{
                    position:'relative',
                    borderStyle:"solid", 
                    borderRadius:"8px", 
                    borderWidth:"1px", 
                    borderColor:Theme.palette.grey[400],
                    "&:hover": {borderColor: 'rgb(0,0,0)'}, 
                    width:'300px', 
                    height:'260px', 
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'stretch',
                    justifyContent:'center'
                }} onClick={(e)=>handleSetGrantType('external')}
                >
                    <Box 
                        sx={{flexGrow:1,display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <Typography  align='center' variant="h3">External grant</Typography>
                    </Box>
                    

                    <Box 
                        sx={{
                            bgcolor: formData.grantType == 'external' ?  '#46AC37' : Theme.palette.grey[400],
                            height:'56px',
                            borderRadius:'0px 0px 8px 8px',
                            display:'flex',alignItems:'center',
                            justifyContent:'center'}}                       
                    >
                        <Avatar sx={{ bgcolor: 'white',width:'32px',height:'32px'}}>
                            {formData.grantType == 'external' && <DoneIcon sx={{color:'#46AC37',width:'18px',height:'18px'}}/>}
                        </Avatar>                       
                    </Box>
                   

                </ButtonBase>

            </Box>

        </Box>
        
    )
}

const Search = ({formData})=>{
    
    const mainService = useContext(MainContext);

    const [anchorEl, setAnchorEl] = useState(null); 
    const [resultsMenuOpen, setResultsMenuOpen] = useState(false); 

    const handleSearchResults  = (results) => {
        //console.log(JSON.stringify(results));
        formData.setMatches(results);
        setResultsMenuOpen(true);
    }

    const handleClearSearchResults = () => {
        formData.setMatches([]);
        setResultsMenuOpen(false);
    }

    const handleClearGrant = () => {
        formData.setGrantName('');
        formData.setGrantOrg('');
        formData.setGrantUrl('');
        formData.setAwardMax(0);
        formData.setAwardMin(0);
        formData.setSubRanges([{start:'',end:''}]);
    }

    const handleGrantClick = (data) => {
        //console.log(JSON.stringify(data))
        formData.setGrantName(data.GrantName);
        formData.setGrantOrg(data.AwardOrg);
        formData.setGrantUrl(data.GrantURL);
        formData.setAwardOrgUrl(data.AwardOrgURL);
        formData.setAwardMax(data?.AwardMax ?? 0);
        formData.setAwardMin(data?.AwardMin ?? 0);
        formData.setSubRanges(data?.SubmissionRanges ?? []);
        //console.log(JSON.stringify(data.SubmissionRanges));
        setResultsMenuOpen(false);
    }

    return(
        <Box sx={{ height:'100%', width:'100%',display:'flex', alignItems:'stretch', flexDirection:'column',justifyContent:'center',mx:'64px', position:'relative'}}>
            <Box sx={{height:'15%'}}></Box>
            <Typography variant='h3' align='center' gutterBottom>Enter grant name, awarding organization or grant URL</Typography>
            <Typography variant='body2' align='center' sx={{mb: '32px'}}>If you find your grant select it, otherwise leave blank and continue</Typography>
            <Box sx={{mx:'64px',display:'flex',flexDirection:'column'}}  ref={(el)=>setAnchorEl(el)}>
                <SearchBar type="grants" displayText="grants" onResults={handleSearchResults} onClear={handleClearSearchResults} maxHits={10}/>
                <Popper
                    anchorEl={anchorEl}
                    open={resultsMenuOpen}
                    transition
                    sx={{zIndex:10000}}
                >  
                    {({TransitionProps}) => (
                        <Fade {...TransitionProps} timeout={350}>                    
                            <Box 
                                sx={{
                                    minWidth:'500px',
                                    backgroundColor:"white",
                                    borderRadius:"8px", 
                                    boxShadow: `rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px`}}
                            >
                            {formData.matches.length > 0 ?
                            
                            formData.matches.map((v)=>{ return (
                                <MenuItem key={v.document.id} sx={{ minWidth:'230px'}} onClick={()=>handleGrantClick(v.document)}>
                                    <Typography variant='h6'>{v.document.GrantName}</Typography> <Typography variant = 'body2'>, {v.document.AwardOrg}</Typography>
                                </MenuItem>
                            )
                            }) :(
                                <MenuItem key="noResults" sx={{ minWidth:'230px'}} disabled>No results</MenuItem>
                            )                   
                            }
                            </Box>
                        </Fade>
                    )}
                </Popper>
            </Box>
            <Box sx={{flexGrow:1, display:'flex',mt:'16px',justifyContent:'center',alignItems:'flex-start'}}>
                {formData.grantName &&
                (<Box sx={{borderRadius:'8px',border:`1px solid #46AC37`, display:'flex', p:'16px', gap:'16px'}}>
                    <Box sx={{display:'flex', flexDirection:'column'}}>
                        <Link variant='h4' href={formData.grantUrl} target='_blank'>{formData.grantName}</Link>
                        <Typography variant='body2'>{formData.grantOrg}</Typography>  
                    </Box>
                    <IconButton size='small' onClick={handleClearGrant}><CloseIcon/></IconButton>
                </Box>)}
            </Box>
            
        </Box>
    )
}


const AddDetails = ({formData})=>{
    

    const mainService = useContext(MainContext);

    return(
        <Box sx={{ height:'100%', width:'100%',display:'flex', alignItems:'stretch', flexDirection:'column',justifyContent:'center',mx:'64px'}}>
            <Box sx={{height:'15%'}}></Box>
            <Typography variant='h3' align='center' gutterBottom >Enter the grant's name, organization and url</Typography>
            {/*<Typography variant='body2' align='center' sx={{mx:'16px',mb:'48px'}}>We'll double check it's not in our system already</Typography>*/}
            <Box sx={{mt:'16px',mx:'32px',flexGrow:1}}>

                <TextFormInput 
                    title={`Grant name`}
                    value={formData.grantName}
                    onChange={(v)=>{formData.setGrantName(v)}}                    
                />
                
                <TextFormInput 
                    title={`Awarding organization`}
                    value={formData.grantOrg}
                    onChange={(v)=>{formData.setGrantOrg(v)}}                    
                />

                <TextFormInput 
                    title={`Grant url`}
                    value={formData.grantUrl}
                    startAdornment={<LinkIcon sx={{mr:'4px'}}/>}
                    onChange={(v)=>{formData.setGrantUrl(v)}}                    
                />

            </Box>
        </Box>
    )
}

const AddDetailsTwo = ({formData})=>{
    
    const mainService = useContext(MainContext);

    return(
        <Box sx={{ height:'100%', width:'100%',display:'flex', alignItems:'stretch', flexDirection:'column',justifyContent:'center',mx:'64px'}}>
            <Box sx={{height:'15%'}}></Box>
            <Typography variant='h3' align='center' gutterBottom>Enter the maximum and minimum award amounts</Typography>
            <Typography variant='body2' align='center' sx={{mx:'16px',mb:'48px'}}>Leave blank if not stated</Typography>
            <Box sx={{mt:'16px',mx:'32px',flexGrow:1, display:'flex',flexDirection:'column'}}>

                <NumberFormInput
                    title={'Minimum award'}
                    value={formData.awardMin}
                    onChange={(v)=>formData.setAwardMin(v)}
                    increment={50}
                    isCurrency
                    align='center'
                />

                <NumberFormInput
                    title={'Maximum award'}
                    value={formData.awardMax}
                    onChange={(v)=>formData.setAwardMax(v)}
                    increment={50}
                    isCurrency
                    align="center"
                />

            </Box>
        </Box>
    )
}


const AddDetailsThree = ({formData})=>{
    
    const subRanges = formData.subRanges;

    //TODO remove years when adding recommendation?

    const handleUpdateToDate = (v,i) => {
        let sr = [...formData.subRanges];
        let dArr = v.split('/');
        sr[i].end = `${dArr[0]}-${dArr[1]}`;
        formData.setSubRanges(sr);
    }

    const handleUpdateFromDate = (v,i) => {
        let sr = [...formData.subRanges];
        let dArr = v.split('/');
        sr[i].start = `${dArr[0]}-${dArr[1]}`;
        formData.setSubRanges(sr);
    }

    const handleAddNewRange = () => {
        let sr = [...formData.subRanges];
        sr.push({start:'',end:''});
        formData.setSubRanges(sr);
    }

    return(
        <Box sx={{ height:'100%', width:'100%',display:'flex', alignItems:'stretch', flexDirection:'column',justifyContent:'center',mx:'64px'}}>
            <Box sx={{height:'15%'}}></Box>
            <Typography variant='h3' align='center' gutterBottom>Add the submission windows for the grant</Typography>
            <Typography variant='body2' align='center' sx={{mx:'16px',mb:'48px'}}>Leave blank if not stated or rolling</Typography>
            <Box sx={{mt:'16px',mx:'32px',flexGrow:1, display:'flex',flexDirection:'column',alignItems:'center'}}>

                {formData.subRanges.map((r,i)=>(
                    <DateRangePicker 
                        key={i} 
                        toDate={getNextDateOccurrence(r.end) ?? ''} onToDateChange={(v)=>handleUpdateToDate(v,i)}  
                        fromDate={getNextDateOccurrence(r.start)?? ''} onFromDateChange={(v)=>handleUpdateFromDate(v,i)} 
                    />
                ))} 

                <Box sx={{width:'100%',display:'flex',flexDirection:'column', alignItems:'center', mt:'12px',gap:'8px'}}>
                    <StyledSquareButton
                        onClick={handleAddNewRange}
                    >
                        <AddIcon/>
                    </StyledSquareButton>
                    <Typography variant='body2'>
                        Add new submission range
                    </Typography>
                </Box>          

            </Box>
        </Box>
    )
}

const AddDetailsInternal = ({formData})=>{
    

    return(
        <Box sx={{ height:'100%', width:'100%',display:'flex', alignItems:'stretch', flexDirection:'column',justifyContent:'center',mx:'64px'}}>
            <Box sx={{height:'15%'}}></Box>
            <Typography variant='h3' align='center' gutterBottom >Set a name for your internal funding source</Typography>
            {/*<Typography variant='body2' align='center' sx={{mx:'16px',mb:'48px'}}>We'll double check it's not in our system already</Typography>*/}
            <Box sx={{mt:'16px',mx:'32px',flexGrow:1}}>

                <TextFormInput 
                    title={`Grant name`}
                    value={formData.grantName}
                    onChange={(v)=>{formData.setGrantName(v)}}                    
                />
                
                <TextFormInput 
                    title={`Awarding organization`}
                    value={formData.grantOrg}
                    onChange={(v)=>{formData.setGrantOrg(v)}}                    
                />


            </Box>
        </Box>
    )
}

const AddDetailsInternalTwo = ({formData})=>{
    
    const mainService = useContext(MainContext);

    const handleSetDeadline = (val) => {
        console.log(val);
        let datestr = val.join('-');
        console.log(datestr)
        let subRanges = [{'end':datestr}];
        formData.setSubRanges(subRanges);
    }

    return(
        <Box sx={{ height:'100%', width:'100%',display:'flex', alignItems:'stretch', flexDirection:'column',justifyContent:'center',mx:'64px'}}>
            <Box sx={{height:'15%'}}></Box>
            <Typography variant='h3' align='center' gutterBottom>If applicable, enter award amounts and a submission deadline</Typography>
            {/*<Typography variant='body2' align='center' sx={{mx:'16px',mb:'48px'}}>We'll double check it's not in our system already</Typography>*/}
            <Box sx={{mt:'16px',mx:'32px',flexGrow:1, display:'flex',flexDirection:'column'}}>

                <NumberFormInput
                    title={'Minimum award, leave blank if not applicable'}
                    value={formData.awardMin}
                    onChange={(v)=>formData.setAwardMin(v)}
                    increment={50}
                    isCurrency
                    align='left'
                />

                <NumberFormInput
                    title={'Maximum award, leave blank if not applicable'}
                    value={formData.awardMax}
                    onChange={(v)=>formData.setAwardMax(v)}
                    increment={50}
                    isCurrency
                    align="left"
                />

                <DateFormPickerInput
                    title={'When do you need to submit your application by?'}
                    value={formData.subRanges[0]?.end ? formData.subRanges[0]?.end.split('-') : null}
                    label='Set a deadline'
                    onChange={(v)=>handleSetDeadline(v)}
                />

            </Box>
        </Box>
    )
}


const Summary = ({formData})=>{
    
    const mainService = useContext(MainContext);
    const nextDeadline = formData.subRanges.length > 0 ? getNextDeadline(formData.subRanges) : null;

    return(
        <Box sx={{ height:'100%', width:'100%',display:'flex', alignItems:'stretch', flexDirection:'column',justifyContent:'center',mx:'64px'}}>
            <Box sx={{height:'15%'}}></Box>
            <Typography variant='h3' align='center' gutterBottom >Here's a summary of your new potential funding source</Typography>
            <Typography variant='body2' align='center' sx={{mb:'32px'}}>Continue to finish or go back if you want to change something</Typography>
            <Box sx={{mx:'32px',flexGrow:1,display:'flex',flexDirection:'column'}}>

            <Paper sx={{mt:'12px', display:'flex', flexDirection:'column', overflow:'hidden'}}>
            
            <Table  aria-label="funding-summary" >

                <TableBody>
                    <StyledTableRow>
                        <StyledTableCell  component="th" scope="row">
                            <Typography variant='h6'>Name:</Typography>
                        </StyledTableCell>
                        <StyledTableCell  align="left">
                            <Typography variant='body2'>{formData.grantName}</Typography>
                        </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                        <StyledTableCell  component="th" scope="row">
                            <Typography variant='h6'>Organization:</Typography>
                        </StyledTableCell>
                        <StyledTableCell  align="left">
                            <Typography variant='body2'>{formData.grantOrg}</Typography>
                        </StyledTableCell>
                    </StyledTableRow>  

                    <StyledTableRow>
                        <StyledTableCell  component="th" scope="row">
                            <Typography variant='h6'>Maximum award:</Typography>
                        </StyledTableCell>
                        <StyledTableCell  align="left">
                            <Typography variant='body2'>${formData?.awardMax ? getFormattedNumberString(formData.awardMax) : 'N/A'}</Typography>
                        </StyledTableCell>
                    </StyledTableRow>  

                    <StyledTableRow>
                        <StyledTableCell  component="th" scope="row">
                            <Typography variant='h6'>Minimum award:</Typography>
                        </StyledTableCell>
                        <StyledTableCell  align="left">
                            <Typography variant='body2'>${formData?.awardMin ? getFormattedNumberString(formData.awardMin) : 0}</Typography>
                        </StyledTableCell>
                    </StyledTableRow>  

                    <StyledTableRow>
                        <StyledTableCell  component="th" scope="row">
                            <Typography variant='h6'>Next deadline:</Typography>
                        </StyledTableCell>
                        <StyledTableCell  align="left">
                            <Typography variant='body2'> {nextDeadline ? nextDeadline.toLocaleDateString() : 'Not available'} </Typography>
                        </StyledTableCell>
                    </StyledTableRow>  

                </TableBody>
            </Table>
            
            </Paper>
            </Box>
        </Box>
    )
}


const AddGrantFlow = ({userObject,appId,applicationMetadata, open,onFinish,onClose}) => {
    
    const mainService = useContext(MainContext);

    const [grantType,setGrantType] = useState('internal');
    const [grantName,setGrantName] = useState('');
    const [grantOrg,setGrantOrg] = useState('');
    const [grantUrl,setGrantUrl] = useState('');
    const [awardOrgUrl,setAwardOrgUrl] = useState('');
    const [awardMax,setAwardMax] = useState('0');
    const [awardMin,setAwardMin] = useState('0');
    const [recursAnually,setRecursAnually] = useState({});
    const [subRanges,setSubRanges] = useState([{start:'',end:''}]);

    const [matches,setMatches]= useState([]);
    
    const handleFinish = ()=>{
        const data = {
            selected:true,
            stage:'not started',
            userCreated:true,
            data:{
                AwardMax:awardMax,
                AwardMin:awardMin,
                GrantName:grantName,
                AwardOrg:grantOrg,
                AwardOrgURL:awardOrgUrl,
                GrantURL:grantUrl,
                RecurrsAnnually:recursAnually,
                SubmissionRanges:subRanges
            }
        }
        onFinish(data);
    }

    //RENDER 
    const formData = {
        
        startNode: 'teacherStartNode',

        userObject: userObject,

        setGrantType: (v) => setGrantType(v),
        grantType,

        setGrantName: (v) => setGrantName(v),
        grantName,

        setGrantOrg: (v) => setGrantOrg(v),
        grantOrg,

        setGrantUrl: (v) => setGrantUrl(v),
        grantUrl,

        setAwardOrgUrl: (v)=> setAwardOrgUrl(v),
        awardOrgUrl,

        setMatches: (v) => setMatches(v),
        matches,

        setAwardMax: (v) => setAwardMax(v),
        awardMax,

        setAwardMin: (v) => setAwardMin(v),
        awardMin,

        setRecursAnually: (v) => setRecursAnually(v),
        recursAnually,

        setSubRanges: (v) => setSubRanges(v),
        subRanges,

        nodes:{
            teacherStartNode:{
                component:TeacherStart,
                next:()=>grantType == 'external' ? 'searchNode': 'addDetailsInternalNode',
            },

            searchNode:{
                component:Search,
                next:()=> grantName ? 'summaryNode' : 'addDetailsNode',
            },
   
            summaryNode: {
                component:Summary,
                end:true
            },

            addDetailsNode: {
                component:AddDetails,
                next:()=>'addDetailsTwoNode'
            },

            addDetailsTwoNode:{
                component:AddDetailsTwo,
                next:()=>'addDetailsThreeNode'
            },

            addDetailsThreeNode:{
                component:AddDetailsThree,
                next:()=>'summaryNode'
            },

            addDetailsInternalNode:{
                component:AddDetailsInternal,
                next:()=>'addDetailsInternalTwoNode'
            },

            addDetailsInternalTwoNode:{
                component:AddDetailsInternalTwo,
                next:()=>'summaryNode'
            }
        }
    }

    return (
        <ResponsiveDialog open={open} onClose={onClose} maxWidth='lg'>
            <Box sx={{overflowX:"hidden",position:'relative', display:'flex', flexDirection:'column', m:'32px',width:'800px', height:'600px'}}>
                <FormFlow onFinish={handleFinish} formData={formData}/>
            </Box>
        </ResponsiveDialog>
    )
}

export default AddGrantFlow; 