import React, {useState, useEffect, useContext } from "react";

import { Box, Typography, ButtonBase, TextField, Tooltip  } from "@mui/material";

import ContentPasteIcon from '@mui/icons-material/ContentPaste';

import BorderPulsate from "../BorderPulsate";
import Theme from "../../Theme";


const PasteFormInput = ({value, title, onChange, startAnimation, sx, readOnly, descriptionText})=>{

    const [animate,setAnimate] = useState(false);
        
    const handleChange = (val)=>{
        onChange(val); 
    }

    const handlePasteText = async () => {
        const clipText = await navigator.clipboard.readText();
      
        onChange(clipText);

        setAnimate(true);
        setTimeout(()=>{
            setAnimate(false)
        },1000)

    }

    return(
    <React.Fragment>
        <Typography align='left' sx={{mt:'16px', mb:descriptionText?'0px':'16px', ml:'16px'}}>{title}</Typography>
        {descriptionText && <Typography variant='body2' sx={{mb:'16px', ml:'16px'}}>{descriptionText}</Typography>} 
        <Box sx={{display:'flex'}}>
            <BorderPulsate startAnimation={animate} sx={{alignSelf:'stretch',mx:'16px', flexGrow:1,display:'flex', flexDirection:'column'}}>
                <TextField
                    InputProps={{
                        style:{marginLeft:'0px'},
                        //startAdornment:<Typography sx={{color:Theme.palette.grey[600]}} variant='body1'>https://</Typography>
                    }}
                    type='url'
                    disabled={readOnly}
                    value={value ? value : ''}
                    onChange={(e)=>handleChange(e.target.value)}
                    variant="outlined"
                    sx={{ ml:0,flexGrow:1, ...sx }}
                />
            </BorderPulsate>
            <Tooltip title='Paste URL'>
                <ButtonBase onClick={handlePasteText} sx={{p:'8px',borderRadius:'50px'}}>
                    <ContentPasteIcon sx={{color:Theme.palette.grey[400]}}/>
                </ButtonBase>
            </Tooltip>
        </Box>
    </React.Fragment>
    )
}

export default PasteFormInput;