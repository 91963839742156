import React, {useState, useEffect, useContext } from "react";

import Theme from '../../Theme';

import MainContext from "../../MainContext"; 

import { Box, Typography, Link, TextField, ButtonBase, Tooltip, Alert as MuiAlert, Snackbar, CircularProgress, Checkbox, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, ListItemText, ListItemIcon} from "@mui/material";

import FormFlow from '../../components/FlowForm'; 
import StyledButton from "../../components/StyledButton";
import TextFormInput from "../../components/formInputs/TextFormInput";
import Paper from "../../components/Paper";
import AnimatedTypography from "../../components/AnimatedTypography";
import BorderPulsate from "../../components/BorderPulsate";
import ResponsiveDialog from "../../components/ResponsiveDialog";
import AutoTextFlow from "../../components/AutoTextFlow";

import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import {isURL} from 'validator';
import { getCurrencyAdornment } from "../../Util";

const getAgeRangeString = (minAge,maxAge) => {
    if(minAge && maxAge){
        return minAge + ' ' + '-' + ' ' + maxAge;
    }
    else if (minAge && !maxAge){
        return minAge + '+';
    }
    else if (!minAge && maxAge){
        return '0 ' + '-' + ' ' + maxAge;
    }
    else {
        return null;
    }
}


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LoadingOne = ({animation}) => {
    return (
        (<Typography align='center' sx={{animation}} variant='h1'>Hold tight while we grab your product information...</Typography>)
    )
}

const LoadingTwo = ({animation}) => {
    return (
        (<Typography align='center' sx={{animation}} variant='h1'>This normally takes around 10 - 15 seconds</Typography>)
    )
}

const LoadingThree = ({}) => {
    return(
        <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
            <CircularProgress size='100px' sx={{mb:'48px'}}/>
            <AnimatedTypography 
                texts={[
                    'Searching the web', 
                    'Gathering data',
                    'Reading results', 
                    'Applying some AI magic', 
                    'Generating content',  
                ]}
            />
        </Box>
    )
}

const LongLoadingOne = ({animation}) => {
    return (<Typography align='center' sx={{animation}} variant='h1'>This is taking a bit longer than expected</Typography>);
}

const LongLoadingTwo = ({animation}) => {
    return (<Typography align='center' sx={{animation}} variant='h1'>Hold on a little longer</Typography>);
}

const LongLoadingThree = ({animation}) => {
    return (<Typography align='center' sx={{animation}} variant='h1'>Not much longer now (hopefully 🤞)</Typography>);
}

const ProductLoading = ({playing,isLong,onFinish}) => {

    //RENDER 
    const data = [
        {},
        {component:LoadingOne},
        {component:LoadingTwo},
        {component:LoadingThree}
    ];

    const longData = [
        {},
        {component:LongLoadingOne},
        {component:LongLoadingTwo},
        {component:LongLoadingThree}

    ];

    return (
        <>
            {isLong ? (
                <>
                    <AutoTextFlow data={longData} playing={playing} iterations={'infinite'} autostop={false} onFinish={()=>{onFinish()}}/>
                    <CircularProgress/>
                </>
            ) : (
                <AutoTextFlow data={data} playing={playing} iterations={1} autostop={false} onFinish={()=>{onFinish()}}/>
            )}
        </>
       
    )

}

const Start = ({formData,continueFlow})=>{
    const [continueDisabled,setContinueDisabled] = useState(true);
    const [adviceDlgOpen,setAdviceDlgOpen] = useState(false)
    const [animate,setAnimate] = useState(false);
    const [loading,setLoading] = useState(true);
    const mainService = useContext(MainContext);

    const url = formData?.productData?.websiteurl ? formData.productData.websiteurl : '';
    const appId = formData.appId;

    const handlePasteText = async () => {
        const clipText = await navigator.clipboard.readText();
        const validUrl = isURL(clipText);
        if(validUrl){
            mainService.applicationService.setApplicationProductWebsiteURL(formData.appId,clipText);
            setAnimate(true);
            setTimeout(()=>{
                setAnimate(false)
            },1000)
        }
        else   
            formData.onAlert('Invalid URL','error')
        console.log(clipText);
    }

    const handleContinue = async () => {
        if(isURL(url)){
            await Promise.all([
                mainService.applicationService.setApplicationProductGenerated(appId,false),
                mainService.applicationService.setApplicationProductError(appId,false)
            ])
            mainService.applicationService.getProductData(url,appId)
            continueFlow();
        }
    }

    const handleDlgClose = () => {
        setAdviceDlgOpen(false);
    }

    //EFFECTS 
    useEffect(() => {
        let timer = null;
        if(formData.appId){
            console.log('setting timer');
            timer =  
            setTimeout(() => {
                setLoading(false);
            }, "1000");
        }

        return (()=>{
            if(timer && formData.appId){
                console.log('clearing timer');
                clearTimeout(timer);
            }
        })

    }, [formData.appId]);

    //effect to check url
    useEffect(()=>{
        setContinueDisabled(!isURL(url));
    }, [formData]);

    return(
        <>
        <Box sx={{ height:'100%', flexGrow:1, display:'flex', alignItems:'stretch', flexDirection:'column',maxWidth:'600px'}}>
            {loading ? (
            <Box sx={{width:'100%',height:'100%',flexGrow:1,display:'flex',alignItems:'center',justifyContent:'center'}}>
                <CircularProgress/>
            </Box>) : (
            <>
            <Box sx={{height:'10%'}}></Box>
            <Typography gutterBottom align='left' variant="h2">Let's start by adding a product to your application!</Typography>
            <Typography gutterBottom align='left'>Paste the URL for your product in the form below and click continue, Intellectible AI will do it's best effort at getting the data we need to start building your application.</Typography>
            <Box sx={{height:'10%'}}></Box>
            <Box sx={{my:'16px', display:'flex', flexDirection:'column', flexGrow:1}}>
                <Typography gutterBottom>Paste your URL here, <Link onClick={()=>setAdviceDlgOpen(true)}>click here</Link> to see which type of pages work best</Typography>
                <Box sx={{display:'flex',gap:'4px',justifyContent:'center'}}>
                    <BorderPulsate startAnimation={animate} sx={{flexGrow:1}}>
                    <TextField
                        fullWidth
                        value={url}
                        onChange={(e)=>mainService.applicationService.setApplicationProductWebsiteURL(formData.appId,e.target.value)}
                        type='url'
                        autoComplete="url"
                    /> 
                    </BorderPulsate>
                    <Tooltip title='Paste URL'>
                        <ButtonBase onClick={handlePasteText} sx={{p:'8px',borderRadius:'50px'}}>
                            <ContentPasteIcon sx={{color:Theme.palette.grey[400]}}/>
                        </ButtonBase>
                    </Tooltip>
                </Box>               
            </Box>
            <Box sx={{flexGrow:1}}/>
            
            </>)}
            <StyledButton disabled={continueDisabled} text='Continue' sx={{mb:'64px', width:"175px", alignSelf:'center'}} onClick = {handleContinue}/>
        </Box>
        <ResponsiveDialog open={adviceDlgOpen} onClose={handleDlgClose}>
            <DialogTitle>Best practice for grabbing product information</DialogTitle>
            <DialogContent>
                <List dense>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{minWidth:0,mt:'4px',mr:'16px'}}>
                            ◾
                        </ListItemIcon>
                        <ListItemText>
                            Pick a page that is dedicated to the product you want, such as the product landing page or shop listing.
                        </ListItemText>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{minWidth:0,mt:'4px',mr:'16px'}}>
                            ◾
                        </ListItemIcon>
                        <ListItemText>
                            If possible, make sure the page showcases just one product (rather than multiple bundles or products.)
                        </ListItemText>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{minWidth:0,mt:'4px',mr:'16px'}}>
                            ◾
                        </ListItemIcon>
                        <ListItemText>
                            Pick a page from the product provider's own website (rather than an Amazon listing for example.)
                        </ListItemText>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{minWidth:0,mt:'4px',mr:'16px'}}>
                            ◾
                        </ListItemIcon>
                        <ListItemText>
                            Pick a page from your country and intended currency (e.g. don't pick a UK web page if you are in the US)
                        </ListItemText>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{minWidth:0,mt:'4px',mr:'16px'}}>
                            ◾
                        </ListItemIcon>
                        <ListItemText>
                            A shop listing from the Provider's own web shop is ideal.
                        </ListItemText>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{minWidth:0,mt:'4px',mr:'16px'}}>
                            ◾
                        </ListItemIcon>
                        <ListItemText>
                            If you can't find a page to meet these criteria, you can still give it a go, you might just need to fill out some information manually.
                        </ListItemText>
                    </ListItem>
                    
                {/*<Typography gutterBottom>◾ Pick a page that is dedicated to the product you want, such as the product landing page or shop listing.</Typography>
                <Typography gutterBottom>◾ If possible, make sure the page showcases just one product (rather than multiple bundles or products.)</Typography>
                <Typography gutterBottom>◾ Pick a page from the product provider's own website (rather than an Amazon listing for example.)</Typography>
                <Typography gutterBottom>◾ A shop listing from the Provider's own web shop is ideal.</Typography>
            <Typography gutterBottom>◾ If you can't find a page to meet these criteria, you can still give it a go, you might just need to fill out some information manually.</Typography>*/}
                </List>

            </DialogContent>
            <DialogActions >
                <Button variant="contained" sx={{color:'white'}} onClick={handleDlgClose}>Got it</Button>
            </DialogActions>
        </ResponsiveDialog>
        </>
    )
}

const ProductDetails = ({formData,continueFlow}) => {

    const [loading,setLoading] = useState(true);
    const [dlgOpen,setDlgOpen] = useState(false);
    const [isLongLoading,setIsLongLoading] = useState(false);

    const [continueDisabled,setContinueDisabled] = useState(true);

    const mainService = useContext(MainContext);

    const appId = formData.appId;
    const title = formData?.productData?.title ?? '';
    const strapline = formData?.productData?.strapline ?? '';
    const provider =  formData?.productData?.providerName ?? '';
    const description = formData?.productData?.description ?? '';
    const contentgenerated = formData?.productData?.contentgenerated ?? false;
    const errorGeneratingContent = formData?.productData?.errorGeneratingContent ?? false;

    const handleContinue = () => {
        //send request for problem statements/project titles
        mainService.applicationService.getProblemsAndTitles(title,strapline,provider,appId);
        //update application title
        mainService.applicationService.setApplicationName(appId,title);
        continueFlow();
    }

    const handleDlgClose = () => {
        mainService.applicationService.setApplicationProductError(appId,false);
        setDlgOpen(false);
    }

    useEffect(()=>{

        const longLoadingTimer = setTimeout(()=>{
            if(loading)
                setIsLongLoading(true);
        },20000);

        if(contentgenerated ) {
            setLoading(false);
            setIsLongLoading(false);
        } else if (errorGeneratingContent) {
            setLoading(false);
            setIsLongLoading(false);
            setDlgOpen(true);
        }

        const disableContinue = !contentgenerated;
        setContinueDisabled(disableContinue)

        return (()=>{
            if(longLoadingTimer)
                clearTimeout(longLoadingTimer);
        })

    },[formData])

    return(
        <>
        <Box sx={{ height:'100%', flexGrow:1, display:'flex', alignItems:'stretch', flexDirection:'column',maxWidth:'600px'}}>
            {loading ? (
            <Box sx={{width:'100%',height:'100%',flexGrow:1,display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center', gap:'16px'}}>
                <ProductLoading playing={loading} isLong={isLongLoading}/>
            </Box>) : (
            <> 
            <Box sx={{height:'10%'}}></Box>
            <Typography variant='h3' align='left' gutterBottom sx={{mx:'16px'}}>About your product</Typography>
            <Typography sx={{ mx:'16px'}}>Double check and ensure this information looks right</Typography>
            <Box sx={{my:'16px', display:'flex', flexDirection:'column', alignItems:'stretch'}}>

                <TextFormInput 
                    title={'Product name'}
                    value={title}
                    onChange={(v)=>mainService.applicationService.setApplicationProductTitle(appId, v)}
                    sx={{mb:'8px'}}
                    multiline
                />
                <TextFormInput 
                    title={'Provider name'}
                    value={provider}
                    onChange={(v)=>mainService.applicationService.setApplicationProductProvider(appId, v)}
                    sx={{mb:'8px'}}
                    multiline
                />
                <TextFormInput 
                    title={'Short description'}
                    value={strapline}
                    onChange={(v)=>mainService.applicationService.setApplicationProductStrapline(appId, v)}
                    sx={{mb:'8px'}}
                    multiline
                />
                <TextFormInput 
                    smallPara 
                    title='Long description'
                    value={description}
                    onChange={(v)=>mainService.applicationService.setApplicationProductDescription(appId, v)}
                />
                
                
            </Box>
            </>)}
            <Box sx={{flexGrow:1}}/>
            <StyledButton disabled={continueDisabled} text='Continue' sx={{mb:'64px', width:"175px", alignSelf:'center'}} onClick = {handleContinue}/>
        </Box>
        <ResponsiveDialog open={dlgOpen}  onClose={handleDlgClose}>
            <DialogTitle>Error occurred getting content</DialogTitle>
            <DialogContent>I'm sorry, an error has occurred while trying to access this product's content. In the meantime you can complete this form manually, or contact our support team for assistance.</DialogContent>
            <DialogActions><Button variant='contained' onClick={handleDlgClose}>Understood</Button></DialogActions>
        </ResponsiveDialog>
        </>
    )
}

const PricingDetails = ({formData,setDisableContinueButton}) => {
    const mainService = useContext(MainContext);
    const [allowContinue,setAllowContinue] = useState(false);
    const [currencyAdorn,setCurrencyAdorn] = useState('$');
    const title = formData?.productData?.title ?? '';

    const appId = formData.appId;
    //(!isNaN(parseInt(applicationMetadata.eligibilityStudentCount)) && parseInt(applicationMetadata.eligibilityStudentCount) > 0);
    const amount = formData?.productData?.basePricing?.amount ?? '';
    const amountIsNumber = !isNaN(parseInt(amount)) && parseInt(amount) > 0;
    const studentPerUnit = formData?.productData?.basePricing?.quantPerStudent ?? '';
    const studentsPerUnitIsNumber = !isNaN(parseInt(studentPerUnit)) && parseInt(studentPerUnit) > 0;
    const websiteurl = formData?.productData?.websiteurl ?? '';
    const currency = formData?.productData?.currency ?? '$';

    useEffect(()=>{
        console.log(amount);
        console.log(amountIsNumber);
        const disableContinue = !amount || !allowContinue || !amountIsNumber;
        if(setDisableContinueButton)
            setDisableContinueButton(disableContinue)
        setCurrencyAdorn(getCurrencyAdornment(currency));       
        return (()=>{

        })

    },[formData,allowContinue])



    return(
        <Box sx={{ height:'100%', flexGrow:1, display:'flex', alignItems:'stretch', flexDirection:'column',maxWidth:'600px'}}>
            <Box sx={{height:'10%'}}></Box>
            <Typography variant='h3' align='left' gutterBottom sx={{mx:'16px'}}>Product pricing information</Typography>
            <Typography sx={{ mx:'16px'}}>We need this information to calculate your project budget, double check the autofilled values and correct them if they are incorrect. <Link href={websiteurl} target='_blank'>Click here</Link> to revisit the product provider's page.</Typography>
                <Box sx={{my:'16px', display:'flex', flexDirection:'column', alignItems:'stretch'}}>
                <TextFormInput 
                    title={'Product unit price'}
                    value={amount}
                    onChange={(v)=>mainService.applicationService.setApplicationProductPrice(appId, v)}
                    sx={{mb:'32px'}}
                    startAdornment={currencyAdorn}
                    error = {!amountIsNumber}
                    helperText={!amountIsNumber ? `Product unit price couldn't be sourced correctly, please double check the provider website` : ``}
                />

                {
                    studentsPerUnitIsNumber && (
                    <>
                    <Typography gutterBottom sx={{ mx:'16px'}}>
                    {`It looks like the provider recommends ordering 1 unit of ${title} for every `} <b>{studentPerUnit}</b> student(s), this information will be used to calculate your budget. 
                    </Typography>
                    <Typography variant="h6" sx={{ mx:'16px',mb:'32px'}}>If you think this is incorrect it can be corrected in your budget later on.</Typography>
                    </>)
                }

                <Typography sx={{ mx:'16px'}}>I confirm that the price information is correct to the best of my knowledge.</Typography>
                <Checkbox 
                    checked={allowContinue}
                    value={allowContinue}
                    onChange={(e)=>setAllowContinue(e.target.checked)}
                    sx={{alignSelf:'flex-start',ml:'8px'}}
                />
                                
            </Box>           
        </Box>
    )
}


const Finish = ({formData})=>{

    const mainService = useContext(MainContext);

    const websiteurl = formData?.productData?.websiteurl ?? '';
    const title = formData?.productData?.title ?? '';
    const strapline = formData?.productData?.strapline ?? '';
    const provider =  formData?.productData?.providerName ?? '';
    const description = formData?.productData?.description ?? '';
    const amount = formData?.productData?.basePricing?.amount ?? '';
    const studentsPerUnit = formData?.productData?.basePricing?.quantPerStudent ?? '';
    const studentsPerUnitIsNumber = !isNaN(parseInt(studentsPerUnit)) && parseInt(studentsPerUnit) > 0;
    const ageRange = getAgeRangeString(formData?.productData?.minAge,formData?.productData?.maxAge);
    const currency = getCurrencyAdornment(formData?.productData?.basePricing?.currency);

    return(
        <Box sx={{ height:'100%', flexGrow:1, display:'flex', alignItems:'stretch', flexDirection:'column',maxWidth:'600px'}}>
            <Box sx={{height:'10%'}}></Box>
            <Typography gutterBottom align='left' variant="h2">Great, we've got your product details!</Typography>
            <Typography gutterBottom align='left'>If you're happy with this click continue to start building your application.</Typography>
            <Box sx={{my:'16px', display:'flex', flexDirection:'column', flexGrow:1}}>   
            <Typography variant='h3' sx={{mb:'16px'}}>Your Product Summary:</Typography>  
            <Typography variant='h4'>{title}</Typography>      
            <Typography variant='body2' gutterBottom>by {provider}</Typography>  
            <Typography sx={{mb:'8px'}}>{strapline}</Typography> 
            <Typography sx={{mb:'16px'}}>{description}</Typography> 
            {ageRange && <Typography ><b>Age range: </b>{ageRange} years</Typography> }
            <Typography><b>Unit price: </b>{currency}{amount}</Typography> 
            {studentsPerUnitIsNumber && <Typography><b>Number of students per unit: </b> {studentsPerUnit}</Typography> }
            <Typography><b>Website: </b> <Link href={websiteurl} target='_blank'>{websiteurl}</Link></Typography>
            </Box>
        </Box>
    )
}

const ProductCreationForm = ({userObject, applicationMetadata, appId, productData, loading, onFinish}) => {
    
    const mainService = useContext(MainContext);
    const [alertOpen,setAlertOpen] = useState(false);
    const [alertMsg,setAlertMsg] = useState('');
    const [alertSeverity,setAlertSeverity] = useState('error');

    const handleAlert = (msg,severity) => {
        setAlertOpen(true);
        setAlertMsg(msg);
        setAlertSeverity(severity ?? 'success');
    }

    //RENDER 
    const formData = {
        
        startNode: 'startNode',

        userObject,

        applicationMetadata,

        productData,

        appId,

        onAlert: handleAlert,

        nodes:{
            startNode:{
                component:Start,
                hideContinueButton:true,
                next:()=>'productDetailsNode',
            },
            productDetailsNode:{
                component:ProductDetails,
                hideContinueButton:true,
                next:()=>'pricingDetailsNode'
            },
            pricingDetailsNode:{
                component:PricingDetails,
                end:true,
                //next:()=>'finishNode'
            },
            /*finishNode:{
                component:Finish,
                end:true,
            },*/
        }
    }

    return (
    <>
        <Paper sx={{flexGrow:1, overflowX:"hidden",position:'relative', display:'flex', flexDirection:'column', mb:'32px', mx:'32px', maxWidth:'1200px'}}>
            <FormFlow onFinish={onFinish} formData={formData}/>
        </Paper>
        <Snackbar open={alertOpen} autoHideDuration={800} onClose={()=>{setAlertOpen(false)}}>       
            <Alert onClose={()=>{setAlertOpen(false)}} severity={alertSeverity} sx={{ width: '100%' }}>        
                {alertMsg}           
            </Alert>       
        </Snackbar>
    </>
    )
}

export default ProductCreationForm; 