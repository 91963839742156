import { keyframes } from  '@mui/system';

const fadeout = keyframes`
0% {
opacity: 1;
}
50% {
opacity:0.8;
}
100% {
    opacity: 0;
}
`;

export default fadeout;