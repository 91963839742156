import  {useState, useEffect, useContext } from "react";
import MainContext from "../../MainContext";
import AddIcon from '@mui/icons-material/Add';

import { 
        Box, 
        Typography, 
        CircularProgress,
        IconButton} 
    from '@mui/material';

import theme from "../../Theme";

import EligibilityCriteria from "./EligibilityCriteria";

const granteeCriteria = ["location","demographic","schoolType","locale","membership","funding"];

const projectCriteria = [
    "gradeMin","gradeMax","studentFocus","subjectFocus","projectStyle","projectFocus","studentReachMax","studentReachMin"
];


const GrantEligibility = ({gid}) => {

    //Use
	const mainService = useContext(MainContext);

    //STATES
    const [loading,setLoading] = useState(false);
    
    const [granteeElList, setGranteeElList] = useState([]);
    const [projectElList, setProjectElList] = useState([]);

    const [newGranteeElOpen,setNewGranteeElOpen] = useState(false);
    const [newGranteeElDoc,setNewGranteeElDoc] = useState("");
    const [newGranteeElField,setNewGranteeElField] = useState("");
    const [newGranteeElValue,setNewGranteeElValue] = useState("or");

    const [newProjectElOpen,setNewProjectElOpen] = useState(false);
    const [newProjectElDoc,setNewProjectElDoc] = useState("");
    const [newProjectElField,setNewProjectElField] = useState("");
    const [newProjectElValue,setNewProjectElValue] = useState("or");


    //
    //Eligibility
    //

    //new grantee eligibility
    const handleOpenNewGranteeEl = () => {
        setNewGranteeElOpen(true);
    }

    const handleSetNewGranteeElDoc = (val) => {
        setNewGranteeElDoc(val)
    }

    const handleSetNewGranteeElField = (val) => {
        
        setNewGranteeElField(val);
    }

    const handleSetNewGranteeElValue = (val) => {
        const value = val ? "and" : "or"
        setNewGranteeElValue(value)
    }

    const handleAddNewGranteeElCriteria = async () => {
        try{
            let data ={};
            data[encodeURIComponent(newGranteeElField)]  = newGranteeElValue;
            await mainService.intellectibaseService.setToSubcollection("grants",gid,"granteeCriteria",newGranteeElDoc,data);
            setNewGranteeElValue('or');
        }
        catch(error){
            console.error(error);
        }
        
    }

    const handleCloseNewGranteeEl = () => {
        setNewGranteeElDoc("");
        setNewGranteeElField("");
        setNewGranteeElOpen(false);
    }

    const handleDeleteGranteeEl = async (doc,field) =>{
        setLoading(true);
        try{
            console.log('deleting ' + doc + ' ' + field)
            await mainService.intellectibaseService.deleteFieldFromSubcollection("grants",gid,"granteeCriteria",doc,field);
            setLoading(false);
        }
        catch(error){
            console.error(error);
            setLoading(false);
        }
    }

    //new project eligibility
    const handleOpenNewProjectEl = () => {
        setNewProjectElOpen(true);
    }
    const handleSetNewProjectElDoc = (val) => {
        setNewProjectElDoc(val)
    }

    const handleSetNewProjectElField = (val) => {
        setNewProjectElField(val)
    }

    const handleSetNewProjectElValue = (val) => {
        const value = val ? "and" : "or"
        setNewProjectElValue(value)
    }

    const handleAddNewProjectElCriteria = async () => {
        try{
            let data ={};
            data[encodeURIComponent(newProjectElField)] = newProjectElValue;
            await mainService.intellectibaseService.setToSubcollection("grants",gid,"projectCriteria",newProjectElDoc,data);
            setNewProjectElValue('or');
        }
        catch(error){
            console.error(error);
        }
        
    }

    const handleCloseNewProjectEl = () => {
        setNewProjectElDoc("");
        setNewProjectElField("");
        setNewProjectElOpen(false);
    }

    const handleDeleteProjectEl = async (doc,field) =>{
        setLoading(true);
        try{
            await mainService.intellectibaseService.deleteFieldFromSubcollection("grants",gid,"projectCriteria",doc,field);
            setLoading(false);
        }
        catch(error){
            console.error(error);
            setLoading(false);
        }
    }


    //LISTENERS

    const listenGranteeCritChanged = (docs) =>{
        if(docs){

            let granteeCritList = [];
            docs.forEach((doc) => {
                Object.keys(doc.data()).forEach((field)=>{
                    let obj = {doc:doc.id,field:decodeURIComponent(field),value:doc.data()[field]}
                    granteeCritList.push(obj);
                })
            });
            setGranteeElList(granteeCritList);
        }
        else{
            setGranteeElList([]);
        }
    }

    const listenProjectCritChanged = (docs) =>{
        if(docs){
            let projectCritList = [];
            docs.forEach((doc) => {
                Object.keys(doc.data()).forEach((field)=>{
                    let obj = {doc:doc.id,field:decodeURIComponent(field),value:doc.data()[field]}
                    projectCritList.push(obj);
                })
            });
            setProjectElList(projectCritList);
        }
        else{
            setProjectElList([]);
        }
    }

    //Effects
	useEffect(() => {
		//on mount
		console.log('Grant criteria mounted'); 
        mainService.intellectibaseService.listenGranteeCriteria(gid,listenGranteeCritChanged);
        mainService.intellectibaseService.listenProjectCriteria(gid,listenProjectCritChanged)
		//on unmount
		return ()=>{
			console.log('Grant criteria unmounted');
            mainService.intellectibaseService.unsubscribeGranteeCriteria();
            mainService.intellectibaseService.unsubscribeProjectCriteria();
		}

	}, []);



    return (
        <Box sx={{display:"flex",flexDirection:"Column",position:"relative",minWidth:"600px",overflow:"hidden"}}>

            {loading && (<Box sx={{ width:"100%",height:"100%", position:"absolute",backgroundColor:"rgba(255,255,255,0.5)",display:"flex",alignItems:"center",flexGrow:1,justifyContent:"center"}}>
                <CircularProgress/>
            </Box>)}

            <Box sx={{width:"100%",height:"100%",filter:loading && "blur(3px)", display:"flex",flexDirection:"Column",overflow:"hidden",gap:'16px'}}>

                <Typography sx={{}} variant="h2">Eligibility criteria</Typography>

                <Box sx={{overflowY:"auto"}}>

                    <Box sx={{width:"100%",height:"4px",boxSizing:"border-box", borderTop:`solid 1px ${theme.palette.grey[400]}`,mt:theme.spacing(1)}} />

                    <Box sx={{display:"flex", flexDirection:"row",gap:"16px",alignItems:"center"}}>
                        <Typography sx={{m:theme.spacing(1)}} variant="h4">Grantee Eligibility</Typography>
                        <IconButton disabled={newGranteeElOpen}  onClick={handleOpenNewGranteeEl}><AddIcon/></IconButton>
                    </Box>
                    {newGranteeElOpen &&
                        <EligibilityCriteria 
                            type={"grantee"} 
                            crit={newGranteeElDoc}  
                            critValue={newGranteeElField} 
                            critValues={granteeCriteria}
                            canEdit 
                            onCritChange={handleSetNewGranteeElDoc}
                            onCritValueChange={handleSetNewGranteeElField}
                            onCritRequiredChange={handleSetNewGranteeElValue}
                            onAdd={handleAddNewGranteeElCriteria} 
                            onRemove={handleCloseNewGranteeEl}
                        />
                    }
                    <Box sx={{maxHeight:"200px",overflowY:"overlay"}}>
                    {granteeElList.map((el,i)=>{
                        return (
                            <EligibilityCriteria key={i} type={"grantee"} crit={el.doc}  critValue={el.field} onRemove={handleDeleteGranteeEl} required={el.value === 'and'} />
                        );
                    })}
                    </Box>

                    <Box sx={{width:"100%",height:"4px",boxSizing:"border-box", borderTop:`solid 1px ${theme.palette.grey[400]}`,mt:theme.spacing(1)}} />
                    <Box sx={{display:"flex", flexDirection:"row",gap:"16px",alignItems:"center"}}>
                        <Typography sx={{m:theme.spacing(1)}} variant="h4">Project Eligibility</Typography>
                        <IconButton disabled={newProjectElOpen} onClick={handleOpenNewProjectEl}><AddIcon/></IconButton>
                    </Box>
                    {newProjectElOpen &&
                        <EligibilityCriteria 
                            type={"project"} 
                            crit={newProjectElDoc}  
                            critValue={newProjectElField}
                            critValues={projectCriteria} 
                            canEdit 
                            onCritChange={handleSetNewProjectElDoc}
                            onCritValueChange={handleSetNewProjectElField}
                            onCritRequiredChange={handleSetNewProjectElValue}
                            onAdd={handleAddNewProjectElCriteria} 
                            onRemove={handleCloseNewProjectEl}
                        />
                    }
                    <Box sx={{maxHeight:"200px",overflowY:"overlay"}}>
                        {projectElList.map((el,i)=>{
                            return (
                                //type,crit,critValue,canEdit,onAdd,onRemove
                                <EligibilityCriteria key={i} type={"project"} crit={el.doc}  critValue={el.field} onRemove={handleDeleteProjectEl} required={el.value === 'and'}/>
                            );
                        })}
                    </Box>
                </Box> 
            </Box>                     
        </Box>
    );
};

export default GrantEligibility;