import React, {useState, useEffect, useContext } from "react";

import MainContext from "../MainContext"; 

import theme from '../Theme';

import useMediaQuery from '@mui/material/useMediaQuery';

import { ThemeProvider } from  '@mui/system';
import {Box,Typography, Dialog, Button} from '@mui/material';

const YesNoDialog = ({text, onYes, onNo, open, sx}) => {

    return(
        <ThemeProvider theme={theme}>
            <Dialog onClose={onNo} open={open}>
                <Box sx={{display:'flex', flexDirection:'column', p:'16px', ...sx}}>
                    <Typography sx={{mb:'8px'}}>{text}</Typography>
                    <Box sx={{display:'flex',gap:"8px"}}>
                        <Box sx={{flexGrow:1}}/>
                        <Button sx={{color:'white'}} variant = "contained" onClick={onNo}>No</Button>
                        <Button variant = "outlined" onClick={onYes}>Yes</Button>
                    </Box>
                </Box>
            </Dialog>
        </ThemeProvider>
    )
}

export default YesNoDialog;