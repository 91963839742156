import React, {useState, useEffect, useContext } from "react";

import MainContext from "../../MainContext"; 

import { Box, TextField, Typography} from "@mui/material";

import CopyButton from "./CopyButton";
import CopyLink from "./CopyLink";
import ResponsiveDialog from "../../components/ResponsiveDialog";

const ShareProductDialog = ({open, onClose, listingData, listingId, onLoading, onAlert, userObject}) => {

    //States
    const [emailCopy,setEmailCopy] = useState(null);

    //Use
	const mainService = useContext(MainContext);

    //Listeners
    const listenEmailCopyChanged = (data) => {
        console.log('setting up email copy listener')
        setEmailCopy(data);
    }

    //Effects
    useEffect(()=>{
        if(listingId)
            mainService.productListingService.addEmailCopyListener(listingId,listenEmailCopyChanged);
        return (()=>{
            if(listingId)
                mainService.productListingService.removeEmailCopyListener(listingId,listenEmailCopyChanged);
        })
    },[listingId])

    const buttonHTML = `<a href="https://edu.intellectible.com/home?module=applications&action=new&name=${encodeURIComponent(listingData?.title)}&productid=${listingId}&usertype=teacher" target="_blank">`+
        `<button style="background-color: #54BD95; color: white; padding: 12px 24px; border-radius: 8px; border: none; cursor: pointer; font-size: 16px;">` +
        `Apply with Intellectible` +
        `</button></a>`;

    return (       
        <ResponsiveDialog open={open} onClose={onClose} maxWidth='lg'>
            <Box sx={{p:'16px'}}>
                <Typography variant="h2" sx={{mb:'16px'}}>Share your product with educators</Typography>

                <Typography variant="h4" gutterBottom>Link to product page</Typography>
                <Typography variant='body2'>This link takes the user to your product page, where they can view your product information and click apply to start a new application for your product </Typography>
                <CopyLink sx={{mb:'16px'}} link={`https://edu.intellectible.com/product?listingid=${listingId}`} onCopy={()=>onAlert('Link copied to clipboard successfully','success')}/>

                <Typography variant="h4" gutterBottom>Link to apply directly</Typography>
                <Typography variant='body2'>This link rederedicts the user directly to create a new grant application for your product.</Typography>
                <CopyLink sx={{mb:'16px'}} link={`https://edu.intellectible.com/home?module=applications&action=new&name=${encodeURIComponent(listingData?.title)}&productid=${listingId}&usertype=teacher`} onCopy={()=>onAlert('Link copied to clipboard successfully','success')}/>
                
                <Typography variant="h4" gutterBottom>Button to apply directly</Typography>
                <Typography variant='body2' sx={{mb:'8px'}} >This button can be embedded into your website or email campaign to allow teachers to apply directly for this product.</Typography>        
                <Box sx={{display:'flex',gap:'8px',alignItems:'center',mb:'28px'}}>
                    <div dangerouslySetInnerHTML={{__html:buttonHTML}}></div>
                    <CopyButton text={buttonHTML} onCopy={()=>onAlert('HTML copied to clipboard successfully','success')} copyText={'Copy HTML'}/>
                </Box>      
                
                <Typography variant="h4" gutterBottom>Copy for email campaign</Typography>
                <Typography sx={{mb:'8px'}} variant='body2'>Share your product in an email campaign. We've generated some email copy to help get you started! </Typography>

                <Typography gutterBottom>Subject line</Typography>
                <Box sx={{display:'flex',gap:'8px',alignItems:'center',mb:'16px'}}>
                    <TextField
                        variant="standard"
                        value = {emailCopy?.subject ?? ''}
                        onChange = {(e)=>mainService.productListingService.writeEmailCopySubject(listingId,e.target.value)}
                        fullWidth
                    /> 
                    <CopyButton text= {emailCopy?.subject ?? ''} onCopy={()=>onAlert('Email subject copied to clipboard successfully','success')} copyText={'Copy text'}/>
                </Box>

                <Typography gutterBottom>Body</Typography>
                <Box sx={{display:'flex',gap:'8px',alignItems:'center',mb:'16px'}}>
                    <TextField
                       multiline
                       value = {emailCopy?.body ?? ''}
                       onChange = {(e)=>mainService.productListingService.writeEmailCopyBody(listingId,e.target.value)}
                       fullWidth
                    /> 
                    <CopyButton text={emailCopy?.body ?? ''} onCopy={()=>onAlert('Email body copied to clipboard successfully','success')} copyText={'Copy text'}/>
                </Box>
            </Box>
        </ResponsiveDialog>
    )
}

export default ShareProductDialog;