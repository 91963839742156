import React, {useState} from "react";

import Theme from '../../Theme';

import { Box, Typography, CircularProgress, DialogTitle, DialogContent, DialogActions, Button} from "@mui/material";

import ResponsiveDialog from "../../components/ResponsiveDialog";
import ConditionalImage from "./ConditionalImage";



const WebImagesDialog = ({urls,open,onClose,onUpload,multiSelect}) => {
    const [selected,setSelected] = useState(null);

    const isSelected = (imgurl) => {
            if(multiSelect){
                return selected ? selected.includes(imgurl) : false;
            }
            else {
                return selected ? selected === imgurl : false;
            }
    }

    const handleSelect = (imgurl) => {
        const urlSelected = isSelected(imgurl);
        if(multiSelect && !urlSelected){
            let urlArr = selected ? [...selected] : [];
            urlArr.push(imgurl);
            setSelected(urlArr);
        }
        else if (multiSelect && urlSelected){
            let urlArr = [...selected];
            let i = urlArr.indexOf(imgurl);
            urlArr.splice(i,1);
            setSelected(urlArr);
        }
        else if(!multiSelect && urlSelected) {
            setSelected(null);   
        }
        else{
            setSelected(imgurl);
        }
    }

    const handleDone = () => {
        if(selected && onUpload){
            console.log('selected',selected)
            onUpload(selected);
        }
        setSelected(null);
        onClose();
    }

    const handleClose = () => {
        setSelected(null);
        onClose();
    }

    return (
        <ResponsiveDialog onClose={handleClose} open={open} >
            <DialogTitle>Pick an image from the product website</DialogTitle>
            <DialogContent>
                <Box sx={{position:'relative', display:"flex", flexDirection:"row", my:'16px', mb:'48px', overflowX:'auto'}}>
                    {!urls &&
                        (<Box sx={{width:'428px', height:'260px', position:'relative', flexGrow:1,display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <CircularProgress />
                        </Box>)
                    }
                    {urls && urls.map((url, inx)=>{
                        const urlSelected = isSelected(url);

                        return(
                            <Box 
                                onClick={()=>handleSelect(url,inx)} 
                                key={url+inx} 
                                sx={{ position:'relative',display:"flex", flexDirection:"column", m:'8px'}}>
                            {urlSelected && multiSelect && (
                                <Box sx={{zIndex:5,display:'flex',justifyContent:'center',alignItems:'center',position:'absolute',p:'8px',bgcolor:Theme.palette.primary.main,top:'2px',left:'2px',borderRadius:'50px',width:'32px',height:'32px'}}>
                                  <Typography>{selected.indexOf(url)+1}</Typography>
                                </Box>
                            )}
                            <ConditionalImage width='428px' height='260px' url={url} selected={urlSelected} style={{position:'absolute', width:'100%', height:'100%', objectFit:'contain'}} />
                            </Box>
                        )
                    })
                    }
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined">
                    Cancel
                </Button>
                <Button 
                    variant="contained" 
                    sx={{color:"white"}}
                    onClick = {handleDone}
                >
                    Select
                </Button>
            </DialogActions>
        </ResponsiveDialog>
    )
}

export default WebImagesDialog; 