import WebFont from  'webfontloader';
import opentype from 'opentype.js'; 
import { jsPDF } from "jspdf";
import { Document, Packer, Paragraph, TextRun, Tab } from "docx";

//Load fonts for reading metadata
const fontData = new Map();
const LoadFont = (name, srcLight, srcMedium, srcRegular, srcSemiBold, srcBold)=>{

    
    //light:300, regular:400, medium:500, semiBold:600, bold:700
    //Load vthe font and parse to get metrics
    opentype.load(srcBold, (err, font)=>{ 
        fontData.set(name, font);
    }); 


    //load the font into the document 
    const fontLight = new FontFace(name, `url(${srcLight})`, {weight: "300",}); 
    fontLight.load().then((font)=>{
        document.fonts.add(font);
    });

    const fontRegular = new FontFace(name, `url(${srcRegular})`, {weight: "400",}); 
    fontRegular.load().then((font)=>{
        document.fonts.add(font);
    });

    const fontMedium = new FontFace(name, `url(${srcMedium})`, {weight: "500",}); 
    fontMedium.load().then((font)=>{
        document.fonts.add(font);
    });

    const fontSemiBold = new FontFace(name, `url(${srcSemiBold})`, {weight: "600",}); 
    fontSemiBold.load().then((font)=>{
        document.fonts.add(font);
    });

    const fontBold = new FontFace(name, `url(${srcBold})`, {weight: "700",}); 
    fontBold.load().then((font)=>{
        document.fonts.add(font);
    });
}

//Load Inter 
LoadFont('Inter', 
    'assets/fonts/Inter-Light.ttf', 
    'assets/fonts/Inter-Medium.ttf', 
    'assets/fonts/Inter-Regular.ttf', 
    'assets/fonts/Inter-SemiBold.ttf', 
    'assets/fonts/Inter-Bold.ttf', 
); 




const RenderTypeText = 0; 
const RenderTypeBox = 1; 
const RenderTypeImage = 2; 

export default class CanvasDocumentRenderer{

    constructor(){

        this.nodeData = {}; 
        this.nodeGeometry = new Map();
        this.hoverBetween = {nodeId:'', betweenNodes:new Set(), geometry:null, secondGeometry:null};
        this.nodeParentMap = new Map(); 

        this.selectionFinishedCallbacks = new Set(); 
        this.modifierGeometryUpdateCallbacks = new Set(); 
        this.nodesGeometryUpdateCallbacks = new Set(); 
        this.hoverUpdateCallbacks = new Set(); 
        this.hoverBetweenUpdateCallbacks = new Set()

        this.textSelection = {hasSelection:false, hasCaret:false, geometry:[], boundingBox:null, nodes:[]}; 

        this.canvas = null; 
        this.ctx = null;
        this.canvasWidth = 0; 
        this.canvasHeight = 0; 
        this.pageCount = 0; 
        this.renderQueue = []; 
        this.pageRenderSettings = []; 
        this.measureTextcache = new Map(); 

        this.scrollValue = 0;
        this.pointerStartPos = {x:0, y:0,}; 
        this.pointerLatestPos = {x:0, y:0,}; 
        this.pointerDown = false; 
        this.dragSelectAABB = {minx: 0, miny: 0, maxx: 0, maxy: 0,}; 
        this.multiClickCount = 0;

        

        this.defaultStyles = {}
        
        this.defaultStyles['document'] = {
            
        }; 

        this.defaultStyles['section'] = {
            pageWidth:210,
            pageHeight:297,
            marginTop:25.4, 
            marginBottom:25.4, 
            marginLeft:25.4, 
            marginRight:25.4, 
        }; 

        this.defaultStyles['text'] = {
            fontStyle : 'normal',  
            fontWeight : 'normal',  
            fontSize : 12, 
            fontFace : 'Inter',   
            color: '#222222', 
        };

        this.defaultStyles['paragraph'] = {
            beforeSpacing : 0,  
            afterSpacing : 8,  
            lineSpacing : 1.5, 
        };

        this.defaultStyles['heading1'] = {
            beforeSpacing : 0,  
            afterSpacing : 8,  
            lineSpacing : 1.5, 
        };

        this.defaultStyles['heading2'] = {
            beforeSpacing : 0,  
            afterSpacing : 8,  
            lineSpacing : 1.5, 
        };

        this.defaultStyles['table'] = {
            beforeSpacing : 8,  
            afterSpacing : 0,  
        };

        this.defaultStyles['table_row'] = {
            height:5,
            backgroundColor:'#dddddd',
        };

        this.defaultStyles['table_cell'] = {
            backgroundColor:'#dddddd',
            paddingLeft:0, 
            paddingTop:0,
        };

        this.defaultStyles['list'] = {
            fontStyle : 'normal',  
            fontWeight : 'normal',  
            fontSize : 12, 
            fontFace : 'Inter',   
            color: '#222222', 
        }

        this.pageGap = 5; 

        this.images = new Map(); 

        this.usePageNums = true; 
        this.disabledPageNums = new Set(); 
        this.pageNumsFontFace = 'Inter'; 
        this.pageNumsFontSize = 11; 
        this.pageNumsFontColor = '#222222'; 
        this.pageNumsFontWeight = 'normal'; 
        this.pageNumsFontStyle = 'normal'; 


        this.modifiers = [];

        this.caretColor = '#000000'; 
        this.caretTick = false; 
        this.caretInterval = null; 
        this.startCaretInterval(); 
        
    }

    aproxEq(a, b){
        let eps = 0.00001; 
        return (b < a+eps) && (b >= a-eps);
    }

    dpr(){return window.devicePixelRatio ? window.devicePixelRatio : 1; }
    in2Px(v){return 96*v*this.dpr()}
    mm2Px(v){return 3.77831849714*v*this.dpr()}
    px2mm(v){return v/(3.77831849714*this.dpr())}
    pt2px(v){return 1.3333333333*v*this.dpr(); }

    getDim(v, p=0){
        if (typeof v == 'string' && v.length > 0){
            if(v.endsWith('%')){
                return +(v.split('%')[0])/100.0 * p; 
            } else {
                return 0; 
            }
        } else {
            return v; 
        }
    }

    getStyleProp(node, nodeStyle, prop, parentVal=0){
        let val = nodeStyle && typeof nodeStyle[prop] !== 'undefined' ? nodeStyle[prop] : 
            node.type && this.defaultStyles[node.type] && typeof this.defaultStyles[node.type][prop] !== 'undefined' ? this.defaultStyles[node.type][prop] : 
            null;
            
        if(val != null && prop == 'width'){
            let finalVal = this.getDim(val, parentVal);
            return finalVal;  
        }

        if(val != null && prop == 'height'){
            let finalVal = this.getDim(val, parentVal);
            return finalVal;  
        }

        return val; 
    }

    getStyleObj(node, styleData){
        return (!node) ? null :
               (styleData && typeof node.style == 'string') ? styleData[node.style] : 
               typeof node.style == 'object' ? node.style : null; 
    }

    getDecender(fontName, fontSize){
        let font = fontData.get(fontName); 
        let fontSizePx = this.pt2px(fontSize); 
        let descenderPx = fontSizePx * (font.descender / font.unitsPerEm); 
        return -descenderPx; 
    }

    getAscender(fontName, fontSize){
        let font = fontData.get(fontName); 
        let fontSizePx = this.pt2px(fontSize); 
        let ascenderPx = fontSizePx * (font.ascender / font.unitsPerEm); 
        return ascenderPx; 
    }

    getLineSpace(fontName, fontSize, lineSpacing){
        if(!fontData.has(fontName)){
            return 0; 
        }

        let font = fontData.get(fontName); 
        let fontSizePx = this.pt2px(fontSize); 
        let ascenderPx = fontSizePx * (font.ascender / font.unitsPerEm); 
        let descenderPx = fontSizePx * (font.descender / font.unitsPerEm); 
        let lineGapPx = fontSizePx * (font.tables.os2.sTypoLineGap / font.unitsPerEm); 
        return {lineSpace:(ascenderPx - descenderPx) * lineSpacing, ascender:ascenderPx, descender:descenderPx} ; 
    }

    measureText(fontName, fontSize, text){
        let font = fontData.get(fontName);
        if(!font )
            return {width:0}; 

        let W = font.getAdvanceWidth(text, fontSize); 
        return {width:W}; 
    }

    measureText2(ctx, text){
        let font = ctx.font; 
        let key = font + text; 
        let cache = this.measureTextcache.get(key);
        if(cache){
            return cache; 
        }

        let metrics = ctx.measureText(text); 
        this.measureTextcache.set(key, metrics)
        return metrics; 
    }

    /*
    getLineSpace2(fontName0, fontSize0, fontName1, fontSize1, lineSpacing){
        let font0 = fontData.get(fontName0); 
        let fontSizePx0 = this.pt2px(fontSize0); 
        let descenderPx0 = fontSizePx0 * (font0.descender / font0.unitsPerEm); 

        let font1 = fontData.get(fontName1); 
        let fontSizePx1 = this.pt2px(fontSize1); 
        let ascenderPx1 = fontSizePx1 * (font1.ascender / font1.unitsPerEm); 
        
        return (ascenderPx1 - descenderPx0) * lineSpacing ; 
    }*/

    setScroll(val){
        this.scrollValue = val * this.dpr(); 
    }

    setCanvasSize(w, h){

        this.canvasWidth = w;
        this.canvasHeight = h;
        if(this.canvas){
            this.canvas.width = w; 
            this.canvas.height = h;
        } 
    }

    setCanvas(canvas){

        this.canvas = canvas; 
        this.ctx = canvas.getContext("2d");
        const deviceScale = this.dpr();
        const scaledWidth = Math.floor(canvas.clientWidth * deviceScale); 
        const scaledHeight = Math.floor(canvas.clientHeight * deviceScale); 
        this.canvasWidth = scaledWidth;
        this.canvasHeight = scaledHeight;
    }

    startCaretInterval(){
        if(this.caretInterval){
            clearInterval(this.caretInterval); 
        }
        this.caretInterval = setInterval(()=>{
            this.caretTick = !this.caretTick; 
            if(!this.textSelection.hasSelection && this.textSelection.nodes.length > 0)
                this.draw(); 
        }, 500); 
    }

    resetCaretFlash(isOn){
        this.caretTick = isOn;
        if(!this.textSelection.hasSelection && this.textSelection.nodes.length > 0)
                this.draw(); 
        this.startCaretInterval(); 
    }

    buildNodeParentMap(nodeData, startNodeId){
        let parentMap = new Map(); 

        if(!nodeData || !nodeData[startNodeId])
            return parentMap;

        let startNode = nodeData[startNodeId]; 

        const processNode = (node, nodeid)=>{
            let children = node.children;
            
            if(!children)
                return; 

            for(let childid of children){
                let child = nodeData[childid];
                if(child){
                    parentMap.set(childid, nodeid); 
                    processNode(child, childid);
                } 
            }
        }

        processNode(startNode, startNodeId); 

        return parentMap; 
    }

    getParentNode(nodeid){
        if(!this.nodeParentMap.has(nodeid))
            return null; 

        return this.nodeParentMap.get(nodeid); 
    }

    nodeWithinParentOfType(nodeid, type, recure=true){
        const check = (nodeid)=>{
            //check if node is of type 
        }
    }

    buildList(nodeData, styleData, listNode, x, y, boxWidth,
        pageWidth, pageHeight, 
        pageMl, pageMr, pageMb, pageMt, 
        autoPageBreak=true, pageGap=this.mm2Px(this.pageGap),
        currentPageCount=this.pageCount, currentPageY=this.currentPageY, currentDocumentHeight=this.currentDocumentHeight){

        let pageCount = currentPageCount;
        let listStyle = this.getStyleObj(listNode, styleData); 

        //grab the items
        let itemIds = listNode.children; 
        if(!Array.isArray(itemIds))
            return; 
        let items = [];
        for(let i = 0; i < itemIds.length; i++){
            let id = itemIds[i]; 
            let item = nodeData[id]; 
            if(item && item.type == 'paragraph')
                items.push(item); 
        } 

        //
        let beforeSpacing = this.getStyleProp(listNode, listStyle, 'beforeSpacing'); 
        let afterSpacing = this.getStyleProp(listNode, listStyle, 'afterSpacing'); 
        let penPos = {x:x, y:y}; 

        //apply the before spacnig 
        penPos.y += this.pt2px(beforeSpacing);

        //create new page and set pen position to top of new page if needed 
        if(autoPageBreak){
            if(penPos.y > currentPageY + pageHeight - pageMb){
                penPos.y = currentPageY + pageHeight + pageGap + pageMt
                currentPageY += pageHeight + pageGap; 
                currentDocumentHeight = currentPageY + pageHeight + pageGap; 
                pageCount++; 
            }
        }

        //
        let itemInx = 0; 
        let fontStyle = this.getStyleProp(listNode, listStyle, 'fontStyle'); 
        let fontWeight = this.getStyleProp(listNode, listStyle, 'fontWeight'); 
        let fontSize = this.getStyleProp(listNode, listStyle, 'fontSize'); 
        let fontFace = this.getStyleProp(listNode, listStyle, 'fontFace'); 
        let fillStyle = this.getStyleProp(listNode, listStyle, 'color'); 
        let textBaseline = "alphabetic";
        let textAlign = "left";
        let shadowBlur = 0;
        let font = '' + fontStyle + ' ' + fontWeight + ' ' + this.pt2px(fontSize) + 'px ' + fontFace;

        for(let item of items){
            //reset the start position after each row has been rendered
            penPos.x = x;

            //get the row style 
            let nodeStyle = this.getStyleObj(item, styleData); 
            let node = item; 

            //grap the block level line spacing and reset the latest line height
            let lineSpacing = this.getStyleProp(node, nodeStyle, 'lineSpacing'); 
            let beforeSpacing = this.getStyleProp(node, nodeStyle, 'beforeSpacing'); 
            let afterSpacing = this.getStyleProp(node, nodeStyle, 'afterSpacing'); 

            //apply the before spacnig 
            let prePenY = penPos.y;
            penPos.y += this.pt2px(beforeSpacing);
             
            //create new page and set pen position to top of new page if needed 
            if(autoPageBreak){
                if(penPos.y > currentPageY + pageHeight - pageMb){
                    penPos.y = currentPageY + pageHeight + pageGap + pageMt
                    currentPageY += pageHeight + pageGap; 
                    currentDocumentHeight = currentPageY + pageHeight + pageGap; 
                    pageCount++; 
                }
            }

            //draw bullet point
            let bulletWidth = this.mm2Px(8); 

            let ff = fontFace ? fontFace : 'Inter'; 
            let fs = fontSize ? fontSize : 12; 
            let ascender = this.getAscender(ff, fs) * lineSpacing;  

            this.renderQueue.push({origNode:listNode, origNodeId:'', type: RenderTypeText, page:pageCount-1, text:''+itemInx+'.', textInxOffset: 0, 
                x:penPos.x, y:penPos.y+ascender, font, fillStyle, shadowBlur, textBaseline, textAlign, fontSize, fontWeight, fontFace});

            penPos.x += bulletWidth; 

            //for every child node
            let ret = this.buildText(
                nodeData,
                styleData, 
                node,
                penPos.x, 
                penPos.y, 
                pageWidth-pageMl-pageMr-bulletWidth, 
                lineSpacing, pageHeight, pageMb, pageMt, true, this.pageGap, pageCount, currentPageY, currentDocumentHeight); 

            penPos = ret.penPos; 
            pageCount = ret.pageCount; 
            currentPageY = ret.currentPageY; 
            currentDocumentHeight = ret.currentDocumentHeight
            
            //after each block level node we need to move the cursor down by the "after" ammmount, to add gap between paragraph
            penPos.y += this.pt2px(afterSpacing);

            itemInx++; 
        }

        //after each block level node we need to move the cursor down by the "after" ammmount, to add gap between paragraph
        penPos.y += this.pt2px(afterSpacing);

        return {pageCount, penPos, currentPageY, currentDocumentHeight}
    }

    buildTable(nodeData, styleData, tableNode, x, y, boxWidth,
                pageWidth, pageHeight, 
                pageMl, pageMr, pageMb, pageMt, 
                autoPageBreak=true, pageGap=this.mm2Px(this.pageGap),
                currentPageCount=this.pageCount, currentPageY=this.currentPageY, currentDocumentHeight=this.currentDocumentHeight){

        let pageCount = currentPageCount; 

        let tableStyle = this.getStyleObj(tableNode, styleData); 

        //grab the row nodes
        let rowIds = tableNode.children; 
        if(!Array.isArray(rowIds))
            return; 
        let rows = [];
        for(let i = 0; i < rowIds.length; i++){
            let id = rowIds[i]; 
            let row = nodeData[id]; 
            if(row && row.type == 'table_row')
                rows.push(row); 
        } 

        let beforeSpacing = this.getStyleProp(tableNode, tableStyle, 'beforeSpacing'); 
        let afterSpacing = this.getStyleProp(tableNode, tableStyle, 'afterSpacing'); 
        let penPos = {x:x, y:y}; 

        //apply the before spacnig 
        penPos.y += this.pt2px(beforeSpacing);
        
        //create new page and set pen position to top of new page if needed 
        if(autoPageBreak){         
            if(penPos.y > currentPageY + pageHeight - pageMb){
                penPos.y = currentPageY + pageHeight + pageGap + pageMt
                currentPageY += pageHeight + pageGap; 
                currentDocumentHeight = currentPageY + pageHeight + pageGap; 
                pageCount++; 
            }
        }

        //calculate the number of rows and cols in the table
        let rowCount = rows.length; 
        let colCount = 0;
        for(let i = 0; i < rows.length; i++){
            let row = rows[i]; 
            
            if (!(row.children && Array.isArray(row.children)))
                continue; 

            let localColCount = row.children.length; 
            if(localColCount > colCount)
                colCount = localColCount; 
        }
        

        //
        for(let row of rows){

            //reset the start position after each row has been rendered
            penPos.x = x;

            //get the row style 
            let rowStyle = this.getStyleObj(row, styleData); 

            let rowBackgroundColor = this.getStyleProp(row, rowStyle, 'backgroundColor')          
            
            //get the row children (the cells)
            if (!(row.children && Array.isArray(row.children)))
                continue; 
            let cells = []; 
            for(let i = 0; i < row.children.length; i++){
                let id = row.children[i]; 
                let cell = nodeData[id]; 
                if(cell && cell.type == 'table_cell')
                    cells.push(cell); 
            } 
            
            //get height of the row
            let rowHeight = this.getStyleProp(row, rowStyle, 'height'); 
            rowHeight = this.mm2Px(rowHeight); 

            for(let cell of cells){

                //get the cell style 
                let cellStyle = this.getStyleObj(cell, styleData); 
                
                let cellRowSpan = cell.rowSpan ? cell.rowSpan : 0; 
                let cellPl = this.mm2Px( this.getStyleProp(cell, cellStyle, 'paddingLeft') ); 
                let cellPt = this.mm2Px( this.getStyleProp(cell, cellStyle, 'paddingTop') );
                let cellwidth = boxWidth / colCount;
                
                if(cellRowSpan){
                    cellwidth *= cellRowSpan; 
                }

                let cellBackgroundColor = this.getStyleProp(cell, cellStyle, 'backgroundColor')
                if(!cellStyle.backgroundColor)
                    cellBackgroundColor = rowBackgroundColor; 

                this.renderQueue.push({origNode:cell, origNodeId:'', type:RenderTypeBox, page:pageCount-1, width:cellwidth, height:rowHeight, x:penPos.x, y:penPos.y, fillStyle:cellBackgroundColor});
                
                if(cell.children && Array.isArray(cell.children)){

                    for(let cellChildId of cell.children){
                        let cellChild = nodeData[cellChildId]; 
                        if(cellChild && cellChild.type == 'paragraph'){
                            
                            let pStyle = this.getStyleObj(cellChild, styleData); 
                            let lineSpacing = this.getStyleProp(cellChild, pStyle, 'lineSpacing'); 

                            let textRet = this.buildText(nodeData, styleData, cellChild, penPos.x + cellPl, penPos.y + cellPt, cellwidth, lineSpacing, 
                                                         pageHeight, pageMb, pageMt, false, this.mm2Px(this.pageGap), pageCount, currentPageY, currentDocumentHeight); 
                        }
                    }
                }

                penPos.x += cellwidth;
                
            }

            //after each row move pen down 
            penPos.y += rowHeight;
            if(autoPageBreak){         
                if(penPos.y > currentPageY + pageHeight - pageMb){
                    penPos.y = currentPageY + pageHeight + pageGap + pageMt
                    currentPageY += pageHeight + pageGap; 
                    currentDocumentHeight = currentPageY + pageHeight + pageGap; 
                    pageCount++; 
                }
            }
        }

        //after each block level node we need to move the cursor down by the "after" ammmount, to add gap between paragraph
        penPos.y += this.pt2px(afterSpacing);

        return {pageCount, penPos, currentPageY, currentDocumentHeight}
    }

    buildText(nodeData, styleData, parentNode, x, y, boxWidth, lineSpacing, 
        pageHeight, pageMb, pageMt, 
        autoPageBreak=true, pageGap=this.mm2Px(this.pageGap), 
        currentPageCount=this.pageCount, currentPageY=this.currentPageY, currentDocumentHeight=this.currentDocumentHeight){
        
        let ctx = this.ctx; 
        let pageCount = currentPageCount; 

        let penPos = {x:x, y:y}; 

        //array of all the render objects in line order (array of arrays of objects for multiple objects per line)
        let renderedLines = []; 

        //Lamda function to process each child node in a block node
        let processChildTextNode = (child, childid, lineSpacing)=>{

            //
            if(child.hidden)
                return; 

            //Set the canvas context with correct font settings, this is vital for ctx measurments
            let textStyle = this.getStyleObj(child, styleData); 

            let fontStyle = this.getStyleProp(child, textStyle, 'fontStyle'); 
            let fontWeight = this.getStyleProp(child, textStyle, 'fontWeight'); 
            let fontSize = this.getStyleProp(child, textStyle, 'fontSize'); 
            let fontFace = this.getStyleProp(child, textStyle, 'fontFace'); 
            let fillStyle = this.getStyleProp(child, textStyle, 'color'); 

            let shadowBlur = 0;
            let textBaseline = "alphabetic";
            let textAlign = "left";

            let font = '' + fontStyle + ' ' + fontWeight + ' ' + this.pt2px(fontSize) + 'px ' + fontFace;

            ctx.font = font; 
            ctx.fillStyle = fillStyle; 
            ctx.shadowBlur = shadowBlur;
            ctx.textBaseline = textBaseline;
            ctx.textAlign = textAlign;
            
            //compute the size of a space charecter with the font 
            //let spaceMetrics = ctx.measureText(' '); 
            let spaceMetrics = this.measureText2(ctx, ' '); 
            //let spaceMetrics = this.measureText(fontFace, this.pt2px(fontSize), ' ');
             
            let spaceSize = spaceMetrics.width;
            let lineSpaceObj = this.getLineSpace(fontFace, fontSize, lineSpacing); 
            let lineSpace = lineSpaceObj.lineSpace; 
            let ascender = lineSpaceObj.ascender; 
            let decender = lineSpaceObj.descender; 

            //compute the area that text can be written to 
            let textAreaWidth = boxWidth;// boxWidth - marginLeft - marginRight; 

            //zero the line, and word count (word count is just group of chars seperated by spacing not nec an actual word)
            let line = ''; 
            let lineWordCount = 0; 
            let lineCharCount = 0; 

            //apply modifier changes to text in node
            let text = child && child.data && typeof child.data === 'string' ? child.data : '';
            
            //split the childs text by space (should this be other chars as well??)
            let textSplit = child && child.data && typeof child.data === 'string' ? text.split(' ') : []; 

            let writeRenderQueue = (line, textInxOffset, lineWidth)=>{
                this.renderQueue.push({origNode:child, origNodeId:childid, type: RenderTypeText, page:pageCount-1, text:line, textInxOffset: textInxOffset, 
                    x:penPos.x, y:penPos.y, lineWidth, lineSpace, lineSpacing, ascender, decender,
                    font, fillStyle, shadowBlur, textBaseline, textAlign, fontSize, fontWeight, fontFace});
            }

            //lamda process long word function 
            let processLongWordLine = (line, textInxOffset)=>{
                let c = 0; 
                let subline = ''; 
                let sw = this.measureText2(ctx, subline + line.charAt(c)).width;
                let sx = penPos.x - x;
                let lineWidth = 0; 

                while(sw + sx < textAreaWidth && c < line.length){
                    subline += line.charAt(c); 
                    c++; 
                    lineWidth = sw; 
                    sw = this.measureText2(ctx, subline + line.charAt(c)).width;
                }

                writeRenderQueue(subline, textInxOffset, lineWidth); 

                let extra = line.substring(c); 
                return [extra, subline];
            }

            let lineWidth = 0;

            //for every "word" in the child node build a line and then render it out
            for(let i = 0; i < textSplit.length; i++){

                //get the next word from the childnode, test the length of the line with this extra word added 
                let wordText = textSplit[i]; 
                let fontMetrics = this.measureText2(ctx, line + wordText + ' ');
                let sw = fontMetrics.width; 
                let sx = penPos.x - x;

                //if the line (with the next word) width is less than the writting area 
                if(sw + sx <= textAreaWidth){
                    //write the word to the line with a space on the end (or no space if this is the last word in the text node). 
                    let isLastWord = i == textSplit.length - 1;
                    let str2Write =  wordText + (isLastWord ? '' : ' '); 
                    line += str2Write 
                    lineWordCount++; 
                    //lineCharCount += str2Write.length; //inc char count by wordLength plus 1 for space 
                    lineWidth = sw; 
                }
                //if the line with next word would exceeds the writting area ...
                else{
                    //if there are chars in the line but there is only a single word, and the word alone is longer than the text area split up
                    let lineHasText = line.length > 0 ; 
                    let swLine = this.measureText2(ctx, line).width;
                    lineWidth = swLine;
                    let isLongWord = lineHasText && lineWordCount == 1 && swLine > textAreaWidth;
                    
                    if(isLongWord){
                        //write to the renderquueue the line up to the longest it can be in the area, return the extra unwritten part
                        let args = processLongWordLine(line, lineCharCount); 
                        wordText = args[0]; 
                        let written = args[1]; 
                        let charNumWritten = written.length; 
                        lineCharCount += charNumWritten; 
                        i--; 
    
                        //move pen down a line
                        penPos.y += lineSpace; 
                    }  
                    //if there is text in the line and the line is bigger than one word, write to render queue
                    else if(lineHasText){
                        writeRenderQueue(line, lineCharCount, lineWidth); 
                        let charNumWritten = line.length; 
                        lineCharCount += charNumWritten; 
                        //move pen down a line
                        penPos.y += lineSpace; 
                    } 

                    //zero the line but add the next word 
                    let isLastWord = i == textSplit.length - 1;
                    line = wordText + (isLastWord || isLongWord ? '' : ' '); 
                    lineWordCount = 1; 
                    
                    //move the pen to the next line and the left edge of the text area, make new pae if needed
                    penPos.x = x; 
                    if(autoPageBreak){
                        
                        if(penPos.y > currentPageY + pageHeight - pageMb){
                            penPos.y = currentPageY + pageHeight + pageGap + pageMt
                            currentPageY += pageHeight + pageGap; 
                            currentDocumentHeight = currentPageY + pageHeight + pageGap; 
                            pageCount++; 
                        }
                    }
                }


                //Process last line if there is anything in it.
                
                if(i == textSplit.length - 1 && line.length > 0){

                    //console.log('Final Line')

                    let fontMetrics2 = this.measureText2(ctx, line);
                    let sw = fontMetrics2.width; 
                    lineWidth = sw;

                    if(lineWordCount == 1 && sw > textAreaWidth){

                        //process long line writting to render list
                        let args = processLongWordLine(line, lineCharCount); 
                        wordText = args[0]; 
                        let written = args[1]; 
                        let charNumWritten = written.length; 
                        textSplit[i] = ''; 
                        i--; 
                        
                        lineCharCount += charNumWritten;

                        //zero the line but add the next word 
                        line = wordText; 
                        lineWordCount = 1; 

                        //move pen down a line
                        penPos.y += lineSpace; 

                        //move the pen to the next line and the left edge of the text area, make new pae if needed
                        penPos.x = x; 
                        if(autoPageBreak){
                            
                            if(penPos.y > currentPageY + pageHeight - pageMb){
                                penPos.y = currentPageY + pageHeight + pageGap + pageMt
                                currentPageY += pageHeight + pageGap; 
                                currentDocumentHeight = currentPageY + pageHeight + pageGap; 
                                pageCount++; 
                            }
                        }
                    }
                    else{
                        writeRenderQueue(line, lineCharCount, lineWidth); 
                        penPos.x += fontMetrics2.width ; 
                        lineCharCount += line.length;
                    }
                    
                }
                
            }

            //if there is no text data 2 write, write an empty line (this helps with selection)
            if(textSplit.length == 0){
                writeRenderQueue('', 0, 0); 
            }

        }


        //build child node array from array of node ids (in the parent.children )
        let childNodeData = []; 
        let childNodeIds = []; 
        let parentNodeChildren = parentNode.children && Array.isArray(parentNode.children) ? parentNode.children : []; 
        for(let childid of parentNodeChildren){
            let child = nodeData[childid]; 
            if(child){
                childNodeData.push(child);
                childNodeIds.push(childid);
            } 
            else{
                console.log('broken node:', child); 
            }
        }
        
        //move down by the ascender so the text does not overlap the prev node
        if(childNodeData.length > 0){
            let textStyle = this.getStyleObj(childNodeData[0], styleData)
            let fontSize = this.getStyleProp(childNodeData[0], textStyle, 'fontSize'); 
            let fontFace = this.getStyleProp(childNodeData[0], textStyle, 'fontFace'); 
            let ff = fontFace ? fontFace : 'Inter'; 
            let fs = fontSize ? fontSize : 12; 
            penPos.y += this.getAscender(ff, fs) * lineSpacing; 
        }

        //for every child node
        for(let i = 0; i < childNodeData.length; i++){
            let child = childNodeData[i]; 
            let childid = childNodeIds[i]; 
            processChildTextNode(child, childid,  lineSpacing); 
        }

        //move down by the decender, so the cursor is just under the text
        if(childNodeData.length > 0){
            let textStyle = this.getStyleObj(childNodeData[0], styleData)
            let fontSize = this.getStyleProp(childNodeData[0], textStyle, 'fontSize'); 
            let fontFace = this.getStyleProp(childNodeData[0], textStyle, 'fontFace'); 
            let ff = fontFace ? fontFace : 'Inter'; 
            let fs = fontSize ? fontSize : 12; 
            penPos.y += this.getDecender(ff, fs) * lineSpacing; 
        }

        return {pageCount, penPos, currentPageY, currentDocumentHeight}
    }

    //builds the render queue and document from a list of node data 
    buildFromNodeData(nodeData, styleData){
        //Pre flight checks!
        if(!(nodeData && nodeData.rootNode && nodeData.rootNode.children && Array.isArray(nodeData.rootNode.children)))
            return; 
        if(!styleData){
            return; 
        }

        let ctx = this.ctx;
        this.nodeData = nodeData; 
        this.styleData = styleData; 

        //create the geometry map for the node data, containing each node and its geometry info 
        this.nodeGeometry = new Map(); 

        //grab the style for the rootNode
        let rootNode = nodeData.rootNode; 
        let rootStyleNode = this.getStyleObj(rootNode, styleData)

        //rebuild node parent map 
        this.nodeParentMap = this.buildNodeParentMap(nodeData, 'rootNode'); 

        // the initial page params required  
        let pageWidth = 0; 
        let pageHeight = 0;
        let marginLeft = 0; 
        let marginRight = 0; 
        let marginTop = 0; 
        let marginBottom = 0; 
        const pageGap = this.mm2Px(this.pageGap);

        //update the modifiers selections 
        this.computeModifierChanges(nodeData, styleData, this.modifiers); 

        //zero the render queue
        this.renderQueue = []; 
        this.pageRenderSettings = []; 
        
        //
        this.disabledPageNums = new Set(); 

        //Set the initial pen position to top left of the first page and set pagecount to 0
        let penPos = {x:0, y:0};  
        this.pageCount = 0; 
        this.currentPageY = 0;
        this.currentDocumentHeight = this.currentPageY; 

        //for each node in the input data
        const processNode = (node, nodeid)=>{

            //get the style object 
            let nodeStyle = this.getStyleObj(node, styleData); 
            if(!nodeStyle) nodeStyle = {error:true}; 
            
            //create new geometry object for the node
            let geometry = null;

            //lamda function for processing children of inner nodes
            let processAllChildren = (node)=>{
                let nodegeometry = null;
                if(node && node.children && Array.isArray(node.children)){
                    for(let childid of node.children){
                        let child = nodeData[childid];
                        
                        if(child) {
                            let childGeom = processNode(child, childid); 
                            if(childGeom){
                                if(nodegeometry == null){
                                    nodegeometry = {x: childGeom.x, y: childGeom.y, xMax: childGeom.x+childGeom.w, yMax: childGeom.y+childGeom.h, w: childGeom.w, h: childGeom.h, mt:0, mb:0}
                                }
                                else{
                                    if(childGeom.x < nodegeometry.x)
                                        nodegeometry.x = childGeom.x;
                                    if(childGeom.xMax > nodegeometry.xMax )
                                        nodegeometry.xMax = childGeom.xMax; 
                                    if(childGeom.y < nodegeometry.y)
                                        nodegeometry.y = childGeom.y;
                                    if(childGeom.yMax > nodegeometry.yMax )
                                        nodegeometry.yMax = childGeom.yMax; 
                                    
                                    nodegeometry.w = nodegeometry.xMax - nodegeometry.x;
                                    nodegeometry.h = nodegeometry.yMax - nodegeometry.y;

                                    
                                }
                            }
                            
                        }
                    }
                }

                //if there is no geometry, then set w and h as zero but set the position as the pen position. 
                if(nodegeometry == null){
                    let edgeX = (this.canvasWidth - pageWidth) / 2 + marginLeft;
                    nodegeometry = {x:edgeX, y:penPos.y, xMax:edgeX, yMax:penPos.y, w:0, h:0, mt:0, mb:0}
                }
                 
                return nodegeometry; 
            }
            
            //if its a dcument, recursivly process all nodes in the document
            if(node.type == 'document'){
                geometry = processAllChildren(node); 
            }

            //section, recursivly process
            else if(node.type == 'section'){
                //extract the section style information for the section
                let pageWidthNew = this.mm2Px( this.getStyleProp(node, nodeStyle, 'pageWidth') ); 
                let pageHeightNew = this.mm2Px( this.getStyleProp(node, nodeStyle, 'pageHeight') );
                let marginLeftNew = this.mm2Px(this.getStyleProp(node, nodeStyle, 'marginLeft') ); 
                let marginRightNew = this.mm2Px( this.getStyleProp(node, nodeStyle, 'marginRight') ); 
                let marginTopNew = this.mm2Px( this.getStyleProp(node, nodeStyle, 'marginTop') ); 
                let marginBottomNew = this.mm2Px( this.getStyleProp(node, nodeStyle, 'marginBottom') ); 

                let same = this.aproxEq(pageWidthNew, pageWidth) && this.aproxEq(pageHeightNew, pageHeight) && 
                           this.aproxEq(marginLeftNew, marginLeft) && this.aproxEq(marginRightNew, marginRight) &&  
                           this.aproxEq(marginTopNew, marginTop) && this.aproxEq(marginBottomNew, marginBottom); 

                //Test if page settings are different with previous, if so create new page for section and push page settings  
                if(!same){
                    penPos = {x:(this.canvasWidth - pageWidthNew) / 2 + marginLeftNew,  y: this.currentPageY + pageHeight + pageGap + marginTopNew}
                    this.currentPageY += pageHeight + pageGap; 
                    this.currentDocumentHeight = this.currentPageY + pageHeightNew + pageGap;

                    pageWidth = pageWidthNew; 
                    pageHeight = pageHeightNew; 
                    marginLeft = marginLeftNew; 
                    marginRight = marginRightNew; 
                    marginTop = marginTopNew; 
                    marginBottom = marginBottomNew; 

                    this.pageRenderSettings.push({ pageInx:this.pageCount, width:pageWidth, height:pageHeight, marginLeft, marginRight, marginTop, marginBottom,}); 
                    this.pageCount++; 

                }

                //process children
                geometry = processAllChildren(node); 
            }

            //
            else if(node.type == 'container'){
                geometry = processAllChildren(node); 
            }

            //handle paragraph type node (has text in it etc...)
            else if(node.type == 'paragraph' || node.type == 'heading' || node.type == 'heading1' || node.type == 'heading2'){
                
                //return if there is no valid style for this node! 
                if(nodeStyle.error){
                    return null; 
                }

                //return if there is no page to render onto
                if(pageWidth == 0 || pageHeight == 0)
                    return null; 

                //grap the block level line spacing and reset the latest line height
                let lineSpacing = this.getStyleProp(node, nodeStyle, 'lineSpacing'); 
                let beforeSpacing = this.getStyleProp(node, nodeStyle, 'beforeSpacing'); 
                let afterSpacing = this.getStyleProp(node, nodeStyle, 'afterSpacing'); 

                //before rendering each block level node, reset x position
                penPos.x = (this.canvasWidth - pageWidth) / 2 + marginLeft;

                //apply the before spacnig 
                let prePenY = penPos.y;
                penPos.y += this.pt2px(beforeSpacing);
                 
                //create new page and set pen position to top of new page if needed 
                if(penPos.y > this.currentPageY + pageHeight - marginBottom){
                    penPos.y = this.currentPageY + pageHeight + pageGap + marginTop
                    this.currentPageY += pageHeight + pageGap; 
                    this.currentDocumentHeight = this.currentPageY + pageHeight + pageGap;
                    this.pageCount++; 
                }

                //update the geometry object for the node
                geometry = {x:0, y:0, xMax:0, yMax:0, w:0, h:0, mt:penPos.y - prePenY, mb:0}
                geometry.x = penPos.x; 
                geometry.y = penPos.y; 
                geometry.w = pageWidth-marginLeft-marginRight;  
                geometry.xMax = geometry.x + geometry.w;

                //for every child node
                let ret = this.buildText(
                    nodeData,
                    styleData, 
                    node,
                    penPos.x, 
                    penPos.y, 
                    pageWidth-marginLeft-marginRight, 
                    lineSpacing, pageHeight, marginBottom, marginTop); 
                penPos = ret.penPos; 
                this.pageCount = ret.pageCount; 
                this.currentPageY = ret.currentPageY; 
                this.currentDocumentHeight = ret.currentDocumentHeight
                
                //update geometry for node
                geometry.h = penPos.y - geometry.y;
                geometry.yMax = geometry.y + geometry.h;

                //after each block level node we need to move the cursor down by the "after" ammmount, to add gap between paragraph
                penPos.y += this.pt2px(afterSpacing);

                geometry.mb = afterSpacing; 
                
            }

            //handle page break node, forces a new page to be created
            else if(node.type == 'page_break'){  
                penPos.y = this.currentPageY + pageHeight + pageGap + marginTop
                this.currentPageY += pageHeight + pageGap; 
                this.pageCount++; 
            }

            //setting use page num
            else if(node.type == 'page_num_block'){  
                if(typeof node.page == 'number'){
                    this.disabledPageNums.add(node.page-1);
                }
            }

            //handle drawing a box 
            else if(node.type == 'shape_box'){  
                /*
                let width = this.mm2Px( this.getDim(node.width, this.pageParams.width) ); 
                let height = this.mm2Px( this.getDim(node.height, this.pageParams.height) ); 
                let fillStyle = node.color; 
                let x = (this.canvasWidth - pageWidth) / 2 + this.mm2Px( node.x); 
                let y = pageGap + (this.pageCount - 1) * (pageHeight + pageGap) + this.mm2Px( node.y);
                this.renderQueue.push({origNode:node, origNodeId:nodeid, type: RenderTypeBox, page:this.pageCount-1, width, height, x, y, fillStyle});*/
            }

            //handle drawing a text box 
            else if(node.type == 'text_box'){  
                //return if there is no valid style for this node! 
                if(nodeStyle.error){
                    return null; 
                }

                //return if there is no page to render onto
                if(pageWidth == 0 || pageHeight == 0)
                    return null; 
                
                //build text
                let lineSpacing = this.getStyleProp(node, nodeStyle, 'lineSpacing'); 
                let width = this.mm2Px( this.getStyleProp(node, nodeStyle, 'width', pageWidth) ); 
                let height = this.mm2Px( this.getStyleProp(node, nodeStyle, 'height', pageHeight) ); 
                let x = this.mm2Px( this.getStyleProp(node, nodeStyle, 'x') ); 
                let y = this.mm2Px( this.getStyleProp(node, nodeStyle, 'y') ); 
                let renderx = (this.canvasWidth - pageWidth) / 2 + x; 
                let rendery = pageGap + (this.pageCount - 1) * (pageHeight + pageGap) + y;
                this.buildText(nodeData, styleData, node, renderx, rendery, width, lineSpacing, pageHeight, marginBottom, marginTop, false); 
                
            }

            //image 
            else if(node.type == 'image'){
                /*
                let width = this.mm2Px( this.getDim(node.width, this.pageParams.width) ); 
                let height = this.mm2Px( this.getDim(node.height, this.pageParams.height) ); 
                let x = (this.canvasWidth - pageWidth) / 2 + this.mm2Px( node.x); 
                let y = pageGap + (this.pageCount - 1) * (pageHeight + pageGap) + this.mm2Px( node.y);
                let filter = node.filter; 
                let opacity = node.opacity; 
                this.renderQueue.push({origNode:node, origNodeId:nodeid, type: RenderTypeImage, page:this.pageCount-1, filter, opacity, width, height, x, y, imageSrc:node.src});

                if(!this.images.has(node.src)){
                    this.images.set(node.src, 'loading'); 
                    let img = new Image;
                    img.onload = ()=>{
                        this.images.set(node.src, img)
                    };
                    img.src = node.src;
                }
                */
            }

            //table
            else if(node.type == 'table'){
                //return if there is no valid style for this node! 
                if(nodeStyle.error){
                    return null; 
                }

                //return if there is no page to render onto
                if(pageWidth == 0 || pageHeight == 0)
                    return null; 

                penPos.x = (this.canvasWidth - pageWidth) / 2 + marginLeft;
                
                let ret = this.buildTable(nodeData, styleData, node, penPos.x, penPos.y, pageWidth-marginLeft-marginRight, 
                                          pageWidth, pageHeight, marginLeft, marginRight, marginBottom, marginTop, true ); 
                penPos = ret.penPos; 
                this.pageCount = ret.pageCount; 
                this.currentPageY = ret.currentPageY; 
                this.currentDocumentHeight = ret.currentDocumentHeight
            }

            //list
            else if(node.type == 'list'){
                //return if there is no valid style for this node! 
                if(nodeStyle.error){
                    return null; 
                }

                //return if there is no page to render onto
                if(pageWidth == 0 || pageHeight == 0)
                    return null; 

                penPos.x = (this.canvasWidth - pageWidth) / 2 + marginLeft;
                    
                let ret = this.buildList(nodeData, styleData, node, penPos.x, penPos.y, pageWidth-marginLeft-marginRight, 
                                         pageWidth, pageHeight, marginLeft, marginRight, marginBottom, marginTop, true ); 
                
                penPos = ret.penPos; 
                this.pageCount = ret.pageCount; 
                this.currentPageY = ret.currentPageY; 
                this.currentDocumentHeight = ret.currentDocumentHeight;
            }

            //add to the node geometry map
            this.nodeGeometry.set(nodeid, geometry); 

            return geometry; 
        }

        processNode(rootNode, 'rootNode'); 

        //update the modifiers selections 
        for(let m of this.modifiers){
            this.updateModifierSelectionGeometry(nodeData, styleData, m);
        } 

        //update the text selection geometry
        this.updateTextSelection(this.textSelection);
        
        //call callbacks for selection complete
        for(let callback of this.modifierGeometryUpdateCallbacks){
            callback(this.modifiers); 
        }

        //call callback for node geometry Update
        for(let callback of this.nodesGeometryUpdateCallbacks){
            callback(this.nodeGeometry, this.nodeData); 
        }
    }

    //apply modifiers 
    computeModifierChanges(nodeData, styleData, modifiers){
        //update the modifiers selections 
        /*
        this.modifierChanges = new Map(); 

        for(let m of modifiers){

            if(!m)
                continue; 

            if(m.loading || !m.enabled)
                continue; 
            
            let newText = m.newData;
            
            let inx = 0; 
            for(let n of m.selection.nodes){

                //get the set of changes for the node
                let nodeChangeSet = null; 
                if(this.modifierChanges.has(n.id)){
                    nodeChangeSet = this.modifierChanges.get(n.id); 
                }
                else{
                    nodeChangeSet = new Set(); 
                    this.modifierChanges.set(n.id, nodeChangeSet); 
                }
                
                //first node takes the text!
                if(inx == 0){
                    nodeChangeSet.add({start:n.start, end:n.end, newText:newText}); 
                }
                else{
                    nodeChangeSet.add({start:n.start, end:n.end, newText:''}); 
                }
                inx++; 
            }
            
        } 

        //sort each node change set from last to first, so the edit happens last to fisrt
        for(let [key, changeSet] of this.modifierChanges){
            let array = Array.from(changeSet).sort((a, b)=> {return b.start - a.start } );
            this.modifierChanges.set(key, new Set(array)); 
            
        }
        console.log(this.modifierChanges)
        */
    }

    updateModifierSelectionGeometry(nodeData, styleData, m){

        if(!m || !m.origNodes)
            return; 

        let s = {}
        s.geometry = []; //zero the geometry part (this will get rebuilt)
        s.boundingBox = null; //zero to bounding box too

        //create the nodes array selection for the modifier 
        s.nodes = [];
        if(m.enabled){
            let childid = m.newNode; 
            let node = nodeData[childid]; 
            let text = node.data;
            s.nodes.push({id:childid, start:0, end:text.length}); 
        }
        else{
            for(let childid of m.origNodes){
                let node = nodeData[childid]; 
                let text = node.data;
                s.nodes.push({id:childid, start:0, end:text.length}); 
            } 
        }
        m.selection = s; 

        this.updateSelection(nodeData, styleData, s); 
    }

    updateTextSelection(s){
        this.textSelection = s;
    
        if(!this.textSelection){
            return; 
        }
        this.updateSelection(this.nodeData, this.styleData, s); 
    }

    //recompute geometry for selection
    updateSelection(nodeData, styleData, s){

        this.textSelection.hasSelection = false;
        this.textSelection.hasCaret = false; 

        let ctx = this.ctx; 

        let geometry = []; 

        //Get the selection nodes and start and ends
        let nodeSelectionSet = new Map(); 
        for(let nodeSelection of s.nodes){
            nodeSelectionSet.set(nodeSelection.id, nodeSelection); 
        }
        
        //loop over all render items
        let renderItems = new Set(); 
        for (let renderItem of this.renderQueue){
            //get the node id from the render item
            let nodeId = renderItem.origNodeId; 

            //check to see if the render item node is in the selection. 
            if(!nodeSelectionSet.has(nodeId))
                continue ;

            //Get the node and the style if posible
            let node = nodeData[nodeId]; 
            if(!node )
                continue; 
            let style = this.getStyleObj(node, styleData);
            if(!style)
                continue; 

            //get the start and the end and the string
            let selection = nodeSelectionSet.get(nodeId);
            let selectionStart = selection.start; 
            let selectionEnd = selection.end; 

            //get the offset into the node the line is
            let lineOffset = renderItem.textInxOffset;
            let lineLen = renderItem.text.length; 
            let lineOffsetPlusLen = lineOffset + lineLen; 

            let x = renderItem.x; 
            let y = renderItem.y; 
            let w = renderItem.lineWidth; 
            let h = renderItem.lineSpace;
            let a = renderItem.ascender; 
            let d = -renderItem.decender; 
            let s = renderItem.lineSpacing; 

            //test if line is completly contained by selection 
            
            if(lineOffset >= selectionStart && lineOffset < selectionEnd && lineOffsetPlusLen > selectionStart && lineOffsetPlusLen <= selectionEnd){
                geometry.push({
                    allSelected:false, 
                    minX:x, 
                    minY:y - a*s, 
                    width:w, 
                    height:h,
                    minCharInx:0, 
                    maxCharInx:lineLen, 
                    textNodeInxOffset:renderItem.textInxOffset, 
                });
                renderItems.add(renderItem);
            }
            
            //test if selection is within the line somehow...
            else if(lineOffsetPlusLen >= selectionStart && lineOffset <= selectionEnd ){

                ctx.font = renderItem.font; 
                ctx.fillStyle = renderItem.fillStyle; 
                ctx.shadowBlur = renderItem.shadowBlur;
                ctx.textBaseline = renderItem.textBaseline;
                ctx.textAlign = renderItem.textAlign;

                //loop over each char of string and find thepoint at which the width exceeds the selection x
                let charInx = 0;
                let str = ''; 
                let selectionStartX = 0; 
                let selectionWidth = 0; 
                for (let i = 0; i < renderItem.text.length; i++){

                    let newChar = renderItem.text.charAt(i); 
                    str += newChar;  
                    //let textMetrics = ctx.measureText(str); 
                    let textMetrics = this.measureText2(ctx, str); 
                    //let textMetrics = this.measureText(renderItem.fontFace, this.pt2px(renderItem.fontSize), str);
                    let textWidth = textMetrics.width;
                    
                    //if i plusthe lineOffset is less than the selectionStart index continue but also overwrite start x
                    if((i + lineOffset) < selectionStart){
                        selectionStartX = textWidth; 
                        selectionWidth = textWidth;
                        continue; 
                    }
                    
                    //if i plusthe lineOffset is less than the selectionStart index continue 
                    if(i + lineOffset >= selectionEnd)
                        break; 

                    //get the next 
                    selectionWidth = textWidth; 
                    charInx++; 
                }
                
                geometry.push({
                    allSelected:false, 
                    minX:selectionStartX + x, 
                    minY:y - a*s, 
                    width:selectionWidth - selectionStartX, 
                    height:h,
                    minCharInx:0, 
                    maxCharInx:lineLen, 
                    textNodeInxOffset:renderItem.textInxOffset, 
                });
                renderItems.add(renderItem);
            }
        }

        s.geometry = geometry; 

        //calculate if this selection has actually selected any text
        for(let ge of this.textSelection.geometry){
            //update the hasTextSelection param
            if(ge.width && ge.width > 0.0001){
                this.textSelection.hasSelection = true;
            } 
        }

        //update the min and max positions of the render items (bb)
        for(let renderItem of renderItems){
            let x = renderItem.x; 
            let y = renderItem.y; 
            let w = renderItem.lineWidth; 
            let h = renderItem.lineSpace;
            let a = renderItem.ascender; 
            let d = -renderItem.decender; 
            let sp = renderItem.lineSpacing; 

            let minx = x;
            let maxx = x+w;
            let miny = y-a*sp;
            let maxy = y+d*sp;

            if(s.boundingBox == null){
                s.boundingBox = {minx, maxx, miny, maxy,};
            }
            else{
                let bb = s.boundingBox; 
                bb.minx = minx < bb.minx ? minx : bb.minx;
                bb.maxx = maxx > bb.maxx ? maxx : bb.maxx;
                bb.miny = miny < bb.miny ? miny : bb.miny;
                bb.maxy = maxy > bb.maxy ? maxy : bb.maxy;
            }
        }

        if(!this.textSelection.hasSelection && this.textSelection.nodes.length > 0)
            this.textSelection.hasCaret = true; 

    }

    //Block fill the backgroud of the canvas to default color
    drawBackgroundColor(){
        let ctx = this.ctx; 

        let prevFillStyle = ctx.fillStyle; 
        ctx.beginPath();
        ctx.rect(0, 0, this.canvasWidth, this.canvasHeight);
        ctx.fillStyle = "#888888";//"#888888";// "#f3f4f9";//"#F5F6F8";
        ctx.fill();
        ctx.fillStyle = prevFillStyle; 
    }

    drawA4Page(pageNum, renderSettings){

        const pageWidth = renderSettings.width; 
        const pageHeight = renderSettings.height;
        const pageGap = this.mm2Px(this.pageGap);

        let ctx = this.ctx; 

        let y = pageNum * (pageHeight + pageGap) + pageGap;
        let x = (this.canvasWidth - pageWidth)/2; 

        let prevShadowColor = ctx.shadowColor;
        let prevShadowBlur = ctx.shadowBlur; 
        let prevFillStyle = ctx.fillStyle; 

        ctx.beginPath();
        ctx.shadowColor = '#777777';//'#cccccc'; 
        ctx.shadowBlur = 8;
        ctx.rect(x, y, pageWidth, pageHeight);
        ctx.fillStyle = "#FFFFFF";
        ctx.fill();

        //draw the page numbers 
        if (this.usePageNums && !this.disabledPageNums.has(pageNum)){
            this.ctx.font = '' + 
                `${this.pageNumsFontStyle} ` + 
                `${this.pageNumsFontWeight} ` + 
                `${this.pt2px(this.pageNumsFontSize)}px ` + 
                this.pageNumsFontFace; 
            this.ctx.fillStyle = this.pageNumsFontColor; 
            
            this.ctx.shadowBlur = 0; 
            //this.ctx.textBaseline = renderItem.textBaseline; 
            //this.ctx.textAlign = renderItem.textAlign; 
            this.ctx.fillText(''+pageNum, x + pageWidth/2-10, y + pageHeight-30); 
            this.displayPageNum++; 
        }

        ctx.shadowColor = prevShadowColor;
        ctx.shadowBlur = prevShadowBlur; 
        ctx.fillStyle = prevFillStyle;
    }

    drawSelection(s, color='#B8D4FC', borderOnly=false, opacity='ff'){
        if(s && s.geometry){
            for(let selection of s.geometry){
                this.ctx.fillStyle = color + opacity; 
                this.ctx.strokeStyle = color ; 
                this.ctx.shadowBlur = 0; 
                let rx = selection.minX; 
                let ry = selection.minY; 
                let rw = selection.width; 
                let rh = selection.height; 
                if(borderOnly){
                    this.ctx.fillRect(rx, ry, rw, rh); 
                    this.ctx.strokeRect(rx, ry, rw, rh)
                }
                else{
                    this.ctx.fillRect(rx, ry, rw, rh); 
                }
            }
        }
    }

    drawCaret(s){
        if(s && s.hasCaret && this.caretTick){
            if(s.geometry && s.geometry.length > 0){
                let geom = s.geometry[0]; 
                this.ctx.fillStyle = this.caretColor; 
                this.ctx.shadowBlur = 0; 
                let rx = geom.minX; 
                let ry = geom.minY; 
                let rw = this.mm2Px(0.4); 
                let rh = geom.height; 
                this.ctx.fillRect(rx, ry, rw, rh);
            }
        }
    }

    //Render actually draws the document at a scroll position, 
    draw(){

        this.displayPageNum = 0;

        const pageGap = this.mm2Px(this.pageGap);

        this.drawBackgroundColor(); 
        this.ctx.save(); 
        
        this.ctx.translate(0, -this.scrollValue); 
        let pageRenderSettingsInx = 0;
        for(let p = 0; p < this.pageCount; p++){
            //itterate page settings object until the current  page inx being rendered is no longer below the pageCount var
            while( pageRenderSettingsInx+1 < this.pageRenderSettings.length -1){
                if(p < this.pageRenderSettings[pageRenderSettingsInx+1].pageInx)
                    break; 
                pageRenderSettingsInx++; 
            }
            let currentPageRenderSettings = this.pageRenderSettings[pageRenderSettingsInx]; 
            if(currentPageRenderSettings)
                this.drawA4Page(p, currentPageRenderSettings); 
        }

        //draw text selection 
        this.drawSelection(this.textSelection);
        

        //draw modifiers selections 
        for(let m of this.modifiers){
            this.drawSelection(m.selection, m.color, !m.enabled, !m.enabled?'11':'aa'); 
        } 

        //draw caret
        this.drawCaret(this.textSelection)
        
        //draw every node 
        for(let i = 0; i < this.renderQueue.length; i++){
            let renderItem = this.renderQueue[i];

            if(renderItem.type == RenderTypeText){
                this.ctx.font = renderItem.font; 
                this.ctx.fillStyle = renderItem.fillStyle; 
                this.ctx.shadowBlur = renderItem.shadowBlur; 
                this.ctx.textBaseline = renderItem.textBaseline; 
                this.ctx.textAlign = renderItem.textAlign; 
                this.ctx.fillText(renderItem.text, renderItem.x, renderItem.y); 
            }
            else if(renderItem.type == RenderTypeBox){
                this.ctx.fillStyle = renderItem.fillStyle; 
                this.ctx.shadowBlur = 0; 
                this.ctx.fillRect(renderItem.x, renderItem.y, renderItem.width, renderItem.height); 
            }
            else if(renderItem.type == RenderTypeImage){
                let prevFilter = this.ctx.filter; 
                let prevGlobalAlpha = this.ctx.globalAlpha;
                
                this.ctx.filter = renderItem.filter ? renderItem.filter : 'none'; 
                this.ctx.globalAlpha = renderItem.opacity ? renderItem.opacity : 1.0; 
                this.ctx.shadowBlur = 0; 

                if(this.images.has(renderItem.imageSrc) && this.images.get(renderItem.imageSrc) != 'loading'){
                    this.ctx.drawImage(this.images.get(renderItem.imageSrc), renderItem.x, renderItem.y, renderItem.width, renderItem.height);
                }
                else{
                    this.ctx.fillStyle = '#EEEEEE'; 
                    this.ctx.fillRect(renderItem.x, renderItem.y, renderItem.width, renderItem.height); 
                }

                this.ctx.filter = prevFilter; 
                this.ctx.globalAlpha = prevGlobalAlpha; 
            }
        }
        
        this.ctx.restore();

        return (this.currentDocumentHeight) / this.dpr(); 
    }

    getPerPageParams(){
        let pageRenderSettingsInx = 0;
        let pageParams = [];
        for(let p = 0; p < this.pageCount; p++){
            //itterate page settings object until the current  page inx being rendered is no longer below the pageCount var
            while( pageRenderSettingsInx+1 < this.pageRenderSettings.length -1){
                if(p < this.pageRenderSettings[pageRenderSettingsInx+1].pageInx)
                    break; 
                pageRenderSettingsInx++; 
            }
            let currentPageRenderSettings = this.pageRenderSettings[pageRenderSettingsInx]; 
            if(currentPageRenderSettings){
                pageParams.push(currentPageRenderSettings); 
            } 
        }

        return pageParams; 
    }

    renderToDocx(filename){
        let pageParams = this.getPerPageParams(); 
        if(pageParams.length == 0)
            return;

        const sections = []; 

        

        const doc = new Document({sections:sections, }); 
        /*{
            sections: [
                {
                    properties: {},
                    children: [
                        new Paragraph({
                            children: [
                                new TextRun("Hello World"),
                                new TextRun({
                                    text: "Foo Bar",
                                    bold: true,
                                    size: 40,
                                }),
                                new TextRun({
                                    children: [new Tab(), "Github is the best"],
                                    bold: true,
                                }),
                            ],
                        }),
                    ],
                },
            ],
        });*/
    }

    renderToPdf(fileName){

        let dpr = this.dpr(); 
        let invdpr = 1.0/dpr; 

        let pageParams = this.getPerPageParams(); 
        if(pageParams.length == 0)
            return; 

        let pageWidth = 0; 
        let pageHeight = 0;
        let marginLeft = 0; 
        let marginRight = 0; 
        let marginTop = 0; 
        let marginBottom = 0; 

        const pageGap = this.mm2Px(this.pageGap);

        console.log('pageWidth: ', pageWidth )
        let canvasOffsetX = invdpr * (this.canvasWidth - pageWidth) / 2; 
        let canvasOffsetY = 0 ; 

        let doc = new jsPDF(); 

        doc.addFont('assets/fonts/Inter-Light.ttf', 'Inter', 'normal', 300);
        doc.addFont('assets/fonts/Inter-Regular.ttf', 'Inter', 'normal', 400);
        doc.addFont('assets/fonts/Inter-Medium.ttf', 'Inter', 'normal', 500);
        doc.addFont('assets/fonts/Inter-SemiBold.ttf', 'Inter', 'normal', 600);
        doc.addFont('assets/fonts/Inter-Bold.ttf', 'Inter', 'normal', 700);
        
        let prevPage = -1;
        let pageInx = 0; 
        for(let i = 0; i < this.renderQueue.length; i++){
            let renderItem = this.renderQueue[i];

            if(renderItem.page != prevPage){
                

                canvasOffsetY += (pageHeight + pageGap);//increment by the previous page height 

                //update page sizing
                console.log('creating new page ', renderItem.page, pageInx,  pageParams[pageInx])
                let currentPageParams = pageParams[pageInx];  
                pageWidth = (currentPageParams.width); 
                pageHeight = (currentPageParams.height);
                marginLeft = (currentPageParams.marginLeft); 
                marginRight = (currentPageParams.marginRight); 
                marginTop = (currentPageParams.marginTop); 
                marginBottom = (currentPageParams.marginBottom); 

                //add page to pdf (but there is already one by default ) 
                if(prevPage != -1)
                    doc.addPage(); 

                prevPage = renderItem.page; 

                canvasOffsetX = (this.canvasWidth - pageWidth) / 2; 
                 
                console.log('canvasOffsetX: ', canvasOffsetX )
                console.log('canvasOffsetY: ', canvasOffsetY )
                pageInx++; 
            }
            

            if(renderItem.type == RenderTypeText){
  /*              this.ctx.font = renderItem.font; 
                this.ctx.fillStyle = renderItem.fillStyle; 
                this.ctx.shadowBlur = renderItem.shadowBlur; 
                this.ctx.textBaseline = renderItem.textBaseline; 
                this.ctx.textAlign = renderItem.textAlign; 
                this.ctx.fillText(renderItem.text, renderItem.x, renderItem.y); 
*/
                
                let fontWeight = renderItem.fontWeight ? renderItem.fontWeight : 'normal';

                doc.setFont(renderItem.fontFace, 'normal', fontWeight);

                if(renderItem.fillStyle)
                    doc.setTextColor(renderItem.fillStyle);
                else 
                    doc.setTextColor('#000000');  
                
                
                doc.setFontSize( renderItem.fontSize); 
                let x = this.px2mm(renderItem.x-canvasOffsetX); 
                let y = this.px2mm(renderItem.y-canvasOffsetY); 
                //console.log('text render ', x, y)

                doc.text(renderItem.text, x, y  );

            }
            else if(renderItem.type == RenderTypeBox){
               /* this.ctx.fillStyle = renderItem.fillStyle; 
                this.ctx.shadowBlur = 0; 
                this.ctx.fillRect(renderItem.x, renderItem.y, renderItem.width, renderItem.height); 
                */
               
                let x = this.px2mm(renderItem.x-canvasOffsetX); 
                let y = this.px2mm(renderItem.y-canvasOffsetY); 
                let w = this.px2mm(renderItem.width); 
                let h = this.px2mm(renderItem.height); 
                let style = renderItem.fillStyle; 
                doc.setFillColor(style);
                doc.rect(x, y, w, h, 'F'); 
            }
            else if(renderItem.type == RenderTypeImage){
                /*
                let prevFilter = this.ctx.filter; 
                let prevGlobalAlpha = this.ctx.globalAlpha;
                
                this.ctx.filter = renderItem.filter ? renderItem.filter : 'none'; 
                this.ctx.globalAlpha = renderItem.opacity ? renderItem.opacity : 1.0; 
                this.ctx.shadowBlur = 0; 

                if(this.images.has(renderItem.imageSrc) && this.images.get(renderItem.imageSrc) != 'loading'){
                    this.ctx.drawImage(this.images.get(renderItem.imageSrc), renderItem.x, renderItem.y, renderItem.width, renderItem.height);
                }
                else{
                    this.ctx.fillStyle = '#EEEEEE'; 
                    this.ctx.fillRect(renderItem.x, renderItem.y, renderItem.width, renderItem.height); 
                }

                this.ctx.filter = prevFilter; 
                this.ctx.globalAlpha = prevGlobalAlpha; 
                */
            }
        }

        doc.save(fileName);
    }

    onPointerDown(x, y){

        //logic for double click 
        if(this.multiClickCount == 3)
            this.multiClickCount = 0;
        this.multiClickCount++; 
        if(this.multiClickTimeout)
            clearTimeout(this.multiClickTimeout); 
        this.multiClickTimeout = setTimeout(()=>{ this.multiClickCount = 0; }, 500); 

        //get the latest positions and data about pointer
        this.pointerStartPos = {x:x* this.dpr(), y:y* this.dpr()}; 
        this.pointerLatestPos = {x:x* this.dpr(), y:y* this.dpr()}; 
        this.pointerDown = true; 
        
        
        //handle double click event
        if(this.multiClickCount == 2){
            
            if(this.dragSelectAABB.maxx == this.dragSelectAABB.minx && this.dragSelectAABB.maxy == this.dragSelectAABB.miny){
                this.updateSelectionDoubleClick(); 
            }
        }
        else{

            //update the selection bb 
            this.dragSelectAABB = {
                minx: Math.min(this.pointerStartPos.x, this.pointerLatestPos.x), 
                miny: Math.min(this.pointerStartPos.y, this.pointerLatestPos.y),
                maxx: Math.max(this.pointerStartPos.x, this.pointerLatestPos.x), 
                maxy: Math.max(this.pointerStartPos.y, this.pointerLatestPos.y),
            };

            this.calculatePointerTextSelection(); 
        }

        this.resetCaretFlash(true); 
    }

    onPointerUp(x, y){
        this.pointerLatestPos = {x:x* this.dpr(), y:y* this.dpr()}; 
         
        //console.log('pointer drag select, pointerStartPos:', this.pointerStartPos, ' pointerLatestPos ', this.pointerLatestPos, ' aabb ', this.dragSelectAABB); 
        this.pointerDown = false;

        this.calculatePointerTextSelection(); 

        //call callbacks for selection complete
        for(let callback of this.selectionFinishedCallbacks){
            callback(this.dragSelectAABB, this.textSelection); 
        }
    }

    onPointerMove(x, y){
        this.pointerLatestPos = {x:x* this.dpr(), y:y* this.dpr()}; 

        //Find the selected node on the hover, and find the between nodes cloesest to hover (for add)
        let px = this.pointerLatestPos.x; 
        let py = this.pointerLatestPos.y; 
        let hoverNodes = new Set();  
        let betweenNodes = new Map(); 
        let hoverBetween = {nodeId:'', geometry:null, y:0, x:0};  
        let hoverBetweenDist = this.mm2Px(6);
        
        for(let [nodeid, geometry] of this.nodeGeometry){

            let node = this.nodeData[nodeid];
            if(!node)
                continue; 

            //only certanin nodes get geometry
            if(geometry){
                if(py <= geometry.yMax && py >= geometry.y){
                    hoverNodes.add(nodeid); 
                }

                if(py <= geometry.yMax + hoverBetweenDist && py >= geometry.yMax - hoverBetweenDist ){
                    betweenNodes.set(nodeid, node ); 
                    hoverBetween.nodeId = nodeid; 
                    hoverBetween.y = geometry.yMax; 
                    hoverBetween.x = geometry.x; 
                    hoverBetween.w = geometry.w; 
                }
            }
        }
        hoverBetween.betweenNodes = betweenNodes; 
        
        //build aabb from drag info / pointer info
        this.dragSelectAABB = {
            minx: Math.min(this.pointerStartPos.x, this.pointerLatestPos.x), 
            miny: Math.min(this.pointerStartPos.y, this.pointerLatestPos.y),
            maxx: Math.max(this.pointerStartPos.x, this.pointerLatestPos.x), 
            maxy: Math.max(this.pointerStartPos.y, this.pointerLatestPos.y),
        };

        if(this.pointerDown)
            this.calculatePointerTextSelection(); 

        //call the callbacks for hover nodes
        const isSameSet = (s1, s2) => {
            if(!s1 || !s2 || s1.size !== s2.size)
                return false; 
            return [...s1].every(i => s2.has(i))
        }

        if(!isSameSet(hoverNodes, this.hoverNodes)){
            this.hoverNodes = hoverNodes; 
            for(let callback of this.hoverUpdateCallbacks){
                callback(this.hoverNodes); 
            }
        } 

        //check hover between is diff
        if(hoverBetween.nodeId != this.hoverBetween.nodeId || 
           !isSameSet(hoverBetween.betweenNodes, this.hoverBetween.betweenNodes)
        ){
            //build parent map 
            hoverBetween.parentMap = new Map(); 
            for(let [nodeid, node] of hoverBetween.betweenNodes){
                let parentid = this.nodeParentMap.get(nodeid); 
                if(!parentid)
                    continue; 
                let parent = this.nodeData[parentid];
                if(!parent)
                    continue;  

                let children = parent.children; 
                if(!children || !Array.isArray(children))
                    continue; 
                
                let index = children.indexOf(nodeid); 
                if(index < 0)
                    continue ; 
                
                hoverBetween.parentMap.set(nodeid, {id:parentid, node:parent, childIndex: index}); 
            }

            this.hoverBetween = hoverBetween; 
            for(let callback of this.hoverBetweenUpdateCallbacks){
                callback(this.hoverBetween); 
            }
        }
    }

    onPointerLeave(){
        //this.pointerDown = false; 
        //this.calculatePointerTextSelection(); 
    }

    onPointerEnter(){

    }

    setTextSelection(textSelection){
        if(!textSelection)
            this.textSelection = {hasSelection:false, hasCaret:false, geometry:[], boundingBox:null, nodes:[]}; 
        this.updateTextSelection(textSelection);
        this.resetCaretFlash(true); 
        this.draw(); 
    }

    moveTextSelection(key, ctrl=false, forceMoveForward=false){

        //check there is selection
        if(!this.textSelection.hasSelection && !this.textSelection.hasCaret)
            return; 

        //get the nodes in the selection 
        let nodes = this.textSelection.nodes;
        if(nodes.length == 0){
            return; 
        }
        
        //process left press
        if(key == 'left'){
            //get the first node in the selection, and the start index and decrement it
            let nodeInfo = nodes[0]; 
            nodeInfo.start--; 
            if(nodeInfo.start < 0)
                nodeInfo.start = 0;

            //check if the selection is caret only and if so set the end value to match the start
            if(!this.textSelection.hasSelection){
                nodeInfo.end = nodeInfo.start;
            }
        }
        //process right press
        else if(key == 'right'){
            //get the first node in the selection, and the start index and decrement it
            let nodeInfo = nodes[0]; 
            nodeInfo.end++; 
            
            let node = this.nodeData[nodeInfo.id];
            if(node && node.data && (nodeInfo.end > node.data.length && !forceMoveForward) )
                nodeInfo.end = node.data.length;

            //check if the selection is caret only and if so set the end value to match the start
            if(!this.textSelection.hasSelection){
                nodeInfo.start = nodeInfo.end;
            }
        }
        //process up press
        else if(key == 'up'){
            //get the first node in the selection, and the start index and decrement it
            let nodeInfo = nodes[0]; 
            nodeInfo.end++; 
            
            let node = this.nodeData[nodeInfo.id];
            if(node && node.data && nodeInfo.end > node.data.length)
                nodeInfo.end = node.data.length;

            //check if the selection is caret only and if so set the end value to match the start
            if(!this.textSelection.hasSelection){
                nodeInfo.start = nodeInfo.end;
            }
        }
        //process up press
        else if(key == 'down'){
            //get the first node in the selection, and the start index and decrement it
            let nodeInfo = nodes[0]; 
            nodeInfo.start--; 
            if(nodeInfo.start < 0)
                nodeInfo.start = 0;

            //check if the selection is caret only and if so set the end value to match the start
            if(!this.textSelection.hasSelection){
                nodeInfo.end = nodeInfo.start;
            }
        }
        
        this.updateTextSelection(this.textSelection);
        this.resetCaretFlash(true); 
        this.draw(); 
    }

    calculatePointerTextSelection(){
        
        if(this.pointerDown){

            this.textSelection = {hasSelection: false, hasCaret:false, geometry:[], boundingBox:null, nodes:[] }
            let renderItems = new Set(); 
            let nodeSelections = new Map(); 

            let ctx = this.ctx; 
            
            let minx = this.dragSelectAABB.minx; 
            let miny = this.dragSelectAABB.miny; 
            let maxx = this.dragSelectAABB.maxx; 
            let maxy = this.dragSelectAABB.maxy;  

            let p0x = this.pointerStartPos.x; 
            let p1x = this.pointerLatestPos.x;
            let p0y = this.pointerStartPos.y; 
            let p1y = this.pointerLatestPos.y;

            for(let i = 0; i < this.renderQueue.length; i++){
                let renderItem = this.renderQueue[i];

                let lineSelection = null; 
    
                if(renderItem.type == RenderTypeText){

                    renderItem.selected = false; 

                    let x = renderItem.x; 
                    let y = renderItem.y; 
                    let w = renderItem.lineWidth; 
                    let h = renderItem.lineSpace;
                    let a = renderItem.ascender; 
                    let d = -renderItem.decender; 
                    let s = renderItem.lineSpacing; 

                    ctx.font = renderItem.font; 
                    ctx.fillStyle = renderItem.fillStyle; 
                    ctx.shadowBlur = renderItem.shadowBlur;
                    ctx.textBaseline = renderItem.textBaseline;
                    ctx.textAlign = renderItem.textAlign;
                




                    //test if text bb is contained by selection bounding box vertically, in that case select the whole line 
                    if (miny <= (y-a*s) && maxy > (y+d*s) ){
                        lineSelection = {
                            allSelected:true, 
                            minX:x, 
                            minY:y - a*s, 
                            width:w, 
                            height:h,
                            minCharInx:0, 
                            maxCharInx:renderItem.text.length,
                            textNodeInxOffset:renderItem.textInxOffset, 
                        }; 
                        renderItems.add(renderItem); 
                        this.textSelection.geometry.push(lineSelection); 
                        renderItem.selected = true; 
                    }
                    //min selection is intersecting with the line (in the y axis)
                    else if(miny > (y-a*s) && miny <= (y+d*s)){
                        
                        //test if selection max is on another line further down
                        if(maxy > (y+d*s)){

                            //find the x value of the selection that is nearest the top of the document 
                            let sx = p0x; 
                            if(p0y > p1y)
                                sx = p1x;
                            
                            //loop over each char of string and find thepoint at which the width exceeds the selection x
                            let charInx = 0;
                            let str = ''; 
                            let selectionStartX = x; 
                            let newLineWidth = 0; 
                            for (let i = 0; i < renderItem.text.length; i++){
                                let newChar = renderItem.text.charAt(i)
                                let newCharW = ctx.measureText(newChar).width; 
                                //let newCharW = this.measureText(renderItem.fontFace, this.pt2px(renderItem.fontSize), newChar).width;
                                str += newChar;  
                                let textMetrics = ctx.measureText(str); 
                                //let textMetrics = this.measureText(renderItem.fontFace, this.pt2px(renderItem.fontSize), str);
                                let textWidth = textMetrics.width;
                                if((x + textWidth-newCharW/2 ) >= sx){
                                    break;
                                }
                                newLineWidth = textWidth; 
                                selectionStartX = x + textWidth; 
                                charInx++; 
                            }
                            
                            lineSelection = {
                                allSelected:false, 
                                minX:selectionStartX,  
                                minY:y - a*s, 
                                width:w - newLineWidth, 
                                height:h,
                                minCharInx:charInx, 
                                maxCharInx:renderItem.text.length,
                                textNodeInxOffset:renderItem.textInxOffset, 
                            }; 
                            renderItems.add(renderItem); 
                            this.textSelection.geometry.push(lineSelection); 
                            renderItem.selected = true; 
                        }

                        //test if selection is on the same line
                        if(maxy <= (y+d*s)){
                           
                            //loop over all char up to the min x, then up to max x to find gap
                            let minCharInx = 0;
                            let str = ''; 
                            let lineText = ''; 
                            let selectionStartX = x; 
                            for (let i = 0; i < renderItem.text.length; i++){
                                let newChar = renderItem.text.charAt(i)
                                let newCharW = ctx.measureText(newChar).width; 
                                //let newCharW = this.measureText(renderItem.fontFace, this.pt2px(renderItem.fontSize), newChar).width;
                                str += newChar;  
                                let textMetrics = ctx.measureText(str); 
                                //let textMetrics = this.measureText(renderItem.fontFace, this.pt2px(renderItem.fontSize), str);
                                let textWidth = textMetrics.width;
                                
                                if((x + textWidth-newCharW/2 ) >= minx){
                                    break;
                                }
                                selectionStartX = x + textWidth; 
                                minCharInx++; 
                            }

                            //loop over all char up to the max x, then up to max x to find gap
                            let maxCharInx = minCharInx; 
                            let str2 = str.substring(0, str.length-1 ); 
                            let selectionEndX = selectionStartX;
                            for (let i = maxCharInx; i < renderItem.text.length; i++){
                                let newChar = renderItem.text.charAt(i)
                                let newCharW = ctx.measureText(newChar).width; 
                                //let newCharW = this.measureText(renderItem.fontFace, this.pt2px(renderItem.fontSize), newChar).width;
                                str2 += newChar;  
                                let textMetrics = ctx.measureText(str2); 
                                //let textMetrics = this.measureText(renderItem.fontFace, this.pt2px(renderItem.fontSize), str2);
                                let textWidth = textMetrics.width;
                                if((x + textWidth-newCharW/2 ) > maxx){
                                    break;
                                }
                                selectionEndX = x + textWidth; 
                                maxCharInx++; 
                                lineText += newChar; 
                            }
                            
                            //handle single char selection 
                            if(maxCharInx == minCharInx + 1){
                                
                               
                            }

                            lineSelection = {
                                allSelected:false, 
                                minX:selectionStartX, 
                                minY:y - a*s, 
                                width:selectionEndX - selectionStartX, 
                                height:h,
                                minCharInx, 
                                maxCharInx, 
                                textNodeInxOffset:renderItem.textInxOffset, 
                            }; 
                            renderItems.add(renderItem); 
                            this.textSelection.geometry.push(lineSelection); 
                            renderItem.selected = true; 
                        }
                        
                        
                    }
                    //test if text bb is intersecting selection bb at the max
                    else if(maxy > (y-a*s) && maxy < (y+d*s)){
                       
                        //find the x value of the selection that is nearest the bottom of the document 
                        let sx = p1x; 
                        if(p0y > p1y)
                            sx = p0x;
                        
                        //loop over each char of string and find thepoint at which the width exceeds the selection x
                        let charInx = 0;
                        let str = ''; 
                        let selectionEndX = x; 
                        let newLineWidth = 0; 
                        for (let i = 0; i < renderItem.text.length; i++){
                            let newChar = renderItem.text.charAt(i)
                            let newCharW = ctx.measureText(newChar).width; 
                            //let newCharW = this.measureText(renderItem.fontFace, this.pt2px(renderItem.fontSize), newChar).width;
                            str += newChar;  
                            let textMetrics = ctx.measureText(str); 
                            //let textMetrics = this.measureText(renderItem.fontFace, this.pt2px(renderItem.fontSize), str);
                            let textWidth = textMetrics.width;
                            if((x + textWidth-newCharW/2 ) >= sx){
                                break;
                            }
                            newLineWidth = textWidth; 
                            selectionEndX = x + textWidth; 
                            charInx++; 
                        }
                        
                        lineSelection = {
                            allSelected:false, 
                            minX:x, 
                            minY:y - a*s, 
                            width:newLineWidth, 
                            height:h,
                            minCharInx:0, 
                            maxCharInx:charInx, 
                            textNodeInxOffset:renderItem.textInxOffset, 
                        }; 
                        renderItems.add(renderItem); 
                        this.textSelection.geometry.push(lineSelection); 
                        renderItem.selected = true; 
                    }
                    
                }


                
                

                if(renderItem.selected){
                    
                    //update the node selection (which child nodes are selected and where)
                    let textInxOffset = lineSelection.textNodeInxOffset + lineSelection.minCharInx; 
                    let textInxOffsetEnd = lineSelection.textNodeInxOffset + lineSelection.maxCharInx; 
                    let hasNodeSelection = nodeSelections.has(renderItem.origNode); 
                    let nodeSelection = null; 
                    if(hasNodeSelection){
                        nodeSelection = nodeSelections.get(renderItem.origNode);
                        nodeSelection.textInxOffset = textInxOffset < nodeSelection.textInxOffset ? textInxOffset : nodeSelection.textInxOffset;
                        nodeSelection.textInxOffsetEnd = textInxOffsetEnd > nodeSelection.textInxOffsetEnd ? textInxOffsetEnd : nodeSelection.textInxOffsetEnd; 
                    }
                    else{
                        nodeSelection = {
                            node:renderItem.origNode, 
                            nodeid:renderItem.origNodeId,
                            textInxOffset:textInxOffset,
                            textInxOffsetEnd:textInxOffsetEnd,
                        }
                        nodeSelections.set(renderItem.origNode, nodeSelection);
                    }
                    
                }

            }//end of loop 

            //build the node selection in the text selection
            for(let [_, nodeSelection] of nodeSelections){
                let pid = this.nodeParentMap.get(nodeSelection.nodeid); 
                this.textSelection.nodes.push({id:nodeSelection.nodeid, parentId:pid, start:nodeSelection.textInxOffset, end:nodeSelection.textInxOffsetEnd }); 
            }

            //calculate if this selection has actually selected any text
            for(let ge of this.textSelection.geometry){
                //update the hasTextSelection param
                if(ge.width && ge.width > 0.0001){
                    this.textSelection.hasSelection = true;
                } 
            }

            //update the min and max positions of the render items (bb)
            this.textSelection.boundingBox = null; 
            for(let renderItem of renderItems){
                let x = renderItem.x; 
                let y = renderItem.y; 
                let w = renderItem.lineWidth; 
                let h = renderItem.lineSpace;
                let a = renderItem.ascender; 
                let d = -renderItem.decender; 
                let s = renderItem.lineSpacing; 

                let minx = x;
                let maxx = x+w;
                let miny = y-a*s;
                let maxy = y+d*s;

                if(this.textSelection.boundingBox == null){
                    this.textSelection.boundingBox = {minx, maxx, miny, maxy,};
                }
                else{
                    let bb = this.textSelection.boundingBox; 
                    bb.minx = minx < bb.minx ? minx : bb.minx;
                    bb.maxx = maxx > bb.maxx ? maxx : bb.maxx;
                    bb.miny = miny < bb.miny ? miny : bb.miny;
                    bb.maxy = maxy > bb.maxy ? maxy : bb.maxy;
                }
            }

            if(!this.textSelection.hasSelection && this.textSelection.nodes.length > 0)
                this.textSelection.hasCaret = true; 


            /*
            //Calculate parent map for selection 
            this.textSelectionParentMap = new Map(); 
            const addParentMap = (nodeid)=>{
                let pid = this.nodeParentMap.get(nodeid); 
                if(pid){
                    this.textSelectionParentMap.set(nodeid, pid); 
                    if(!this.textSelectionParentMap.has(pid))
                        addParentMap(pid); 
                }
                
            }
            for(let nodeInfo of this.textSelection.nodes){
                addParentMap(nodeInfo.id)
            }

            console.log(this.textSelectionParentMap); 
            */
            
        }

        this.draw(); 
    }

    updateSelectionDoubleClick(){

        if(this.pointerDown){

            let ctx = this.ctx; 
            
            let minx = this.dragSelectAABB.minx; 
            let miny = this.dragSelectAABB.miny; 
            let maxx = this.dragSelectAABB.maxx; 
            let maxy = this.dragSelectAABB.maxy;  

            let p0x = this.pointerStartPos.x; 
            let p1x = this.pointerLatestPos.x;
            let p0y = this.pointerStartPos.y; 
            let p1y = this.pointerLatestPos.y;

            for(let i = 0; i < this.renderQueue.length; i++){
                let renderItem = this.renderQueue[i];
    
                if(renderItem.type == RenderTypeText){

                    renderItem.selected = false; 

                    let x = renderItem.x; 
                    let y = renderItem.y; 
                    let w = renderItem.lineWidth; 
                    let h = renderItem.lineSpace;
                    let a = renderItem.ascender; 
                    let d = -renderItem.decender; 
                    let s = renderItem.lineSpacing; 

                    ctx.font = renderItem.font; 
                    ctx.fillStyle = renderItem.fillStyle; 
                    ctx.shadowBlur = renderItem.shadowBlur;
                    ctx.textBaseline = renderItem.textBaseline;
                    ctx.textAlign = renderItem.textAlign;
                    
                }
            }
        }
        this.draw(); 
    }

    forceDeselect(){
        
        //deselect all render items
        for(let i = 0; i < this.renderQueue.length; i++){
            let renderItem = this.renderQueue[i];

            if(renderItem.type == RenderTypeText){

                renderItem.selected = false; 
                
            }
        }

        //clear selection
        this.textSelection = {hasSelection: false, hasCaret:false, geometry:[], boundingBox:null, nodes:[]};
 
        this.draw();

        //call callbacks for selection complete
        for(let callback of this.selectionFinishedCallbacks){
            callback(this.dragSelectAABB, this.textSelection); 
        }
    }

    setModifiers(modifiers){
        this.modifiers = modifiers; 
        this.draw(); 
    }

    addSelectionFinishedListner(callback){
        this.selectionFinishedCallbacks.add(callback); 
    }

    removeSelectionFinishedListner(callback){
        this.selectionFinishedCallbacks.delete(callback); 
    }


    addModifierGeometryUpdateListner(callback){
        this.modifierGeometryUpdateCallbacks.add(callback); 
    }

    removeModifierGeometryUpdateListner(callback){
        this.modifierGeometryUpdateCallbacks.delete(callback); 
    }

    addNodesGeometryUpdateListner(callback){
        this.nodesGeometryUpdateCallbacks.add(callback); 
    }

    removeNodesGeometryUpdateListner(callback){
        this.nodesGeometryUpdateCallbacks.delete(callback); 
    }

    addHoverUpdateListner(callback){
        this.hoverUpdateCallbacks.add(callback); 
    }

    removeHoverUpdateListner(callback){
        this.hoverUpdateCallbacks.delete(callback); 
    }

    addHoverBetweenUpdateListner(callback){
        this.hoverBetweenUpdateCallbacks.add(callback); 
    }

    removeHoverBetweenUpdateListner(callback){
        this.hoverBetweenUpdateCallbacks.delete(callback); 
    }
}