import React, {useState, useEffect, useContext } from "react";

import MainContext from "../../MainContext"; 

import { Box, TextField, Typography, Checkbox, Switch} from "@mui/material";

import CopyButton from "./CopyButton";
import CopyLink from "./CopyLink";
import ResponsiveDialog from "../../components/ResponsiveDialog";

const ManageProductsDialog = ({open, onClose, onLoading, collectionId, collectionData, onAlert}) => {

    //States
    

    //Use
	const mainService = useContext(MainContext);



    //Effects
    useEffect(()=>{
        
        return (()=>{
           
        })
    },[])

    const handleEnableProductsPage = (e) => {
        const checked = e.target.checked;
        if(collectionId)
            mainService.productListingService.setProductCollectionEnabled(collectionId,checked);
    }


    return (       
        <ResponsiveDialog open={open} onClose={onClose} maxWidth='lg'>
            <Box sx={{p:'32px'}}>
                <Typography variant="h2" sx={{ mb:'16px'}}>Manage products</Typography>
                

                <Typography variant="h4" gutterBottom>Enable products page</Typography>
                <Typography variant='body2'>When this is un-toggled, Educators will not have access to your products page</Typography>
                <Switch
                    disabled={!collectionId}
                    sx={{mb:'16px'}}
                    checked={collectionData && collectionData.enabled}
                    onChange={handleEnableProductsPage}
                    inputProps={{ 'aria-label': 'enableproducts' }}
                />

                {/*<Typography variant="h4" gutterBottom>Automatically add products to products page</Typography>
                <Typography variant='body2'>When this is toggled your products will automatically be added to your page when published</Typography>
                <Switch
                    sx={{mb:'16px'}}
                    checked={true}
                    onChange={()=>{}}
                    inputProps={{ 'aria-label': 'enableproducts' }}
                />*/}

                <Typography variant="h4" gutterBottom>Link to products page</Typography>
                <Typography variant='body2'>This is the link to your products page to share with Educators</Typography>
                <CopyLink sx={{mb:'16px'}} link={`https://edu.intellectible.com/products?collection=${collectionId}`} onCopy={()=>onAlert('Link copied to clipboard successfully','success')}/>


            </Box>
        </ResponsiveDialog>
    )
}

export default ManageProductsDialog;