
import React, {useState, useEffect, useContext, useRef } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";

import Theme from '../../Theme';
import { ThemeProvider } from  '@mui/system';

import MainContext from "../../MainContext"; 

import { Box, ButtonBase, Typography } from "@mui/material";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import UpgradeIcon from '@mui/icons-material/Upgrade';

import LoadingBackdrop from '../../components/LoadingBackdrop';
import StyledButton from "../../components/StyledButton";
import Paper from "../../components/Paper";


const ApplicationMenu = ({open, onAppSelect, onCreateAppClick, onOpenUpgrade}) => {

    const appMenuTransitionTime = 0.4; 
    const MENU_CLOSED = 0; 
    const MENU_OPENING = 1;
    const MENU_OPEN = 2; 
    const MENU_CLOSING = 3; 

    //Use
	const mainService = useContext(MainContext);
	const navigate = useNavigate(); 

    //STATES
    const [creatingNewApp, setCreatingNewApp] = useState(false); 
    const [newAppName, setNewAppName] = useState(""); 

    const [appListData, setAppListData] = useState([]); 
    
    const [appMenuVisible, setAppMenuVisible] = useState(MENU_OPEN); 

    //FUNCTIONS
    const canCreateNewApp = ()=>{
        return !creatingNewApp && newAppName.length != 0; 
    }

    const openAppMenu = ()=>{
        setAppMenuVisible(MENU_OPENING); 
        setTimeout(()=>setAppMenuVisible(MENU_OPEN), 1000*appMenuTransitionTime)
    }

    const closeAppMenu = ()=>{
        setAppMenuVisible(MENU_CLOSING); 
        setTimeout(()=>setAppMenuVisible(MENU_CLOSED), 1000*appMenuTransitionTime)
    }


    //HANDLERS
    const handleAppClick = (appId)=>{
        console.log("handleAppClick"); 
        if(onAppSelect)
            onAppSelect(appId); 
    }

    //LISTNERS
    const applicationListListner = (appListData)=>{
        setAppListData(appListData); 
    }


    //Effects
	useEffect(()=>{
		//on mount
        console.log('App Menu mounted'); 

		mainService.applicationService.addListListener(applicationListListner);

		//on unmount
		return ()=>{
			console.log('App Menu unmounted');

            mainService.applicationService.removeListListener(applicationListListner);
		}

	}, []);

    
    useEffect(()=>{
        if(!open)
            closeAppMenu(); 
        else
            openAppMenu(); 
    }, [open]);
    //backgroundColor:'#f3f4f9'
    return (
    <>
        <Box sx={{
            pointerEvents: 'none',
            //backdropFilter:(appMenuVisible == MENU_CLOSED)?'default':'blur('+( (appMenuVisible == MENU_OPEN || appMenuVisible == MENU_OPENING)?'16px':'0')+') opacity(1)', 
            backdropFilter:(appMenuVisible == MENU_CLOSED)?'default':'opacity('+( (appMenuVisible == MENU_OPEN || appMenuVisible == MENU_OPENING)?'1':'0')+')', 
            position:'absolute', width:'100%', height:'100%', zIndex:2, 
            transition: 'backdrop-filter '+appMenuTransitionTime+'s',
            transitionTimingFunction:'ease-in',  
        }}/>
        
        <Box sx={{position:'absolute', width:'100%', height:'100%', display:'flex', justifyContent:'center', zIndex:2,
                    transform: 'scaleY('+( (appMenuVisible == MENU_OPEN || appMenuVisible == MENU_OPENING)?'100%':'0%')+')',
                    transformOrigin:'bottom',
                    transition: 'transform '+appMenuTransitionTime+'s',}}
        >
            <Paper sx={{flexGrow:1, maxWidth:'1200px', display:'flex', flexDirection:'column', mx:'32px', my:'16px'}}>
                <LoadingBackdrop open={creatingNewApp}/>
                
                <Box sx={{height:'70px', display:'flex', alignItems:'center', px:'24px', /*borderBottom:`1px solid ${Theme.palette.grey[300]}`*/}}>
                    <Typography variant='h4'>My Applications</Typography> 
                </Box>
                
                <Box sx={{height:'80px', display:'flex', alignItems:'center', px:'24px', }}>                    
                    <StyledButton text='Create new application' icon={()=><AutoFixHighIcon sx={{ml:'8px', color:'#ffffff'}} color='action'/>} onClick={onCreateAppClick}></StyledButton>                   
                </Box>
                
                <Box sx={{height:'30px', display:'flex', alignItems:'center', px:'24px', borderBottom:`1px solid ${Theme.palette.grey[300]}`}}>
                    <Typography variant='subtitle2' sx={{flexGrow:1}}>Name</Typography> 
                    <Typography variant='subtitle2'>Created Date</Typography>
                </Box>


                {appListData.length > 0 &&
                <Box sx={{flexGrow:1, position:'relative', overflow:'hidden'}}>
                    <Box sx={{position:'absolute', width:'100%', height:'100%', overflow:'auto', display:'flex', flexDirection:'column'}}>

                        {appListData.map((app)=>(
                        <ButtonBase key={app.id} sx={{position:'relative', minHeight:'50px',}} onClick={()=>handleAppClick(app.id)}>
                            <Box sx={{position:'absolute', width:'100%', height:'100%', display:'flex', alignItems:'center', px:'24px', borderBottom:`1px solid ${Theme.palette.grey[300]}`, 
                                    backgroundColor: '#FFFFFF', '&:hover': {backgroundColor: '#f3f4f9'},}}>
                                <Typography align='left' variant='body2' sx={{flexGrow:1}}>{app.name}</Typography> 
                                <Typography variant='body2'>{mainService.getDateString(app.createdDate)}</Typography>
                            </Box>
                        </ButtonBase>
                        ))}
                        
                    </Box>
                </Box>
                }  
            </Paper>
        </Box>
    </>
    )
}


export default ApplicationMenu; 