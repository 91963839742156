let FirebaseConfig; 
let API_URL;

//The env variable REACT_APP_IS_TEST is set at build time in npm run build, local and test server versions of Intellectible should have different config
if(process.env.REACT_APP_IS_TEST === 'true'){
    
    console.log('---TEST APP---'); 
    
    FirebaseConfig = {
        apiKey: "AIzaSyA90RJ-tJee-54ffDEqrgxG_izuiVDISbE",
        authDomain: "intellectibletest.firebaseapp.com",
        databaseURL: "https://intellectibletest-default-rtdb.firebaseio.com",
        projectId: "intellectibletest",
        storageBucket: "intellectibletest.appspot.com",
        messagingSenderId: "954933133763",
        appId: "1:954933133763:web:4ebfce9be0a27fe6c57d76",
        measurementId: "G-02Y06X6DWG"
    };

    API_URL = process.env.NODE_ENV == 'development' ? '/api' : 'https://testapp.intellectible.com/api';
}
else {
    
    FirebaseConfig = {
        apiKey: "AIzaSyB6a7yNo3JALxG5E-gSF5sPEi7fIJ91Fks",
        authDomain: "app.intellectible.com",
        databaseURL: "https://intellectibleapp-default-rtdb.firebaseio.com",
        projectId: "intellectibleapp",
        storageBucket: "intellectibleapp.appspot.com",
        messagingSenderId: "236515987298",
        appId: "1:236515987298:web:d2aab7ab410fcb2494663b",
        measurementId: "G-4JSHYHFRPL",
    };

    API_URL = process.env.NODE_ENV == 'development' ? '/api' : 'https://app.intellectible.com/api'//'https://api.intellectible.com'
}

export {FirebaseConfig, API_URL}