import {useState, useEffect, useContext } from "react";
import MainContext from "../../MainContext"; 

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from "../../components/Paper";
import Checkbox from '@mui/material/Checkbox';
import Skeleton from "@mui/material/Skeleton";
import Link from "@mui/material/Link";

import theme from "../../Theme";
import { CircularProgress} from "@mui/material";

import CustomPagination from "../components/CustomPagination";
import EnhancedTableHead from "../components/EnhancedTableHead";
import EnhancedTableToolbar from "../components/EnhancedTableToolbar";

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Id',
  },
  {
    id: 'SchoolName',
    numeric: false,
    disablePadding: false,
    label: 'School name',
  },
  {
    id: 'District',
    numeric: false,
    disablePadding: false,
    label: 'District',
  },
  {
    id: 'State',
    numeric: false,
    disablePadding: false,
    label: 'State',
  },
  {
    id: 'Students',
    numeric: true,
    disablePadding: false,
    label: 'Students',
  },
];

export default function SchoolsTable() {
    
    //Use
    const mainService = useContext(MainContext);


    const [loading,setLoading] = useState(false);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('SchoolName');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows,setRows] = useState([]);


    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
        const newSelected = rows.map((n) => n.id);
        setSelected(newSelected);
        return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
        );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event,direction) => {
        let newPage = 0;
        if(direction==="forward"){
          newPage = page
          newPage++;
        }
          
        else if(direction==="backward" && page > 0){
          newPage = page
          newPage--;
        }

        setLoading(true);
        try{
          mainService.intellectibaseService.listenQueryData("schools",orderBy,rowsPerPage,newPage,direction,listenDocData);
          /*setRows(rows);
          setPage(newPage);
          setLoading(false);*/
        }
        catch(error){
          console.error(error)
          setLoading(false);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        const value = parseInt(event.target.value, 10);
        setLoading(true);
        try{
          mainService.intellectibaseService.listenQueryData("schools",orderBy,value,0,"forward",listenDocData);
          /*setRows(rows);
          setPage(0);*/
          setRowsPerPage(value);
          /*setLoading(false);*/
        }
        catch(error){
          console.error(error)
          setLoading(false);
        }       
        
    };

    const handleChangeOrderby = (event,value) => {
      setLoading(true);
      try{
        mainService.intellectibaseService.listenQueryData("schools",value,rowsPerPage,0,"forward",listenDocData);
        /*setRows(rows);
        setPage(0);*/
        setOrderBy(value);
        //setLoading(false);
      }
      catch(error){
        console.error(error)
        setLoading(false);
      }       
      
    };

  //Listeners
  const listenDocData = (docs,p) => {
    if(docs){
      docs.forEach((doc) => {
        console.log(doc.data().SchoolName);
      });
      setRows(docs);
      setPage(p);
      setLoading(false);
    }
  }

  //Effects
  useEffect(() => {
    //on mount
    console.log('Schools table mounted'); 
        setLoading(true);
        mainService.intellectibaseService.clearLastVisibles();
        mainService.intellectibaseService.listenQueryData("schools",orderBy,rowsPerPage,0,"forward",listenDocData)

    //on unmount
    return ()=>{
      console.log('Schools table unmounted');
      mainService.intellectibaseService.clearLastVisibles();
      mainService.intellectibaseService.unsubscribeQueryListener();
    }

  }, []);


    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - rows.length;

    return (

        <Box sx={{ width: '100%'}}>       
         {loading && !rows ? 
         (<Paper sx={{ width: '100%', mb: 2}}>
          <EnhancedTableToolbar  title={"Schools"} numSelected={selected.length} />
            <Box sx={{p:theme.spacing(2),left:"50%",top:0,width:"100%",display:"flex",justifyContent:"center"}}>
              <CircularProgress/>
            </Box>
          </Paper>):

          (<Paper sx={{ width: '100%', mb: 2,overflow: 'hidden'}}>
            <EnhancedTableToolbar title={"Schools"} numSelected={selected.length} />

            
            <TableContainer sx={{maxHeight:"600px"}}>
            <Table
                stickyHeader 
                sx={{ minWidth: 750}}
                aria-labelledby="tableTitle"
                size='small'
            >
              <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleChangeOrderby}
                  rowCount={rows.length}   
                  headCells={headCells} 
              />
                
                <TableBody >                                              
                {
                    rows
                    .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                        <TableRow
                        hover
                        
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        >
                        <TableCell padding="checkbox">
                            <Checkbox
                            onClick={(event) => handleClick(event, row.id)}
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                                'aria-labelledby': labelId,
                            }}
                            />
                        </TableCell>
                        <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                        >
                            {loading ? <Skeleton width={210} /> : row.id}
                        </TableCell>
                        <TableCell align="left">
                          {loading ? <Skeleton width={210} /> : (<Link color="#324ab2" href="#" onClick={()=>console.log("clickin")}>{row.data()["SchoolName"]}</Link>)}
                        </TableCell>
                        <TableCell align="left">{loading ? <Skeleton width={210}/> : row.data()["District"]}</TableCell>
                        <TableCell  align="left">{loading ? <Skeleton width={210}/> : row.data()["State"]}</TableCell>
                        <TableCell align="right">{loading ? <Skeleton width={210}/> : row.data()["Students"]}</TableCell>
                        </TableRow>
                    );
                    })                   
                    }
                  {emptyRows > 0 && (
                      <TableRow
                      style={{
                          height: (33) * emptyRows,
                      }}
                      >
                      <TableCell colSpan={6} />
                      </TableRow>
                  )}
                </TableBody>
            </Table>
            </TableContainer>
            <CustomPagination
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsLength={rows.length}
            />
        </Paper>)}
        </Box>

    );
}