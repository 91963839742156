import React, {useState, useEffect, useContext } from "react";

import MainContext from "../../MainContext"; 

import { Box, Typography, Button, DialogTitle, DialogContent, DialogActions} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DoneIcon from '@mui/icons-material/Done';
import ProfileAvatar from "../../components/ProfileAvatar";
import ResponsiveDialog from '../../components/ResponsiveDialog';
import FileDragAndDrop from '../../components/FileDragAndDrop'
import StyledButton from "../../components/StyledButton";

import TextFormInput from "../../components/formInputs/TextFormInput";


const ProfileInput = ({formData})=>{
    const mainService = useContext(MainContext);
    const [uploadDialogOpen,setUploadDialogOpen] = useState(false);
    const [imgFile,setImgFile] = useState(null);

    const userObject = formData.userObject;

    const handleUploadDialogClose = () => {
        setUploadDialogOpen(false);
    }

    const handleUploadImage = () => {
        setUploadDialogOpen(false);

        if(imgFile){
            mainService.profileService.uploadCustomProfilePic(userObject,imgFile);              
        }

    }

    return(
    <React.Fragment>
        <Box sx={{ height:'100%', flexGrow:1, display:'flex', alignItems:'stretch', flexDirection:'column',maxWidth:'600px'}}>

            <Box sx={{height:'10%'}}></Box>
            <Typography variant='h3' align='left' gutterBottom >Now, personalize your profile!</Typography>
            <Typography variant='body2' align='left' sx={{mb:'32px'}}>Add profile picture and a short bio describing what you do</Typography>
            <Box sx={{my:'16px',flexGrow:1, display:'flex',flexDirection:'column',alignItems:'stretch',gap:'8px'}}>
                <Box sx={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                    <ProfileAvatar  sx={{ width: 150, height: 150 }} photoURL={userObject?.photoURL ?? null} name={userObject?.firstname ?? null}/>
                    <StyledButton 
                        sx={{mt:'8px',mb:'16px'}}  
                        text='Upload image' 
                        icon={()=><CloudUploadIcon sx={{ml:'8px', color:'#ffffff'}}/>} 
                        onClick={()=>setUploadDialogOpen(true)}
                    />
                </Box>
                <TextFormInput 
                    value={userObject?.userbio ?? ''} 
                    onChange={(v)=>mainService.profileService.setUserBio(v)}
                    title={'Add a short bio describing what you do'} 
                    rows={3} 
                    multiline={true}
                />
            </Box>
        </Box>

        <ResponsiveDialog onClose={handleUploadDialogClose} open={uploadDialogOpen} >
            <DialogTitle>Upload an image</DialogTitle>
            <DialogContent>
                <Box sx={{width: "450px"}}>
                    <FileDragAndDrop
                        sx={{width:'100%', height:'200px'}}
                        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                        maxFileSize={3000000}
                        filesLimit={1}
                        dropzoneText='drop profile pic here, or click'
                        onChange={(imgFiles)=>setImgFile(imgFiles[0])}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button  onClick={()=>(setUploadDialogOpen(false))} variant="outlined">
                    Cancel
                </Button>
                <Button 
                    variant="contained" 
                    sx={{color:"white"}}
                    onClick = {handleUploadImage}
                >
                    Upload
                </Button>
            </DialogActions>
        </ResponsiveDialog>
    </React.Fragment>
    )
}

export default ProfileInput; 