import React, {useState, useEffect, useContext } from "react";

import Theme from '../Theme';

import { Box, Typography, ButtonBase} from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import YesNoDialog from "./YesNoDialog";


const ProgressIndicator = ({steps,stage,onSetStage,readonly}) => {
    const [ynDialogOpen,setYnDialogOpen] = useState(false);
    const [stageToUpdate,setStageToUpdate] = useState(null);

    const handleYnClose = () => {
        setYnDialogOpen(false)
    }

    const handleOpenYnDialog = (s) => {
        setStageToUpdate(s)
        setYnDialogOpen(true)
    }

    const handleSetStage = () => {
        if(stageToUpdate)
            onSetStage(stageToUpdate);
        setYnDialogOpen(false)
    }

    return (
        <React.Fragment>
        <Box sx={{width:'100%',display:'flex',justifyContent:'center',mb:'32px'}}>
            {steps && steps.map((step,i)=>(
                <React.Fragment>
                    <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'space-between',gap:'4px'}}>
                        <ButtonBase 
                            disabled={readonly}
                            sx={{bgcolor: i<= stage ? Theme.palette.primary.main : 'white',
                            p:'8px',
                            border: i > stage && `1px solid ${Theme.palette.primary.main}`,
                            borderRadius:'50px',
                            width:'48px',
                            height:'48px',
                            '&:hover':
                                {bgcolor:i<= stage ? Theme.palette.primary.dark : 'white',
                                border: i > stage && `1px solid ${Theme.palette.primary.dark}`,
                            }
                        }}
                            onClick={()=>handleOpenYnDialog(i)}
                        >
                            {i<= stage ? 
                            (<DoneIcon sx={{color:'white'}}/>) :
                            (<Typography align="center" sx={{color:i<= stage ? 'white':Theme.palette.primary.main}}>{i+1}</Typography>)}
                        </ButtonBase>
                        <Typography align="center">{step}</Typography>
                    </Box>
                    {i < steps.length - 1 && 
                    <Box sx={{height:'48px',width:'100px'}}>
                        <Box sx={{height:'50%',borderBottom:`1px solid ${Theme.palette.primary.main}`}} />
                    </Box>}

                </React.Fragment>
            ))}
        </Box>
        <YesNoDialog text="Are you sure you're ready to move to the next stage?" onYes={handleSetStage} onNo={handleYnClose} open={ynDialogOpen}/>
        </React.Fragment>
    )
}




export default ProgressIndicator; 