import React, {useState, useEffect, useContext } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";

import MainContext from "../MainContext"; 
import Theme from "../Theme";
import fadein from "../AnimationKeyframes/FadeIn";

import { Box, Button, Typography, ButtonBase } from "@mui/material";

import ResponsiveDialog from "../components/ResponsiveDialog";
import CondensedApplication from "./ppDashboard/CondensedApplication"
import BudgetPreview from "./dashboard/BudgetPreview";

import MessageIcon from '@mui/icons-material/Message';
import PublicIcon from '@mui/icons-material/Public';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddBoxIcon from '@mui/icons-material/AddBox'

import ProgressIndicator from "../components/ProgressIndicator";
import StyledButton from "../components/StyledButton";

import Paper from "../components/Paper";
import LoadingFull from "../components/LoadingFull";

import { getNextDeadline, daysBetweenTwoDates, getFormattedNumberString } from "../Util";


const Dashboard = ({userObject,onOpenMessaging,onOpenUpgrade}) => {
    
    const mainService = useContext(MainContext);

    const [mainAnim, setMainAnim] = useState(`${fadein} 0.5s linear 0s 1`); 
    const [initialLoading,setInitialLoading] = useState(true);
    const [mainOpacity, setMainOpacity] = useState('1');
    const [latestAppId,setLatestAppId] = useState(null);
    const [applicationMetadata,setApplicationMetadata] = useState(null);
    const [fundingData,setFundingData] = useState(null);
    const [budgetSummary,setBudgetSummary] = useState(null);
    const [budgetSections,setBudgetSections] = useState(null);
    const [budgetLines,setBudgetLines] = useState(null);
    const [appProductData,setAppProductData] = useState(null);
    const [appSections,setAppSections] = useState(null);
    const [projectSummary,setProjectSummary] = useState(null);
    const [dlgOpen, setDlgOpen] = useState(false); 
    const [dlgMode, setDlgMode] = useState('application');

    const steps = ['not started','in progress','submitted','answer received','funds received'];

    const listenMostRecentAppChanged = (v) => {
        if(v){
            setLatestAppId(Object.keys(v)[Object.keys(v).length-1])
        }
    }

    const listenApplicationMetadata = (v) => {
        if(v)
            setApplicationMetadata(v);
        
    }

    const listenAppSections = (v) => {
        if(v)
            setAppSections(v);
    }

    const listenAppContent = (v) => {
        if(v){
            let projectSummaryNode = mainService.applicationService.queryFirstNode(v, {tags:['projectSummary']})
            let contentNode = mainService.applicationService.queryFirstNode(v, {types:['container']}, projectSummaryNode.key)
            let projectSummaryText = mainService.applicationService.queryText(v, contentNode.key); 
            setProjectSummary(projectSummaryText);
        }
    }

    const listenBudgetSummary = (v) => {
        if(v)
            setBudgetSummary(v);
        
    }

    const listenBudgetSections = (v) => {
        if(v)
            setBudgetSections(v);
    }

    const listenBudgetLines = (v) => {
        if(v)
            setBudgetLines(v);
    }

    const listenAppProduct = (v) => {

        if(v)
            setAppProductData(v);
    }

    useEffect(()=>{
        console.log('Dashboard mounted');
        //get and set latest appId
        mainService.applicationService.addMostRecentAppListener(listenMostRecentAppChanged);
        

        return ()=> {
            mainService.applicationService.removeMostRecentAppListener(listenMostRecentAppChanged);
        }
        
    }, []);


    useEffect(()=>{

        setTimeout(()=>{setInitialLoading(false)}, 800);

        //add all the required listeners
        if(latestAppId){
            mainService.applicationService.addApplicationMetadataListner(latestAppId,listenApplicationMetadata);
            mainService.applicationService.addBudgetSummaryListener(latestAppId,listenBudgetSummary);
            mainService.applicationService.addBudgetLinesListener(latestAppId,listenBudgetLines);
            mainService.applicationService.addBudgetSectionsListener(latestAppId,listenBudgetSections);
            mainService.applicationService.addApplicationAllContentListener(latestAppId,listenAppContent);
        }

        return ()=>{
            //remove all listeners
            if(latestAppId){
                mainService.applicationService.removeApplicationMetadataListner(latestAppId,listenApplicationMetadata);
                mainService.applicationService.removeBudgetSummaryListener(latestAppId,listenBudgetSummary);
                mainService.applicationService.removeBudgetLinesListener(latestAppId,listenBudgetLines);
                mainService.applicationService.removeBudgetSectionsListener(latestAppId,listenBudgetSections);
                mainService.applicationService.removeApplicationAllContentListener(latestAppId,listenAppContent);
            }          
		}       
    }, [latestAppId]);

    useEffect(()=>{
        if(applicationMetadata && applicationMetadata.productId)    
            mainService.applicationService.addProductDataListener(applicationMetadata.productId,listenAppProduct)
        if(applicationMetadata && !applicationMetadata.productId)
            mainService.applicationService.addApplicationProductDataListener(latestAppId,listenAppProduct)
        if(applicationMetadata && applicationMetadata.fundingRecommendations){
            const fdata = applicationMetadata.fundingRecommendations.find(el => el.selected);
            setFundingData(fdata)
        }
        
        return ()=>{
            if(applicationMetadata && applicationMetadata.productId)
                mainService.applicationService.removeProductDataListener(latestAppId,listenAppProduct);
        }
    },[applicationMetadata])


    const handleGoToEditApp = (tab) => {
        window.open(`/home?module=applications&action=edit&appid=${latestAppId}&tab=${tab}`,'_self')
    }

    const handleOpenMessaging = () => {
        onOpenMessaging();
    }

    const handleCreateNewApplicationClick = () => {
        window.open('/home?module=applications&action=new', '_self'); 
    }

    return (
        <React.Fragment>
        <Box sx={{position:'absolute', width:'100%', height:'100%', overflow:'auto', display:'flex', flexDirection:'column',}}>
        <Box sx={{display:'flex', flexGrow:1, flexDirection:'row', justifyContent:'center',}}>
        {initialLoading ?
        (<Box sx={{position:'relative', mb:'12px', display:'flex', flexDirection:'column', flexGrow:1, animation:mainAnim, opacity:mainOpacity}}>
            <LoadingFull/>
        </Box>) :       
        !applicationMetadata && !initialLoading ? (
        <Box sx={{display:'flex', flexGrow:1, maxWidth:'1200px', flexDirection:'column',mx:'32px', mb:'32px', mt:'16px'}}>
            <Paper sx={{ px:'16px', py:'16px', flexGrow:1, display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
                <Typography align='center' gutterBottom variant="h1" sx={{mb:'32px'}}>
                    Welcome to your dashboard!
                </Typography>
                <Typography align='center' gutterBottom variant="h3" sx={{mb:'32px'}}>
                    You haven't created any applications yet! Click the button below to get started.
                </Typography>
                <StyledButton text='Create new grant application' icon={()=><AddBoxIcon sx={{ml:'8px', color:'#ffffff'}} color='action'/>} onClick={ handleCreateNewApplicationClick }/>
            </Paper>
        </Box>) : (
        <Box sx={{display:'flex', flexGrow:1, maxWidth:'1200px', flexDirection:'column',mx:'32px', mb:'32px', mt:'16px'}}>
            <Box sx={{my:'12px', display:'flex', flexDirection:'column'}}>
                <Typography variant='h4'>My Dashboard</Typography>
            </Box>
            
            <Box sx={{ display:'flex', flexDirection:'column', flexGrow:1, animation:mainAnim, opacity:mainOpacity}}>

            <Box sx={{position:'relative', flexGrow:1 }}>
                
                <Box sx={{position:'absolute', width:'100%', height:'100%', overflow:'auto', borderRadius:'8px',flexGrow:1,display:'flex',flexDirection:'column'}}>
                <Box sx={{display:'flex', flexDirection:{xs:'column', md:'row'}, mb:'12px',flexGrow:1  }}>

                    <Paper sx={{position:'relative', p:'8px', mr:'6px', display:'flex', flexDirection:'column',flexGrow:1}}>
                        <Typography gutterBottom variant="h3">My product</Typography>
                        <Box sx={{position:'relative', display:'flex', flexGrow:1,flexDirection:{xs:'column', md:'row'}}}>
                            {appProductData?.featureImage?.url &&
                            <Box sx={{position:'relative',p:'8px',minWidth:'300px'}}>
                                <img style={{width:'100%',height:'100%',objectFit:'contain'}} src={appProductData?.featureImage?.url}/>
                            </Box>}
                            <Box sx={{flexGrow:1,display:'flex',flexDirection:'column',p:'4px'}}>
                                {!appProductData?.title &&
                                <Typography>                          
                                    No product data yet, continue your product flow to generate product data.                         
                                </Typography>
                                }
                                <Typography variant='h3' gutterBottom>{appProductData?.title}</Typography>
                                <Typography variant='h5' gutterBottom>{appProductData?.strapline}</Typography>
                                <Typography 
                                    variant='body2'
                                    sx={{textOverflow:'ellipsis',overflow:'hidden',display:'-webkit-box',WebkitLineClamp:9,lineClamp:9,WebkitBoxOrient:'vertical'}}
                                >
                                    {appProductData?.shortdescription ?? appProductData?.description}
                                </Typography>
                                <Box sx={{flexGrow:1}}/>
                                <Box sx={{display:'flex',flexGrow:1,alignItems:'flex-end'}}>
                                    <Button 
                                        onClick={appProductData?.websiteurl ? ()=>{window.open(appProductData.websiteurl,'_blank')} : null}
                                        startIcon={<PublicIcon/>}
                                    >
                                        Visit website
                                    </Button>
                                    <Box sx={{flexGrow:1}}/>
                                    <Button startIcon={<MessageIcon/>} onClick={handleOpenMessaging}>Message provider</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Paper>

                    <Paper sx={{position:'relative', p:'8px', display:'flex', flexDirection:'column', ml:'6px', flexGrow:1,maxWidth:'500px'}}>
                        <Typography gutterBottom variant="h3">my application summary</Typography>
                        {!projectSummary &&
                            <Typography>                          
                                No application content yet, continue your application flow to generate some content.                         
                            </Typography>
                        }
                        <Box sx={{position:'relative', flexGrow:1, display:'flex', flexDirection:'column',p:'4px'}}>
                            <Typography variant='h4' gutterBottom>
                                {applicationMetadata?.eligibilityTitle}
                            </Typography>
                            <Typography 
                                variant='body2' 
                                sx={{textOverflow:'ellipsis',overflow:'hidden',display:'-webkit-box',WebkitLineClamp:12,lineClamp:12,WebkitBoxOrient:'vertical'}}
                            >
                                {projectSummary && projectSummary}
                            </Typography>
                            <Box sx={{display:'flex',flexGrow:1,alignItems:'flex-end'}}>
                                <Button 
                                    onClick={()=>{setDlgMode('application'); setDlgOpen(true)}}
                                    startIcon={<VisibilityIcon/>}
                                >
                                    Preview application
                                </Button>
                                <Box sx={{flexGrow:1}}/>
                                <Button startIcon={<EditIcon/>} onClick={()=>handleGoToEditApp(1)}>Edit application</Button>
                            </Box>
                        </Box>
                    </Paper>

                </Box>

                <Box sx={{display:'flex', flexDirection:{xs:'column', md:'row'}, mb:'12px', flexGrow:1}}>

                    <Paper sx={{position:'relative', p:'8px', flexGrow:1,  display:'flex', flexDirection:'column',mr:'6px'}}>
                        <Typography gutterBottom variant="h3">My funding</Typography>
                        <Box sx={{position:'relative', display:'flex',flexGrow:1, flexDirection:'column',gap:'4px',pt:'8px',overflow:'auto'}}>
                            {applicationMetadata?.fundingSelected ? (
                            <React.Fragment>
                                <ProgressIndicator steps={steps}  stage={!fundingData?.stage || typeof fundingData.stage === 'string' ? 0 : fundingData.stage} readonly/>
                                 <Box sx={{display:'flex',flexDirection:'column', borderRadius:'8px',border:`1px solid ${Theme.palette.primary.main}`,px:'16px',py:'12px',bgcolor:Theme.palette.primary.light}}>
                                    <Box sx={{display:'flex',gap:'8px',alignItems:'center',mb:'8px'}}>
                                        <Typography variant="h5">
                                            {fundingData?.data?.GrantName}
                                        </Typography>
                                        <ButtonBase onClick={()=>window.open(fundingData?.data?.GrantURL,'_blank')} sx={{borderRadius:'5px'}}>
                                            <OpenInNewIcon sx={{color:Theme.palette.grey[400],width:'24px',height:'24px'}}/>
                                        </ButtonBase>
                                    </Box>

                                    <Typography gutterBottom variant="body">
                                        Awarded by: {fundingData?.data?.AwardOrg}
                                    </Typography>

                                    <Typography gutterBottom variant="body">
                                        Maximum award: ${fundingData?.data?.AwardMax ? getFormattedNumberString(fundingData?.data?.AwardMax) : 'N/A'}
                                    </Typography>

                                    <Typography variant="body">
                                        Deadline: {}
                                    </Typography>
                                </Box>
                            </React.Fragment>) : (
                            <Typography>
                                No funding added yet, head over to your application to link a funding source.
                            </Typography>)                           
                            }
                        </Box>
                        <Box sx={{display:'flex',flexGrow:1,alignItems:'flex-end'}}>
                                <Box sx={{flexGrow:1}}/>
                                <Button startIcon={<EditIcon/>} onClick={()=>handleGoToEditApp(3)}>Update funding progress</Button>
                        </Box>
                    </Paper>

                    <Paper sx={{position:'relative', p:'8px', display:'flex', flexDirection:'column',minWidth:'300px', ml:'6px'}}>
                        <Typography gutterBottom variant="h3">Current budget</Typography>
                        {!budgetSummary && !budgetSummary?.total &&
                            <Typography>                          
                                No budget yet, complete your application flow to create a budget.                          
                            </Typography>
                        }
                        <Box sx={{position:'relative', flexGrow:1, display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                            <Box sx={{display:'flex',flexGrow:1,alignItems:'center'}}>
                                <Typography variant='h1'>
                                {budgetSummary && budgetSummary?.total &&
                                    `$${getFormattedNumberString(budgetSummary.total)}`
                                }
                                </Typography>
                            </Box>
                            <Box sx={{display:'flex',alignItems:'flex-end',justifyContent:'space-between'}}>
                                <Button 
                                    onClick={()=>{setDlgMode('budget');setDlgOpen(true)}}
                                    startIcon={<VisibilityIcon/>}
                                >
                                    View budget
                                </Button>
                                <Box sx={{flexGrow:1}}/>
                                <Button startIcon={<EditIcon/>} onClick={()=>handleGoToEditApp(2)}>Edit budget</Button>
                            </Box>
                        </Box>
                    </Paper>

                </Box>

                </Box>
            </Box>
            </Box>
        </Box>)}
        </Box>
    </Box>
    <ResponsiveDialog open={dlgOpen} onClose={()=>setDlgOpen(false)} maxWidth={1200} sx={{overflow:'hidden'}}>
        {dlgMode == 'application' ?
            (<Box sx={{position:'relative', width:'900px', height:'900px', }}>
                <CondensedApplication uid={null} appid={latestAppId}/>
            </Box>) : dlgMode == 'budget' ? (
                <BudgetPreview budgetSummary={budgetSummary} budgetSections={budgetSections} budgetLines={budgetLines} />
            ) : null
        }
       
    </ResponsiveDialog>
    </React.Fragment>);    
}

export default Dashboard; 