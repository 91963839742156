import React, {useState, useEffect, useContext } from "react";

import Theme from '../../Theme';

import { ThemeProvider } from  '@mui/system';
import {Box,Table, TableBody, TableHead, TableRow, TableContainer, TableCell, Typography} from '@mui/material';
import { getFormattedNumberString } from "../../Util";

const SpanRow = ({title}) => {
    return (
        <TableRow>
            <TableCell sx={{bgcolor:Theme.palette.primary.light}} align="left" colSpan={3} size='small'>
                    <Typography variant='h5'>{title}</Typography>
            </TableCell>
        </TableRow>       
    )
}

const BudgetLineRow = ({item,description,total}) => {
    return(
        <TableRow>
            <TableCell align='left' sx={{borderRight:`solid 1px ${Theme.palette.grey[300]}`}} size='small'>
                {item}
            </TableCell>
            <TableCell align="left" sx={{borderRight:`solid 1px ${Theme.palette.grey[300]}`}} size='small'>
                {description}
            </TableCell>
            <TableCell align='right' size='small'>
                ${total && getFormattedNumberString(total)}
            </TableCell>
        </TableRow>
    );
}

const SubtotalRow = ({total}) => {
    return(
        <TableRow>
        <TableCell align="left" size='small'>
            <b>Subtotal:</b>
        </TableCell>
        <TableCell align="right" colSpan={2} size='small'>
            <Typography variant='h5'>${total && getFormattedNumberString(total)}</Typography>
        </TableCell>
        </TableRow>
    )
}

const GrandTotalRow = ({total}) => {
    return (
        <TableRow>
            <TableCell sx={{bgcolor:Theme.palette.grey[300], borderRight:`solid 1px ${Theme.palette.grey[300]}`}} align="left" size='small'>
                <Typography variant='h5'>Total:</Typography>
            </TableCell>
            <TableCell align="right" colSpan={2} size='small'>
                <Typography variant='h5'>${total && getFormattedNumberString(total)}</Typography>
            </TableCell>
        </TableRow>       
    )
}


const BudgetPreview = ({budgetLines,budgetSections,budgetSummary}) => {
    
    return(
        <Box>
            <TableContainer sx={{flexGrow:1,position:'relative',overflow:'hidden'}}>
            <Table sx={{ minWidth: 700}} aria-label="funding table" stickyHeader>
            <TableHead>
                <TableRow sx={{bgcolor:Theme.palette.primary.main}}>
                    <TableCell 
                        sx={{bgcolor:Theme.palette.primary.main,borderRight:`solid 1px ${Theme.palette.grey[300]}`}} 
                        align='left'
                    >
                        <Typography  sx={{color:'white'}} variant='h3'>Budget Item</Typography>
                    </TableCell>
                    <TableCell 
                        sx={{bgcolor:Theme.palette.primary.main,borderRight:`solid 1px ${Theme.palette.grey[300]}`}} 
                        align='left'
                    >
                        <Typography  sx={{color:'white'}} variant='h3'>Description</Typography>
                    </TableCell>
                    <TableCell 
                        sx={{bgcolor:Theme.palette.primary.main}} 
                        align='right'
                    >
                        <Typography sx={{color:'white'}} variant='h3'>Total cost</Typography>
                    </TableCell>
                </TableRow>
            </TableHead>            
            <TableBody>
                {budgetSummary?.sections && budgetSummary.sections.map((sId)=>(
                    <React.Fragment>
                        <SpanRow key={sId} title={budgetSections[sId].name} total={budgetSections[sId].total} />
                        {budgetSections[sId]?.lines && budgetSections[sId]?.lines.map((lId)=>(
                            <BudgetLineRow key={lId} item={budgetLines[lId].item} description={budgetLines[lId].description} total={budgetLines[lId].total}/>
                        ))}
                        <SubtotalRow total={budgetSections[sId].total}/>
                    </React.Fragment>
                ))}
                <GrandTotalRow total={budgetSummary?.total} />
            </TableBody>   
            </Table>            
            </TableContainer>
        </Box>
    )
}

export default BudgetPreview; 