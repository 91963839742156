import React, {useState, useEffect, useContext } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";

import Theme from '../Theme';

import MainService from "../MainService"; 
import MainContext from "../MainContext"; 

import { ThemeProvider } from  '@mui/system';
import { Box, IconButton, Typography,  } from "@mui/material";

import Paper from "../components/Paper"; 


const Campaigns = () => {
    const mainService = useContext(MainContext);

    return (
    <ThemeProvider theme={Theme}>
        <Box sx={{position:'absolute', width:'100%', height:'100%', overflow:'auto', display:'flex', justifyContent:'center', }}>
            


            <Paper sx={{display:'flex', flexGrow:1, maxWidth:'1200px', p:0, m:'32px', overflow:'hidden', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                <Typography variant="h2" gutterBottom>Coming Soon!</Typography>
                <Typography variant="body">Campaigns will allow you to create parent and community supported projects</Typography>
            </Paper>



        </Box>
    </ThemeProvider>
    )
}

export default Campaigns; 