import {useState,useEffect} from "react";

import { Box } from "@mui/material";

import fadein from "../AnimationKeyframes/FadeIn";
import fadeout from "../AnimationKeyframes/FadeOut";



const AutoTextFlow = ({data, playing, iterations=1, autostop=true, onFinish }) => {

    const TRANSITION_TIME = 1; 
    const WAIT_TIME = 1;

    const FADE_IN = 0; 
    const FADE_OUT = 2;

    //STATES 
    const [currentNodeIndex, setCurrentNodeIndex] = useState(1); 
    const [stage, setStage] = useState(FADE_IN);
    
    const sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const executeFlowInfinite = async () => {
        console.log('starting flow loop');
        let inx = 0
        while(playing){
            setCurrentNodeIndex(inx);
            setStage(FADE_IN); 
            await sleep(TRANSITION_TIME*1000);
            await sleep(WAIT_TIME*1000);
            setStage(FADE_OUT);
            await sleep(TRANSITION_TIME*1000);
            if(inx >= data.length-1)
                inx = 0
            else
                inx++;
        }
        handleFinish();
    }

    const executeFlowAutostop = async () => {
        console.log('starting flow')
        let i = 0
        while(i<iterations){
            for (let j = 1; j < data.length; j++) {
                setCurrentNodeIndex(j);
                setStage(FADE_IN); 
                await sleep(TRANSITION_TIME*1000);
                await sleep(WAIT_TIME*1000);
                setStage(FADE_OUT);
                await sleep(TRANSITION_TIME*1000);
    
            }
            i++;
        }
        handleFinish();
    }

    const executeFlow = async () => {
        console.log('starting flow')
        let i = 0

        while(i<iterations){
            for (let j = 1; j < data.length; j++) {
                setCurrentNodeIndex(j);
                setStage(FADE_IN); 
                await sleep(TRANSITION_TIME*1000);
                await sleep(WAIT_TIME*1000);
                setStage(FADE_OUT);
                await sleep(TRANSITION_TIME*1000);
                if(i+1 >= iterations && j+2 >= data.length)
                    break;        
            }
            i++;
        }
        
        //play final one until stops playing 
        setCurrentNodeIndex(data.length-1);
        setStage(FADE_IN); 
    }

    const handleFinish = () => {
        onFinish()
    }

    //EFFECTS 
    useEffect(() => {
        console.log('auto text flow mounted:'); 

        if(playing){
            if(iterations==='infinite')
                executeFlowInfinite();
            else if(!autostop && iterations !== 'infinite')
                executeFlow()
            else
                executeFlowAutostop();
        }

        return ()=>{

        }

    }, [playing]);

    //RENDER 
    let currentNode = currentNodeIndex ? data[currentNodeIndex] : null;
    let NodeComponent = currentNode ? currentNode.component : null; 

    return (
        <Box sx={{position:'relative', display:'flex', justifyContent:'center', alignItems:'center'}}>
            {(currentNode && playing) &&                    
                <NodeComponent                    
                    animation={`${stage === FADE_IN ? fadein : stage === FADE_OUT ? fadeout : null} ${TRANSITION_TIME}s ease-in`}
                    data={data}
                />}
        </Box>
    )
}

export default AutoTextFlow; 