import React, {useState, useEffect, useContext } from "react";
import MainContext from "../../MainContext"; 

import {Box,Tab,Tabs,Typography,ButtonBase,CircularProgress,IconButton, Snackbar, TextField, InputBase} from '@mui/material';
import Paper from "../../components/Paper";
import Theme from "../../Theme";
import fadein from "../../AnimationKeyframes/FadeIn";
import CondensedApplication from "../ppDashboard/CondensedApplication";
import StyledTooltip from "../../components/StyledTooltip";

import { CopyAll } from "@mui/icons-material";


const Campaign = ({appId, onAlert, productData, userObject}) => {
    const mainService = useContext(MainContext);

    const [title, setTitle] = useState('');  
    const [strapline, setStrapline] = useState('');  
    const [description, setDescription] = useState('');  
    const [thankyou, setThankyou] = useState('');  
    const [loading, setLoading] = useState(true); 

    const listenCampaignLoadingChanged = (data)=>{
        if(data == null)
            setLoading(true); 
        else
            setLoading(data?true:false); 
    }


    const listenCampaignTitleChanged = (data)=>{
        setTitle(data ? data:''); 
    }

    const listenCampaignStraplineChanged = (data)=>{
        setStrapline(data ? data:''); 
    }

    const listenCampaignDescriptionChanged = (data)=>{
        setDescription(data ? data:''); 
    }

    const listenCampaignThankyouChanged = (data)=>{
        setThankyou(data ? data:''); 
    }

    const handleTitleChange = (e)=>{
        let text = e.target.value;
        mainService.applicationService.setCampaignData(appId, 'title', text); 
    }

    const handleStraplineChange = (e)=>{
        let text = e.target.value;
        mainService.applicationService.setCampaignData(appId, 'strapline', text); 
    }

    const handleDescriptionChange = (e)=>{
        let text = e.target.value;
        mainService.applicationService.setCampaignData(appId, 'description', text); 
    }

    const handleThankyouChange = (e)=>{
        let text = e.target.value;
        mainService.applicationService.setCampaignData(appId, 'thankyou', text); 
    }

    const handleCopy = (text)=>{
        navigator.clipboard.writeText(text)
        if(onAlert)
            onAlert('Text copied to clipboard','success');
    }

    //EFFECTS 
    useEffect(() => {
        if(appId){
            mainService.applicationService.addCampaignTitleListener(appId, listenCampaignTitleChanged);  
            mainService.applicationService.addCampaignStraplineListener(appId, listenCampaignStraplineChanged);  
            mainService.applicationService.addCampaignDescriptionListener(appId, listenCampaignDescriptionChanged);  
            mainService.applicationService.addCampaignThankyouListener(appId, listenCampaignThankyouChanged);  

            mainService.applicationService.addCampaignLoadingListener(appId, listenCampaignLoadingChanged); 
        }
        return ()=>{
            if(appId){
                mainService.applicationService.removeCampaignTitleListener(appId, listenCampaignTitleChanged); 
                mainService.applicationService.removeCampaignStraplineListener(appId, listenCampaignStraplineChanged); 
                mainService.applicationService.removeCampaignDescriptionListener(appId, listenCampaignDescriptionChanged); 
                mainService.applicationService.removeCampaignThankyouListener(appId, listenCampaignThankyouChanged); 

                mainService.applicationService.removeCampaignLoadingListener(appId, listenCampaignLoadingChanged); 
            }
		}

	}, [appId]);
    
    return (
        <React.Fragment>
        <Box sx={{flexGrow:1, display:'flex', flexDirection:'row'}}> 

            <Paper sx={{position:'relative', flexGrow:1, display:'flex', flexDirection:'column', overflow:'hidden', borderBottomRightRadius:"0px", borderBottomLeftRadius:"0px" }}>
                <Box sx={{px:'16px', pt:'16px',pb:'8px', display:'flex', flexDirection:'row', alignItems:'center', borderBottom:`1px solid ${Theme.palette.grey[300]}`}}>
                    <Typography gutterBottom variant="h2">DonorsChoose Campaign</Typography>
                    <Box sx={{flexGrow:1}}/>
                </Box>
                <Box sx={{position:'relative', borderRadius:'8px', flexGrow:1,}}>
                {loading?(
                <Box sx={{position:'absolute', width:'100%', height:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
                    <CircularProgress sx={{mb:'32px'}}/>
                    <Typography variant="h2">Generating Campaign</Typography>
                </Box>
                ):(
                <Box sx={{position:'absolute', width:'100%', height:'100%', overflowY:'auto'}}>
                    <Box sx={{display:'flex', flexDirection:'column'}}>
                        <Box sx={{position:'relative', bgcolor:'#3804c1', 
                                  color:'#FF6565',}} >
                            
                            <Box sx={{
                                position:'absolute', 
                                width:'100%', height:'100%',
                                backgroundImage:'url(https://cdn.donorschoose.net/images/tapestry-wireframe-dark-blue.png?auto=webp)',
                                backgroundRepeat:'repeat', backgroundSize:'728px 275px',
                                opacity:0.5, top:0, left:0,
                            }}/>

                            <Box sx={{ display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', my:'24px', px:'16px'}}>
                                <Typography sx={{fontStyle:'italic', color:'#ffffff'}}>
                                Project title: Make it exciting and Capitalize It Like a Book Title. 2 words minimum.
                                </Typography>
                                <Box sx={{display:'flex', alignSelf:'stretch'}}>
                                    <Box sx={{flexGrow:1}}/>
                                    <InputBase multiline onChange={handleTitleChange} value={title} sx={{mb:'16px', color:'#ffffff', fontSize:'40px', width:'932px'}}/>
                                    <Box sx={{flexGrow:1}}/>
                                    <IconButton sx={{color:'white', alignSelf:'center'}} onClick={()=>handleCopy(title)}>
                                        <CopyAll/>
                                    </IconButton>
                                </Box>
                                
                                <Typography sx={{fontStyle:'italic', color:'#ffffff'}}>
                                Summarize what's in your cart: In one sentence: what do your students need? 8 word minimum.
                                </Typography>
                                <Box sx={{display:'flex', alignSelf:'stretch'}}>
                                    <Box sx={{flexGrow:1}}/>
                                    <InputBase multiline onChange={handleStraplineChange} value={strapline} sx={{ color:'#ffffff', fontSize:'24px', width:'932px'}}/>
                                    <Box sx={{flexGrow:1}}/>
                                    <IconButton sx={{color:'white', alignSelf:'center'}} onClick={()=>handleCopy(strapline)}>
                                        <CopyAll/>
                                    </IconButton>
                                </Box>
                            </Box>

                        </Box>
                        <Box sx={{width:'100%', display:'flex', justifyContent:'center',}}>
                            <Box sx={{ display:'flex', flexDirection:'column', p:'16px', borderBottomLeftRadius:'8px', borderBottomRightRadius:'8px', mt:'32px',  width:'980px'}}>
                                
                                <Typography sx={{fontSize:'24px', fontWeight:'900'}}>
                                My Project
                                </Typography>
                                <Typography sx={{fontStyle:'italic'}}>
                                    About your project: Tell us what you love about your students. What makes them special? How will these materials make a difference 
                                    in their learning? Help prospective donors picture the lessons you'll teach and your students' learning outcomes.
                                </Typography>
                                <Box sx={{display:'flex', }}>
                                    <InputBase multiline onChange={handleDescriptionChange} value={description} sx={{my:'8px', fontSize:'17px', color:'#212121', flexGrow:1,}}/>
                                    <IconButton sx={{alignSelf:'center'}} onClick={()=>handleCopy(description)}>
                                        <CopyAll/>
                                    </IconButton>
                                </Box>
                           
                                <Typography sx={{fontSize:'24px', fontWeight:'900'}}>
                                Thank your future donors
                                </Typography>
                                <Typography sx={{fontStyle:'italic', }}>
                                A note sent to doners thanking them for their contribution. 
                                </Typography>
                                <Box sx={{display:'flex', }}>
                                    <InputBase multiline onChange={handleThankyouChange} value={thankyou} sx={{my:'8px', fontSize:'17px', color:'#212121', flexGrow:1,}}/>
                                    <IconButton sx={{alignSelf:'center'}} onClick={()=>handleCopy(thankyou)}>
                                        <CopyAll/>
                                    </IconButton>
                                </Box>

                            </Box>
                        </Box>
                    </Box>
                </Box>
                )}
                </Box>
            </Paper>
        </Box>
        </React.Fragment>
    )
}



export default Campaign; 