import React, {useState, useEffect, useContext } from "react";

import Theme from '../Theme';

import { ThemeProvider } from  '@mui/system';
import {Box,} from '@mui/material';

const Paper = ({children, sx}) => {
    return(
    <ThemeProvider theme={Theme}>
        <Box sx={{
            borderRadius:"8px", 
            backgroundColor:'#FFFFFF',
            //boxShadow: "rgb(95 116 141 / 3%) 0px 2px 1px -1px, rgb(95 116 141 / 4%) 0px 1px 1px 0px, rgb(95 116 141 / 8%) 0px 1px 3px 0px", 
            border:`1px solid ${Theme.palette.grey[300]}`,
            position:'relative',
            ...sx
        }}>
            {children}
        </Box>
    </ThemeProvider> 
    )
}

export default Paper; 