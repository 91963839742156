import React, {useState, useEffect, useContext } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";

import theme from '../../Theme';

import MainContext from "../../MainContext"; 

import fadein from "../../AnimationKeyframes/FadeIn";

import { ThemeProvider } from  '@mui/system';
import { Box, Typography, ButtonBase, IconButton, InputAdornment, DialogActions, DialogContent,DialogTitle, Button, Switch, TextField, CircularProgress} from "@mui/material";

import MenuIcon from '@mui/icons-material/Menu';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';
import PublishIcon from '@mui/icons-material/Publish';
import AddIcon from '@mui/icons-material/Add';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

import ResponsiveDialog from "../../components/ResponsiveDialog";
import FileDragAndDrop from "../../components/FileDragAndDrop";
import YesNoDialog from "../../components/YesNoDialog";

import Paper from "../../components/Paper";

import TextFormInput from "../../components/formInputs/TextFormInput";
import PasteFormInput from "../../components/formInputs/PasteFormInput";

import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import {getEmbeddedLink,unitOptions,allProjectSkills} from "../../Util"; 

import StyledTextField from "../../components/StyledTextField";
import Product from "../../pages/Product";
import SimpleAutocomplete from "../../components/formInputs/SimpleAutocomplete";
import MultiTagAutoComplete from "../../components/formInputs/MultiTagAutoComplete";
import Theme from "../../Theme";
import StyledSquareButton from "../../components/StyledSquareButton";
import CopyButton from "./CopyButton";
import CopyLink from "./CopyLink";
import StyledButton from "../../components/StyledButton";

import WebImagesDialog from "./WebImagesDialog";

import { imgSrcToBlob } from 'blob-util';


function useWordCount(text) {
	const [wordCount, setWordCount] = useState(null);
	useEffect(() => {
		//split spaces
		if (text){
			const textArr = text.split(' ');
			const wordArr = textArr.filter(e => (e !== '' && e !== '.'));
			const wc = wordArr.length;
			setWordCount(wc);
		}
	});
	
	return wordCount;
}


const ProductListingEditor = ({onListingMenuClick, listingId, listingData, collectionId, collectionData, onAlert,onLoading, onPublishDone, onListingDeleted})=>{

    //Use
	const mainService = useContext(MainContext);

    //STATES
    //const [listingData,setListingData] = useState({title:"",strapline:"",websiteurl:""});
    const [uploadDialogOpen,setUploadDialogOpen] = useState(false);
    const [webImgsDlgOpen,setWebImgsDlgOpen] = useState(false);
    const [webImgsMultiselect,setWebImgsMultiselect] = useState(false);
    const [websiteurls,setWebsiteUrls] = useState(false);
    const [emailCopy,setEmailCopy] = useState(null);

    const [imgFile,setImgFile] = useState(null);
    const [imgType,setImgType] = useState(null);
    const [maxImgFiles, setMaxImgFiles] = useState(1);

    const [confirmOpen,setConfirmOpen] = useState(false);
    
    const [sectionSelectionInx, setSectionSelectionInx] = useState(0); 

    const [mainAnim, setMainAnim] = useState(`${fadein} 0.5s linear 0s 1`); 
    const [mainOpacity, setMainOpacity] = useState('1'); 

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const [previewImgsLoading, setPreviewImgsLoading] = useState(true); 
    const [projectTitle,setProjectTitle] = useState('');
    const [problemStatement,setProblemStatement] = useState('');

    const [sampleContentDisabled,setSampleContentDisabled] = useState(false);

    const projDescriptionWordCount = useWordCount(listingData?.sampleProjectDescription);
    const prodDescriptionWordCount = useWordCount(listingData?.description);
    const strapLineWordCount = useWordCount(listingData?.strapline)

    const triggerFadeAnim = ()=>{
        setMainAnim(``);
        setMainOpacity(0);
        setTimeout(()=>{setMainAnim(`${fadein} 0.5s linear 0s 1`); setMainOpacity(1);}, 0);
    }

    //HANDLERS
    
    const handleSectionClick = (s)=>{
        triggerFadeAnim(); 
        setSectionSelectionInx(s); 
    }

    const handleSetListingTitle = async (text) => {
        if(!listingId)
            return
        try{
            await mainService.productListingService.writeListingTitle(listingId,text)
        }
        catch(error){
            console.error(error);
        }
    }

    const handleSetListingTextValue = async (text,type) => {
        if(!listingId)
            return
        try{
            await mainService.productListingService.writeListingTextvalue(listingId, type, text)
        }
        catch(error){
            console.error(error);
        }
    }

    const handleSetProviderName = async (text) => {
        if(!listingId)
            return
        try{
            await mainService.productListingService.writeListingProviderName(listingId,text)
        }
        catch(error){
            console.error(error);
        }
    }

    const handleSetProviderTerms = async (text) => {
        if(!listingId)
            return
        try{
            await mainService.productListingService.writeListingProviderTerms(listingId,text)
        }
        catch(error){
            console.error(error);
        }
    }

    const handleBasePriceAmountChange = async (e) => {
        if(!listingId)
            return
        await mainService.productListingService.writeListingBasePriceAmount(listingId, e.target.value)
    }

    const handleBasePriceMaxQuantChange = async (e) => {
        if(!listingId)
            return
        await mainService.productListingService.writeListingBasePriceMaxQuant(listingId, e.target.value)
    }

    const handleBasePriceMinQuantChange = async (e) => {
        if(!listingId)
            return
        await mainService.productListingService.writeListingBasePriceMinQuant(listingId, e.target.value)
    }

    const handleBasePriceUnitTypeChange = async (val) => {
        if(!listingId)
            return
        await mainService.productListingService.writeListingBasePriceUnitType(listingId, val)
    }
    
    const handleBasePriceQuantPerStudentChange= async (e) => {
        if(!listingId)
            return
        await mainService.productListingService.writeListingBasePriceQuantPerStudent(listingId, e.target.value);
    }

    const handleSetListingSkills = (v) => {
        let val = v.toString();
        mainService.productListingService.writeListingSkills(listingId,val);
    }

    const handleSetTitles = () => {
        if(!projectTitle)
            return;

        let titles = listingData && listingData?.projectTitles ? [...listingData.projectTitles] : [];
        titles.push(projectTitle);
        mainService.productListingService.writeProjectTitles(listingId,titles); 
        setProjectTitle('');
    }

    const handleEditTitles = (v,i) => {
        let titles = listingData && listingData?.projectTitles ? [...listingData.projectTitles] : [];
        titles[i] = v;
        mainService.productListingService.writeProjectTitles(listingId,titles); 
    }

    const handleDeleteTitles = (i) => {
        let titles = listingData && listingData?.projectTitles ? [...listingData.projectTitles] : [];
        titles.splice(i,1);
        mainService.productListingService.writeProjectTitles(listingId,titles); 
        
    }

    const handleGenerateProjectTitle = async () => {
        setSampleContentDisabled(true);
        await mainService.productListingService.generateProjectTitle(listingId,listingData);
        setSampleContentDisabled(false);
    }

    const handleSetStatements = () => {
        if(!problemStatement)
            return;

        let statements = listingData && listingData?.problemStatements ? [...listingData.problemStatements] : [];
        statements.push(problemStatement);
        mainService.productListingService.writeProblemStatements(listingId,statements); 
        setProblemStatement('');
    }

    const handleEditStatements = (v,i) => {
        let statements = listingData && listingData?.problemStatements ? [...listingData.problemStatements] : [];
        statements[i] = v;
        mainService.productListingService.writeProblemStatements(listingId,statements); 
    }

    const handleDeleteStatements = (i) => {
        let statements = listingData && listingData?.problemStatements ? [...listingData.problemStatements] : [];
        statements.splice(i,1);
        mainService.productListingService.writeProblemStatements(listingId,statements); 
        
    }

    const handleGenerateProblemStatement= async () => {
        setSampleContentDisabled(true);
        const res = await mainService.productListingService.generateProblemStatement(listingId,listingData);
        console.log(JSON.stringify(res));
        setSampleContentDisabled(false);
    }    

    const handleSetSampleDescription = (v) => {
        mainService.productListingService.writeSampleProjectDescription(listingId,v);
    }

    const handleUploadDialogOpen = (imgType, maxImgs=1) => {
        setMaxImgFiles(maxImgs);
        setImgType(imgType);
        setUploadDialogOpen(true);
    }

    const handleUploadDialogClose = () => {
        setUploadDialogOpen(false)
    }

    const handleWebImgDialogOpen = (imgType, maxImgs=1,multiselect=false) => {
        setMaxImgFiles(maxImgs);
        setImgType(imgType);
        setWebImgsMultiselect(multiselect);
        setWebImgsDlgOpen(true);
    }

    const handleWebImgDialogClose = () => {
        setWebImgsDlgOpen(false)
    }

    const handleUploadImage = async () => {
        setUploadDialogOpen(false);
        if(onLoading)
            onLoading(true);
        if(imgFile && listingId && imgType){
            try{
                if(Array.isArray(imgFile)){

                    let promList = [];
                    for(let img of imgFile){
                        promList.push(mainService.productListingService.uploadProductListingImage(listingId, img.name, img, imgType, true)); 
                    }
                    await Promise.all(promList);

                }
                else {
                    //add img type
                    await mainService.productListingService.uploadProductListingImage(listingId, imgFile.name, imgFile, imgType);
                }
            }           
            catch(error){
                console.error(error);
            }
        }
        if(onLoading)
            onLoading(false);
        if(onAlert)
            onAlert("Image uploaded successfully","success");
    }

    const handleUploadFromUrl = async(urlData)=> {
        console.log('urldata',urlData);
        if(onLoading)
            onLoading(true);
        let filename = '';
        try{

            if(Array.isArray(urlData)){
                console.log('is array')
                let promList = [];
                for(let url of urlData){
                    const u = new URL(url);
                    let fn = u.pathname.substring(u.pathname.lastIndexOf('/')+1);
                    console.log('url',url);
                    console.log('fn ',fn);
                    const prom = imgSrcToBlob(url,'image/png', 'anonymous', 1.0)
                    .then((blob)=>(mainService.productListingService.uploadProductListingImage(listingId,fn,blob,imgType,true)))
                    promList.push(prom);
                }
                await Promise.all(promList);
            }
            else {
                const u = new URL(urlData);
                filename = u.pathname.substring(u.pathname.lastIndexOf('/')+1);
                console.log(urlData);
                const blob = await imgSrcToBlob(urlData,'image/png', 'anonymous', 1.0);
                await mainService.productListingService.uploadProductListingImage(listingId,filename,blob,imgType,false);
            }

            if(onLoading)
                onLoading(false);
        }
        catch(error){
            if(error?.target?.naturalWidth === 0 && error?.target?.naturalHeight===0){
                console.log('getting img with server');
                try{

                    if(Array.isArray(urlData)){
                        let promList = [];
                        for(let url of urlData){
                            const u = new URL(url);
                            let fn = u.pathname.substring(u.pathname.lastIndexOf('/')+1);
                            console.log(url);
                            promList.push(mainService.productListingService.getImageData(url)
                            .then((blob)=>(mainService.productListingService.uploadProductListingImage(listingId,fn,blob,imgType,true))));
                        }
                        await Promise.all(promList);
                    }
                    else {
                        const blob = await mainService.productListingService.getImageData(urlData);
                        await mainService.productListingService.uploadProductListingImage(listingId, filename, blob,imgType,false);
                    }

                    if(onLoading)
                        onLoading(false);
                }
                catch(error){
                    onAlert('An error occurred uploading image: '+ error.message,'error');
                    if(onLoading)
                        onLoading(false);
                }
            }

            else{
                onAlert('An error occurred uploading image: '+ error.message,'error');
                if(onLoading)
                    onLoading(false);
            }
        }
    }

    const handleDeleteImage = async (type) => {
        onLoading(true);
        console.log(listingData); 
        let keyarr = type.split('/'); 
        if(listingData[type] || (listingData[ keyarr[0] ] && listingData[ keyarr[0] ][ keyarr[1] ]) ){
            try{
                console.log('Delete image'); 
                await mainService.productListingService.deleteProductListingImage(listingId, type);
            }           
            catch(error){
                console.error(error);
            }
        }
        onLoading(false);
    }

    const handleSetReview = async (shouldReview) => {
        console.log("publishing");
        setConfirmOpen(false);
        onLoading(true);
        try{
            await mainService.productListingService.reviewListing(listingId, shouldReview, listingData.isPublished);
            //if(shouldReview)
                mainService.productListingService.getProductListingEmailCopy(listingId,listingData);
            onLoading(false);
        }
        catch(error){
            onAlert(error.code,"error");
            onLoading(false);
        }       
        
    }

    const handleToggleToProductCollection =  (e) => {
        console.log('toggle to product collection');
        const val = e.target.checked;
        console.log(val)
        if(val)
            mainService.productListingService.addToProductCollection(listingId,collectionId);
        else
            mainService.productListingService.removeFromProductCollection(listingId,collectionId);
    }

    const handleToggleFeaturedInProductCollection = async (e) => {
        const val = e.target.checked;
        mainService.productListingService.toggleFeaturedInProductCollection(listingId,collectionId,val);
    }

    const handleListingMenuClick = () => {
        if (onListingMenuClick)
            onListingMenuClick();
    }

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false)
    }

    const handleDeleteListing = async () =>{
        onLoading(true);
        setDeleteDialogOpen(false);
        //delete
        try{
            await mainService.productListingService.deleteProductListing(listingId);
            if(onListingDeleted)
                onListingDeleted(); 
            if(onAlert)
                onAlert("deleted successfully","success");
            if(onLoading)
                onLoading(false);
        }
        catch(error){
            if(onAlert)
                onAlert("An error occurred: " + error.message,"error");
            console.error(error);
            if(onLoading)
                onLoading(false);
        }
    }    

    const handleGetWebsiteUrls = async (url) =>{
        if(url){
            const urls = await mainService.productListingService.getWebsiteImages(url);
            if(urls){
                console.log('setting website urls')
                setWebsiteUrls(urls);
            }      
        }
    }

    //listeners
    const listenEmailCopyChanged = (data) => {
        console.log('setting up email copy listener')
        setEmailCopy(data);
    }

    //Effects
    useEffect(()=>{
        if(listingId)
            mainService.productListingService.addEmailCopyListener(listingId,listenEmailCopyChanged);
        return (()=>{
            if(listingId)
                mainService.productListingService.removeEmailCopyListener(listingId,listenEmailCopyChanged);
        })
    },[listingId])


    useEffect(()=>{

        if(listingData.websiteurl){
            console.log('get website urls');
            console.log(listingData.websiteurl)
            handleGetWebsiteUrls(listingData.websiteurl);
        }

    },[listingData])

    const ListingInfo = ({listingData})=>{
        
        return(
        <Box sx={{position:'absolute', display:'flex', flexDirection:'column', width:'100%', height:'100%', overflow:'auto', p:'32px', }}>
            <Box sx={{display:'flex', mb:'24px', alignItems:'center'}}>
                <Typography variant="h2" sx={{mx:'16px'}}>Listing Info </Typography>
                {listingData.inReview && <Typography variant="body" align="right" color='error' sx={{mx:'16px', flexGrow:1}}>In Review</Typography>}
            </Box>
            <Box sx={{flexGrow:1, display:'flex', flexDirection:'column', mb:'32px',}}>
                <TextFormInput 
                    value={listingData.title ? listingData.title : ""}
                    onChange={(text) => handleSetListingTitle(text)}
                    title={'Title'} 
                    descriptionText={'This is the title of your product that will appear on teacher applications and your listing page'}
                    readOnly={listingData.inReview}
                    multiline={false}
                    rows={1}
                />

                {/*<TextFormInput 
                    value={listingData.pluraltitle ? listingData.pluraltitle : ""}
                    onChange={(text) => handleSetListingTextValue(text, "pluraltitle")}
                    title={'Plural Title'} 
                    descriptionText={'Some content will be generated which makes having a plural version of the title very important.'}
                    readOnly={listingData.inReview}
                    multiline={false}
                    rows={1}
                />*/}

                <TextFormInput 
                    value={listingData.strapline ? listingData.strapline : ""}
                    onChange={(text) => handleSetListingTextValue(text,"strapline")}
                    title={'One sentence description/ product strapline'} 
                    descriptionText={'Provide a description of your product in a maximum of one sentence (max 50 words)'}
                    readOnly={listingData.inReview}
                    multiline={true}
                    rows={2}
                    helperText={ strapLineWordCount >= 50 && `At ${strapLineWordCount} words this is over our recommended limit of 50 words, any content that exceeds this limit may be excluded from content generation.`}
                />
                {strapLineWordCount < 50 && 
                (<Box sx={{display:'flex',width:'100%',mx:'16px',mt:'4px'}}>
                    {listingData?.strapline && <Typography variant="body2">{strapLineWordCount} words</Typography>}
                </Box>)}

                {/*<TextFormInput 
                    value={listingData.shortdescription ? listingData.shortdescription : ""}
                    onChange={(text) => handleSetListingTextValue(text,"shortdescription")}
                    title={'Short Description'} 
                    readOnly={listingData.inReview}
                    multiline={true}
                    rows={6}
                    descriptionText={'Maximum one paragraph.'}
                />*/}

                <TextFormInput 
                    value={listingData.description ? listingData.description : ""}
                    onChange={(text) => handleSetListingTextValue(text,"description")}
                    title={'Description'} 
                    descriptionText={'Provide a longer description of your product. (maximum 150 words, ideally 3 - 4 sentences)'}
                    readOnly={listingData.inReview}
                    multiline={true}
                    maxRows={20}
                    minRows={10}
                    helperText={ prodDescriptionWordCount >= 150 && `At ${prodDescriptionWordCount} words this is over our recommended limit of 150 words, any content that exceeds this limit may be excluded from content generation.`}
                />
                {prodDescriptionWordCount < 150 && 
                (<Box sx={{display:'flex',width:'100%',mx:'16px',mt:'4px'}}>
                    {listingData?.description && <Typography variant="body2">{prodDescriptionWordCount} words</Typography>}
                </Box>)}

                <PasteFormInput 
                    value={listingData.websiteurl ? listingData.websiteurl : ""}
                    onChange={(text) => handleSetListingTextValue(text,"websiteurl")}
                    title={'Product Website URL'} 
                    readOnly={listingData.inReview}
                />

                {/*<TextFormInput 
                    value={listingData.websiteurl ? listingData.websiteurl : ""}
                    onChange={(text) => handleSetListingTextValue(text,"websiteurl")}
                    title={'Product Website URL'} 
                    readOnly={listingData.inReview}
                    multiline={false}
                    rows={1}
                />*/}

            </Box>
        </Box>
        )
    }

    const ProviderInfo = ({listingData}) => {
        return(
            <Box sx={{position:'absolute', display:'flex', flexDirection:'column', width:'100%', height:'100%', overflow:'auto', p:'32px', }}>
                <Box sx={{display:'flex', mb:'24px', alignItems:'center'}}>
                    <Typography variant="h2" sx={{mx:'16px'}}>Product provider info</Typography>
                    {listingData.inReview && <Typography variant="body" align="right" color='error' sx={{mx:'16px', flexGrow:1}}>In Review</Typography>}
                </Box>
                <Box sx={{flexGrow:1, display:'flex', flexDirection:'column', mb:'32px',}}>
                    <TextFormInput 
                        value={listingData.providerName ? listingData.providerName : ""}
                        onChange={(text) => handleSetProviderName(text)}
                        title={'Provider name'} 
                        descriptionText='How your brand name will appear to teachers and grant applicants'
                        readOnly={listingData.inReview}
                        multiline={false}
                        rows={1}
                    />

                    <PasteFormInput 
                        value={listingData.providerTerms ? listingData.providerTerms : ""}
                        onChange={(text) => handleSetProviderTerms(text)}
                        title={'Terms'} 
                        descriptionText='Provide a link to your product terms, this will be displayed on your listing page'
                        readOnly={listingData.inReview}
                    />

                </Box>
            </Box>
            )
    }

    const Graphics = ({listingData})=>{
        
        return(
        <>
        <Box sx={{position:'absolute', display:'flex', flexDirection:'column', width:'100%', height:'100%', overflow:'auto', p:'32px'}}>
            <Box sx={{display:'flex', mb:'24px', alignItems:'center'}}>
                <Typography variant="h2" sx={{mx:'16px'}}>Images and Media </Typography>
                {listingData.inReview && <Typography variant="body" align="right" color='error' sx={{mx:'16px', flexGrow:1}}>In Review</Typography>}
            </Box>
            <Box sx={{flexGrow:1, display:'flex', flexDirection:'column', mb:'32px',}}>


                <Typography variant="body" sx={{mt:'16px', mx:'16px'}}>Logo</Typography>
                <Typography variant="body2" sx={{maxWidth:"300px", mx:'16px'}}>Upload your logo, this will appear on your browse page card.</Typography>
                <Typography variant="body2" sx={{maxWidth:"300px", mb:'16px', mx:'16px'}}>Your image should be 128 x 128px</Typography>
                <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", mx:'16px', mb:theme.spacing(2), my:'16px'}}>
                    <Button disabled={listingData.inReview} variant="contained" sx={{mr:'8px', borderRadius:'30px', color:'white'}} endIcon={<CloudUploadIcon/>} onClick={()=>(handleUploadDialogOpen("logoImage"))}>
                        Upload image
                    </Button>
                    <Button disabled={listingData.inReview} variant="contained" sx={{mr:'0px', borderRadius:'30px', color:'white',bgcolor:Theme.palette.secondary.main,'&:hover':{bgcolor:Theme.palette.secondary.dark}}} endIcon={<AddAPhotoIcon/>} onClick={()=>(handleWebImgDialogOpen("logoImage"))}>
                        Pick an image
                    </Button>
                </Box>

                <Box sx={{position:'relative', display:"flex", flexDirection:"row", mx:'16px', my:'16px', mb:'48px', overflowX:'auto'}}>
                    {listingData.logoImage &&
                        <Box sx={{ display:"flex", flexDirection:"column", }}>
                            <Box sx={{ display:"flex", flexDirection:"row", alignItems:'center', mb:'4px' }}>
                                <Typography variant="body2">{decodeURIComponent(listingData.logoImage.fileName)}</Typography>
                                <IconButton disabled={listingData.inReview} size="small" onClick={()=>{handleDeleteImage("logoImage")}} ><DeleteIcon/></IconButton>
                            </Box>
                            <Box sx={{width:'128px', height:'128px', position:'relative', flexGrow:1, borderRadius:'4px', borderWidth:'2px', borderStyle:'dashed', borderColor:'#808080'}}>
                                <img style={{position:'absolute', width:'100%', height:'100%',objectFit:'contain'}} src={listingData.logoImage && listingData.logoImage.url}></img>
                            </Box>
                        </Box>
                    }
                </Box>
                

                <Typography variant="body" sx={{mt:'16px', mx:'16px'}}>Banner Image</Typography>
                <Typography variant="body2" sx={{maxWidth:"300px", mx:'16px'}}>This image will appear accross the top of your product page</Typography>
                <Typography variant="body2" sx={{maxWidth:"300px", mb:'16px', mx:'16px'}}>Your image should be 428 x 260px</Typography>

                <Box sx={{display:"flex",flexDirection:"row",alignItems:"center", mx:'16px', mb:theme.spacing(2), my:'16px'}}>
                    <Button disabled={listingData.inReview} variant="contained" sx={{mr:'8px', color:'white', borderRadius:'30px'}} endIcon={<CloudUploadIcon/>} onClick={()=>(handleUploadDialogOpen("featureImage"))}>
                        Upload image
                    </Button>
                    <Button disabled={listingData.inReview} variant="contained" sx={{mr:'0px', borderRadius:'30px', color:'white',bgcolor:Theme.palette.secondary.main,'&:hover':{bgcolor:Theme.palette.secondary.dark}}} endIcon={<AddAPhotoIcon/>} onClick={()=>(handleWebImgDialogOpen("featureImage"))}>
                        Pick an image
                    </Button>
                </Box>

                <Box sx={{position:'relative', display:"flex", flexDirection:"row", mx:'16px', my:'16px', mb:'48px', overflowX:'auto'}}>
                    {listingData.featureImage &&
                        <Box sx={{ display:"flex", flexDirection:"column", }}>
                            <Box sx={{ display:"flex", flexDirection:"row", alignItems:'center', mb:'4px' }}>
                                <Typography variant="body2">{decodeURIComponent(listingData.featureImage.fileName)}</Typography>
                                <IconButton disabled={listingData.inReview} size="small" onClick={()=>{handleDeleteImage("featureImage")}} ><DeleteIcon/></IconButton>
                            </Box>
                            <Box sx={{width:'428px', height:'260px', position:'relative', flexGrow:1, borderRadius:'4px', borderWidth:'2px', borderStyle:'dashed', borderColor:'#808080'}}>
                                <img style={{position:'absolute', width:'100%', height:'100%', objectFit:'cover'}} src={listingData.featureImage && listingData.featureImage.url} ></img>
                            </Box>
                        </Box>
                    }
                </Box>


                <Typography variant="body" sx={{mt:'16px', mx:'16px'}}>Product Images</Typography>
                <Typography variant="body2" sx={{maxWidth:"300px", mx:'16px'}}>These images will be part of your product page. </Typography>
                <Typography variant="body2" sx={{maxWidth:"300px", mb:'16px', mx:'16px'}}>Your images should be 428 x 260px</Typography>

                <Box sx={{display:"flex",flexDirection:"row",alignItems:"center", mx:'16px', mb:theme.spacing(2), my:'16px'}}>
                    <Button disabled={listingData.inReview} variant="contained" sx={{mr:'8px', borderRadius:'30px', color:'white'}} endIcon={<CloudUploadIcon/>} onClick={()=>(handleUploadDialogOpen("productImages",8))}>
                        Upload images
                    </Button>
                    <Button disabled={listingData.inReview} variant="contained" sx={{mr:'0px', borderRadius:'30px', color:'white',bgcolor:Theme.palette.secondary.main,'&:hover':{bgcolor:Theme.palette.secondary.dark}}} endIcon={<AddAPhotoIcon/>} onClick={()=>(handleWebImgDialogOpen("productImages",8,true))}>
                        Pick images
                    </Button>
                </Box>

                <Box sx={{position:'relative', display:"flex", flexDirection:"row", mx:'16px', my:'16px', mb:'48px', overflowX:'auto'}}>
                    {listingData.productImages && Object.entries(listingData.productImages).map(([imgkey, productImage], inx)=>(
                        <Box key={imgkey} sx={{ display:"flex", flexDirection:"column", mr:'8px', mb:'4px'}}>
                            <Box sx={{ display:"flex", flexDirection:"row", alignItems:'center', mb:'4px' }}>
                                <Typography variant="body2">{decodeURIComponent(productImage.fileName)}</Typography>
                                <IconButton disabled={listingData.inReview} size="small" onClick={()=>{handleDeleteImage("productImages/"+imgkey)}} ><DeleteIcon/></IconButton>
                            </Box>
                            <Box sx={{width:'428px', height:'260px', position:'relative', flexGrow:1, borderRadius:'4px', borderWidth:'2px', borderStyle:'dashed', borderColor:'#808080'}}>
                                <img style={{position:'absolute', width:'100%', height:'100%', objectFit:'cover'}} src={productImage.url}></img>
                            </Box>
                        </Box>
                        ))
                    }
                </Box>
                
                {/*<TextFormInput 
                    value={listingData.videourl ? listingData.videourl : ""}
                    onChange={(text) => handleSetListingTextValue(text,"videourl")}
                    title={'Product Video URL'} 
                    readOnly={listingData.inReview}
                    multiline={false}
                    rows={1}
                    descriptionText={'Add a product video to your product page (YouTube or Vimeo)'}
                />*/}

                <Box sx={{position:'relative', display:"flex", flexDirection:"row", mx:'16px', my:'16px', mb:'48px', overflowX:'auto'}}>
                    {listingData.videourl && 
                        <Box sx={{ display:"flex", flexDirection:"column", }}>
                            <Box sx={{width:'428px', height:'260px', position:'relative', flexGrow:1, borderRadius:'4px', borderWidth:'1px', borderStyle:'dashed', borderColor:'#808080'}}>
                                <iframe 
                                    style={{position:'absolute', width:'100%', height:'100%'}}
                                    src={ getEmbeddedLink( listingData.videourl ).url }
                                > </iframe>
                            </Box>
                        </Box>
                    }
                </Box>

            </Box>
        </Box>

        <WebImagesDialog onClose={handleWebImgDialogClose} open={webImgsDlgOpen} urls ={websiteurls} onUpload={handleUploadFromUrl} multiSelect={webImgsMultiselect} />

        </>
        )
    }


    const Pricing = ({})=>{
        return(
        <Box sx={{position:'absolute', display:'flex', flexDirection:'column', width:'100%', height:'100%', overflow:'auto', p:'32px'}}>
            <Box sx={{display:'flex', mb:'24px', alignItems:'center'}}>
                <Typography variant="h2" sx={{mx:'16px'}}>Pricing </Typography>
                {listingData.inReview && <Typography variant="body" align="right" color='error' sx={{mx:'16px', flexGrow:1}}>In Review</Typography>}
            </Box>
            <Box sx={{flexGrow:1, display:'flex', flexDirection:'column', mb:'32px',}}>
                <Typography variant="body" sx={{mt:'16px', mx:'16px'}}>Base Product Unit Price</Typography>
                <Typography variant="body2" sx={{maxWidth:"300px", mb:'16px', mx:'16px'}}>This is the unit price of the main product.</Typography>

                <StyledTextField
                    sx={{mt:'16px', mb:'32px', mx:'16px', maxWidth:'300px'}}
                    value={listingData.basePricing && listingData.basePricing.amount ? listingData.basePricing.amount : ''}
                    onChange={handleBasePriceAmountChange}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    disabled={listingData.inReview}
                />

                <Typography variant="body" sx={{mt:'16px', mx:'16px'}}>Base Product Unit name</Typography>
                <Typography variant="body2" sx={{maxWidth:"300px", mb:'16px', mx:'16px'}}>This is what you call one unit of your product. It will display like this in teacher's budgets.</Typography>

                <SimpleAutocomplete 
                    sx={{mt:'16px', mb:'32px', mx:'16px', maxWidth:'300px'}}
                    value={listingData?.basePricing?.unitType ? listingData.basePricing.unitType : ''}
                    title='Unit name'
                    onChange={handleBasePriceUnitTypeChange}
                    valueName='name'
                    freeSolo
                    options={unitOptions}
                    disabled={listingData.inReview}
                />

                <Typography variant="body" sx={{mt:'16px', mx:'16px'}}>Number of students per product unit</Typography>
                <Typography variant="body2" sx={{maxWidth:"300px", mb:'16px', mx:'16px'}}>How many students do you recommend per product, e.g. 12 students per kit or 1 student per license. This will help auto-calculate teacher's budgets. If nothing is input it defaults to 1.</Typography>

                <Box sx={{mt:'16px', mb:'32px', mx:'16px', maxWidth:'300px',display:'flex',justifyContent:'flex-start', gap:'8px',alignItems:'center'}}>

                <Typography noWrap sx={{width:'140px',p:'16.5px 14px',fontSize:'14px',color:Theme.palette.grey[500],overflow:'hidden',textOverflow:'ellipsis'}}>
                   1 {listingData?.basePricing?.unitType ? `${listingData.basePricing.unitType}` : 'unit'} for every
                </Typography>

                {/*<Box 
                    sx={{height:'45px',
                        width:'20px',
                        background:`linear-gradient(to bottom right, #fff calc(50% - 1px), ${Theme.palette.grey[400]} , #fff calc(50% + 1px) )`}}
                />*/}

                <TextField
                    variant="standard"  
                    sx={{width:'45px',m:0}}                  
                    value={listingData?.basePricing?.quantPerStudent ? listingData.basePricing.quantPerStudent : ''}
                    onChange={handleBasePriceQuantPerStudentChange}
                    disabled={listingData.inReview}
                />

                <Typography noWrap sx={{width:'85px',p:'4px',fontSize:'14px',color:Theme.palette.grey[500],overflow:'hidden',textOverflow:'ellipsis'}}>
                    student(s)
                </Typography>

                </Box>


                <Typography variant="body" sx={{mt:'16px', mx:'16px'}}>Base Product Minimum & Maximum Quantity</Typography>
                <Typography variant="body2" sx={{maxWidth:"300px", mb:'16px', mx:'16px'}}>This is the maximum / minimum number of product in an order at the base price.</Typography>
                
                <StyledTextField
                    sx={{mt:'16px', mx:'16px', maxWidth:'300px'}}
                    value={listingData.basePricing && listingData.basePricing.minQuant ? listingData.basePricing.minQuant : ''}
                    onChange={handleBasePriceMinQuantChange}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">min</InputAdornment>,
                    }}
                    disabled={listingData.inReview}
                />

                <StyledTextField
                    sx={{mt:'16px', mb:'32px', mx:'16px', maxWidth:'300px'}}
                    value={listingData.basePricing && listingData.basePricing.maxQuant ? listingData.basePricing.maxQuant : ''}
                    onChange={handleBasePriceMaxQuantChange}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">max</InputAdornment>,
                    }}
                    disabled={listingData.inReview}
                />

            </Box>
        </Box> 
        )
    }


    const Eligibility = ({})=>{
        return(
        <Box sx={{position:'absolute', display:'flex', flexDirection:'column', width:'100%', height:'100%', overflow:'auto', p:'32px'}}>
            <Box sx={{display:'flex', mb:'24px', alignItems:'center'}}>
                <Typography variant="h2" sx={{mx:'16px'}}>Eligibility</Typography>
                {listingData.inReview && <Typography variant="body" align="right" color='error' sx={{mx:'16px', flexGrow:1}}>In Review</Typography>}
            </Box>
            <Box sx={{flexGrow:1, display:'flex', flexDirection:'column', mb:'32px',}}>

                <Typography variant="body" sx={{mt:'16px', mx:'16px'}}>Age Range</Typography>
                <Typography variant="body2" sx={{maxWidth:"300px", mb:'16px', mx:'16px'}}>Tell us what age range is your product suitable for.</Typography>

                <StyledTextField
                    sx={{mt:'16px', mx:'16px', maxWidth:'300px'}}
                    value={listingData.minAge ? listingData.minAge : ''}
                    onChange={(e) => handleSetListingTextValue(e.target.value, "minAge")}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">min</InputAdornment>,
                    }}
                    disabled={listingData.inReview}
                />

                <StyledTextField
                    sx={{mt:'16px', mb:'32px', mx:'16px', maxWidth:'300px'}}
                    value={listingData.maxAge ? listingData.maxAge : ''}
                    onChange={(e) => handleSetListingTextValue(e.target.value, "maxAge")}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">max</InputAdornment>,
                    }}
                    disabled={listingData.inReview}
                />

                <Typography variant="body" sx={{mt:'16px', mx:'16px'}}>Skills developed</Typography>
                <Typography variant="body2" sx={{maxWidth:"300px", mb:'16px', mx:'16px'}}>Tag the skills that students will develop with your product. This will help seed the content generated for teacher's applications. Try and stick to 3 skills.</Typography>

                <MultiTagAutoComplete 
                    sx={{mt:'16px', mb:'32px', mx:'16px'}}
                    value={listingData?.skills ? listingData.skills : ''}
                    title='Skills'
                    onChange={handleSetListingSkills}
                    labelName='name'
                    valueName='name'
                    freeSolo
                    options={allProjectSkills}
                    disabled={listingData.inReview}
                />

            </Box>
        </Box> 
        )
    }


    const Usecases = ({})=>{
        return(
        <Box sx={{position:'absolute', display:'flex', flexDirection:'column', width:'100%', height:'100%', overflow:'auto', p:'32px'}}>
            <Box sx={{display:'flex', mb:'24px', alignItems:'center'}}>
                <Typography variant="h2" sx={{mx:'16px'}}>Use Cases </Typography>
                {listingData.inReview && <Typography variant="body" align="right" color='error' sx={{mx:'16px', flexGrow:1}}>In Review</Typography>}
            </Box>
            <Box sx={{flexGrow:1, display:'flex', flexDirection:'column', mb:'32px',}}>
                <TextFormInput 
                    value={listingData.usecases ? listingData.usecases : ""}
                    onChange={(text) => handleSetListingTextValue(text,"usecases")}
                    title={'Use case descriptions'} 
                    readOnly={listingData.inReview}
                    multiline={true}
                    rows={10}
                    descriptionText={'To help match your product to teachers and projects, you can add descriptive examples of how this product has been used in the past.'}
                />
            </Box>
        </Box> 
        )
    }

    const SampleAppContent = ({})=> {
                
        return(
        <Box sx={{position:'absolute', display:'flex', flexDirection:'column', width:'100%', height:'100%', overflow:'auto', p:'32px'}}>
            <Box sx={{display:'flex', mb:'24px', alignItems:'center'}}>
                <Typography variant="h2" sx={{mx:'16px'}}>Sample application content</Typography>
                {listingData.inReview && <Typography variant="body" align="right" color='error' sx={{mx:'16px', flexGrow:1}}>In Review</Typography>}
            </Box>
            <Box sx={{flexGrow:1, display:'flex', flexDirection:'column', mb:'32px',}}>
                <Typography variant="body" sx={{mt:'16px', mx:'16px'}}>Sample project titles</Typography>
                <Typography variant="body2" sx={{mb:'16px', mx:'16px'}}>Provide some example project titles for teachers to choose from to help inspire their project. These will appear as options when they are generating their application. 3 to 5 is a good amount.</Typography>
                
                <Box sx={{display:'flex',gap:'4px',alignItems:'center',mt:'16px', mb:'8px', mx:'16px',}}>
                    <StyledTextField
                        disabled={sampleContentDisabled || listingData.inReview}
                        placeholder='Add a sample title...'
                        sx={{flexGrow:1}}
                        value={projectTitle}
                        onChange={(e) => setProjectTitle(e.target.value)}
                    />
                    <StyledSquareButton disabled={sampleContentDisabled || listingData.inReview} onClick={handleSetTitles} sx={{width:'56px',height:'56px'}}>
                        <AddIcon/>
                    </StyledSquareButton>
                </Box>
                {listingData?.projectTitles && 
                <Box sx={{display:'flex',flexDirection:'column',mt:'16px', mb:'8px', mx:'16px',maxHeight:'150px', overflow:'auto',p:'4px',border:`1px solid ${Theme.palette.grey[300]}`, borderRadius:'5px'}}>
                    {listingData.projectTitles.map((title,i)=>(
                    <Box key={i} sx={{ p:'4px',display:'flex',flexDirection:'row',borderRadius:'5px',gap:'4px','&:hover':{bgcolor:Theme.palette.primary.light}}}>
                        <TextField 
                            disabled={sampleContentDisabled || listingData.inReview}
                            fullWidth
                            placeholder="Add a title or remove from the list..."
                            variant="standard" 
                            value={title}
                            multiline
                            InputProps={{disableUnderline:true,sx:{...Theme.typography.body1,'&:hover':{bgcolor:Theme.palette.grey[100]},borderRadius:'5px'}}}
                            onChange={(e)=>handleEditTitles(e.target.value,i)}
                        />
                        <ButtonBase disabled={sampleContentDisabled || listingData.inReview} onClick={()=>handleDeleteTitles(i)} sx={{borderRadius:'50px'}}>
                            <CloseIcon sx={{color:sampleContentDisabled || listingData.inReview  ? Theme.palette.grey[100] : Theme.palette.grey[400]}}/>
                        </ButtonBase>
                    </Box>))}
                </Box>}

                <Button 
                    disabled={sampleContentDisabled || listingData.inReview}
                    sx={{mt:'16px', mb:'8px', mx:'16px', mb:'32px',color:'white'}}
                    variant="contained"
                    startIcon={<SettingsSuggestIcon/>}
                    onClick={handleGenerateProjectTitle}                        
                >
                    {sampleContentDisabled ? <CircularProgress size={20} sx={{color:'white'}}/> :  "Generate a title!"}
                </Button>

                <Typography variant="body" sx={{mt:'16px', mx:'16px'}}>Sample problem statements</Typography>
                <Typography variant="body2" sx={{mb:'16px', mx:'16px'}}>Add a series of statements that describe the problem within education that your product can help solve. Grant applications often ask for an evidence backed description of a teacher's problem to justify the need for a grant. We generate these using one sentence descriptions of problems that teachers are facing. </Typography>
                
                <Box sx={{display:'flex',gap:'4px',alignItems:'center',mt:'16px', mb:'8px', mx:'16px',}}>
                    <StyledTextField
                        disabled={sampleContentDisabled || listingData.inReview}
                        placeholder='Add a sample statement...'
                        sx={{flexGrow:1}}
                        value={problemStatement}
                        onChange={(e) => setProblemStatement(e.target.value)}
                    />
                    <StyledSquareButton disabled={sampleContentDisabled || listingData.inReview} onClick={handleSetStatements} sx={{width:'56px',height:'56px'}}>
                        <AddIcon/>
                    </StyledSquareButton>
                </Box>
                {listingData?.problemStatements && 
                <Box sx={{display:'flex',flexDirection:'column',mt:'16px', mb:'8px', mx:'16px',maxHeight:'150px', overflow:'auto',p:'4px',border:`1px solid ${Theme.palette.grey[300]}`, borderRadius:'5px'}}>
                    {listingData.problemStatements.map((statement,i)=>(
                    <Box key={i} sx={{ p:'4px',display:'flex',flexDirection:'row',borderRadius:'5px',gap:'4px','&:hover':{bgcolor:Theme.palette.primary.light}}}>
                        <TextField 
                            disabled={sampleContentDisabled || listingData.inReview}
                            fullWidth
                            placeholder="Add a problem statement or remove from the list..."
                            variant="standard" 
                            value={statement}
                            multiline
                            InputProps={{disableUnderline:true,sx:{...Theme.typography.body1,'&:hover':{bgcolor:Theme.palette.grey[100]},borderRadius:'5px'}}}
                            onChange={(e)=>handleEditStatements(e.target.value,i)}
                        />
                        <ButtonBase disabled={sampleContentDisabled || listingData.inReview} onClick={()=>handleDeleteStatements(i)} sx={{borderRadius:'50px'}}>
                            <CloseIcon sx={{color:sampleContentDisabled || listingData.inReview  ? Theme.palette.grey[100] : Theme.palette.grey[400]}}/>
                        </ButtonBase>
                    </Box>))}
                </Box>}

                <Button 
                    disabled={sampleContentDisabled || listingData.inReview}
                    sx={{mt:'16px', mb:'8px', mx:'16px', mb:'32px',color:'white'}}
                    variant="contained"
                    startIcon={<SettingsSuggestIcon/>}
                    onClick={handleGenerateProblemStatement}                        
                >
                    {sampleContentDisabled ? <CircularProgress size={20} sx={{color:'white'}}/> :  "Generate a problem statement!"}
                </Button>                

                <Typography variant="body" sx={{mt:'16px', mx:'16px'}}>Sample project description</Typography>
                <Typography variant="body2" sx={{mb:'16px', mx:'16px'}}>Write an example of a grant-winning project description. This will be used as a template for generating teacher sample grant answers. Aim for around 250 words.</Typography>
                
                <StyledTextField
                    disabled={sampleContentDisabled || listingData.inReview}
                    placeholder='Write a sample description...'
                    sx={{flexGrow:1,mt:'16px', mx:'16px'}}
                    value={listingData?.sampleProjectDescription ? listingData.sampleProjectDescription : ''}
                    onChange={(e) => handleSetSampleDescription(e.target.value)}
                    multiline
                    minRows={5}
                    maxRows={8}
                />
                <Box sx={{display:'flex',width:'100%',mx:'16px'}}>
                    {listingData?.sampleProjectDescription && <Typography variant="body2">{listingData.sampleProjectDescription.length} chars | {projDescriptionWordCount} words </Typography>}
                </Box>

            </Box>
        </Box> 
        )
    }


    const Preview = ({})=>{
        return(
        <Box sx={{position:'absolute', display:'flex', flexDirection:'column', width:'100%', height:'100%', overflow:'auto', p:'32px'}}>
            <Box sx={{display:'flex', mb:'24px', alignItems:'center'}}>
                <Typography variant="h2" sx={{mx:'16px'}}>Page Preview </Typography>
                {listingData.inReview && <Typography variant="body" align="right" color='error' sx={{mx:'16px', flexGrow:1}}>In Review</Typography>}
            </Box>

            <Box sx={{position:'relative', flexGrow:1, display:'flex', flexDirection:'column', mb:'32px',}}>
                <Typography variant='body' sx={{mx:'16px', mt:'16px', mb:'32px'}}>Laptop Preview</Typography>

                <Box sx={{position:'relative', p:0, width:'800px', height:'500px', overflowY:'auto', overflowX:'hidden', border:'2px dashed #808080',}}>
                    <Box sx={{position:'absolute', width:'1600px', height:'2000px', left:0, top:0, transform:'scale(0.5, 0.5)', transformOrigin:'top left',}}>
                        {/*<Box sx={{position:'absolute', width:'100%', height:'100%', zIndex:2}}></Box>*/}
                        <Product ldata={listingData} lid={listingId} previewMode={true} hideTopBar={true}/>
                    </Box>
                </Box>
                
            </Box>
        </Box> 
        )
    }
    
    const Admin = ({listingData})=>{

        let draftWarning = 
            !listingData.title ? 'You have not included a title for your product.' : 
            !listingData.strapline ? 'You have not included a strapline for your product.' : 
            /*!listingData.shortdescription ? 'You need to write a short description for your product.' : */
            !listingData.description ? 'You need to write a description for your product.' : 
            !listingData.websiteurl ? 'You need add a product website URL to your product.' : 
            !listingData.logoImage ? 'You must include a logo image for your product.' : 
            !listingData.featureImage ? 'You must include a banner image for your product.' : 
            !listingData.productImages ? 'You must include at least one product image for your product.' : 
            !listingData.basePricing?.amount ? 'You must add a unit price to your product.' : 
            !listingData.basePricing?.quantPerStudent ? 'You must add a quantity per student to your product.' : 
            !listingData.projectTitles ? 'You must add at least one sample project title to your product, head to section 7.' :
            !listingData.problemStatements ? 'You must add at least one sample problem statement to your product, head to section 7.' :
            '';

        let draftIsComplete = !draftWarning;
        let inReview = listingData.inReview; 
        let isPublished = listingData.isPublished; 

        let stageComplete = [draftIsComplete || inReview || isPublished, inReview || isPublished, isPublished && !inReview, ];
        
        return(
        <Box sx={{position:'absolute', display:'flex', flexDirection:'column', width:'100%', height:'100%', overflow:'auto', p:'32px'}}>
            <Box sx={{display:'flex', mb:'24px'}}>
                <Typography variant="h2" sx={{mx:'16px'}}>Admin and Publishing </Typography>
            </Box>
            <Box sx={{flexGrow:1, display:'flex', flexDirection:'column', mb:'32px',}}>

                <Typography variant="body" sx={{mt:'16px', mx:'16px'}}>Publishing</Typography>
                <Typography variant="body2" sx={{maxWidth:"300px", mb:'16px', mx:'16px'}}>
                    You can publish you product and re-publish changes here. All publications are reviewed by our team before going live to the store, this can take up to 24 hours. 
                </Typography>

                <Timeline sx={{flexGrow:0, mb:'0px', [`& .${timelineItemClasses.root}:before`]: {flex: 0,padding: 0,}, }}>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color='primary'/>
                            <TimelineConnector sx={{ bgcolor: stageComplete[0] ? 'primary.main' : 'grey' }}/>
                        </TimelineSeparator>
                        <TimelineContent >
                            <Typography variant="body" component="span">Work in Progress</Typography>
                            <Typography variant="body2" color='error'>{draftWarning}</Typography>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot  color={stageComplete[0] ? 'primary' : 'grey'}/>
                            <TimelineConnector sx={{ bgcolor: stageComplete[1]? 'primary.main' : 'grey' }}/>
                        </TimelineSeparator>
                        <TimelineContent color={stageComplete[0] ? '#121f43':'grey'} >Draft Complete</TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color={stageComplete[1] ? 'primary' : 'grey'} />
                            <TimelineConnector sx={{ bgcolor: stageComplete[2] ? 'primary.main' : 'grey' }}/>
                        </TimelineSeparator>
                        <TimelineContent color={stageComplete[1] ? '#121f43':'grey'} >In review</TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color={isPublished ? 'primary' : 'grey'} />
                        </TimelineSeparator>
                        <TimelineContent color={isPublished ? '#121f43':'grey'} >Published & Live</TimelineContent>
                    </TimelineItem>
                </Timeline>

                <Box sx={{display:"flex",flexDirection:"row",alignItems:"center", mx:'16px', mb:theme.spacing(2), my:'16px'}}>
                    {!listingData.inReview ? (
                        <Button disabled={!draftIsComplete} variant="contained" sx={{mr:'0px', borderRadius:'30px',color:'white' }} endIcon={<PublishIcon/>} onClick={()=>(setConfirmOpen(true))}  >
                            {listingData.isPublished ? 'Republish' : 'Publish'}
                        </Button>
                    ):(
                        <React.Fragment>
                        <Button variant="contained" sx={{mr:'0px', borderRadius:'30px', color:'white'}} endIcon={<BlockIcon/>} onClick={()=>(setConfirmOpen(true))}  >
                            Cancel Review
                        </Button>
                        <Typography variant="body2" sx={{maxWidth:"300px", mb:'16px', mx:'16px'}}>
                            Your product listing is currently in review for publication live, press this button to cancel and return to draft.
                        </Typography>
                        </React.Fragment>
                    )}
                </Box>

                <Typography variant="body" sx={{mt:'16px', mx:'16px'}}>Show on products page</Typography>
                <Typography variant="body2" sx={{maxWidth:"300px", mb:'16px', mx:'16px'}}>When toggled this product will show on your products page. Your product must be published first.</Typography>
                
                <Box sx={{display:"flex",flexDirection:"row",alignItems:"center", mx:'16px', mb:theme.spacing(2), my:'16px'}}>
                    <Switch disabled={!listingData.isPublished} checked={(collectionData && collectionData?.products && collectionData?.products[listingId])} onChange={handleToggleToProductCollection}/>
                </Box>

                <Typography variant="body" sx={{mt:'16px', mx:'16px'}}>Add to product page featured</Typography>
                <Typography variant="body2" sx={{maxWidth:"300px", mb:'16px', mx:'16px'}}>Add this product to the featured carousel of the products page. When no products are selected they are chosen at random</Typography>
                
                <Box sx={{display:"flex",flexDirection:"row",alignItems:"center", mx:'16px', mb:theme.spacing(2), my:'16px'}}>
                    <Switch disabled={!listingData.isPublished} checked={(collectionData && collectionData?.products && collectionData?.products[listingId] === 'featured')}  onChange={handleToggleFeaturedInProductCollection}/>
                </Box>
                
                
                <Typography variant="body" sx={{mt:'16px', mx:'16px'}}>Disable This Product</Typography>
                <Typography variant="body2" sx={{maxWidth:"300px", mb:'16px', mx:'16px'}}>This will remove this product from the store. It can be re-enabled at a later date.</Typography>
                
                <Box sx={{display:"flex",flexDirection:"row",alignItems:"center", mx:'16px', mb:theme.spacing(2), my:'16px'}}>
                    <Button disabled={!listingData.isPublished} variant="contained" sx={{mr:'0px', borderRadius:'30px',color:'white' }} endIcon={<BlockIcon/>} onClick={()=>(setDeleteDialogOpen(true))}>
                        Disable
                    </Button>
                </Box>

                <Typography variant="body" sx={{mt:'16px', mx:'16px'}}>Delete This Product</Typography>
                <Typography variant="body2" sx={{maxWidth:"300px", mb:'16px', mx:'16px'}}>WARNING, This will permanently delete this product from draft and from Intellectible. </Typography>

                <Box sx={{display:"flex",flexDirection:"row",alignItems:"center", mx:'16px', mb:theme.spacing(2), my:'16px'}}>
                    <Button color='error' variant="contained" sx={{mr:'0px', borderRadius:'30px', }} endIcon={<DeleteIcon/>} onClick={()=>(setDeleteDialogOpen(true))}>
                        Delete
                    </Button>
                </Box>



            </Box>
        </Box>
        )
    }

    const Sharing = ({}) => {
        const buttonHTML = `<a href="https://edu.intellectible.com/home?module=applications&action=new&name=${encodeURIComponent(listingData?.title)}&productid=${listingId}" target="_blank">`+
        `<button style="background-color: #54BD95; color: white; padding: 12px 24px; border-radius: 8px; border: none; cursor: pointer; font-size: 16px;">` +
        `Apply with Intellectible` +
        `</button></a>`;
        return(
            <Box sx={{position:'absolute', display:'flex', flexDirection:'column', width:'100%', height:'100%', overflow:'auto', p:'32px'}}>
            <Box sx={{display:'flex', mb:'24px'}}>
                <Typography variant="h2" sx={{mx:'16px'}}>Share your product with educators </Typography>
            </Box>
            <Box sx={{flexGrow:1, display:'flex', flexDirection:'column', mb:'32px',}}>

                <Typography sx={{mx:'16px'}} variant="h5" gutterBottom>Link to product page</Typography>
                <Typography sx={{mx:'16px'}} variant='body2'>This link takes the user to your product page, where they can view your product information and click apply to start a new application for your product </Typography>
                <CopyLink sx={{mb:'16px',mx:'16px'}} link={`https://edu.intellectible.com/product?listingid=${listingId}`} onCopy={()=>onAlert('Link copied to clipboard successfully','success')}/>

                <Typography sx={{mx:'16px'}} variant="h5" gutterBottom>Link to apply directly</Typography>
                <Typography sx={{mx:'16px'}} variant='body2'>This link rederedicts the user directly to create a new grant application for your product.</Typography>
                <CopyLink sx={{mb:'16px',mx:'16px'}} link={`https://edu.intellectible.com/home?module=applications&action=new&name=${encodeURIComponent(listingData?.title)}&productid=${listingId}`} onCopy={()=>onAlert('Link copied to clipboard successfully','success')}/>

                <Typography variant="h5" sx={{mx:'16px'}} gutterBottom>Button to apply directly</Typography>
                <Typography variant='body2' sx={{mb:'8px', mx:'16px'}} >This button can be embedded into your website or email campaign to allow teachers to apply directly for this product.</Typography>        
                <Box sx={{display:'flex',gap:'8px',alignItems:'center',mb:'28px', mx:'16px'}}>
                    <div dangerouslySetInnerHTML={{__html:buttonHTML}}></div>
                    <CopyButton text={buttonHTML} onCopy={()=>onAlert('HTML copied to clipboard successfully','success')} copyText={'Copy HTML'}/>
                </Box>      

                <Typography sx={{mx:'16px'}} variant="h5" gutterBottom>Copy for email campaign</Typography>
                <Typography sx={{mx:'16px', mb:'16px'}} variant='body2'>Share your product in an email campaign. We've generated some email copy to help get you started </Typography>
                
                <Typography  sx={{mx:'16px'}}  gutterBottom>Subject line</Typography>
                <Box sx={{display:'flex',gap:'8px',alignItems:'center',mb:'16px',mx:'16px'}}>
                    <TextField
                        variant="standard"
                        value = {emailCopy?.subject ?? ''}
                        onChange = {(e)=>mainService.productListingService.writeEmailCopySubject(listingId,e.target.value)}
                        fullWidth
                    /> 
                    <CopyButton text= {emailCopy?.subject ?? ''} onCopy={()=>onAlert('Email subject copied to clipboard successfully','success')} copyText={'Copy text'}/>
                </Box>

                <Typography  sx={{mx:'16px'}} gutterBottom>Body</Typography>
                <Box sx={{display:'flex',gap:'8px',alignItems:'center',mb:'16px',mx:'16px'}}>
                    <TextField
                       multiline
                       value = {emailCopy?.body ?? ''}
                       onChange = {(e)=>mainService.productListingService.writeEmailCopyBody(listingId,e.target.value)}
                       fullWidth
                    /> 
                    <CopyButton text={emailCopy?.body ?? ''} onCopy={()=>onAlert('Email body copied to clipboard successfully','success')} copyText={'Copy text'}/>
                </Box>
 
            </Box>
        </Box>
        )

    }

    const sections = [
        {id:'listingInfo', label:'1. Listing Information', Component:ListingInfo},
        {id:'providerInfo', label:'2. Provider Information', Component:ProviderInfo},
        {id:'graphics', label:'3. Images and Media', Component:Graphics},
        {id:'pricing', label:'4. Pricing', Component:Pricing},
        {id:'eligibility', label:'5. Eligibility Information', Component:Eligibility},
        /*{id:'usecases', label:'6. Product Use cases', Component:Usecases},*/
        {id:'appContent', label:'6. Sample application content', Component:SampleAppContent},
        {id:'preview', label:'7. Page Preview', Component:Preview},
        {id:'publish', label:'8. Admin and Publishing', Component:Admin},
    ];
    if(listingData.isPublished)
        sections.push( {id:'share', label:'9. Sharing', Component:Sharing});
    let sectionSelection = sections[sectionSelectionInx]; 



    //RENDER 
    return (
    <>
        <Box sx={{position:'absolute', width:'100%', height:'100%', display:'flex', flexDirection:'column',}}>

            <Box sx={{height:'40px', mt:'20px', display:'flex', justifyContent:'center', px:'18px', /*borderBottom:`1px solid ${Theme.palette.grey[300]}`*/}}>
                
                <Box sx={{flexGrow:1, maxWidth:'1200px', display:'flex', alignItems:'center',}}>

                    <IconButton sx={{mr:'16px'}} onClick={handleListingMenuClick}>
                        <MenuIcon/>
                    </IconButton>

                    <Typography variant='h4' sx={{ml:'26px'}}>Edit your product listing</Typography> 
                    <Box sx={{flexGrow:1}}/>

                </Box>
            </Box>

            <Box sx={{display:'flex', flexGrow:1, flexDirection:'row', justifyContent:'center',}}>
            <Box sx={{display:'flex', flexGrow:1, maxWidth:'1200px', flexDirection:'column',mx:'32px', my:'32px', overflow:'hidden'}}>
               
                <Box sx={{flexGrow:1, display:'flex', flexDirection:'row'
                }}>
                    

                    <Paper sx={{width:'300px', p:'16px', mr:'12px', display:'flex', flexDirection:'column'}}>
                        <Box sx={{display:'flex', alignItems:'center', mb:'16px'}}>

                            <Typography variant="h5">Sections</Typography>
                        </Box>


                        <Box sx={{flexGrow:1, display:'flex', flexDirection:'column', overflow:'auto',}}>
                        {sections.map((section, inx)=>(

                            <ButtonBase 
                                key={inx}
                                sx={{position:'relative', display:'flex', alignItems:'center', height:'40px', justifyContent:'flex-start', 
                                    backgroundColor: sectionSelection && sectionSelection.id == section.id ? 'rgba(187, 229, 213, 0.7)' : 'default',
                                    '&:hover':{backgroundColor:'rgba(187, 229, 213, 0.7)'},
                                    p:'16px'
                                }}
                                
                                onClick={()=>handleSectionClick(inx)}
                            >
                                {sectionSelection && sectionSelection.id == section.id && <Box sx={{width:'5px', height:'100%', position:'absolute', left:0, top:0, backgroundColor:theme.palette.primary.main}}></Box>}
                                <Typography variant="body2">{section.label}</Typography>

                            </ButtonBase>

                            ))}
                        </Box>

                        <Box sx={{flexGrow:1}}></Box>

                    </Paper>

                    

                    <Paper sx={{position:'relative', flexGrow:1, ml:'12px', mr:'12px', display:'flex', flexDirection:'column', overflow:'hidden'}}>
                        <Box sx={{animation:mainAnim, opacity:mainOpacity}}>
                        {sectionSelection && sectionSelection.Component && sectionSelection.Component({listingData})}  
                        </Box>                      
                    </Paper>


                </Box>  

            </Box>
            </Box>
            
        </Box>

        <ResponsiveDialog onClose={()=>(setConfirmOpen(false))} open={confirmOpen} >
            {listingData.inReview ? (
            <React.Fragment>
                <DialogTitle>Cancel Review</DialogTitle>
                <DialogContent>
                Your draft is currently in review for live publication, would you like to stop this process and return to draft? 
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>(setConfirmOpen(false))}variant="outlined">
                        Cancel
                    </Button>
                    <Button 
                        variant="contained" 
                        sx={{color:"white"}}
                        onClick = {()=>{handleSetReview(false)}}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </React.Fragment>
            ):(
            <React.Fragment>
                <DialogTitle>Publish your product</DialogTitle>
                <DialogContent>
                After clicking confirm your draft will go to our team for review before going live. Once your product is live it will be publicly available so make sure you are happy with everything! 
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>(setConfirmOpen(false))}variant="outlined">
                        Cancel
                    </Button>
                    <Button 
                        variant="contained" 
                        sx={{color:"white"}}
                        onClick = {()=>{handleSetReview(true)}}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </React.Fragment>
            )}
        </ResponsiveDialog>


        <ResponsiveDialog onClose={handleUploadDialogClose} open={uploadDialogOpen} >
            <DialogTitle>Upload an image</DialogTitle>
            <DialogContent>
                <Box sx={{width: "450px"}}>
                    <FileDragAndDrop
                        sx={{width:'100%', height:'200px'}}
                        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                        maxFileSize={3000000}
                        filesLimit={maxImgFiles}
                        dropzoneText='drop images here, or click'
                        onChange={(imgFiles)=>{
                            if(maxImgFiles == 1)
                                setImgFile(imgFiles[0]);
                            else if(maxImgFiles > 1)
                                setImgFile(imgFiles); 
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button  onClick={()=>(setUploadDialogOpen(false))} variant="outlined">
                    Cancel
                </Button>
                <Button 
                    disabled={!imgFile} 
                    variant="contained" 
                    sx={{color:"white"}}
                    onClick = {handleUploadImage}
                >
                    Upload
                </Button>
            </DialogActions>
        </ResponsiveDialog>

        <YesNoDialog
            open={deleteDialogOpen}
            onYes={handleDeleteListing}
            onNo={handleDeleteDialogClose}
            text="Are you sure you want to delete this listing? This can't be undone!"
        />

    </>
    )
}

export default ProductListingEditor;