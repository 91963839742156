import React, {useState, useEffect, useContext } from "react";

import MainContext from "../../MainContext"; 

import { Box, Typography, Button, DialogTitle, DialogContent, DialogActions, CircularProgress} from "@mui/material";

import TextFormInput from "../../components/formInputs/TextFormInput";
import WebsiteFormInput from "../../components/formInputs/WebsiteFormInput";
import FormTitle from "../../components/formInputs/FormTitle";

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import FileDragAndDrop from '../../components/FileDragAndDrop';
import StyledButton from "../../components/StyledButton";

import Theme from '../../Theme'

import { WorkQueue } from "../../Util";
import {isURL} from 'validator';
import { API_URL } from "../../Config"

const workQueue = new WorkQueue(); 

const OrgNameInput = ({formData,setDisableContinueButton})=>{
    
    const mainService = useContext(MainContext);

    const [uploadDialogOpen,setUploadDialogOpen] = useState(false);
    const [imgFile,setImgFile] = useState(null);
    const [imgLoading,setImgLoading] = useState(false);
    
    const handleOrgNameChange = (val)=>{
        mainService.profileService.setOrgName(val); 
    }

    const handleGetLogo = async (v) => {
        console.log(v)
        workQueue.cancelAll();
        
        //if no val
        if(!v || !isURL(v)){   
            console.log('not url or empty')
            mainService.profileService.setOrgWebsite(v);
            return
        }

        let url = null;
        try {
            url = new URL(v);
        }
        catch(error){
            console.log('failed to construct url');
        }
        if(!url){
            try {
                url = new URL('https://'+v);
            }
            catch(error){
                console.log('failed to construct url');
            }
        }

        if(!url){
            mainService.profileService.setOrgWebsite(v);
            return
        }

        
        const stringurl = url.toString(); 
        mainService.profileService.setOrgWebsite(v);
        console.log(stringurl)

        console.log('is url, attempting to retrieve logo');
        setImgLoading(true);
        workQueue.push(async (job) => {
            try{
                const token = await mainService.getUserToken();
                const res = await fetch(API_URL + `/getlogo?` + new URLSearchParams({
                    url:stringurl
                }),{
                    headers: {
                      Authorization: `${token}`
                    }
                })
                //get content type
                const contentType = res.headers.get("content-type");
                const isJson = contentType && contentType.indexOf("application/json") !== -1;
                if(isJson){
                    console.log('response is json')
                    const data = await res.json();
                    await mainService.profileService.deleteLogo(userObject);
                    throw data.message;
                }
                else{
                    const data = await res.blob();
                    if(data){                
                        console.log("success")
                        await mainService.profileService.uploadLogo(userObject,data)
                    }
                }
                setImgLoading(false);
            }
            catch(error){
                console.error(error);
                mainService.profileService.setOrgWebsite(v);
                setImgLoading(false);
                return
            }
        })
       
    }
    const handleUploadDialogClose = () => {
        setUploadDialogOpen(false);
    }

    const handleUploadImage = async () => {
        setUploadDialogOpen(false);
        setImgLoading(true);
        if(imgFile){
            await mainService.profileService.uploadLogo(userObject,imgFile)           
        }
        setImgLoading(false);
    }

    let userObject = formData.userObject;

    useEffect(()=>{
        let disableButton = !(userObject && userObject.orgname && userObject.orgwebsite); 
        if(setDisableContinueButton)
            setDisableContinueButton(disableButton); 
    }, [formData]);


    return(
    <React.Fragment>

        <Box sx={{flexGrow:1, maxWidth:'510px', height:'100%', display:'flex', alignItems:'stretch', flexDirection:'column',}}>
            <Box sx={{height:'10%'}}></Box>
            
            <FormTitle text='Tell us about your organization'/>
            <Box sx={{display:'flex', alignItems:'stretch', flexDirection:'column' }}>
                <TextFormInput 
                    value={userObject.orgname ? userObject.orgname : ''}
                    onChange={handleOrgNameChange}
                    title={'What is the name of your organization?'}
                /> 
                
                <WebsiteFormInput 
                    value={userObject.orgwebsite ? userObject.orgwebsite : ''}
                    onChange={handleGetLogo}
                    title={'What is your website?'}
                /> 

                <Typography sx={{mx:'16px',mt:'32px',mb:'16px'}} variant='body1' align='left'>Add your company logo</Typography>
                <Box sx={{display:'flex',flexDirection:'column',gap:'8px',mx:'16px', alignItems:'flex-start'}}>
                    <Box sx={{p:'4px',width:'64px',height:'64px',borderRadius:'8px',position:'relative',border:`1px solid ${Theme.palette.grey[400]}`}}>
                        {imgLoading ? (
                        <Box sx={{position:'relative',width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <CircularProgress size={28} />
                        </Box>
                        ) : (
                        <Box sx={{position:'relative',width:'100%',height:'100%'}}>
                            <img style={{position:'absolute',width:'100%',height:'100%', objectFit:'cover'}} src={userObject?.logoURL} referrerPolicy='no-referrer' />
                        </Box>
                        )}


                    </Box>
                    <StyledButton 
                        sx={{mt:'8px',mb:'16px'}}  
                        text='Upload image' 
                        icon={()=><CloudUploadIcon sx={{ml:'8px', color:'#ffffff'}}/>} 
                        onClick={()=>setUploadDialogOpen(true)}
                    />
                </Box>
            </Box>


        </Box>
        <ResponsiveDialog onClose={handleUploadDialogClose} open={uploadDialogOpen} >
            <DialogTitle>Upload an image</DialogTitle>
            <DialogContent>
                <Box sx={{width: "450px"}}>
                    <FileDragAndDrop
                        sx={{width:'100%', height:'200px'}}
                        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                        maxFileSize={3000000}
                        filesLimit={1}
                        dropzoneText='drop logo pic here, or click'
                        onChange={(imgFiles)=>setImgFile(imgFiles[0])}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button  onClick={()=>(setUploadDialogOpen(false))} variant="outlined">
                    Cancel
                </Button>
                <Button 
                    variant="contained" 
                    sx={{color:"white"}}
                    onClick = {handleUploadImage}
                    disabled={imgLoading}
                >
                    Upload
                </Button>
            </DialogActions>
        </ResponsiveDialog>
    </React.Fragment>
    )
}

export default OrgNameInput; 