import React, {useState, useEffect, useContext } from "react";
import { Menu, MenuItem, Avatar, ListItemIcon, Divider } from '@mui/material';

const ItemMenu = ({open, handleClose, anchorEl,onDelete, actions, transformOrigin, anchorOrigin})=>{
    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 1px 6px rgba(43,43,43,0.05))',
                border:'1px solid #EBEFF2',
                mt: 1.5,
                '& .MuiAvatar-root': {width: 32, height: 32, ml: -0.5, mr: 1,},
                '&:before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', 
                              borderTop:'1px solid #EBEFF2', borderLeft:'1px solid #EBEFF2', borderTop:'1px solid #EBEFF2', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0,},
                },
            }}
            transformOrigin={transformOrigin ? transformOrigin : { horizontal: 'right', vertical: 'top' }}
            anchorOrigin={ anchorOrigin ? anchorOrigin : { horizontal: 'right', vertical: 'bottom' }}
        >
            {actions && (actions.map((action)=>(
                <MenuItem key={action.name} onClick={ ()=>{if(action.action) action.action()} } >
                    {action.name}
                </MenuItem>
                ))
            )}

            {onDelete &&
            <MenuItem onClick={ ()=>{if(onDelete) onDelete();} } >
                Delete
            </MenuItem>}  


        </Menu>
    )
}

export default ItemMenu; 