import React, {useState, useEffect, useContext } from "react";

import { Box, Typography, MenuItem, Select, TextField  } from "@mui/material";

const singleOptions = ['day','week','month','year'];
const pluralOptions = ['days','weeks','months','years'];

const DurationFormInput = ({value, title, onChange, sx})=>{

    const [amount,setAmount] = useState('1');
    const [option,setOption] = useState('');
    const [options,setOptions] = useState(singleOptions);
        
    const handleChangeAmount = (val)=>{

        const newVal = val < 0 ? '0 ' + option : val.toString() + ' ' + option;
        onChange(newVal); 
    }

    const handleChangeOption = (val)=> {
        const newVal = amount + ' ' + val;
        onChange(newVal);
    }

    useEffect(()=>{
        if(value){
            const [a,o] = value.split(' ');
            setAmount(a);
            let newOp = o;          
            if(a==='1'){
                newOp = pluralOptions.includes(o) ? singleOptions[pluralOptions.indexOf(o)] : newOp;
                setOptions(singleOptions);
            }                         
            else{
                newOp = singleOptions.includes(o) ? pluralOptions[singleOptions.indexOf(o)] : newOp;
                setOptions(pluralOptions);
            }        
            setOption(newOp);
        }
    },[value])

    return(
    <React.Fragment>
        <Typography align='left' sx={{mt:'16px', mx:'16px'}}>{title}</Typography>
        <Box sx={{my:'16px', ml:'16px', display:'flex'}}>
        <TextField 
            value={amount}
            type='number'
            onChange={(e)=>handleChangeAmount(e.target.value)}
        />
        <Select
            fullWidth
            sx={{mb:'16px', mx:'16px', ...sx }}
            value={option}
            onChange={(e)=>handleChangeOption(e.target.value)}
        >
            {options && options.map((o)=>(
                <MenuItem key={o} value={o}>{o}</MenuItem>
            ))}
        </Select>
        </Box>

    </React.Fragment>
    )
}

export default DurationFormInput;