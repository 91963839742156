import React, {useState, useEffect, useContext } from "react";

import Theme from '../../Theme';

import MainContext from "../../MainContext"; 

import { ThemeProvider } from  '@mui/system';
import { Box, Typography, Table, TableBody, TableHead, TableRow, TableContainer, IconButton, TableCell, TextField, Collapse, InputAdornment, ButtonBase} from "@mui/material";
import TouchRipple from '@mui/material/ButtonBase/TouchRipple';

import { styled } from '@mui/material/styles';

import Paper from "../../components/Paper";

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SettingsIcon from '@mui/icons-material/Settings';
import EditIcon from '@mui/icons-material/Edit';

import StyledTooltip from "../../components/StyledTooltip";
import BudgetGeneratorForm from "./BudgetGeneratorForm";
import ExpandMoreButton from "../../components/ExpandMoreButton";
import SimpleAutocomplete from "../../components/formInputs/SimpleAutocomplete";
import ItemMenu from "../../components/ItemMenu";


import { unitOptions, getFormattedNumberString, getCurrencyAdornment } from "../../Util";

const colWidths = {
    md:{
        item:250,
        description:350,
        total:100,
        options:30
    },
    xs:{}
}

const UnStyledTableCell = styled(TableCell)(({}) => ({
    padding:'0px',
    margin:'0px',
}));

const UnStyledTableRow = styled(TableRow)(({}) => ({
    padding:'0px',
    margin:'0px',
}));

const newLineData = {
    item:'',
    quantity:0,
    unitPrice:0,
    unitType:'unit', //TODO add options to PP
    description:'0 x $0/unit',
    total: 0
};

const EditableTableCell = ({currency,lineData,align,onValueChange,name}) => {
    const [editMode,setEditMode] = useState(false);

    const rippleRef = React.useRef(null);
    const onRippleStart = (e) => {
        console.log('rippling')
        rippleRef.current.start(e);
    };
    const onRippleStop = (e) => {
      rippleRef.current.stop(e);
    };

    return (
        <TableCell 
            onMouseDown={!editMode ? onRippleStart : null}
            onMouseUp={!editMode ? onRippleStop : null}
            onClick={!editMode ? ()=>setEditMode(true) : null}
            sx={{borderRight:`solid 1px ${Theme.palette.grey[300]}`,height:'50px',width:`${colWidths.md[name]}px`,position:'relative'}} 
            align={align} 
            scope="row"
            size='small'
        >
        {editMode ? 
            (<Box sx={{display:'flex',alignItems:'flex-end', justifyContent:align=='right'? "flex-end":null, gap:'8px'}}>             
                {name == 'description' ?
                (<React.Fragment>
                    <TextField 
                        sx={{}}
                        value={lineData['quantity'] ?? ''}
                        onChange={(e)=>onValueChange(e.target.value,lineData['unitPrice'],lineData['unitType'])}
                        variant='standard'
                        inputProps={{style: {fontSize: '13px', color:Theme.typography.body2.color,textAlign:align}}}
                        type='number'
                    />
                    <Typography variant='body2'>x</Typography>
                    <TextField 
                        sx={{}}
                        value={lineData['unitPrice'] ?? ''}
                        onChange={(e)=>onValueChange(lineData['quantity'],e.target.value,lineData['unitType'])}
                        variant='standard'
                        InputProps={{startAdornment: <InputAdornment position="start">{getCurrencyAdornment(currency ?? null)}</InputAdornment>}}
                        inputProps={{style: {fontSize: '13px', color:Theme.typography.body2.color,textAlign:align}}}
                        type='number'
                    />
                    <Typography variant='body2'>per</Typography>
                    <SimpleAutocomplete  
                        small
                        sx={{width:`${Math.floor(colWidths.md.description/2)}px`,
                        display:'inline-flex',
                        ' .MuiInputBase-input': {
                            height: '1rem'
                          }
                        }}
                        value={lineData['unitType'] ?? ''}
                        options={unitOptions} 
                        valueName="name" 
                        labelName="name" 
                        onChange={(v)=>onValueChange(lineData['quantity'],lineData['unitPrice'],v)}
                        variant='standard'
                        freeSolo
                    />
                    <IconButton onClick={()=>setEditMode(false)} size='small' sx={{height:'24px',width:'24px'}}><DoneIcon/></IconButton> 
                </React.Fragment>
                ):(
                <React.Fragment>
                <TextField 
                    sx={{flexGrow:1}}
                    value={lineData && lineData[name] ? lineData[name] : ''}
                    onChange={(e)=>onValueChange(name,e.target.value)}
                    variant='standard'
                    inputProps={{style: {fontSize: '13px', color:Theme.typography.body2.color,textAlign:align}}}
                />
                    <IconButton onClick={()=>setEditMode(false)} size='small' sx={{height:'24px',width:'24px'}}><DoneIcon/></IconButton> 
                </React.Fragment>
                )}

            </Box>) : (
                <Typography align={align} variant='body2'>{lineData && lineData[name] ? lineData[name] : ''}</Typography>
            )}
            {!editMode &&
            (<Box sx={{position:'absolute',width:'100%',height:'100%', right:0,top:0}}>
                <TouchRipple ref={rippleRef && rippleRef} center={false} sx={{width:'100%'}}/>
            </Box>)}
        </TableCell> 
    )

}

const SpanRow = ({appId, sectionId, title,expand,onClickExpand,onDeleteSection,onAddNewLine,onAddSectionAbove,onAddSectionBelow,total, currency}) => {
    
    const mainService = useContext(MainContext);
    const [itemMenuOpen,setItemMenuOpen] = useState(false);
    const [itemMenuAnchorEl,setItemMenuAnchorEl] = useState(null);

    //HANDLERS
    const handleThreeDotsClick = (e)=>{
        setItemMenuAnchorEl(e.currentTarget);
        setItemMenuOpen(true);
    }

    const handleThreeDotsClose = (e)=>{
        setItemMenuOpen(false);
    }

    return (
        <React.Fragment>
        <TableRow >
            <TableCell sx={{bgcolor:Theme.palette.primary.light,px:'8px', py:'0px'}} align="left" colSpan={3} size='small'>
                <Box sx={{display:'flex', alignItems:'center'}}>
                    <ExpandMoreButton onClick={onClickExpand} expand={expand}>
                        <ExpandMoreIcon/>
                    </ExpandMoreButton>
                    <TextField 
                        placeholder="Give your section a name..."
                        variant="standard" 
                        value={title}
                        onChange={(e)=>mainService.applicationService.setSectionValue(appId,sectionId,"name",e.target.value)} 
                        InputProps={{disableUnderline:true,sx:{...Theme.typography.h5,'&:hover':{bgcolor:Theme.palette.grey[100]},borderRadius:'5px'}}}
                    />
                    {/*<Typography variant='h5'>{title}</Typography>*/}
                    <Box sx={{flexGrow:1}}/>

                </Box>
            </TableCell>
            <UnStyledTableCell align='center' sx={{bgcolor:Theme.palette.primary.light}}>
                <Box sx={{display:'flex',justifyContent:'flex-end'}}>
                {expand ?
                (<IconButton 
                    size='small' 
                    sx={{height:'24px',width:'24px'}}
                    onClick={handleThreeDotsClick}
                >
                    <MoreVertIcon/>
                </IconButton>):(
                <Typography variant='h6' sx={{pr:'16px'}}>{getCurrencyAdornment(currency ?? null)}{total && getFormattedNumberString(total)}</Typography>
                )}
                </Box>
            </UnStyledTableCell>
        </TableRow>  
        <ItemMenu 
            open={itemMenuOpen} 
            anchorEl={itemMenuAnchorEl}
            handleClose={handleThreeDotsClose}
            actions={[{name:'Add section above',action:onAddSectionAbove},{name:'Add section below',action:onAddSectionBelow},{name:'Add row', action:onAddNewLine},{name:'Delete section', action:onDeleteSection}]}
        />
        </React.Fragment>     
    )
}

const BudgetLineRow = ({currency,lineData,appId,budgetLineId,budgetSectionId,sections,sectionTotal,grandTotal,onNewLineAbove,onNewLineBelow}) => {
    const mainService = useContext(MainContext);
    const [itemMenuAnchorEl, setItemMenuAnchorEl] = useState(null);
    const [itemMenuOpen, setItemMenuOpen] = useState(false);

    //HANDLERS
    const handleThreeDotsClick = (e)=>{
        setItemMenuAnchorEl(e.currentTarget);
        setItemMenuOpen(true);
    }

    const handleThreeDotsClose = (e)=>{
        setItemMenuOpen(false);
    }
    
    return (
        <React.Fragment>
        <UnStyledTableRow
            sx= {{'&:hover':{bgcolor:Theme.palette.grey[100]}, position:'relative',height:'30px'}}
        >
            <EditableTableCell 
                currency={currency}
                align='left' 
                name='item' 
                lineData={lineData} 
                onValueChange={(n,v)=>mainService.applicationService.setBudgetLineValue(appId,budgetLineId,n,v)}
            />
            <EditableTableCell 
                currency={currency}
                align='left' 
                name='description' 
                lineData={lineData} 
                onValueChange={(q,up,ut)=>mainService.applicationService.setBudgetValueAndDescription(appId,budgetLineId,q,currency,up,ut,budgetSectionId,lineData.total,sectionTotal,grandTotal)}
            />
            <TableCell size = 'small' align='right' sx={{height:'50px',width:`${colWidths.md.total}px`,px:'0px',py:'0px'}} >
                <Typography align='right' variant='body2'>{getCurrencyAdornment(currency ?? null)}{lineData?.total && getFormattedNumberString(lineData.total)}</Typography>
            </TableCell>

            <UnStyledTableCell align='center' sx={{height:'50px',width:`${colWidths.md.options}px`}}>
                <Box sx={{display:'flex',justifyContent:'flex-end'}}>
                    <ButtonBase sx={{borderRadius:'50px'}} onClick={handleThreeDotsClick}>
                        <MoreVertIcon sx={{color:'#5f748d'}}/>
                    </ButtonBase>       
                </Box>       
            </UnStyledTableCell>

        </UnStyledTableRow>
        <ItemMenu 
            open={itemMenuOpen} 
            anchorEl={itemMenuAnchorEl}
            handleClose={handleThreeDotsClose}
            actions={[{name:'Add row above', action:onNewLineAbove},{name:'Add row below',action:onNewLineBelow}]}
            onDelete={()=>mainService.applicationService.removeBudgetLine(appId,budgetLineId,sections,budgetSectionId,lineData.total,sectionTotal,grandTotal)}
        />
        </React.Fragment>        
        )

}

const GrandTotalRow = ({total,currency}) => {
    return (
        <UnStyledTableRow sx={{}}>
            <TableCell sx={{bgcolor:Theme.palette.grey[300], borderRight:`solid 1px ${Theme.palette.grey[300]}`,py:'0px'}} align="left">
                <Typography variant='h5'>Total:</Typography>
            </TableCell>
            <TableCell align="right" colSpan={3} sx={{py:'12px'}}>
                <Typography variant='h5'>{getCurrencyAdornment(currency ?? null)}{total && getFormattedNumberString(total)}</Typography>
            </TableCell>
        </UnStyledTableRow>       
    )
}

const Budget = ({userObject, applicationMetadata, budgetSummary, budgetSections, budgetLines, appId, productData}) => {

    const mainService = useContext(MainContext);

    const [settingsMenuOpen,setSettingsMenuOpen] = useState(false);
    const [settingsMenuAnchorEl, setSettingsMenuAnchorEl] = useState(null);

    const handleAddNewLine = (budgetSectionId) => {
        mainService.applicationService.addBudgetLine(appId,budgetSections,budgetSectionId,budgetSummary['currency'] ?? 'USD')
    }

    const handleAddNewLineAtPosition = (budgetSectionId,pos) =>{
        mainService.applicationService.addBudgetLine(appId,budgetSections,budgetSectionId,budgetSummary['currency'] ?? 'USD','',pos);
    }

    const handleAddSectionAtPosition = (pos) => {
        mainService.applicationService.addBudgetSection(appId,budgetSummary.sections,pos)
    }

    const handleDeleteSection = (budgetSectionId,sectionTotal,sectionLines) => {
        //(appId,sectionIds,sectionId,sectionTotal,sectionLines,grandTotal)
        mainService.applicationService.removeBudgetSection(appId,budgetSummary.sections,budgetSectionId,sectionTotal,sectionLines,budgetSummary.total);
    }

    const handleOpenSettingsMenu = (e) => {
        setSettingsMenuAnchorEl(e.currentTarget);
        setSettingsMenuOpen(true);
    }

    const handleSettingsMenuClose = () => {
        setSettingsMenuOpen(false);
    }

    const handleDownloadCsv = () => {
        //add headers
        let rows = [
            ["Budget item", "Description", "Total cost"]
        ]
        //add all budget rows
        for(const sId of budgetSummary?.sections ?? []){           
            const section = budgetSections[sId]
            rows.push([section.name,'','']);
            for(const lId of section?.lines ?? []){
                const line = budgetLines[lId];
                rows.push([line.item,line.description,line.total]);
            }
        }
        //add grand total
        rows.push(['Total','',budgetSummary.total]);
       
        let csvContent = "data:text/csv;charset=utf-8," 
                + rows.map(e => e.join(",")).join('\n');
        
        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "project_budget.csv");
        document.body.appendChild(link); // Required for FF
        
        link.click();
        link.remove();
    }

    useEffect(()=>{
        
    }, [appId])

    //RENDER
    return (
    <ThemeProvider theme={Theme}>
        
        <Paper sx={{flexGrow:1, display:'flex', flexDirection:'column', overflow:'hidden'}}>
            
            <Box sx={{px:'16px', pt:'16px',pb:'8px', display:'flex', flexDirection:'row', alignItems:'center', borderBottom:`1px solid ${Theme.palette.grey[300]}`  }}>
                <Typography gutterBottom variant="h2">Project budget</Typography>
                <Box sx={{flexGrow:1}}/>
                <StyledTooltip arrow title={<Typography variant='body2'>Download as csv</Typography>}>
                    <IconButton onClick={handleDownloadCsv}><FileDownloadIcon/></IconButton>
                </StyledTooltip>
            </Box>

            <Typography sx={{pt:'16px',px:'16px'}}>
                Here is your project budget. Click into the cells to edit the details. Click the three dots menus to add and remove more lines and sections to your budget. Your application document will update automatically with the changes.You can also download the budget sheet as a csv file by clicking the download icon on top right.
            </Typography>

            <TableContainer sx={{flexGrow:1,position:'relative',overflow:'hidden'}}>
            <Box sx={{position:'absolute',width:'100%',height:'100%',overflow:'overlay',p:'16px',}}>
            <Table sx={{ minWidth: 700, border:`1px solid ${Theme.palette.grey[300]}`}} aria-label="funding table" stickyHeader >
                <TableHead>
                <TableRow sx={{bgcolor:Theme.palette.primary.main}}>
                    <TableCell 
                        sx={{py:'12px',bgcolor:Theme.palette.primary.main,borderRight:`solid 1px ${Theme.palette.grey[300]}`,zIndex:1,width:`${colWidths.md.item}px`}} 
                        align='left'
                    >
                        <Typography  sx={{color:'white'}} variant='h3'>Budget Item</Typography>
                    </TableCell>
                    <TableCell 
                        sx={{py:'12px',bgcolor:Theme.palette.primary.main,borderRight:`solid 1px ${Theme.palette.grey[300]}`,zIndex:1,width:`${colWidths.md.description}px`}} 
                        align='left'
                    >
                        <Typography  sx={{color:'white'}} variant='h3'>Description</Typography>
                    </TableCell>
                    <TableCell 
                        sx={{py:'12px',bgcolor:Theme.palette.primary.main,width:`${colWidths.md.total}px`,zIndex:1}} 
                        align='right'
                    >
                        <Typography sx={{color:'white'}} variant='h3'>Total cost</Typography>
                    </TableCell>

                    <UnStyledTableCell align='center' sx={{bgcolor:Theme.palette.primary.main,width:`${colWidths.md.options}px`,zIndex:1}} >
                        <Box sx={{display:'flex',justifyContent:'flex-end'}}>
                            <StyledTooltip arrow title={<Typography variant='body2'>Add new section</Typography>}>
                                <ButtonBase onClick={handleOpenSettingsMenu} sx={{mr:'8px', borderRadius:'50px'}}>
                                    <AddIcon sx={{color:'white'}}/>
                                </ButtonBase>   
                            </StyledTooltip> 
                        </Box>                    
                    </UnStyledTableCell>
                </TableRow>
                </TableHead>
                <TableBody >

                    {budgetSummary?.sections && budgetSections && budgetSummary.sections.map((sId,i)=> (
                        <React.Fragment>
                            <SpanRow 
                                currency={budgetSummary?.currency ?? 'USD'}
                                title = {budgetSections[sId]?.name} 
                                key={sId} 
                                expand={budgetSections[sId]?.open}
                                onClickExpand={()=>mainService.applicationService.setSectionValue(appId,sId,'open',!budgetSections[sId]?.open)}
                                total={budgetSections[sId]?.total}
                                onDeleteSection={()=>handleDeleteSection(sId,budgetSections[sId].total,budgetSections[sId].lines)}
                                onAddNewLine={()=>handleAddNewLine(sId)}
                                onAddSectionAbove={()=>handleAddSectionAtPosition(i)}
                                onAddSectionBelow={()=>handleAddSectionAtPosition(i+1)}
                                appId={appId}
                                sectionId={sId}

                            />
                            <TableRow>
                            <UnStyledTableCell colSpan={4} size='small'>
                            <Collapse in={budgetSections[sId]?.open } timeout="auto" unmountOnExit>
                            <Table aria-label={`budget-lines-${sId}`} size='small'>
                            {budgetSections[sId]?.lines && budgetLines && budgetSections[sId]?.lines.map((lId,i)=>{
                                return (                                       
                                    <BudgetLineRow 
                                        key={lId}
                                        currency={budgetSummary?.currency ?? 'USD'}
                                        lineData={budgetLines[lId]} 
                                        appId={appId}
                                        budgetLineId={lId}
                                        budgetSectionId={sId}
                                        sectionTotal={budgetSections[sId].total}
                                        grandTotal={budgetSummary.total}
                                        sections={budgetSections}
                                        onNewLineAbove={()=>handleAddNewLineAtPosition(sId,i)}
                                        onNewLineBelow={()=>handleAddNewLineAtPosition(sId,i+1)}
                                    />
                                )                               
                            })}
                            </Table>
                            </Collapse>
                            </UnStyledTableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}

                    <GrandTotalRow total={budgetSummary?.total} currency={budgetSummary?.currency ?? 'USD'}/>
                                
                </TableBody>
            </Table>
            </Box>
            </TableContainer>            
        </Paper>
        <ItemMenu 
            open={settingsMenuOpen} 
            anchorEl={settingsMenuAnchorEl}
            handleClose={handleSettingsMenuClose}
            actions={[{name:'Add section', action:()=>mainService.applicationService.addBudgetSection(appId,budgetSummary.sections)}]}
        />
    </ThemeProvider>
    )
}

export default Budget; 