import React, {useState, useEffect, useContext } from "react";

import { Box, Typography, MenuItem, Select,  } from "@mui/material";

const SelectFormInput = ({value, title, options, onChange, sx})=>{
        
    const handleChange = (val)=>{
        onChange(val); 
    }

    return(
    <React.Fragment>
        <Typography align='left' sx={{my:'16px', mx:'16px'}}>{title}</Typography>
        <Select
            sx={{mb:'16px', mx:'16px', ...sx }}
            value={value}
            onChange={(e)=>handleChange(e.target.value)}
        >
            {options.map((v)=>(
                <MenuItem key={v.code} value={v.code}>{v.name}</MenuItem>
            ))}
        </Select>
    </React.Fragment>
    )
}

export default SelectFormInput;