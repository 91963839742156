import React, {useState, useEffect, useContext } from "react";
import { Menu, MenuItem, Avatar, ListItemIcon, Divider } from '@mui/material';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';

const AccountMenu = ({open, handleClose, anchorEl, handleLogout, handleMyProfile, handleAccountSettings, dashboard, handleDashboard})=>{
    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 1px 6px rgba(43,43,43,0.05))',
                border:'1px solid #EBEFF2',
                mt: 1.5,
                '& .MuiAvatar-root': {width: 32, height: 32, ml: -0.5, mr: 1,},
                '&:before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', 
                              borderTop:'1px solid #EBEFF2', borderLeft:'1px solid #EBEFF2', borderTop:'1px solid #EBEFF2', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0,},
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            {dashboard &&
            
            <MenuItem onClick={ ()=>{if(handleDashboard) handleDashboard();} } >
                <ListItemIcon>
                <DashboardIcon /> 
                </ListItemIcon>
                Dashboard
            </MenuItem>           
            
            }

            <MenuItem onClick={ ()=>{if(handleMyProfile) handleMyProfile();} } >
                <ListItemIcon>
                <AccountCircle/> 
                </ListItemIcon>
                My profile
            </MenuItem>
            
            {/*
            <MenuItem onClick={ ()=>{if(handleAccountSettings) handleAccountSettings();} } >
                <ListItemIcon>
                <SettingsIcon/> 
                </ListItemIcon>
                Account Settings
            </MenuItem>
            */}
            
            <MenuItem onClick={ ()=>{if(handleLogout) handleLogout();} } >
                <ListItemIcon>
                <Logout fontSize="small" />
                </ListItemIcon>
                Logout
            </MenuItem>
        </Menu>
    )
}

export default AccountMenu; 