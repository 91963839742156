import React, {useState, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";

import theme from '../Theme';

import MainContext from "../MainContext"; 

import { ThemeProvider } from  '@mui/system';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LoadingBackdrop from "../components/LoadingBackdrop";

import ProductListingEditor from "./productListings/ProductListingEditor";
import ProductListingsMenu from "./productListings/ProductListingsMenu"; 
import ProductListingCreationForm from "./productListings/ProductListingCreationForm";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ProductListings = ({userObject}) => {

    const [searchParams, setSearchParams] = useSearchParams()

    const mainService = useContext(MainContext);
    
    //STATE
    const [loading,setLoading] = useState(false);
    const [listingsMenuOpen, setListingsMenuOpen] = useState(true); 
    const [currentListingId, setCurrentListingId] = useState("");
    const [currentListingCreationStage,setCurrentListingCreationStage] = useState('');
    const [currentListingData,setCurrentListingData] = useState({});
    const [productCollectionData,setProductCollectionData] = useState(null);
    const [productCollectionId,setProductCollectionId] = useState(null);

    const [alertOpen,setAlertOpen] = useState(false);
    const [alertMsg,setAlertMsg] = useState("");
    const [alertSeverity,setAlertSeverity] = useState("success");


    //HANDLERS
    const handleCreateListing = async ()=>{

        if(loading)
            return; 
        
        handleSetLoading(true); 
        const newKey = await mainService.productListingService.createNewProductListing(userObject); 
        handleListingSelect(newKey,'flow'); 
        handleSetLoading(false); 
    }

    const handleListingsMenuClose = ()=>{
        setListingsMenuOpen(false);
    }

    const handleListingSelect = (listingId,creationStage) => {
        setListingsMenuOpen(false);
        setCurrentListingId(listingId); 
        setCurrentListingCreationStage(creationStage);
        console.log("handle listing select: " + listingId);
    }

    const handleListingMenuClick = ()=>{
        setListingsMenuOpen(true); 
    }

    const handleListingDeleted = ()=>{
        setListingsMenuOpen(true); 
    }

    const handleSetAlert = (msg,severity="success") => {
        if(msg){
            setAlertMsg(msg);
            setAlertSeverity(severity);
            setAlertOpen(true);
        }
    }

    const handleSetLoading = (loading) => {
        setLoading(loading)
    }

    const handlePublishDone = () => {
        setListingsMenuOpen(true);
        setAlertOpen(true);
        setAlertMsg("Product listing published successfully!");
        setAlertSeverity("success");
    }

    const handleFinishFlow = () => {
        console.log('finishing flow')
        mainService.productListingService.writeListingCreationStage(currentListingId,'editor');
        setCurrentListingCreationStage('editor');
    }

    const listenUserProductCollectionRelationships = async (data) => {
        console.log('data',data)
        if(data){
            let collid = Object.keys(data)[0];
            console.log('collid',collid);
            setProductCollectionId(collid)
        }
    }

    //handle query 
    useEffect(()=>{
        setLoading(true); 

        if(userObject.accountstarted && userObject.type === "thirdparty"){
            
            if(searchParams.get("module") === "products" && searchParams.get("action")==='new' && !currentListingId){
                searchParams.delete('module'); 
                searchParams.delete('action'); 
                setSearchParams(searchParams); 
                setListingsMenuOpen(false);
                handleCreateListing(); 
            }
            else if (searchParams.get("module") === "applications" && searchParams.get("action")==='edit' && searchParams.get('productid')){
                const productid = searchParams.get('productid');
                searchParams.delete('module'); 
                searchParams.delete('action'); 
                searchParams.delete('productid'); 
                setSearchParams(searchParams); 
                handleListingSelect(productid);
                setLoading(false);
            }
            else{
                setLoading(false); 
            }
        }

        if(userObject){
            //get collection id
            console.log('hey')
            mainService.productListingService.addUserProductCollectionRelationshipListener(listenUserProductCollectionRelationships);
        }

        return(()=>{
            if(userObject){
                mainService.productListingService.removeUserProductCollectionRelationshipListener(listenUserProductCollectionRelationships);
            }
        })
        

    }, [userObject]);

    const listenProductCollection = (data) => {
        console.log('collectionData',JSON.stringify(data))
        setProductCollectionData(data)
    }

    useEffect(()=>{
        if(productCollectionId){
            console.log('adding product collection listener');
            mainService.productListingService.addProductCollectionListener(productCollectionId,listenProductCollection);
        }
        return (()=>{
            if(productCollectionId){
                mainService.productListingService.removeProductCollectionListener(productCollectionId,listenProductCollection);
            }
        })

    },[productCollectionId])
        
    const listenListingChanged= async (data)=>{
        if(!data){
            return; 
        }       
        if(currentListingId){
            setCurrentListingData({...data}); 
        }       
    }
    
       
    useEffect(() => {
        //on mount
        console.log('Product listings:' + currentListingId); 
        
        if(currentListingId){
            mainService.productListingService.addListingListener(currentListingId, listenListingChanged );
            //setSectionSelectionInx(0);
        }

        //on unmount
        return ()=>{
            console.log('Product Listings unmounted' + currentListingId);
            if(currentListingId){
                mainService.productListingService.removeListingListener(currentListingId, listenListingChanged );
            }
        }
    }, [currentListingId]);

    //RENDER
    return (
    <ThemeProvider theme={theme}>
        
        <LoadingBackdrop open={loading}/>

        <ProductListingsMenu 
            open={listingsMenuOpen} 
            onClose={handleListingsMenuClose} 
            onListingSelect={handleListingSelect} 
            onCreateListing={handleCreateListing}
            collectionId={productCollectionId}
            collectionData={productCollectionData}
            onLoading={handleSetLoading}
            onAlert={handleSetAlert} 
            userObject={userObject}
        />

        {currentListingCreationStage === 'flow' ? (
        <ProductListingCreationForm 
            onListingMenuClick={handleListingMenuClick} 
            productData={currentListingData}
            userObject={userObject} 
            listingId={currentListingId} 
            onFinish={handleFinishFlow}
            onAlert={handleSetAlert} 
            onLoading={handleSetLoading} 
            onListingDeleted={handleListingDeleted}
        />
        ) : (
        <ProductListingEditor 
            onListingMenuClick={handleListingMenuClick} 
            onListingDeleted={handleListingDeleted}
            listingId={currentListingId} 
            listingData={currentListingData}
            collectionId={productCollectionId}
            collectionData={productCollectionData}
            onAlert={handleSetAlert} 
            onLoading={handleSetLoading} 
            onPublishDone={handlePublishDone}
        />
        )}

        <Snackbar open={alertOpen} autoHideDuration={3000} onClose={()=>{setAlertOpen(false)}}>       
            <Alert onClose={()=>{setAlertOpen(false)}} severity={alertSeverity} sx={{ width: '100%' }}>        
                {alertMsg}           
            </Alert>       
        </Snackbar>
        
    </ThemeProvider>
    )
}

export default ProductListings; 