import React, {useState, useEffect, useContext, useRef, useMemo } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";

import Theme from '../Theme';

import MainService from "../MainService"; 
import MainContext from "../MainContext"; 

import { ThemeProvider } from  '@mui/system';
import { Box, IconButton, Typography, Avatar, InputBase, Button, ButtonBase, Chip, Menu, MenuItem, ListItemIcon, CircularProgress, Badge } from "@mui/material";

import { styled } from '@mui/system';


import AddCommentIcon from '@mui/icons-material/AddComment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';

import Paper from "../components/Paper"; 

import StyledTextField from "../components/StyledTextField";
import ProfileAvatar from "../components/ProfileAvatar";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { WorkQueue } from "../Util";
import { API_URL } from "../Config";

import LoadingFull from "../components/LoadingFull";

import fadein from "../AnimationKeyframes/FadeIn";

import EmojiPicker from 'emoji-picker-react';
import './EmojiPicker.css'; 

// usePrev Hook
function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
      ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
  }

const workQueue = new WorkQueue(); 

const MessageMenu = ({open, handleClose, anchorEl, handleDelete})=>{
    return (
        <Menu
            anchorEl={anchorEl}
            id="message-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 1px 6px rgba(43,43,43,0.05))',
                border:'1px solid #EBEFF2',
                mt: 1.5,
                '& .MuiAvatar-root': {width: 32, height: 32, ml: -0.5, mr: 1,},
                '&:before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', 
                              borderTop:'1px solid #EBEFF2', borderLeft:'1px solid #EBEFF2', borderTop:'1px solid #EBEFF2', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0,},
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem onClick={ ()=>{if(handleDelete) handleDelete();} } >
                <ListItemIcon>
                <DeleteIcon /> 
                </ListItemIcon>
                Delete Conversation
            </MenuItem>

        </Menu>
    )
}

let ConvoMembersInput = ({sx, value, onChange, instanceId })=>{

    const mainService = useContext(MainContext);


    const [searchValue, setSearchValue] = useState(''); 
    const [searchResult, setSearchResult] = useState([]); 
    const [filteredSearchResult, setFilteredSearchResult] = useState([]); 
    const [loading, setLoading] = useState(false); 
    const [contacts, setContacts] = useState([]); 
    const [filteredContacts, setFilteredContacts] = useState([]); 


    const handleInputChange = (str)=>{

        setSearchValue(str); 

        workQueue.cancelAll();
        
        if(!str){
            setSearchResult([]);
            setFilteredSearchResult([]);
            return
        }

        setLoading(true);

        workQueue.push(async (job) => {
            try{
                const token = await mainService.getUserToken();
                const res = await fetch(API_URL + `/findusers?` + new URLSearchParams({
                    name: str,
                }),{
                    headers: {
                      Authorization: `${token}`
                    }
                })
                const data = await res.json();
                if(data.ok){
                    setLoading(false);
                    console.log("success")
                    if(job.state != "cancelled"){

                        setSearchResult(data.results); 
                        
                        //Filter results out if they are already in value
                        let filteredResults = data.results.filter((result)=>{
                            let uid = result.document?.id;
                            return value.find(element => element.uid == uid) ? false : true; 
                        }); 
                        setFilteredSearchResult(filteredResults); 
                    }
                }
                else{
                    throw "request failed"
                }
            }
            catch(error){
                setLoading(false);
                console.error(error);
            }
        });
    }

    const handleAddMember = (uid, name, photoURL)=>{
        let newValue = []; 
        if(value && value.length > 0){
            newValue = [...value];
        }

        newValue.push({
            uid, 
            name,
            photoURL,
        });

        onChange(newValue);

        //remove the result from the filtered search results
        let sInx = filteredSearchResult.findIndex(element => element.document?.id == uid);
        if(sInx > -1){
            filteredSearchResult.splice(sInx, 1); 
            setFilteredSearchResult([...filteredSearchResult]); 
        } 

        //remove result from contacts too
        let cInx = filteredContacts.findIndex(element => element.uid == uid);
        if(cInx > -1){
            filteredContacts.splice(cInx, 1); 
            setFilteredContacts([...filteredContacts]); 
        } 
    }

    const handleDeleteMember = (inx)=>{
        if(value){
            let newValue = []; 
            if(value.length > 0){
                newValue = [...value];
            }
            let member = newValue[inx]; 
            newValue.splice(inx, 1); 
            onChange(newValue);

            //add the result back to the filtered search results if needed
            let filteredResults = searchResult.filter((result)=>{
                let uid = result.document?.id;
                if(uid ==  member.uid)
                    return true;
                return value.find(element => element.uid == uid) ? false : true; 
            }); 
            setFilteredSearchResult(filteredResults); 

            //
            let filteredContactsNew = contacts.filter((result)=>{
                let uid = result.uid;
                if(uid ==  member.uid)
                    return true;
                return value.find(element => element.uid == uid) ? false : true; 
            }); 
            setFilteredContacts(filteredContactsNew); 
        }
    }

    let getContacts = ()=>{
        mainService.messageService.getContactsData()
        .then((data)=>{
            setContacts(data); 
            let filteredContacts = data.filter((result)=>{
                let uid = result.uid;
                return value.find(element => element.uid == uid) ? false : true; 
            }); 
            setFilteredContacts(filteredContacts); 
        });
    }

    let createNewChatConvoTrigger = (contact)=>{
        
        if(contact && contact.uid){
            console.log(contact); 
            handleAddMember(contact?.uid, '' + contact?.firstname + ' ' + contact?.lastname, contact?.photoURL);
        }
    }

    useEffect(()=>{
        setSearchValue(''); 
        setSearchResult([]); 
        setFilteredSearchResult([]); 
        getContacts(); 
        
    }, [instanceId])

    useEffect(()=>{
        mainService.addTriggerListener('createNewChatConvo', createNewChatConvoTrigger); 
        return ()=>{
            mainService.removeTriggerListener('createNewChatConvo', createNewChatConvoTrigger); 
		}
    }, [])

    return(
    <React.Fragment>
        <Box sx={{position:'relative', display:'flex', flexDirection:'column', borderBottom:`1px solid ${Theme.palette.grey[300]}`, ...sx}}>
            
            <Box sx={{position:'relative', display:'flex', flexGrow:1, flexDirection:'column', backgroundColor:'#ffffff'}}>
                <Box sx={{ my:'8px', mx:'16px', height:'32px', display:'flex', py:'8px', px:'16px', borderRadius:'16px', border: searchValue ? `1px solid ${Theme.palette.grey[300]}`:'default' }}>
                    {!searchValue && <Typography sx={{ position:'absolute',  }} variant='body2'>Type to search...</Typography>}
                    <InputBase sx={{flexGrow:1}} onChange={(e)=>handleInputChange(e.target.value)} value={searchValue}/>
                   
                </Box>
            </Box>
            
            {value && value.length > 0 &&
            <Box sx={{pb:'8px', px:'16px', display:'flex', flexGrow:1, flexDirection:'column'}}>
                
                <Box sx={{ display:'flex', alignItems:'center', flexWrap:'wrap',}}>
                    {value.map((m, inx)=>(
                        <Chip key={inx} sx={{m:'2px'}} label={m.name} onDelete={()=>handleDeleteMember(inx)} />
                    ))}
                </Box>
                
            </Box>
            }

        </Box>

        {loading?(
            <Box sx={{flexGrow:1, display:'flex', alignItems:'center', justifyContent:'center'}}>
                <CircularProgress/>
            </Box>
        ):!filteredSearchResult.length && searchValue?(
            <Box sx={{flexGrow:1, display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Typography variant="h4">No Results...</Typography>
            </Box>
        ):!filteredSearchResult.length && !searchValue?(
            <Box sx={{display:'flex', flexDirection:'column'}}>
                <Typography variant="h4" sx={{mt:'30px', mb:'30px', ml:'16px',}}>Your Contacts</Typography>
                {filteredContacts && filteredContacts.map((contact)=>(
                    <Box sx={{display:'flex',}}>
                        <ProfileAvatar  sx={{ ml:'16px', width: 48, height: 48, alignSelf:'center', }} photoURL={contact?.photoURL ?? null} name={contact?.firstname}/>
                        <Box sx={{flexGrow:1, ml:'16px', py:'18px', display:'flex', flexDirection:'column', borderBottom:`1px solid ${Theme.palette.grey[300]}`}}>
                            <Typography variant='h6' align='left'>{contact?.firstname} {contact?.lastname}</Typography>
                            <Typography variant='body2'>{contact.type == 'teacher' ? (contact?.teacherrole) : (contact?.orgname)}</Typography>
                        </Box>
                        <IconButton size='small' onClick={()=>handleAddMember(contact?.uid, '' + contact?.firstname + ' ' + contact?.lastname, contact?.photoURL)} 
                                    sx={{alignSelf:'center', width:'32px', height:'32px', mr:'16px'}}>
                            <AddIcon />
                        </IconButton>
                    </Box>
                ))}

            </Box>
        ):(
            <Box sx={{display:'flex', flexDirection:'column'}}>
                {filteredSearchResult.map((result)=>(
                    <Box key={result.document?.id} sx={{ display:'flex', flexGrow:1, alignItems:'center', backgroundColor:'default', }}>
                        <ProfileAvatar  sx={{ ml:'16px', width: 48, height: 48 }} photoURL={result.document?.photoURL ?? null} name={result.document?.firstname}/>
                        {/*<Avatar sx={{ ml:'16px', width: 48, height: 48 }} color="action" >R</Avatar>*/}
                        <Box sx={{flexGrow:1, ml:'16px', py:'18px', display:'flex', flexDirection:'column', borderBottom:`1px solid ${Theme.palette.grey[300]}`}}>
                            <Typography variant='h6' align='left'>{result.document?.firstname} {result.document?.lastname}</Typography>
                            <Box sx={{display:'flex'}}>
                                <Typography variant='body2'>{''}</Typography>
                            </Box>
                        </Box>
                        <IconButton size='small' onClick={()=>handleAddMember(result.document?.id, '' + result.document?.firstname + ' ' + result.document?.lastname, result.document?.photoURL)} 
                                    sx={{alignSelf:'center', width:'32px', height:'32px', mr:'16px'}}>
                            <AddIcon />
                        </IconButton>
                    </Box>
                ))}
            </Box>
        )}

    </React.Fragment>
    )
}



let Conversation = ({lastMessage, onClick, selected, convoData, uid, unread})=>{
   
    let name = ''; 
    let photos = [];
    if(convoData && convoData.members){
        for(let member of convoData.members){
            if(member.uid != uid){
                name += (name?', ':'') + member.name; 
                if(member.photoURL)
                    photos.push(member.photoURL);
                
            }

        }
        
    }
    
    //TODO we can improve UI by implementing Avatar Group
    return(
        <ButtonBase sx={{display:'flex', alignItems:'center',  }} onClick={onClick}>
            <Box sx={{ display:'flex', flexGrow:1, alignItems:'center', 
                backgroundColor:selected?Theme.palette.primary.light:'default', '&:hover': {backgroundColor:Theme.palette.primary.light}, }}>
                <ProfileAvatar sx={{ ml:'16px', width: 48, height: 48 }} photoURL={photos.length > 0 ? photos[0] : null} name={name}/>
                <Box sx={{flexGrow:1, ml:'16px', py:'18px', display:'flex', flexDirection:'column', borderBottom:`1px solid ${Theme.palette.grey[300]}`}}>
                    <Box sx={{position:'relative', display:'flex' }}>
                        <Badge color="secondary" variant="dot" badgeContent={unread ? 1 : 0}>
                            <Typography variant='h6' align='left' noWrap sx={{width:'200px', fontWeight:unread?'600':'initial', }}>{name}</Typography>
                        </Badge>
                    </Box>
                    
                    <Box sx={{position:'relative', display:'flex'}}>
                        <Typography variant='body2' align='left' noWrap sx={{ width:'200px', fontWeight:unread?'600':'initial', }} >
                            {lastMessage}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </ButtonBase>
    )
}



const MessageInput = ({
    disableNewMessageInput, 
    currentConvoId,
    onSendClick
})=>{

    const [newMessageText, setNewMessageText] = useState(''); 
    const [emojiOpen, setEmojiOpen] = useState(false); 
    const [newEmoji, setNewEmoji] = useState(null); 

    const handleSendClick = ()=>{
        setEmojiOpen(false); 
        onSendClick(newMessageText)
        setNewMessageText('');
    }

    const handleInputKeyPress = (ev)=>{
        if (ev.ctrlKey && ev.key === 'Enter' && newMessageText.length > 0) {
            ev.preventDefault();
            handleSendClick(); 
        }
    }

    const handleMessageTextChange = (e)=>{
        setNewMessageText(e.target.value); 
    }

    const handleEmojiOpenClick = ()=>{
        setEmojiOpen(true); 
        
    }

    const handleEmojiCloseClick = ()=>{
        setEmojiOpen(false); 
    }

    const handleEmojiClick = (emj)=>{
        setNewEmoji(emj); 
    }

    useEffect(()=>{
        if(newEmoji){
            let newText = newMessageText; 
            newText += newEmoji.emoji; 
            setNewMessageText(newText);
        }
    }, [newEmoji])

    useEffect(()=>{
        setEmojiOpen(false);
        setNewMessageText('');
    },[currentConvoId])

    const cachedPicker = useMemo(() => {
        return(
            <EmojiPicker emojiStyle={'native'} skinTonesDisabled={true} previewConfig={{showPreview:false}} onEmojiClick={handleEmojiClick} width={'100%'} height={'100%'}/>
    )}, [ ]);

    return (
        <Box sx={{ backgroundColor:'#FFFFFF', display:'flex', px:'8px', flexDirection:'column', borderTop:`1px solid ${Theme.palette.grey[300]}`}}>
            
            <Box sx={{transition:'height 0.3s', height:emojiOpen?'300px':'0px', }}>
                {cachedPicker}
            </Box>
            
            <InputBase sx={{backgroundColor:'#f5f5f5', mt:'8px', borderRadius:'5px'}} disabled={disableNewMessageInput} multiline rows={3} value={newMessageText} onKeyPress={handleInputKeyPress} onChange={handleMessageTextChange} />
            
            <Box sx={{display:'flex', py:'8px'}}>
                {!emojiOpen &&
                <IconButton size='small' onClick={handleEmojiOpenClick} disabled={disableNewMessageInput}>
                    <EmojiEmotionsIcon/>
                </IconButton>
                }
                {emojiOpen &&
                <IconButton size='small' onClick={handleEmojiCloseClick} disabled={disableNewMessageInput}>
                    <CloseIcon/>
                </IconButton>
                }
                <Box sx={{flexGrow:1}}/>
                <Button disabled={newMessageText.length == 0 || disableNewMessageInput} variant="contained" sx={{color:'white',mr:'0px', borderRadius:'30px'}} endIcon={<SendIcon/>} onClick={handleSendClick}>
                    Send
                </Button>
            </Box>

        </Box>
    )
}

const MessagePanel = ({
    isMobile,
    newConvoMenuOpen, 
    convos, 
    currentConvoId, 
    currentConvoName, 
    convoMemberData,
    firstConvoMember, 
    messages, 
    messagesLoading, 
    disableNewMessageInput,
    uidMemberNameMap,

    handleMessageMenuOpen, 
    handleClearConvoSelection,
    
    onSendClick,

})=>{
    const mainService = useContext(MainContext);

    return(
        <React.Fragment>
        {newConvoMenuOpen ? (
            <Box sx={{display:'flex', alignItems:'center', flexGrow:1, justifyContent:'center',}}>
                {disableNewMessageInput ? (
                    <Typography variant='h4'>Choose a contact to talk to</Typography>
                ):(
                    <Typography variant='h4'>Type a message bellow to start a conversation</Typography>
                )}
            </Box>
        ): convos.length == 0 ? (
            <Box sx={{display:'flex', alignItems:'center', flexGrow:1, justifyContent:'center',}}>
                <Typography variant='h4'>Start a new conversation using the panel on the left</Typography>
            </Box>
        ):messages && messages.length == 0 || !currentConvoId ? (
            <Box sx={{display:'flex', alignItems:'center', flexGrow:1, justifyContent:'center',}}>
                <Typography variant='h4'>Pick a conversation, or start a new one</Typography>
            </Box>
        ):(
        <React.Fragment>
            <Box sx={{height:'60px', px:'16px', display:'flex', alignItems:'center', 
                backgroundColor:'#ffffff', borderBottom:`1px solid ${Theme.palette.grey[300]}`}}
            >
                {isMobile ? (
                <IconButton>
                    <ArrowBackIcon onClick={()=>{handleClearConvoSelection(); }}/>
                </IconButton>
                ):(
                <ProfileAvatar  sx={{ ml:'16px', width: 32, height: 32 }} 
                    photoURL={firstConvoMember?.photoURL ? firstConvoMember.photoURL : null} 
                    name={firstConvoMember?.name ? firstConvoMember.name : ''}
                />
                )}
                <Typography variant='h5' sx={{ml:'16px'}}>{currentConvoName}</Typography>
                <Box sx={{flexGrow:1}}/>
                <IconButton onClick={handleMessageMenuOpen}>
                    <MoreVertIcon />
                </IconButton>
            </Box>

            <Box sx={{flexGrow:1, position:'relative'}}>

            {messagesLoading ? (
            <Box sx={{display:'flex', alignItems:'center', flexGrow:1, justifyContent:'center',}}>
                
            </Box>
            ):(
            <Box sx={{position:'absolute', width:'100%', height:'100%', overflow:'overlay', display: 'flex', flexDirection: 'column-reverse', overflowAnchor: 'none', }} >
            <Box sx={{flexGrow:1, display:'flex', pb:'16px', flexDirection:'column'}}>

                {messages.map((m, inx)=>(
                <React.Fragment key={inx}>

                    {(inx == 0 || 
                        inx > 0 && mainService.getDateString(m.timestamp) != mainService.getDateString(messages[inx-1].timestamp) ) &&
                    <Box sx={{display:'flex', alignItems:'center', mt:'24px'}}>
                        <Box sx={{backgroundColor:'#d0d0d0', height:'1px', flexGrow:1}}></Box>
                        <Typography variant="body" sx={{mx:'16px', color:Theme.palette.primary.main}}>{mainService.getDateString(m.timestamp)}</Typography>
                        <Box sx={{backgroundColor:'#d0d0d0', height:'1px', flexGrow:1}}></Box>
                    </Box>
                    }

                    {(
                        inx == 0 || 
                        inx > 0 && (messages[inx-1].sender != m.sender || 
                        inx > 0 && mainService.getDateString(m.timestamp) != mainService.getDateString(messages[inx-1].timestamp)) 
                    ) &&
                    <Box sx={{display:'flex', alignItems:'flex-end', ml:'16px', mt:'24px', mb:'8px'}}>
                        <ProfileAvatar  sx={{width: 32, height: 32 }} 
                            photoURL={convoMemberData.get(m.sender)?.photoURL} 
                            name={convoMemberData.get(m.sender)?.name} 
                        />
                        <Box sx={{display:'flex', alignItems:'flex-end', ml:'16px', mt:'24px', mb:'8px'}}>
                            <Typography variant="h6" sx={{mr:'10px',}}>{uidMemberNameMap.get(m.sender)}</Typography>
                            <Typography variant="caption">{mainService.getTimeString(m.timestamp)}</Typography>
                        </Box>
                    </Box>
                    }

                    <Typography sx={{ml:'64px', fontSize:'11pt'}} variant='body2'>{m.value}</Typography>

                </React.Fragment>
                ))}

            </Box>
            </Box>
            )}
            
            </Box>
        </React.Fragment>
        )}

        <MessageInput
            disableNewMessageInput={disableNewMessageInput}
            currentConvoId={currentConvoId}
            onSendClick={onSendClick}
        />
        </React.Fragment>
    )
}



const Messages = ({userObject, forceRenderMode}) => {
    const mainService = useContext(MainContext);

    const messageContainerRef = useRef(); 
    const newMessageAudioRef = useRef(); 


    const [initialLoading, setInitialLoading] = useState(true); 
    
    const [messagesLoading, setMessagesLoading] = useState(true); 
    const [convos, setConvos] = useState([]); 
    const [currentConvoId, setCurrentConvoId] = useState(''); 
    const [currentConvo, setCurrentConvo] = useState(null); 
    const [messages, setMessages] = useState([]); 
    
    const [newConvoMenuOpen, setNewConvoMenuOpen] = useState(false); 
    const [newConvoMenuId, setNewConvoMenuId] = useState(Date.now()); 
    const [newConvoMembersValue, setNewConvoMembersValue] = useState([]); 

    const [messageMenuAnchorEl, setMessageMenuAnchorEl] = useState(null);
    const [messageMenuOpen, setMessageMenuOpen] = useState(false); 

    const [mainAnim, setMainAnim] = useState(`${fadein} 0.5s linear 0s 1`); 
    const [mainOpacity, setMainOpacity] = useState('1');

    const [unreadMessages, setUnreadMessages] = useState(null); 
    const prevUnreadMessages = usePrevious(unreadMessages);


    const onSendClick = async (newMessageText)=>{

        if(newConvoMenuOpen){
            let newConvo = await mainService.messageService.createConv(userObject, newConvoMembersValue, convos); 
            let newConvoKey = newConvo.id; 
            setCurrentConvoId(newConvoKey); 
            setNewConvoMenuOpen(false); 
            setNewConvoMembersValue([]); 
            mainService.messageService.sendMessage(newConvoKey, newMessageText, newConvo); 
        }
        else {
            mainService.messageService.sendMessage(currentConvoId, newMessageText, currentConvo); 
        }

    }   


    const handleConvoClick = (cid)=>{
        let cc = convos.find((convo)=>convo.id==cid);
        if(cc)
            setCurrentConvo(cc);
        setCurrentConvoId(cid); 

        mainService.messageService.setReadLatest(cid); //make sure you have now "read" the latest message
    }




    const handleNewConvoClick = ()=>{
        setNewConvoMenuOpen(true); 
        setNewConvoMembersValue([]); 
        setNewConvoMenuId(Date.now());
    }

    const handleNewConvoCancelClick = ()=>{
        setNewConvoMenuOpen(false); 
        setNewConvoMembersValue([]); 
        setNewConvoMenuId(Date.now());
    }

    const handleMessageMenuClose = ()=>{
        setMessageMenuOpen(false);
    }

    const handleMessageMenuOpen = (e)=>{
        setMessageMenuAnchorEl(e.currentTarget);
        setMessageMenuOpen(true);
    }

    const handleConvoDelete = ()=>{
        if(currentConvoId){
            mainService.messageService.deleteConv(currentConvoId); 
            handleClearConvoSelection(); 
        }
    }

    const handleClearConvoSelection = ()=>{
        setCurrentConvo(null); 
        setCurrentConvoId(''); 
        console.log('ClearConvoSelection') 
    }

   


    const messagesListner = (messages)=>{
        let messagesArray = []; 
        for(let mid in messages){
            let message = messages[mid]; 
            message.id = mid;
            messagesArray.push(message); 
        }
        
        messagesArray.sort((a, b)=>{
            return a.id < b.id ? -1 : a.id === b.id ? 0 : 1;
        });
        
        setMessages(messagesArray);
        setMessagesLoading(false); 
        
    }

    const convosListner = (convos)=>{

        //create new convos list and add listners
        let convosArray = []; 
        for(let [cid, convo] of convos){
            convosArray.push(convo); 
        }
        
        convosArray.sort((a, b)=>{
            let aT = a?.latestMessage?.timestamp; 
            let bT = b?.latestMessage?.timestamp; 
            return aT < bT ? 1 : aT === bT ? 0 : -1;
        }); 

        setConvos(convosArray); 
    } 

    let newUnreadMessagesListner = (newMessages)=>{
        if(newMessages){
            setUnreadMessages( newMessages ); 
        }
        else
            setUnreadMessages( {} );
    }

    let createNewChatConvoTrigger = ()=>{
        handleNewConvoClick(); 
    }


    useEffect(()=>{
        if(!unreadMessages){
            return;
        }
        //if the current convo id is in the unread messages, then set that to read instantly!
        if(unreadMessages[currentConvoId]){ 
            mainService.messageService.setReadLatest(currentConvoId); 
        }
        else{
            
            if(newMessageAudioRef.current && Object.entries(unreadMessages).length == 1 && prevUnreadMessages != null){
                newMessageAudioRef.current.play(); 
            }
        }
    }, [unreadMessages]);


    useEffect(()=>{
        let currentConvoIdExists = false; 
        for(let convo of convos){
            if(convo.id == currentConvoId){
                setCurrentConvo(convo); 
                currentConvoIdExists = true; 
            }
        }
        if(!currentConvoIdExists){
            handleClearConvoSelection(); 
        }
    }, [convos]);


    useEffect(()=>{

        setTimeout(()=>{setInitialLoading(false)}, 500);

        console.log('convo id ' + currentConvoId)
        
        if(currentConvoId){
            setMessagesLoading(true); 
            mainService.messageService.addMessagesListner(currentConvoId, messagesListner); 
        }
        return ()=>{
            mainService.messageService.removeMessagesListner(currentConvoId, messagesListner);
		}
        
    }, [currentConvoId]);


    useEffect(()=>{
        console.log('Messages mounted');

        mainService.messageService.addConvosListner(convosListner); 

        mainService.messageService.addNewMessageListner(newUnreadMessagesListner);

        mainService.addTriggerListener('createNewChatConvo', createNewChatConvoTrigger); 
        
        return ()=>{
			console.log('Messages unmounted');
            mainService.messageService.removeConvosListner(convosListner);

            mainService.messageService.removeNewMessageListner(newUnreadMessagesListner);

            mainService.removeTriggerListener('createNewChatConvo', createNewChatConvoTrigger); 
		}
        
    }, [userObject]);

    let disableNewMessageInput = !currentConvoId && !newConvoMenuOpen || newConvoMenuOpen && newConvoMembersValue.length == 0; 

    let currentConvoName = ''; 
    let uidMemberNameMap = new Map();
    if(currentConvo?.members){
        for(let member of currentConvo.members){
            if(member.uid != mainService.getUid()){
                currentConvoName += (currentConvoName?', ':'') + member.name; 
            }
            uidMemberNameMap.set(member.uid, member.name)
        }
    }

    let convoMemberData = new Map();
    let firstConvoMember = null;
    if(currentConvo?.members){
        for(let member of currentConvo.members){
            let photoURL = member.photoURL ? member.photoURL : member.uid == mainService.getUid() ? userObject?.photoURL ?? null : null; 
            convoMemberData.set(member.uid, {photoURL:photoURL, name:member.name});    
            if(firstConvoMember == null && member.uid != mainService.getUid()){
                firstConvoMember = {photoURL:member.photoURL?member.photoURL:null, name:member.name}
            }  
        }
    }

    
    const renderDesktop = ()=>{
        

        return (
        <ThemeProvider theme={Theme}>
            <Box sx={{position:'absolute', width:'100%', height:'100%', overflow:'auto', display:'flex', justifyContent:'center', }}>
                
            {!initialLoading && 
            <Paper sx={{display:'flex', flexGrow:1, maxWidth:'1200px', p:0, m:'32px', overflow:'hidden', animation:mainAnim, opacity:mainOpacity}}>


                <Box sx={{
                    width:'350px', 
                    display:'flex', 
                    flexDirection:'column', 
                    backgroundColor:'#ffffff',//'#D3EAFB',//'#d7e3fc',//'#2499ef',//#F2F9FE #2499ef 0e6abd
                    borderRight:`1px solid ${Theme.palette.grey[300]}`,
                    overflow:'hidden',
                    position:'relative',
                }}>

                
                <Box sx={{position:'absolute', width:'100%', height:'100%', display:'flex', flexDirection:'column', 
                    transform: !newConvoMenuOpen ? 'translateX(100%)':'translateX(0)', transition:'transform 0.3s',}}
                >
                    <Box sx={{height:'60px', display:'flex', alignItems:'center', px:'16px', borderBottom:`1px solid ${Theme.palette.grey[300]}`}}>
                        <IconButton size='medium' onClick={handleNewConvoCancelClick}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Box sx={{flexGrow:1}}></Box>
                        <Typography variant="h4" sx={{ml:'16px', mr:'16px'}}>New Conversation</Typography>
                    </Box>
                    <ConvoMembersInput instanceId={newConvoMenuId} userObject={userObject} value={newConvoMembersValue} onChange={setNewConvoMembersValue} />
                </Box>

               

                <Box sx={{position:'absolute', width:'100%', height:'100%', display:'flex', flexDirection:'column', flexGrow:1, 
                    transform: newConvoMenuOpen ? 'translateX(-100%)':'translateX(0)', transition:'transform 0.3s',}}
                >
                    {!convos.length == 0 ? (
                    <React.Fragment>
                        <Box sx={{height:'60px', display:'flex', alignItems:'center', px:'16px', backgroundColor:'#ffffff', borderBottom:`1px solid ${Theme.palette.grey[300]}`}}>
                            <Typography variant="h4" sx={{ml:'16px'}}>Messages</Typography>
                            <Box sx={{flexGrow:1}}></Box>
                            <IconButton size='medium' onClick={handleNewConvoClick}>
                                <AddCommentIcon />
                            </IconButton>
                        </Box>
                        
                        <Box sx={{flexGrow:1, position:'relative'}}>
                        <Box sx={{position:'absolute', width:'100%', height:'100%', overflow:'overlay'}}>    
                        <Box sx={{flexGrow:1, display:'flex', flexDirection:'column'}}>

                            {convos.map((convo )=>(
                                <Conversation 
                                    key={convo.id} 
                                    unread={unreadMessages && unreadMessages[convo.id] && convo.id != currentConvoId ? true:false}
                                    onClick={()=>handleConvoClick(convo.id)}
                                    selected={convo.id == currentConvoId}
                                    convoData={convo}
                                    uid={ mainService.getUid()}
                                    lastMessage={convo?.latestMessage?.value}
                                />
                            ))}


                        </Box>
                        </Box>
                        </Box>
                    </React.Fragment>
                    ):(
                    <Box sx={{flexGrow:1, display:'flex', flexDirection:'column', justifyContent:'center'}}>
                        <Box sx={{ position:'relative', display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <IconButton size='large' onClick={handleNewConvoClick}>
                                <AddCommentIcon />
                            </IconButton>
                            <Typography variant="h4" sx={{ml:'16px', mr:'16px'}}>Start new conversation</Typography>
                        </Box>
                    </Box>
                    )}
                </Box>

                

                </Box>





                <Box sx={{
                    flexGrow:1,
                    display:'flex', 
                    flexDirection:'column', 
                    backgroundColor:'#fafafa',
                }}>
                    <MessagePanel
                        newConvoMenuOpen={newConvoMenuOpen} 
                        convos={convos}
                        currentConvoId={currentConvoId}
                        currentConvoName={currentConvoName} 
                        convoMemberData={convoMemberData}
                        firstConvoMember={firstConvoMember} 
                        messages={messages}
                        messagesLoading={messagesLoading} 
                        disableNewMessageInput={disableNewMessageInput}
                        uidMemberNameMap={uidMemberNameMap}
                    
                        handleMessageMenuOpen={handleMessageMenuOpen}
                        handleClearConvoSelection={handleClearConvoSelection}

                        onSendClick={onSendClick}
                    />
                
                </Box>




            </Paper>
            }


            {initialLoading &&
            <Box sx={{position:'relative', mb:'12px', display:'flex', flexDirection:'column', flexGrow:1}}>
                <LoadingFull/>
            </Box>  
            }


            </Box>
            <MessageMenu anchorEl={messageMenuAnchorEl} open={messageMenuOpen} handleClose={handleMessageMenuClose} handleDelete={handleConvoDelete}/>
            <audio src='assets/newMessageSound.mp3' ref={newMessageAudioRef}></audio>
        </ThemeProvider>
        )
    }



    const renderMobile = ()=>{
        return(
        <ThemeProvider theme={Theme}>
            <Box 
                sx={{position:'absolute', width:'100%', height:'100%', overflow:'hidden',}}
                ref={ (el)=>{if(el){el.scrollTop=0; el.scrollLeft=0; /*Odd but this fis needed when transforming inside container */ }} }
            >

            {!initialLoading &&
            <React.Fragment>
                    <Box sx={{
                        
                        position:'absolute',
                        width:'100%', 
                        height:'100%',
                        left:0, right:0,
                        backgroundColor:'#ffffff',//'#D3EAFB',//'#d7e3fc',//'#2499ef',//#F2F9FE #2499ef 0e6abd
                        borderRight:`1px solid ${Theme.palette.grey[300]}`,
                        transform: currentConvoId ? 'translateX(-100%)':'translateX(0)', 
                        transition:'transform 0.3s',
                        zIndex:0,  
                    }}>
        
                        
                        <Box sx={{position:'absolute', width:'100%', height:'100%', display:'flex', flexDirection:'column',
                            transform: !newConvoMenuOpen ? 'translateX(100%)':'translateX(0)', transition:'transform 0.3s',}}
                        >
                            <Box sx={{height:'60px', display:'flex', alignItems:'center', px:'16px', borderBottom:`1px solid ${Theme.palette.grey[300]}`}}>
                                <IconButton size='medium' onClick={handleNewConvoCancelClick}>
                                    <ArrowBackIcon />
                                </IconButton>
                                <Box sx={{flexGrow:1}}></Box>
                                <Typography variant="h4" sx={{ml:'16px', mr:'16px'}}>New Conversation</Typography>
                            </Box>
                            <ConvoMembersInput userObject={userObject} value={newConvoMembersValue} onChange={setNewConvoMembersValue} />
                            <Box sx={{flexGrow:1}}/>
                            
                            <MessageInput onSendClick={onSendClick} disableNewMessageInput={disableNewMessageInput} />
                            
                        </Box>
        
                        
                        <Box sx={{position:'absolute', width:'100%', height:'100%', display:'flex', flexDirection:'column',
                            transform: newConvoMenuOpen ? 'translateX(-100%)':'translateX(0)', transition:'transform 0.3s',}}
                        >
                            {!convos.length == 0 ? (
                            <React.Fragment>
                                <Box sx={{height:'60px', display:'flex', alignItems:'center', px:'16px', borderBottom:`1px solid ${Theme.palette.grey[300]}`}}>
                                    <Typography variant="h4" sx={{ml:'16px'}}>Messages</Typography>
                                    <Box sx={{flexGrow:1}}></Box>
                                    <IconButton size='medium' onClick={handleNewConvoClick}>
                                        <AddCommentIcon />
                                    </IconButton>
                                </Box>
                                
                                <Box sx={{flexGrow:1, position:'relative'}}>
                                <Box sx={{position:'absolute', width:'100%', height:'100%', overflow:'overlay'}}>    
                                <Box sx={{flexGrow:1, display:'flex', flexDirection:'column'}}>
        
                                    {convos.map((convo )=>(
                                        <Conversation 
                                            key={convo.id} 
                                            unread={unreadMessages && unreadMessages[convo.id] && convo.id != currentConvoId ? true:false}
                                            onClick={()=>handleConvoClick(convo.id)}
                                            selected={convo.id == currentConvoId}
                                            convoData={convo}
                                            uid={ mainService.getUid()}
                                            lastMessage={convo?.latestMessage?.value}/>
                                    ))}
        
        
                                </Box>
                                </Box>
                                </Box>
                            </React.Fragment>
                            ):(
                            <Box sx={{flexGrow:1, display:'flex', flexDirection:'column', justifyContent:'center'}}>
                                <Box sx={{ position:'relative', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    <IconButton size='large' onClick={handleNewConvoClick}>
                                        <AddCommentIcon />
                                    </IconButton>
                                    <Typography variant="h4" sx={{ml:'16px', mr:'16px'}}>Start new conversation</Typography>
                                </Box>
                            </Box>
                            )}
                        </Box>
                            
    
                    </Box>
    
    
    
    
    
                    <Box sx={{
                        display:'flex', 
                        flexDirection:'column', 
                        backgroundColor:'#fafafa',
                        position:'absolute', width:'100%', height:'100%',
                        left:0, top:0,
                        transform: currentConvoId ? 'translateX(0)':'translateX(100%)',
                        transition:'transform 0.3s',
                        zIndex:1,
                       
                    }}>
                        <MessagePanel
                            isMobile={true}

                            newConvoMenuOpen={newConvoMenuOpen} 
                            convos={convos}
                            currentConvoId={currentConvoId}
                            currentConvoName={currentConvoName} 
                            convoMemberData={convoMemberData}
                            firstConvoMember={firstConvoMember} 
                            messages={messages}
                            messagesLoading={messagesLoading} 
                            disableNewMessageInput={disableNewMessageInput}
                            uidMemberNameMap={uidMemberNameMap}
                        
                            handleMessageMenuOpen={handleMessageMenuOpen}
                            handleClearConvoSelection={handleClearConvoSelection}
    
                            onSendClick={onSendClick}
                        />
                    </Box>
            </React.Fragment>                            
            }

            {initialLoading &&
            <Box sx={{position:'relative', mb:'12px', display:'flex', flexDirection:'column', flexGrow:1}}>
                <LoadingFull/>
            </Box>  
            }
        
            </Box>

            <MessageMenu anchorEl={messageMenuAnchorEl} open={messageMenuOpen} handleClose={handleMessageMenuClose} handleDelete={handleConvoDelete}/>

            <audio src='assets/newMessageSound.mp3' ref={newMessageAudioRef}></audio>
        </ThemeProvider>
        )
    }



    let renderMode = forceRenderMode ? forceRenderMode : 'desktop'; 
    return renderMode == 'desktop' ? renderDesktop() : renderMobile(); 
}

export default Messages; 