import React, {useState, useEffect, useContext, useRef } from "react";

import { Typography, Box, TextField, Popover, Popper, Tooltip, IconButton, ButtonBase, Paper } from "@mui/material";
import ClickAwayListener from '@mui/material/ClickAwayListener';

import * as dayjs from 'dayjs'

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import Theme from '../../Theme';

import { styled } from '@mui/material/styles';


const MOY = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']; 

const toDayJs = (str) =>{
    let d = str.replaceAll('/', '-').replaceAll(' ', ''); 
    return dayjs(d, ["MM-DD-YYYY", "M-D-YYYY", "MM-D-YYYY", "M-DD-YYYY"], true); 
} 

const getDayOfWeek = (str)=>{
    return dayjs(str, ["M-D-YYYY"], true).day();    
}

const getMonthLength = (str)=>{
    return dayjs(str, ["M-D-YYYY"], true).daysInMonth();    
}

const isDateStrUS = (str)=>{
    let d = toDayJs(str); 
    return d.isValid();
}

const dateTextFieldError = (str)=>{
    if(!str)
        return false; 
    return str.length != 0 && !isDateStrUS(str); 
}

const computeDaysOfMonth = (month, year)=>{
    let str = (month+1)+'-1-'+year; 
    let d = getDayOfWeek(str); 
    let c = getMonthLength(str);
    return {offset:d, count:c}; 
}


const DateRangePicker = ({onFromDateChange, onToDateChange, fromDate, toDate, small, sx})=>{

    const D = new Date();

    let startCtrld = typeof fromDate === 'string'; 
    let endCtrld = typeof toDate === 'string'; 

    const [startDate, setStartDate] = useState(""); 
    const [endDate, setEndDate] = useState(""); 
    const [startDateFocused, setStartDateFocused] = useState(false); 
    const [endDateFocused, setEndDateFocused] = useState(false); 
    const [editStartDate, setEditStartDate] = useState(true); 
    const [popoverOpen, setPopoverOpen] = useState(false); 
    const [popoverMonth, setPopoverMonth] = useState(D.getMonth()); 
    const [popoverYear, setPopoverYear] = useState(D.getFullYear()); 
    const [popoverHoverDate, setPopoverHoverDate] = useState(null); 
    
    const popoverAnchorRef = useRef(null);

    const handlePopoverClose = ()=>{
        setPopoverOpen(false); 
        setStartDateFocused(false); 
        setEndDateFocused(false); 
    }

    const handleStartDateClick = ()=>{
        setEditStartDate(true);
        setStartDateFocused(true); 
        setEndDateFocused(false); 
        setPopoverOpen(true); 
    }

    const handleEndDateClick = ()=>{
        setEditStartDate(false); 
        setStartDateFocused(false); 
        setEndDateFocused(true); 
        setPopoverOpen(true); 
    }

    const handleClickPopoverRight = ()=>{
        let nextMonth = (popoverMonth + 1)%12;
        setPopoverMonth(nextMonth);  
        if(nextMonth == 0)
            setPopoverYear(popoverYear+1); 
    }

    const handleClickPopoverLeft = ()=>{
        let prevMonth = (popoverMonth - 1);
        if(prevMonth < 0) 
            prevMonth = 11;
        setPopoverMonth(prevMonth);  
        if(prevMonth == 11)
            setPopoverYear(popoverYear-1); 
    }

    const handleClickPopoverDate = (day, month, year)=>{
        let dayStr = (''+(day+1)).padStart(2, '0'); 
        let monthStr = (''+(month+1)).padStart(2, '0'); 
        let str = monthStr+'/'+dayStr+'/'+year; 
        if(editStartDate){
            setStartDate(str);
            if(onFromDateChange  && typeof onFromDateChange === 'function')
                onFromDateChange(str); 
            setEditStartDate(false); 
            setStartDateFocused(false); 
            setEndDateFocused(true); 
        } 
        else{
            setEndDate(str); 
            if(onToDateChange && typeof onToDateChange === 'function')
                onToDateChange(str);
            setPopoverOpen(false); 
            setStartDateFocused(false); 
            setEndDateFocused(false); 
        }
    }

    


    const renderMonthBar = ({scrollDirection, month, year, onClickRight, onClickLeft})=>{
        return (
            <Box sx={{height:'40px', display:'flex', alignItems:'center'}}>
                {scrollDirection == 'left' &&
                <IconButton onClick={()=>onClickLeft()}>
                    <KeyboardArrowLeftIcon/>
                </IconButton>
                }
                <Typography  align="center" sx={{flexGrow:1}}>{MOY[month]} {year}</Typography>
                {scrollDirection == 'right' &&
                <IconButton onClick={()=>onClickRight()}>
                    <KeyboardArrowRightIcon/>
                </IconButton>
                }
            </Box>
        )
    }

    const renderDayBar = ()=>{
        const dow = ['S', 'M', 'T', 'W', 'T', 'F', 'S']; 

        return(
            <Box sx={{display:'flex', height:'40px'}}>
                {dow.map((v, i)=>(
                    <Box key={i} sx={{position:'relative', flexGrow:1, display:'flex', alignItems:'center'}}>
                        <Box sx={{position:'absolute', width:'100%', height:'100%', display:'flex', alignItems:'center'}}>
                            <Typography variant="body2" sx={{flexGrow:1}} align="center">{v}</Typography>
                        </Box>
                    </Box>
                ))}
            </Box>
        )
    }


    const renderDayGrid = ({daysRange, month, year, onClick})=>{
        const dow = ['S', 'M', 'T', 'W', 'T', 'F', 'S']; 
        const wom = [0, 1, 2, 3, 4, 5]; 

        let s = toDayJs(startCtrld ? fromDate : startDate); 
        let e = toDayJs(endCtrld ? toDate : endDate); 
        let f = toDayJs(''+(month+1)+'-01-'+year); 
        let l = toDayJs(''+(month+1)+'-'+daysRange.count+'-'+year); 

        let esDiff = e.diff(s, 'days'); 
        
        let sDiff = (s.diff(f, 'days')); 
        let eDiff = (l.diff(e, 'days')); 

        let h = popoverHoverDate==null ? null : toDayJs(popoverHoverDate); 
        let hfDiff = h != null ? h.diff(f, 'days') : null; 
        let lsDiff = h != null ? l.diff(s, 'days') : null; 

        let efDiff = h != null ? e.diff(f, 'days') : null; 
        let lhDiff = h != null ? l.diff(h, 'days') : null;

        const getDate = (i, j)=>{
            return j + i*7 - daysRange.offset; 
        }

        const getCellColor = (i, j)=>{
            let d = getDate(i, j);
            let isExact = d == sDiff || d == (daysRange.count - eDiff - 1); 
            let isBetween = d >= sDiff && d < (daysRange.count - eDiff); 
            let isHover = d == hfDiff; 

            let isBeforeStart = h!=null && esDiff > 0 && (d >= hfDiff && d < (daysRange.count - lsDiff)); 
            let isAfterEnd = h!=null && esDiff > 0 && (d >= efDiff && d < (daysRange.count - lhDiff)); 

            if(s.isValid() && !e.isValid() && endDateFocused){
                let isAfterStart = h!=null && ( d >= sDiff && d < hfDiff); 
                return (
                    isExact ? Theme.palette.primary.main:
                    isHover ? Theme.palette.grey[300]:
                    isAfterStart ? Theme.palette.primary.light:
                    'default'); 
            }
            else {
                return (
                    isExact ? Theme.palette.primary.main:
                    isHover ? Theme.palette.grey[300]:
                    isBetween ? Theme.palette.primary.light:
                    isBeforeStart || isAfterEnd ? Theme.palette.grey[200]:
                    'default'); 
            }
        }

        return (
            <Box sx={{display:'flex', flexDirection:'column'}}>
                <Box sx={{display:'flex', flexDirection:'column'}}>

                    {wom.map((w, i)=>(
                        <Box key={i} sx={{display:'flex', height:'40px'}}>

                            {dow.map((d, j)=>(
                                <React.Fragment key={j}>
                                {( getDate(i, j) >= 0 && getDate(i, j) < daysRange.count) ? (
                                    <ButtonBase key={j} onClick={()=>onClick(getDate(i, j), month, year)} 
                                                sx={{position:'relative', flexGrow:1, display:'flex', alignItems:'center'}}
                                                onMouseEnter={() => setPopoverHoverDate(''+(month+1)+'-'+(getDate(i, j)+1)+'-'+year)}
                                                onMouseLeave={() => setPopoverHoverDate(null)}
                                    >
                                        <Box sx={{position:'absolute', width:'100%', height:'90%', display:'flex', alignItems:'center', backgroundColor:getCellColor(i, j),}}>
                                            <Typography variant="body" sx={{flexGrow:1}} align="center">{getDate(i, j)+1}</Typography>
                                        </Box>
                                    </ButtonBase>
                                ):(
                                    <Box key={j} sx={{position:'relative', flexGrow:1, display:'flex', alignItems:'center'}}>
                                        
                                    </Box>
                                )}
                                </React.Fragment>
                            ))}

                        </Box>
                    ))}

                </Box>
            </Box>
        )
    }

    return(
    <React.Fragment>
        <Box sx={{mb:'16px', mx:'16px', display:'flex', ...sx}}>
        <ClickAwayListener onClickAway={handlePopoverClose}>
            <div>
            <Box ref={popoverAnchorRef} sx={{display:'flex', alignItems:'center',}}>
                <TextField 
                    onClick={handleStartDateClick} 
                    value={startCtrld ? fromDate : startDate} 
                    onChange={(e)=>{
                        setStartDate(e.target.value); 
                        if(onFromDateChange && typeof onFromDateChange === 'function')
                            onFromDateChange(e.target.value); 
                    }} 
                    sx={{width:'130px'}} 
                    label='Start Date' 
                    placeholder='MM/DD/YYYY' 
                    autoComplete='off'
                    error={dateTextFieldError(startCtrld ? fromDate : startDate)}
                    color="primary"
                    focused={startDateFocused}
                    size={small ? 'small' : 'medium'}
                />
                <Typography sx={{mx:'13px'}}>to</Typography>
                <TextField 
                    onClick={handleEndDateClick} 
                    value={endCtrld ? toDate : endDate} 
                    onChange={(e)=>{
                        setEndDate(e.target.value); 
                        if(onToDateChange && typeof onToDateChange === 'function')
                            onToDateChange(e.target.value); 
                    }} 
                    sx={{width:'130px'}} 
                    label='End Date' 
                    placeholder='MM/DD/YYYY' 
                    autoComplete='off'
                    error={dateTextFieldError(endCtrld ? toDate : endDate)}
                    color="primary"
                    focused={endDateFocused}
                    size={small ? 'small' : 'medium'}
                   
                />
            </Box>

        
            <Popper 
                sx={{zIndex:2000}}
                open={popoverOpen}
                anchorEl={popoverAnchorRef.current}
                onClose={handlePopoverClose}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Paper sx={{display:'flex', boxShadow:'rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px'}}>
                    <Box sx={{width:'300px', height:'360px', p:'16px', borderRight:'1px solid #bbbbbb', overflow:'hidden'}}>
                        {renderMonthBar({onClickLeft:handleClickPopoverLeft, scrollDirection:'left', month:popoverMonth, year:popoverYear })}
                        {renderDayBar()}
                        {renderDayGrid({onClick:handleClickPopoverDate, 
                                        month:popoverMonth, 
                                        year:popoverYear, 
                                        daysRange:computeDaysOfMonth(popoverMonth, popoverYear)})
                        }
                    </Box>
                    <Box sx={{width:'300px', height:'360px', p:'16px', overflow:'hidden'}}>
                        {renderMonthBar({onClickRight:handleClickPopoverRight, scrollDirection:'right', month:(popoverMonth + 1) % 12, year:popoverMonth == 11 ? popoverYear+1 : popoverYear })}
                        {renderDayBar()}
                        {renderDayGrid({onClick:handleClickPopoverDate, 
                                        month:(popoverMonth + 1) % 12, 
                                        year:popoverMonth == 11 ? popoverYear+1 : popoverYear, 
                                        daysRange:computeDaysOfMonth((popoverMonth + 1) % 12, popoverMonth == 11 ? popoverYear+1 : popoverYear)})
                        }
                    </Box>
                </Paper>
            </Popper >
            </div>
        </ClickAwayListener>
        </Box>
        
    </React.Fragment>
    )
}

export default DateRangePicker;