import React, {useState, useEffect, useContext } from "react";

import { Box, Typography, TextField} from "@mui/material";

import StyledTextField from "../StyledTextField";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";


const MonthPickerFormInput = ({title, value, label, onChange, descriptionText, sx})=>{
        
    const handleChange = (val)=>{
        console.log(val)
        if(!val || isNaN(val.month()) || isNaN(val.year())){
            onChange(null);
            return;
        }
        let date = [val.month(),val.year()]
        onChange(date); 
        return;
    }

    return(
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <React.Fragment>
        <Typography align='left' sx={{mt:'16px', mb:descriptionText?'0px':'16px', ml:'16px'}}>{title}</Typography>
        {descriptionText && <Typography variant='body2' sx={{mb:'16px', ml:'16px'}}>{descriptionText}</Typography>} 
        <Box  sx={{mb:'16px', mx:'16px', display:'flex', flexDirection:'column', alignItems:'stretch', ...sx }}>
        <DatePicker   
            disablePast       
            views={['month','year']}
            value={value ? dayjs().month(value[0]).year(value[1]) : null}
            onChange={handleChange}
            renderInput={(params) => {let p = {...params}; p['inputProps']['placeholder']='Pick a start month'; return(<StyledTextField {...p} />)}}
        />
        </Box>
    </React.Fragment>
    </LocalizationProvider>
    )
}

export default MonthPickerFormInput;