import {useState,useEffect} from "react";

import { keyframes } from  '@mui/system';
import {Box} from '@mui/material';



const BorderPulsate = ({sx,startAnimation,children}) => {
        
    if(!sx)
        {sx={}}

    const pulsate = keyframes`
        0% {
            box-shadow: 0 0 0 0 rgba(50, 205, 50, 0.4);
        }
        70% {
            box-shadow: 0 0 0 10px rgba(50, 205, 50, 0);
        }
        100% {
            box-shadow: 0 0 0 0 rgba(50, 205, 50, 0);
        }
    `;

    return(
        
        <Box
            sx={{
                borderRadius:'4px',
                animation: startAnimation && `${pulsate} 2s infinite`,
                ...sx
            }}
        >
            {children}
        </Box>

    )
}

export default BorderPulsate;