import  {useState} from "react";

import { Tab, Tabs} from '@mui/material';

import TabPanel from "../../components/YesNoDialog"
import GrantDetails from "./GrantDetails";
import GrantRequirements from "./GrantRequirements";
import GrantEligibility from "./GrantEligibility";

const ViewGrant = ({gid}) => {

    const [tabValue,setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="data tabs">
                <Tab label="Grant Details" {...a11yProps(0)} />
                <Tab label="Eligibility Criteria" {...a11yProps(1)} />
                <Tab label="Application Requirements" {...a11yProps(2)} />
            </Tabs>

            <TabPanel value={tabValue} index={0}>
                <GrantDetails gid={gid}/>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <GrantEligibility gid={gid}/>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                <GrantRequirements gid={gid}/>
            </TabPanel>
        </>);
};

export default ViewGrant;