import React, {useState, useEffect, useContext } from "react";
import MainContext from "../../MainContext"; 

import {Box,Tab,Tabs,Typography,ButtonBase,CircularProgress,IconButton, Snackbar, TextField} from '@mui/material';
import Paper from "../../components/Paper";
import Theme from "../../Theme";
import fadein from "../../AnimationKeyframes/FadeIn";
import CondensedApplication from "../ppDashboard/CondensedApplication";



const ApplicationView = ({appId, onAlert, productData, userObject}) => {
    const mainService = useContext(MainContext);
    
    return (
        <React.Fragment>
        <Box sx={{flexGrow:1, display:'flex', flexDirection:'row'}}> 

            <Paper sx={{position:'relative', flexGrow:1, display:'flex', flexDirection:'column', overflow:'hidden', borderBottomRightRadius:"0px", borderBottomLeftRadius:"0px" }}>
                <CondensedApplication appid={appId} productData={productData} onAlert={onAlert} /> 
            </Paper>
        </Box>
        </React.Fragment>
    )
}



export default ApplicationView; 