import React, {useState, useEffect, useContext } from "react";
import MainContext from "../../MainContext";

import theme from '../../Theme';

import {Box,Typography, Skeleton, ButtonBase} from '@mui/material';


//card widths and heights
const widths = {xs:430}
const heights = {xs:400}

const ListingCard = ({listingId,onClick,loading,sx}) => {

    const mainService = useContext(MainContext);
    
    //STATES
    const [imgLoaded,setImgLoaded] = useState(false);
    const [listingData,setListingData] = useState(null);

    const listenListingData = (data) => {
        setListingData(data)
    }

    useEffect(()=>{
        if(listingId)
            mainService.productListingService.addPublicListingListener(listingId,listenListingData);
        return(()=>{
            if(listingId)
                mainService.productListingService.removePublicListingListener(listingId,listenListingData);
        })
    },[listingId])

    return(
        <>
        {loading ? (<Skeleton variant="rounded" width={ "430px"} height={"400px"}/>)
        :(
            <ButtonBase
            onClick={onClick}
            sx={{
                position:"relative",
                width:{...widths}, 
                height:{...heights}, 
                borderRadius:"8px",
                overflow:'hidden', 
                border: `1px solid ${theme.palette.grey[300]}`,
                display:"flex",
                flexDirection:"column",
                justifyContent:"flex-start",
                alignItems:"stretch",
                boxShadow: "rgb(95 116 141 / 3%) 0px 2px 1px -1px, rgb(95 116 141 / 4%) 0px 1px 1px 0px, rgb(95 116 141 / 8%) 0px 1px 3px 0px", 
                transform: 'translate(0, 0)',
                transition:"transform .2s cubic-bezier(.25,.46,.45,.94)",
                '&:hover':{
                    transform: 'translateY(-4px)',
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                    //boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                },
                ...sx
            }}
        >

            <Box 
                sx={{
                    position:"relative",
                    width:{xs:widths.xs-(theme.spacing(2)*2)}, 
                    height:{xs:260},
                    overflow:'hidden', 
                    mb:theme.spacing(2)}}
            >
                {!imgLoaded && <Skeleton variant="rectangular" width={widths.xs} height={260} sx={{position:"absolute"}}/>}
                <img onLoad={()=>{setImgLoaded(true)}}style={{ left:0, position:'absolute', width:"100%",height:"100%",objectFit:"cover"}} src={listingData?.featureImage?.url}/>
            </Box>
            
            
            <Box sx={{
                position:"absolute",
                backgroundColor:"white",
                left:"16px",
                top:"185px",
                width:"53px",
                height:"53px",
                border: `4px solid white`,
                borderRadius:"8px",
                }}
            >
                <Box 
                    sx={{
                        width:"100%",
                        height:"100%",
                        backgroundColor:"white",
                        borderRadius:"5px",
                        border: `1px solid ${theme.palette.grey[600]}`,
                        p:"2px"
                    }}
                >
                    <img style={{borderRadius:"3px",width:"100%",height:"100%",objectFit:"contain"}} src={listingData?.logoImage?.url}/>
                </Box>
            </Box>
            <Box sx={{p:theme.spacing(2), display:"flex",flexDirection:"column"}}>
                <Typography sx={{mt:'5px'}} align="left" variant="h6">{listingData?.title}</Typography>
                <Typography align="left" variant="body2">{listingData?.strapline}</Typography>
            </Box>

        </ButtonBase>
        )}
        </>
    )
}

export default ListingCard;