import  {Fragment, useState} from "react";


import { 
    DialogTitle, 
    DialogContent, 
    DialogActions,
    Button,
    TextField,
    OutlinedInput,
    FormControl, 
    FormControlLabel,
    InputLabel,
    Checkbox,
    Autocomplete,
    Select,
    MenuItem} from '@mui/material';

import ResponsiveDialog from "../../components/ResponsiveDialog";

export default function NewGrantRequirement({open,onClose,loading,newReqData,onSetReqData,onSetReqName,onSetReqType,onAdd,newReqNameOptions,newReqAutoCompleteVal}) {

    //STATES
    const [newReqStandardOptions,setNewReqStandardOptions] = useState(false);

    //render
    const renderInputRequirements = () => {
        let inputRequirements = null;
        if(newReqData['input'] && newReqData['input'] ==="text"){
            //length
            inputRequirements = (
                <FormControl>
                    <InputLabel htmlFor="char-length">Length characters (approx.) </InputLabel>
                    <OutlinedInput
                        disabled={loading}
                        onChange={(e)=>onSetReqData(e.target.value,"charLimit")}
                        size="small" 
                        id="char-length"
                        type="number"
                    />
                </FormControl >
            );
        }
        else if (newReqData['input'] && (newReqData['input'] === "singleSelect" || newReqData['input'] ==="multiSelect")){
            //options
            inputRequirements = (
                <Fragment>
                    <FormControlLabel control={<Checkbox value={newReqStandardOptions} onChange={(e)=>setNewReqStandardOptions(e.target.checked)}/>} label="Standard list" />
                    {newReqStandardOptions ?
                    (<FormControl sx={{width:"100%",mt:"8px"}}>
                        <InputLabel htmlFor="options-set">Options set</InputLabel>
                        <Select disabled={loading} onChange={(e)=>{onSetReqData(e.target.value,"optionsSet")}} id="input-type" size="small">
                                <MenuItem value="states">States</MenuItem>
                                <MenuItem value="countries">Countries</MenuItem>
                                <MenuItem value="taxStatus">Tax Status</MenuItem>
                                <MenuItem value="grades">Grades</MenuItem>
                        </Select>
                    </FormControl>):
                    (<TextField
                        id="new-requirement-options-input"
                        label="Add comma separated options"
                        onChange={(e)=>{onSetReqData(e.target.value,"options")}}
                    />)}
                </Fragment>
            );
        }
        else if (newReqData['input'] && newReqData['input'] === "fileUpload") {
            //file types
            inputRequirements = (<TextField
            id="new-requirement-filetypes-input"
            label="Add comma separated options for accepted filetypes"
            onChange={(e)=>onSetReqData(e.target.value,"fileTypes")}
            />);
        }

        return inputRequirements;
    }

  
    return (
        <ResponsiveDialog open={open} onClose={onClose}>
            <DialogTitle>Add new requirement</DialogTitle>
            <DialogContent sx={{display:"flex",gap:"8px",flexDirection:"column",minWidth:"600px"}}>

                <FormControl sx={{width:"100%",mt:"8px"}}>
                    <InputLabel htmlFor="requirement-type">Requirement type</InputLabel>
                    <Select disabled={loading} onChange={onSetReqType} id="requirement-type" size="small">
                            <MenuItem value="organization">Organization</MenuItem>
                            <MenuItem value="contact">Contact</MenuItem>
                            <MenuItem value="project">Project</MenuItem>
                            <MenuItem value="funding">Funding</MenuItem>
                            <MenuItem value="application">Application content</MenuItem>
                            <MenuItem value="references">References</MenuItem>
                            <MenuItem value="admin">Admin</MenuItem>
                    </Select>
                </FormControl>
                <Autocomplete
                    freeSolo
                    id="requirement-name"
                    options={newReqNameOptions}
                    inputValue={newReqAutoCompleteVal}
                    value={newReqAutoCompleteVal}
                    onInputChange={(e,val)=>{onSetReqName(val)}}
                    sx={{ width: "100%"}}
                    renderInput={(params) => <TextField {...params} size='small' label="Requirement name" />}
                />
                <FormControlLabel control={<Checkbox onChange={(e)=>onSetReqData(e.target.checked,"required")}/>} label="Required" />
                <TextField
                    id="new-requirement-question-text"
                    label="Question text"
                    multiline
                    maxRows={4}
                    minRows={2}
                    onChange={(e)=>onSetReqData(e.target.value,"questionText")}
                />
                <FormControl sx={{width:"100%",mt:"8px"}}>
                    <InputLabel htmlFor="input-type">Input type</InputLabel>
                    <Select disabled={loading} onChange={(e)=>onSetReqData(e.target.value,"input")} id="input-type" size="small">
                        <MenuItem value="textSmall">Text small</MenuItem>
                        <MenuItem value="textMedium">Text medium</MenuItem>
                        <MenuItem value="textLarge">Text large</MenuItem>
                        <MenuItem value="number">Number</MenuItem>
                        <MenuItem value="date">Date</MenuItem>
                        <MenuItem value="dateRange">Date range</MenuItem>
                        <MenuItem value="address">Address</MenuItem>
                        <MenuItem value="boolean">Check box</MenuItem>
                        <MenuItem value="singleSelect">Single select</MenuItem>
                        <MenuItem value="multiSelect">Multi select</MenuItem>
                        <MenuItem value="fileUpload">File upload</MenuItem>
                    </Select>
                </FormControl>
                {renderInputRequirements()}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
                <Button variant="contained" onClick={onAdd}>Done</Button>
            </DialogActions>
        </ResponsiveDialog>
    );
}