import React, {useState, useEffect, useContext } from "react";

import { Box, Typography, FormControlLabel, Checkbox} from "@mui/material";


const CheckBoxMultiSelectInput = ({values, title, onChange, options, descriptionText, sx})=>{
        
    const handleChange = (name,val)=>{
        onChange(name,val); 
    }

    const isChecked = (name) => {
        const arr = values ? values.split(',') : [];
        const nameInValues = arr.find(e => e==name);
        if(nameInValues)
            return true;
        return false;
    }


    return(
    <React.Fragment>
        <Typography align='left' sx={{mt:'16px', mb:descriptionText?'0px':'16px', ml:'16px'}}>{title}</Typography>
        {descriptionText && <Typography variant='body2' sx={{mb:'16px', ml:'16px'}}>{descriptionText}</Typography>} 
        <Box  sx={{mb:'16px', mx:'16px', display:'flex',flexDirection:'column', ...sx }}>
            {options.map((option)=>(
                <FormControlLabel 
                    key={option.name}
                    checked={values ? isChecked(option.name) : false}                  
                    onChange={(e)=>handleChange(option.name,e.target.checked)} 
                    control={<Checkbox  />} 
                    label={<Typography variant='body2'>{option.name}</Typography>} 
                />
            ))}
        </Box>
    </React.Fragment>
    )
}

export default CheckBoxMultiSelectInput;