import React, {useState, useEffect, useContext, useRef } from "react";
import { useNavigate, createSearchParams, useSearchParams } from "react-router-dom";
import MainContext from "../MainContext"; 
import ApplicationMenu from './applications/ApplicationMenu';
import Application from "./applications/Application";
import LoadingFull from "../components/LoadingFull";

const Applications = ({userObject, onOpenUpgrade,}) => {

    const [searchParams, setSearchParams] = useSearchParams()
    const mainService = useContext(MainContext);
    
    //STATE
    const [appMenuOpen, setAppMenuOpen] = useState(true); 
    const [currentAppId, setCurrentAppId] = useState(""); 
    const [currentProductId, setCurrentProductId] = useState(""); 
    const [tabValue,setTabValue] = useState(0);

    const [loading, setLoading] = useState(true); 

    //HANDLERS
    const handleAppMenuClose = ()=>{
        setAppMenuOpen(false);
    }

    const handleAppSelect = (appId)=>{
        setAppMenuOpen(false);
        setCurrentAppId(appId); 
    }

    const handleAppMenuClick = ()=>{
        setAppMenuOpen(true); 
    }

    const handleCreateApp = async (productId, name)=>{
        const newAppId = await mainService.applicationService.createNewApplication(name, userObject, productId); 
        setLoading(false); 
        if(newAppId)
            handleAppSelect(newAppId); 
    }

    const handleCreateProductAndApp = async () =>{
    
        const newAppId = await mainService.applicationService.createNewApplication('...', userObject); 
        setLoading(false); 
        if(newAppId){
            handleAppSelect(newAppId); 
        }

    }

    const handleUpgradeClick = () => {
        onOpenUpgrade();
    }

    useEffect(()=>{
        console.log('Applications mounted');
        return ()=> {
            console.log('Applications unmounted');
        }
        
    }, []);

    useEffect(()=>{
        setLoading(true); 
        
        if(userObject.accountstarted ){
            //start query actions
            console.log(searchParams.toString());

            if(searchParams.has('usertype')){
                searchParams.delete('usertype');
                setSearchParams(searchParams);
            }

            if(searchParams.get("module") === "applications" && searchParams.get("action")==='new' && searchParams.get('name') && searchParams.get('productid') && !currentProductId){
                
                //Create a new app if the query string is saying to do it. 
                let productId = searchParams.get('productid');
                let name = decodeURIComponent(searchParams.get('name'));
                searchParams.delete('module'); 
                searchParams.delete('action'); 
                searchParams.delete('productid'); 
                searchParams.delete('name');
                setSearchParams(searchParams); 
                setAppMenuOpen(false);//initially have app menu shut
                handleCreateApp(productId, name); 
            }
            else if (searchParams.get("module") === "applications" && searchParams.get("action")==='new' && !searchParams.get('name')) {
                searchParams.delete('module'); 
                searchParams.delete('action'); 
                setSearchParams(searchParams);
                setAppMenuOpen(false);//initially have app menu shut
                handleCreateProductAndApp(); 
            }
            else if (searchParams.get("module") === "applications" && searchParams.get("action")==='edit' && searchParams.get('appid')){
                const appid = searchParams.get('appid');
                const tab = searchParams.get('tab') ? parseInt(searchParams.get('tab')) : 0;
                searchParams.delete('module'); 
                searchParams.delete('action'); 
                searchParams.delete('appid'); 
                searchParams.delete('tab');
                setSearchParams(searchParams); 
                handleAppSelect(appid);
                setTabValue(tab);
                setLoading(false);
            }
            else{
                setLoading(false); 
            }
        }
    }, [userObject]);

    //RENDER
    return (
        <>
        {loading && (<LoadingFull/>)}

        {!loading && (
        <>
            <ApplicationMenu open={appMenuOpen} onOpenUpgrade={handleUpgradeClick} onClose={handleAppMenuClose} onAppSelect={handleAppSelect} onCreateAppClick={handleCreateProductAndApp}/>
            <Application appId={currentAppId} userObject={userObject} onAppMenuClick={handleAppMenuClick} tabValue={tabValue} onTabChange={(v)=>setTabValue(v)}/>
        </>
        )}
        </>
    )
}

export default Applications; 