import { Router, Navigate } from "react-router-dom";
import React, {useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";

import { ThemeProvider } from  '@mui/system';
import Theme from './Theme';

import MainService from "./MainService"; 
import MainContext from "./MainContext"; 

import {Box, CircularProgress} from '@mui/material';

const PrivateRoute = ((props) => {

	//USE
	const mainService = useContext(MainContext);
	let query =  useQuery();

	//STATES
	const [auth,setAuth] = useState(MainService.AUTHING);

	//EFFECTS
	useEffect(() => {
		//on mount
		setAuth(mainService.getAuthedState());
		mainService.addAuthChangeCallback( (authed)=>{
			setTimeout(()=>{ setAuth(authed); }, 500);
		}); 
		//on unmount
		return ()=>{
		
		}
	}, []);

	//RENDER
	const renderComponents = () => {
		let routeRender = null;
        
		if(auth === MainService.AUTHED){
			routeRender = ()=>{return props.children}; 
		}
		else if(auth === MainService.AUTHING){
			routeRender = ()=>{return(
			<ThemeProvider theme={Theme}>
				<Box sx={{marginTop: '64px', display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
					<CircularProgress />
				</Box>
			</ThemeProvider>
			)}; 
		}
		else {
			routeRender = ()=>{return(<Navigate to={ {pathname: `/login`, search: query.toString() ? `?${query.toString()}` : `` } }/>)}; 
		}
				
		return routeRender(); 
	}

	return(renderComponents());

});

export default PrivateRoute;

/*export default class PrivateRoute extends React.Component {
    static contextType = MainContext; 
	
	constructor(props){
		super(props); 
		this.state = {auth: MainService.AUTHING}; 
	}
	
	async componentDidMount(){
		const mainService = this.context;		
		this.setState({auth: mainService.getAuthedState()}); 
		mainService.addAuthChangeCallback( (authed)=>{
			setTimeout(()=>{ this.setState({auth: authed}); }, 500);
		}); 

	}
	
	componentWillUnmount(){
		
	}
	
	render(){
	
		let routeRender = null;
        
		if(this.state.auth === MainService.AUTHED){
			routeRender = ()=>{return this.props.children}; 
		}
		else if(this.state.auth === MainService.AUTHING){
			routeRender = ()=>{return(
			<ThemeProvider theme={Theme}>
				<Box sx={{marginTop: '64px', display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
					<CircularProgress />
				</Box>
			</ThemeProvider>
			)}; 
		}
		else {
			routeRender = ()=>{return(<Navigate to={ {pathname: '/login' } }/>)}; 
		}
		
		
		return routeRender(); 
	}
}*/