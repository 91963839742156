import React, {useState, useEffect, useContext, useRef } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import { styled, alpha } from '@mui/material/styles';

import Theme from '../../Theme';
import AirbnbSlider from '../../components/AirbnbSlider'; 

import MainService from "../../MainService"; 
import MainContext from "../../MainContext"; 

import { ThemeProvider } from  '@mui/system';
import { Box, Typography, Tabs, Tab, ButtonBase, Link, CircularProgress, Avatar, Button, IconButton, Switch, Tooltip, Popover, MenuList, MenuItem, ListItemIcon, ListItemText, Menu, Dialog, TextField } from "@mui/material";
import Slider, { SliderThumb, SliderValueLabelProps } from '@mui/material/Slider';

import DashboardIcon from '@mui/icons-material/Dashboard';

import Paper from "../../components/Paper";


import StyledTableCell from "../../components/StyledTableCell";
import StyledTableRow from "../../components/StyledTableRow";

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import ResponsiveDialog from "../../components/ResponsiveDialog";

import {teacherRoleNames, allUSSchoolGrades, allUSStates, beautifyString} from "../../Util"; 

import * as dayjs from 'dayjs'; 
import CanvasDocumentRenderer from "./CanvasDocumentRenderer";

//reduce icon 
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
//expand icon
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

import TextDecreaseIcon from '@mui/icons-material/TextDecrease';

import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

import SpellcheckIcon from '@mui/icons-material/Spellcheck';

import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import AddIcon from '@mui/icons-material/Add';
import ContentCopy from '@mui/icons-material/ContentCopy';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ViewDayIcon from '@mui/icons-material/ViewDay';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import StyledButton from "../../components/StyledButton";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { ContentCut, ContentPaste, Delete } from "@mui/icons-material";

const CondensedApplication = ({uid, appid, productData, viewOnly, onAlert})=>{

    const mainService = useContext(MainContext);
    const canvasRef = useRef();
    const scrollRef = useRef();  

    const pointerCaptureRef = useRef(); 

    const [documentSettings, setDocumentSettings] = useState({
        titleFontSize:24, 
        subtitleFontSize:18, 
        paragraphFontSize:13, 
        refFontSize:11,
        fontFace:'Inter', 
        lineSpacing:1.5,
        beforeSpacing:0,
        afterSpacing:8,
    }); 

    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]); 

    const [canvasDocumentRenderer, setCanvasDocumentRenderer] = useState(new CanvasDocumentRenderer()); 

    const [barOpen, setBarOpen] = useState(false); 

    const [applicationMetadata, setApplicationMetadata] = useState({}); 
    const [applicationContent, setApplicationContent] = useState({});  
    const [applicationStyle, setApplicationStyle] = useState({}); 

    const [budgetSummary,setBudgetSummary] = useState({});
    const [budgetSections,setBudgetSections] = useState({});
    const [budgetLines,setBudgetLines] = useState({});

    const [editorHeight, setEditorHeight] = useState(0); 

    const [userData, setUserData] = useState(null); 

    const [aiEditPanelOpen, setAiEditPanelOpen] = useState(false); 
    const [aiEditPanelVisible, setAiEditPanelVisible] = useState(false); 
    const [aiEditPanelOpaic, setAiEditPanelOpaic] = useState(false); 
    const [aiEditPanelPositionY, setAiEditPanelPositionY] = useState(0); 

    const [modifiers, setModifiers] = useState([]); 
    const [modifierYs, setModifierYs] = useState([]); 

    const [modifierLoading, setModifierLoading] = useState(false); 
    const [modifierInvalidSelection, setModifierInvalidSelection] = useState(null); 

    const [nodeWidgets, setNodeWidgets] = useState([]); 

    const [hoverNodes, setHoverNodes] = useState(new Set()); 

    const [hoverBetween, setHoverBetween] = useState({}); 

    const [paragraphMenuOpen, setParagraphMenuOpen] = useState(false);
    const [paragraphMenuNodeId, setParagraphMenuNodeId] = useState(''); 
    const [paragraphMenuAnchor, setParagraphMenuAnchor] = useState(null); 

    const [sectionMenuOpen, setSectionMenuOpen] = useState(false);
    const [sectionMenuNodeId, setSectionMenuNodeId] = useState(''); 
    const [sectionMenuAnchor, setSectionMenuAnchor] = useState(null); 

    const [addMenuOpen, setAddMenuOpen] = useState(false);
    const [addMenuNodeId, setAddMenuNodeId] = useState(''); 
    const [addMenuHoverBetween, setAddMenuHoverBetween] = useState(null); 
    const [addMenuAnchor, setAddMenuAnchor] = useState(null); 
    const [addMenuValidTypes, setAddMenuValidTypes] = useState(new Map())

    const [questionMenuOpen, setQuestionMenuOpen] = useState(false);
    const [questionMenuParentInfo, setQuestionMenuParentInfo] = useState(''); 
    const [questionMenuText, setQuestionMenuText] = useState(''); 
    const [questionMenuAnchor, setQuestionMenuAnchor] = useState(null); 
    const [questionMenuLoading, setQuestionMenuLoading] = useState(false); 

    const [downloadMenuOpen, setDownloadMenuOpen] = useState(false);
    const [downloadMenuAnchor, setDownloadMenuAnchor] = useState(null); 


    const userObjectListner = (data)=>{
        setUserData(data); 
        //console.log(data); 
    }

    const onApplicationMetadata = (data)=>{
        setApplicationMetadata(data); 
    }

    const onApplicationContent = (data)=>{
        /*const canvas = canvasRef.current;
        if (canvas) {
            const deviceScale = window.devicePixelRatio ? window.devicePixelRatio : 1;
            const scaledWidth = Math.floor(canvas.clientWidth * deviceScale); 
            const scaledHeight = Math.floor(canvas.clientHeight * deviceScale); 
            canvasDocumentRenderer.setCanvasSize(scaledWidth, scaledHeight); 
        }
        canvasDocumentRenderer.buildFromNodeData(data, applicationStyle); 
        */
        //renderCanvas();
        setApplicationContent(data); 
    }

    const onApplicationStyle = (data)=>{
        setApplicationStyle(data); 
    }

    const listenBudgetSummary = (v) => {
            setBudgetSummary(v);        
    }

    const listenBudgetSections = (v) => {
        setBudgetSections(v);
    }

    const listenBudgetLines = (v) => {
        setBudgetLines(v);
    }

    const listenModifiers = (v) => {
        
        let modifiersArray = []; 

        if(v){
            let keys = Object.keys(v); 
            keys.sort(); 
            for(let mkey of keys){
                let m = v[mkey]; 
                m.id = mkey; 
                modifiersArray.push(m);
            }
        }
        
        setModifiers(modifiersArray);
        canvasDocumentRenderer.setModifiers(modifiersArray); 
    }

    const getDownloadTitle = (ext, inclDate=true)=>{

        let firstNode = mainService.applicationService.queryFirstNode(applicationContent, {tags:['applicationTitle']})
        let grantTitle = mainService.applicationService.queryText(applicationContent, firstNode.key); 

        //Add date stamp
        if(inclDate){
            let date = new Date();
            let dateSplit = date.toISOString().split('T'); 
            let dateStr = dateSplit[0]; 
            let time = dateSplit[1].split('.')[0]; 
            grantTitle += ' ' + dateStr + '_' + time;
        }
        //add ext and return 
        grantTitle += ext;

        return grantTitle; 
    }

    const onSelectionFinished = (dragSelectAABB, textSelection,)=>{

        let hasTextSelection = textSelection.hasSelection;

        setAiEditPanelOpen(hasTextSelection); 

        if(hasTextSelection){

            if(textSelection.nodes.length > 1){
                setModifierInvalidSelection({id:'multipleParagraphs'}); 
            }else{
                setModifierInvalidSelection(null); 
            }
            let dpr = window.devicePixelRatio ? window.devicePixelRatio : 1; 
            setAiEditPanelPositionY(textSelection.boundingBox.miny / dpr)
        }
    }

    const onNodesGeometryUpdate = (nodeGeometry, nodeData)=>{

        let dpr = window.devicePixelRatio ? window.devicePixelRatio : 1; 

        //when the geometry updates , the node update is done and renderin can take place, 
        //rebuild any node widgets
        let nodeWidgets = [];
        //console.log(nodeData); 
        //console.log(nodeGeometry); 
        for(let [nodeid, geometry] of nodeGeometry){
            let node = nodeData[nodeid];
            if(node && geometry && (node.type == 'section' || node.type == 'heading1' || node.type == 'paragraph' || node.type == 'container')){
                nodeWidgets.push({
                    nodeid:nodeid, 
                    x:geometry.x / dpr, 
                    y:geometry.y / dpr, 
                    w:geometry.w / dpr, 
                    h:geometry.h / dpr, 
                    node:node,
                }); 
            }
        }
        setNodeWidgets(nodeWidgets);
    }

    const onNodeHoverUpdate = (hoverNodes)=>{
        setHoverNodes(hoverNodes);
    }

    const onNodeHoverBetweenUpdate = (hoverBetween)=>{
        let dpr = window.devicePixelRatio ? window.devicePixelRatio : 1; 
        hoverBetween.x /= dpr; 
        hoverBetween.y /= dpr; 
        hoverBetween.w /= dpr; 
        setHoverBetween(hoverBetween); 
    }

    const onModifierGeometryUpdate = (modifiers)=>{
        if (!modifiers)
            return; 
        
        //get all modifier bounds 
        let modifierBounds = [];
        for(let m of modifiers){
            if(m && m.selection && m.selection.boundingBox)
                modifierBounds.push(m.selection.boundingBox);
            else 
                modifierBounds.push(null);
        }

        //
        let ys = []; 
        let inx = 0;
        let dpr = window.devicePixelRatio ? window.devicePixelRatio : 1; 
        for(let b of modifierBounds){
            if(b)
                ys.push( ((b.miny + b.maxy) / 2) / dpr - 30);
            else {
                if(modifierYs.lenght == modifierBounds.length)
                    ys.push(modifierYs[inx] );
                else 
                    ys.push(0);
            } 
            inx++;
        }
        setModifierYs(ys); 
    }

    //Creates a new modifier
    const onModifierClick = (modifierType, y)=>{
       
        let m = {type:modifierType.id, name:modifierType.name, y, color:modifierType.color, enabled:false, loading:true}; 

        mainService.applicationService.addModifier(appid, m, canvasDocumentRenderer.textSelection, applicationContent); 

        canvasDocumentRenderer.forceDeselect(); 
    }   

    //toggles the modifier on and off
    const onModifierToggleClick = (modifierId, value)=>{
        console.log('toggle clicked'); 
        mainService.applicationService.setModifierToggle(appid, modifierId, value); 
        canvasDocumentRenderer.forceDeselect(); 
    }

    const onModifierDeleteClick = (modifierId)=>{
        console.log('delete clicked'); 
        mainService.applicationService.deleteModifier(appid, modifierId); 
    }

    const onModifierApplyClick = (modifierId)=>{
        console.log('apply clicked'); 
        mainService.applicationService.applyModifier(appid, modifierId); 
    }


    const onParagraphMenuClick = (nodeId, clickEvent)=>{
        setParagraphMenuOpen(true); 
        setParagraphMenuNodeId(nodeId); 
        setParagraphMenuAnchor(clickEvent.currentTarget); 
    }

    const onParagraphMenuItemClick = (nodeId, itemType)=>{
        if(itemType == 'delete'){
            mainService.applicationService.deleteContentNode(appid, nodeId); 
        }
        else if(itemType == 'moveDown'){
            mainService.applicationService.moveContentNode(appid, nodeId, 'down'); 
        }
        else if(itemType == 'moveUp'){
            mainService.applicationService.moveContentNode(appid, nodeId, 'up'); 
        }
        else if(itemType == 'copyText'){
            mainService.applicationService.getContentNodeText(appid, nodeId)
            .then((text)=>{
                navigator.clipboard.writeText(text)
                if(onAlert)
                    onAlert('Text copied to clipboard','success');
            }) 
        }
        setParagraphMenuOpen(false); 
    }

    const onSectionMenuClick = (nodeId, clickEvent)=>{
        setSectionMenuOpen(true); 
        setSectionMenuNodeId(nodeId); 
        setSectionMenuAnchor(clickEvent.currentTarget); 
    }

    const onSectionMenuItemClick = (nodeId, itemType)=>{
        if(itemType == 'delete'){
            mainService.applicationService.deleteContentNode(appid, nodeId); 
        }
        else if(itemType == 'moveDown'){
            mainService.applicationService.moveContentNode(appid, nodeId, 'down'); 
        }
        else if(itemType == 'moveUp'){
            mainService.applicationService.moveContentNode(appid, nodeId, 'up'); 
        }
        else if(itemType == 'copyText'){
            mainService.applicationService.getContentNodeText(appid, nodeId)
            .then((text)=>{
                navigator.clipboard.writeText(text)
                if(onAlert)
                    onAlert('Text copied to clipboard','success');
            }) 
        }
        setSectionMenuOpen(false); 
    }



    const onAddMenuClick = (hoverBetween, clickEvent)=>{
        
        //get all types and therefore all valid add types
        let validAddTypes = new Set(); 
        for(let [nodeid, node] of hoverBetween.betweenNodes){
            if(node.type == 'paragraph' || node.type == 'heading1' || node.type == 'heeading2'){
                validAddTypes.add('paragraph'); 
            }
            else if(node.type == 'section'){
                validAddTypes.add('section')
                validAddTypes.add('question')
            }
        }

        setAddMenuOpen(true); 
        setAddMenuHoverBetween(hoverBetween);
        setAddMenuAnchor(clickEvent.currentTarget); 
        setAddMenuValidTypes(validAddTypes); 
    }

    const onAddMenuItemClick = async (type)=>{

        //compute the hover node with the type, and compute the index in the parents list, and the parent id.
        let firstOfType = (type)=>{
            //find the first node of type paragraph and run 
            let parentInfo = null; 
            for(let [nodeid, node] of hoverBetween.betweenNodes){
                parentInfo = hoverBetween.parentMap.get(nodeid);
                if(node.type == type)
                    break;
            }
            return parentInfo; 
        }
        
        //apply the add New ....
        if(type == 'paragraph'){
            let parentInfo = firstOfType('paragraph');
            console.log("add paragraph", parentInfo);  
            if (parentInfo)
                mainService.applicationService.addNewParagraph(appid, parentInfo.id, parentInfo.childIndex+1); 
        }
        else if(type == 'section'){
            let parentInfo = firstOfType('section');
            console.log("add section", parentInfo);  
            if (parentInfo)
                mainService.applicationService.addNewSection(appid, parentInfo.id, parentInfo.childIndex+1); 
        }
        else if(type == 'question'){
            let parentInfo = firstOfType('section');
            if (parentInfo){
                setQuestionMenuParentInfo(parentInfo); 
                onQuestionMenuClick(); 
            }
            
            console.log("add question", parentInfo);  
        }
        setAddMenuOpen(false); 
    }

    const onQuestionMenuClick = ()=>{
        setQuestionMenuOpen(true); 
    }

    const onQuestionMenuClose = ()=>{
        setQuestionMenuOpen(false); 
        setQuestionMenuParentInfo(null);
        
    }

    const onGenerateQuestionAnswerClick = async ()=>{

        if(questionMenuParentInfo){
            setQuestionMenuLoading(true); 
            //generate the section and add title, then start the generation (server call)
            setQuestionMenuOpen(false); 
            await mainService.applicationService.addNewQuestionAnswer(appid, applicationMetadata, userData, productData, questionMenuParentInfo.id, questionMenuParentInfo.childIndex+1, questionMenuText, ['QUESTION ANSWER...']); 
            setQuestionMenuText(''); 
            setQuestionMenuLoading(false); 
        }
        
    }

    useEffect(()=>{

        mainService.applicationService.addApplicationMetadataListner(appid, onApplicationMetadata );
        mainService.applicationService.addApplicationAllContentListener(appid, onApplicationContent );
        mainService.applicationService.addApplicationAllStyleListener(appid, onApplicationStyle );

        mainService.applicationService.addBudgetSummaryListener(appid, listenBudgetSummary);
        mainService.applicationService.addBudgetLinesListener(appid, listenBudgetLines);
        mainService.applicationService.addBudgetSectionsListener(appid, listenBudgetSections);

        mainService.applicationService.addModifiersListner(appid, listenModifiers); 

        //on first run rebuild from generators
        mainService.applicationService.updateBudgetInApp(appid);
        mainService.applicationService.updateSourcesInApp(appid);

        return ()=>{
            mainService.applicationService.removeApplicationMetadataListner(appid, onApplicationMetadata );
            mainService.applicationService.removeApplicationAllContentListener(appid, onApplicationContent );
            mainService.applicationService.removeApplicationAllStyleListener(appid, onApplicationStyle );

            mainService.applicationService.removeBudgetSummaryListener(appid, listenBudgetSummary);
            mainService.applicationService.removeBudgetLinesListener(appid, listenBudgetLines);
            mainService.applicationService.removeBudgetSectionsListener(appid, listenBudgetSections);

            mainService.applicationService.removeModifiersListner(appid, listenModifiers); 
		}

	}, [appid]); 


    useEffect(() => {

        let latestTimeout; 

        const onResize = (e)=>{
            setWindowSize([window.innerWidth, window.innerHeight]); 
        }

        if(window)
            window.addEventListener("resize", onResize);
        return () => {
            if(window)
                window.removeEventListener("resize", onResize);
        }
    }, []);

    useEffect(()=>{
        const canvas = canvasRef.current;
        if (canvas) {
            const deviceScale = window.devicePixelRatio ? window.devicePixelRatio : 1;
            const scaledWidth = Math.floor(canvas.clientWidth * deviceScale); 
            const scaledHeight = Math.floor(canvas.clientHeight * deviceScale); 
            canvasDocumentRenderer.setCanvasSize(scaledWidth, scaledHeight); 
        }

        canvasDocumentRenderer.buildFromNodeData(applicationContent, applicationStyle); 
        renderCanvas(); 
    
    }, [applicationContent, applicationStyle, budgetSummary, budgetSections, budgetLines, modifiers, windowSize]); 


    useEffect(()=>{
		mainService.ppDashboardService.addUserObjectListner(uid, userObjectListner); 
        
		return ()=>{
            mainService.ppDashboardService.removeUserObjectListner(uid, userObjectListner);
		}
	}, [uid]);

    useEffect(()=>{
        canvasDocumentRenderer.addSelectionFinishedListner(onSelectionFinished)
        canvasDocumentRenderer.addModifierGeometryUpdateListner(onModifierGeometryUpdate); 
        canvasDocumentRenderer.addNodesGeometryUpdateListner(onNodesGeometryUpdate); 
        canvasDocumentRenderer.addHoverUpdateListner(onNodeHoverUpdate); 
        canvasDocumentRenderer.addHoverBetweenUpdateListner(onNodeHoverBetweenUpdate); 

        return ()=>{
            canvasDocumentRenderer.removeSelectionFinishedListner(onSelectionFinished);
            canvasDocumentRenderer.removeModifierGeometryUpdateListner(onModifierGeometryUpdate); 
            canvasDocumentRenderer.removeNodesGeometryUpdateListner(onNodesGeometryUpdate); 
            canvasDocumentRenderer.removeHoverUpdateListner(onNodeHoverUpdate); 
            canvasDocumentRenderer.removeHoverBetweenUpdateListner(onNodeHoverBetweenUpdate); 
		}
    }, [canvasDocumentRenderer]);

    //handle edit panel fading in and out
    useEffect(()=>{
        let currentOpen = aiEditPanelOpen; 
        let timeout = 0; 
        if(!currentOpen){
            setAiEditPanelOpaic(false); 
            timeout = setTimeout(()=>setAiEditPanelVisible(false), 500); 
        }
        else{
            setAiEditPanelVisible(true); 
            timeout = setTimeout(()=>setAiEditPanelOpaic(true), 1); 
        }

        return()=>{
            if(!currentOpen){
                clearTimeout(timeout); 
            }
        }
    }, [aiEditPanelOpen]);

    const renderCanvas = ()=>{
        const canvas = canvasRef.current;
        const scrollEl = scrollRef.current;
        
        if(!canvas || !scrollEl)
            return; 

        const width = canvas.clientWidth;
        const height = canvas.clientHeight;

        const deviceScale = window.devicePixelRatio ? window.devicePixelRatio : 1;
        const scaledWidth = Math.floor(width * deviceScale); 
        const scaledHeight = Math.floor(height * deviceScale); 

        if (canvas.width !== scaledWidth || canvas.height !== scaledHeight) {
            canvas.width = scaledWidth;
            canvas.height = scaledHeight;
        }

        canvasDocumentRenderer.setCanvas(canvas); 

        canvasDocumentRenderer.setScroll(scrollEl.scrollTop); 

        let newEditorHeight = canvasDocumentRenderer.draw(); 

        if(editorHeight != newEditorHeight){        
            setEditorHeight(newEditorHeight ); 
        }
    }

    const handleDownloadClick = (type)=>{
        if(type == 'pdf'){
            let title = getDownloadTitle('pdf'); 
            canvasDocumentRenderer.renderToPdf( title ); 
        }
        else if(type=='docx'){
            let title = getDownloadTitle('docx'); 
            canvasDocumentRenderer.renderToDocx( title ); 
        }
        handleDownloadMenuClose(); 
    }

    const handleDownloadButtonClick = (e)=>{
        setDownloadMenuAnchor(e.currentTarget); 
        console.log(e.currentTarget)
        setDownloadMenuOpen(true); 
    }

    const handleDownloadMenuClose = ()=>{
        setDownloadMenuOpen(false); 
    }

    const handlePointerDown = (e)=>{
        e.preventDefault()
        
        
        let rect = e.target.getBoundingClientRect();
        let x = e.clientX - rect.left; //x position within the element.
        let y = e.clientY - rect.top;  //y position within the element.
        canvasDocumentRenderer.onPointerDown(x, y); 
        e.target.setPointerCapture(e.pointerId);
        pointerCaptureRef.current.focus(); 
    }

    const handlePointerUp = (e)=>{
        e.preventDefault()
        let rect = e.target.getBoundingClientRect();
        let x = e.clientX - rect.left; //x position within the element.
        let y = e.clientY - rect.top;  //y position within the element.
        canvasDocumentRenderer.onPointerUp(x, y);
    }

    const handlePointerMove = (e)=>{
        e.preventDefault()
        let rect = e.target.getBoundingClientRect();
        let x = e.clientX - rect.left; //x position within the element.
        let y = e.clientY - rect.top;  //y position within the element.
        canvasDocumentRenderer.onPointerMove(x, y);
    }

    const handlePointerLeave = (e)=>{
        e.preventDefault()
        let rect = e.target.getBoundingClientRect();
        let x = e.clientX - rect.left; //x position within the element.
        let y = e.clientY - rect.top;  //y position within the element.
        canvasDocumentRenderer.onPointerLeave();
    }

    const handleKeyDown = (e)=>{
        e.preventDefault(); 

        //if there is no selection dont continue
        let textSelection = canvasDocumentRenderer.textSelection; 
        if(!textSelection || textSelection.hasSelection == false && textSelection.hasCaret == false){
            return;
        }

        //if the ctrl key is down, action ctrl commands!
        if(e.ctrlKey){
            if(e.key=='v' || e.key=='V'){
               
                return;
            }
            if(e.key=='c' || e.key=='C'){
               
                return;
            }
        }

        //if arrow keys pressed, follow default behaviour of moving seletion
        if(e.key=='ArrowRight' || e.key=='ArrowLeft' || e.key=='ArrowUp' || e.key=='ArrowDown'){
            let dir = e.key=='ArrowRight' ? 'right' : 
                      e.key=='ArrowLeft' ? 'left' : 
                      e.key=='ArrowUp' ? 'up' : 
                      e.key=='ArrowDown' ? 'down' : 
                      ''; 
            canvasDocumentRenderer.moveTextSelection(dir, false); 
            return; 
        }

        //handle enter key
        else if(e.key == 'Enter'){
        }

        //Handle backspace
        else if(e.key=='Backspace'){
            //run delete
            if(textSelection.hasSelection == false && textSelection.hasCaret == true){
                let charCount = mainService.applicationService.typeChar(appid, textSelection, applicationContent, 'backspace');
                if(charCount == -1)
                    canvasDocumentRenderer.moveTextSelection('left', false);
            }
        }

        //Handle delete
        else if(e.key=='Delete'){
            //run delete
            if(textSelection.hasSelection == false && textSelection.hasCaret == true){
                let charCount = mainService.applicationService.typeChar(appid, textSelection, applicationContent, 'delete');
                //if(charCount == -1)
                    //canvasDocumentRenderer.moveTextSelection('right', false);
            }
        }

        //handle char key 
        else if (e.key.length == 1 && !e.ctrlKey){
            let newChar = e.key;
            let textSelection = canvasDocumentRenderer.textSelection; 

            //run insert
            if(textSelection.hasSelection == false && textSelection.hasCaret == true){

                let charCount = mainService.applicationService.typeChar(appid, textSelection, applicationContent, newChar);
                if(charCount == 1)
                    canvasDocumentRenderer.moveTextSelection('right', false, true); 
            }
        }
    }

    const onContextMenu = (e)=>{
        e.preventDefault(); 
    }

    let aiEditModifiers= [
        {name:'Expand on this', icon:<OpenInFullIcon/>, id:'expand', color:'#9AE3B7', },
        {name:'Shorten this point', icon:<CloseFullscreenIcon/>, id:'shortenPoint', color:'#F0A8A8',},
        {name:'Remove unnessisary detail', icon:<TextDecreaseIcon/>, id:'removeDetail', color:'#95DBDA',},
        {name:'Provide evidence', icon:<FormatQuoteIcon/>, id:'provideEvidence', color:'#9BAADD',},
        {name:'Improve wording', icon:<SpellcheckIcon/>, id:'improveWording', color:'#E6CDA3',},
    ]; 

    return (
    <ThemeProvider theme={Theme}>
    <Box draggable={false} sx={{position:'absolute', width:'100%', height:'100%', display:'flex', flexDirection:'column'}}>
        <canvas draggable={false} ref={canvasRef} style={{position:'absolute', width:'100%', height:'100%', }}/>
        <Box draggable={false} ref={scrollRef} onScroll={()=>renderCanvas()} sx={{position:'absolute', width:'100%', height:'100%', overflow:'auto', cursor:'text'}}>
            <Box draggable={false} sx={{position:'absolute', width:'100%', height:`${editorHeight}px`}} 
                
            >
                <Box ref={pointerCaptureRef} draggable={false} sx={{position:'absolute', width:'100%', height:`100%`, outline:'none',}} 
                    onPointerDown={handlePointerDown} 
                    onPointerUp={handlePointerUp} 
                    onPointerMove={handlePointerMove}
                    onPointerLeave={handlePointerLeave}
                    onKeyDown={handleKeyDown}
                    onContextMenu={onContextMenu}
                    tabIndex={0}
                />

                {/*HOVER BETWEEN NODES*/}
                <Box draggable={false} 
                    sx={{
                        position:'absolute', 
                        top: hoverBetween.y+'px', 
                        left: hoverBetween.x+'px',
                        width: hoverBetween.w+'px', 
                        height: '2px', 
                        backgroundColor:'#B8D4FC',
                        pointerEvents:'none',
                        opacity:hoverBetween && hoverBetween.nodeId && !aiEditPanelOpen ? 1:0,
                        display:hoverBetween && hoverBetween.nodeId && !aiEditPanelOpen ? 'default' : 'none',
                    }}
                />
                <Box draggable={false} sx={{
                    position:'absolute', 
                    top: (hoverBetween.y-15)+'px',
                    left: hoverBetween.x+hoverBetween.w+15+'px', 
                    display:hoverBetween && hoverBetween.nodeId && !aiEditPanelOpen ? 'default' : 'none',
                }}>
                    <IconButton size='small' color='#B8D4FC' sx={{color:'#B8D4FC',}} onClick={(e)=>onAddMenuClick(hoverBetween, e)}>
                        <AddIcon/>
                    </IconButton>
                </Box>
                

                {/*NODE WIDGETS */}
                {nodeWidgets.map((widget)=>(
                    <>
                    {/*Border around node*/}
                    { hoverNodes.has(widget.nodeid) && (widget.nodeid == paragraphMenuNodeId) && (paragraphMenuOpen || sectionMenuOpen) && (widget.node.editable || widget.node.type == 'section') &&
                    <Box draggable={false} 
                        sx={{
                            position:'absolute', 
                            top:  widget.y+'px', 
                            left:  widget.node.type == 'section' ? widget.x-90+'px' : widget.x+'px', 
                            width: widget.node.type == 'section' ? widget.w+180+'px' : widget.w+'px', 
                            height: widget.h+'px', 
                            border: `1px solid #dddddd`,
                            //border: widget.node.type == 'section' ? 'initial' : `1px solid #dddddd`,
                            //borderTop: widget.node.type == 'section' ? `1px solid #dddddd` : 'default',
                            //borderBottom: widget.node.type == 'section' ? `1px solid #dddddd` : 'default',
                            pointerEvents:'none',
                            //transition:'opacity 0.25s', 
                        }}
                    />
                    }
                
                    {hoverNodes.has(widget.nodeid) && ( widget.node.type == 'section' ||  widget.node.type == 'paragraph') &&
                    <Box draggable={false} sx={{
                        position:'absolute', 
                        top: widget.node.type == 'section' ?  (widget.y)+'px' : (widget.y+widget.h/2 - 20)+'px', 
                        left: widget.node.type == 'section' ? (widget.x-95)+'px' : (widget.x-60)+'px', 
                        display:!aiEditPanelOpen ? 'initial':'none',
                        opacity:hoverNodes.has(widget.nodeid) ? 1 : 0,
                        pointerEvents:hoverNodes.has(widget.nodeid) ? 'initial' : 'none',
                        //transition:'opacity 0.25s', 
                    }}>
                        { widget.node.type == 'section' ? (
                            <IconButton size='small' onClick={(e)=>onSectionMenuClick(widget.nodeid, e)} >       
                                <DragIndicatorIcon/>
                            </IconButton>
                        ): widget.node.type == 'paragraph' ? (
                            <IconButton size='small' onClick={(e)=>onParagraphMenuClick(widget.nodeid, e)}>
                                <MoreVertIcon/>
                            </IconButton>
                        ):(
                            null
                        )}
                    </Box>
                    }
                    
                    {widget.nodeid && applicationContent[widget.nodeid] && applicationContent[widget.nodeid].loading && 
                    <Box draggable={false} 
                        sx={{
                            position:'absolute', 
                            top:  widget.y+'px', 
                            left: widget.x-60+'px', 
                            width: widget.w+'px', 
                            height: widget.h+'px', 
                            pointerEvents:'none',
                    }}>
                        <CircularProgress/>
                    </Box>
                    }

                    {/*
                    <Box draggable={false} sx={{
                        position:'absolute', 
                        top: widget.node.type == 'section' ? (widget.y)+'px' : (widget.y+widget.h/2 - 20)+'px', 
                        left: widget.node.type == 'section' ? (widget.x+widget.w+60)+'px' : (widget.x+widget.w+10)+'px', 
                        //display:hoverNodes.has(widget.nodeid) ? 'initial':'none',
                        opacity:hoverNodes.has(widget.nodeid) ? 1 : 0,
                        pointerEvents:hoverNodes.has(widget.nodeid) ? 'initial' : 'none',
                        //transition:'opacity 0.25s', 
                    }}>
                        {widget.node.deletable && 
                        <IconButton size='small' color='#eeeeee'>
                            <DeleteIcon/>
                        </IconButton>
                        }
                    </Box>
                    */}

                    </>
                ))}


                {/*Paragraph MENU POPUP*/}
                {paragraphMenuOpen && paragraphMenuNodeId && paragraphMenuAnchor && 
                    <Menu sx={{
                        '& .MuiPaper-root':{
                            backgroundColor:'#ffffff', border:`1px solid ${Theme.palette.grey[300]}`, borderRadius:'16px', 
                            boxShadow:'0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)', 
                        }
                    }}
                        open={paragraphMenuOpen} onClose={()=>setParagraphMenuOpen(false)} anchorEl={paragraphMenuAnchor} anchorOrigin={{vertical: 'bottom',horizontal: 'left',}} transformOrigin={{vertical: 'top',horizontal: 'center',}} 
                    >
                            <Typography align="center" sx={{fontSize:'10pt', fontWeight:'700', mb:'8px',}}>Paragraph</Typography>
                            <MenuItem onClick={()=>onParagraphMenuItemClick(paragraphMenuNodeId, 'moveUp')}>
                                <ListItemIcon> <KeyboardArrowUpIcon/> </ListItemIcon>
                                <ListItemText>Move Up</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={()=>onParagraphMenuItemClick(paragraphMenuNodeId, 'moveDown')}>
                                <ListItemIcon> <KeyboardArrowDownIcon/> </ListItemIcon>
                                <ListItemText>Move Down</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={()=>onParagraphMenuItemClick(paragraphMenuNodeId, 'delete')}>
                                <ListItemIcon> <DeleteIcon/> </ListItemIcon>
                                <ListItemText>Delete</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={()=>onParagraphMenuItemClick(paragraphMenuNodeId, 'copyText')}>
                                <ListItemIcon> <ContentCopy/> </ListItemIcon>
                                <ListItemText>Copy Text</ListItemText>
                            </MenuItem>
                    </Menu>
                }

                {/*Section MENU POPUP*/}
                {sectionMenuOpen && sectionMenuNodeId && sectionMenuAnchor && 
                    <Menu sx={{
                        '& .MuiPaper-root':{
                            backgroundColor:'#ffffff', border:`1px solid ${Theme.palette.grey[300]}`, borderRadius:'16px', 
                            boxShadow:'0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)', 
                        }
                    }}
                        open={sectionMenuOpen} onClose={()=>setSectionMenuOpen(false)} anchorEl={sectionMenuAnchor} anchorOrigin={{vertical: 'bottom',horizontal: 'left',}} transformOrigin={{vertical: 'top',horizontal: 'center',}} 
                    >
                            <Typography align="center" sx={{fontSize:'10pt', fontWeight:'700', mb:'8px',}}>Section</Typography>
                            <MenuItem onClick={()=>onSectionMenuItemClick(sectionMenuNodeId, 'moveUp')}>
                                <ListItemIcon> <KeyboardArrowUpIcon/> </ListItemIcon>
                                <ListItemText>Move Up</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={()=>onSectionMenuItemClick(sectionMenuNodeId, 'moveDown')}>
                                <ListItemIcon> <KeyboardArrowDownIcon/> </ListItemIcon>
                                <ListItemText>Move Down</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={()=>onSectionMenuItemClick(sectionMenuNodeId, 'delete')}>
                                <ListItemIcon> <DeleteIcon/> </ListItemIcon>
                                <ListItemText>Delete</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={()=>onSectionMenuItemClick(sectionMenuNodeId, 'copyText')}>
                                <ListItemIcon> <ContentCopy/> </ListItemIcon>
                                <ListItemText>Copy Text</ListItemText>
                            </MenuItem>
                    </Menu>
                }


                {/*Add MENU POPUP*/}
                {addMenuOpen && addMenuHoverBetween && addMenuAnchor && 
                    <Menu sx={{
                        '& .MuiPaper-root':{
                            backgroundColor:'#ffffff', border:`1px solid ${Theme.palette.grey[300]}`, borderRadius:'16px', 
                            boxShadow:'0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)', 
                        }
                    }}
                        open={addMenuOpen} onClose={()=>setAddMenuOpen(false)} anchorEl={addMenuAnchor} anchorOrigin={{vertical: 'bottom',horizontal: 'left',}} transformOrigin={{vertical: 'top',horizontal: 'center',}} 
                    >
                        {addMenuValidTypes.has('paragraph') && 
                            <MenuItem onClick={()=>onAddMenuItemClick('paragraph')}>
                                <ListItemIcon> <ViewHeadlineIcon/> </ListItemIcon>
                                <ListItemText>Paragraph</ListItemText>
                            </MenuItem>
                        }
                        {addMenuValidTypes.has('section') && 
                            <MenuItem onClick={()=>onAddMenuItemClick('section')}>
                                <ListItemIcon> <ViewDayIcon/> </ListItemIcon>
                                <ListItemText>Section</ListItemText>
                            </MenuItem>
                        }
                        {addMenuValidTypes.has('question') && 
                            <MenuItem onClick={()=>onAddMenuItemClick('question')}>
                                <ListItemIcon> <HelpCenterIcon/> </ListItemIcon>
                                <ListItemText>Question</ListItemText>
                            </MenuItem>
                        }
                    </Menu>
                }


                {/*AI MODIFIER POPUP */}
                <Box draggable={false} sx={{
                    position:'absolute', top:aiEditPanelPositionY, left:'30px', width:'200px', height:'260px', 
                    backgroundColor:'#ffffff', border:`1px solid ${Theme.palette.grey[300]}`, borderRadius:'16px', 
                    boxShadow:'0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)', 
                    opacity:aiEditPanelOpaic?1:0, 
                    transition:'opacity 0.5s, top 0.5s',
                    display:aiEditPanelVisible?'flex':'none',
                    pointerEvents:aiEditPanelVisible?'auto':'none',
                    flexDirection:'column', 
                    userSelect:'none',
                }}
                >
                    {/*!modifierInvalidSelection &&
                    <Box sx={{display:'flex', justifyContent:'space-evenly', mt:'5px', mb:'8px'}}>
                        <Tooltip title="Delete Text">
                            <IconButton size='small'>
                                <Delete/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Cut Text">
                        <IconButton size='small'>
                            <ContentCut/>
                        </IconButton>
                        </Tooltip>
                        <Tooltip title="Paste Text">
                        <IconButton size='small'>
                            <ContentPaste/>
                        </IconButton>
                        </Tooltip>
                        <Tooltip title="Copy Text">
                        <IconButton size='small'>
                            <ContentCopy/>
                        </IconButton>
                        </Tooltip>
                    </Box>
                    */}
                
                    {/*<Typography variant='body' align="center" sx={{fontWeight:900, fontSize:'12pt', mt:'10px', mb:'6px'}}>AI Modify</Typography>*/}
                    {modifierInvalidSelection ? (
                        <>
                        {modifierInvalidSelection && modifierInvalidSelection.id == 'multipleParagraphs' &&
                        <Typography align="left" sx={{ml:'8px', fontSize:'10pt', mt:'16px'}}>You can only select text within a single paragraph for edits.</Typography>
                        }
                        </>
                    ):(
                        <>
                        {aiEditModifiers.map((m)=>(
                        <ButtonBase disabled={!aiEditPanelOpen} onClick={()=>onModifierClick(m, aiEditPanelPositionY)} 
                            sx={{mx:'8px', height:'40px', justifyContent:'flex-start', ':hover':{backgroundColor:'#f3f4f9'}, backgroundColor:'initial'}}
                        >
                            {m.icon && m.icon}
                            <Typography align="left" sx={{ml:'8px', fontSize:'10pt'}}>{m.name}</Typography>
                        </ButtonBase>
                        ))}
                        </>
                    )}
                </Box>


                {/*QUESTION QUESTION UI*/}
                <Dialog open={questionMenuOpen} onClose={()=>onQuestionMenuClose()} sx={{
                    '& .MuiPaper-root':{
                        backgroundColor:'#ffffff', border:`1px solid ${Theme.palette.grey[300]}`, borderRadius:'16px', 
                        boxShadow:'0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)', 
                    }, 
                }} maxWidth='lg'>
                    {questionMenuLoading ? (
                        <Box sx={{p:'16px', display:'flex', flexDirection:'column', width:'600px', alignItems:'center', justifyContent:'center'}}><CircularProgress/></Box>
                    ):(
                    <Box sx={{p:'16px', display:'flex', flexDirection:'column', width:'600px'}}>
                        <Typography variant='body' align="center" sx={{fontWeight:900, fontSize:'14pt', mb:'16px'}}>Answer a Question</Typography>
                        <Typography variant='body' sx={{fontWeight:900, fontSize:'11pt', mb:'16px'}}>Add the question</Typography>
                        <TextField value={questionMenuText} onChange={(e)=>setQuestionMenuText(e.target.value)} variant="outlined"></TextField>
                        <Box sx={{display:'flex'}}>
                            <Box sx={{flexGrow:1}}/>
                            <StyledButton onClick={()=>onQuestionMenuClose()} colors={['#AAAAAA', '#CCCCCC']} text={'Cancel'} sx={{my:'16px'}}/>
                            <StyledButton onClick={()=>onGenerateQuestionAnswerClick()} text={'Generate Answer'} sx={{my:'16px', ml:'16px'}}/>
                        </Box>
                    </Box>
                    )}
                </Dialog>

                {/*MODIFIERS */}
                {modifiers.map((m, inx)=>(
                    <Box key={m.id} sx={{
                        position:'absolute', top:inx < modifierYs.length ? modifierYs[inx] : 0, right:'0px', width:'230px', 
                        backgroundColor:m.color, 
                        //border:`1px solid #bbbbbb`, 
                        borderRadius:'16px', 
                        transition:'top 0.25s',
                        flexDirection:'column', 
                        display:'flex',
                        userSelect:'none',
                    }}
                    
                    >
                        <Typography variant='body' align="left" sx={{fontWeight:900, fontSize:'9pt', ml:'16px', mt:'8px', }}>{m.name}</Typography>
                        {m.loading ? (
                        <Box sx={{display:'flex', ml:'8px', alignItems:'center', pb:'8px'}}>
                            <Box sx={{flexGrow:1}}/>
                            <CircularProgress size='30px'/>
                            <Box sx={{flexGrow:1}}/>
                            <Box sx={{display:'flex', mr:'5px'}}>
                                <Tooltip title='Cancel change' enterDelay={500}>
                                    <IconButton size='small' onClick={()=>onModifierDeleteClick(m.id)}>
                                        <ClearIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                        ):(
                        <Box sx={{display:'flex', ml:'8px', alignItems:'center',}}>
                           
                            <Box sx={{/*border:`1px solid #999999`,*/ borderRadius:'16px', height:'25px', display:'flex', my:'8px', }}>
                                <ButtonBase sx={{width:'53px', backgroundColor:m.enabled?'#CFECE2':'#54DD95', /*borderRight:'1px solid #bbbbbb',*/ display:'flex', alignItems:'center', justifyContent:'center', 
                                    borderTopLeftRadius:'16px', borderBottomLeftRadius:'16px', }}
                                    onClick={()=>onModifierToggleClick(m.id, false)}
                                >
                                    <Typography sx={{fontWeight:900, fontSize:'8pt', }}>Original</Typography>
                                </ButtonBase>
                                <ButtonBase sx={{width:'53px', backgroundColor:m.enabled?'#54DD95':'#CFECE2', display:'flex', alignItems:'center', justifyContent:'center',
                                    borderTopRightRadius:'16px', borderBottomRightRadius:'16px',}}
                                    onClick={()=>onModifierToggleClick(m.id, true)}
                                >
                                    <Typography sx={{fontWeight:'bold', fontSize:'8pt', }}>New</Typography>
                                </ButtonBase>
                            </Box>

                            <Box sx={{flexGrow:1}}/>
                            <Box sx={{display:'flex', mr:'5px'}}>
                                <Tooltip title='Cancel change' enterDelay={500}>
                                    <IconButton size='small' onClick={()=>onModifierDeleteClick(m.id)}>
                                        <ClearIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title='Commit change' enterDelay={500}>
                                    <IconButton size='small' onClick={()=>onModifierApplyClick(m.id)}>
                                        <DoneIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                        )}
                    </Box>
                ))}

                
            </Box>
        </Box>
        <ButtonBase onClick={handleDownloadButtonClick} sx={{
            position:'absolute',
            display:'flex',  
            top:'0px', 
            left:'0px', 
            height:'32px',
            px:'8px', 
            transform: barOpen || true ? 'scaleX(1)' : 'scaleX(0)', 
            transformOrigin:'top left',
            transition:'transform 0.3s', 
            backgroundColor:'#ffffff',
            borderBottomRightRadius:'8px',
            border:`1px solid ${Theme.palette.grey[300]}`, 
            //boxShadow:'0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)', 
        }}>
            <FileDownloadIcon/>
            <Typography sx={{ml:'5px'}}>Export</Typography>
        </ButtonBase>
        <Menu
            anchorEl={downloadMenuAnchor}
            open={downloadMenuOpen}
            onClose={handleDownloadMenuClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
        >
            <MenuItem onClick={()=>handleDownloadClick('pdf')}>Export as PDF</MenuItem>
            {/*<MenuItem onClick={()=>handleDownloadClick('docx')}>Export as DOCX</MenuItem>*/}
        </Menu>
    </Box>

    </ThemeProvider>
    )
}

export default CondensedApplication; 