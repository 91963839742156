import React, {useState, useEffect} from "react";

import { Autocomplete, Typography } from "@mui/material";

import StyledTextField from "../StyledTextField";

import Theme from "../../Theme";

const SimpleAutocomplete = ({value, title, valueName, labelName, small, disabled, freeSolo, options, onChange, variant, sx})=>{
    
    const [val,setVal] = useState("")
    const [freeSoloOptions,setFreeSoloOptions] = useState(null);
        
    const handleChange = (e,option)=>{
        if(freeSolo)
            return
        console.log("on change")
        console.log(option);
        if(option){
            const newVal = option[valueName];
            onChange(newVal); 
        }
    }

    const handleInputChange = (e,option)=>{
        if(!freeSolo)
            return
        console.log("on input change");
        console.log(option);
        onChange(option);        
    }

    const handleSetVal = (v) => {
        const option = options.find(el=>el[valueName]===v)
        if(option)
            setVal(option)
    }

    const handleGetOptionLabel = (option) => {
        const val = option[labelName] ? option[labelName] : option;
        return val;
    }

    //Effects
	useEffect(() => {
		console.log('autocomplete value updated');        
        if(freeSolo){
            const fsOptions = options.map(e=>e[valueName]);
            setVal(value);
            setFreeSoloOptions(fsOptions)
        }
        else{
            handleSetVal(value)
        }

	}, [value]);

    return(
        <Autocomplete
            autoComplete
            id={encodeURIComponent(title)}
            value={val}
            inputValue={freeSolo && val}
            options={freeSolo ? freeSoloOptions : options}
            freeSolo={freeSolo}
            onChange={(e,val)=>handleChange(e,val)}
            onInputChange={(e,val)=>handleInputChange(e,val)}
            getOptionLabel={handleGetOptionLabel}
            sx={{...sx}}
            disabled={disabled}
            renderInput={(params) => <StyledTextField {...params} InputProps={{...params.InputProps,sx: small && {...Theme.typography.body2}}}  size={small && "small"} label={<Typography variant={small && 'body2'}>{title}</Typography>} variant={variant ? variant : 'outlined' } />}          
        />
    )
}

export default SimpleAutocomplete;

