import React, {useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import theme from '../../Theme';
import MainContext from "../../MainContext"; 

import { ThemeProvider } from  '@mui/system';
import { Box, Typography, ButtonBase, IconButton, Chip, Switch } from "@mui/material";

import AddBoxIcon from '@mui/icons-material/AddBox';
import PreviewIcon from '@mui/icons-material/Preview';
import ShareIcon from '@mui/icons-material/Share';
import SettingsIcon from '@mui/icons-material/Settings';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import BottomSlidingPanel from "../../components/BottomSlidingPanel"; 
import StyledButton from "../../components/StyledButton";
import ShareProductDialog from "./ShareProductDialog";
import ManageProductsDialog from "./ManageProductsDialog";
import ItemMenu from "../../components/ItemMenu";


const ProductListingsMenu = ({open, onListingSelect, onCreateListing, onLoading, onAlert, userObject, collectionId, collectionData}) => {


    //Use
	const mainService = useContext(MainContext);
	const navigate = useNavigate(); 

    //STATES
    const [listingsData,setListingsData] = useState({});
    const [shareDlgOpen,setShareDlgOpen] = useState(false);
    const [manageDlgOpen,setManageDlgOpen] = useState(false);
    const [currentLid,setCurrentLid] = useState(null);
    const [currentListingData,setCurrentListingData] = useState(null);
    const [itemMenuOpen,setItemMenuOpen] = useState(false);
    const [itemMenuAnchor, setItemMenuAnchor] = useState(null);
    
    //HANDLERS
    const handleListingClick = (listingId,creationStage)=>{
        if(onListingSelect)
            onListingSelect(listingId,creationStage); 
    }

    const handleShareDlgClose = () => {
        setCurrentLid(null);
        setCurrentListingData(null);
        setShareDlgOpen(false);
    }

    const handleShareDlgOpen = (lid) => {
        setCurrentLid(lid);
        setCurrentListingData(listingsData[lid]);
        setShareDlgOpen(true);
    }

    const handleManageDlgClose = () => {
        setManageDlgOpen(false);
    }

    const handleManageDlgOpen = () => {
        setManageDlgOpen(true);
    }

    const handleThreeDotsOpen = (e, id)=>{
        setCurrentLid(id);
        setItemMenuAnchor(e.currentTarget);
        setItemMenuOpen(true);
    }

    const handleThreeDotsClose = (e) => {
        setItemMenuAnchor(null);
        setItemMenuOpen(false);
    }

    const handleDeleteListing = async () => {
        console.log('deleting listing: ' + currentLid);
        onLoading(true);
        try{
            await mainService.productListingService.deleteProductListing(currentLid);
            onAlert('Listing deleted successfully','success');
        }
        catch(error){
            onAlert('Error deleting listing, contact support','error');
        }
        onLoading(false);
    }

    const handleCloneListing = async () => {
        console.log('cloning listing: ' + currentLid);
        onLoading(true);
        try{
            await mainService.productListingService.cloneProductListing(currentLid,userObject);
            onAlert('Listing cloned successfully','success');
        }
        catch(error){
            onAlert('Error cloning listing, contact support','error');
        }
        onLoading(false);
    }

    //LISTENERS
    const listingsListListener = (listingsObj)=>{
        if(listingsObj){
            let listings = {};
            for(const lid of Object.keys(listingsObj)){
                if(listingsObj[lid]){
                    listings[lid] = listingsObj[lid];
                }
            }
            setListingsData({...listings});
        }
    }
    
    //Effects
	useEffect(()=>{
		//on mount
        console.log('Product listings menu mounted'); 
		mainService.productListingService.addListListener(listingsListListener);

		//on unmount
		return ()=>{
			console.log('Product listings menu  unmounted');
            mainService.productListingService.removeListListener(listingsListListener);
		}

	}, []);


    return (
        <>
        <BottomSlidingPanel open={open}>
            <Box sx={{height:'70px', display:'flex', alignItems:'center', px:'24px'}}>
                <Typography variant='h4'>My Product listings</Typography> 
                <Box sx={{flexGrow:1}}/>
                <IconButton onClick={handleManageDlgOpen}><SettingsIcon/></IconButton>
            </Box>

            <Box sx={{height:'80px', display:'flex', alignItems:'center', px:'24px', }}>
                <StyledButton text='Add New Product' icon={()=><AddBoxIcon sx={{ml:'8px', color:'#ffffff'}} color='action'/>} onClick={onCreateListing}></StyledButton>
            </Box>

            <Box sx={{height:'30px', display:'flex', alignItems:'center', pl:'24px',borderBottom:`1px solid ${theme.palette.grey[300]}`}}>
                <Typography variant='subtitle2' sx={{flexGrow:1}}>Title</Typography> 
                <Typography variant='subtitle2' align="left" sx={{width:"160px"}}>View listing</Typography>
                <Typography variant='subtitle2' align="left" sx={{width:"160px"}}>Share listing</Typography>
                <Typography variant='subtitle2' align="left" sx={{width:"111px"}}>Status</Typography>
                <Typography variant='subtitle2'sx={{width:"120px"}}>Created Date</Typography>
                <Box sx={{width:'32px'}}/>
            </Box>

            {Object.keys(listingsData).length > 0 &&
            <Box sx={{flexGrow:1, position:'relative', overflow:'hidden'}}>
                <Box sx={{position:'absolute', width:'100%', height:'100%', overflow:'auto', display:'flex', flexDirection:'column'}}>
                    {Object.keys(listingsData).map((lk)=>                    
                    (                       
                        <Box key={lk} sx={{position:'relative', minHeight:'50px',}}>
                            <Box 
                                
                                sx={{
                                    position:'absolute', 
                                    width:'100%', 
                                    height:'100%', 
                                    display:'flex', 
                                    
                                    pl:'24px', 
                                    borderBottom:`1px solid ${theme.palette.grey[300]}`, 
                                    backgroundColor:'#FFFFFF', 
                                    '&:hover': {backgroundColor:'#FAFAFA'}
                                }}>
                                    <ButtonBase onClick={ ()=>handleListingClick(lk,listingsData[lk]?.creationStage)} sx={{alignItems:'center', flexGrow:1}}>
                                        <Typography  align='left' variant='body2' sx={{width:"200px"}}>
                                            {listingsData[lk].title}  
                                        </Typography>                                    
                                        
                                        <Box sx={{flexGrow:1}}/>
                                    </ButtonBase>

                                    {listingsData[lk].status === "published" &&
                                    <>
                                    <Box sx={{width:'160px'}}>
                                        <IconButton sx={{width:'48px',height:'48px'}} onClick={()=>window.open(`/product?listingid=${lk}`)}>
                                            <PreviewIcon/>
                                        </IconButton>
                                    </Box>

                                    <IconButton sx={{width:'48px',height:'48px'}} onClick={()=>handleShareDlgOpen(lk)}>
                                        <ShareIcon/>
                                    </IconButton>
                                    </>
                                    }
                                    
                                    <ButtonBase onClick={ ()=>handleListingClick(lk,listingsData[lk]?.creationStage)} sx={{alignItems:'center', pl:'75px'}}>
                                        <Chip label={listingsData[lk].status} variant={listingsData[lk].status === "draft" ? "outlined":"contained"} 
                                            color='success' 
                                            sx={{width:'100px', height:'26px', mr:'32px',
                                            backgroundColor:listingsData[lk].status === "published" ? theme.palette.success.light : 
                                                            listingsData[lk].status === "review" ? theme.palette.warning.light : 'default', 
                                            color:!(listingsData[lk].status === "published" || listingsData[lk].status === "review") ? '#000000' : '#FFFFFF'}} 
                                        />                         
                                        <Typography align='left' variant='body2'sx={{width:"120px"}}>{mainService.getDateString(listingsData[lk].createdDate)}</Typography>
                                    </ButtonBase>
                                    <Box sx={{width:'32px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                    <IconButton size='small' onClick={(e)=>handleThreeDotsOpen(e,lk)}>
                                        <MoreVertIcon />
                                    </IconButton>
                                    </Box>
                            </Box>
                        </Box>
                    )
                    )}                    
                </Box>
            </Box>
            }
        </BottomSlidingPanel>
        <ShareProductDialog open={shareDlgOpen} onClose={handleShareDlgClose} listingId={currentLid} listingData={currentListingData} onAlert={onAlert} />
        <ManageProductsDialog open={manageDlgOpen} collectionId={collectionId} collectionData={collectionData} onClose={handleManageDlgClose} onAlert={onAlert} />
        <ItemMenu 
            open={itemMenuOpen} 
            anchorEl={itemMenuAnchor}
            handleClose={handleThreeDotsClose}
            actions={[{name:'Clone listing',action:handleCloneListing},{name:'Delete listing', action:handleDeleteListing}]}
        />
        </>
    )
}

export default ProductListingsMenu;