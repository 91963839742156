import React, {useState, useEffect, useContext, useTheme } from "react";
import { useNavigate } from "react-router-dom";

import Theme from '../Theme';

import MainContext from "../MainContext"; 

import { ThemeProvider } from  '@mui/system';
import { Box, Typography, Tabs, Tab, CircularProgress, IconButton, FormControl, Select, OutlinedInput, MenuItem } from "@mui/material";

import Paper from "../components/Paper";

import fadein from "../AnimationKeyframes/FadeIn";

import DateRangePicker from "../components/formInputs/DateRangePicker";

import ResponsiveDialog from "../components/ResponsiveDialog";
import DataVizPanel from "./ppDashboard/DataVizPanel";
import TableVizPanel from "./ppDashboard/TableVizPanel";

import AddBoxIcon from '@mui/icons-material/AddBox';

import StyledButton from '../components/StyledButton'; 

import LoadingFull from "../components/LoadingFull";

import FilterAltIcon from '@mui/icons-material/FilterAlt';

import * as dayjs from 'dayjs'


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function MultipleSelectWithPlaceholder({items, value, onChange}) {
  
    const handleChange = (event) => {
        const {target: { value },} = event;
        console.log(value)
    };
  
    return (
      <div>
        <FormControl sx={{ mx: '16px', width: 300,}}>
          <Select
            multiple
            displayEmpty
            value={value}
            onChange={onChange}
            input={<OutlinedInput />}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>All Products</em>;
              }
  
              return selected.map((k)=>items[k].title).join(', ');
            }}
            MenuProps={MenuProps}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem disabled value="">
              <em>All Products</em>
            </MenuItem>
            {Object.entries(items).filter((item)=>item[1]).map(([id, obj]) => (
              <MenuItem
                key={id}
                value={id}
              >
                {obj.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
}

const PPDashboard = ({userObject, openChatPopup, billingId}) => {
    const navigate = useNavigate();

    const mainService = useContext(MainContext);

    const [initialLoading, setInitialLoading] = useState(true); 

    const [tabValue, setTabValue] = useState(0);
    const [filterDialogOpen, setFilterDialogOpen] = useState(false); 

    const [mainAnim, setMainAnim] = useState(`${fadein} 0.5s linear 0s 1`); 
    const [mainOpacity, setMainOpacity] = useState('1');

    const [data, setData] = useState({}); 
    const [products, setProducts] = useState({}); 

    const [myData,setMyData] = useState({});
    const [myProducts,setMyProducts] = useState({});

    const [orgData,setOrgData] = useState({});
    const [orgProducts,setOrgProducts] = useState({});

    const [fromDate, setFromDate] = useState(dayjs(userObject.createddate).format('MM/DD/YYYY'));
    const [toDate, setToDate] = useState(dayjs(Date.now()).format('MM/DD/YYYY'));
    const [productFilter, setProductFilter] = useState([]); 
    const [view,setView] = useState('myProducts')

    const dashboardDataListner = (dashboardData)=>{
        setMyData(dashboardData); 
        //setData(dashboardData);
        //console.log('data', dashboardData)
    }; 

    const productListingListner = (products)=>{
        //setProducts(products); 
        setMyProducts(products); 
        //console.log('products ',products);
    }

    const dashboardOrgDataListner = (dashboardData)=>{
        //setData(dashboardData);
        setOrgData(dashboardData); 
        console.log('data', dashboardData)
    }; 

    const productListingOrgListner = (products)=>{
        //setProducts(products); 
        setOrgProducts(products); 
        console.log('products ', products);
    }

    //Effects
	useEffect(()=>{

        mainService.ppDashboardService.addDashboardDataListner(dashboardDataListner);
        mainService.productListingService.addListListener(productListingListner); 

        if(billingId){
            mainService.ppDashboardService.addOrgDashboardDataListner(billingId, dashboardOrgDataListner);
            mainService.productListingService.addOrgListListener(billingId, productListingOrgListner); 
        }

        setTimeout(()=>{setInitialLoading(false)}, 1000);

		//on unmount
		return ()=>{
			console.log('PP dashboard, unmounted');
            mainService.ppDashboardService.removeDashboardDataListner(dashboardDataListner);
            mainService.productListingService.removeListListener(productListingListner); 
            if(billingId){
                console.log('removing org listeners');
                mainService.ppDashboardService.removeOrgDashboardDataListner(billingId, dashboardOrgDataListner);
                mainService.productListingService.removeOrgListListener(billingId, productListingOrgListner); 
            }
		}

	}, [billingId,initialLoading,view]); 

    useEffect(()=> {
        if(view === 'myProducts' && !initialLoading){
            setProducts(myProducts);
            setData(myData);
        }
        else if(view === 'orgProducts' && !initialLoading){
            setProducts(orgProducts);
            setData(orgData)
        }
    },[view,initialLoading])

    const handleTabValueChange = (event, newValue) => {
        triggerFadeAnim(); 
        setTabValue(newValue);
    };

    const triggerFadeAnim = ()=>{
        setMainAnim(``);
        setMainOpacity(0);
        setTimeout(()=>{setMainAnim(`${fadein} 0.5s linear 0s 1`); setMainOpacity(1);}, 0);
    }


    const handleFromDateChange = (from)=>{
        setFromDate(from); 
    }
    
    const handleToDateChange = (to)=>{
        setToDate(to); 
    }

    const handleCreateNewProductClick = () => {
        window.open('/home?module=products&action=new', '_self'); 
        //navigate('/home?module=products&action=new');
    }

    const handleFilterButtonClick = ()=>{
        setFilterDialogOpen(true); 
    }

    const handleFilterDialogClose = ()=>{
        setFilterDialogOpen(false); 
    }

    let from = dayjs(fromDate)
    let to = dayjs(toDate)
    let datesValid =from.isValid() && to.isValid() && from.isBefore(to); 

    let hasData = Object.keys(data).length > 0;
    let hasProducts = products && Object.keys(products).length > 0;

    /*let hasMyData = Object.keys(myData).length > 0;
    let hasMyProducts = myProducts && Object.keys(myProducts).length > 0;

    let hasOrgData = Object.keys(orgData).length > 0;
    let hasOrgProducts = orgProducts && Object.keys(orgProducts).length > 0;*/

    return (
    <ThemeProvider theme={Theme}>
        <Box sx={{position:'absolute', width:'100%', height:'100%', overflow:'auto', display:'flex', flexDirection:'column', }}>
            <Box sx={{display:'flex', flexGrow:1, flexDirection:'row', justifyContent:'center',}}>
            <Box sx={{display:'flex', flexGrow:1, maxWidth:'1200px', flexDirection:'column',mx:'32px', mb:'32px', mt:'16px'}}>
              
            { hasData && !initialLoading && 
            <React.Fragment>

                <Box sx={{mb:'12px', display:'flex', flexDirection:'column'}}>

                    {/*
                    <Paper sx={{ px:'16px', py:'16px', flexGrow:1, display:'flex', alignItems:'center'}}>
                    */}
                    <Box sx={{mb:'12px', display:'flex', flexDirection:'row',position:'relative'}}>
                        <Box sx={{ display:'flex', alignItems:'center',zIndex:10}}>
                            {/*<DashboardIcon sx={{mr:'16px'}} color="action" />*/}
                            <Typography variant='h4'>My Dashboard</Typography>
                            <>
                            <Typography sx={{ml:'16px',mr:'8px'}}>View</Typography>
                            <Select id="select-view" variant="standard" value={view} onChange={(e)=>setView(e.target.value)}>
                                <MenuItem value={'myProducts'}>my products</MenuItem>
                                <MenuItem value={'orgProducts'}>my org's products</MenuItem>
                            </Select>
                            </>
                        </Box>


                        <Box sx={{flexGrow:1}}/>

                        <Box sx={{position:'absolute',display:'flex',width:'100%',justifyContent:'center',alignItems:'center'}}>
                        <Tabs value={tabValue} onChange={handleTabValueChange} aria-label="application-tabs">
                            <Tab sx={{textTransform:'none'}} label="Overview"/>
                            <Tab sx={{textTransform:'none'}} label="Applications"/>
                        </Tabs>
                        </Box>

                        <Box sx={{flexGrow:1}}/>

                        <IconButton onClick={handleFilterButtonClick}>
                            <FilterAltIcon/>
                        </IconButton>
                    </Box>
                    {/*
                    </Paper>
                    */}
                </Box>
                
                {datesValid && hasData && 
                <Box sx={{ display:'flex', flexDirection:'column', flexGrow:1, animation:mainAnim, opacity:mainOpacity}}>
                {tabValue == 0 ? (
                    <DataVizPanel data={data} fromDate={fromDate} toDate={toDate} datesValid={datesValid} productFilter={productFilter}/>
                ):(
                    <TableVizPanel data={data} fromDate={fromDate} toDate={toDate} datesValid={datesValid} productFilter={productFilter} openChatPopup={openChatPopup}/>
                )}
                </Box>
                }

                {!datesValid && hasData &&
                <Box sx={{flexGrow:1, display:'flex', flexDirection:'column'}}>
                    <Paper sx={{ px:'16px', py:'6px', flexGrow:1, display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Typography align='center' variant="h1">Choose a date range to look at.</Typography>
                    </Paper>
                </Box> 
                }

            </React.Fragment>
            }



            {!hasData && !hasProducts && !initialLoading &&
            <Box sx={{mb:'12px', display:'flex', flexDirection:'column', flexGrow:1, animation:mainAnim, opacity:mainOpacity}}>
                <Box sx={{display:'flex',alignItems:'center', mb:'4px'}}>
                    <Typography variant='h4'>My Dashboard</Typography>
                    <>
                    <Typography sx={{ml:'16px',mr:'8px'}}>View</Typography>
                    <Select id="select-view" variant="standard" value={view} onChange={(e)=>setView(e.target.value)}>
                        <MenuItem value={'myProducts'}>my products</MenuItem>
                        <MenuItem value={'orgProducts'}>my org's products</MenuItem>
                    </Select>
                    </>
                </Box>
                <Paper sx={{ px:'16px', py:'16px', flexGrow:1, display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
                    <Typography align='center' gutterBottom variant="h1" sx={{mb:'32px'}}>
                        Welcome to your dashboard!
                    </Typography>
                    <Typography align='center' gutterBottom variant="h3" sx={{mb:'32px'}}>
                        You don't have any product listings yet so click the button bellow to start making one.
                    </Typography>
                    <StyledButton text='Add New Product' icon={()=><AddBoxIcon sx={{ml:'8px', color:'#ffffff'}} color='action'/>} onClick={ handleCreateNewProductClick }/>
                </Paper>
            </Box>   
            }



            {!hasData && hasProducts && !initialLoading &&
            <Box sx={{mb:'12px', display:'flex', flexDirection:'column', flexGrow:1, animation:mainAnim, opacity:mainOpacity}}>
                <Box sx={{display:'flex',alignItems:'center', mb:'4px'}}>
                    <Typography variant='h4'>My Dashboard</Typography>
                    <>
                    <Typography sx={{ml:'16px',mr:'8px'}}>View</Typography>
                    <Select id="select-view" variant="standard" value={view} onChange={(e)=>setView(e.target.value)}>
                        <MenuItem value={'myProducts'}>my products</MenuItem>
                        <MenuItem value={'orgProducts'}>my org's products</MenuItem>
                    </Select>
                    </>
                </Box>
                <Paper sx={{ px:'16px', py:'16px', flexGrow:1, display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
                    <Typography align='center' gutterBottom variant="h1" sx={{mb:'32px'}}>
                        Welcome to your dashboard!
                    </Typography>
                    <Typography align='center' gutterBottom variant="h3" sx={{mb:'32px'}}>
                        You don't have any data to report yet 
                    </Typography>
                </Paper>
            </Box>   
            }


            {initialLoading &&
            <Box sx={{position:'relative', mb:'12px', display:'flex', flexDirection:'column', flexGrow:1, animation:mainAnim, opacity:mainOpacity}}>
                <LoadingFull/>
            </Box>  
            }



            </Box>
            </Box>
        </Box>

        <ResponsiveDialog open={filterDialogOpen} onClose={handleFilterDialogClose}>
            <Box sx={{position:'relative', my:'32px'}}>
                <Typography sx={{mx:'16px', mb:'16px'}} gutterBottom variant="h2">Filters</Typography>
                <Typography sx={{mx:'16px', mb:'24px'}} gutterBottom variant="h5">Date range</Typography>
                <DateRangePicker fromDate={fromDate} toDate={toDate} onFromDateChange={handleFromDateChange} onToDateChange={handleToDateChange} small={false} sx={{mb:'16px', mr:'16px'}}/> 
                <Typography sx={{mx:'16px', mb:'24px'}} gutterBottom variant="h5">Products</Typography>       

                <MultipleSelectWithPlaceholder value={productFilter} onChange={(val)=>{setProductFilter(val.target.value);}} items={products}/>
            </Box>
        </ResponsiveDialog>

    </ThemeProvider>
    )
}

export default PPDashboard; 