import {forwardRef, Fragment, useState, useEffect, useContext } from "react";
import MainContext from "../../MainContext";

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Skeleton from "@mui/material/Skeleton";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Link from "@mui/material/Link";

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import theme from "../../Theme"
import { ButtonBase, CircularProgress} from "@mui/material";

import CustomPagination from "../components/CustomPagination";
import EnhancedTableHead from "../components/EnhancedTableHead";
import EnhancedTableToolbar from "../components/EnhancedTableToolbar";
import ResponsiveDialog from "../../components/ResponsiveDialog";
import NewGrant from "./NewGrant";
import ViewGrant from "./ViewGrant";

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Id',
  },
  {
    id: 'GrantName',
    numeric: false,
    disablePadding: false,
    label: 'Grant name',
  },
  {
    id: 'AwardOrg',
    numeric: false,
    disablePadding: false,
    label: 'Awarding organisation',
  },
  {
    id: 'AwardMax',
    numeric: true,
    disablePadding: false,
    label: 'Award max.',
  },
  {
    id: 'AwardMin',
    numeric: true,
    disablePadding: false,
    label: 'Award min.',
  },
];

export default function GrantsTable() {
    
    //Use
    const mainService = useContext(MainContext);


    const [loading,setLoading] = useState(false);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('GrantName');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows,setRows] = useState([]);
    const [currentRow,setCurrentRow] = useState(null);

    const [dialogOpen,setDialogOpen] = useState(false);
    const [dialogView,setDialogView] = useState("new");

    const [alertOpen,setAlertOpen] = useState(false);
    const [alertMsg,setAlertMsg] = useState("");
    const [alertSeverity,setAlertSeverity] = useState("success");


    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
        const newSelected = rows.map((n) => n.id);
        setSelected(newSelected);
        return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
        );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event,direction) => {
        let newPage = 0;
        if(direction==="forward"){
          newPage = page
          newPage++;
        }
          
        else if(direction==="backward" && page > 0){
          newPage = page
          newPage--;
        }

        setLoading(true);
        try{
          mainService.intellectibaseService.listenQueryData("grants",orderBy,rowsPerPage,newPage,direction,listenDocData);
        }
        catch(error){
          console.error(error)
          setLoading(false);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        const value = parseInt(event.target.value, 10);
        setLoading(true);
        try{
          mainService.intellectibaseService.listenQueryData("grants",orderBy,value,0,"forward",listenDocData);
          setRowsPerPage(value);

        }
        catch(error){
          console.error(error)
          setLoading(false);
        }       
        
    };

    const handleChangeOrderby = (event,value) => {
      setLoading(true);
      try{
        mainService.intellectibaseService.listenQueryData("grants",value,rowsPerPage,0,"forward",listenDocData);
        setOrderBy(value);
      }
      catch(error){
        console.error(error)
        setLoading(false);
      }       
      
    };

    const handleOpenCreateNew = () => {
        setDialogOpen(true);
        setDialogView("new");
    }

    const handleViewGrant = (id) => {
        console.log(id)
        setCurrentRow(id);
        setDialogOpen(true);
        setDialogView("view");
    }

    const handleCreateSuccess = (msg,severity,id) => {
        //open up edit new Grant
        if(severity==='success' && id){
          setCurrentRow(id);
          setDialogView("view");
        }
        else{
          setDialogOpen(false);
        }
        setAlertOpen(true);
        setAlertMsg(msg);
        setAlertSeverity(severity);
    }

    //LISTENERS

    const listenDocData = (docs,p) => {
        if(docs){
          setRows(docs);
          setPage(p);
          setLoading(false);
        }
    }

    //Effects
    useEffect(() => {
        //on mount
        console.log('Grants table mounted'); 
            setLoading(true);
            mainService.intellectibaseService.clearLastVisibles();
            mainService.intellectibaseService.listenQueryData("grants",orderBy,rowsPerPage,0,"forward",listenDocData);
        //on unmount
        return ()=>{
          console.log('Grants table unmounted');
          mainService.intellectibaseService.clearLastVisibles();
          mainService.intellectibaseService.unsubscribeQueryListener();
        }

    }, []);

    //RENDER
    const renderDialogView = () => {
        let view =(null)
        if(dialogView==="new"){
            return (
                <NewGrant onDone={handleCreateSuccess}/>
            )
        }
        else if(dialogView==="view"){
            return (
                <ViewGrant gid={currentRow} />
            )
        }
        return view;
    }

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - rows.length;

    return (
        <Fragment>
        <Box sx={{ width: '100%'}}>       
         {loading && !rows ? 
         (<Paper sx={{ width: '100%', mb: 2}}>
          <EnhancedTableToolbar title={"Grants"} numSelected={selected.length} />
            <Box sx={{p:theme.spacing(2),left:"50%",top:0,width:"100%",display:"flex",justifyContent:"center"}}>
              <CircularProgress/>
            </Box>
          </Paper>):

          (<Paper sx={{ width: '100%', mb: 2,overflow: 'hidden'}}>
            <EnhancedTableToolbar title={"Grants"} numSelected={selected.length} onCreateNew={handleOpenCreateNew} />
            <TableContainer sx={{maxHeight:"600px"}}>
            <Table
                stickyHeader 
                sx={{ minWidth: 750}}
                aria-labelledby="tableTitle"
                size='small'
            >
              <EnhancedTableHead                 
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleChangeOrderby}
                  rowCount={rows.length}   
                  headCells={headCells} 
              />
                
                <TableBody >                                              
                {
                    rows
                    .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                        <TableRow
                            hover
                            
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                        >
                        <TableCell padding="checkbox">
                            <Checkbox
                            onClick={(event) => handleClick(event, row.id)}
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                                'aria-labelledby': labelId,
                            }}
                            />
                        </TableCell>
                        <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                        >
                            {loading ? <Skeleton width={210} /> : row.id}
                        </TableCell>
                        <TableCell align="left">
                          {loading ? <Skeleton width={210} /> : 
                            (<Fragment>
                                <Link color="#324ab2" href="#" onClick={()=>handleViewGrant(row.id)}>
                                {row.data()["GrantName"]}
                                </Link>
                                <ButtonBase onClick={()=>window.open(row.data()["GrantURL"],'_blank')}>
                                  <OpenInNewIcon  sx={{height:'14px',width:'14px',color:'gray'}} />
                                </ButtonBase>
                              </Fragment>)}
                        </TableCell>
                        <TableCell align="left">{loading ? <Skeleton width={210}/> : (<Fragment>{row.data()["AwardOrg"]} <ButtonBase onClick={()=>window.open(row.data()["AwardOrgURL"],'_blank')}><OpenInNewIcon  sx={{height:'14px',width:'14px',color:'gray'}} /></ButtonBase></Fragment>)}</TableCell>
                        <TableCell  align="right">{loading ? <Skeleton width={210}/> : row.data()["AwardMax"]}</TableCell>
                        <TableCell align="right">{loading ? <Skeleton width={210}/> : row.data()["AwardMin"]}</TableCell>
                        </TableRow>
                    );
                    })                   
                    }
                  {emptyRows > 0 && (
                      <TableRow
                      style={{
                          height: (33) * emptyRows,
                      }}
                      >
                      <TableCell colSpan={6} />
                      </TableRow>
                  )}
                </TableBody>
            </Table>
            </TableContainer>
            <CustomPagination
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsLength={rows.length}
            />
        </Paper>)}
        </Box>
        <ResponsiveDialog maxWidth="lg" open={dialogOpen} onClose={()=>{setDialogOpen(false)}}>
            {renderDialogView()}
        </ResponsiveDialog>
        <Snackbar open={alertOpen} autoHideDuration={3000} onClose={()=>{setAlertOpen(false)}}>       
            <Alert onClose={()=>{setAlertOpen(false)}} severity={alertSeverity} sx={{ width: '100%' }}>        
                {alertMsg}           
            </Alert>       
        </Snackbar>
        </Fragment>
    );
}