import React, {useState, useEffect, useContext } from "react";

import theme from '../Theme';
import { ThemeProvider,keyframes } from  '@mui/system';


import {Box, Typography} from '@mui/material';


const pulse = keyframes`
  0% {
    opacity: 0.0;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    opacity: 0.0;
  }
`;

function AnimatedTypography(props){

    const [text, setText] = useState(''); 

    let tempText = '';
    let forwards = true; 
    let textInx = 0; 
    let pauseCounter = 0; 
    let animTimeout = 55; 
    let pauseTime = 1000; 

    useEffect(() => {
        console.log('AnimatedTypography mounted'); 

        let animationfire = ()=>{

            if(pauseCounter > 0){
                pauseCounter--; 
                return;
            }
            
            if(forwards){
                
                if(tempText.length < props.texts[textInx].length){
                    let newText = tempText + props.texts[textInx][tempText.length];
                    tempText = newText; 
                    setText(tempText); 
                }
                else{
                    forwards = (false); 
                    pauseCounter = Math.floor(pauseTime / animTimeout);
                    textInx = (textInx+1) % props.texts.length; 
                }
            }
            else{
                if(tempText.length > 0){
                    let newText = tempText.substring(0, tempText.length-1); 
                    tempText = newText; 
                    setText(tempText); 
                }
                else{
                    forwards = (true); 
                }
            }
        }; 

        let animInter = setInterval(animationfire, 70);

        return () => {
            console.log('AnimatedTypography unmounted'); 
            clearInterval(animInter);
        }
    }, []);

    return (
        <Box sx={{display:'flex',}}>
            <Typography variant='h4' align='center' sx={{fontSize:'28px', lineHeight:'1.2'}}>{text}</Typography>
            <Box sx={{ backgroundColor:'#080808', animation: `${pulse} 0.5s infinite ease`, width:4, height:'43px', ml:'5px'}} />
            <Typography sx={{ visibility:'hidden', fontSize:{xs:'38px', sm:'46.6px', md:'53.3px', lg:'60px'}, fontWeight: 900,  color:'pink.main', lineHeight:'1.2'}}>{'p'}</Typography>
        </Box>
    )
}

export default AnimatedTypography;