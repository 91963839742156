import React, {useState, useEffect, useContext } from "react";

import Theme from '../../Theme';

import { API_URL } from "../../Config";

import MainContext from "../../MainContext"; 

import { Box, Typography,Table, TableBody, ButtonBase,CircularProgress,Button, DialogTitle} from "@mui/material";

import FormFlow from '../../components/FlowForm'
import FundingCard from "./FundingCard";
import StyledButton from "../../components/StyledButton";
import StyledTableRow from "../../components/StyledTableRow";
import StyledTableCell from "../../components/StyledTableCell";
import ResponsiveDialog from "../../components/ResponsiveDialog";
import GrantDetails from "./GrantDetails";
import AddGrantFlow from "../../components/AddGrantFlow";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import Paper from "../../components/Paper";
import ProgressIndicator from "../../components/ProgressIndicator";
import FundingResources from "./FundingResources";
import { getFormattedNumberString, getNextDeadline} from "../../Util";


const Start = ({}) => {
    return (
        <Box sx={{ p:'64px',pt:'48px',height:'100%', flexGrow:1, display:'flex', alignItems:'stretch', flexDirection:'column'}}>
            <Typography gutterBottom variant="h2">Welcome to your funding</Typography>
            <Typography gutterBottom variant="body">
                Here you can assign a funding opportunity to your application and track your progress milestones. This helps persolanize your application to your specific funding opportunity as well as keeping your product provider in the loop with your progress allowing them to offer assistance where needed. 
            </Typography>
            <Typography gutterBottom variant='body'>
                Click continue to view your funding recommendations, you can either select one of these or add your own.
            </Typography>          
        </Box>
    )
}

const Recommendations = ({formData,setDisableContinueButton}) => {
    
    const [loading,setLoading] = useState(true);

    const [detailsDialogOpen,setDetailsDialogOpen] = useState(false);
    const [detailsDialogData,setDetailsDialogData] = useState({});
    const [detailsDialogImageURL,setDetailsDialogImageURL] = useState(null);
    const [detailsOgData,setDetailsOgData] = useState(null);
    
    const [addGrantOpen,setAddGrantOpen] = useState(false);
    const [resourcesOpen,setResourcesOpen] = useState(false);
    
    const mainService = useContext(MainContext);

    //EFFECTS 
    useEffect(() => {
        let timer = null;
        if(formData.appId){
            console.log('setting timer');
           timer =  
           setTimeout(() => {
                setLoading(false);
            }, "1000");
        }

        return (()=>{
            if(timer && formData.appId){
                console.log('clearing timer');
                clearTimeout(timer);
            }
        })

    }, [formData.appId]);

    useEffect(()=>{
        let fundingRecs = formData && formData?.applicationMetadata?.fundingRecommendations ? formData.applicationMetadata.fundingRecommendations : null;
        let hasSelected = fundingRecs && fundingRecs.some(el => el.selected);
        console.log(hasSelected)
        let disableButton = !(hasSelected); 
        if(setDisableContinueButton)
            setDisableContinueButton(disableButton); 
    }, [formData]);

    const handleOpenDetailsDialog = async (fundingData) => {
        setDetailsDialogData(fundingData);
        setDetailsDialogOpen(true);
        console.log(fundingData);
        if(!fundingData.AwardOrgURL){
            console.log('no award org url')
            return
        }
            

        try{
            setDetailsDialogImageURL('loading');
            const token = await mainService.getUserToken();
            //get opengraph logo url
            const res = await fetch(API_URL + `/getogdata?` + new URLSearchParams({
                url: fundingData.AwardOrgURL,
            }),{
                headers: {
                Authorization: `${token}`
                }
            })
            const data = await res.json();
            if(data.ok && data.ogdata){
                setDetailsOgData(data.ogdata);
                const logourl = mainService.getOgImageFromData(data.ogdata);
                if (logourl)
                    setDetailsDialogImageURL(logourl);
                else 
                    setDetailsDialogImageURL(null);
            }
            else{
                console.log('setting to null')
                setDetailsDialogImageURL(null);
            }
        }
        catch(error){
            console.error(error)
        }

    }

    const handleCloseDetailsDialog = () => {
        setDetailsDialogData({});
        setDetailsDialogImageURL(null);
        setDetailsOgData(null);
        setDetailsDialogOpen(false);
    }

    const handleCloseResources = () => {
        setResourcesOpen(false);
    }

    const handleFinishAddGrant = (data) => {
        setAddGrantOpen(false);
        let recs = formData?.applicationMetadata?.fundingRecommendations ? [...formData.applicationMetadata.fundingRecommendations] : [];
        for (let i = 0; i < recs.length;i++){          
            recs[i].selected = false;
        }
        recs.push(data);
        mainService.applicationService.setFundingRecommendation(formData.appId, recs);
    }

    const handleCloseAddGrant = () => {
        setAddGrantOpen(false);
    }

    const handleSelectRec = (fundingInx,e) => {
        e.preventDefault();
        console.log('selecting rec');
        let recs = formData?.applicationMetadata?.fundingRecommendations ? [...formData.applicationMetadata.fundingRecommendations] : null;
        for (let i = 0; i < recs.length;i++){
            
            const s = i == fundingInx ? e.target.checked : false;
            recs[i].selected = s;
            console.log('index ' + i);
            console.log('selected ' + s);
        }
        //console.log(JSON.stringify(recs))
       mainService.applicationService.setFundingRecommendation(formData.appId, recs); 
    }

    const handleRemoveFundingRec = (fundingInx) => {

        mainService.applicationService.removeFundingRecommendation(formData.appId, fundingInx); 

    }

    const fundingRecs = formData?.applicationMetadata?.fundingRecommendations ? formData.applicationMetadata.fundingRecommendations : null;
    const reducedRecs = fundingRecs ? fundingRecs.slice(0,2) : null;
    const userGrantsIndices = fundingRecs.reduce((acc, element, index) => {
        if (element.userCreated) {
          acc.push(index);
        }
        return acc;
      }, []);
    console.log(JSON.stringify(userGrantsIndices));

    return (
        <React.Fragment>
        <Box sx={{ p:'64px',pt:'48px',height:'100%', flexGrow:1, display:'flex', alignItems:'stretch', flexDirection:'column'}}>
            {loading ? (
                <Box sx={{display:'flex',flexGrow:1,alignItems:'center',justifyContent:'center'}}>
                    <CircularProgress/>
                </Box>                
            ) : (
                <Box sx={{display:'flex',flexGrow:1, flexDirection:'column'}}>
                    <Typography gutterBottom variant="h2">
                        Add a funding source to your application
                    </Typography>

                    {/*<Typography gutterBottom variant='body'>
                        Here are a few of our funding recommendations. Click on grant details to read a bit more about the grant and decided if it is right for your project. You can select one of these to add to your application, or you can add your own funding source below.
                    </Typography>
                    <Box sx={{display:'flex', gap:'16px',alignItems:'stretch',mb:'16px'}}>
                        {reducedRecs && reducedRecs.map((rec,i)=>(
                            <FundingCard 
                                key={rec.data.GrantName} 
                                fundingId={i} 
                                onSetSelected={handleSelectRec} 
                                selected={rec?.selected} 
                                fundingData={rec?.data}
                                onDetailsClick={()=>handleOpenDetailsDialog(rec.data)}
                            />
                        ))}
                    </Box>*/}

                    {userGrantsIndices.length > 0 ? (
                        <React.Fragment>
                        <Typography gutterBottom variant='h4'>
                            Your funding option
                        </Typography>                   
                        <Typography gutterBottom variant='body' sx={{mb:'8px'}}>
                            Once you're happy click continue
                        </Typography>

                        <Box sx={{display:'flex'}}>
                        {/*<ButtonBase sx={{px:'8px',py:'6px',borderRadius:'5px',alignSelf:'flex-start',mr:'8px'}}>
                            <Typography sx={{color:Theme.palette.primary.main}}>Edit</Typography>
                        </ButtonBase>*/}
                        <ButtonBase onClick={()=>handleRemoveFundingRec(userGrantsIndices[0])} sx={{px:'8px',py:'6px',borderRadius:'5px',alignSelf:'flex-start'}}>
                            <Typography sx={{color:Theme.palette.primary.dark}}>Remove</Typography>
                        </ButtonBase>
                        </Box>

                        </React.Fragment>
                    ) : (
                    <React.Fragment>
                        {/*<Typography gutterBottom variant='h4'>
                            Have something else in mind?
                        </Typography>*/}
                        <Typography gutterBottom variant='body' sx={{mb:'8px'}}>
                            Click below and follow the short flow to add your own funding source into your application.
                        </Typography>
                        <StyledButton onClick={()=>setAddGrantOpen(true)} sx={{alignSelf:'flex-start',my:'8px'}} text='Add a grant'></StyledButton>
                    </React.Fragment>)}              
                    
                    <Box sx={{display:'flex', gap:'16px',alignItems:'stretch'}}>
                        {userGrantsIndices.length > 0 &&
                        <React.Fragment>
                            <FundingCard 
                                key={fundingRecs[userGrantsIndices[0]].data.GrantName} 
                                fundingId={userGrantsIndices[0]} 
                                onSetSelected={handleSelectRec} 
                                selected={fundingRecs[userGrantsIndices[0]]?.selected} 
                                fundingData={fundingRecs[userGrantsIndices[0]]?.data}
                                onDetailsClick={()=>handleOpenDetailsDialog(fundingRecs[userGrantsIndices[0]].data)}
                            />
                            <Box sx={{flexGrow:1,flexBasis:0}} />
                        </React.Fragment>
                       }
                    </Box>

                    <Typography gutterBottom variant='h4' sx={{mt:'16px'}}>
                       Not sure where to get funding?
                    </Typography>
                    <ButtonBase onClick={()=>setResourcesOpen(true)} sx={{pr:'8px',py:'6px',borderRadius:'5px',alignSelf:'flex-start'}}>
                        <Typography sx={{color:Theme.palette.primary.main}}>
                            Show me some more grant discovery resources
                        </Typography>
                    </ButtonBase>
                </Box>
            )}
        </Box>
        <ResponsiveDialog open={detailsDialogOpen} onClose={handleCloseDetailsDialog} maxWidth='md'>
            <GrantDetails coverImg={detailsDialogImageURL} fundingData={detailsDialogData} ogData={detailsOgData} />
        </ResponsiveDialog>
        <ResponsiveDialog open={resourcesOpen} onClose={handleCloseResources}>
            <FundingResources/>
        </ResponsiveDialog>
        <AddGrantFlow 
            userObject={formData.userObject} 
            appId={formData.appId} 
            applicationMetadata={formData.applicationMetadata} 
            open={addGrantOpen} 
            onFinish={handleFinishAddGrant}
            onClose={handleCloseAddGrant} 
        />
        </React.Fragment>
    )
}

const Finish = ({formData}) => {

    const fundingRecs = formData?.applicationMetadata?.fundingRecommendations ? formData.applicationMetadata.fundingRecommendations : null;
    const selectedRec = fundingRecs ? fundingRecs.find(rec => rec.selected) : null;
    const nextDeadline = selectedRec && selectedRec?.data?.SubmissionRanges?.length > 0 ? getNextDeadline(selectedRec.data.SubmissionRanges) : null;

    return (
        <Box sx={{px:'64px',pt:'48px',height:'100%', flexGrow:1, display:'flex', alignItems:'stretch', flexDirection:'column'}}>
            <Typography gutterBottom variant="h2">Your funding summary</Typography>
            <Typography gutterBottom variant='body'>
                The following funding source will be added to your application. Check the details carefully and go back if you need to make any changes. If you're happy click continue to link finish adding your funding source and track your progress.
            </Typography>
            <Paper sx={{mt:'12px', display:'flex', flexDirection:'column', overflow:'hidden'}}>
            
            <Table  aria-label="funding-summary" >

                <TableBody>
                    <StyledTableRow>
                        <StyledTableCell  component="th" scope="row">
                            <Typography variant='h6'>Name:</Typography>
                        </StyledTableCell>
                        <StyledTableCell  align="left">
                            <Typography variant='body2'>{selectedRec?.data?.GrantName}</Typography>
                        </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                        <StyledTableCell  component="th" scope="row">
                            <Typography variant='h6'>Organization:</Typography>
                        </StyledTableCell>
                        <StyledTableCell  align="left">
                            <Typography variant='body2'>{selectedRec?.data?.AwardOrg}</Typography>
                        </StyledTableCell>
                    </StyledTableRow>  

                    <StyledTableRow>
                        <StyledTableCell  component="th" scope="row">
                            <Typography variant='h6'>Maximum award:</Typography>
                        </StyledTableCell>
                        <StyledTableCell  align="left">
                            <Typography variant='body2'>${selectedRec?.data?.AwardMax ? getFormattedNumberString(selectedRec?.data?.AwardMax) : 'N/A'}</Typography>
                        </StyledTableCell>
                    </StyledTableRow>  

                    <StyledTableRow>
                        <StyledTableCell  component="th" scope="row">
                            <Typography variant='h6'>Minimum award:</Typography>
                        </StyledTableCell>
                        <StyledTableCell  align="left">
                            <Typography variant='body2'>${selectedRec?.data?.AwardMin ? getFormattedNumberString(selectedRec?.data?.AwardMin) : 0}</Typography>
                        </StyledTableCell>
                    </StyledTableRow>  

                    <StyledTableRow>
                        <StyledTableCell  component="th" scope="row">
                            <Typography variant='h6'>Next deadline:</Typography>
                        </StyledTableCell>
                        <StyledTableCell  align="left">
                            <Typography variant='body2'> {nextDeadline ? nextDeadline.toLocaleDateString() : 'Not available'} </Typography>
                        </StyledTableCell>
                    </StyledTableRow>  

                </TableBody>
            </Table>
            
            </Paper>
        </Box>
    )
}


const PickFundingFlow = ({userObject,applicationMetadata,appId,onFinish}) => {

    //EFFECTS 
    useEffect(() => {

	}, [appId]);


    //RENDER 
    const formData = {
        
        startNode: 'startNode',

        userObject,

        applicationMetadata,

        appId,

        nodes:{
            startNode:{
                component:Start,
                next:()=>'recommendationsNode',
            },

            recommendationsNode:{
                component:Recommendations,
                next:()=>'finishNode'
            },

            finishNode:{
                component:Finish,
                end:true,
            },
        }
    }

    return (      
        <FormFlow onFinish={onFinish} formData={formData}/>               
    )

}  

const FundingProgress = ({appId,applicationMetadata}) => {
    const mainService = useContext(MainContext);

    const [loading,setLoading] = useState(true);
    const [fundingId,setFundingId] = useState(null);
    const [fundingData,setFundingData] = useState(null);
    const [nextdeadline,setNextDeadline] = useState(null);
    

    const steps = ['not started','in progress','submitted','answer received','funds received'];
    const titles = ['Time to get your skates on','Your application is in progress','Nice job!','You did it!','Time to get cracking!'];
    const subtitles = [
        "Now that you've found your funding source, it's time to use Intellectible to generate your application.",
        "Brilliant! You've generated your funding application with Intellectible. Now it's time to make your final tweaks and get it submitted. Keep an eye on that deadline!",
        "That's the hard work done for now! Remember to check back in once you receive your reply so your product provider can get your order prepared.",
        "Congratulations! You have done something amazing. Your product product provider is getting your order ready to go. Check back in here once the funds are received",
        "Time to put your ideas into action and get your project started. Your product provider is here to support you throughout the process."
    ];

    //EFFECTS 
    useEffect(() => {
        let timer = null;
        if(appId){
            console.log('setting timer');
            timer =  
            setTimeout(() => {
                setLoading(false);
            }, "1000");
        }

        return (()=>{
            if(timer && appId){
                console.log('clearing timer');
                clearTimeout(timer);
            }
        })

    }, [appId]);

    useEffect(()=>{

        if(applicationMetadata && applicationMetadata.fundingRecommendations){
            const fid = applicationMetadata.fundingRecommendations.findIndex(el => el.selected);
            const fdata = applicationMetadata.fundingRecommendations[fid];
            const nd = fdata && fdata?.data?.SubmissionRanges?.length > 0 ? getNextDeadline(fdata.data.SubmissionRanges) : null;
            console.log(nd)
            setFundingId(fid);
            setFundingData(fdata);
            setNextDeadline(nd);
        }


    },[applicationMetadata])

    const handleSetFundingStage = (stage) => {
        mainService.applicationService.setFundingRecommendationStage(appId,fundingId,stage);
    }

    return(
        <Box sx={{ p:'64px',pt:'48px',height:'100%', flexGrow:1, display:'flex', alignItems:'stretch', flexDirection:'column'}}>
        {loading ? (
            <Box sx={{display:'flex',flexGrow:1,alignItems:'center',justifyContent:'center'}}>
                <CircularProgress/>
            </Box>                
        ) : (<Box sx={{display:'flex',flexGrow:1, flexDirection:'column'}}>
                <Typography gutterBottom variant="h2">
                    Your funding progress
                </Typography>
                <Typography gutterBottom variant='body' sx={{mb:'16px'}}>
                    Update your funding progress here, this will help keep you on track with your application as well as keeping your product provider in the loop so they can assist you where needed.
                </Typography>
                <ProgressIndicator steps={steps} stage={!fundingData.stage || typeof fundingData.stage === 'string' ? 0 : fundingData.stage} onSetStage={handleSetFundingStage}/>
                <Typography gutterBottom variant="h3">
                    {titles[!fundingData.stage || typeof fundingData.stage === 'string' ? 0 : fundingData.stage]}
                </Typography>
                <Typography gutterBottom variant='body' sx={{mb:'32px'}}>
                    {subtitles[!fundingData.stage || typeof fundingData.stage === 'string' ? 0 : fundingData.stage]}
                </Typography>
                <Typography variant="h3" sx={{mb:'16px'}}>
                    Your funding summary:
                </Typography>

                <Box sx={{display:'flex',flexDirection:'column', borderRadius:'8px',border:`1px solid ${Theme.palette.primary.main}`,px:'16px',py:'12px',bgcolor:Theme.palette.primary.light}}>

                    <Box sx={{display:'flex',gap:'8px',alignItems:'center'}}>
                        <Typography variant="h5">
                            {fundingData.data.GrantName}
                        </Typography>
                        <ButtonBase onClick={()=>window.open(fundingData?.data?.GrantURL,'_blank')} sx={{borderRadius:'5px'}}>
                            <OpenInNewIcon sx={{color:Theme.palette.grey[400],width:'24px',height:'24px'}}/>
                        </ButtonBase>
                    </Box>

                    <Typography gutterBottom variant="body">
                        Awarded by: {fundingData.data.AwardOrg}
                    </Typography>

                    <Typography gutterBottom variant="body">
                        Maximum award: ${fundingData?.data?.AwardMax ? getFormattedNumberString(fundingData?.data?.AwardMax) : 'N/A'}
                    </Typography>

                    <Typography variant="body">
                        Deadline: {nextdeadline ? nextdeadline.toLocaleDateString() : 'N/A'}
                    </Typography>
                </Box>

            </Box>)}       
        </Box>  
    )
}

const MyFunding = ({userObject, applicationMetadata, budgetSummary, budgetSections, budgetLines, appId, productData}) => {

    const mainService = useContext(MainContext);

    const handleFinishPickFunding = () => {
        mainService.applicationService.setFundingSelected(appId,true);
    }

    //RENDER
    return (        
        <Paper sx={{flexGrow:1, display:'flex', flexDirection:'column', overflow:'hidden'}}>
            {!applicationMetadata.fundingSelected ? (
                <PickFundingFlow userObject={userObject} applicationMetadata={applicationMetadata} appId={appId} onFinish={handleFinishPickFunding}/>
            ):(
                <FundingProgress appId={appId} applicationMetadata={applicationMetadata}/>
            )} 
        </Paper>
    )
}

export default MyFunding; 