import React, {useState, useEffect, useContext } from "react";
import MainContext from "../../MainContext"; 
import Theme from "../../Theme";
import { Box, Autocomplete, Typography } from "@mui/material";
import FormTitle from "../../components/formInputs/FormTitle";
import SimpleAutocomplete from '../../components/formInputs/SimpleAutocomplete';
import StyledTextField from "../../components/StyledTextField";
import {roles} from '../../Util';

const RoleInput = ({formData, setDisableContinueButton})=>{
    
    const mainService = useContext(MainContext);

    let userObject = formData.userObject;
    const role = userObject?.role ?? '';

    useEffect(()=>{
        let disableButton = !(formData && formData.userObject && formData.userObject.role); 
        if(setDisableContinueButton)
            setDisableContinueButton(disableButton); 
    }, [formData]);

    return(
    <>
        <Box sx={{ flexGrow:1, maxWidth:'510px', height:'100%',  display:'flex', alignItems:'stretch', flexDirection:'column'}}>
            <Box sx={{height:'20%'}}></Box>
            
            <FormTitle text='I am a...'/>
            <Box sx={{display:'flex', alignItems:'center', flexDirection:{xs:'column', sm:'row'} }}>
                
                <Autocomplete
                    autoComplete
                    value={role ?? ''} 
                    options={roles}
                    onChange={(e,val)=>mainService.profileService.setOrgRole(val)}
                    renderInput={(params) => <StyledTextField {...params} InputProps={{...params.InputProps}} label={<Typography>My job title</Typography>} variant='outlined'/>}                        
                    sx={{flexGrow:1,mx:'16px'}} 
                />

            </Box>

        </Box>
    </>
    )
}

export default RoleInput; 