import React, {useState, useEffect, useContext } from "react";

import MainContext from "../../MainContext"; 

import { Box, Typography, Button, DialogTitle, DialogContent, DialogActions, CircularProgress} from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import FileDragAndDrop from '../../components/FileDragAndDrop';
import Theme from "../../Theme";

import TextFormInput from "../../components/formInputs/TextFormInput";
import WebsiteFormInput from "../../components/formInputs/WebsiteFormInput";
import { WorkQueue } from "../../Util";
import {isURL} from 'validator';
import { API_URL } from "../../Config"
import StyledButton from "../../components/StyledButton";

const workQueue = new WorkQueue(); 

const SchoolWebInput = ({formData,setDisableContinueButton})=>{
    const mainService = useContext(MainContext);
    const [uploadDialogOpen,setUploadDialogOpen] = useState(false);
    const [imgFile,setImgFile] = useState(null);
    const [imgLoading,setImgLoading] = useState(false);

    const userObject = formData.userObject;

    const handleUploadDialogClose = () => {
        setUploadDialogOpen(false);
    }

    const handleUploadImage = async () => {
        setUploadDialogOpen(false);
        setImgLoading(true);
        if(imgFile){
            await mainService.profileService.uploadSchoolLogo(userObject,imgFile)           
        }
        setImgLoading(false);
    }

    const handleGetLogo = async (v) => {
        console.log(v)
        workQueue.cancelAll();
        
        //if no val
        if(!v || !isURL(v)){   
            console.log('not url or empty')
            mainService.profileService.setSchoolWebsite(v);
            return
        }

        let url = null;
        try {
            url = new URL(v);
        }
        catch(error){
            console.log('failed to construct url');
        }
        if(!url){
            try {
                url = new URL('https://'+v);
            }
            catch(error){
                console.log('failed to construct url');
            }
        }

        if(!url){
            mainService.profileService.setSchoolWebsite(v);
            return
        }

        
        const stringurl = url.toString(); 
        mainService.profileService.setSchoolWebsite(v);
        console.log(stringurl)

        console.log('is url, attempting to retrieve logo');
        setImgLoading(true);
        workQueue.push(async (job) => {
            try{
                const token = await mainService.getUserToken();
                const res = await fetch(API_URL + `/getlogo?` + new URLSearchParams({
                    url:stringurl
                }),{
                    headers: {
                      Authorization: `${token}`
                    }
                })
                //get content type
                const contentType = res.headers.get("content-type");
                const isJson = contentType && contentType.indexOf("application/json") !== -1;
                if(isJson){
                    console.log('response is json')
                    const data = await res.json();
                    await mainService.profileService.deleteSchoolLogo(userObject);
                    throw data.message;
                }
                else{
                    const data = await res.blob();
                    if(data){                
                        console.log("success")
                        await mainService.profileService.uploadSchoolLogo(userObject,data)
                    }
                }
                setImgLoading(false);
            }
            catch(error){
                console.error(error);
                setImgLoading(false);
            }
        })
       
    }

    useEffect(()=>{
        let disableButton = !(userObject && userObject.schoolwebsite); 
        if(setDisableContinueButton)
            setDisableContinueButton(disableButton); 
    }, [formData]);

    return(
    <React.Fragment>
        <Box sx={{ height:'100%', flexGrow:1, display:'flex', alignItems:'stretch', flexDirection:'column',maxWidth:'600px'}}>

            <Box sx={{height:'10%'}}></Box>
            <Typography sx={{mx:'16px'}} variant='h3' align='left' gutterBottom >Add your school's website</Typography>
            <Typography sx={{mx:'16px',mb:'32px'}} variant='body2' align='left'>Add your school's website. If your logo doesn't appear you can upload it manually. We'll use this to automate creating custom professional grant and fundraising documents</Typography>
            
            <Box sx={{my:'16px',flexGrow:1, display:'flex',flexDirection:'column',alignItems:'stretch',gap:'8px'}}>
                <WebsiteFormInput 
                    value={userObject?.schoolwebsite ?? ''} 
                    onChange={handleGetLogo}
                    title={`Paste your school website here...`} 
                    rows={1} 
                    multiline={true}
                />
                <Typography sx={{mx:'16px',mt:'32px',mb:'16px'}} variant='body1' align='left'>Add a school logo</Typography>
                <Box sx={{display:'flex',flexDirection:'column',gap:'8px',mx:'16px', alignItems:'flex-start'}}>
                    <Box sx={{p:'8px',width:'128px',height:'128px',borderRadius:'8px',position:'relative',border:`1px solid ${Theme.palette.grey[400]}`}}>
                        {imgLoading ? (
                        <Box sx={{position:'relative',width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <CircularProgress size={28} />
                        </Box>
                        ) : (
                        <Box sx={{position:'relative',width:'100%',height:'100%'}}>
                            <img style={{position:'absolute',width:'100%',height:'100%', objectFit:'contain'}} src={userObject?.schoolLogoURL} referrerPolicy='no-referrer' />
                        </Box>
                        )}


                    </Box>
                    <StyledButton 
                        sx={{mt:'8px',mb:'16px'}}  
                        text='Upload image' 
                        icon={()=><CloudUploadIcon sx={{ml:'8px', color:'#ffffff'}}/>} 
                        onClick={()=>setUploadDialogOpen(true)}
                    />
                </Box>

            </Box>
        </Box>

        <ResponsiveDialog onClose={handleUploadDialogClose} open={uploadDialogOpen} >
            <DialogTitle>Upload an image</DialogTitle>
            <DialogContent>
                <Box sx={{width: "450px"}}>
                    <FileDragAndDrop
                        sx={{width:'100%', height:'200px'}}
                        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                        maxFileSize={3000000}
                        filesLimit={1}
                        dropzoneText='drop logo pic here, or click'
                        onChange={(imgFiles)=>setImgFile(imgFiles[0])}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button  onClick={()=>(setUploadDialogOpen(false))} variant="outlined">
                    Cancel
                </Button>
                <Button 
                    variant="contained" 
                    sx={{color:"white"}}
                    onClick = {handleUploadImage}
                    disabled={imgLoading}
                >
                    Upload
                </Button>
            </DialogActions>
        </ResponsiveDialog>
    </React.Fragment>
    )
}

export default SchoolWebInput; 