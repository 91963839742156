import React from 'react';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function ResponsiveDialog(props) {
  
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (

		<Dialog
			fullScreen={fullScreen}
			open={props.open}
			onClose={props.onClose}
			aria-labelledby={props.title}
			maxWidth={props.maxWidth ?? 'lg'}
            PaperProps={{variant:"outlined",elevation:0,sx:{borderRadius:'8px', ...props.sx}}}
			disableEscapeKeyDown={props?.disableEscapeKeyDown}
		>
 			{props.children}
		</Dialog>
		
	);
}