import React, {useState, Fragment } from "react";

import { Box, Typography, Link } from "@mui/material";


import Theme from "../../Theme";



const FundingResources = ({})=>{

    return (
        <Box sx={{display:'flex',flexDirection:'column',px:'16px',py:'12px'}}>
            <Typography variant="h2" sx={{mb:'8px'}}>
                Grant discovery resources
            </Typography>
            <Typography variant="body" sx={{mb:'16px'}}>
                If you haven't got a grant in mind the following links are great resources to find your perfect source of funding.
            </Typography>
            <Link variant="h3" gutterBottom sx={{}}  href='https://www.getedfunding.com/c/index.web?s@1..WACFBW3ns2' target='_blank'>GetEdFunding</Link>
            <Typography variant="body">GetEdFunding hosts thousands of education grants, including funding opportunities for public and private preK–12 schools and districts; awards for outstanding professionals; grants for teachers, media specialists, and administrators; higher education institutions; and nonprofit organizations offering educational programs.</Typography>
        </Box>
    )    
}

export default FundingResources; 