import {
    initializeApp, 
    getApps } from 'firebase/app';

import {
    getAuth, 
    getIdToken,
    setPersistence,
    sendSignInLinkToEmail,
    isSignInWithEmailLink, 
    signInWithEmailLink,
    GoogleAuthProvider,
    signInWithPopup,
    signOut,
    browserLocalPersistence, 
    browserSessionPersistence, 
    signInWithRedirect,
    getRedirectResult,
    inMemoryPersistence,} from 'firebase/auth';

import { 
    getDatabase, 
    ref, 
    set, 
    get, 
    push, 
    update, 
    onValue, 
    orderByKey,
    limitToFirst,
    query as rtQuery,
    off } from 'firebase/database';

import { getAnalytics } from "firebase/analytics";

import {FirebaseConfig, API_URL} from './Config';

import moment from 'moment'

import is from 'is_js';

import ApplicationService from './ApplicationService';
import ProductListingService from './ProductListingService';
import ProfileService from './ProfileService';
import MessageService from './MessageService';
import PPDashboardService from './PPDashboardService'; 
import TextEditingService from './TextEditingService';
import AccountService from './AccountService';
import IntellectibaseService from './IntellectibaseService';

export default class MainService {
    static AUTHED = 2;
	static AUTHING = 1;
	static NOTAUTHED = 0;

    static STRIPE_PUBLISHABLE_KEY = 
        process.env.NODE_ENV ===  'development' ? "pk_test_51N1QnOJuYBJzp2nhvHByfijHIanC149Iz4Hjez2rAgOe9ArPayp0gNwmOG2kvVI1Uy35Cv1h5uUGCTIvdF3nlsSD00jMsdURTZ" : 
            "pk_live_51N1QnOJuYBJzp2nhBe4cxKltYKPQhvBqbe99AC2QQMcYiQ9X7ME9bsKAzYEiuugu3BzkIGrxpwoyvGYcibL4zAZq00Fcs6UmKF";

    constructor(){
        //if(!getApps().length){
        this.app = initializeApp(FirebaseConfig);
        this.analytics = getAnalytics(this.app);
        //}

        this.dataListnerCallbacks = new Map(); 

        this.authed = MainService.AUTHING;
		this.authChangeCallbacks = new Set(); 

		let authChangedCallbacks = (newAuthState)=>{
			this.authed = newAuthState;
			this.authChangeCallbacks.forEach((callback)=>{
                
				if(callback){  callback(this.authed); }
			}); 
		}

        let OnAuthChange = async (user) =>{
			if (user) {

				try{
                    authChangedCallbacks( MainService.AUTHING );

					let token = await user.getIdToken();
                    
                    let snapshot = null; 
                    for(let i = 0; i < 30; i++){
                        snapshot = await get( ref(getDatabase(), 'users/' + user.uid) );
                        if(snapshot.exists())
                            break;
                        await new Promise((resolve, reject)=>setTimeout(()=>resolve(), 100));
                    }
                    if(!snapshot.exists()){
                        console.log("NO USER OBJECT ON AUTH"); 
                        //TODO write user object maybe...
                    }

					authChangedCallbacks(MainService.AUTHED);
				} 
				catch(err){
					authChangedCallbacks(MainService.NOTAUTHED); 
				}

			} else {
				authChangedCallbacks(MainService.NOTAUTHED); 
			}
		}
		
		getAuth().onAuthStateChanged( OnAuthChange );

        this.triggerListners = new Map(); 

        //Add services outside of the main
        this.applicationService = new ApplicationService(this); 
        this.productListingService = new ProductListingService(this);
        this.profileService = new ProfileService(this); 
        this.messageService = new MessageService(this); 
        this.ppDashboardService = new PPDashboardService(this); 
        this.textEditingService = new TextEditingService(this);
        this.accountService = new AccountService(this);
        this.intellectibaseService = new IntellectibaseService(this);
    }

    addAuthChangeCallback(callback){
		this.authChangeCallbacks.add(callback); 
	}
	
	removeAuthChangeCallback(callback){
		this.authChangeCallbacks.delete(callback);
	}

    isAuthed(){
		return this.authed === MainService.AUTHED; 
	}

    isAuthing(){
		return this.authed === MainService.AUTHING; 
	}

    isNotAuthed(){
        return this.authed === MainService.NOTAUTHED; 
    }

    getAuthedState(){
		return this.authed; 
	}

    async isAdmin(){
        const uid = this.getUid();
        try{
            const snap = await get(ref(getDatabase(),`admins/${uid}`));
            if(snap.exists()){
                return true;
            }
            return false;
        }
        catch(error){
            return false;
        }
    }

    getUid(){
        let auth = getAuth(); 
        if(!auth || !auth.currentUser || !auth.currentUser.uid)
            return ""; 
        return auth.currentUser.uid;
    }

    async getUserToken(){
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        return token;
    }

	getEncodedEmail(){
		let auth = getAuth(); 
        if(!auth || !auth.currentUser || !auth.currentUser.email)
            return ""; 
        return auth.currentUser.email.trim().toLowerCase().replace(new RegExp("\\.","g"),"%2E"); 
	}

    getEmail(){
        let auth = getAuth(); 
        if(!auth || !auth.currentUser || !auth.currentUser.email)
            return ""; 
        return auth.currentUser.email.trim(); 
    }

    getDatetimeString(){
        return moment().format("YY-MM-DD h:mm");
    }

    getDateString(timestamp){
       const date = new Date(timestamp);
       return date.toDateString();
    }
    getTimeString(timestamp){
        const date = new Date(timestamp);
        return '' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
     }

    getOgImageFromData(ogData){
        let imageURL = null;
        if(ogData && ogData.ogImage && ogData.ogImage.url)
            imageURL =  ogData.ogImage.url;
            
        else if (ogData && ogData.ogImage && Array.isArray(ogData.ogImage)){
            if(ogData.ogImage[0].url)
                imageURL =  ogData.ogImage[0].url;
        }   

        return imageURL
    }
    
    //sign in email link
    async sendEmailSigninLink(email, query="", userType='writer'){

        if(userType && query){
            query.set('usertype', userType);
        }
        
        let url = query ? `${window.location.origin}/email-login?${query}` : `${window.location.origin}/email-login`;
        
        const actionCodeSettings = {
            url:url,
            handleCodeInApp: true,
        }
        const auth = getAuth();
        try{
            await sendSignInLinkToEmail(auth, email, actionCodeSettings);
            window.localStorage.setItem('emailForSignIn', email);
        }
        catch(error){
            throw error;
        }
    }

    checkIsSigninWithEmailLink(){
        const auth = getAuth();
        if(isSignInWithEmailLink(auth,window.location.href)){
            return true;
        }
        return false;
    }

    async emailLinkSigninUser(email){

        const auth = getAuth();
        const db = getDatabase();
        const persistance = browserSessionPersistence;
        if (isSignInWithEmailLink(auth, window.location.href) && email) {
            try{
                await auth.setPersistence(persistance);
                const result = await signInWithEmailLink(auth, email, window.location.href);
                window.localStorage.removeItem('emailForSignIn');
                const userRef = ref(db, 'users/' + auth.currentUser.uid);
                const snapshot = await get(userRef);
                if(!snapshot.exists()){
                    //assume it's first sign up
                    let urlParams = new URLSearchParams( window.location?.search ??'');
                    let userType = urlParams.get('usertype') ?? 'writer'; 

                    await this.createUserObject("","", userType);
                    //send welcome email
                    await this.sendWelcomeEmail("",email);
                } 
                /*const userAccessRef = ref(db, 'userAccess/' + auth.currentUser.uid);
                const accessSnapshot = await get(userAccessRef);
                if(!accessSnapshot.exists()){
                    //they have no user access obj yet so create
                    console.log("creating user access obj");
                    await this.createUserAccessObject();
                }*/
                //add app access - it won't add if data exists
                this.addAppAccess();

                const emailUidRel = await get( ref(db, 'emailUidRelationship/' + this.getEncodedEmail()) );

                if (!emailUidRel.exists()){
                    await this.createEmailUidRelationship(); 
                }
            }
            catch(error){
                throw error;
            }
        }
        else {
            console.log("is not email sign in");
            return;
        }           
    }

    async googleSigninUser(userType) {
        //if mobile - use popup
        if(is.mobile() || is.safari()){
            console.log('is mobile using popup');
            console.log('is not mobile/safari?? using redirect');
            //return this.googleSigninWithRedirect(true);
            return this.googleSigninWithPopup(true, userType);
        }
        //else redirect
        else {
            console.log('is not mobile/safari?? using redirect');
            return this.googleSigninWithPopup(true, userType);
            //return this.googleSigninWithRedirect(true);
        }
    }

    async googleSigninWithRedirect(isRemembered){

        const auth = getAuth();
        const db = getDatabase();

        let persistance = browserLocalPersistence; 
		//set up persistance
		if(!isRemembered)
			persistance = browserSessionPersistence;

        try{
            await auth.setPersistence(persistance);
            const provider = new GoogleAuthProvider();
            await signInWithRedirect(auth, provider);
            /*const result = await getRedirectResult(auth);
            console.log('result',result);
            console.log(auth.currentUser);
            //create user in db if none exists
            const names = result.user.displayName.split(" ");
            const email = result.user.email;
            const firstname = names[0] ? names[0] : "";
            const lastname = names[1] ? names[1] : "";
            const userRef = ref(db, 'users/' + auth.currentUser.uid);
            const snapshot = await get(userRef);
            if(!snapshot.exists()){
                //assume it's first sign up
                console.log("creating user obj");
                await this.createUserObject(firstname,lastname);
                //send welcome email
                await this.sendWelcomeEmail(firstname,email);
            }       
            //TODO if snapshot exists but is missing name info update user   
            else{
                let data = snapshot.val();
                if(!data.photoURL && result.user.photoURL){
                    const updates = {};
                    updates[`users/${auth.currentUser.uid}/photoURL`] = result.user.photoURL;
                    updates[`users/${auth.currentUser.uid}/photoSource`]  = 'google';
                    await update(ref(db), updates);
                }
                else if(data.photoSource == 'google' && result.user.photoURL){
                    console.log('setting google img')
                    await set(ref(db,`users/${auth.currentUser.uid}/photoURL`),result.user.photoURL)
                }
                
            }

            //add app access - it won't add if data exists
            this.addAppAccess();

            const emailUidRel = await get( ref(db, 'emailUidRelationship/' + this.getEncodedEmail()) );
            if (!emailUidRel.exists()){
                await this.createEmailUidRelationship(); 
            }*/

            return;
        }

        catch(error){
            throw error; 
        }

    }

    //sign in google
    async googleSigninWithPopup(isRemembered=true, userType='writer'){

        const auth = getAuth();
        const db = getDatabase();

        let persistance = browserLocalPersistence; 
		//set up persistance
		if(!isRemembered)
			persistance = browserSessionPersistence;	
		
		try{
            await auth.setPersistence(persistance);
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth,provider);

            //create user in db if none exists
            const names = result.user.displayName.split(" ");
            const email = result.user.email;
            const firstname = names[0] ? names[0] : "";
            const lastname = names[1] ? names[1] : "";
            const userRef = ref(db, 'users/' + auth.currentUser.uid);
            const snapshot = await get(userRef);
            if(!snapshot.exists()){
                //assume it's first sign up

                await this.createUserObject(firstname,lastname, userType);
                //send welcome email
                await this.sendWelcomeEmail(firstname,email);
            }       
            //TODO if snapshot exists but is missing name info update user   
            else{
                let data = snapshot.val();
                if(!data.photoURL && result.user.photoURL){
                    const updates = {};
                    updates[`users/${auth.currentUser.uid}/photoURL`] = result.user.photoURL;
                    updates[`users/${auth.currentUser.uid}/photoSource`]  = 'google';
                    await update(ref(db), updates);
                }
                else if(data.photoSource == 'google' && result.user.photoURL){

                    await set(ref(db,`users/${auth.currentUser.uid}/photoURL`),result.user.photoURL)
                }
                
            }

            //add app access - it won't add if data exists
            this.addAppAccess();

            const emailUidRel = await get( ref(db, 'emailUidRelationship/' + this.getEncodedEmail()) );
            if (!emailUidRel.exists()){
                await this.createEmailUidRelationship(); 
            }

        }
        catch(error){
            throw error; 
        }
    }


    async logout(){
        const auth = getAuth();
        try{
            this.removeAllDataListners('users/' + auth.currentUser.uid); //force removal of all listners of the user object 
            await signOut(auth);
            return true;
        }
        catch(error){
            throw error;
        }        
    }

    //database
    async createUserObject(firstname="",lastname="", userType="writer"){
        const auth = getAuth(); 
        const db = getDatabase();
        const userRef = ref(db, 'users/' + auth.currentUser.uid);
        const createddate = Date.now();

        //TODO function to set last activity date
        const userObj = await set(userRef, {
			firstname: firstname,
			lastname: lastname,
			email: auth.currentUser.email,
            createddate:createddate,
            lastactivitydate:createddate, 
            type:userType,
            accountstarted:userType === 'writer' ? true:false, //defaults to the account being started (no getting started form for writers)
		}); 

        return userObj;
    }

    /*async createUserAccessObject(){
        const auth = getAuth(); 
        const db = getDatabase();
        const userAccessRef = ref(db, 'userAccess/' + auth.currentUser.uid);
        await set(userAccessRef, {
			appAccess: true,
            teacherAccess:false,
            productProAccess:false,
            schoolAccess:false
		}); 
    }*/

    async createEmailUidRelationship(){
        const auth = getAuth(); 
        const db = getDatabase();
        await set(ref(db, 'emailUidRelationship/' + this.getEncodedEmail()), auth.currentUser.uid); 
    }

    async updateLastActivityDate() {
        const auth = getAuth();
        const db = getDatabase();
        const now = Date.now();
        await set(ref(db,`users/${auth.currentUser.uid}/lastactivitydate`),now);
    }
    
    addUserListner(listner){
        const auth = getAuth(); 
        if(auth.currentUser){
            this.addDataListener('users/' + auth.currentUser.uid, listner); 
        }
        else{
            console.log('cannot add user listner, no currentuser');
        }
    }

    removeUserListner(listner, uid){
        const auth = getAuth();
        if(auth.currentUser || uid){
            uid = uid ? uid : auth.currentUser.uid;
            this.removeDataListener('users/' + uid, listner); 
        }
        else{
            console.log('cannot remove user listner, no currentuser or uid provided ');
        }
    } 

    addAccessListner(listner){
        const auth = getAuth(); 
        if(auth.currentUser){
            this.addDataListener('userAccess/' + auth.currentUser.uid, listner); 
        }
        else{
            console.log('cannot add user access listner, no currentuser');
        }
    }

    removeAccessListner(listner, uid){
        const auth = getAuth();
        if(auth.currentUser || uid){
            uid = uid ? uid : auth.currentUser.uid;
            this.removeDataListener('userAccess/' + uid, listner); 
        }
        else{
            console.log('cannot remove user access listner, no currentuser or uid provided ');
        }
    }

    async addAppAccess(){
        const uid = this.getUid();
        const db = getDatabase();
        const r = ref(db,`userAccess/${uid}/appAccess`);
        if(uid){
            const snap = await get(r);
            if(!snap.exists())
                set(r,true);
        }

    }

    async addTeacherAccess(){
        const uid = this.getUid();
        const db = getDatabase();
        const r = ref(db,`userAccess/${uid}/teacherAccess`);
        if(uid){
            const snap = await get(r);
            if(!snap.exists())
                set(r,true);
        }
    }


    //Email

    async sendWelcomeEmail(firstname,email){
        const token = await this.getUserToken();
        let data = {firstname,email,token}; 
        const res = await fetch( API_URL+'/mailwelcome', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        const resJson = await res.json();
    }

    /*
     *
     *Data listener
     * 
     */

    addDataListener(path, callback, rtq = null){
        //Create Map for the path if it doesnt already exist 
        if(!this.dataListnerCallbacks.has(path) ){
            this.dataListnerCallbacks.set(path, new Map()); 
        }
        let callbackMap = this.dataListnerCallbacks.get(path); 

        //if the callback is already registered, unregister and reregister 
        if(callbackMap.has(callback)) {
            this.removeDataListener(path, callback); 
        }


        //create the wraper func for callback 
        let callbackWrap = (snapshot) => {
            const val = snapshot.val();//(val returns null when no data there)
            const key = snapshot.key
            callback(val,key);//fire callback 
        }

        //add to the path 
        callbackMap.set(callback, callbackWrap); 

        //set up firebase listner
        const db = getDatabase();

        const R = rtq ? rtq : ref(db, path);

        onValue(R, callbackWrap);
    }

    removeDataListener(path, callback){
        //if the path does not exist return
        if(!this.dataListnerCallbacks.has(path)){
            console.log("removeDataListener failed, " + path + " no such path")
            return; 
        }

        //if no callbak at path return
        let callbackMap = this.dataListnerCallbacks.get(path); 
        if(!callbackMap.has(callback)) {
            console.log("removeDataListener failed, " + path + " no callback")
            return; 
        }

        //get the wraper func 
        let callbackWrap = callbackMap.get(callback); 

        //remove from the map and remove whole map at the path if empty
        callbackMap.delete(callback); 
        if(callbackMap.size == 0)
            this.dataListnerCallbacks.delete(path); 


        //remove listner from firebase
        const db = getDatabase();
        const R = ref(db, path);

        off(R, "value", callbackWrap); 
    }

    removeAllDataListners(path){
        
        //if the path does not exist return
        if(!this.dataListnerCallbacks.has(path)){
            console.log("removeDataListener failed, " + path + " no such path")
            return; 
        }

        //if no callbak at path return
        let callbackMap = this.dataListnerCallbacks.get(path); 

        callbackMap.forEach((callbackWrap, callback)=>{
            
            //remove listner from firebase
            const db = getDatabase();
            const R = ref(db, path);
            off(R, "value", callbackWrap); 

        });

        //delete whole data listners 
        this.dataListnerCallbacks.delete(path); 

    }

    /*
     *
     *Listener Chain
     * CHILDREN, products/$key 
     */

    addDenormalizerListner(path, ...params){

        //return if params too short
        if(params.length < 1){
            console.error("addDenormalizerListner more params needed"); 
            return; 
        }
        if(params.length % 2 == 0){
            console.error("addDenormalizerListner wrong number of parameters"); 
            return; 
        }


        let mainObject = {}; 

        //the main callback for the final denomalized data withh be the last parameter in the variac
        let mainCallback = params[params.length-1]; 

        let callbackList = []; 
        
        let makeCallback = (paramInx, parentObject)=> {return (snapshot)=>{

            //grab value and key
            const val = snapshot.val();//(val returns null when no data there)
            const key = snapshot.key; 

            //assign the val to the correct object
            let currentObject = {}
            if(!parentObject){
                mainObject = val ? val : {};
                currentObject = mainObject;  
            }else{
                parentObject[key] = val ? val : {};
                currentObject = parentObject[key]; 
            }

            //if the end of the tree is found call the callback with the main object 
            if(paramInx+2 >= params.length){
                mainCallback(mainObject); 
                return; 
            }

            let fromParam = params[paramInx];
            let toParam = params[paramInx+1];

            //get the keys of the object to map from
            let fromKeys = []; 
            if(fromParam == 'CHILDREN'){
                fromKeys = Object.keys(val ? val : {}); 
            }
            else if(fromParam == 'VALUE'){
                fromKeys = val ? [val] : []; 

                //when mapping from value, make the current an empty object
                parentObject[key] = {}
                currentObject = parentObject[key]; 
            }
            else if(typeof fromParam === 'string'){
                //if its just a string, assume you want the child specifically to map on
                let k = val && typeof val === 'object' ? val[fromParam] : null;
                fromKeys = k != null ? [ k ] : []; 
                
                currentObject[fromParam] = {}
                currentObject = currentObject[fromParam]; 
            }
            else if(typeof fromParam === 'function'){
                fromKeys = fromParam(key, val); 
            }

            //for each from key, compute the to key and add a listner for the toKey.
            for(let i = 0; i < fromKeys.length; i++){
                let fromkey = fromKeys[i]; 

                currentObject[fromkey] = null; 
                
                let toKey; 
                if(typeof toParam === 'string'){
                    toKey = toParam.replace('$key', fromkey); 
                }
                else{
                    toKey = toParam(key, val, fromkey);
                }

                //register callback listner
                let callback = makeCallback(paramInx+2, currentObject); 
                const db = getDatabase();
                const R = ref(db, toKey);
                callbackList.push({R, callback}); 
                onValue(R, callback); 
                
            }
            
        }}

        //register root path for listner
        let callback = makeCallback(0, null); 
        const db = getDatabase();
        const R = ref(db, path);
        callbackList.push({R, callback}); 
        onValue(R, callback);
    }

    addTriggerListener(id, listner){
        if(this.triggerListners.has(id)){
            let listnersSet = this.triggerListners.get(id);
            listnersSet.add(listner);
        }
        else{
            let listnersSet = new Set(); 
            listnersSet.add(listner);
            this.triggerListners.set(id, listnersSet)
        }
    }

    removeTriggerListener(id, listner){
        if(this.triggerListners.has(id)){
            let listnersSet = this.triggerListners.get(id);
            listnersSet.delete(listner);
            if(listnersSet.size == 0){
                this.triggerListners.delete(id);
            }
        }
    }

    trigger(id, ...args){
        let listnersSet = this.triggerListners.get(id); 
        if(listnersSet){
            for(let listner of listnersSet){
                listner(...args);
            }
        }
    }
    
}