import * as React from 'react';
import { styled } from '@mui/material/styles';
import { TextField } from "@mui/material";

const StyledTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#808080',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#808080',
    },
});

export default StyledTextField;