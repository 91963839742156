import React, {useState} from "react";

import Theme from '../../Theme';

import { Box } from "@mui/material";

const ConditionalImage = ({url,style,width,height,selected}) => {
    const border = `2px dashed #808080`;
    const borderSelected = `3px solid ${Theme.palette.success.main}`;
    //const borderHover = `2px solid ${Theme.palette.primary.main}`;
    //const borderHover = `2px solid transparent`;
    const borderTransition = `border-color 0.5s ease-in-out, border-width 0.1s ease-in-out`;
    const [imgError,setImageError] = useState(false);
    const handleImageError = () => {
        console.log('img error')
        setImageError(true)
    }
    return (
        <>
        {imgError ? (
            null
        ) : (
            <Box sx={{width, height, position:'relative', flexGrow:1,borderRadius: '5px', transition:!selected ? borderTransition : null,  border: selected ? borderSelected : border, '&:hover':{borderWidth:'3px',borderColor:selected ? Theme.palette.success.main : 'blue'}}}>
                <img style={style} src={url} referrerPolicy='no-referrer' onError={handleImageError}/>
            </Box>
        )}
        </>
    )
}

export default ConditionalImage; 