import { 
    getStorage,
    uploadBytes,
    deleteObject,
    getDownloadURL 
} from "firebase/storage";

import {ref as sRef} from "firebase/storage";

import { v4 as uuidv4 } from 'uuid';

import { 
    getDatabase, 
    ref, 
    set, 
    get, 
    child,
    onValue, 
    query as rtquery, 
    limitToFirst,
    startAfter as rtstartAfter,
    orderByChild,
    orderByKey,
    equalTo,
    off } from 'firebase/database';

import {
    getFirestore, 
    collection, 
    query, 
    orderBy, 
    startAfter, 
    limit, 
    getDocs,
    addDoc,
    onSnapshot,
    updateDoc,
    doc,
    setDoc,
    getDoc,
    deleteField,
    deleteDoc } from "firebase/firestore"; 


export default class IntellectibaseService {
    
    constructor(mainService){
        this.mainService = mainService; 
    }

    async isSuperAdminUser(){
        let returnVal = false
        const uid  = this.mainService.getUid(); 
        if(uid){
            const db = getDatabase();
            const snap = await get(ref(db,`admins/${uid}`));
            console.log(snap);
            if(snap.exists() && snap.val() === 'admin')
                returnVal = true;
        }
        return returnVal; 
    }

    addUserListner(listner){
        const uid = this.mainService.getUid(); 
        if(uid){
            //console.log('addUserListner ' + auth.currentUser.uid);
            this.mainService.addDataListener('users/' + uid, listner); 
        }
        else{
            console.log('cannot add user listner, no currentuser');
        }
    }

    removeUserListner(listner, uid){
        if(uid){
            this.mainService.removeDataListener('users/' + uid, listner); 
        }
        else{
            console.log('cannot remove user listner, no uid provided ');
        }
    }


    //
    //Product Listings
    //

    listenProductListings(orderby, lim, page, direction="forward", callback){
        const db = getDatabase();

        if(page===0){
            this.lastVisibleProducts = [];
        }
        let startdoc = null
        console.log("page " + page)
        if(direction==="forward" && page > 0){
            startdoc = this.lastVisibleProducts[this.lastVisibleProducts.length-1];
            //console.log(startdoc)
        }            
        else if(direction==="backward" && page>0){            
            startdoc = this.lastVisibleProducts[page-1];
            this.lastVisibleProducts.pop();        
        }

        let orderByChildKey = 
            orderby == 'Name' ? 'title':
            orderby == 'Status' ? 'status':
            'createdDate';

        
        let R = ref(db, 'productListingMetadata');
        let q = null;
        if(startdoc)
            q = rtquery(R, orderByChild(orderByChildKey), rtstartAfter(startdoc), limitToFirst(lim));
        else 
            q = rtquery(R, orderByChild(orderByChildKey), limitToFirst(lim));

        onValue(q, (querySnapshot)=>{
            let val = querySnapshot.val(); 
            
            console.log(val); 
            if(val){

                let valArray = Object.entries(val); 
                console.log("orderByChildKey  " + orderByChildKey)
                if(orderByChildKey == 'title')
                    valArray.sort((a, b)=>{ return ('' + a[1].title).localeCompare( b[1].title ); });
                else if(orderByChildKey == 'status')
                    valArray.sort((a, b)=>{ return ('' + a[1].status).localeCompare( b[1].status ); });
                else if(orderByChildKey == 'createdDate')
                    valArray.sort((a, b)=>{ return ('' + a[1].createdDate).localeCompare( b[1].createdDate ); });

                const lastVisible = valArray[valArray.length-1][1][orderByChildKey];
                this.lastVisibleProducts.push(lastVisible);

                valArray = valArray.map((v)=>{
                    v[1].id = v[0];
                    return v[1];
                });
                
                callback(valArray, page);
            }
        }); 
    }

    clearLastVisibleProducts() {
        this.lastVisibleProducts = [];
    }


    unsubscribeProductListings(){
        let R = ref(getDatabase(), 'productListingMetadata');
        off(R, "value");
    }

    async publishListing(listingId) {
        const db = getDatabase();       
        //create refs
        const draftRef = ref( db, `productListingDrafts/${listingId}`);
        const pubRef = ref(db,`productListingPublished/${listingId}`);
        const mdRef = ref( db,`productListingMetadata/${listingId}/status`);
        const inReviewRef = ref( db, `productListingDrafts/${listingId}/inReview`);
        const isPublishedRef = ref( db, `productListingDrafts/${listingId}/isPublished`);
        
        try {
            //set metadata to pending
            await set(mdRef, "review"); 
            //get draft listing data
            const snapshot = await get(draftRef);
            //copy to published
            if(snapshot.exists()){
                //create public listing
                await set(pubRef, snapshot.val());                                              
                //update listing metadata to published             
                await set(mdRef, "published"); 

                await set(inReviewRef, false ); 
                await set(isPublishedRef, true ); 

                //create product billing relationship
                if(snapshot.val().billingId){
                    await set(ref(db,`billingProductRelationship/${snapshot.val().billingId}/${listingId}`),true);
                }
            }

        }
        catch(error){
            throw error;
        }

    }

    setCreationStage(pid,stage){
        set(ref(getDatabase(),`productListingMetadata/${pid}/creationStage`),stage);
    }

    setBillingId(pid,bid){
        set(ref(getDatabase(),`productListingMetadata/${pid}/billingId`),bid);
        set(ref(getDatabase(),`productListingDrafts/${pid}/billingId`),bid);
    }

    async getProductOwner(pid){

        const db = getDatabase();

        const snap = await get(ref(db,`productListingCollaborators/${pid}`));
        if(!snap.exists())
            return '';

        const collaborators = snap.val();
        let owner = Object.keys(collaborators).find(el => collaborators[el] === 'owner');
        owner = decodeURIComponent(owner);
        return owner;
    }

    //
    //Users
    //

    listenUsers(orderby, lim, page, direction="forward", callback){
        console.log('listen users');
        console.log(this.lastVisibleUsers);

        const db = getDatabase();

        if(page===0){
            this.lastVisibleUsers = [];
        }
        let startdoc = null
        console.log("page " + page)

        if(direction==="forward" && page > 0){
            startdoc = this.lastVisibleUsers[this.lastVisibleUsers.length-1];
            console.log(startdoc)
        }            
        else if(direction==="backward" && page>0){            
            startdoc = this.lastVisibleUsers[page-1];
            this.lastVisibleUsers.pop();        
        }
        
        let R = ref(db, 'users');
        let q = null;
        if(startdoc)
            q = rtquery(R, orderByChild('createddate'), rtstartAfter(startdoc), limitToFirst(lim));
        else 
            q = rtquery(R, orderByChild('createddate'), limitToFirst(lim));

        onValue(q, (querySnapshot)=>{
            let val = querySnapshot.val(); 
            
            if(val){
                callback(val, page);
            }
        }); 
      
        
    }

    unsubscribeUsers(){
        let R = ref(getDatabase(), 'users');
        off(R, "value");
    }

    pushLastVisibleUsers(lv){
        this.lastVisibleUsers.push(lv);
    }

    addUserAccessListener(listener){
        this.mainService.addDataListener('userAccess', listener); 
    }

    removeUserAccessListener(listener){
        this.mainService.removeDataListener('userAccess', listener); 
    }

    setAppAccess(uid,val){
        set(ref(getDatabase(), 'userAccess/' + uid + '/appAccess'),val);
    }

    setTeacherAccess(uid,val){
        set(ref(getDatabase(), 'userAccess/' + uid + '/teacherAccess'),val);
    }

    setProductProAccess(uid,val){
        set(ref(getDatabase(), 'userAccess/' + uid + '/productProAccess'),val);
    }

    setWriterAccess(uid,val){
        set(ref(getDatabase(), 'userAccess/' + uid + '/writerAccess'),val);
    }

    setAccountStarted(uid,val){
        console.log(uid)
        set(ref(getDatabase(), 'users/' + uid + '/accountstarted'),val);
    }

    setUserType(uid,val){
        console.log(uid)
        set(ref(getDatabase(), 'users/' + uid + '/type'),val);
    }

    //
    //Billing
    //

    listenBillingPlanProductPro(callback){
        console.log('listen billing plan');

        const db = getDatabase();
        
        let R = ref(db, 'billingPlan');
        let q = null;

        q = rtquery(R, orderByChild('createddate'));

        onValue(q, (querySnapshot)=>{
            let val = querySnapshot.val(); 
            if(val){
                //Filter val by plan name
                let valArray = Object.entries(val).filter(e => e[1].plan == 'productfree' ||  e[1].plan == 'productpro');
                valArray.sort((a, b)=>{ return ('' + a[1].createdDate).localeCompare( b[1].createdDate ); });
                valArray = valArray.map((v)=>{
                    v[1].id = v[0];
                    return v[1];
                });
                callback(valArray);
            }
        }); 
        
    }

    unsubscribeBillingPlan(){
        let R = ref(getDatabase(), 'billingPlan');
        off(R, "value");
    }

    clearLastVisiblePlans() {
        this.lastVisiblePlans = [];
    }

    async getBillingOwner(bid){

        const db = getDatabase();

        const snap = await get(ref(db,`billingMembers/${bid}`));
        if(!snap.exists())
            return '';

        const members = snap.val();
        let admin = Object.keys(members).find(el => members[el] === 'admin');
        admin = decodeURIComponent(admin);
        return admin;
    }

    setBillingPlanName(bid,name){
        set(ref(getDatabase(),`billingPlan/${bid}/name`),name);
    }

    setBillingPlanWebsiteUrl(bid,websiteurl){
        set(ref(getDatabase(),`billingPlan/${bid}/websiteurl`),websiteurl);
    }

    setBillingPlanLogoUrl(bid,logourl){
        set(ref(getDatabase(),`billingPlan/${bid}/logourl`),logourl);
    }

    setBillingPlanPlan(bid,plan){
        set(ref(getDatabase(),`billingPlan/${bid}/plan`),plan);
    }

    setBillingMemberAccess(bid,email,access){
        set(ref(getDatabase(),`billingMembers/${bid}/${email}`),access);
    }

    async addBillingMember(bid,email,access){
        const safeEmail = email.trim().toLowerCase().replace(new RegExp("\\.","g"),"%2E");
        const db = getDatabase();
        const emailUidSnap = await get(ref(db,`emailUidRelationship/${safeEmail}`));
        const uid = emailUidSnap.val();
        await set(ref(db,`billingMembers/${bid}/${safeEmail}`),access);
        await set(ref(db,`userBillingRelationship/${safeEmail}/${bid}`),true);
        await set(ref(db,`users/${uid}/activeBillingId`),bid);
    }

    async removeBillingMember(bid,email){
        console.log('removing billing member');
        const db = getDatabase();
        await set(ref(db,`billingMembers/${bid}/${email}`),null);
        await set(ref(db,`userBillingRelationship/${email}/${bid}`),null);
        const emailUidSnap = await get(ref(db,`emailUidRelationship/${email}`));
        const uid = emailUidSnap.val();
        const abidSnap =  await get(ref(db,`users/${uid}/activeBillingId`));
        if(abidSnap.exists()){
            const activeBillingId = abidSnap.val();
            console.log('active billing id',activeBillingId);
            console.log('org billing id',bid)
            if(activeBillingId === bid){
                console.log('removing billing id')
                await set(ref(db,`users/${uid}/activeBillingId`),null);
            }
        }
    }

    async removeBillingAccount(bid,billingMembers){
        const db = getDatabase();
        console.log(Object.keys(billingMembers))
        const promlist = [];
        let uid = null;
        for(const em of Object.keys(billingMembers)){
            //remove active billing id if matches && user billing relationship
            
            promlist.push(get(ref(db,`emailUidRelationship/${em}`))
            .then((snap)=>{
                uid = snap.exists() ? snap.val() : null;
                if(!uid){
                    console.log('no user skipping');
                    return Promise.resolve(null);
                }
                return get(ref(db,`users/${uid}/activeBillingId`))
            })
            .then((snap)=>{
                if(!snap){
                    return Promise.resolve();
                }
                const abid = snap && snap.exists() ? snap.val() : null;
                if(abid && abid === bid){
                    console.log('removing active billing');
                    return set(ref(db,`users/${uid}/activeBillingId`),null);
                }
                else {
                    console.log('no active billing skipping');
                    return Promise.resolve();
                }
            })
            .then(()=>{
                console.log('removing uid rel');
                return set(ref(db,`userBillingRelationship/${em}/${bid}`),null);
            })
            .catch((error)=>{
                console.error(error.message);
            }))

        }
        await Promise.all(promlist);
        //remove members
        await set(ref(db,`billingMembers/${bid}`),null);

        //remove information
        await set(ref(db,`billingPlan/${bid}`),null);
        await set(ref(db,`billingInformation/${bid}`),null);
    }

    //
    //Generated content
    //

    listenGeneratedContent(orderby, lim, page, direction="forward", callback){
        const db = getDatabase();

        if(page===0){
            this.lastVisibles = [];
        }

        let startdoc = null
        console.log("page " + page)
        if(direction==="forward" && page > 0){
            startdoc = this.lastVisibles[this.lastVisibles.length-1];
        }            
        else if(direction==="backward" && page>0){            
            startdoc = this.lastVisibles[page-1];
            this.lastVisibles.pop();        
        }
        
        let R = ref(db, 'generatedContent');
        let q = null;
        console.log(JSON.stringify(startdoc))
        
        let startAfter = startdoc && startdoc[1][orderby] ? startdoc[1][orderby] : null;
        console.log('orderBy '+orderby)
        console.log('startAfter '+startAfter)

        if(startdoc)
            q = rtquery(R, orderByChild(orderby), rtstartAfter(startAfter), limitToFirst(lim));
        else 
            q = rtquery(R, orderByChild(orderby), limitToFirst(lim));

        onValue(q, (querySnapshot)=>{
            let val = querySnapshot.val(); 
            console.log('value')
            console.log(val); 
            if(val){

                let valArray = Object.entries(val); 
                console.log("orderByChildKey  " + orderby)
                if(orderby == 'prompt')
                    valArray.sort((a, b)=>{ return ('' + a[1].title).localeCompare( b[1].title ); });
                else if(orderby == 'text')
                    valArray.sort((a, b)=>{ return ('' + a[1].status).localeCompare( b[1].status ); });
                else if(orderby == 'contentType')
                    valArray.sort((a, b)=>{ return ('' + a[1].createdDate).localeCompare( b[1].createdDate ); });

                const lastVisible = valArray[valArray.length-1];
                this.lastVisibles.push(lastVisible);

                valArray = valArray.map((v)=>{
                    v[1].id = v[0];
                    return v[1];
                });
                
                console.log(valArray)
                callback(valArray, page);
            }
        });         
    }

    unsubscribeContent(){
        let R = ref(getDatabase(), 'generatedContent');
        off(R, "value", );
    }
    
    //
    //database queries
    //

    clearLastVisibles(){
        this.lastVisibles= [];
    }

    async listenQueryData(collName,orderby,lim,page,direction="forward",callback){

        this.unsubscribeQueryListener();

        const fs = getFirestore();
        if(page===0){
            this.lastVisibles = [];
        }
        let startdoc = null
        console.log("page " + page)
        if(direction==="forward" && page > 0){
            startdoc = this.lastVisibles[this.lastVisibles.length-1];
            //console.log(startdoc)
        }            
        else if(direction==="backward" && page>0){            
            startdoc = this.lastVisibles[page-1];
            this.lastVisibles.pop();        
        }

        let q = null
        //add one to limit - hack for table pagination component to undisable button
        if(startdoc){
            q = query(collection(fs, collName),orderBy(orderby),startAfter(startdoc),limit(lim));
        }
        else {
            q = query(collection(fs, collName), orderBy(orderby), limit(lim));
        }
        try{
            console.log("setting up listener")
            this.unsubscribeQueryData = onSnapshot(q, (querySnapshot) => {
                const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
                this.lastVisibles.push(lastVisible);
                const docs = querySnapshot.docs;
                callback(docs,page)
            },  
            (error) => {
                throw error
            });
            //get data
            /*const documentSnapshots = await getDocs(q);
            console.log("setting last visible")
            const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
            this.lastVisibles.push(lastVisible);
            docs = documentSnapshots.docs;*/
        }
        catch(error){
            throw error
        }      

        //return docs;
    }

    unsubscribeQueryListener () {
        if(this.unsubscribeQueryData){
            this.unsubscribeQueryData();
        }
    }

    unsubscribeDocListener(){
        if(this.unsubscribeDocData){
            this.unsubscribeDocData();
        }
    }

    listenDocumentData (collName,docId,callback){
        this.unsubscribeDocListener();
        const fs = getFirestore(); 
        const docRef = doc(fs, collName, docId);
        this.unsubscribeDocData = onSnapshot(docRef, (docSnapshot) => {
            const doc = docSnapshot.data();
            callback(doc);
        },  
        (error) => {
            throw error
        });
    }

    unsubscribeGranteeCriteria (){
        if(this.unsubscribeGrantee){
            this.unsubscribeGrantee()
        }       
    }

    listenGranteeCriteria (docId,callback) {
        this.unsubscribeGranteeCriteria();
        const fs = getFirestore();
        const docRef = doc(fs, "grants", docId);
        const subcolRef = collection(docRef, "granteeCriteria");
        this.unsubscribeGrantee = onSnapshot(subcolRef, (snapshot) => {
            let docs = null
            if(!snapshot.empty){
                docs = snapshot.docs;          
            }
            callback(docs)
        },  
        (error) => {
            console.error(error);
        });
    }

    unsubscribeProjectCriteria () {
        if(this.unsubscribeProject){
            this.unsubscribeProject();
        }
    }

    listenProjectCriteria (docId, callback) {
        this.unsubscribeProjectCriteria();
        const fs = getFirestore();
        const docRef = doc(fs, "grants", docId);
        const subcolRef = collection(docRef, "projectCriteria");
        this.unsubscribeProject = onSnapshot(subcolRef, (snapshot) => {
            let docs = null
            if(!snapshot.empty){
                docs = snapshot.docs;              
            }
            callback(docs)
        },  
        (error) => {
            console.error(error);
        });
    }

    unsubscribeRequirements () {
        if(this.unsubscribeRequirements){
            this.unsubscribeRequirements();
        }
    }

    listenRequirements(docId, callback) {
        this.unsubscribeProjectCriteria();
        const fs = getFirestore();
        const docRef = doc(fs, "grants", docId);
        const subcolRef = collection(docRef, "applicationRequirements");
        this.unsubscribeRequirements = onSnapshot(subcolRef, (snapshot) => {
            let docs = null
            if(!snapshot.empty){
                docs = snapshot.docs;              
            }
            callback(docs)
        },  
        (error) => {
            console.error(error);
        });
    }



    async createDocument(collName,docData) {
        const fs = getFirestore(); 
        console.log(docData)
        if(docData && Object.keys(docData).length !== 0){
            try{
               const docRef =  await addDoc(collection(fs, collName), docData);
               return docRef.id;
            }
            catch(error){
                throw error;
            }
        }
        else {
            throw new Error("no data");
        }
    }

    async updateDocData(collName,docId,fieldName,value){
        const fs = getFirestore(); 
        const docRef = doc(fs, collName, docId);
        let data = {}
        data[fieldName]=value;
        try{
            await updateDoc(docRef, data);
        }
        catch(error){
            throw error;
        }
    }

    async setToSubcollection (collName,docId,subcollName,subCollDocId,data){
        const fs = getFirestore();
        try{
            console.log("setting to subcoll")
            const docRef = doc(fs, collName, docId);
            const subcolRef = collection(docRef, subcollName);
            const subColDocRef = doc(subcolRef, subCollDocId);

            //try to get doc to see if exists
            const docSnapshot = await getDoc(subColDocRef);
            if(docSnapshot.exists()){
                console.log("updating doc")
                await updateDoc(subColDocRef, data);
            }
                
            else {
                console.log("setting doc")
                await setDoc(subColDocRef, data);
            }
                
        }
        catch(error){
            throw error;
        }
    }

    async updateDocInSubcollection(collName,docId,subcollName,subCollDocId,data){
        const fs = getFirestore();
        try{
            console.log("updating doc")
            const docRef = doc(fs, collName, docId);
            const subcolRef = collection(docRef, subcollName);
            const subColDocRef = doc(subcolRef, subCollDocId);          
            await updateDoc(subColDocRef, data);
        }
        catch(error){
            throw error;
        }
    }

    async addToSubcollection(collName,docId,subcollName,data) {
        const fs = getFirestore();
        try{
            console.log("adding to subcoll")
            const docRef = doc(fs, collName, docId);
            const subcolRef = collection(docRef, subcollName);
            await addDoc(subcolRef, data);
        }
        catch(error){
            throw error;
        }

    }

    async deleteDocFromSubcollection(collName,docId,subcollName,subCollDocId) {
        const fs = getFirestore();
        try{
            const docRef = doc(fs, collName, docId);
            const subcolRef = collection(docRef, subcollName);
            const subColDocRef = doc(subcolRef, subCollDocId);
            
            const delDoc = await getDoc(subColDocRef);
            if(delDoc.exists()){
                await deleteDoc(subColDocRef);               
            }
        }
        catch(error){
            throw error;
        }
    }

    async deleteFieldFromSubcollection(collName,docId,subcollName,subCollDocId,field) {
        const fs = getFirestore();
        try{
            const docRef = doc(fs, collName, docId);
            const subcolRef = collection(docRef, subcollName);
            const subColDocRef = doc(subcolRef, subCollDocId);
            
            const delDoc = await getDoc(subColDocRef);
            if(delDoc.exists()){
                //check number of keys, if less than 2 delete doc otherwise update
                if(Object.keys(delDoc.data()).length < 2){
                    await deleteDoc(subColDocRef);
                }
                else{
                    console.log('using delete field')
                    let data = {}
                    data[encodeURIComponent(field)]=deleteField();
                    console.log(data)
                    await updateDoc(subColDocRef, data);
                }
            }
        }
        catch(error){
            throw error;
        }
    }


}

