import { 
    getStorage,
    uploadBytes,
    deleteObject,
    getDownloadURL 
} from "firebase/storage";

import {ref as sRef} from "firebase/storage";

import { 
    getDatabase, 
    ref, 
    remove,
    set, 
    get, 
    push, 
} from 'firebase/database';

import { v4 as uuidv4 } from 'uuid';

export default class PPDashboardService {
    
    constructor(mainService){
        this.mainService = mainService; 
    }

    addDashboardDataListner(listener){
        let email = this.mainService.getEncodedEmail(); 
        /*
        this.mainService.addDenormalizerListner(`userProductListingRelationships/${email}`, 
                                                'CHILDREN', 'productApplicationRelationship/$key', 
                                                'CHILDREN', 'applicationCollaborators/$key', 
                                                'CHILDREN', 'emailUidRelationship/$key', 
                                                'VALUE', 'users/$key', 
                                                (v)=>console.log(v)); 
        */
        this.mainService.addDenormalizerListner(`userProductListingRelationships/${email}`, 
                                                'CHILDREN', 'productApplicationRelationship/$key',
                                                'CHILDREN', 'applicationDashboardData/$key', 
                                                'ownerId', 'users/$key',
                                                listener);
    }

    removeDashboardDataListner(listner){

    }

    addOrgDashboardDataListner(bid, listener){
        this.mainService.addDenormalizerListner(`billingProductRelationship/${bid}`, 
                                                'CHILDREN', 'productApplicationRelationship/$key',
                                                'CHILDREN', 'applicationDashboardData/$key', 
                                                'ownerId', 'users/$key',
                                                listener);
    }

    removeOrgDashboardDataListner(bid, listner){

    }

    addUserObjectListner(uid, listner){
        this.mainService.addDataListener('users/'+uid, listner);
    }

    removeUserObjectListner(uid, listner){
        this.mainService.removeDataListener('users/'+uid, listner);
    }
}

