import React, {useState, useEffect, useContext } from "react";


import MainService from "../../MainService"; 
import MainContext from "../../MainContext"; 

import { ThemeProvider } from  '@mui/system';
import { Box, Typography, MenuItem, Menu, Popper,ClickAwayListener,Fade} from "@mui/material";

import SearchBar from '../../components/SearchBar'; 

import TextFormInput from "../../components/formInputs/TextFormInput";

import { calculateSchoolIncomeDemo } from "../../Util";
import AddressFormInput from "../../components/formInputs/AddressFormInput";
import FormTitle from "../../components/formInputs/FormTitle";



const SchoolInput = ({formData,setDisableContinueButton})=>{
   
    const mainService = useContext(MainContext);

    const [searchResults,setSearchResults] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null); 
    const [resultsMenuOpen, setResultsMenuOpen] = useState(false); 
    const [startAnimation,setStartAnimation] = useState(false);

    let userObject = formData.userObject;

    const handleSchoolSearchResults = (results) => {
        setSearchResults([...results]);
        openResultsMenu();
    }

    const handleClearSearchResults = () =>{
        setSearchResults([]);
        closeResultsMenu();
    }

    const handleSetSchoolValues = (schoolData) => {
        //set datas
        mainService.profileService.setSchoolName(schoolData.SchoolName);

        if(schoolData.StreetAddress){
            mainService.profileService.setSchoolStreetAddress(schoolData.StreetAddress);
        }
        if(schoolData.City){
            mainService.profileService.setSchoolCity(schoolData.City);
        }
        if(schoolData.State){
            mainService.profileService.setSchoolState(schoolData.State);
        }
        if(schoolData.ZIP){
            mainService.profileService.setSchoolZipCode(schoolData.ZIP);
        }
        if(schoolData.Title1SchoolWide){
            console.log("title one school wide")
            mainService.profileService.setSchoolTitle1("schoolwide");
        }
        if(schoolData.Title1School && !schoolData.Title1SchoolWide){
            console.log("title one targeted")
            mainService.profileService.setSchoolTitle1("targeted");
        }
        if(!schoolData.Title1School && !schoolData.Title1SchoolWide){
            console.log("not title one")
            mainService.profileService.setSchoolTitle1("none");
        }
        if(schoolData.Locale){
            let localeStr = schoolData.Locale.split(": ")[0];
            localeStr = localeStr.charAt(0).toLowerCase() + localeStr.slice(1);
            mainService.profileService.setSchoolLocale(localeStr);
        }
        if(schoolData.Students !== null && (schoolData.FreeLunch !== null || schoolData.ReducedLunch !== null)){
            const nstudents = parseInt(schoolData.Students,0);
            const nFL = parseInt(schoolData.FreeLunch,0);
            const nRPL = parseInt(schoolData.ReducedLunch,0);
            if(nstudents>0){
                const incomeDemo = calculateSchoolIncomeDemo(nstudents,nFL,nRPL);
                const percentageFRPL = Math.round((nFL + nRPL)/nstudents*100).toString();
                mainService.profileService.setSchoolIncomeDemo(incomeDemo);
                mainService.profileService.setPercentFreeLunch(percentageFRPL);
            }
        }
        
        if(schoolData.LowGrade){
            let grade = schoolData.LowGrade;
            if(grade==="PK" || grade==="KG"){
                grade = grade.toLowerCase()
            }
            else{
                grade = `g${grade}`
            }
            mainService.profileService.setSchoolGradeLow(grade);
        }
        if(schoolData.HighGrade){
            let grade = schoolData.HighGrade;
            if(grade==="PK" || grade==="KG"){
                grade = grade.toLowerCase()
            }
            else{
                grade = `g${grade}`
            }
            mainService.profileService.setSchoolGradeHigh(grade);
        }
        if(schoolData.Magnet){
            mainService.profileService.setSchoolType('magnet');
        }
        if(schoolData.Charter){
            mainService.profileService.setSchoolType('charter');
        }
        if(!schoolData.Charter && !schoolData.Magnet){
            mainService.profileService.setSchoolType('traditional');
        }
        if(schoolData.Students && schoolData.Students > 0){
            mainService.profileService.setSchoolStudentCount(schoolData.Students);
        }
        if(schoolData.Teachers && schoolData.Teachers > 0){
            let numTeachers = Math.floor(schoolData.Teachers)
            mainService.profileService.setSchoolTeacherCount(numTeachers);
        }
   
        setResultsMenuOpen(false);
        animateInputs();
    }

    const animateInputs = () => {
        setStartAnimation(true);
        setTimeout(() => {
            setStartAnimation(false);;
        }, "2000")
    }

    const openResultsMenu = ()=>{
        setResultsMenuOpen(true);
    }

    const closeResultsMenu = ()=>{
        setResultsMenuOpen(false);
    }

    useEffect(()=>{
        let disableButton = !(userObject && userObject.schoolname && userObject.schoolstate 
            && userObject.schoolcity && userObject.schoolstreetaddress && userObject.schoolzipcode); 
        if(setDisableContinueButton)
            setDisableContinueButton(disableButton); 
    }, [formData]);

    return(
    <React.Fragment>

        <Box sx={{ flexGrow:1, maxWidth:'510px', height:'100%', display:'flex', alignItems:'stretch', flexDirection:'column',}}>
            <Box sx={{height:'10%'}}></Box>
            
            <FormTitle text='Which school do you represent?'/>
            <Box sx={{display:'flex', alignItems:'stretch', flexDirection:'column' }}>
                <ClickAwayListener onClickAway={closeResultsMenu}>
                <Box ref={(el)=>setAnchorEl(el)} sx={{mx:'8px', mt:'24px', mb:'64px',}}>
                    <SearchBar type="schools" displayText="schools" onResults={handleSchoolSearchResults} onClear={handleClearSearchResults} maxHits={10}/>
                    <Popper
                        anchorEl={anchorEl}
                        open={resultsMenuOpen}
                        transition
                    >  
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>                    
                            <Box 
                                sx={{
                                    minWidth:'432px',
                                    backgroundColor:"white",
                                    borderRadius:"5px", 
                                    boxShadow: `rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px`}}
                            >
                            {//boxShadow:`rgba(22, 45, 61, 0.12) 0px 2px 4px`  boxShadow:`rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px` 
                            //boxShadow: `rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px`
                            searchResults.length > 0 ?
                            
                            searchResults.map((v)=>{ return (
                                <MenuItem key={v.document.id} sx={{ minWidth:'230px'}} onClick={()=>handleSetSchoolValues(v.document)}>{`${v.document.SchoolName}, ${v.document.State}`}</MenuItem>
                            )
                            }) :(
                                <MenuItem key="noResults" sx={{ minWidth:'230px'}} disabled>No results</MenuItem>
                            )                   
                            }
                            </Box>
                        </Fade>
                    )}
                    </Popper>
                   
                </Box>
                </ClickAwayListener>

                <TextFormInput title={"What is your school's name?"} value={userObject.schoolname} onChange={(v)=>mainService.profileService.setSchoolName(v)} startAnimation={startAnimation}/>

                <AddressFormInput 
                    title={"what is your school's address?"}
                    streetAddress={userObject.schoolstreetaddress}
                    onStreetAddressChange={(v)=>mainService.profileService.setSchoolStreetAddress(v)}
                    city={userObject.schoolcity}
                    onCityChange={(v)=>mainService.profileService.setSchoolCity(v)}
                    state={userObject.schoolstate}
                    onStateChange={(v)=>mainService.profileService.setSchoolState(v)}
                    zipcode={userObject.schoolzipcode}
                    onZipCodeChange={(v)=>mainService.profileService.setSchoolZipCode(v)}
                    startAnimation={startAnimation}
                />
            </Box>
        </Box>
    </React.Fragment>
    )
}

export default SchoolInput;