import { Box, Typography, ButtonBase, IconButton, InputBase } from "@mui/material";
import Theme from '../Theme';

const StyledButton = ({text, onClick, disabled, sx, icon, colors})=>{

    let Icon = icon?icon:null;

    //Render
    return(
        <ButtonBase
            onClick={onClick}
            disabled={disabled}
            sx={{
                p:Theme.spacing(1),
                borderRadius:"50px",
                color: "white",
                backgroundColor:disabled ? Theme.palette.grey[400] : colors ? colors[0] :Theme.palette.primary.main,
                borderWidth:"1px",
                "&:hover": {
                    backgroundColor: colors ? colors[1]: Theme.palette.primary.dark,
                },
                ...sx
            }}
        >
            {icon && <Icon/>}
            <Typography variant='button' sx={{mx:'16px', color: "white" }}>{text}</Typography>
        </ButtonBase>
    )
}

export default StyledButton; 