
import { API_URL } from './Config';


export default class TextEditingService {


    constructor(mainService){
        this.mainService = mainService; 
    }


    async requestText(text,path){
        console.log('making request');
        //send request for content data to be created
        try{
            const userToken = await this.mainService.getUserToken();
            
            let data = {userToken, text}; 

            const res = await fetch( API_URL+path, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            const json = await res.json();
            console.log(JSON.stringify(json))
            if(!json.ok)
                throw json.message;

            return json?.text ? json.text : '';
        }
        catch(err){
            console.error('Content generation Error:', err);
        };
    }

    async expandPoint(text,context){
        console.log('requesting point expansion for ' + text);
        //send request for content data to be created
        const edit = await this.requestText(text,'/expandpoint');
        return edit;
    }

    async shortenPoint(text,context){
        console.log('requesting point shortening for ' + text);
        //send request for content data to be created
        const edit = await this.requestText(text,'/shortenpoint');
        return edit;
    }

    async removeDetail(text,context){
        console.log('removing unnecessary detail from ' + text);
        //send request for content data to be created
        const edit = await this.requestText(text,'/removedetail');
        return edit;
    }

    async provideEvidence(text,context){
        console.log('providing evidence for ' + text);
        //send request for content data to be created
        const edit = await this.requestText(text,'/provideevidence');
        return edit;
    }

    async improveWording(text,context){
        console.log('improve wording for ' + text);
        const edit = await this.requestText(text,'/improvewording');
        return edit;
    }


}