import React, {useState, useEffect, useContext } from "react";

import Theme from '../../Theme';

import MainContext from "../../MainContext"; 

import { imgSrcToBlob } from 'blob-util';

import { Box, IconButton, Typography, Link, TextField, ButtonBase, Tooltip,CircularProgress, Checkbox, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, ListItemText, ListItemIcon, getNativeSelectUtilityClasses} from "@mui/material";

import FormFlow from '../../components/FlowForm'; 
import StyledButton from "../../components/StyledButton";
import TextFormInput from "../../components/formInputs/TextFormInput";
import Paper from "../../components/Paper";
import AnimatedTypography from "../../components/AnimatedTypography";
import BorderPulsate from "../../components/BorderPulsate";
import ResponsiveDialog from "../../components/ResponsiveDialog";
import AutoTextFlow from "../../components/AutoTextFlow";
import FileDragAndDrop from "../../components/FileDragAndDrop";
import YesNoDialog from "../../components/YesNoDialog";

import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DoneIcon from '@mui/icons-material/Done';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import WebImagesDialog from "./WebImagesDialog";

import {isURL} from 'validator';
import { getCurrencyAdornment } from "../../Util";

const getAgeRangeString = (minAge,maxAge) => {
    if(minAge && maxAge){
        return minAge + ' ' + '-' + ' ' + maxAge;
    }
    else if (minAge && !maxAge){
        return minAge + '+';
    }
    else if (!minAge && maxAge){
        return '0 ' + '-' + ' ' + maxAge;
    }
    else {
        return null;
    }
}

const LoadingOne = ({animation}) => {
    return (
        (<Typography align='center' sx={{animation}} variant='h1'>Hold tight while we grab your product information...</Typography>)
    )
}

const LoadingTwo = ({animation}) => {
    return (
        (<Typography align='center' sx={{animation}} variant='h1'>This normally takes around 10 - 15 seconds</Typography>)
    )
}

const LoadingThree = ({}) => {
    return(
        <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
            <CircularProgress size='100px' sx={{mb:'48px'}}/>
            <AnimatedTypography 
                texts={[
                    'Searching the web', 
                    'Gathering data',
                    'Reading results', 
                    'Applying some AI magic', 
                    'Generating content',  
                ]}
            />
        </Box>
    )
}

const LongLoadingOne = ({animation}) => {
    return (<Typography align='center' sx={{animation}} variant='h1'>This is taking a bit longer than expected</Typography>);
}

const LongLoadingTwo = ({animation}) => {
    return (<Typography align='center' sx={{animation}} variant='h1'>Hold on a little longer</Typography>);
}

const LongLoadingThree = ({animation}) => {
    return (<Typography align='center' sx={{animation}} variant='h1'>Not much longer now (hopefully 🤞)</Typography>);
}

const ProductLoading = ({playing,isLong,onFinish}) => {

    //RENDER 
    const data = [
        {},
        {component:LoadingOne},
        {component:LoadingTwo},
        {component:LoadingThree}
    ];

    const longData = [
        {},
        {component:LongLoadingOne},
        {component:LongLoadingTwo},
        {component:LongLoadingThree}

    ];

    return (
        <>
            {isLong ? (
                <>
                    <AutoTextFlow data={longData} playing={playing} iterations={'infinite'} autostop={false} onFinish={()=>{onFinish()}}/>
                    <CircularProgress/>
                </>
            ) : (
                <AutoTextFlow data={data} playing={playing} iterations={1} autostop={false} onFinish={()=>{onFinish()}}/>
            )}
        </> 
    )
}

const FileUploadDialog  = ({onClose,open,onImageFiles,onUploadImages,imgLoading,limit}) => {
    return (
        <ResponsiveDialog onClose={onClose} open={open} >
            <DialogTitle>Upload your logo</DialogTitle>
            <DialogContent>
                <Box sx={{width: "450px"}}>
                    <FileDragAndDrop
                        sx={{width:'100%', height:'200px'}}
                        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                        maxFileSize={3000000}
                        filesLimit={limit}
                        dropzoneText='drop logo pic here, or click'
                        onChange={onImageFiles}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="outlined">
                    Cancel
                </Button>
                <Button 
                    variant="contained" 
                    sx={{color:"white"}}
                    onClick = {onUploadImages}
                    disabled={imgLoading}
                >
                    Upload
                </Button>
            </DialogActions>
        </ResponsiveDialog>
    )
}


const Start = ({formData,continueFlow})=>{
    const [continueDisabled,setContinueDisabled] = useState(true);
    const [adviceDlgOpen,setAdviceDlgOpen] = useState(false)
    const [animate,setAnimate] = useState(false);
    const [loading,setLoading] = useState(true);
    const mainService = useContext(MainContext);

    const url = formData?.productData?.websiteurl ? formData.productData.websiteurl : '';
    const listingId = formData.listingId;

    const handlePasteText = async () => {
        const clipText = await navigator.clipboard.readText();
        const validUrl = isURL(clipText);
        if(validUrl){
            mainService.productListingService.writeListingURL(listingId, clipText)
            setAnimate(true);
            setTimeout(()=>{
                setAnimate(false)
            },1000)
        }
        else   
            formData.onAlert('Invalid URL','error')
    }

    const handleContinue = async () => {
        if(isURL(url)){
            await Promise.all([
                mainService.productListingService.writeListingGenerated(listingId,false),
                mainService.productListingService.writeListingGenerationError(listingId,false)
            ])
            mainService.productListingService.getProductListingData(url,listingId)
            continueFlow();
        }
    }

    const handleDlgClose = () => {
        setAdviceDlgOpen(false);
    }

    //EFFECTS 
    useEffect(() => {
        let timer = null;
        if(formData.listingId){
            timer =  
            setTimeout(() => {
                setLoading(false);
            }, "1000");
        }

        return (()=>{
            if(timer && formData.listingId){
                clearTimeout(timer);
            }
        })

    }, [formData.listingId]);

    //effect to check url
    useEffect(()=>{
        setContinueDisabled(!isURL(url));
    }, [formData]);

    return(
        <>
        <Box sx={{ height:'100%', flexGrow:1, display:'flex', alignItems:'stretch', flexDirection:'column',maxWidth:'600px'}}>
            {loading ? (
            <Box sx={{width:'100%',height:'100%',flexGrow:1,display:'flex',alignItems:'center',justifyContent:'center'}}>
                <CircularProgress/>
            </Box>) : (
            <>
            <Box sx={{height:'10%'}}></Box>
            <Typography gutterBottom align='left' variant="h2">Time to create your product listing!</Typography>
            <Typography sx={{mb:'16px'}} align='left'>This is the product information that teachers will see and will be fed into their grant proposals.</Typography>
            <Typography gutterBottom align='left'>Paste the URL for your product in the form below and click continue. Intellectible AI will do it's best effort at getting the data we need to start building out your listing.</Typography>
            <Box sx={{height:'10%'}}></Box>
            <Box sx={{my:'16px', display:'flex', flexDirection:'column', flexGrow:1}}>
                <Typography gutterBottom>Paste your URL here, <Link onClick={()=>setAdviceDlgOpen(true)}>click here</Link> to see which type of pages work best</Typography>
                <Box sx={{display:'flex',gap:'4px',justifyContent:'center'}}>
                    <BorderPulsate startAnimation={animate} sx={{flexGrow:1}}>
                    <TextField
                        fullWidth
                        value={url}
                        onChange={(e)=>mainService.productListingService.writeListingURL(listingId, e.target.value)}
                        type='url'
                        autoComplete="url"
                    /> 
                    </BorderPulsate>
                    <Tooltip title='Paste URL'>
                        <ButtonBase onClick={handlePasteText} sx={{p:'8px',borderRadius:'50px'}}>
                            <ContentPasteIcon sx={{color:Theme.palette.grey[400]}}/>
                        </ButtonBase>
                    </Tooltip>
                </Box>               
            </Box>
            <Box sx={{flexGrow:1}}/>
            
            </>)}
            <StyledButton disabled={continueDisabled} text='Continue' sx={{mb:'64px', width:"175px", alignSelf:'center'}} onClick = {handleContinue}/>
        </Box>
        <ResponsiveDialog open={adviceDlgOpen} onClose={handleDlgClose}>
            <DialogTitle>Best practice for grabbing product information</DialogTitle>
            <DialogContent>
                <List dense>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{minWidth:0,mt:'4px',mr:'16px'}}>
                            ◾
                        </ListItemIcon>
                        <ListItemText>
                            Pick a page that is dedicated to the product you want to list, such as the product landing page or shop listing.
                        </ListItemText>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{minWidth:0,mt:'4px',mr:'16px'}}>
                            ◾
                        </ListItemIcon>
                        <ListItemText>
                            If possible, make sure the page showcases just one product (rather than multiple bundles or products.)
                        </ListItemText>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{minWidth:0,mt:'4px',mr:'16px'}}>
                            ◾
                        </ListItemIcon>
                        <ListItemText>
                            Pick a page from your own website (rather than an Amazon listing for example.)
                        </ListItemText>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{minWidth:0,mt:'4px',mr:'16px'}}>
                            ◾
                        </ListItemIcon>
                        <ListItemText>
                            If you have your own online store, a shop listing for the product is ideal.
                        </ListItemText>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{minWidth:0,mt:'4px',mr:'16px'}}>
                            ◾
                        </ListItemIcon>
                        <ListItemText>
                            If you can't find a page to meet these criteria, you can still give it a go, you might just need to fill out some information manually.
                        </ListItemText>
                    </ListItem>
                </List>

            </DialogContent>
            <DialogActions >
                <Button variant="contained" sx={{color:'white'}} onClick={handleDlgClose}>Got it</Button>
            </DialogActions>
        </ResponsiveDialog>
        </>
    )
}

const ProductDetails = ({formData,continueFlow}) => {

    const [loading,setLoading] = useState(true);
    const [dlgOpen,setDlgOpen] = useState(false);
    const [isLongLoading,setIsLongLoading] = useState(false);

    const [continueDisabled,setContinueDisabled] = useState(true);

    const mainService = useContext(MainContext);

    const listingId = formData.listingId;
    const title = formData?.productData?.title ?? '';
    const strapline = formData?.productData?.strapline ?? '';
    const provider =  formData?.productData?.providerName ?? '';
    const description = formData?.productData?.description ?? '';

    const contentgenerated = formData?.productMetadata?.contentgenerated ?? false;
    const errorGeneratingContent = formData?.productMetadata?.errorGeneratingContent ?? false;

    const handleContinue = () => {
        //write title so it updated metadata
        mainService.productListingService.writeListingTitle(listingId, title);
        //load website img
        formData.getWebsiteImages();
        continueFlow();
    }

    const handleDlgClose = () => {
        mainService.productListingService.writeListingGenerationError(listingId,false);
        setDlgOpen(false);
    }

    useEffect(()=>{
        
        const longLoadingTimer = setTimeout(()=>{
            if(loading)
                setIsLongLoading(true);
        },20000);

        if(contentgenerated ) {
            setLoading(false);
            setIsLongLoading(false);
        } else if (errorGeneratingContent) {
            setLoading(false);
            setIsLongLoading(false);
            setDlgOpen(true);
        }

        // loading || !(title && strapline && provider && description)
        const disableContinue = !(title && strapline && provider && description);
        setContinueDisabled(disableContinue)

        return (()=>{
            if(longLoadingTimer)
                clearTimeout(longLoadingTimer);
        })

    },[formData])

    return(
        <>
        <Box sx={{ height:'100%', flexGrow:1, display:'flex', alignItems:'stretch', flexDirection:'column',maxWidth:'600px'}}>
            {loading ? (
            <Box sx={{width:'100%',height:'100%',flexGrow:1,display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center', gap:'16px'}}>
                <ProductLoading playing={loading} isLong={isLongLoading}/>
            </Box>) : (
            <> 
            <Box sx={{height:'10%'}}></Box>
            <Typography variant='h3' align='left' gutterBottom sx={{mx:'16px'}}>About your product</Typography>
            <Typography sx={{ mx:'16px'}}>Double check and ensure this information looks right, feel free to edit the copy!</Typography>
            <Box sx={{my:'16px', display:'flex', flexDirection:'column', alignItems:'stretch'}}>

                <TextFormInput 
                    title={'Product name'}
                    value={title}
                    onChange={(v)=>mainService.productListingService.writeListingTitle(listingId, v)}
                    sx={{mb:'8px'}}
                    multiline
                />
                <TextFormInput 
                    title={'Provider name'}
                    value={provider}
                    onChange={(v)=>mainService.productListingService.writeListingProviderName(listingId, v)}
                    sx={{mb:'8px'}}
                    multiline
                />
                <TextFormInput 
                    title={'Short description'}
                    value={strapline}
                    onChange={(v)=>mainService.productListingService.writeListingTextvalue(listingId, 'strapline', v)}
                    sx={{mb:'8px'}}
                    multiline
                />
                <TextFormInput 
                    smallPara 
                    title='Long description'
                    value={description}
                    onChange={(v)=>mainService.productListingService.writeListingTextvalue(listingId, 'description', v)}
                />
                
                
            </Box>
            </>)}
            <Box sx={{flexGrow:1}}/>
            {!loading && <StyledButton disabled={continueDisabled} text='Continue' sx={{mb:'64px', width:"175px", alignSelf:'center'}} onClick = {handleContinue}/>}
        </Box>
        <ResponsiveDialog open={dlgOpen}  onClose={handleDlgClose}>
            <DialogTitle>Error occurred getting content</DialogTitle>
            <DialogContent>I'm sorry, an error has occurred while trying to access this product's content. In the meantime you can complete this form manually, or contact our support team for assistance.</DialogContent>
            <DialogActions><Button variant='contained' onClick={handleDlgClose}>Understood</Button></DialogActions>
        </ResponsiveDialog>
        </>
    )
}

const PricingDetails = ({formData,setDisableContinueButton}) => {
    const mainService = useContext(MainContext);
    const [allowContinue,setAllowContinue] = useState(false);
    const [currencyAdorn,setCurrencyAdorn] = useState('$');

    const listingId = formData.listingId;
    const unitType = formData?.productData?.basePricing?.unitType ?? 'unit'
    const amount = formData?.productData?.basePricing?.amount ?? '';
    const amountIsNumber = !isNaN(parseInt(amount)) && parseInt(amount) > 0;
    const studentPerUnit = formData?.productData?.basePricing?.quantPerStudent ?? '';
    const studentsPerUnitIsNumber = !isNaN(parseInt(studentPerUnit)) && parseInt(studentPerUnit) > 0;
    const websiteurl = formData?.productData?.websiteurl ?? '';
    const currency = formData?.productData?.basePricing?.currency ?? '$';

    useEffect(()=>{
        const disableContinue = !amount || !allowContinue || !amountIsNumber;
        if(setDisableContinueButton)
            setDisableContinueButton(disableContinue)
        setCurrencyAdorn(getCurrencyAdornment(currency));       
        return (()=>{

        })

    },[formData,allowContinue])

    const handleStudentsPerUnitChange = (e) => {
        mainService.productListingService.writeListingBasePriceQuantPerStudent(listingId,e.target.value)
    }


    return(
        <Box sx={{ height:'100%', flexGrow:1, display:'flex', alignItems:'stretch', flexDirection:'column',maxWidth:'600px'}}>
            <Box sx={{height:'10%'}}></Box>
            <Typography variant='h3' align='left' gutterBottom sx={{mx:'16px'}}>Product pricing information</Typography>
            <Typography sx={{ mx:'16px'}}>We need this information to calculate teacher's project budget, double check the autofilled values and correct them if they are incorrect. <Link href={websiteurl} target='_blank'>Click here</Link> to revisit your product page.</Typography>
                <Box sx={{my:'16px', display:'flex', flexDirection:'column', alignItems:'stretch'}}>
                <TextFormInput 
                    title={'Product unit price'}
                    value={amount}
                    onChange={(v)=>mainService.productListingService.writeListingBasePriceAmount(listingId, v)}
                    sx={{mb:'32px'}}
                    startAdornment={currencyAdorn}
                    error = {!amountIsNumber}
                    helperText={!amountIsNumber ? `Product unit price couldn't be sourced correctly, please enter the details manually` : ``}
                />

                <Typography variant="body" gutterBottom sx={{mt:'16px', mx:'16px'}}>Number of students per product unit</Typography>
                <Typography variant="body2" sx={{mx:'16px'}}>This will be used to auto-calculate teacher budgets</Typography>

                <Box sx={{mt:'16px', mb:'32px', mx:'16px', maxWidth:'300px',display:'flex',justifyContent:'flex-start', gap:'8px',alignItems:'center'}}>
                <Typography noWrap sx={{width:'140px',fontSize:'14px',color:Theme.palette.grey[500],overflow:'hidden',textOverflow:'ellipsis'}}>
                    1 {unitType} for every
                </Typography>
                <TextField
                    variant="standard"  
                    sx={{width:'45px',m:0}}                  
                    value={studentPerUnit && studentsPerUnitIsNumber ? studentPerUnit : ''}
                    onChange={handleStudentsPerUnitChange}
                />
                <Typography noWrap sx={{width:'85px',p:'4px',fontSize:'14px',color:Theme.palette.grey[500],overflow:'hidden',textOverflow:'ellipsis'}}>
                    student(s)
                </Typography>
                </Box>
    
                <Typography sx={{ mx:'16px'}}>I confirm that the price information is correct. </Typography>
                <Checkbox 
                    checked={allowContinue}
                    value={allowContinue}
                    onChange={(e)=>setAllowContinue(e.target.checked)}
                    sx={{alignSelf:'flex-start',ml:'8px'}}
                />
                                
            </Box>           
        </Box>
    )
}

const LogoInput = ({formData}) => {
    const mainService = useContext(MainContext);

    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [imgFile, setImgFile] = useState(null);
    const [imgLoading, setImgLoading] = useState(false);
    const [webImgsDlgOpen,setWebImgsDlgOpen] = useState(false)

    
    const listingId = formData.listingId;
    const websiteurls = formData?.imgurls ?? null;
    const logoURL = formData?.productData?.logoImage?.url ?? null;

    const handleUploadDialogClose = () => {
        setUploadDialogOpen(false);
    }

    const handleWebImgDialogClose = () => {
        setWebImgsDlgOpen(false)
    }

    const handleUploadImage = async () => {
        setUploadDialogOpen(false);
        setImgLoading(true);
        if(imgFile){
            await mainService.productListingService.uploadProductListingImage(listingId,imgFile.name,imgFile,'logoImage', false);       
        }
        setImgLoading(false);
    }

    const handleSetLogoFromUserProfile = async (userObject) => {
        setImgLoading(true);
        try{
            const logoFile = await mainService.profileService.getLogoBytesAndMetadata(userObject);
            console.log('file data',logoFile.metadata)
            await mainService.productListingService.uploadProductListingImage(listingId,logoFile.metadata.name,logoFile.file,'logoImage', false);
            setImgLoading(false);
        }
        catch(error){
            console.log('couldnt set logo ');
            setImgLoading(false);
        }
    }

    const handleUploadFromUrl = async (url) => {
        setImgLoading(true);
        let filename = '';
        try{
            const u = new URL(url);

            filename = u.pathname.substring(u.pathname.lastIndexOf('/')+1);
            console.log(url);
            const blob = await imgSrcToBlob(url,'image/png', 'anonymous', 1.0);
            
            await mainService.productListingService.uploadProductListingImage(listingId,filename,blob,'logoImage',false);
            setImgLoading(false);
        }
        catch(error){
            if(error?.target?.naturalWidth === 0 && error?.target?.naturalHeight===0){
                console.log('getting img with server');
                try{
                    const blob = await mainService.productListingService.getImageData(url);
                    await mainService.productListingService.uploadProductListingImage(listingId, filename, blob,'logoImage',false);

                    setImgLoading(false);
                }
                catch(error){
                    formData.onAlert('An error occurred uploading image: '+ error.message,'error');
                    setImgLoading(false);
                }
            }

            else{
                formData.onAlert('An error occurred uploading image: '+ error.message,'error');
                setImgLoading(false);
            }
        }
    }

    useEffect(()=>{
        console.log('logo input mounted');
        if(!logoURL && formData?.userObject?.logoKey){
           handleSetLogoFromUserProfile(formData.userObject);
        }
    },[formData]);


    return(
        <>
        <Box sx={{ height:'100%', flexGrow:1, display:'flex', alignItems:'stretch', flexDirection:'column',maxWidth:'600px'}}>
            <Box sx={{height:'10%'}}></Box>
            <Typography gutterBottom align='left' variant="h2">Upload a logo for your product</Typography>
            <Typography gutterBottom align='left'>This is the logo that educators will see on your listing page and their dashboard.</Typography>
            <Box sx={{my:'16px', display:'flex', flexDirection:'column', flexGrow:1}}>   
            <Typography sx={{mt:'32px',mb:'16px'}} variant='body1' align='left'>Add your company/product logo, for best results it should be 128 x 128px</Typography>
            <Box sx={{display:'flex',flexDirection:'column',gap:'8px',alignItems:'flex-start'}}>
                <Box sx={{display:'flex', alignItems:'flex-end',gap:'8px'}}>
                <Box sx={{p:'4px',width:'64px',height:'64px',borderRadius:'8px',position:'relative',border:`1px solid ${Theme.palette.grey[400]}`}}>
                    {imgLoading ? (
                    <Box sx={{position:'relative',width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <CircularProgress size={28} />
                    </Box>
                    ) : (
                    <Box sx={{position:'relative',width:'100%',height:'100%'}}>
                        <img style={{position:'absolute',width:'100%',height:'100%', objectFit:'contain'}} src={logoURL} referrerPolicy='no-referrer' />
                    </Box>
                    )}
                </Box>
                {logoURL && !imgLoading ? (
                    <>
                    <DoneIcon sx={{color:Theme.palette.success.main}}/>
                    <Typography variant='body2'>Logo uploaded successfully</Typography>
                    </>
                ) : (
                    <>
                    <PriorityHighIcon sx={{color:Theme.palette.error.main}}/>
                    <Typography variant='body2'>Please add a logo</Typography>
                    </>
                )}
                </Box>
                <Box sx={{display:'flex', gap:'16px',width:'100%'}}>
                    <StyledButton 
                        sx={{mt:'8px',mb:'16px'}}  
                        text='Upload image' 
                        icon={()=><CloudUploadIcon sx={{ml:'8px', color:'#ffffff'}}/>} 
                        onClick={()=>setUploadDialogOpen(true)}
                    />
                    
                    <StyledButton 
                        sx={{mt:'8px',mb:'16px',bgcolor:Theme.palette.secondary.main,'&:hover':{bgcolor:Theme.palette.secondary.dark}}}  
                        text='Pick an image' 
                        icon={()=><AddAPhotoIcon sx={{ml:'8px', color:'#ffffff'}}/>} 
                        onClick={()=>setWebImgsDlgOpen(true)}
                    />

                </Box>
            </Box>
            </Box>
        </Box>
        <FileUploadDialog onClose={handleUploadDialogClose} open={uploadDialogOpen} onImageFiles={(imgs)=>setImgFile(imgs[0])} onUploadImages={handleUploadImage} imgLoading={imgLoading} limit={1}/>
        <WebImagesDialog onClose={handleWebImgDialogClose} open={webImgsDlgOpen} urls ={websiteurls} onUpload={handleUploadFromUrl} />
        </>
    )

}

const FeatureImageInput = ({formData,continueFlow}) => {
    const mainService = useContext(MainContext);

    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [webImgsDlgOpen,setWebImgsDlgOpen] = useState(false);
    const [imgFile, setImgFile] = useState(null);
    const [imgLoading, setImgLoading] = useState(false);
    
    const listingId = formData.listingId;

    const featureImgURL = formData?.productData?.featureImage?.url ?? null;
    const websiteurls = formData?.imgurls ?? null;
    const featureImgFileName = formData?.productData?.featureImage?.fileName ?? null;

    const handleUploadDialogClose = () => {
        setUploadDialogOpen(false);
    }

    const handleWebImgDialogClose = () => {
        setWebImgsDlgOpen(false)
    }

    const handleUploadImage = async () => {
        setUploadDialogOpen(false);
        setImgLoading(true);
        if(imgFile){
            await mainService.productListingService.uploadProductListingImage(listingId,imgFile.name,imgFile,'featureImage', false);       
        }
        setImgLoading(false);
    }

    const handleUploadFromUrl = async (url) => {
        setImgLoading(true);
        let filename = '';
        try{
            const u = new URL(url);
            filename = u.pathname.substring(u.pathname.lastIndexOf('/')+1);
            /*const res = await fetch(url)
            const blob = await res.blob();*/
            const blob = await imgSrcToBlob(url,'image/jpeg', 'anonymous', 1.0);
            await mainService.productListingService.uploadProductListingImage(listingId,filename,blob,'featureImage',false);
            setImgLoading(false);
        }
        catch(error){
            if(error?.target?.naturalWidth === 0 && error?.target?.naturalHeight===0){
                console.log('getting img with server');
                try{
                    const blob = await mainService.productListingService.getImageData(url);
                    await mainService.productListingService.uploadProductListingImage(listingId, filename, blob,'featureImage',false);

                    setImgLoading(false);
                }
                catch(error){
                    formData.onAlert('An error occurred uploading image: '+ error.message,'error');
                    setImgLoading(false);
                }
            }

            else{
                formData.onAlert('An error occurred uploading image: '+ error.message,'error');
                setImgLoading(false);
            }
        }
    }

    //if a feature image has auto populated then save
    const handleUploadImgToStorage = async (url) => {
        const res = await fetch(url)
        const blob = await res.blob();
        await mainService.productListingService.uploadProductListingImage(listingId,featureImgFileName,blob,'featureImage',false);
    }

    const handleContinue = () => {
        if(featureImgURL && !formData?.productData?.featureImage?.key){
            console.log('uploading img to storage');
            handleUploadImgToStorage(featureImgURL);
        }
        continueFlow();
    }

    return(
        <>
        <Box sx={{ height:'100%', flexGrow:1, display:'flex', alignItems:'stretch', flexDirection:'column',maxWidth:'600px'}}>
            <Box sx={{height:'10%'}}></Box>
            <Typography gutterBottom align='left' variant="h2">Upload a banner image</Typography>
            <Typography gutterBottom align='left'>This is the image that educators will see at the top of your listing page and their dashboard.</Typography>
            <Box sx={{my:'16px', display:'flex', flexDirection:'column', flexGrow:1}}>
                <Typography variant="body" sx={{mt:'16px'}}>Banner Image</Typography>
                <Typography variant="body2">This image will appear accross the top of your product page</Typography>
                <Typography variant="body2" sx={{mb:'16px'}}>Your image should be 428 x 260px</Typography>

                <Box sx={{display:'flex', gap:'16px',width:'100%'}}>
                    <StyledButton 
                        sx={{mt:'8px',mb:'16px'}}  
                        text='Upload image' 
                        icon={()=><CloudUploadIcon sx={{ml:'8px', color:'#ffffff'}}/>} 
                        onClick={()=>setUploadDialogOpen(true)}
                    />              
                    <StyledButton 
                        sx={{mt:'8px',mb:'16px',bgcolor:Theme.palette.secondary.main,'&:hover':{bgcolor:Theme.palette.secondary.dark}}}  
                        text='Pick an image' 
                        icon={()=><AddAPhotoIcon sx={{ml:'8px', color:'#ffffff'}}/>} 
                        onClick={()=>setWebImgsDlgOpen(true)}
                    />
                </Box>

                <Box sx={{position:'relative', display:"flex", flexDirection:"row", my:'16px', mb:'48px', overflowX:'auto'}}>
                    <>
                    {imgLoading && !featureImgURL && (
                        <Box sx={{ display:"flex", flexDirection:"column", }}>
                            <Box sx={{ display:"flex", flexDirection:"row", alignItems:'center', mb:'4px' }}>
                                <Typography variant="body2">image loading...</Typography>
                            </Box>
                            <Box sx={{width:'428px', height:'260px', display:'flex', alignItems:'center', justifyContent:'center', flexGrow:1, borderRadius:'4px', borderWidth:'2px', borderStyle:'dashed', borderColor:'#808080'}}>
                                <CircularProgress />                 
                            </Box>
                        </Box>
                    )
                    }
                    {featureImgURL && 
                        <Box sx={{ display:"flex", flexDirection:"column", }}>
                            <Box sx={{ display:"flex", flexDirection:"row", alignItems:'center', mb:'4px' }}>
                                <Typography variant="body2">{decodeURIComponent(featureImgFileName ? featureImgFileName : '')}</Typography>
                                <IconButton size="small" onClick={()=>{}} ><DeleteIcon/></IconButton>
                            </Box>
                            {imgLoading ? (
                            <Box sx={{width:'428px', height:'260px', display:'flex', alignItems:'center', justifyContent:'center', flexGrow:1, borderRadius:'4px', borderWidth:'2px', borderStyle:'dashed', borderColor:'#808080'}}>
                                <CircularProgress />                 
                            </Box>
                            ) : (
                                <Box sx={{width:'428px', height:'260px', position:'relative', flexGrow:1, borderRadius:'4px', borderWidth:'2px', borderStyle:'dashed', borderColor:'#808080'}}>
                                <img style={{position:'absolute', width:'100%', height:'100%', objectFit:'cover'}} src={featureImgURL} ></img>
                            </Box>
                            )}
                        </Box>
                    }
                    </>
                </Box>
            </Box>
            <Box sx={{flexGrow:1}}/>
            <StyledButton text='Continue' sx={{mb:'64px', width:"175px", alignSelf:'center'}} onClick = {handleContinue}/>
        </Box>
        <FileUploadDialog onClose={handleUploadDialogClose} open={uploadDialogOpen} onImageFiles={(imgs)=>setImgFile(imgs[0])} onUploadImages={handleUploadImage} imgLoading={imgLoading} limit={1}/>
        <WebImagesDialog onClose={handleWebImgDialogClose} open={webImgsDlgOpen} urls ={websiteurls} onUpload={handleUploadFromUrl} />
        </>
    )
}

const ProductImagesInput = ({formData,continueFlow}) => {
    const mainService = useContext(MainContext);

    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [webImgsDlgOpen,setWebImgsDlgOpen] = useState(false);
    const [imgFiles, setImgFiles] = useState(null);
    const [imgsLoading, setImgsLoading] = useState(false);
    
    const listingId = formData.listingId;

    const websiteurls = formData?.imgurls ?? null;
    const productImages = formData?.productData?.productImages ?? null;
    const featureImgFileName = formData?.productData?.featureImage?.fileName ?? null;

    const handleUploadDialogClose = () => {
        setUploadDialogOpen(false);
    }

    const handleWebImgDialogClose = () => {
        setWebImgsDlgOpen(false)
    }

    const handleUploadImages = async () => {
        setUploadDialogOpen(false);
        setImgsLoading(true);
        if(imgFiles){
            try{               
                let promList = [];
                for(let img of imgFiles){
                    promList.push(mainService.productListingService.uploadProductListingImage(listingId, img.name, img, 'productImages', true)); 
                }
                await Promise.all(promList);           
            }           
            catch(error){
                console.error(error);
            }
        }
        setImgsLoading(false);
    }

    const handleUploadFromUrl = async (urls) => {
        setImgsLoading(true);
        try{
            let promList = [];
            for(const url of urls){
                const u = new URL(url);
                const filename = u.pathname.substring(u.pathname.lastIndexOf('/')+1);
                promList.push(
                    imgSrcToBlob(url,'image/jpeg', 'anonymous', 1.0)
                    .then((blob)=>{
                        mainService.productListingService.uploadProductListingImage(listingId,filename,blob,'productImages',true);
                    })
                )
            }
            await Promise.all(promList);
            setImgsLoading(false);
        }
        catch(error){
            if(error?.target?.naturalWidth === 0 && error?.target?.naturalHeight===0){

                try{
                    let promList = [];
                    for(const url of urls){
                        const u = new URL(url);
                        const filename = u.pathname.substring(u.pathname.lastIndexOf('/')+1);
                        promList.push(
                            mainService.productListingService.getImageData(url)
                            .then((blob)=>{
                                mainService.productListingService.uploadProductListingImage(listingId,filename,blob,'productImages',true);
                            })
                        )
                    }
                    //const blob = await mainService.productListingService.getImageData(url);
                    //await mainService.productListingService.uploadProductListingImage(listingId, filename, blob,'featureImage',false);

                    setImgsLoading(false);
                }
                catch(error){
                    formData.onAlert('An error occurred uploading image: '+ error.message,'error');
                    setImgsLoading(false);
                }
            }

            else{
                formData.onAlert('An error occurred uploading image: '+ error.message,'error');
                setImgsLoading(false);
            }
        }
    }

    const handleDeleteImage = async (path) => {
        setImgsLoading(true);
        if(productImages){
            try{
                console.log('Delete image'); 
                await mainService.productListingService.deleteProductListingImage(listingId, path);
            }           
            catch(error){
                console.error(error);
            }
        }
        setImgsLoading(false);
    }

    //if user has picked an images from their website upload a copy to storage
    const handleUploadImgToStorage = async (url) => {
        const res = await fetch(url)
        const blob = await res.blob();
        await mainService.productListingService.uploadProductListingImage(listingId,featureImgFileName,blob,'productImages',false);
    }

    const handleContinue = () => {
        /*if(featureImgURL && !formData?.productData?.featureImage?.key){
            console.log('uploading img to storage');
            handleUploadImgToStorage(featureImgURL);
        }*/
        continueFlow();
    }

    return(
        <>
        <Box sx={{ height:'100%', flexGrow:1, display:'flex', alignItems:'stretch', flexDirection:'column',maxWidth:'600px'}}>
            <Box sx={{height:'10%'}}></Box>
            <Typography gutterBottom align='left' variant="h2">Upload some product images</Typography>
            <Typography gutterBottom align='left'>These appear in a carousel on your product listing</Typography>
            <Box sx={{my:'16px', display:'flex', flexDirection:'column', flexGrow:1}}>
                <Typography variant="body" sx={{mt:'16px'}}>Product Images</Typography>
                <Typography variant="body2" >These images will be part of your product page. </Typography>
                <Typography variant="body2">Your images should be 428 x 260px</Typography>

                <Box sx={{display:'flex', gap:'16px',width:'100%'}}>
                    <StyledButton 
                        sx={{mt:'8px',mb:'16px'}}  
                        text='Upload image' 
                        icon={()=><CloudUploadIcon sx={{ml:'8px', color:'#ffffff'}}/>} 
                        onClick={()=>setUploadDialogOpen(true)}
                    />              
                    <StyledButton 
                        sx={{mt:'8px',mb:'16px',bgcolor:Theme.palette.secondary.main,'&:hover':{bgcolor:Theme.palette.secondary.dark}}}  
                        text='Pick an image' 
                        icon={()=><AddAPhotoIcon sx={{ml:'8px', color:'#ffffff'}}/>} 
                        onClick={()=>setWebImgsDlgOpen(true)}
                    />
                </Box>

                <Box sx={{position:'relative', display:"flex", flexDirection:"row", my:'16px', mb:'48px', overflowX:'auto'}}>
                    {!productImages && imgsLoading && (
                        <Box sx={{ display:"flex", flexDirection:"column", }}>
                            <Box sx={{ display:"flex", flexDirection:"row", alignItems:'center', mb:'4px' }}>
                                <Typography variant="body2">images loading...</Typography>
                            </Box>
                            <Box sx={{width:'428px', height:'260px', display:'flex', alignItems:'center', justifyContent:'center', flexGrow:1, borderRadius:'4px', borderWidth:'2px', borderStyle:'dashed', borderColor:'#808080'}}>
                                <CircularProgress />                 
                            </Box>
                        </Box>
                    )}
                    {productImages && Object.entries(productImages).map(([imgkey, productImage], inx)=>(
                        <Box key={imgkey} sx={{ display:"flex", flexDirection:"column", mr:'8px', mb:'4px'}}>
                            <Box sx={{ display:"flex", flexDirection:"row", alignItems:'center', mb:'4px' }}>
                                <Typography variant="body2">{decodeURIComponent(productImage.fileName)}</Typography>
                                <IconButton size="small" onClick={()=>{handleDeleteImage("productImages/"+imgkey)}} ><DeleteIcon/></IconButton>
                            </Box>
                            <Box sx={{width:'428px', height:'260px', position:'relative', flexGrow:1, borderRadius:'4px', borderWidth:'2px', borderStyle:'dashed', borderColor:'#808080'}}>
                                <img style={{position:'absolute', width:'100%', height:'100%', objectFit:'cover'}} src={productImage.url}></img>
                            </Box>
                        </Box>
                        ))
                    }
                </Box>
            </Box>
            <Box sx={{flexGrow:1}}/>
            <StyledButton text='Continue' sx={{mb:'64px', width:"175px", alignSelf:'center'}} onClick = {handleContinue}/>
        </Box>
        <FileUploadDialog onClose={handleUploadDialogClose} open={uploadDialogOpen} onImageFiles={(imgs)=>setImgFiles(imgs)} onUploadImages={handleUploadImages} imgLoading={imgsLoading} limit={8}/>
        <WebImagesDialog onClose={handleWebImgDialogClose} open={webImgsDlgOpen} urls ={websiteurls} onUpload={handleUploadFromUrl} multiSelect />
        </>
    )
}


const Finish = ({formData})=>{

    const mainService = useContext(MainContext);

    const websiteurl = formData?.productData?.websiteurl ?? '';
    const title = formData?.productData?.title ?? '';
    const strapline = formData?.productData?.strapline ?? '';
    const provider =  formData?.productData?.providerName ?? '';
    const description = formData?.productData?.description ?? '';
    const amount = formData?.productData?.basePricing?.amount ?? '';
    const studentsPerUnit = formData?.productData?.basePricing?.quantPerStudent ?? '';
    const studentsPerUnitIsNumber = !isNaN(parseInt(studentsPerUnit)) && parseInt(studentsPerUnit) > 0;
    const ageRange = getAgeRangeString(formData?.productData?.minAge,formData?.productData?.maxAge);
    const currency = getCurrencyAdornment(formData?.productData?.basePricing?.currency);

    return(
        <Box sx={{ height:'100%', flexGrow:1, display:'flex', alignItems:'stretch', flexDirection:'column',maxWidth:'600px'}}>
            <Box sx={{height:'10%'}}></Box>
            <Typography gutterBottom align='left' variant="h2">Great, we've got your product details!</Typography>
            <Typography gutterBottom align='left'>If you're happy with this click continue to review your listing and add any missing information. Once your're happy you can publish the listing and you'll be ready to start receiving applications! </Typography>
            <Box sx={{my:'16px', display:'flex', flexDirection:'column', flexGrow:1}}>   
            <Typography variant='h3' sx={{mb:'16px'}}>Your Product Summary:</Typography>  
            <Typography variant='h4'>{title}</Typography>      
            <Typography variant='body2' gutterBottom>by {provider}</Typography>  
            <Typography sx={{mb:'8px'}}>{strapline}</Typography> 
            <Typography sx={{mb:'16px'}}>{description}</Typography> 
            {ageRange && <Typography ><b>Age range: </b>{ageRange} years</Typography> }
            <Typography><b>Unit price: </b>{currency}{amount}</Typography> 
            {studentsPerUnitIsNumber && <Typography><b>Units per student: </b> {studentsPerUnit}</Typography> }
            <Typography><b>Website: </b> <Link href={websiteurl} target='_blank'>{websiteurl}</Link></Typography>
            </Box>
        </Box>
    )
}

const ProductListingCreationForm = ({userObject, listingId, productData, onFinish, onListingMenuClick,onAlert,onListingDeleted,onLoading}) => {
    
    const mainService = useContext(MainContext);
    const [ynDlgOpen,setYnDlgOpen] = useState(false);
    const [productMetadata,setProductMetadata] = useState(null);
    const [imgurls,setimgurls] = useState(null);

    const handleCloseYnDlg = () => {
        setYnDlgOpen(false);
    }

    const handleOpenYnDlg = () => {
        setYnDlgOpen(true);
    }

    const listenMetadataChanged = (data) => {
        setProductMetadata(data)
    }

    const getWebsiteImages = async () => {
        const url = productData.websiteurl;
        if(url){
            const urls = await mainService.productListingService.getWebsiteImages(url);
            if(urls){
                console.log(urls)
                setimgurls(urls);
            }      
        }
    }

    const handleListingMenuClick = () => {
        onListingMenuClick();
    }

    const handleDeleteListing = async () =>{
        onLoading(true);
        setYnDlgOpen(false);
        //delete
        try{
            await mainService.productListingService.deleteProductListing(listingId);
            onListingDeleted(); 
            onAlert("deleted successfully","success");
            onLoading(false);
        }
        catch(error){
            onAlert("An error occurred: " + error.message,"error");
            console.error(error);
            onLoading(false);
        }
    }   

    useEffect(()=>{
        mainService.productListingService.addMetadataListener(listingId, listenMetadataChanged);

        return (()=>{
            mainService.productListingService.removeMetadataListener(listingId, listenMetadataChanged);

        })

    },[listingId])

    //RENDER 
    const formData = {
        
        startNode: 'startNode',

        userObject,

        productData,

        productMetadata,

        listingId,

        imgurls,

        getWebsiteImages,

        onAlert,

        nodes:{
            startNode:{
                component:Start,
                hideContinueButton:true,
                next:()=>'productDetailsNode',
            },
            productDetailsNode:{
                component:ProductDetails,
                hideContinueButton:true,
                next:()=>'pricingDetailsNode'
            },
            pricingDetailsNode:{
                component:PricingDetails,
                next:()=>'logoInputNode'
            },
            logoInputNode:{
                component:LogoInput,
                next:()=>'featureImgInputNode'
            },
            featureImgInputNode:{
                component:FeatureImageInput,
                hideContinueButton:true,
                next:()=>'prodcutImagesInputNode'
            },
            prodcutImagesInputNode:{
                component:ProductImagesInput,
                hideContinueButton:true,
                next:()=>'finishNode'
            },
            finishNode:{
                component:Finish,
                end:true,
            },
        }
    }

    return (
        <>
        <Box sx={{overflowX:"hidden", width:'100%', height:'100%', display:'flex', flexDirection:'column',}}>
            <Box sx={{height:'40px', mt:'20px', display:'flex', justifyContent:'center', px:'18px', /*borderBottom:`1px solid ${Theme.palette.grey[300]}`*/}}>
                <Box sx={{flexGrow:1, maxWidth:'1200px', display:'flex', alignItems:'center',}}>
                    <IconButton sx={{mr:'16px'}} onClick={handleListingMenuClick}>
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant='h4' sx={{ml:'26px'}}>Generate your product listing</Typography> 
                    <Box sx={{flexGrow:1}}/>
                    <IconButton sx={{mr:'16px'}} onClick={handleOpenYnDlg}>
                        <MoreVertIcon/>
                    </IconButton>
                </Box>
            </Box>
            <Box sx={{overflowX:"hidden", flexGrow:1, display:'flex', justifyContent:'center', }}>
                <Paper sx={{flexGrow:1, overflowX:"hidden", display:'flex', flexDirection:'column', my:'16px',mx:'32px', maxWidth:'1200px'}}>
                    <FormFlow onFinish={onFinish} formData={formData}/>
                </Paper>
            </Box>
        </Box>
        <YesNoDialog text={'Are you sure you want to delete this draft?'} onYes={handleDeleteListing} onNo={handleCloseYnDlg} open={ynDlgOpen}/>
        </>
    )
}

export default ProductListingCreationForm; 