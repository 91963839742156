import { createTheme } from '@mui/material';

const Theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
          light: "#bbe5d5",
          main: '#54BD95',
          dark: "#1e4f3c",
      },
     
    },

    typography: {
      //fontFamily: "Source Sans Pro",
      //fontFamily: "Montserrat",
      fontFamily: "Manrope",

      button:{
        color:'#121f43',
        fontWeight: 600,
        fontSize:'12px',
      },

      body1: {
        color:'#121f43',
        fontWeight: 500,
        fontSize:'14px',
      },

      body2: {
        color:'#5f748d',
        fontWeight: 500,
        fontSize:'13px',
      },

      h6: {
        fontSize:'13px',
        color:'#121f43',
        fontWeight: 600,
      },

      h5: {
        fontSize:'14px',
        color:'#121f43',
        fontWeight: 600,
      },

      h4: {
        fontSize:'16px',
        color:'#121f43',
        fontWeight: 600,
      },

      h3: {
        fontSize:'18px',
        color:'#121f43',
        fontWeight: 600,
      },

      h2: {
        fontSize:'24px',
        color:'#121f43',
        fontWeight: 600,
      },

      h1: {
        fontSize:'32px',
        color:'#121f43',
        fontWeight: 400,
      },

      h0: {
        fontSize:'100px',
        color:'#121f43',
        fontWeight: 400,
      },


    },

    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            scrollbarColor: "#6b6b6b #2b2b2b",
            "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
              width:"8px",
              height:"8px"
            },
            "&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track":{
              backgroundColor:"transparent"
            },
            "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
              borderColor:"rgba(255,255,255,0.5)",
              borderStyle:"solid",
              borderWidth:"1px",
              borderRadius: "10px",
              backgroundColor: "rgba(187, 229, 213, 0.7)",
            }
          },
        },
      },
    }, 
});

export default Theme;