import {
    getAuth, } from 'firebase/auth';

import { 
    getDatabase, 
    ref, 
    set, 
    get, 
    push, 
    update, 
    onValue, 
    off } from 'firebase/database';

import { API_URL } from './Config';

import { sha256, sha224 } from 'js-sha256'; 


export default class AccountService {


    constructor(mainService){
        this.mainService = mainService; 
    }
    
    async getBillingId(userObj){
        console.log('get billing id');
        const db = getDatabase();
        const email = this.mainService.getEncodedEmail();
        const snap = await get(ref(db,`userBillingRelationship/${email}`));
        let billingId = '';
        if(snap.exists()){
            //get first bid from list
            billingId = Object.keys(snap.val())[0];
        }
        else {
            //user has no associated billing accounts, create one on free plan
            if(userObj.type == 'thirdparty')
                billingId = await this.setUpProductFreePlan(email);
        }
        this.setActiveBillingId(billingId);
        return billingId;
    }

    setActiveBillingId(billingId){
        const uid = this.mainService.getUid();
        set(ref(getDatabase(),`users/${uid}/activeBillingId`),billingId)
    }

    async setUpProductFreePlan(encodedEmail){
        console.log('setUpProductFree'); 
        try{
            const token = await this.mainService.getUserToken();
            const res = await fetch (API_URL+'/setproductfreeplan',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({encodedEmail,token}),
            });
            let data = await res.json(); 
            if(!data.ok){
                throw new Error(data.message);
            }
            return data.billingId;
        }
        catch(error){
            console.error(error.message);
            return null;
        }
    }


}