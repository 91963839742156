import React, {useState, useEffect, useContext, useSyncExternalStore } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";

import Theme from '../Theme';

import MainService from "../MainService"; 
import MainContext from "../MainContext"; 

import { ThemeProvider } from  '@mui/system';
import { Box, Typography, ButtonBase, IconButton, InputBase } from "@mui/material";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useQuery from "../useQuery";
import StyledButton from "./StyledButton";


/*
formData:
    nodes:
        $id:
            end=true/false
            component
            next
*/

const FlowForm = ({formData, disableContinue, onFinish}) => {

    const TRANSITION_TIME = 0.3; 

    const STAGE_OPEN = 0; 
    const STAGE_SLIDE_NEXT = 1;
    const STAGE_SLIDE_BACK = 2;
    const STAGE_OPEN_BACK = 3;

    //STATES 
    const [nextInx, setNextInx] = useState(0);  
    const [currentNodeId, setCurrentNodeId] = useState(formData.startNode); 
    const [prevNodeId, setPrevNodeId] = useState(""); 
    const [history, setHistory] = useState([]); 
    const [stage, setStage] = useState(STAGE_OPEN); 
    const [disableContinueButton, setDisableContinueButton] = useState(false); 

    const handleContinueClicked = ()=>{

        //transition forward
        let currentNode = currentNodeId ? formData.nodes[currentNodeId] : null;
        let nextNodeId = currentNode.next();
        
        let newHistory = [...history]; 
        newHistory.push(currentNodeId); 
        setHistory(newHistory); 

        setPrevNodeId(currentNodeId); 
        setCurrentNodeId(nextNodeId); 

        setNextInx(0);
        setStage(STAGE_SLIDE_NEXT); 
        setTimeout(()=>{
            setStage(STAGE_OPEN); 
        }, 0);
    }

    const handleBackClick = ()=>{
        //transition forward
        let currentNode = currentNodeId ? formData.nodes[currentNodeId] : null;
        
        let newHistory = [...history]; 
        let lastHistory = newHistory.pop(); 
        let newLastHistory = newHistory[newHistory.length-1]; 
        setHistory(newHistory); 

        setPrevNodeId(currentNodeId); 
        setCurrentNodeId(lastHistory); 

        setNextInx(0);
        setStage(STAGE_SLIDE_BACK); 
        setTimeout(()=>{
            setStage(STAGE_OPEN_BACK); 
        }, 0);
    }

    const handleFinishClicked = ()=>{
        onFinish(); 
    }

    const handleNextChanged = (inx)=>{
        setNextInx(inx);  
    }


    //Effects


    //RENDER 
    let currentNode = currentNodeId ? formData.nodes[currentNodeId] : null;
    let prevNode = prevNodeId ? formData.nodes[prevNodeId] : null;
    let NodeComponent = currentNode ? currentNode.component : null; 
    let PrevNodeComponent = prevNode ? prevNode.component : null; 

    return (
    <ThemeProvider theme={Theme}>
            <Box sx={{overflowX:"hidden", position:'absolute', width:'100%', height:'100%', display:'flex', flexDirection:'column', backgroundColor:'#FFFFFF', 
                transform: `translate(  ${(stage == STAGE_OPEN || stage == STAGE_OPEN_BACK) ? '0': (stage == STAGE_SLIDE_NEXT) ? '100%' : '-100%'}  )`,
                transition: (stage == STAGE_SLIDE_NEXT || stage == STAGE_SLIDE_BACK) ? 'default' : `transform ${TRANSITION_TIME}s ease-in`
            }}>
                
                <IconButton disabled={history.length == 0} sx={{visibility: history.length == 0 ?'hidden' : 'visible', position:'absolute', left:'10px', top:'10px', zIndex:2}} size='medium' onClick={handleBackClick}>
                    <ArrowBackIcon sx={{}} />
                </IconButton>

                <Box sx={{flexGrow:1, position:'relative', display:'flex', justifyContent:'center'}}>
                    {currentNode && 
                    <NodeComponent 
                        formData={formData} 
                        onNextChange={handleNextChanged} 
                        continueFlow={ () => currentNode.end ? handleFinishClicked() : handleContinueClicked() }
                        setDisableContinueButton={(d)=>setDisableContinueButton(d)}
                    />
                    }
                </Box>
                
                {currentNode && !currentNode.hideContinueButton &&
                <StyledButton disabled={disableContinueButton} text='Continue' sx={{mb:'64px', width:"175px", alignSelf:'center'}} onClick = { () => currentNode.end ? handleFinishClicked() : handleContinueClicked() }></StyledButton>
                }

            </Box>

            <Box sx={{overflowX:"hidden",position:'absolute', width:'100%', height:'100%', display:'flex', flexDirection:'column', backgroundColor:'#FFFFFF', 
                transform: `translate(  ${(stage == STAGE_SLIDE_NEXT || stage == STAGE_SLIDE_BACK) ? '0': (stage == STAGE_OPEN) ? '-100%' : '100%'}  )`,
                transition: (stage == STAGE_SLIDE_NEXT || stage == STAGE_SLIDE_BACK) ? 'default' : `transform ${TRANSITION_TIME}s ease-in`
            }}>
                <IconButton sx={{position:'absolute', left:'10px', top:'10px', zIndex:2, visibility: history.length == 1 ?'hidden':'visible'}} size='medium' >
                    <ArrowBackIcon sx={{}} />
                </IconButton>

                <Box sx={{flexGrow:1, position:'relative', display:'flex', justifyContent:'center'}}>
                    {prevNode && <PrevNodeComponent formData={formData}/>}
                </Box>
                
                <StyledButton text='Continue' disabled={disableContinue} sx={{mb:'64px', width:"175px", alignSelf:'center'}} ></StyledButton>                

            </Box>

    </ThemeProvider>
    )
}

export default FlowForm; 