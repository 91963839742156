import React, {useState, useEffect, useContext } from "react";

import { Box, Typography, TextField} from "@mui/material";

import StyledTextField from "../StyledTextField";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";


const DateFormPickerInput = ({title, value, onChange, descriptionText, sx})=>{
        
    const handleChange = (val)=>{
        let date = [val.month(),val.date(),val.year()]
        onChange(date); 
    }

    return(
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <React.Fragment>
        <Typography align='left' sx={{mt:'16px', mb:descriptionText?'0px':'16px', ml:'16px'}}>{title}</Typography>
        {descriptionText && <Typography variant='body2' sx={{mb:'16px', ml:'16px'}}>{descriptionText}</Typography>} 
        <Box  sx={{mb:'16px', mx:'16px', display:'flex', flexDirection:'column', alignItems:'stretch', ...sx }}>
        <DatePicker          
            value={value ? dayjs().month(value[0]).date(value[1]).year(value[2]) : null}
            onChange={handleChange}
            renderInput={(params) => { return(<StyledTextField {...params} />)}}
        />
        </Box>
    </React.Fragment>
    </LocalizationProvider>
    )
}

export default DateFormPickerInput;