import  {Fragment, useState, useEffect} from "react";
import { camelCase } from 'lodash';

import { 
    DialogTitle, 
    DialogContent, 
    DialogActions,
    Button,
    TextField,
    OutlinedInput,
    FormControl, 
    FormControlLabel,
    InputLabel,
    Checkbox,
    Autocomplete,
    Select,
    MenuItem} from '@mui/material';


import ResponsiveDialog from "../../components/ResponsiveDialog";
import { grantRequirementNames } from "../../Util";


export default function EditGrantRequirement({open,onClose,loading,reqsData,rId,onEditReq}) {

    //STATES
    const [useStandardOptions,setUseStandardOptions] = useState(false);

    const [nameOptions,setNameOptions] = useState([]);
    const [reqData,setReqData] = useState({});

    //handlers
    const handleEditReqType = (e) => {
        const val = e.target.value;
        if(val){
            setNameOptions(grantRequirementNames[val]);
            //edit data
            onEditReq(rId,val,"type");
        }  
    }

    const handleEditReqName = (val) => {
        if(val){
            const nameObj = nameOptions.find(element => element.label === val);
            let name = ""
            if(nameObj){
                name = nameObj.value
            }
            else{
                name= camelCase(val);
            }
            onEditReq(rId,name,"name");
        }
    }


    //Effects
	useEffect(() => {
		//on mount
		console.log('Edit grant mounted'); 
        const req = reqsData.find(element => element.id === rId);
        if(req){
            console.log("setting req data");
            setReqData(req.data);
        }

		//on unmount
		return ()=>{
			console.log('Edit grant unmounted');

		}

	}, [rId,reqsData]);

    //render
    const renderInputRequirements = () => {
        let inputRequirements = null;
        if(reqData['input'] && (reqData['input'] ==="textSmall" || reqData['input'] ==="textMedium" || reqData['input'] ==="textLarge")){
            //length
            inputRequirements = (
                <FormControl>
                    <InputLabel htmlFor="char-length">Length characters (approx.) </InputLabel>
                    <OutlinedInput
                        value={reqData['charLimit'] ? reqData['charLimit'] : ""}
                        disabled={loading}
                        onChange={(e)=>onEditReq(rId,e.target.value,"charLimit")}
                        size="small" 
                        id="char-length"
                        type="number"
                    />
                </FormControl >
            );
        }
        else if (reqData['input'] && (reqData['input'] === "singleSelect" || reqData['input'] ==="multiSelect")){
            //options
            inputRequirements = (
                <Fragment>
                    <FormControlLabel control={<Checkbox value={useStandardOptions} onChange={(e)=>setUseStandardOptions(e.target.checked)}/>} label="Standard list" />
                    {useStandardOptions ?
                    (<FormControl sx={{width:"100%",mt:"8px"}}>
                        <InputLabel htmlFor="options-set">Options set</InputLabel>
                        <Select value={reqData['optionsSet'] ? reqData['optionsSet'] : ""} disabled={loading} onChange={(e)=>{onEditReq(rId,e.target.value,"optionsSet")}} id="input-type" size="small">
                                <MenuItem value="states">States</MenuItem>
                                <MenuItem value="countries">Countries</MenuItem>
                                <MenuItem value="taxStatus">Tax Status</MenuItem>
                        </Select>
                    </FormControl>):
                    (<TextField
                        value={reqData['options'] ? reqData['options'] : ""}
                        id="new-requirement-options-input"
                        label="Add comma separated options"
                        onChange={(e)=>{onEditReq(rId,e.target.value,"options")}}
                    />)}
                </Fragment>
            );
        }
        else if (reqData['input'] && reqData['input'] === "fileUpload") {
            //file types
            inputRequirements = (
            <TextField
                value={reqData['fileTypes'] ? reqData['fileTypes'] : ""}
                id="new-requirement-filetypes-input"
                label="Add comma separated options for accepted filetypes"
                onChange={(e)=>onEditReq(rId,e.target.value,"fileTypes")}
            />);
        }

        return inputRequirements;
    }

  
    return (
        <ResponsiveDialog open={open} onClose={onClose}>
            <DialogTitle>Add new requirement</DialogTitle>
            <DialogContent sx={{display:"flex",gap:"8px",flexDirection:"column",minWidth:"600px"}}>

                <FormControl sx={{width:"100%",mt:"8px"}}>
                    <InputLabel htmlFor="requirement-type">Requirement type</InputLabel>
                    <Select value={reqData['type'] ? reqData['type'] : ""} disabled={loading} onChange={handleEditReqType} id="requirement-type" size="small">
                            <MenuItem value="organization">Organization</MenuItem>
                            <MenuItem value="contact">Contact</MenuItem>
                            <MenuItem value="project">Project</MenuItem>
                            <MenuItem value="funding">Funding</MenuItem>
                            <MenuItem value="application">Application content</MenuItem>
                            <MenuItem value="references">References</MenuItem>
                            <MenuItem value="admin">Admin</MenuItem>
                    </Select>
                </FormControl>
                <Autocomplete
                    freeSolo
                    id="requirement-name"
                    options={nameOptions}
                    inputValue={reqData['name'] ? reqData['name'] : ""}
                    value={reqData['name'] ? reqData['name'] : ""}
                    onInputChange={(e,val)=>{handleEditReqName(val)}}
                    sx={{ width: "100%"}}
                    renderInput={(params) => <TextField {...params} size='small' label="Requirement name" />}
                />
                <FormControlLabel control={<Checkbox checked={reqData['required'] ? reqData['required'] : false} onChange={(e)=>onEditReq(rId,e.target.checked,"required")}/>} label="Required" />
                <TextField
                    value={reqData['questionText'] ? reqData['questionText'] : ""}
                    id="new-requirement-question-text"
                    label="Question text"
                    multiline
                    maxRows={4}
                    minRows={2}
                    onChange={(e)=>onEditReq(rId,e.target.value,"questionText")}
                />
                <FormControl sx={{width:"100%",mt:"8px"}}>
                    <InputLabel htmlFor="input-type">Input type</InputLabel>
                    <Select value={reqData['input'] ? reqData['input'] : ""} disabled={loading} onChange={(e)=>onEditReq(rId,e.target.value,"input")} id="input-type" size="small">
                        <MenuItem value="textSmall">Text small</MenuItem>
                        <MenuItem value="textMedium">Text medium</MenuItem>
                        <MenuItem value="textLarge">Text large</MenuItem>
                        <MenuItem value="number">Number</MenuItem>
                        <MenuItem value="date">Date</MenuItem>
                        <MenuItem value="dateRange">Date range</MenuItem>
                        <MenuItem value="boolean">Check box</MenuItem>
                        <MenuItem value="singleSelect">Single select</MenuItem>
                        <MenuItem value="multiSelect">Multi select</MenuItem>
                        <MenuItem value="fileUpload">File upload</MenuItem>
                    </Select>
                </FormControl>
                {renderInputRequirements()}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>Close</Button>
            </DialogActions>
        </ResponsiveDialog>
    );
}