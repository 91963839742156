import React, {useState, useEffect, useContext } from "react";

import { Box, Typography, MenuItem, Select,  } from "@mui/material";

const SelectRangeFormInput = ({value, title, options, valueHigh, onHighChange, valueLow, onLowChange, sx})=>{
        

    
    return(
    <React.Fragment>
        <Typography align='left' sx={{my:'16px', mx:'16px', ml:'16px'}}>{title}</Typography>
        <Box sx={{mb:'16px',mx:'16px', ml:'16px', display:'flex', alignItems:'center', flexDirection:{xs:'column', sm:'row'} }}>
            <Select
                sx={{flexGrow:1}}
                value={valueLow}
                onChange={(e)=>onLowChange(e.target.value)}
            >
                {options.map((v)=>(
                    <MenuItem key={v.code} value={v.code}>{v.name}</MenuItem>
                ))}
            </Select>
            <Typography align='center' sx={{mx:'8px'}}>to</Typography>
            <Select
                sx={{flexGrow:1}}
                value={valueHigh}
                onChange={(e)=>onHighChange(e.target.value)}
            >
                {options.map((v)=>(
                    <MenuItem key={v.code} value={v.code}>{v.name}</MenuItem>
                ))}
            </Select>   
        </Box>
    </React.Fragment>
    )
}

export default SelectRangeFormInput;