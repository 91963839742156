import React, {useState, useEffect, useContext } from "react";

import { Box, Typography, MenuItem, FormControl, InputLabel, Select  } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import StyledTextField from "../StyledTextField";
import BorderPulsate from "../BorderPulsate";
import SimpleAutocomplete from "./SimpleAutocomplete";

import { allUSStates } from "../../Util";


const AddressFormInput = ({
    title, 
    onChange,
    streetAddress, 
    onStreetAddressChange, 
    city, 
    onCityChange, 
    state, 
    onStateChange, 
    zipcode, 
    onZipCodeChange, 
    startAnimation,
    sx
})=>{
    
        
    return(
    <React.Fragment>
        <Typography align='left' sx={{mt:'16px', mb:'8px', ml:'16px'}}>{title}</Typography>

        <Box sx={{ display:'flex', flexDirection:{xs:'column', sm:'row'} }}>
            
            <BorderPulsate startAnimation={startAnimation} sx={{mx:'16px', my:'16px', flexGrow:1, display:'flex'}}>         
                <StyledTextField 
                    value={streetAddress ? streetAddress : ''}
                    onChange={(e)=>onStreetAddressChange(e.target.value)}
                    label="Street Address"
                    variant="outlined"
                    sx={{flexGrow:1,}}
                /> 
            </BorderPulsate>
            
            <BorderPulsate startAnimation={startAnimation} sx={{mx:'16px', my:'16px', flexGrow:1, display:'flex'}}>  
                <StyledTextField 
                    value={city ? city : ''}
                    onChange={(e)=>onCityChange(e.target.value)}
                    label="City"
                    variant="outlined"
                    sx={{flexGrow:1}}
                /> 
            </BorderPulsate>
        </Box>

        <Box sx={{ display:'flex', flexDirection:{xs:'column', sm:'column'} }}> 
            <BorderPulsate startAnimation={startAnimation} sx={{mx:'16px', my:'16px', flexGrow:1, display:'flex'}}>  
                <FormControl fullWidth sx={{flexGrow:1,}}>
                    <SimpleAutocomplete  
                        sx={{flexGrow:1,}} 
                        value={state ? state : ''} 
                        title= "State" options={allUSStates} 
                        valueName="code" 
                        labelName="name" 
                        onChange={onStateChange}
                    />
                </FormControl>
                
            </BorderPulsate>

            <BorderPulsate startAnimation={startAnimation} sx={{mx:'16px', my:'16px', flexGrow:1, display:'flex'}}>  
                <StyledTextField 
                    value={zipcode ? zipcode : ''}
                    onChange={(e)=>onZipCodeChange(e.target.value)}
                    label="Zip Code"
                    variant="outlined"
                    sx={{flexGrow:1,}}
                /> 
            </BorderPulsate>
        </Box>

    </React.Fragment>
    )
}

export default AddressFormInput;