import React, {useState, useEffect, useContext } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import { API_URL } from "../../Config";

import MainContext from "../../MainContext";

import { 
        Box, 
        Typography, 
        TextField,
        FormControl, 
        Button,
        InputLabel,
        Select,
        MenuItem,
        CircularProgress,
        FormControlLabel,
        Checkbox
    } 
        
    from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import AddIcon from '@mui/icons-material/Add';
import theme from "../../Theme";


const NewGrant = ({onDone}) => {

    //Use
	const mainService = useContext(MainContext);
    //STATES
    const [loading,setLoading] = useState(false);
    const [docData,setDocData] = useState({});
    const [subRanges,setSubRanges] = useState([{start:'',end:''}]);

    //HANDLERS
    const handleSetDataValue = (val,field) => {
        if((typeof(val) != "undefined")){
            let newData = {...docData}
            newData[field] = val;
            console.log(JSON.stringify(newData));
            setDocData(newData);
        }
    }

    const handleSetNumericValue = (e,field)=>{
        const val = parseInt(e.target.value, 0);
        let newData = {...docData}
        newData[field] = val;
        console.log(JSON.stringify(newData));
        setDocData(newData);
    }

    const handleSetPercentValue = (e,field)=>{
        let val = parseInt(e.target.value, 0);
        if(val)
            val /= 100;
        let newData = {...docData}
        newData[field] = val;
        console.log(JSON.stringify(newData));
        setDocData(newData);
    }

    const handleSetSubRangeValue = (val,boundary,num) => {
        //convert val to range
        const date = val.split('-');
        const md = `${date[1]}-${date[2]}`
        //get submission ranges
        let newData = {...docData};
        let sranges = [...subRanges]
        //see if no range exists push one on
        if(sranges.length === num){
            sranges.push({start:'',end:''});
        }
        //const boundaryIndex = boundary === 'start' ? 0 : 1;
        sranges[num][boundary] = md;
        newData['SubmissionRanges'] = sranges;
        console.log(JSON.stringify(newData));
        setSubRanges(sranges);
        setDocData(newData);
    }

    //add new range
    const handleAddSubRange = () => {
        const sranges = [...subRanges]
        sranges.push({start:'',end:''})
        setSubRanges(sranges);
    }

    //pop one of thee ranges
    const handleRemoveSubValue = () => {
        const sranges = [...subRanges]
        sranges.pop();
        let newData = {...docData};
        newData['SubmissionRanges'] = sranges;
        setSubRanges(sranges);
    }


    const handleCreateGrant = async () => {
        setLoading(true);
        try{
            let doc = {...docData};
            if(docData.AwardOrgURL){
                console.log('getting logo URL')
                const token = await mainService.getUserToken();
                //get opengraph logo url
                const res = await fetch(API_URL + `/getogdata?` + new URLSearchParams({
                    url: docData.AwardOrgURL,
                }),{
                    headers: {
                    Authorization: `${token}`
                    }
                })
                const data = await res.json();
                if(data.ok && data.ogdata){
                    const logoURL = mainService.getOgImageFromData(data.ogdata);
                    if(logoURL)
                        doc['AwardOrgOgImgURL'] = logoURL;
                }
            }
            console.log(doc)
            //write new grant data to firestore
            const newDocId = await mainService.intellectibaseService.createDocument("grants",doc);

            setLoading(false);
            onDone("Grant created successfully","success", newDocId);
        }
        catch(error){
            console.error(error)
            setLoading(false);
            onDone(error.message,"error");
        }
    }

    

    //LISTENERS
    //Effects
	useEffect(() => {
		//on mount
		console.log('New Grant mounted'); 

		//on unmount
		return ()=>{
			console.log('New Grant unmounted');
		}

	}, []);


    return (

        <Box sx={{display:"flex",flexDirection:"Column",position:"relative",minWidth:"600px",p:'8px'}}>

            {loading && (<Box sx={{ width:"100%",height:"100%", position:"absolute",backgroundColor:"rgba(255,255,255,0.5)",display:"flex",alignItems:"center",flexGrow:1,justifyContent:"center"}}>
                <CircularProgress/>
            </Box>)}

            <Box sx={{width:"100%",height:"100%",filter:loading && "blur(3px)", display:"flex",flexDirection:"Column"}}>
            <Typography sx={{m:theme.spacing(1)}} variant="h2">New Grant</Typography>

            <Box sx={{display:'flex',width:"100%",gap:'8px',mb:theme.spacing(1)}}>
                <FormControl sx={{flexGrow:1}}>
                    <TextField disabled={loading} onChange={(e)=>handleSetDataValue(e.target.value,"GrantName")} size="small" id="grant-name" label="Grant Name" variant="outlined" />
                </FormControl>

                <FormControl sx={{flexGrow:1}}>
                    <TextField disabled={loading} onChange={(e)=>handleSetDataValue(e.target.value,"GrantURL")} size="small" id="grant-url" label="Grant URL" variant="outlined" />
                </FormControl>
            </Box>


            <Box sx={{display:'flex',width:"100%",gap:'8px',pb:theme.spacing(1),borderBottom:`1px solid #d3e6f3`}} >
                <FormControl sx={{flexGrow:1}}>
                    <TextField disabled={loading} onChange={(e)=>handleSetDataValue(e.target.value,"AwardOrg")} size="small" id="award-org" label="Awarding Org" variant="outlined" />
                </FormControl>

                <FormControl sx={{flexGrow:1}}>
                    <TextField disabled={loading} value={docData.AwardOrgURL ? docData.AwardOrgURL : ''} onChange={(e)=>handleSetDataValue(e.target.value,"AwardOrgURL")} size="small" id="award-org-url" label="Awarding Org URL" variant="outlined" />
                </FormControl>
            </Box>

            <Typography sx={{m:theme.spacing(1)}} variant="h4">Funding details</Typography>

            <Box sx={{display:'flex',width:"100%",gap:'8px',mb:theme.spacing(1)}}>
                <FormControl sx={{flexGrow:1}}>
                    <TextField
                        disabled={loading}
                        onChange={(e)=>handleSetNumericValue(e,"AwardMin")}
                        size="small" 
                        id="award-min"
                        label="Award min"
                        type="number"
                    />
                </FormControl>
                <FormControl sx={{flexGrow:1}}>
                    <TextField
                        disabled={loading}
                        onChange={(e)=>handleSetNumericValue(e,"AwardMax")}
                        size="small" 
                        id="award-max"
                        label="Award max"
                        type="number"
                    />
                </FormControl>
            </Box>

            <Box sx={{display:'flex',width:"100%",gap:'8px',pb:theme.spacing(1),borderBottom:`1px solid #d3e6f3`}} >
                <FormControl sx={{flexGrow:1}}>
                    <TextField
                        disabled={loading}
                        onChange={(e)=>handleSetPercentValue(e,"PercentFunded")}
                        size="small" 
                        id="percent-funded"
                        type="number"
                        label="Percent funded"
                        InputProps={{endAdornment:<InputAdornment position="start">%</InputAdornment>}}
                    />
                </FormControl>
                <Box sx={{flexGrow:1}}/>
            </Box>

            <Typography sx={{m:theme.spacing(1)}} variant="h4">Submission period(s)</Typography>

            {subRanges.map((r,i)=>(
                <Box sx={{display:'flex',width:"100%",gap:'8px',mb:theme.spacing(1)}}>
                    <FormControl sx={{flexGrow:1}}>
                        <TextField
                            disabled={loading}
                            onChange={(e)=>{handleSetSubRangeValue(e.target.value,'start',i)}}
                            value={`2022-${r['start']}`}
                            size="small" 
                            id={`submission-start-${i}`}
                            label="Submission start"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>

                    <FormControl sx={{flexGrow:1}}>
                        <TextField
                            disabled={loading}
                            onChange={(e)=>{handleSetSubRangeValue(e.target.value,'end',i)}}
                            value={`2022-${r['end']}`}
                            size="small" 
                            id = {`submission-end-${i}`}
                            label="Submission deadline"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </Box>
            ))}

            <Button 
                disabled={subRanges[subRanges.length-1]['start']==='' || subRanges[subRanges.length-1]['end']===''}
                onClick={handleAddSubRange}
                variant='contained' 
                color="info" 
                size='small' 
                sx={{width:'100%',borderRadius:'8px'}}>
                <AddIcon/>
            </Button>
            
            <Box sx={{display:'flex',width:"100%",gap:'8px',pb:theme.spacing(1),borderBottom:`1px solid #d3e6f3`}} >
                <FormControlLabel control={<Checkbox onChange={(e)=>handleSetDataValue(e.target.checked,'RecurrsAnnually')} />} label="Rolls every year?" />
            </Box>

            <Typography sx={{m:theme.spacing(1)}} variant="h4">Submission details</Typography>
            <Box sx={{display:'flex',width:"100%",gap:'8px',mb:theme.spacing(1)}}>
                <FormControl sx={{flexGrow:1}}>
                    <InputLabel htmlFor="submission-method">Submission method</InputLabel>
                    <Select disabled={loading} onChange={(e)=>handleSetDataValue(e.target.value,"SubmissionMethod")} id="submission-method" size="small">
                            <MenuItem value="form">Online form</MenuItem>
                            <MenuItem value="portal">Online portal</MenuItem>
                            <MenuItem value="email">Email</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{flexGrow:1}}>
                    <TextField 
                        disabled={loading} 
                        onChange={(e)=>handleSetDataValue(e.target.value,"SubmissionLocation")} 
                        size="small" id="submission-location" 
                        label="Submission location" 
                        variant="outlined" 
                    />
                </FormControl>
            </Box>

            <Box sx={{display:'flex',width:"100%",gap:'8px',pb:theme.spacing(1),borderBottom:`1px solid #d3e6f3`}} >
                <FormControlLabel control={<Checkbox onChange={(e)=>handleSetDataValue(e.target.checked,'NeedsLetterInquiry')} />} label="Letter of inquiry needed?" />
            </Box>

            <Typography sx={{m:theme.spacing(1)}} variant="h4">Contact details</Typography>


            <Box sx={{display:'flex',width:"100%",gap:'8px',mb:theme.spacing(1)}}>
                <FormControl sx={{flexGrow:1}}>
                    <TextField disabled={loading} onChange={(e)=>handleSetDataValue(e.target.value,"ContactFirstName")} size="small" id="contact-firstname" label="First name" variant="outlined" />
                </FormControl>

                <FormControl sx={{flexGrow:1}}>
                    <TextField disabled={loading} onChange={(e)=>handleSetDataValue(e.target.value,"ContactLastName")} size="small" id="contact-lastname" label="Last name" variant="outlined" />
                </FormControl>
            </Box>


            <Box sx={{display:'flex',width:"100%",gap:'8px',pb:theme.spacing(1),borderBottom:`1px solid #d3e6f3`}} >
                <FormControl sx={{flexGrow:1}}>
                    <TextField disabled={loading} onChange={(e)=>handleSetDataValue(e.target.value,"ContactEmail")} size="small" id="contact-email" label="Email" variant="outlined" />
                </FormControl>

                <FormControl sx={{flexGrow:1}}>
                    <TextField disabled={loading} onChange={(e)=>handleSetDataValue(e.target.value,"ContactPhone")} size="small" id="contact-phone" label="Phone" variant="outlined" />
                </FormControl>
            </Box>

            <Button 
                disabled={loading}
                sx={{color:"white"}} 
                variant="contained"
                onClick={handleCreateGrant}
            > 
                Create new
            </Button>
            </Box>
            
            
        </Box>
    );
};

export default NewGrant;