import  {useState, useEffect, useContext } from "react";
import { API_URL } from "../../Config";
import MainContext from "../../MainContext";
import AddIcon from '@mui/icons-material/Add';

import { 
        Box, 
        Button,
        Typography, 
        TextField,
        FormControl, 
        InputLabel,
        Select,
        MenuItem,
        CircularProgress,
        IconButton,
        FormControlLabel,
        Checkbox} 
    from '@mui/material';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import theme from "../../Theme";



const GrantDetails = ({gid}) => {

    //Use
	const mainService = useContext(MainContext);

    //STATES
    const [loading,setLoading] = useState(false);
    const [docData,setDocData] = useState({SubmissionMethod:"email"});

    //HANDLERS
    const handleSetDataValue = async (val,field)=>{
        try{
           await mainService.intellectibaseService.updateDocData("grants",gid,field,val);
        }
        catch(error){
            console.error(error)
        }
    }

    const handleSetOrgURL = async (e,field)=>{
        let val = e.target.value;
        try{

            console.log('getting logo URL')
            const token = await mainService.getUserToken();
            //get opengraph logo url
            const res = await fetch(API_URL + `/getogdata?` + new URLSearchParams({
                url: val,
            }),{
                headers: {
                Authorization: `${token}`
                }
            })
            const data = await res.json();
            if(data.ok && data.ogdata){
                const logoURL = mainService.getOgImageFromData(data.ogdata)
                if(logoURL)
                    await mainService.intellectibaseService.updateDocData("grants",gid,"AwardOrgOgImgURL",logoURL);
            }
            
           await mainService.intellectibaseService.updateDocData("grants",gid,field,val);
        }
        catch(error){
            console.error(error)
        }
    }

    const handleSetNumericValue = async (e,field)=>{
        const val = parseFloat(e.target.value, 0);
        try{
            await mainService.intellectibaseService.updateDocData("grants",gid,field,val);
        }
        catch(error){
            console.error(error)
        }
    }

    const handleGetDateDisplayValue = (range,boundary) => {
        const d = new Date();
        let currentYear = d.getFullYear();

        if(!range['end']){
            return `${currentYear}-${range[boundary]}`
        }

        let rangeEnd = range['end'].split('-');
        let endMonth = parseInt(rangeEnd[0]);
        let endDay = parseInt(rangeEnd[1]);

        
        let currentMonth = d.getMonth() + 1; 
        let currentDay = d.getDate();
        let nextYear = currentYear + 1;
        
        //work out if deadline has passed for this year
        if((endMonth<currentMonth) || (endMonth === currentMonth && endDay < currentDay)){
            if(boundary==='end'){
                return `${nextYear}-${range[boundary]}`;
            }
            else{
                let rangeStart = range['start'].split('-');
                let startMonth = parseInt(rangeStart[0]);
                let startDay = parseInt(rangeStart[1]);
                //if start comes after end remove a year
                if(startMonth>endMonth || (startMonth === endMonth && startDay > endDay)){
                    return `${currentYear}-${range[boundary]}`
                }
                return `${nextYear}-${range[boundary]}`;
            }
        }

        else {
            return `${currentYear}-${range[boundary]}`
        }
    }
    
    const handleSetSubmissionRangeValue = async (val,index,boundary) => {

        const date = val.split('-');
        const md = `${date[1]}-${date[2]}`;

        let subRanges = [...docData.SubmissionRanges];
        subRanges[index][boundary] = md;
        try{
            await mainService.intellectibaseService.updateDocData("grants",gid,'SubmissionRanges',subRanges);
        }
        catch(error){
            console.error(error)
        }

    }

    const handleAddSubmissionRange = async () => {
        let subRanges = docData.SubmissionRanges ? [...docData.SubmissionRanges] : [];
        subRanges.push({start:'',end:''});
        try{
            await mainService.intellectibaseService.updateDocData("grants",gid,'SubmissionRanges',subRanges);
        }
        catch(error){
            console.error(error)
        }
    }

    //LISTENERS
    const listenDocChanged = (data) => {
        if(data){
            console.log(JSON.stringify(data));
            setDocData({...data})
        }
        else{
            setDocData({});
            //auto close or show user error as doc is deleted
        }
    }

    //Effects
	useEffect(() => {
		//on mount
		console.log('Grant details mounted'); 
        mainService.intellectibaseService.listenDocumentData("grants",gid,listenDocChanged);
		//on unmount
		return ()=>{
			console.log('Grant details unmounted');
            mainService.intellectibaseService.unsubscribeDocListener();
		}

	}, []);



    return (
        <Box sx={{display:"flex",flexDirection:"Column",position:"relative",minWidth:"600px",overflow:"hidden"}}>

            {loading && (<Box sx={{ width:"100%",height:"100%", position:"absolute",backgroundColor:"rgba(255,255,255,0.5)",display:"flex",alignItems:"center",flexGrow:1,justifyContent:"center"}}>
                <CircularProgress/>
            </Box>)}

            <Box sx={{width:"100%",height:"100%",filter:loading && "blur(3px)", display:"flex",flexDirection:"Column",overflow:"hidden",gap:'16px'}}>

                <Typography sx={{}} variant="h2">Grant data</Typography>

                <Box sx={{overflowY:"auto"}}>

                    <Box sx={{display:"flex",minWidth:"600px",flexDirection:"column",gap:'12px'}}>

                        <FormControl sx={{display:"flex", flexDirection:"row",alignItems:"flex-end",mt:'8px'}}>
                            <Box sx={{display:"flex", alignItems:"flex-end",flexGrow:1}}>
                                <TextField 
                                    fullWidth
                                    value={docData.GrantName} 
                                    disabled={loading} 
                                    onChange={(e)=>{handleSetDataValue(e.target.value,"GrantName")}} 
                                    size="small" 
                                    id="grant-name" 
                                    label="Grant Name" 
                                />
                                <IconButton onClick={()=>window.open(docData.GrantURL,'_blank')}  size="small"><OpenInNewIcon/></IconButton>
                            </Box>
                            <Box sx={{display:"flex", alignItems:"center",flexGrow:1}}>
                                <TextField 
                                    onChange={(e)=>{handleSetDataValue(e.target.value,"AwardOrg")}} 
                                    sx={{flexGrow:1}}
                                    value={docData.AwardOrg} 
                                    disabled={loading} 
                                    size="small" 
                                    id="award-org" 
                                    label="Awarding Org" 
                                />
                                <IconButton onClick={()=>window.open(docData.AwardOrgURL,'_blank')}  size="small"><OpenInNewIcon/></IconButton>
                            </Box>
                        </FormControl>

                        <FormControl sx={{display:"flex", flexDirection:"row",alignItems:"flex-end",gap:"16px",borderBottom:`1px solid #d3e6f3`,pb:'8px'}}>
                            <TextField 
                                fullWidth
                                value={docData.GrantURL} 
                                disabled={loading} 
                                onChange={(e)=>{handleSetDataValue(e.target.value,"GrantURL")}} 
                                size="small" 
                                id="grant-url" 
                                label="Grant URL" 
                                sx={{flexGrow:1}}
                            />
                            <TextField 
                                fullWidth
                                onChange={(e)=>{handleSetOrgURL(e,"AwardOrgURL")}} 
                                sx={{flexGrow:1}}
                                value={docData.AwardOrgURL} 
                                disabled={loading} 
                                size="small" 
                                id="award-org-url" 
                                label="Awarding Org URL" 
                            />
                        </FormControl>

                        <Typography sx={{}} variant="h4">Funding details</Typography>

                        <FormControl sx={{display:"flex", flexDirection:"row",gap:"16px"}}>
                            <TextField
                                value={docData.AwardMax} 
                                sx={{flexGrow:1}}
                                size="small"
                                label = "Award max."
                                disabled={loading}
                                onChange={(e)=>{handleSetNumericValue(e,"AwardMax")}} 
                                id="award-max"
                                type="number"
                            />

                            <TextField
                                value={docData.AwardMin} 
                                sx={{flexGrow:1}}
                                size="small"
                                label = "Award min."
                                disabled={loading}
                                onChange={(e)=>{handleSetNumericValue(e,"AwardMin")}} 
                                id="award-min"
                                type="number"                          
                            />
                        </FormControl>
                        <FormControl sx={{display:"flex", flexDirection:"row",gap:"16px",borderBottom:`1px solid #d3e6f3`,pb:'8px'}}>
                            <TextField
                                value={docData.PercentFunded} 
                                sx={{flexGrow:1}}
                                size="small"
                                label = "Percentage funded (decimal)"
                                disabled={loading}
                                onChange={(e)=>{handleSetNumericValue(e,"PercentFunded")}} 
                                id="percent-funded"
                                type="number"                          
                            />
                        </FormControl>

                        <Typography sx={{}} variant="h4">Submission period(s)</Typography>
                        {docData.SubmissionRanges &&
                            docData.SubmissionRanges.map((range,i)=>(
                                <Box sx={{display:"flex", flexDirection:"row",gap:"16px"}}>
                                    <TextField
                                        sx={{flexGrow:1}}
                                        value={range['start'] ? handleGetDateDisplayValue(range,'start') : ''}
                                        disabled={loading}
                                        onChange={(e)=>{handleSetSubmissionRangeValue(e.target.value,i,'start')}} 
                                        size="small" 
                                        id={`submission-start-${i}`}
                                        label="Submission start"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <TextField
                                        sx={{flexGrow:1}}
                                        value={range['end'] ? handleGetDateDisplayValue(range,'end') : ''}
                                        disabled={loading}
                                        onChange={(e)=>{handleSetSubmissionRangeValue(e.target.value,i,'end')}} 
                                        size="small" 
                                        id={`submission-end-${i}`}
                                        label="Submission end"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Box>
                        ))}

                        <Button 
                            disabled = {docData.SubmissionRanges && (docData.SubmissionRanges[docData.SubmissionRanges.length-1]['start']=== '' || docData.SubmissionRanges[docData.SubmissionRanges.length-1]['end']=== '')}
                            onClick={handleAddSubmissionRange}
                            variant='contained' 
                            color="info" 
                            size='small' 
                            sx={{width:'100%',borderRadius:'8px'}}>
                            <AddIcon/>
                        </Button>

                        <Box sx={{display:'flex',width:"100%",gap:'8px',pb:theme.spacing(1),borderBottom:`1px solid #d3e6f3`}} >
                            <FormControlLabel control={<Checkbox checked={docData.RecurrsAnnually ? docData.RecurrsAnnually : false} onChange={(e)=>handleSetDataValue(e.target.checked,'RecurrsAnnually')} />} label="Rolls every year?" />
                        </Box>

                        <Typography sx={{}} variant="h4">Submission details</Typography>

                        <Box sx={{display:"flex", flexDirection:"row",gap:"16px"}}>
                            <FormControl sx={{flexGrow:1}}>
                                <InputLabel id="sub-method">Submission method</InputLabel>
                                <Select labelId="sub-method"  label="Submission method" value={docData.SubmissionMethod} disabled={loading} onChange={(e)=>{handleSetDataValue(e.target.value,"SubmissionMethod")}}  id="submission-method" size="small">
                                        <MenuItem value="form">Online form</MenuItem>
                                        <MenuItem value="portal">Online portal</MenuItem>
                                        <MenuItem value="email">Email</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField 
                                value={docData.SubmissionLocation ?docData.SubmissionLocation :''} 
                                disabled={loading} 
                                onChange={(e)=>{handleSetDataValue(e.target.value,"SubmissionLocation")}} 
                                size="small" 
                                id="submission-location" 
                                label="Submission location" 
                                sx={{flexGrow:1}}
                            />
                        </Box>
                        <Box sx={{display:'flex',width:"100%",gap:'8px',pb:theme.spacing(1),borderBottom:`1px solid #d3e6f3`}} >
                            <FormControlLabel control={<Checkbox checked={docData.NeedsLetterInquiry ? docData.NeedsLetterInquiry : false} onChange={(e)=>handleSetDataValue(e.target.checked,'NeedsLetterInquiry')} />} label="Letter of inquiry needed?" />
                        </Box>

                        <Typography sx={{}} variant="h4">Contact details</Typography>

                        <FormControl sx={{display:"flex", flexDirection:"row",alignItems:"flex-end",mt:'8px',gap:"16px"}}>
                                <TextField 
                                    fullWidth
                                    value={docData.ContactFirstName ? docData.ContactFirstName : ''} 
                                    disabled={loading} 
                                    onChange={(e)=>{handleSetDataValue(e.target.value,"ContactFirstName")}} 
                                    size="small" 
                                    id="contact-firstname" 
                                    label="First Name" 
                                />
                                <TextField 
                                    fullWidth
                                    onChange={(e)=>{handleSetDataValue(e.target.value,"ContactLastName")}} 
                                    value={docData.ContactLastName ? docData.ContactLastName : ''} 
                                    disabled={loading} 
                                    size="small" 
                                    id="contact-lastname" 
                                    label="Last name" 
                                />
                        </FormControl>

                        <FormControl sx={{display:"flex", flexDirection:"row",alignItems:"flex-end",gap:"16px",borderBottom:`1px solid #d3e6f3`,pb:'8px'}}>
                            <TextField 
                                fullWidth
                                value={docData.ContactEmail ? docData.ContactEmail : ''} 
                                disabled={loading} 
                                onChange={(e)=>{handleSetDataValue(e.target.value,"ContactEmail")}} 
                                size="small" 
                                id="contact-email" 
                                label="Email" 
                            />
                            <TextField 
                                fullWidth
                                onChange={(e)=>{handleSetDataValue(e.target.value,"ContactPhone")}} 
                                value={docData.ContactPhone ? docData.ContactPhone : ''} 
                                disabled={loading} 
                                size="small" 
                                id="contact-phone" 
                                label="Phone" 
                            />
                        </FormControl>

                    </Box>
                </Box> 
            </Box>                     
        </Box>
    );
};

export default GrantDetails;