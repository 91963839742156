import './App.css';
import { HashRouter as Router } from 'react-router-dom';

import { ThemeProvider } from  '@mui/system';
import Theme from './Theme';

import AllRoutes from './AllRoutes';

import MainContext from "./MainContext"; 
import MainService from "./MainService"; 

import CssBaseline from '@mui/material/CssBaseline';

export default function App() {
    return (
    <MainContext.Provider value= {new MainService()}>
		<ThemeProvider theme={Theme}>
			<CssBaseline />
			<AllRoutes />
		</ThemeProvider>
	</MainContext.Provider>
    );
}
