import React, {useState, useEffect, useContext } from "react";
import MainContext from "../../MainContext";


import {Box,Typography} from '@mui/material';
import StyledButton from "../../components/StyledButton";

const FeaturedProduct = ({listingId,onButtonClick}) => {

    const mainService = useContext(MainContext);
    
    //STATES
    const [listingData,setListingData] = useState(null);

    const listenListingData = (data) => {
        setListingData(data)
    }

    useEffect(()=>{
        if(listingId)
            mainService.productListingService.addPublicListingListener(listingId,listenListingData);
        return(()=>{
            if(listingId)
                mainService.productListingService.removePublicListingListener(listingId,listenListingData);
        })
    },[listingId])

    return(
        <Box sx={{position:'relative', display:'flex', height:'500px', justifyContent:'center',}}>
            <Box sx={{position:'relative', width:'1200px', display:'flex', py:'32px'}}>
                <Box sx={{ position:'relative', flexGrow:1,}}>
                    <Box sx={{position:'absolute', width:'100%', height:'100%', display:'flex', flexDirection:'column', justifyContent:'flex-start', }}>
                        <Box sx={{mt:'24px', mb:'24px', height:'30px',  borderRadius:'50px', backgroundColor:'#FFFFFF', display:'flex',  }}>
                            <Typography sx={{fontSize:'22px',  fontWeight:'600'}}>Top Pick</Typography>
                        </Box>
                        <Typography gutterBottom variant='body' sx={{fontSize:'48px', fontWeight:'600', ml:'8px'}}>{listingData?.title}</Typography>
                        <Typography gutterBottom variant='body' sx={{ml:'8px'}}>{listingData?.strapline}</Typography>
                        <Box sx={{flexGrow:1}}/>
                        <StyledButton sx={{width:'180px', mb:'24px',}} text='Learn More' onClick={onButtonClick}></StyledButton>
                    </Box>
                </Box>
                <Box sx={{position:'relative', width:'700px', overflow:'hidden', borderRadius:'8px'}}>
                    <img style={{position:'absolute', width:'100%', height:'100%', objectFit:'cover'}} src={listingData?.featureImage?.url}></img>
                </Box>
            </Box>
        </Box>
    )
}

export default FeaturedProduct;