import React, {useState, useEffect} from "react";

import { Autocomplete, Typography, Chip } from "@mui/material";

import StyledTextField from "../StyledTextField";

const MultiTagAutoComplete = ({value, title, valueName, labelName, inputProps, disabled, freeSolo, options, onChange, variant, sx})=>{
    
    const [val,setVal] = useState([])
    const [freeSoloOptions,setFreeSoloOptions] = useState(null);
        
    const handleChange = (e,option)=>{
        console.log("on change")
        onChange(option); 
    }

    const handleInputChange = (e,option)=>{
        if(!freeSolo)
            return
        console.log("on input change");
        onChange(option);        
    }

    //Effects
	useEffect(() => {
		console.log('autocomplete value updated');    
        console.log(value);    
        if(value){
            let arrayVal = value.split(',');
            setVal(arrayVal);
        }
        else{
            setVal([])
        }

	}, [value]);

    return(
        <Autocomplete
            disabled={disabled}
            multiple
            id={encodeURIComponent(title)}
            value={val}
            options={options.map(e=>e[valueName])}
            freeSolo={freeSolo}
            onChange={(e,v)=>handleChange(e,v)}
            sx={{...sx}}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip variant="contained" label={option} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => <StyledTextField {...params} label={<Typography variant='body2'>{title}</Typography>} variant={variant ? variant : 'outlined' } />}          
        />
    )
}

export default MultiTagAutoComplete;

