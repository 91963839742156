import React, {useState, useEffect, useContext } from "react";

import Theme from '../../Theme';
import MainContext from "../../MainContext"; 

import { ThemeProvider } from  '@mui/system';
import { Box, Typography, Tabs, Tab, CircularProgress } from "@mui/material";


import Paper from "../../components/Paper";


import { Chart } from "react-google-charts";

import * as dayjs from 'dayjs'
import { getFormattedNumberString } from "../../Util";

const DataVizPanel = ({data, fromDate, toDate, datesValid, productFilter}) => {

    const mainService = useContext(MainContext);

    const [activityData, setActivityData] = useState([["Date", "New Applications"]]);
    const [valueData, setValueData] = useState([["Date", "Cumulative Value"]]);
    const [geoData, setGeoData] = useState([['State', 'Count']]);
    const [fundingStageData, setFundingStageData] = useState(["Action", "Number"]);
    const [totalAppCount, setTotalAppCount] = useState(0); 
    const [totalUnitCount, setTotalUnitCount] = useState(0);
    const [totalValue, setTotalValue] = useState(0); 

    const buildRows = (from, to)=>{
        
        const productFilterSet =  new Set(); 
        if(productFilter && Array.isArray(productFilter)){
            for (let pid of productFilter){
                productFilterSet.add(pid); 
            }
        }
        let djsFrom = dayjs(from); 
        let djsTo = dayjs(to).add(1, 'day'); 

        let myProducts = data; 
        let newRows = []; 
        if(!data){
            return newRows; 
        }
        for(let productId in myProducts){
            
            if(productFilter.length > 0 && !productFilterSet.has(productId))
                continue;

            let product = myProducts[productId]; 

            for(let appId in product){
                let appDashboardData = product[appId];
                if(!appDashboardData)
                    continue; 

                let djsCreated = dayjs(appDashboardData.createdDate);
                let inRange = djsCreated.isAfter(djsFrom) && djsCreated.isBefore(djsTo); 
                if(!inRange)
                    continue; 

                let row = {}; 
                row['appid'] = appId; 
                row['pid'] = appDashboardData.productId;
                row['appTitle'] = appDashboardData.projectTitle ? appDashboardData.projectTitle : 'No Title Yet';
                row['productName'] = appDashboardData.name; 
                row['createdDate'] = mainService.getDateString( appDashboardData.createdDate ); 
                row['createdUnixDate'] = appDashboardData.createdDate; 
                row['productCount'] = appDashboardData.productCount; 
                row['fundingStage'] = appDashboardData.fundingStage ? appDashboardData.fundingStage : 0;
                row['totalValue'] = appDashboardData.productCount && appDashboardData.unitPrice ? (appDashboardData.productCount * appDashboardData.unitPrice) : 0;
                //add row for value units * unit price
                //console.log('row ', row)

                if(appDashboardData.ownerId){
                    let uid = Object.keys(appDashboardData.ownerId)[0];
                    let usrObj = appDashboardData.ownerId[uid];  
                    if(usrObj){
                        let firstname = appDashboardData.ownerId[uid].firstname; 
                        let lastname = appDashboardData.ownerId[uid].lastname; 
                        row['userName'] = firstname + ' ' + lastname; 
                        row['uid'] = uid; 
                        row['statecode'] = appDashboardData.ownerId[uid].schoolstate; 
                    }
                }

                newRows.push(row); 
            }
        }
        return newRows; 
    }

    const createActivityDataset = ( rows, granularity, from, to )=>{
        let d = [ ["Date", "New Applications"] ]; 
        let v = [ ["Date", "Cumulative Units", "Cumulative value"] ]; 

        //handle monthly granularity 
        if(granularity == 'month'){
            let monthsShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]; 

            //create a map of month year code vs count of rows 
            let countMap = new Map(); 
            let valueMap = new Map();
            let totalMap = new Map();

            for (let r of rows){
                let djs = dayjs(r.createdUnixDate); 
                let month = djs.month(); 
                let monthStr = monthsShort[month] + ' ' + djs.year();
                
                if(countMap.has(monthStr)){
                    countMap.set(monthStr, countMap.get(monthStr) + 1); 
                }else{
                    countMap.set(monthStr, 1); 
                }


                let productCount = +(r.productCount)

                if(productCount){
                    let value = productCount; 
                    if(valueMap.has(monthStr)){
                        valueMap.set(monthStr, valueMap.get(monthStr) + value); 
                    }else{
                        valueMap.set(monthStr, value); 
                    }
                }

                let totalValue = +(r.totalValue);

                if(totalValue){
                    let total = totalValue; 
                    if(totalMap.has(monthStr)){
                        totalMap.set(monthStr, totalMap.get(monthStr) + total); 
                    }else{
                        totalMap.set(monthStr, total); 
                    }
                }
            }

            //create data over range specified for both activity and funding 
            let djsFrom = dayjs(from).date(1); 
            let djsTo = dayjs(to).date(1); 
            if(djsTo.month()==11){
                djsTo = djsTo.month(0);
                djsTo = djsTo.year(djsTo.year()+1); 
            }else{
                djsTo = djsTo.month(djsTo.month()+1);
            }

            let i = 0;

            //should this be money value rather than units? or both?
            let cumVal = 0; 
            let cumValTotals = 0;
           
            while(djsFrom.isBefore(djsTo)){

                let monthStr = monthsShort[ djsFrom.month() ] + ' ' + djsFrom.year();
                let count = countMap.has(monthStr) ? countMap.get(monthStr) : 0; 
                let value = valueMap.has(monthStr) ? valueMap.get(monthStr) : 0; 
                let total = totalMap.has(monthStr) ? totalMap.get(monthStr) : 0;

                cumVal += value; 
                cumValTotals += total;

                d.push([monthStr, count]); 
                v.push([monthStr, cumVal, cumValTotals]); 

                

                //increment the month (taking into account the year )
                if(djsFrom.month()==11){
                    djsFrom = djsFrom.month(0);
                    djsFrom = djsFrom.year(djsFrom.year()+1); 
                }else{
                    djsFrom = djsFrom.month(djsFrom.month()+1);
                }
                
                i++;
                if(i > 100)
                    break;
            }

        }


        console.log('v ',v)

        setActivityData(d); 
        setValueData(v); 
    }

    const createGeoDataset = (rows)=>{
        let d = [['State', 'Count']]; 

        //create a map of each state 
        let countMap = new Map(); 

        for (let r of rows){
            let state = r.statecode; 
            if(countMap.has(state)){
                countMap.set(state, countMap.get(state) + 1); 
            }else{
                countMap.set(state, 1); 
            }
        }

        for (const [k, v] of countMap){
            d.push(['US-'+k, v]);  
        }

        setGeoData(d); 
    }

    const createTotalsData = (rows)=>{
        setTotalAppCount(rows.length);

        let U = 0;
        let T = 0;
        for(let r of rows){
            let productCount = r.productCount;
            if(+productCount)
                U += +productCount;
            let totalValue = r.totalValue;
            if(+totalValue)
                T += totalValue;
        }
        setTotalUnitCount(U); 
        setTotalValue(T);
    }

    //create donut data
    const createDonutData = (rows)=>{
        let d = [
            ["Action", "Number"],
            ["Not started",0],
            ["In progress",0],
            ["Submitted", 0],
            ["Won",0],
            ["Funds received",0]
        ];

        let counts = [0,0,0,0,0]
        for(const r of rows){
            let fundingstage = r.fundingStage ? r.fundingStage : 0;
            counts[fundingstage]++;
        }
        
        d[1][1]=counts[0];
        d[2][1]=counts[1];
        d[3][1]=counts[2];
        d[4][1]=counts[3];
        d[5][1]=counts[4];

        setFundingStageData(d);
    }

    //Effects
	useEffect(()=>{
		let rows = buildRows(fromDate, toDate); 

        createActivityDataset(rows, 'month', fromDate, toDate); 
        createGeoDataset(rows); 
        createTotalsData(rows); 
        createDonutData(rows);

	}, [data, fromDate, toDate, productFilter]);

    const dataFunding = [
        ["Date", "Funding potential in $"],
        ["Aug",  10000],
        ["Sept",  30000],
        ["Oct",  40000],
        ["Nov",  45000],
        ["Dec",  52000],
    ];

    const data2 = [
        [
          { type: "date", label: "Day" },
          "Customer Impressions",
          "Funding potential generated (in $)",
        ],
        [new Date(2023, 0), 550, 10000],
        [new Date(2023, 1), 600, 20000],
        [new Date(2023, 2), 800, 40000],
        [new Date(2023, 3), 1000, 50000],
        [new Date(2023, 4), 550, 10000],
       
    ];
  

    const optionsBar = {
        isStacked: true,
        legend: { position: "bottom" },
        animation: {
            startup: true,
            easing: "linear",
            duration: 500,
        },
        colors:['#2499ef', '#9c27b0'],
        
    };

    const options = {
        legend: { position: "bottom" },
        animation: {
            startup: true,
            easing: "linear",
            duration: 500,
        },
        colors:['#2499ef','#C9214E'],
        lineWidth:'4',

        /*series: {
            0: {axis: 'Units'}, 
            1: {axis: 'Value'}
        },
        axes: {
            y: {
              Units: {label: 'Units'},
              Value: {label: 'Amount ($)'}
            }
        }*/
        series: {
            0: {targetAxisIndex: 0},
            1: {targetAxisIndex: 1}
          },
          vAxes: {
            // Adds titles to each axis.
            0: {title: 'Units'},
            1: {title: 'Amount ($)'}
          },
        
    };


    const donutData = [
        ["Action", "Number"],
        ["Page Hits", 5000], 
        ["Applications Started", 550], 
        ["Applications Completed", 300]
    ];

    const donutOptions = {
        pieHole: 0.4,
        is3D: false,
        //legend: "none",
        legend:'bottom',
        pieSliceText: "none",
        colors:['#54BD95','#2499ef', '#9c27b0', '#C9214E','#ed6c02'],
        animation: {
            startup: true,
            easing: "linear",
            duration: 500,
        },
    }


    const donutGrantsData = [
        ["Action", "Number"],
        ["Discovered ", 1000], 
        ["Applied", 200], 
        ["Won", 50]
    ];

    const geoOptions = {
        region: 'US',
        displayMode: 'regions',
        resolution: 'provinces',
        colors:['#2499ef'],
    };


    return (
    <ThemeProvider theme={Theme}>
    {!datesValid &&
        <Box sx={{position:'relative', flexGrow:1 }}>
            <CircularProgress/>
        </Box>
    }
    {datesValid &&  
    <Box sx={{position:'relative', flexGrow:1 }}>
    <Box sx={{position:'absolute', width:'100%', height:'100%', overflow:'auto', borderRadius:'8px'}}>
        
        
        <Box sx={{display:'flex', flexDirection:{xs:'column', md:'row'}, mb:'12px'  }}>

                <Paper sx={{position:'relative', p:'8px', flexGrow:1, mr:'6px', display:'flex', flexDirection:'column'}}>
                    <Typography gutterBottom variant="h5">User Activity</Typography>
                    <Typography variant='body2'>This shows the cumulative count of applications created month on month.</Typography>
                    <Box sx={{position:'relative', height:'362px', display:'flex', flexDirection:'column'}}>
                        <Box sx={{width:'100%', height:'100%', position:'absolute', backgroundColor:'#ffffff'}}>
                            <Chart
                                chartType="ColumnChart"
                                width="100%"
                                height="100%"
                                data={activityData}
                                options={optionsBar}
                                
                            />
                        </Box>
                    </Box>
                </Paper>

                <Paper sx={{position:'relative', p:'8px', flexGrow:1, ml:'6px', display:'flex', flexDirection:'column'}}>
                    <Typography gutterBottom variant="h5">Cumulative Value</Typography>
                    <Typography variant='body2'>This shows the cumulative potential value and count of product units for all applications.</Typography>
                    <Box sx={{position:'relative', height:'362px', display:'flex', flexDirection:'column'}}>
                        <Box sx={{width:'100%', height:'100%', position:'absolute', backgroundColor:'#ffffff'}}>
                            <Chart
                                chartType="LineChart"
                                width="100%"
                                height="100%"
                                data={valueData}
                                options={options}
                                series={{0: {axis: 'Product units'},1: {axis: 'Value'}}}
                                
                            />
                        </Box>
                    </Box>
                </Paper>
        </Box>


        <Box sx={{display:'flex', flexDirection:{xs:'column', md:'row'}, mb:'12px', mt:'12px' }}>

                <Paper sx={{ flexGrow:1, p:'8px', mr:'6px' }}>
                    <Typography gutterBottom variant="h5">Total</Typography>
                    <Typography variant='body2'>This shows some useful summary stats about your applications.</Typography>
                    <Box sx={{position:'relative', height:'362px', display:'flex', flexDirection:'column'}}>
                        <Box sx={{flexGrow:1}}/>
                        <Typography variant='h4'  align='center'>Applications</Typography>
                        <Box sx={{flexGrow:1}}/>
                        <Typography variant='h1' align='center'>{totalAppCount}</Typography>
                        <Box sx={{flexGrow:1}}/>
                        <Typography variant='h4'  align='center'>Total Units</Typography>
                        <Box sx={{flexGrow:1}}/>
                        <Typography variant='h1' align='center'>{""+totalUnitCount}</Typography>
                        <Box sx={{flexGrow:1}}/>
                        <Typography variant='h4'  align='center'>Value of Units</Typography>
                        <Box sx={{flexGrow:1}}/>
                        <Typography variant='h1' align='center'>${totalValue && getFormattedNumberString(totalValue)}</Typography>

                        <Box sx={{flexGrow:1}}/>
                    </Box>
                </Paper>

                {/*<Paper sx={{ flexGrow:1, p:'8px', ml:'6px', mr:'6px' }}>
                    <Typography gutterBottom variant="h5">User Progress</Typography>
                    <Box sx={{position:'relative', height:'362px', display:'flex', flexDirection:'column'}}>
                        <Box sx={{width:'100%', height:'100%', position:'absolute', backgroundColor:'#ffffff'}}>
                            <Chart
                                chartType="PieChart"
                                width="100%"
                                height="100%"
                                data={donutData}
                                options={donutOptions}
                                
                            />
                        </Box>
                    </Box>
                </Paper>*/}

                <Paper sx={{ flexGrow:1, p:'8px', ml:'6px'}}>
                    <Typography gutterBottom variant="h5">Funding Stages</Typography>
                    <Typography variant='body2'>This shows the stage that your applications are at.</Typography>
                    <Box sx={{position:'relative', height:'362px', display:'flex', flexDirection:'column'}}>
                        <Box sx={{width:'100%', height:'100%', position:'absolute', backgroundColor:'#ffffff'}}>
                            <Chart
                                chartType="PieChart"
                                width="100%"
                                height="100%"
                                data={fundingStageData}
                                options={donutOptions}
                                
                            />
                        </Box>
                    </Box>
                </Paper>
        </Box>


        <Box sx={{display:'flex', flexDirection:{xs:'column', md:'row'}, mb:'12px', mt:'12px'}}>
            <Paper sx={{ flexGrow:1, p:'8px', }}>
                <Typography gutterBottom variant="h5">Where?</Typography>
                <Typography variant='body2'>This shows the state that your applications were created in.</Typography>
                <Box sx={{position:'relative', height:'362px', display:'flex', flexDirection:'column'}}>
                    <Box sx={{width:'100%', height:'100%', position:'absolute', backgroundColor:'#ffffff'}}>
                        <Chart
                            chartType="GeoChart"
                            width="100%"
                            height="100%"
                            data={geoData}
                            options={geoOptions}
                            
                        />
                    </Box>
                </Box>
            </Paper>
        </Box>

    </Box>
    </Box>
    }
    </ThemeProvider>
    )
}

export default DataVizPanel; 