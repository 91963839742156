import React, {useState, useEffect, useContext } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";

import MainService from "../MainService"; 
import MainContext from "../MainContext"; 

import Theme from "../Theme";

import { Box, Typography, IconButton, ButtonBase, Icon, Avatar, Badge, CircularProgress, Button } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ProfileAvatar from "./ProfileAvatar";

import AccountMenu from "./AccountMenu";
import LoadingBackdrop from "./LoadingBackdrop";

let textColor = '#FFFFFF';textColor='#000000'; 
let barColor = '#FFFFFF';//F2F9FE

const TopBar = ({backAction, title, authed, thinMode, hideUpgradeButton, dashboard, userObject, onOpenUpgrade, children})=>{
    //Use
	const mainService = useContext(MainContext);
	const navigate = useNavigate(); 

    //STATES

    const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState(null);
    const [accountMenuOpen, setAccountMenuOpen] = useState(false);
    const [loading,setLoading] = useState(false);
    const [navLoading,setNavLoading] = useState(true);
    const [plan,setPlan] = useState(null);
    const [upgradeOpen,setUpgradeOpen] = useState(false);


    //HANDLERS
    const handleAccountClick = (e)=>{
        setAccountMenuAnchorEl(e.currentTarget);
        setAccountMenuOpen(true);
    }

    const handleOpenUpgrade = () => {
        setUpgradeOpen(true)
    }

    const handleCloseUpgrade = (event,reason) => {
        if (reason !== 'backdropClick') {
            setUpgradeOpen(false)
        }
    }

    const handleNotificationsClick = ()=>{

    }

    const handleAccountMenuClose = (e)=>{
        setAccountMenuOpen(false);
    }

    const handleLogout = async ()=>{

        setLoading(true);
        try{
            await mainService.logout();
            setLoading(false);
            navigate("/login");           
        }
        catch(error){
            console.error(error);
        }        
    }

    const handleHomeClick = ()=>{
        //navigate("/");  
        window.open(`/home`,'_self');
    }

    useEffect(()=>{
        const timer = setTimeout(()=>{
            setNavLoading(false)
        },500)

        return ()=>{
            if(timer){
                clearTimeout(timer)
            }
        }
    })

    const listenPlanChanged = (data) => {
        setPlan(data);
    }

    return (
    <>
        <LoadingBackdrop open={loading}/>            
        
        <Box sx={{height:thinMode?'40px':'50px', display:'flex', alignItems:'center', px:'24px', backgroundColor:barColor,
            borderBottom:`1px solid ${Theme.palette.grey[300]}`,
            //boxShadow:'0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%)',
        }}>
            
            {backAction && (

                <IconButton
                    size="medium"
                    onClick={backAction}
                    color="inherit"
                >
                    <ArrowBackIcon/>
                </IconButton>
    
            )}

            <ButtonBase sx={{p:'2px',borderRadius:'5px', mr:'32px'}}  onClick={handleHomeClick}>
                <Typography variant='h3' sx={{color:Theme.palette.primary.main}}>{title ? title : "Intellectible"}</Typography> 
            </ButtonBase>

            <Box sx={{flexGrow:1}}></Box>
            {navLoading ? (
                <CircularProgress /> 
            ) : (
                children
            )}
            <Box sx={{flexGrow:1}}></Box>
            
            {authed ? (
            <>  

            <ButtonBase
                size="medium"
                aria-label="account of current user"
                onClick={handleAccountClick}
                color="inherit"
                sx={{ml:'10px', borderRadius:'50%'}}
            >
                <ProfileAvatar  sx={{ width: 32, height: 32 }} isPro={plan?.plan == 'teacherpro' || plan?.plan == 'productpro' || plan?.plan == 'writerpro'} photoURL={userObject?.photoURL ?? null} name={userObject?.firstname ?? null}/>
            </ButtonBase>
            </>
            ):(
            <>                        
            <ButtonBase                     
                sx={{
                    my:'24px',
                    px:"10px",
                    borderStyle:"solid",
                    borderRadius:"8px",
                    height:"36px",
                    borderColor:Theme.palette.grey[400],
                    borderWidth:"1px",
                    "&:hover": {
                        borderColor: 'rgb(0,0,0)'
                    },
                    mr:Theme.spacing(1)
                }}
                onClick={()=>navigate("/login")}
            >
                <Typography >Login</Typography>
            </ButtonBase>

            <ButtonBase
                sx={{
                    my:'24px',
                    color:"white",
                    px:"10px",                                   
                    borderStyle:"solid",
                    backgroundColor:Theme.palette.primary.main,
                    borderRadius:"8px",
                    height:"36px",
                    "&:hover": {
                        backgroundColor:Theme.palette.primary.dark,
                    },
                }}
                onClick={()=>navigate("/signup?usertype=teacher")}
            >
                <Typography sx={{color:'white',fontWeight:600}}>Get started</Typography>
            </ButtonBase>
            </>
            )}

        </Box>

        <AccountMenu 
            open={accountMenuOpen} 
            handleClose={handleAccountMenuClose} 
            anchorEl={accountMenuAnchorEl}
            handleLogout={handleLogout}
            dashboard={dashboard}
            handleDashboard={()=>{navigate("/home")}}
            handleAccountSettings={()=>window.open(`/home?module=account`,'_self')}
            handleMyProfile={()=>window.open(`/home?module=profile`,'_self')}
        />

        {/*!onOpenUpgrade && userObject && plan && authed && <UpgradeTeacherPro open={upgradeOpen} onClose={handleCloseUpgrade} userObj={userObject} />*/}
    </>
    )
}

export default TopBar; 