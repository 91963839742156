import React, {useState, useEffect, useContext } from "react";
import { useNavigate, createSearchParams, useLocation } from "react-router-dom";
import useQuery from "../useQuery";

import MainService from "../MainService"; 
import MainContext from "../MainContext"; 

import isEmail from 'validator/lib/isEmail';
import queryString from 'query-string';

import FormControl from "@mui/material/FormControl";
import { ThemeProvider } from  '@mui/system';
import { Box, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import theme from '../Theme';
import ButtonBase from "@mui/material/ButtonBase"
import InputBase from "@mui/material/InputBase";
import Link from "@mui/material/Link";
import CircularProgress from '@mui/material/CircularProgress';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DoneIcon from '@mui/icons-material/Done';

import ResponsiveDialog from "../components/ResponsiveDialog";
import Theme from "../Theme";

const GoogleButton = ({onSignIn,disabled}) => {

    return (
    <ButtonBase 
        disabled = {disabled}
        type="submit"
        focusRipple
        onClick = {onSignIn}
        sx={{
            borderStyle:"solid",
            borderRadius:"5px",
            height:"36px",
            width:"300px",
            borderColor:"#808080",
            borderWidth:"1px",
            "&:hover": {
                borderColor: 'rgb(0,0,0)'
            },
        }}
        >
        {disabled ? 
        (<CircularProgress sx={{color:"#808080"}} size={20}/>) :
        (<React.Fragment>
            <img style={{marginRight:"6px",height:"14px", width:"14px"}} src="/google-logo.svg"/>
            <Typography>Continue with Google</Typography>
        </React.Fragment>)}
    </ButtonBase>)

}

const Login = ({title}) => {
    //Use
	const mainService = useContext(MainContext);
	const navigate = useNavigate(); 
    let location = useLocation();
    let query = useQuery();

    const [textValue,setTextvalue] = useState("");
    const [email,setEmail] = useState("");
    const [buttonsDisabled,setButtonsDisabled] = useState(false);
    const [authState, setAuthState] = useState(mainService.getAuthedState());
    const [linkSent,setLinkSent] = useState(false);
    const [dialogOpen,setDialogOpen] = useState(false);
    const [dialogMessage,setDialogMessage] = useState("");
    const [popupBlockDialogOpen,setPopupBlockDialogOpen] = useState(false);

    const [userType, setUserType] = useState('teacher');

    const [pageVariant,setPageVariant] = useState('regular');
    const [productName,setProductName] = useState(null);


    
    const handleGoogleSignin = async ()=>{
        setButtonsDisabled(true);
        try{
            setPopupBlockDialogOpen(false);
            await mainService.googleSigninUser(userType);
            navigate(`/home${`?${query.toString()}`}`);
            setButtonsDisabled(false);
        }
        catch(error){
            console.error(error.message)
            if (error.code === "auth/popup-closed-by-user"){
                setButtonsDisabled(false);
                
            }
            //happens first time you try and login with popup in Safari
            //clear cache to recreate
            else if(error.code === "auth/popup-blocked"){
                setPopupBlockDialogOpen(true);
            }
			else {
                setDialogMessage(error.message);
                setDialogOpen(true);
                setButtonsDisabled(false);
                setPopupBlockDialogOpen(false);
            }
        }
    }
    
    const handleEmailLinkSignin = async (email)=>{
        setButtonsDisabled(true);
        try {
            await mainService.sendEmailSigninLink(email,query, userType);
            setLinkSent(true);
            setButtonsDisabled(false);
        }
        catch(error){
            console.error(error);
            setDialogMessage(error.message);
            setDialogOpen(true);
        }
    }

    const handleAuthStateChanged=()=>{
		setAuthState( mainService.getAuthedState() );
		if(mainService.authed == MainService.AUTHED){
			navigate('/home'); 
			setButtonsDisabled(false); 
		}
	}

    const handleEmailInput=(val)=>{
        if(isEmail(val)){
            setEmail(val);
        }
        else{
            setEmail("");
        }
        setTextvalue(val);
    }

	//Effects
	useEffect(() => {
		//on mount
		mainService.addAuthChangeCallback(handleAuthStateChanged); 

		//on unmount
		return ()=>{
			mainService.removeAuthChangeCallback(handleAuthStateChanged); 
		}

	}, []);

    useEffect(() => {

		//get query string
		const search = location.search;
		if(search){
			//get query string
			const searchObj = queryString.parse(search);
		}

		//on unmount
		return ()=>{
				
		}

	}, [location]);

    useEffect(()=>{

        if(query && query.get('module')  === 'applications' && query.get('action') === 'new'){
            if(query.has('name')){
                setProductName(decodeURIComponent(query.get('name')));
            }
            setPageVariant('apply');
        }

        else if (title === "Sign up" && query.get('usertype') !== 'thirdparty'){
            setPageVariant('educatorSignup');
        }

        else if (title === "Sign up" && query.get('usertype') === 'thirdparty'){
            setPageVariant('productSignup');
        }


        //User type from query
        if(query){
            let ut = query.get('usertype') ?? 'teacher'; 
            setUserType(ut); 
        }

    },[query])

    const renderApplyPage = () => {
        return(
            <Box sx={{flexGrow:1,display:'flex',flexDirection:{xs:'column',md:'row'}}}>
            <Box sx={{minWidth:{xs:'0px',md:'600px'},backgroundImage:'url("assets/patternBlur.svg")',flexGrow:1,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                <Typography variant="h1" sx={{ mb: '32px' ,mx:{xs:'16px',md:'32px'}, mt:{xs:'48px',md:'0px'}}}>Hello Educator!</Typography>
                <Typography variant="h2" sx={{mb:'16px',mx:{xs:'16px',md:'32px'}}}>There are just a few steps to start your application for {productName ? productName : 'your product'}</Typography>
                <Typography variant="h3" sx={{mx:{xs:'16px',md:'32px'},mb:{xs:'32px',md:'0px'}}}>Authenticate your account to start generating your application! You should be done in less than 5 minutes!</Typography>
                
            </Box>
            <Box sx={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",flexGrow:1,width:'100%',height:'100%', border:`1px solid ${Theme.palette.grey[300]}`}}>               
                <Typography variant="h3" sx={{mb:"24px",fontWeight:800}}>
                    {title}
                </Typography>
                <GoogleButton disabled={buttonsDisabled} onSignIn={handleGoogleSignin} />
                <Box sx={{display:"flex", alignItems:"flex-end", flexDirection:"row", width:'300px',my:"21px"}}>
                    <Box sx={{flexGrow:1,height:"50%",borderTop: `1px solid ${theme.palette.grey[600]}`}} />
                    <Typography sx={{px:"4px",color:theme.palette.grey[600]}} variant="body2">OR</Typography>
                    <Box sx={{flexGrow:1,height:"50%",borderTop: `1px solid ${theme.palette.grey[600]}`}} />
                </Box>
            
                <Typography variant="body2" align="left" sx={{width:'300px',color:theme.palette.text.secondary}}>Email</Typography>
                <FormControl>
                <Box sx={{mb:theme.spacing(1),height:"36px",width:"300px", position:"relative"}}>
                    {email && 
                    <ButtonBase onClick={()=>handleEmailInput("")} sx={{zIndex:"10",position:"absolute",right:"0",top:"25%",mr:"4px"}}>
                        <CancelOutlinedIcon sx={{color:"#808080",width:"16px",height:"16px","&:hover":{color:"#000"}}}/>
                    </ButtonBase>}
                    <InputBase 
                        value={textValue}
                        onChange={(e)=>handleEmailInput(e.target.value)}
                        placeholder="Enter your email address"
                        type="email"
                        autoComplete="email"
                        sx={{                       
                            borderStyle:"solid",
                            borderRadius:"5px",
                            height:"36px",
                            width:"300px",
                            p:"4px 10px",
                            borderColor:email? "#4BB543":"#808080",
                            borderWidth:"1px",
                            "&:hover": {
                                borderColor:email? '#3c9136':'rgb(0,0,0)'
                            },
                        }}
                    />               
                </Box>     
                {linkSent ?
                (<Typography variant="h6" align="center" sx={{display:"flex", flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
                    Email sent! <DoneIcon sx={{ml:"4px"}}/> 
                </Typography>) : (
                <ButtonBase 
                    onClick = {()=>{if(email) handleEmailLinkSignin(email);}}
                    disabled = {buttonsDisabled}
                    focusRipple
                    type="submit"
                    sx={{                       
                        backgroundColor:email ?"#c6e3c3":"#F5F5F5",
                        borderStyle:"solid",
                        borderRadius:"5px",
                        height:"36px",
                        width:"300px",
                        borderColor:email? "#4BB543":"#808080",
                        borderWidth:"1px",
                        "&:hover": {
                            borderColor:email? '#3c9136':'rgb(0,0,0)'
                        },
                        }}
                >
                    {buttonsDisabled ? 
                    (<CircularProgress sx={{color:"#808080"}} size={20}/>) :
                    (<Typography sx={{color:theme.palette.text.secondary}}>Continue with email link</Typography>)}
                </ButtonBase>)}  
                </FormControl>  
                {linkSent && 
                <Typography align="center" variant="body2" sx={{mt:theme.spacing(1),width:{xs:"300px",sm:'500px'},color:theme.palette.text.secondary}}>
                    A login link has been sent to the email address provided. Please check your email and click the link to log in!
                </Typography>}
                <Typography align="center" variant="body2" sx={{mt:"66px",width:{xs:"300px",sm:'500px'},color:theme.palette.text.secondary}}>
                    By clicking continue with Google/email you acknowledge that you have read and agreed to Intellectible's <Link href="#"color="inherit">Terms and Conditions</Link> and <Link href="#" color="inherit">Privacy Policy</Link>
                </Typography>  
            </Box>
            </Box>)

    }

    const renderEducatorSignupPage = () => {
        return (
            <Box sx={{flexGrow:1,display:'flex',flexDirection:{xs:'column',md:'row'}}}>
            <Box sx={{minWidth:{xs:'0px',md:'600px'},backgroundImage:'url("assets/patternBlur.svg")',flexGrow:1,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                <Typography variant="h1" sx={{ mb: '16px' ,mx:{xs:'16px',md:'32px'}, mt:{xs:'48px',md:'0px'}, fontWeight:700}}>Teachers are awesome and we want to help you be even more awesome!</Typography>
                <Typography variant="h2" sx={{mb:'16px', mx:{xs:'16px',md:'32px'}}}>Intellectible creates grant applications for EDU products you love in minutes using AI</Typography>
                <Typography variant="h3" sx={{mx:{xs:'16px',md:'32px'},mb:{xs:'32px'}}}>Save days of time and authenticate your account to continue the registration process! Your first grant is free!</Typography>
                <Typography  sx={{mx:{xs:'16px',md:'32px'},mb:{xs:'32px',md:'0px'}}}>Not an Educator? Head to our <Link href='https://www.intellectible.com'>website</Link> to find out how you can best use Intellectible.</Typography>
            </Box>
            <Box sx={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",flexGrow:1,width:'100%',height:'100%', border:`1px solid ${Theme.palette.grey[300]}`}}>               
                <Typography variant="h3" sx={{mb:"24px",fontWeight:800}}>
                    {title}
                </Typography>
                <GoogleButton disabled={buttonsDisabled} onSignIn={handleGoogleSignin} />
                <Box sx={{display:"flex", alignItems:"flex-end", flexDirection:"row", width:'300px',my:"21px"}}>
                    <Box sx={{flexGrow:1,height:"50%",borderTop: `1px solid ${theme.palette.grey[600]}`}} />
                    <Typography sx={{px:"4px",color:theme.palette.grey[600]}} variant="body2">OR</Typography>
                    <Box sx={{flexGrow:1,height:"50%",borderTop: `1px solid ${theme.palette.grey[600]}`}} />
                </Box>
               
                <Typography variant="body2" align="left" sx={{width:'300px',color:theme.palette.text.secondary}}>Email</Typography>
                <FormControl>
                <Box sx={{mb:theme.spacing(1),height:"36px",width:"300px", position:"relative"}}>
                    {email && 
                    <ButtonBase onClick={()=>handleEmailInput("")} sx={{zIndex:"10",position:"absolute",right:"0",top:"25%",mr:"4px"}}>
                        <CancelOutlinedIcon sx={{color:"#808080",width:"16px",height:"16px","&:hover":{color:"#000"}}}/>
                    </ButtonBase>}
                    <InputBase 
                        value={textValue}
                        onChange={(e)=>handleEmailInput(e.target.value)}
                        placeholder="Enter your email address"
                        type="email"
                        autoComplete="email"
                        sx={{                       
                            borderStyle:"solid",
                            borderRadius:"5px",
                            height:"36px",
                            width:"300px",
                            p:"4px 10px",
                            borderColor:email? "#4BB543":"#808080",
                            borderWidth:"1px",
                            "&:hover": {
                                borderColor:email? '#3c9136':'rgb(0,0,0)'
                            },
                        }}
                    />               
                </Box>     
                {linkSent ?
                (<Typography variant="h6" align="center" sx={{display:"flex", flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
                    Email sent! <DoneIcon sx={{ml:"4px"}}/> 
                </Typography>) : (
                <ButtonBase 
                    onClick = {()=>{if(email) handleEmailLinkSignin(email);}}
                    disabled = {buttonsDisabled}
                    focusRipple
                    type="submit"
                    sx={{                       
                        backgroundColor:email ?"#c6e3c3":"#F5F5F5",
                        borderStyle:"solid",
                        borderRadius:"5px",
                        height:"36px",
                        width:"300px",
                        borderColor:email? "#4BB543":"#808080",
                        borderWidth:"1px",
                        "&:hover": {
                            borderColor:email? '#3c9136':'rgb(0,0,0)'
                        },
                        }}
                >
                    {buttonsDisabled ? 
                    (<CircularProgress sx={{color:"#808080"}} size={20}/>) :
                    (<Typography sx={{color:theme.palette.text.secondary}}>Continue with email link</Typography>)}
                </ButtonBase>)}  
                </FormControl>  
                {linkSent && 
                <Typography align="center" variant="body2" sx={{mt:theme.spacing(1),width:{xs:"300px",sm:'500px'},color:theme.palette.text.secondary}}>
                    A login link has been sent to the email address provided. Please check your email and click the link to log in!
                </Typography>}
                <Typography align="center" variant="body2" sx={{mt:"66px",width:{xs:"300px",sm:'500px'},color:theme.palette.text.secondary}}>
                    By clicking continue with Google/email you acknowledge that you have read and agreed to Intellectible's <Link href="#"color="inherit">Terms and Conditions</Link> and <Link href="#" color="inherit">Privacy Policy</Link>
                </Typography>  
            </Box>
            </Box>)
    }

    const renderPPSignupPage = () => {
        return (
            <Box sx={{flexGrow:1,display:'flex',flexDirection:{xs:'column',md:'row'}}}>
            <Box sx={{minWidth:{xs:'0px',md:'600px'},backgroundImage:'url("assets/patternBlur.svg")',flexGrow:1,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                <Typography variant="h1"  sx={{ mb: '32px' ,mx:{xs:'16px',md:'32px'}, mt:{xs:'48px',md:'0px'}}}>Welcome Product Provider!</Typography>
                <Typography variant="h2" sx={{mb: '16px', mx:{xs:'16px',md:'32px'}}}>There are just a few steps to get started with your Intellectible journey</Typography>
                <Typography variant="h3" sx={{mx:{xs:'16px',md:'32px'},mb:{xs:'32px'}}}>Authenticate your account to continue the registration process</Typography>
                <Typography  sx={{mx:{xs:'16px',md:'32px'},mb:{xs:'32px',md:'0px'}}}>Not a Product Provider? Head to our <Link href='https://www.intellectible.com'>website</Link> to find out how you can best use Intellectible.</Typography>
            </Box>
            <Box sx={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",flexGrow:1,width:'100%',height:'100%', border:`1px solid ${Theme.palette.grey[300]}`}}>               
                <Typography variant="h3" sx={{mb:"24px",fontWeight:800}}>
                    {title}
                </Typography>

                <GoogleButton disabled={buttonsDisabled} onSignIn={handleGoogleSignin} />

                <Box sx={{display:"flex", alignItems:"flex-end", flexDirection:"row", width:'300px',my:"21px"}}>
                    <Box sx={{flexGrow:1,height:"50%",borderTop: `1px solid ${theme.palette.grey[600]}`}} />
                    <Typography sx={{px:"4px",color:theme.palette.grey[600]}} variant="body2">OR</Typography>
                    <Box sx={{flexGrow:1,height:"50%",borderTop: `1px solid ${theme.palette.grey[600]}`}} />
                </Box>
               
                <Typography variant="body2" align="left" sx={{width:'300px',color:theme.palette.text.secondary}}>Email</Typography>
                <FormControl>
                <Box sx={{mb:theme.spacing(1),height:"36px",width:"300px", position:"relative"}}>
                    {email && 
                    <ButtonBase onClick={()=>handleEmailInput("")} sx={{zIndex:"10",position:"absolute",right:"0",top:"25%",mr:"4px"}}>
                        <CancelOutlinedIcon sx={{color:"#808080",width:"16px",height:"16px","&:hover":{color:"#000"}}}/>
                    </ButtonBase>}
                    <InputBase 
                        value={textValue}
                        onChange={(e)=>handleEmailInput(e.target.value)}
                        placeholder="Enter your email address"
                        type="email"
                        autoComplete="email"
                        sx={{                       
                            borderStyle:"solid",
                            borderRadius:"5px",
                            height:"36px",
                            width:"300px",
                            p:"4px 10px",
                            borderColor:email? "#4BB543":"#808080",
                            borderWidth:"1px",
                            "&:hover": {
                                borderColor:email? '#3c9136':'rgb(0,0,0)'
                            },
                        }}
                    />               
                </Box>     
                {linkSent ?
                (<Typography variant="h6" align="center" sx={{display:"flex", flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
                    Email sent! <DoneIcon sx={{ml:"4px"}}/> 
                </Typography>) : (
                <ButtonBase 
                    onClick = {()=>{if(email) handleEmailLinkSignin(email);}}
                    disabled = {buttonsDisabled}
                    focusRipple
                    type="submit"
                    sx={{                       
                        backgroundColor:email ?"#c6e3c3":"#F5F5F5",
                        borderStyle:"solid",
                        borderRadius:"5px",
                        height:"36px",
                        width:"300px",
                        borderColor:email? "#4BB543":"#808080",
                        borderWidth:"1px",
                        "&:hover": {
                            borderColor:email? '#3c9136':'rgb(0,0,0)'
                        },
                        }}
                >
                    {buttonsDisabled ? 
                    (<CircularProgress sx={{color:"#808080"}} size={20}/>) :
                    (<Typography sx={{color:theme.palette.text.secondary}}>Continue with email link</Typography>)}
                </ButtonBase>)}  
                </FormControl>  
                {linkSent && 
                <Typography align="center" variant="body2" sx={{mt:theme.spacing(1),width:{xs:"300px",sm:'500px'},color:theme.palette.text.secondary}}>
                    A login link has been sent to the email address provided. Please check your email and click the link to log in!
                </Typography>}
                <Typography align="center" variant="body2" sx={{mt:"66px",width:{xs:"300px",sm:'500px'},color:theme.palette.text.secondary}}>
                    By clicking continue with Google/email you acknowledge that you have read and agreed to Intellectible's <Link href="#"color="inherit">Terms and Conditions</Link> and <Link href="#" color="inherit">Privacy Policy</Link>
                </Typography>  
            </Box>
            </Box>)
    }

    const renderRegularPage = () => {
        return (
            <Box sx={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",flexGrow:1}}>               
                <Typography variant="h3" sx={{mt:"32px",mb:"24px",fontWeight:800}}>
                    {title}
                </Typography>
                <GoogleButton disabled={buttonsDisabled} onSignIn={handleGoogleSignin} />

                <Box sx={{display:"flex", alignItems:"flex-end", flexDirection:"row", width:'300px',my:"21px"}}>
                    <Box sx={{flexGrow:1,height:"50%",borderTop: `1px solid ${theme.palette.grey[600]}`}} />
                    <Typography sx={{px:"4px",color:theme.palette.grey[600]}} variant="body2">OR</Typography>
                    <Box sx={{flexGrow:1,height:"50%",borderTop: `1px solid ${theme.palette.grey[600]}`}} />
                </Box>
               
                <Typography variant="body2" align="left" sx={{width:'300px',color:theme.palette.text.secondary}}>Email</Typography>
                <FormControl>
                <Box sx={{mb:theme.spacing(1),height:"36px",width:"300px", position:"relative"}}>
                    {email && 
                    <ButtonBase onClick={()=>handleEmailInput("")} sx={{zIndex:"10",position:"absolute",right:"0",top:"25%",mr:"4px"}}>
                        <CancelOutlinedIcon sx={{color:"#808080",width:"16px",height:"16px","&:hover":{color:"#000"}}}/>
                    </ButtonBase>}
                    <InputBase 
                        value={textValue}
                        onChange={(e)=>handleEmailInput(e.target.value)}
                        placeholder="Enter your email address"
                        type="email"
                        autoComplete="email"
                        sx={{                       
                            borderStyle:"solid",
                            borderRadius:"5px",
                            height:"36px",
                            width:"300px",
                            p:"4px 10px",
                            borderColor:email? "#4BB543":"#808080",
                            borderWidth:"1px",
                            "&:hover": {
                                borderColor:email? '#3c9136':'rgb(0,0,0)'
                            },
                        }}
                    />               
                </Box>     
                {linkSent ?
                (<Typography variant="h6" align="center" sx={{display:"flex", flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
                    Email sent! <DoneIcon sx={{ml:"4px"}}/> 
                </Typography>) : (
                <ButtonBase 
                    onClick = {()=>{if(email) handleEmailLinkSignin(email);}}
                    disabled = {buttonsDisabled}
                    focusRipple
                    type="submit"
                    sx={{                       
                        backgroundColor:email ?"#c6e3c3":"#F5F5F5",
                        borderStyle:"solid",
                        borderRadius:"5px",
                        height:"36px",
                        width:"300px",
                        borderColor:email? "#4BB543":"#808080",
                        borderWidth:"1px",
                        "&:hover": {
                            borderColor:email? '#3c9136':'rgb(0,0,0)'
                        },
                        }}
                >
                    {buttonsDisabled ? 
                    (<CircularProgress sx={{color:"#808080"}} size={20}/>) :
                    (<Typography sx={{color:theme.palette.text.secondary}}>Continue with email link</Typography>)}
                </ButtonBase>)}  
                </FormControl>  
                {linkSent && 
                <Typography align="center" variant="body2" sx={{mt:theme.spacing(1),width:{xs:"300px",sm:'500px'},color:theme.palette.text.secondary}}>
                    A login link has been sent to the email address provided. Please check your email and click the link to log in!
                </Typography>}
                <Typography align="center" variant="body2" sx={{mt:"66px",width:{xs:"300px",sm:'500px'},color:theme.palette.text.secondary}}>
                    By clicking continue with Google/email you acknowledge that you have read and agreed to Intellectible's <Link href="#"color="inherit">Terms and Conditions</Link> and <Link href="#" color="inherit">Privacy Policy</Link>
                </Typography>  
            </Box> 
        )
    }


    return (
    <ThemeProvider theme={theme}>        
        <Box sx={{width:'100%', height:'100%',display:"flex",flexDirection:"column",position:'relative'}}>
            <Box sx={{m:theme.spacing(2),display:"flex",flexDirection:"row",position:'absolute',top:{xs:'4px',md:'8px'},left:{xs:'4px',md:'8px'}}}>
                <Typography variant='h3' sx={{ color:theme.palette.primary.main}}>Intellectible</Typography>               
            </Box>

            {pageVariant === 'apply' ? (renderApplyPage()) :
            pageVariant === 'educatorSignup' ? (renderEducatorSignupPage()) : 
            pageVariant === 'productSignup' ? (renderPPSignupPage()) : 
            (renderRegularPage())}

        </Box>
        <ResponsiveDialog open={dialogOpen} onClose={()=>setDialogOpen(false)} title="error">
            <DialogTitle>An error occurred</DialogTitle>
            <DialogContent>{dialogMessage}</DialogContent>
        </ResponsiveDialog>

        <ResponsiveDialog open={popupBlockDialogOpen} onClose={()=>setPopupBlockDialogOpen(false)} title="error">
            <DialogTitle>Sign in with Google</DialogTitle>
            <DialogContent>Authenticate with your Google Account</DialogContent>
            <DialogActions><GoogleButton onSignIn={handleGoogleSignin} /></DialogActions>
        </ResponsiveDialog>

    </ThemeProvider>
    )
}

export default Login; 