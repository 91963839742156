import React, {useState, useEffect, useContext } from "react";

import { Box, Typography, MenuItem, Select, FormControl, ButtonBase, CircularProgress,  } from "@mui/material";

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import StyledTextField from '../StyledTextField'; 
import BorderPulsate from "../BorderPulsate";
import Theme from "../../Theme";


const WebsiteFormInput = ({value, title, onChange, smallPara, largePara, startAnimation, sx, readOnly, multiline, rows, descriptionText,startAdornment})=>{
        
    const handleChange = (val)=>{
        onChange(val); 
    }

    return(
    <React.Fragment>
        <Typography align='left' sx={{mt:'16px', mb:descriptionText?'0px':'16px', ml:'16px'}}>{title}</Typography>
        {descriptionText && <Typography variant='body2' sx={{mb:'16px', ml:'16px'}}>{descriptionText}</Typography>} 
        <BorderPulsate startAnimation={startAnimation} sx={{alignSelf:'stretch',mx:'16px',  display:'flex', flexDirection:'column'}}>
            <StyledTextField 
                InputProps={{
                    style:{marginLeft:'0px'},
                    //startAdornment:<Typography sx={{color:Theme.palette.grey[600]}} variant='body1'>https://</Typography>
                }}
                type='url'
                disabled={readOnly}
                value={value ? value : ''}
                onChange={(e)=>handleChange(e.target.value)}
                variant="outlined"
                sx={{ ml:0,flexGrow:1, ...sx }}
            />
        </BorderPulsate>
    </React.Fragment>
    )
}

export default WebsiteFormInput;