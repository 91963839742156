import React, {useState, useEffect, useContext } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";

import Theme from '../../Theme';
import AirbnbSlider from '../../components/AirbnbSlider'; 

import MainService from "../../MainService"; 
import MainContext from "../../MainContext"; 

import { ThemeProvider } from  '@mui/system';
import { Box, Typography, Tabs, Tab, ButtonBase, Link, CircularProgress, Avatar, Button } from "@mui/material";
import Slider, { SliderThumb, SliderValueLabelProps } from '@mui/material/Slider';

import DashboardIcon from '@mui/icons-material/Dashboard';
import ProfileAvatar from "../../components/ProfileAvatar";

import Paper from "../../components/Paper";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import StyledTableCell from "../../components/StyledTableCell";
import StyledTableRow from "../../components/StyledTableRow";

import EmailIcon from '@mui/icons-material/Email';
import BusinessIcon from '@mui/icons-material/Business';
import GradeIcon from '@mui/icons-material/Grade';
import SchoolIcon from '@mui/icons-material/School';

import ResponsiveDialog from "../../components/ResponsiveDialog";

import {teacherRoleNames, allUSSchoolGrades, allUSStates, getFormattedNumberString} from "../../Util"; 

import * as dayjs from 'dayjs'
import CondensedApplication from "./CondensedApplication";

const UserView = ({uid, onClose, openChatPopup})=>{

    const mainService = useContext(MainContext);

    const [userData, setUserData] = useState(null); 

    const userObjectListner = (data)=>{
        setUserData(data); 
    }

    const getTeacherRoleName = (role) =>{
        return teacherRoleNames.find((e)=>e.code==role)?.name; 
    }

    const getGradeName = (g) =>{
        return allUSSchoolGrades.find((e)=>e.code==g)?.name; 
    }

    const getStateName = (s) =>{
        return allUSStates.find((e)=>e.code==s)?.name; 
    }

    const getSubjectsList = (s)=>{
        let spl = s.split(',');
        let str = ''; 
        for(s of spl){
            str += s + ' ';
        } 
        return str; 
    }


    const handleMessageClick = async (currentUid)=>{
        console.log('Message user');

        //window.open(`/home?module=messages&action=new&contactid=${currentUid}`, '_self'); 
        openChatPopup()

        let contact = await mainService.messageService.getSingleContactData(currentUid); 

        mainService.trigger('createNewChatConvo', contact);
        
        onClose(); 
    }


    useEffect(()=>{
		mainService.ppDashboardService.addUserObjectListner(uid, userObjectListner); 
		return ()=>{
            mainService.ppDashboardService.removeUserObjectListner(uid, userObjectListner);
		}
	}, [uid]); 

    let itemIndent = '0px'; 

    return (
    <ThemeProvider theme={Theme}>
    <Box sx={{position:'absolute', width:'100%', height:'100%', display:'flex', overflow:'auto'}}>
    {userData ? (
        <Box sx={{ flexGrow:1, p:'24px', display:'flex', flexDirection:'column',}}>
            <Box sx={{display:'flex', }}>
                <ProfileAvatar  sx={{width:'64px', height:'64px'}} photoURL={userData?.photoURL ?? null} name={userData?.firstname ?? null}/>
                <Box sx={{display:'flex', flexDirection:'column', ml:'24px'}}>
                    <Typography variant='h3' sx={{mt:'6px'}} gutterBottom >{userData.firstname} {userData.lastname}</Typography>
                    <Typography variant='h5'>{userData.teacherrole && getTeacherRoleName(userData.teacherrole) ? getTeacherRoleName(userData.teacherrole) : 'Role not given...'}</Typography>
                </Box>
            </Box>

            {userData.teachergradelow && userData.teachergradehigh && 
            <Box sx={{display:'flex', alignItems:'center', mb:'6px', mt:'24px', ml:itemIndent}}>
                <GradeIcon color="disabled" sx={{width:'16px', height:'16px'}}/>
                <Typography variant='body' sx={{ml:'6px', fontWeight:800}}>Grades Taught:</Typography>
                <Typography variant='body' sx={{ml:'3px'}}>{getGradeName(userData.teachergradelow)} to {getGradeName(userData.teachergradehigh)}</Typography>
            </Box>
            }

            {userData.teachersubjects && 
            <Box sx={{display:'flex', alignItems:'center', mb:'6px', ml:itemIndent}}>
                <SchoolIcon color="disabled" sx={{width:'16px', height:'16px'}}/>
                <Typography variant='body' sx={{ml:'6px', fontWeight:800}}>Subjects Taught:</Typography>
                <Typography variant='body' sx={{ml:'3px'}}>{getSubjectsList(userData.teachersubjects)}</Typography>
            </Box>
            }


            {userData.email && 
            <Box sx={{display:'flex', alignItems:'center', mb:'6px', mt:'6px', ml:itemIndent}}>
                <EmailIcon color="disabled" sx={{width:'16px', height:'16px'}}/>
                <Typography variant='body' sx={{ml:'6px'}}>{userData.email}</Typography>
            </Box>
            }

            {userData.schoolname && 
            <Box sx={{display:'flex', alignItems:'center', mb:'6px', ml:itemIndent}}>
                <BusinessIcon color="disabled" sx={{width:'16px', height:'16px'}}/>
                <Typography variant='body' sx={{ml:'6px'}}>{userData.schoolname}</Typography>
            </Box>
            }
            {userData.schoolstreetaddress && <Typography variant='body' sx={{ml:'22px'}}>
                {userData.schoolstreetaddress}, {userData.schoolcity}, {getStateName(userData.schoolstate)}
            </Typography>}
            {userData.schoolzipcode && <Typography variant='body' sx={{ml:'22px', mb:'6px'}}>{userData.schoolzipcode}</Typography>}

            {/*
            <Button onClick={()=>handleMessageClick(uid)}>
                Send Message
            </Button>
            */}
        </Box>
    ):(
        <Box sx={{p:'24px', display:'flex', justifyContent:'center', alignItems:'center' }}>
            <CircularProgress/>
        </Box>
    )}
    </Box>
    </ThemeProvider>
    )
}

const fundingStages = ['not started','in progress','submitted', 'confirmed-won','funds received']

const TableVizPanel = ({data, fromDate, toDate, datesValid, productFilter, openChatPopup}) => {

    const mainService = useContext(MainContext);
    
    const [rows, setRows] = useState([]); 

    const [dlgOpen, setDlgOpen] = useState(false); 
    const [selectedAppId, setSelectedAppId] = useState('');
    const [selectedUid, setSelectedUid] = useState('');
    const [dlgMode, setDlgMode] = useState('user'); 

   

    const buildRows = (from, to)=>{

        const productFilterSet =  new Set(); 
        if(productFilter && Array.isArray(productFilter)){
            for (let pid of productFilter){
                productFilterSet.add(pid); 
            }
        }
        let djsFrom = dayjs(from); 
        let djsTo = dayjs(to).add(1, 'day'); 

        let myProducts = data; 
        let newRows = []; 
        if(!data){
            return newRows; 
        }
        for(let productId in myProducts){

            if(productFilter.length > 0 && !productFilterSet.has(productId))
                continue;

            let product = myProducts[productId]; 

            for(let appId in product){
                let appDashboardData = product[appId];
                if(!appDashboardData)
                    continue; 

                let djsCreated = dayjs(appDashboardData.createdDate);
                let inRange = djsCreated.isAfter(djsFrom) && djsCreated.isBefore(djsTo); 
                if(!inRange)
                    continue; 

                let row = {}; 
                row['appid'] = appId; 
                row['pid'] = appDashboardData.productId;
                row['appTitle'] = appDashboardData.projectTitle ? appDashboardData.projectTitle : 'No Title Yet';
                row['productName'] = appDashboardData.name; 
                row['createdDate'] = mainService.getDateString( appDashboardData.createdDate ).split(' ').slice(1).join(' '); 
                row['createdUnixDate'] = appDashboardData.createdDate; 
                row['productCount'] = appDashboardData.productCount; 
                row['fundingStage'] = appDashboardData.fundingStage ? fundingStages[appDashboardData.fundingStage] : fundingStages[0];
                row['totalValue'] = appDashboardData.productCount && appDashboardData.unitPrice ? (appDashboardData.productCount * appDashboardData.unitPrice) : 0;


                if(appDashboardData.ownerId){
                    let uid = Object.keys(appDashboardData.ownerId)[0];
                    let usrObj = appDashboardData.ownerId[uid];  
                    if(usrObj){
                        let firstname = appDashboardData.ownerId[uid].firstname; 
                        let lastname = appDashboardData.ownerId[uid].lastname; 
                        row['userName'] = firstname + ' ' + lastname; 
                        row['uid'] = uid; 
                        row['statecode'] = appDashboardData.ownerId[uid].schoolstate; 
                    }
                }

                newRows.push(row); 
            }
        }
        return newRows; 
    }

    //Effects
	useEffect(()=>{
		//on mount
        console.log('PP dashboard, TableVizPanel mounted'); 
		let rs = buildRows(fromDate, toDate);
        setRows(rs);  

	}, [data, toDate, fromDate, productFilter]);


    const handleClickUserName = (row)=>{
        setSelectedUid(row.uid); 
        setDlgOpen(true);
        setDlgMode('user'); 
    }

    const handleClickAppTitle = (row)=>{
        setSelectedAppId(row.appid); 
        setSelectedUid(row.uid);
        setDlgOpen(true);
        setDlgMode('app');
    }

    return (
    <ThemeProvider theme={Theme}>
        <Box sx={{position:'relative', flexGrow:1 }}>
        <Box sx={{position:'absolute', width:'100%', height:'100%', borderRadius:'8px'}}>
            <Paper sx={{ p:'8px', flexGrow:1, height:'100%'}}>
                <TableContainer sx={{maxHeight:'100%'}}>
                    <Table stickyHeader sx={{ minWidth: 700 }} >
                        
                        <TableHead>
                            <TableRow>
                                <StyledTableCell sx={{fontWeight:600}}>Applicant</StyledTableCell>
                                <StyledTableCell sx={{fontWeight:600}}>Application Title</StyledTableCell>
                                <StyledTableCell sx={{fontWeight:600}}>Product</StyledTableCell>
                                <StyledTableCell sx={{fontWeight:600}}>Stage</StyledTableCell>
                                <StyledTableCell sx={{fontWeight:600}}>Quantity</StyledTableCell>
                                <StyledTableCell sx={{fontWeight:600}}>Value</StyledTableCell>
                                <StyledTableCell sx={{fontWeight:600}} align="right">Created Date</StyledTableCell>
                            </TableRow>
                        </TableHead>
            
                        <TableBody >
                        {rows.map((row) => (
                            <TableRow
                                hover 
                                key={row.appid}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <StyledTableCell>
                                    <Typography sx={{color:Theme.palette.primary.main, '&:hover':{textDecoration:'underline', cursor:'pointer'} }} 
                                        onClick={()=>handleClickUserName(row)} variant="body">{row.userName}
                                    </Typography>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Typography sx={{color:Theme.palette.primary.main, '&:hover':{textDecoration:'underline', cursor:'pointer'} }} 
                                        onClick={()=>handleClickAppTitle(row)} variant="body">{row.appTitle}
                                    </Typography>
                                </StyledTableCell>
                                
                                <StyledTableCell>
                                    <Typography variant="body">{row.productName}</Typography>
                                </StyledTableCell>

                                <StyledTableCell>
                                    <Typography variant="body">{row.fundingStage}</Typography>
                                </StyledTableCell>
                                
                                <StyledTableCell>
                                    <Typography variant="body">{row.productCount}</Typography>
                                </StyledTableCell>

                                <StyledTableCell>
                                    <Typography variant="body">${row.totalValue ? getFormattedNumberString(row.totalValue) : 0}</Typography>
                                </StyledTableCell>

                                <StyledTableCell align="right">
                                    <Typography variant="body">{row.createdDate}</Typography>
                                </StyledTableCell>
                            </TableRow>
                        ))}
                        </TableBody>

                    </Table>
                </TableContainer>
            </Paper>
        </Box>
        </Box>

        <ResponsiveDialog open={dlgOpen} onClose={()=>setDlgOpen(false)} maxWidth={'lg'} sx={{overflow:'hidden'}}>
            
                {dlgMode == 'user' ? (
                <Box sx={{position:'relative', width:'500px', height:'400px', }}>
                    <UserView data={data} uid={selectedUid} onClose={()=>setDlgOpen(false)} openChatPopup={openChatPopup}/>
                </Box>
                ):(
                <Box sx={{position:'relative', width:'1000px', height:'900px', }}>
                    <CondensedApplication uid={selectedUid} appid={selectedAppId} />
                    {/*<DocumentViewer uid={selectedUid} appid={selectedAppId}/>*/}
                </Box>
                )}
            
        </ResponsiveDialog>

    </ThemeProvider>
    )
}

export default TableVizPanel; 