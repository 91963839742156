import React, {useState, useEffect, useContext } from "react";

import {Box,Typography, ButtonBase, IconButton, Radio} from '@mui/material';

import StyledTooltip from "../../components/StyledTooltip";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import AddCircleIcon from '@mui/icons-material/AddCircle';

import BoxSkeleton from "../../components/BoxSkeleton";

import Theme from "../../Theme";

const FundingCard = ({fundingId, onSetSelected, selected, fundingData,onDetailsClick}) => {

    //STATES
    const [loading,setLoading] = useState(true);

    //Effects

    //Render
    return(
        <Box sx={{
            flexGrow:1,
            flexBasis:0,
            position:'relative',
            display:'flex',
            height:'130px', 
            boxSizing:'content-box',
            borderStyle:selected?"solid":'solid', 
            borderRadius:"8px", 
            borderWidth:selected?'1px':"1px", 
            borderColor:selected?Theme.palette.primary.main : Theme.palette.grey[400],
            boxSizing:'border-box',
            p:selected?'0px':'1px',
            "&:hover": {borderColor: !selected && Theme.palette.primary.main}, 
            my:'8px',
            overflow:'hidden',
            bgcolor:selected?Theme.palette.primary.light:'white',
            boxShadow: "rgb(95 116 141 / 3%) 0px 2px 1px -1px, rgb(95 116 141 / 4%) 0px 1px 1px 0px, rgb(95 116 141 / 8%) 0px 1px 3px 0px", 
            
        }}>
        {!loading ? (
            <BoxSkeleton sx={{width:'100%', height:'100%'}}/>
        ):(

            <Box sx={{width:'100%', height:'100%', display:'flex',}}>
                {/*<ButtonBase 
                    sx={{position:'absolute', width:'100%', height:'100%', }}
                    onClick={()=>onSetSelected(!selected)}
                />*/}

                <Box sx={{display:'flex', flexGrow:1,}}>
                    
                    <Box sx={{
                        flexGrow:1, 
                        display:'flex', 
                        alignItems:'flex-start',
                        flexDirection:'column', 
                        bgcolor:selected?'rgba(187, 229, 213, 0.7)':'white',
                        pl:'16px', 
                        py:'16px',
                    }}>

                        <Typography noWrap gutterBottom variant='h5'sx={{maxWidth:'300px'}}>{fundingData.GrantName}</Typography>
                        <Typography gutterBottom variant='body'>{fundingData.AwardOrg}</Typography>

                        
                        {fundingData.AwardMax && 
                        <Typography gutterBottom variant='body2'> ${fundingData.AwardMin?fundingData.AwardMin.toLocaleString():0} to ${fundingData.AwardMax.toLocaleString()}</Typography>
                        }

                        <ButtonBase onClick={onDetailsClick} sx={{borderRadius:'5px'}}>
                            <Typography align='left' sx={{color:Theme.palette.primary.dark,textDecoration: 'underline'}}>Funding details</Typography>
                        </ButtonBase>
                        
                    </Box>

                    <Box sx={{width:'40px', display:'flex', alignItems:'center', justifyContent:'center'}}>
                        {/*<StyledTooltip arrow title={selected ? 'remove from my funding list' : 'add to my funding list'}>
                            <IconButton onClick={()=>onSetSelected(!selected)}>
                                {selected ? <CheckCircleIcon sx={{color:Theme.palette.primary.main}}/> : <AddCircleIcon /> }
                            </IconButton>
                        </StyledTooltip>*/}
                        <Radio checked={selected} onChange={(e)=>onSetSelected(fundingId,e)}/>
                    </Box>
                </Box>

                
                
            </Box>

        )}
        </Box>
    )
}

export default FundingCard; 