import  { Fragment, useState, useEffect} from "react";


import { 
        Box, 
        Typography, 
        TextField,
        FormControl, 
        InputLabel,
        Select,
        MenuItem,
        IconButton,
        Checkbox,
        Autocomplete,
        FormControlLabel
    } 
    from '@mui/material';

import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import theme from "../../Theme";

import { granteeEligibilityNames, projectEligibilityNames, allUSStates, statesCodeMap} from "../../Util";

const EligibilityCriteria = ({
    type,
    crit,
    critValue,
    critValues,
    canEdit,
    onCritChange,
    onCritValueChange,
    onCritRequiredChange,
    onAdd,
    onRemove,
    required}) => {

    //STATES
    const [loading,setLoading] = useState(false);
    const [autoCompleteOptions,setAutoCompleteOptions] = useState([]);
    const [locationString,setLocationString] = useState(",,");


    //HANDLERS
    const handleSetLocation = (val,inx) => {
        let locations = locationString.split(',')
        locations[inx] = val;
        let newLocationString = locations.join(',');
        console.log(newLocationString)
        setLocationString(newLocationString);
        onCritValueChange(newLocationString);
    }

    const handleSetCrit = (e) => {
        //set autocomplete options
        const options = type === 'project' ? projectEligibilityNames[e.target.value] : granteeEligibilityNames[e.target.value];
        setAutoCompleteOptions(options);
        onCritChange(e.target.value);
    }

    const handleSetCritValue = (val) =>{
        console.log(val)
        onCritValueChange(val);
    }

    const handleSetCritRequired = (e) => {
        onCritRequiredChange(e.target.checked)
    }

    const handleRemove = () => {
        onRemove(crit,critValue);
    }

    const handleDone = () => {
        onAdd();
        onRemove();
    }

    //LISTENERS


    //Effects
	useEffect(() => {
		//on mount
		console.log('Add el mounted'); 
		//on unmount
		return ()=>{
			console.log('Add el unmounted');
		}

	}, []);


    const renderCriteriaInput = () => {
        if(crit==='location'){
            return (
                <Box sx={{display:'flex',gap:'4px'}}>
                    <TextField
                        sx={{width:'150px'}}
                        value={critValue.split(',')[0]}
                        disabled={loading}
                        onChange={(e)=>{handleSetLocation(e.target.value,0)}} 
                        size="small" 
                        label="Enter city"
                        type="city"
                        id="city-val"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        sx={{width:'150px'}}
                        value={critValue.split(',')[1]}
                        disabled={loading}
                        onChange={(e)=>{handleSetLocation(e.target.value,1)}} 
                        size="small" 
                        label="Enter county"
                        type="text"
                        id="county-val"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Autocomplete
                        sx={{width:'150px'}}
                        disabled={loading}
                        
                        id="US-state-name"
                        options={allUSStates}
                        value= {statesCodeMap[critValue.split(',')[2]]}
                        onChange={(e,val)=>{handleSetLocation(val?.value,2)}}
                        getOptionLabel={(option) => option.label ? option.label : ''}

                        onInputChange={(e,val)=>{console.log(val)}}
                        renderInput={(params) => <TextField {...params} size='small' label="State" />}
                    />
                </Box>
            );
        }
        else if (crit === 'studentReachMax' || crit === 'studentReachMin') {
            return (
                <TextField
                    sx={{flexGrow:1}}
                    value={critValue}
                    disabled={loading}
                    onChange={(e)=>{handleSetCritValue(e.target.value)}} 
                    size="small" 
                    label="Enter number"
                    type="number"
                    id="number-val"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />);
        }
        else {
            return (
                <Autocomplete
                    sx={{flexGrow:1}}
                    disabled={loading}
                    freeSolo
                    id="criteria-name"
                    options={autoCompleteOptions}
                    inputValue={critValue}
                    value={critValue}
                    onInputChange={(e,val)=>{handleSetCritValue(val)}}
                    renderInput={(params) => <TextField {...params} size='small' label="Criteria name" />}
                />
            );
        }
    }

    return (
        <Box sx={{
                mx:"8px",
                display:"flex",
                flexDirection:"row",
                alignItems:"center",
                position:"relative",
                minWidth:"300px",
                gap:"16px",
                borderBottom:!canEdit && `solid 1px ${theme.palette.grey[400]}`,
                backgroundColor:required && "lightGreen"}}
        >
            {canEdit ? 
            (<FormControl sx={{width:"150px"}}>
                <InputLabel id="criteria">{`${type === "project" ? "project" : "grantee"} criteria`}</InputLabel>
                <Select 
                    labelId="criteria"  
                    label={`${type === "project" ? "project" : "grantee"} criteria`} 
                    value={crit} 
                    disabled={loading} 
                    onChange={(e)=>{handleSetCrit(e)}}  
                    id="select-criteria" 
                    size="small"
                >
                    {
                        critValues.map((c)=>{
                            return (<MenuItem  key={c} value={c}>{c}</MenuItem>);
                        })
                    }
                </Select>
            </FormControl>):(
                <Typography variant="subtitle2" sx={{width:"150px"}}>{crit}</Typography>
            )}
            {canEdit ? (renderCriteriaInput()) :(
                <Fragment>
                    <Typography variant="body2" sx={{flexGrow:1}}>{critValue}</Typography>
                    {required && (<Typography variant="body2" sx={{flexGrow:1}}>Required!</Typography>)}
                </Fragment>
            )}

            {canEdit &&
            ( <FormControlLabel control={<Checkbox onChange={handleSetCritRequired} inputProps = { {'aria-label': 'Checkbox'} }/>} label="Required?" />)
            }
            
            {canEdit && <IconButton onClick={handleDone} disabled={!critValue || !crit}><DoneIcon/></IconButton>}
            <IconButton onClick={handleRemove}><ClearIcon/></IconButton>
        </Box>       
    );
};

export default EligibilityCriteria;