import React, {useState, useEffect, useContext } from "react";

import Theme from '../../Theme';

import MainContext from "../../MainContext"; 

import { ThemeProvider } from  '@mui/system';
import { Box, Typography, ButtonBase, CircularProgress, Link, TextField, Button} from "@mui/material";

import useQuery from "../../useQuery";

import FormFlow from '../../components/FlowForm'; 

import { allUSSchoolGrades, allSubjectFoci, allStudentFoci, allProjectSettings, allProjectSkills, outcomeMeasureOptions, pastelColors } from "../../Util";

import SelectRangeFormInput from "../../components/formInputs/SelectRangeFormInput";
import SelectFormInput from "../../components/formInputs/SelectFormInput";
import TextFormInput from "../../components/formInputs/TextFormInput";
import Paper from "../../components/Paper";
import FormTitle from "../../components/formInputs/FormTitle";
import NumberFormInput from "../../components/formInputs/NumberFormInput";
import CheckBoxMultiSelectInput from "../../components/formInputs/CheckBoxMultiSelectInput";
import MonthPickerFormInput from "../../components/formInputs/MonthPickerFormInput";
import MultiTagAutoComplete from "../../components/formInputs/MultiTagAutoComplete";
import DurationFormInput from "../../components/formInputs/DurationFormInput";
import StyledSquareButton from "../../components/StyledSquareButton";
import StyledButton from "../../components/StyledButton";
import AddIcon from '@mui/icons-material/Add';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import CloseIcon from '@mui/icons-material/Close';




const Start = ({formData})=>{
    
    const mainService = useContext(MainContext);
    const [loading,setLoading] = useState(true);

    //EFFECTS 
    useEffect(() => {
        let timer = null;
        if(formData.appId){
            console.log('setting timer');
            timer =  
            setTimeout(() => {
                setLoading(false);
            }, "1000");
        }

        return (()=>{
            if(timer && formData.appId){
                console.log('clearing timer');
                clearTimeout(timer);
            }
        })

    }, [formData.appId]);

    return(
        <Box sx={{ height:'100%', flexGrow:1, display:'flex', alignItems:'stretch', flexDirection:'column',maxWidth:'600px'}}>
            {loading ? (
            <Box sx={{width:'100%',height:'100%',flexGrow:1,display:'flex',alignItems:'center',justifyContent:'center'}}>
                <CircularProgress/>
            </Box>) : (
            <>
            <Box sx={{height:'10%'}}></Box>
            <Typography gutterBottom align='center' variant="h2">Let's start building your funding application!</Typography>
            <Box sx={{height:'10%'}}></Box>
            <Box sx={{my:'16px', display:'flex', flexDirection:'column', alignItems:'center'}}>
                <Typography gutterBottom align='center' variant="h3">We need to find out a bit more about your project...</Typography>    
                <Typography align='center' variant="body1">This will take 5 to 10 minutes. Please try and fill out as much as you can. The more we know the more time we can save you!</Typography>                 
            </Box>
            </>)}

        </Box>
    )
}


const IdeaInput = ({formData,setDisableContinueButton})=>{
    
    const mainService = useContext(MainContext);

    let appId = formData.appId; 
    let applicationMetadata = formData.applicationMetadata ? formData.applicationMetadata : {}; 

    useEffect(()=>{
        const hasRequiredVals = applicationMetadata.eligibilitySubjectFocus && applicationMetadata.eligibilityProjectSkills && applicationMetadata.eligibilityProjectSetting;
        let disableButton = !(hasRequiredVals); 
        if(setDisableContinueButton)
            setDisableContinueButton(disableButton); 
    }, [formData]);

    return(
    <React.Fragment>
        <Box sx={{flexGrow:1, maxWidth:'510px', height:'100%', display:'flex', alignItems:'stretch', flexDirection:'column',}}>

            <Box sx={{height:'10%'}}></Box>

            <FormTitle text='Tell us a bit more about your project'/>
            <Box sx={{my:'16px', display:'flex', flexDirection:'column', alignItems:'stretch'}}>

                <Typography sx={{my:'16px', alignSelf:'stretch', mx:'16px',}}>What subjects/content area will you focus on?</Typography>

                <MultiTagAutoComplete 
                    sx={{my:'16px', mx:'16px'}}
                    value={applicationMetadata.eligibilitySubjectFocus}
                    title='Subjects'
                    onChange={(v)=>mainService.applicationService.setEligibilitySubjectFocus(appId, v.toString())}
                    labelName='name'
                    valueName='name'
                    options={allSubjectFoci}
                    freeSolo
                />

                <Typography sx={{my:'16px', alignSelf:'stretch', mx:'16px',}}>What skills will students develop in this project?</Typography>

                <MultiTagAutoComplete 
                    sx={{my:'16px', mx:'16px'}}
                    value={applicationMetadata.eligibilityProjectSkills}
                    title='Skills'
                    onChange={(v)=>mainService.applicationService.setEligibilityProjectSkills(appId, v.toString())}
                    labelName='name'
                    valueName='name'
                    freeSolo
                    options={allProjectSkills}
                />

                <SelectFormInput
                    title={'Where will this project take place?'}
                    value={applicationMetadata.eligibilityProjectSetting ? applicationMetadata.eligibilityProjectSetting : ""}
                    onChange = {(v)=>{ mainService.applicationService.setEligibilityProjectSetting(appId, v) } }
                    options={allProjectSettings}
                />

            </Box>

        </Box>
    </React.Fragment>
    )
}


const WhoInput = ({formData,setDisableContinueButton})=>{
    
    let query = useQuery();
    const mainService = useContext(MainContext);

    let appId = formData.appId; 
    let userObject = formData.userObject; 
    let applicationMetadata = formData.applicationMetadata ? formData.applicationMetadata : {}; 
    let productData = formData?.productData ? formData.productData : {};

    useEffect(()=>{
        const hasRequiredVals = applicationMetadata.eligibilityGradeMax && applicationMetadata.eligibilityGradeMin && 
            applicationMetadata.eligibilityStudentCount && (!isNaN(parseInt(applicationMetadata.eligibilityStudentCount)) && parseInt(applicationMetadata.eligibilityStudentCount) > 0);
        let disableButton = !(hasRequiredVals); 
        if(setDisableContinueButton)
            setDisableContinueButton(disableButton); 
    }, [formData]);

    const handleSetStudentCount = (v) => {
        mainService.applicationService.setEligibilityStudentCount(appId, v);
        if(productData?.basePricing?.quantPerStudent){
            console.log('quant per student: ' + productData?.basePricing?.quantPerStudent);
            const quantPerStudent = parseInt(productData.basePricing.quantPerStudent);
            const unitsPerStudent = !isNaN(quantPerStudent) ? Math.ceil(v/quantPerStudent) : '';
            const val = unitsPerStudent.toString();
            console.log('units per student' + val);
            mainService.applicationService.setEligibilityProductCount(appId,val);
        }
        else {
            console.log('no quant per student, setting to student count')
            mainService.applicationService.setEligibilityProductCount(appId,v);
        }
    }
    
    return(
    <React.Fragment>
        <Box sx={{flexGrow:1, maxWidth:'510px', height:'100%', display:'flex', alignItems:'stretch', flexDirection:'column',}}>

            <Box sx={{height:'10%'}}></Box>
            <FormTitle text='Who will this project support?'/>

            <Box sx={{my:'16px', display:'flex', flexDirection:'column', alignItems:'stretch'}}>
                
                <SelectRangeFormInput
                    title={'What grade level is this project focused on?'}
                    options={allUSSchoolGrades}
                    valueLow={applicationMetadata.eligibilityGradeMin}
                    onLowChange={(v)=>mainService.applicationService.setEligibilityGradeMin(appId, v)}
                    valueHigh={applicationMetadata.eligibilityGradeMax}
                    onHighChange={(v)=>mainService.applicationService.setEligibilityGradeMax(appId, v)}
                />

                <Typography sx={{my:'16px', alignSelf:'stretch', mx:'16px',}}>Are you planning to focus on any particular groups of students? (This can be left blank).</Typography>

                <MultiTagAutoComplete 
                    sx={{my:'16px', mx:'16px'}}
                    value={applicationMetadata.eligibilityStudentFocus}
                    title='Student focus'
                    onChange={(v)=>{ mainService.applicationService.setEligibilityStudentFocus(appId, v.toString()) } }
                    labelName='name'
                    valueName='name'
                    freeSolo
                    options={allStudentFoci}
                />

                <NumberFormInput
                    title={'How many students will be taking part in this project?'}
                    value={applicationMetadata.eligibilityStudentCount}
                    onChange={handleSetStudentCount}
                />

               

            </Box>

        </Box>
    </React.Fragment>
    )
}

const WhyInput = ({formData,continueFlow}) => {

    const mainService = useContext(MainContext);
    
    const [continueDisabled,setContinueDisabled] = useState(false)

    let appId = formData.appId; 
    let applicationMetadata = formData.applicationMetadata ? formData.applicationMetadata : {}; 
    let productData = formData.productData ?  formData.productData : {};
    let userObject =  formData.userObject ?  formData.userObject : {};

    const options = productData?.problemStatements ?
    productData.problemStatements :
    [
        "Low income students are underrepresented in STEM careers.",
        "Our current STEM learning materials are uninspiring and dated.",
        "Gender bias and outdated stereotypes pushes girls away from STEM.",
        "Women and low income students are underrepresented in the technology sector.",
    ];

    useEffect(()=>{
        const hasRequiredVals = applicationMetadata.eligibilityProblemStatement;
        let disableButton = !(hasRequiredVals); 
        setContinueDisabled(disableButton); 
    }, [formData]);

    const handleContinue = () => {
        if(!applicationMetadata.learningObjectivesGenerated)
            mainService.applicationService.getLearningObjectives(applicationMetadata,userObject,productData,appId);
        continueFlow();
    }

    return(
        <Box sx={{flexGrow:1, maxWidth:'510px', height:'100%', display:'flex', flexGrow:1, alignItems:'stretch', flexDirection:'column',}}>

            <Box sx={{height:'10%'}}></Box>
            <FormTitle text='Why is this project important?'/>

            <Box sx={{my:'16px', display:'flex', flexDirection:'column', alignItems:'stretch', gap:'8px'}}>
                
                <TextFormInput 
                    title={`Describe the problem you're addressing in one sentence. We've provided some options below for inspiration. Feel free to use or edit these to suit your needs. (max 100 characters)`}
                    value={applicationMetadata.eligibilityProblemStatement}
                    onChange={(v)=>mainService.applicationService.setEligibilityProblemStatement(appId, v)}
                    sx={{mb:'8px'}}
                    multiline
                />

                <Box sx={{mx:'16px',maxHeight:'300px',overflow:'auto',display:'flex',flexDirection:'column',gap:'8px',p:'8px',borderRadius:'8px', border:`1px solid ${Theme.palette.grey[400]}`}}>
                {options.map((option)=>{
                    return (<ButtonBase 
                                key={option}
                                sx={{px:'16px',
                                    borderRadius:'500px',
                                    bgcolor:Theme.palette.primary.light,
                                    
                                    '&:hover':{
                                        bgcolor:Theme.palette.primary.main,
                                    }}}
                                onClick={(v)=>mainService.applicationService.setEligibilityProblemStatement(appId, option)}
                            >
                                <Typography align='left' variant='body2' sx={{ml:'4px',p:'8px','&:hover':{color:'white'}}}>
                                    {option}
                                </Typography>
                            </ButtonBase>);
                })}               
                </Box>

            </Box>
            <Box sx={{flexGrow:1}}/>
            <StyledButton disabled={continueDisabled} text='Continue' sx={{mb:'64px', width:"175px", alignSelf:'center'}} onClick = {handleContinue}/>
        </Box>
    )
}

const LearningObjectivesInput = ({formData,setDisableContinueButton}) => {
    const mainService = useContext(MainContext);

    const [newObj,setNewObj] = useState('');

    let appId = formData.appId; 
    let applicationMetadata = formData.applicationMetadata ? formData.applicationMetadata : {}; 

    const handleUpdateObjectives = (update,i) => {
        let objsArr = applicationMetadata.learningObjectives.split(';');
        objsArr.splice(i,1,update);
        mainService.applicationService.setLearningObjectives(appId,objsArr.join(';'));
    }

    const handleAddObjective = () => {
        if(newObj){
            let objs = `${applicationMetadata.learningObjectives};${newObj}`;
            mainService.applicationService.setLearningObjectives(appId,objs);
        }
    }

    const handleRemoveObjective = (i) => {
        let objsArr = applicationMetadata.learningObjectives.split(';');
        objsArr.splice(i,1);
        mainService.applicationService.setLearningObjectives(appId,objsArr.join(';'));
    }

    useEffect(()=>{
        const hasRequiredVals = applicationMetadata.learningObjectives && applicationMetadata?.learningObjectivesGenerated;
        let disableButton = !(hasRequiredVals); 
        if(setDisableContinueButton)
            setDisableContinueButton(disableButton); 
    }, [formData]);

    return(
        <Box sx={{flexGrow:1, maxWidth:'510px', height:'100%', display:'flex', alignItems:'stretch', flexDirection:'column',}}>

            <Box sx={{height:'10%'}}></Box>
            <Typography gutterBottom variant='h3' align='left' sx={{mx:'16px'}}>What are your learning objectives for this project?</Typography>
            <Typography variant='body2' align='left' sx={{mb:'48px', mx:'16px'}}>We have generated a few ideas based on your project inputs. Please feel free to edit these, remove them or create new ones.</Typography>

            <Box sx={{my:'16px', display:'flex', flexDirection:'column', alignItems:'stretch'}}>
                <Box sx={{display:'flex',gap:'4px',alignItems:'center',mt:'16px', mb:'8px', mx:'16px',}}>
                    <TextField
                        placeholder='Add a project learning objective...'
                        sx={{flexGrow:1}}
                        
                        onChange={(e) => setNewObj(e.target.value)}
                    />
                    <StyledSquareButton onClick={handleAddObjective} sx={{width:'56px',height:'56px'}}>
                        <AddIcon/>
                    </StyledSquareButton>
                </Box>

                
                <Box sx={{display:'flex',flexDirection:'column',mt:'16px', mb:'8px', gap:'4px', mx:'16px',minHeight:'250px', maxHeight:'400px', overflow:'auto',p:'4px',border:`1px solid ${Theme.palette.grey[300]}`, borderRadius:'5px'}}>
                    {!applicationMetadata?.learningObjectives && !applicationMetadata?.learningObjectivesGenerated && (
                        <Box sx={{display:'flex', flexDirection:'column',flexGrow:1, alignItems:'center', justifyContent:'center'}}>
                            <Typography>Generating learning objectives</Typography>
                            <CircularProgress />
                        </Box>                      
                    )}
                    {applicationMetadata?.learningObjectives && 
                    applicationMetadata.learningObjectives.split(';').map((objective,i)=>(
                    <Box key={i} sx={{ p:'4px',display:'flex',flexDirection:'row',borderRadius:'8px',gap:'4px',bgcolor:`${pastelColors[i%pastelColors.length]}`,'&:hover':{filter: 'brightness(95%)'}}}>
                        <TextField 
                            fullWidth
                            placeholder="Add a learning objective or remove from list..."
                            variant="standard" 
                            value={`${i+1}. ${objective}`}
                            multiline
                            InputProps={{disableUnderline:true,sx:{...Theme.typography.body1,borderRadius:'8px'}}}
                            onChange={(e)=>handleUpdateObjectives(e.target.value,i)}
                        />
                        <ButtonBase  onClick={() => handleRemoveObjective(i)} sx={{borderRadius:'50px'}}>
                            <CloseIcon sx={{color: Theme.palette.grey[400]}}/>
                        </ButtonBase>
                    </Box>))}
                </Box>

                {/*<Button 
                    sx={{mt:'16px', mb:'8px', mx:'16px', mb:'32px',color:'white'}}
                    variant="contained"
                    startIcon={<SettingsSuggestIcon/>}
                    onClick={()=>{}}                        
                >
                    Generate a learning outcome!
                </Button>*/}

            </Box>

        </Box>
        )
}

const SuccessMeasureInput = ({formData,setDisableContinueButton})=>{

    const mainService = useContext(MainContext);

    let appId = formData.appId; 
    let applicationMetadata = formData.applicationMetadata ? formData.applicationMetadata : {}; 

    useEffect(()=>{
        const hasRequiredVals = applicationMetadata.eligibilityOutcomesMeasures;
        let disableButton = !(hasRequiredVals); 
        if(setDisableContinueButton)
            setDisableContinueButton(disableButton); 
    }, [formData]);

    const handleSetEligibilityOutcome = (o,v) => {
        let arr = applicationMetadata.eligibilityOutcomesMeasures ? applicationMetadata.eligibilityOutcomesMeasures.split(',') : [];
        console.log(arr);
        console.log(o);
        console.log(v);
        //if v true push to array?
        if(v && !arr.includes(o)){
            console.log('adding ' + o)
            arr.push(o);
        }
        //if v false splice from array
        else if (!v && arr.includes(o)){
            console.log('removing ' + o);
            arr.splice(arr.indexOf(o),1);
        }
        console.log(arr)
        let val = arr.toString();
        console.log(val);
        mainService.applicationService.setEligibilityOutcomeMeasure(appId,val);
    }

    return(

    <Box sx={{flexGrow:1, maxWidth:'510px', height:'100%', display:'flex', alignItems:'stretch', flexDirection:'column',}}>

        <Box sx={{height:'10%'}}></Box>
        <FormTitle text='How will you measure success?'/>

        <Box sx={{my:'16px', display:'flex', flexDirection:'column', alignItems:'stretch'}}>

            <CheckBoxMultiSelectInput 
                values={applicationMetadata.eligibilityOutcomesMeasures} 
                options={outcomeMeasureOptions}
                title={'How do you plan to report outcomes for your project?'} 
                descriptionText={'Select all that apply'}
                onChange={(o,v)=>handleSetEligibilityOutcome(o,v)}
            />

        </Box>

    </Box>

    )
}

const ExtraDetailsInput = ({formData,setDisableContinueButton})=>{
    
    const mainService = useContext(MainContext);

    let appId = formData.appId; 
    let applicationMetadata = formData.applicationMetadata ? formData.applicationMetadata : {}; 
    let productData = formData.productData ?? {};
    const studentPerUnit = productData?.basePricing?.quantPerStudent ?? '';
    const studentsPerUnitIsNumber = !isNaN(parseInt(studentPerUnit)) && parseInt(studentPerUnit) > 0;

    //eligibilityProductCount
    useEffect(()=>{
        const hasRequiredVals = (!isNaN(parseInt(applicationMetadata.eligibilityProductCount)) && parseInt(applicationMetadata.eligibilityProductCount) > 0) &&
        applicationMetadata.eligibilityProjectStart && applicationMetadata.eligibilityProjectLength;
        let disableButton = !(hasRequiredVals); 
        if(setDisableContinueButton)
            setDisableContinueButton(disableButton); 
    }, [formData]);

    /*const handleCheckBoxChange = (checked) => {
        if(checked && applicationMetadata.eligibilityStudentCount){
            mainService.applicationService.setEligibilityProductCount(appId, applicationMetadata.eligibilityStudentCount)
        }
    }*/

    return(
    <React.Fragment>
        <Box sx={{flexGrow:1, maxWidth:'510px', height:'100%', display:'flex', alignItems:'stretch', flexDirection:'column',}}>

            <Box sx={{height:'10%'}}></Box>
            <FormTitle text='Please provide a few extra details...'/>

            <Box sx={{my:'16px', display:'flex', flexDirection:'column', gap:'8px'}}>
                <Box sx={{display:'flex', flexDirection:'row', alignItems:'flex-end'}}>
                    <Box sx={{flexGrow:1}}>
                        <NumberFormInput
                            title={`How many units of ${applicationMetadata.name} do you need?`}
                            value={applicationMetadata.eligibilityProductCount}
                            onChange={(v)=>mainService.applicationService.setEligibilityProductCount(appId, v)}
                        />
                    </Box>
                    {studentsPerUnitIsNumber ? (
                        <Typography sx={{maxWidth:'150px'}} variant='body2'>This number is auto-calculated based on how much product your provider thinks you need.</Typography>
                    ) : (
                        <Typography sx={{maxWidth:'150px'}} variant='body2'>Double check your provider's <Link href={productData.websiteurl} target='_blank'>website</Link> to work out how many units per student are needed.</Typography>
                    )
                    }
                    
                    {/*<FormControlLabel  
                        sx={{mb:'16px'}}             
                        onChange={(e)=>handleCheckBoxChange(e.target.checked)} 
                        control={<Checkbox  />} 
                        label={<Typography variant='body2'>Same as students?</Typography>} 
                    />*/}
                </Box>

                <MonthPickerFormInput
                    title={'When are you hoping to start?'}
                    value={applicationMetadata.eligibilityProjectStart}
                    label='Pick a rough start date'
                    onChange={(v)=>mainService.applicationService.setEligibilityProjectStart(appId, v)}
                />
                
                <DurationFormInput
                    title='How long will the project last?'
                    value={applicationMetadata?.eligibilityProjectLength}
                    onChange={(v)=>mainService.applicationService.setEligibilityProjectLength(appId, v)}
                />

            </Box>

        </Box>
    </React.Fragment>
    )
}

const TitleInput = ({formData,setDisableContinueButton})=>{
    
    const mainService = useContext(MainContext);

    let appId = formData.appId; 
    let applicationMetadata = formData.applicationMetadata ? formData.applicationMetadata : {}; 
    let productData = formData.productData ?  formData.productData : {};

    const options = productData?.projectTitles ?
    productData.projectTitles :
    [
        "Bring Coding & Robotics to My K-5 Classroom",
        "Fund Our After-School or Summer Coding Programs",
        "Fund STEM Learning at the District Level",
        "Girls after school robotics club"
    ];

    useEffect(()=>{
        const hasRequiredVals = applicationMetadata.eligibilityTitle;
        let disableButton = !(hasRequiredVals); 
        if(setDisableContinueButton)
            setDisableContinueButton(disableButton); 
    }, [formData]);

    return(
    <React.Fragment>
        <Box sx={{flexGrow:1, maxWidth:'510px', height:'100%', display:'flex', alignItems:'stretch', flexDirection:'column',}}>

            <Box sx={{height:'10%'}}></Box>
            <FormTitle text='What shall we call your project?'/>

            <Box sx={{my:'16px', display:'flex', flexDirection:'column', alignItems:'stretch', gap:'8px'}}>

                <TextFormInput 
                    title={`It's time to give your project a title! Your product provider has provided a few example titles in case you need inspiration.`}
                    value={applicationMetadata.eligibilityTitle}
                    onChange={(v)=>mainService.applicationService.setEligibilityProjectTitle(appId, v)}
                    sx={{mb:'8px'}}
                    multiline
                />

                <Box sx={{px:'16px',maxHeight:'300px',overflow:'auto',display:'flex',flexDirection:'column',gap:'8px',p:'8px',borderRadius:'8px', border:`1px solid ${Theme.palette.grey[400]}`}}>
                {options.map((option)=>{
                    return (<ButtonBase 
                                key={option}
                                sx={{mx:'16px',
                                    borderRadius:'500px',
                                    bgcolor:Theme.palette.primary.light,
                                    
                                    '&:hover':{
                                        bgcolor:Theme.palette.primary.main,
                                    }}}
                                onClick={(v)=>mainService.applicationService.setEligibilityProjectTitle(appId, option)}
                            >
                                <Typography align='left' variant='body2' sx={{ml:'4px',p:'8px','&:hover':{color:'white'}}}>
                                    {option}
                                </Typography>
                            </ButtonBase>);
                })}               
                </Box>
            </Box>

        </Box>
    </React.Fragment>
    )
}

const ShortDescriptionInput = ({formData})=>{
    
    const mainService = useContext(MainContext);

    let appId = formData.appId;  
    let applicationMetadata = formData.applicationMetadata ? formData.applicationMetadata : {}; 

    return(
    <React.Fragment>
        <Box sx={{flexGrow:1, maxWidth:'510px', height:'100%',  display:'flex', alignItems:'stretch', flexDirection:'column',}}>

            <Box sx={{height:'10%'}}></Box>

            <FormTitle text='Anything else you want to tell us?'/>

            <Box sx={{my:'16px', display:'flex', flexDirection:'column', alignItems:'stretch'}}>
                
                <TextFormInput 
                    smallPara 
                    title='Please add any extra details about your project here that you think we should know.'
                    value={applicationMetadata.eligibilityShortDescription}
                    onChange={(v)=>mainService.applicationService.setEligibilityShortDescription(appId, v)}
                />

            </Box>

        </Box>
    </React.Fragment>
    )
}


const Finish = ({formData})=>{

    const mainService = useContext(MainContext);
   
    return(
    <React.Fragment>
        <Box sx={{ height:'100%', flexGrow:1, display:'flex', alignItems:'center', flexDirection:'column',maxWidth:'600px'}}>

            <Box sx={{height:'10%'}}></Box>

            <Typography gutterBottom align='center' variant="h3">All Done!</Typography>
            <Box sx={{my:'16px', display:'flex', flexDirection:'column', alignItems:'center'}}>
                <Typography align='center' variant="h4">{`Brilliant! That's all we need to start generating your application. Almost done now, click continue to view and update your project budget`}</Typography>                    
            </Box>

        </Box>
    </React.Fragment>
    )
}

const FundingEligibilityForm = ({userObject, applicationMetadata, appId, productData, loading, onFinish}) => {
    
    const mainService = useContext(MainContext);

    //RENDER 
    const formData = {
        
        startNode: 'startNode',

        userObject,

        applicationMetadata,

        productData,

        appId,

        nodes:{
            startNode:{
                component:Start,
                next:()=>'ideaNode',
            },
            ideaNode:{
                component:IdeaInput,
                next:()=>'whoNode',
            },
            whoNode:{
                component:WhoInput,
                next:()=>'whyNode',
            },
            whyNode:{
                component:WhyInput,
                hideContinueButton:true,
                next:()=>'learningOutcomesNode',
            },
            learningOutcomesNode:{
                component:LearningObjectivesInput,
                next:()=>'successMeasureNode'
            },
            successMeasureNode:{
                component:SuccessMeasureInput,
                next:()=>'extraDetailsNode',
            },
            extraDetailsNode:{
                component:ExtraDetailsInput,
                next:()=>'titleNode'
            },
            titleNode:{
                component:TitleInput,
                /*next:()=>'finishNode'*/
                end:true
            },
            /*shortDescriptionNode:{
                component:ShortDescriptionInput, 
                next:()=>'finishNode',
            },*/
            /*finishNode:{
                component:Finish,
                end:true,
            },*/
        }
    }

    return (
    <ThemeProvider theme={Theme}>
        <Paper sx={{flexGrow:1, overflowX:"hidden",position:'relative', display:'flex', flexDirection:'column', mb:'32px', mx:'32px', maxWidth:'1200px'}}>
            <FormFlow onFinish={onFinish} formData={formData}/>
        </Paper>
    </ThemeProvider>
    )
}

export default FundingEligibilityForm; 