import React, {useState, useEffect, useContext, useSyncExternalStore } from "react";

import Theme from '../Theme';

import { ThemeProvider} from  '@mui/system';
import { Box} from "@mui/material";

import fadein from "../AnimationKeyframes/FadeIn";



const PageForm = ({formData}) => {

    const SpacerBox=()=>(<Box sx={{height:'1px', my:'64px', borderBottom:`1px solid #bbe5d5`, }}/>);//#d3e6f3

    let startNodeId = formData.startNode;

    let nodesToRender = [startNodeId]; 

    let nodeId = startNodeId; 
    let node = formData.nodes[nodeId];
    while (node.next){
        nodeId = node.next(); 
        if(!nodeId)
            break;
        node = formData.nodes[nodeId];
        nodesToRender.push(nodeId); 
    }

    let renderNode = (nodeId)=>{

        let node = formData.nodes[nodeId];
        let NodeComponent = node.component;

        return (
        <React.Fragment>
        <Box key={nodeId} sx={{display:'flex', justifyContent:'center',}}>
            <Box sx={{flexGrow:1, display:'flex', maxWidth:'700px'}}>
                <NodeComponent formData={formData} />
            </Box>
        </Box>
        <SpacerBox/>
        </React.Fragment>
        )
    }

    
    return (
    <ThemeProvider theme={Theme}>
        
        <Box sx={{display:'flex', flexDirection:'column', animation: `${fadein} 0.5s linear 0s 1`,}}>
            <Box sx={{height:'1px', my:'32px'}}/>

            {nodesToRender.map((id)=>{
                return renderNode(id);
            })}  

            <Box sx={{height:'1px', my:'32px'}}/>
        </Box>

    </ThemeProvider>
    )
}

export default PageForm; 