import { styled } from '@mui/material/styles';
import { TableRow } from "@mui/material";

const StyledTableRow = styled(TableRow)(({}) => ({
    '&:nth-of-type(even)': {
        backgroundColor: '#fff',
        "&:hover":{
          filter:'brightness(0.90)'
        }
      },
    '&:nth-of-type(odd)': {
      backgroundColor: '#f6f6f8',
      "&:hover":{
        filter:'brightness(0.9)'
      }
    },
    //theme.palette.action.hover
    // hide last border
    //'&:last-child td, &:last-child th': {
      //border: 0,
    //},
}));

export default StyledTableRow;