import React, {useState, useContext, useRef, useCallback} from "react";

import { API_URL } from "../Config";

import MainContext from "../MainContext"; 

import theme from '../Theme';

import { ThemeProvider } from  '@mui/system';
import {Typography, InputBase, Icon, ButtonBase,CircularProgress,Box, TextField} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import { WorkQueue } from "../Util";

const workQueue = new WorkQueue(); 

const SearchBar = ({type,displayText,onResults,onClear,maxHits}) => {
    //use
    const mainService = useContext(MainContext);

    //STATES
    const [loading,setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState("");


    const handleClearInput = () => {
        setSearchValue("");
        if(onClear){
            onClear();
        }        
    }

    //handles getting search results depending on type whenever search input changes
    //those can be displayed however you like outside the search bar.
    //popover, cards,...etc
    const handleSearch = (e) => {
        e.preventDefault();
        workQueue.cancelAll();

        setLoading(true);

        //TODO trim value to a maxlength?
        const value = e.target.value;

        //state for being able to clear the input by controlling input value
        setSearchValue(value);
        
        if(!value){
            if(onClear){
                onClear();
            }     
            setLoading(false);
            return
        }
   
        workQueue.push(async (job) => {
            try{
                const token = await mainService.getUserToken();
                const res = await fetch(API_URL + `/find${type}?` + new URLSearchParams({
                    name: value,
                    lim: maxHits ?? 10
                }),{
                    headers: {
                      Authorization: `${token}`
                    }
                })
                const data = await res.json();
                if(data.ok){
                    setLoading(false);
                    console.log("success")
                    if(onResults && job.state != "cancelled"){
                        onResults(data.results);
                    }
                }
                else{
                    throw "request failed"
                }
            }
            catch(error){
                setLoading(false);
                console.error(error);
            }
        })

    }

    //focus the search bar input after the transition 
    //to avoid rerenderings
    /*const callbackRef = useCallback(inputEl => {
        console.log('use callback')
        let timer = null;
        if (inputEl) {
            timer = setTimeout(()=>{
                console.log('focussing el')
                inputEl.focus();
            },1000)
        }
        return ()=>{
            console.log('blurring el')
            if(timer){
                timer.clearTimeout();
            }
            if (inputEl) {
                inputEl.blur();
            }
		}
    }, []);*/

    return(
        <Box sx={{width:"100%",height:"100%", position:"relative"}} >
        
        <InputBase    
            inputProps={{ maxLength: 150}}
            value = {searchValue}
            onChange={handleSearch}
            placeholder={displayText ? `Search ${displayText} ...` : `Search...`}
            endAdornment={
                !loading && (searchValue ? 
                <ButtonBase onClick={handleClearInput} sx={{borderRadius:"50%"}}><ClearIcon sx={{color:theme.palette.grey[600]}}/></ButtonBase> : 
                <SearchIcon sx={{color:theme.palette.grey[600]}}/>)
            }
            sx={{                   
                borderStyle:"solid",
                borderRadius:"500px",
                width:"100%",height:"100%", 
                p:"8px 24px",
                borderColor:"#808080",
                borderWidth:"1px",
                "&:hover": {
                    backgroundColor:theme.palette.primary.light,
                    borderColor:theme.palette.primary.dark,
                    //color:"white"
                },
            }}
        />
        {loading && (<Box sx={{display:"flex",justifyContent:"center",position:"absolute",top:"50%",right:"24px",transform:"translateY(-50%)"}}><CircularProgress  size={24}/></Box>)}
        </Box>
    )
}

export default SearchBar;