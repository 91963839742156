import React, {useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useQuery from "../useQuery";


import theme from '../Theme';

import MainContext from "../MainContext"; 
import MainService from "../MainService";

import { ThemeProvider } from  '@mui/system';
import { Box, Button, ButtonBase, Link, Skeleton, Typography, IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import StyledButton from "../components/StyledButton";
import BoxSkeleton from "../components/BoxSkeleton";
import TopBar from "../components/TopBar";
import Paper from "../components/Paper";

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';


const Product = ({lid,ldata,previewMode,hideTopBar}) => {
    //Use
	const mainService = useContext(MainContext);
	const navigate = useNavigate(); 
    //let location = useLocation();

	const [authed, setAuthed] = useState(false)
	const [listingData,setListingData] = useState({});
	const [showErrorPage,setShowErrorPage] = useState(false);
	const [loading,setLoading] = useState(false);
	const [listingId,setListingId] = useState(null);
	const [collectionId,setCollectionId] = useState(null);
	const [imgLoaded,setImgLoaded] = useState(false);
	const [productImageOffset, setProductImageOffset] = useState(0); 
	const [userObj, setUserObj] = useState(null);

	let query =  useQuery();



	const handleAuthStateChanged = () => {
        const authState = mainService.getAuthedState() === 2 ? true : false;
        setAuthed(authState);
    }

	const handleNextProductImageClick = ()=>{
		let productImages = listingData && listingData.productImages && Object.entries(listingData.productImages).map((v)=>v[1]);
		if(productImages && productImageOffset < productImages.length-1)
			setProductImageOffset(productImageOffset + 1); 
	}

	const handlePrevProductImageClick = ()=>{
		if(productImageOffset > 0)
			setProductImageOffset(productImageOffset - 1); 
	}



	//LISTNERS
	let listenUserChanged = (userObj)=>{
		setUserObj(userObj); 
		console.log("listenUserChanged fired")
	}

	useEffect(() => {
		const authState = mainService.getAuthedState() === MainService.AUTHED ? true : false;
        setAuthed(authState);

		mainService.addAuthChangeCallback(handleAuthStateChanged); 

		//on unmount
		return ()=>{
			console.log(' unmounted');
			mainService.removeAuthChangeCallback(handleAuthStateChanged); 
		}
	}, []);

	useEffect(() => {

		if(query.has('collection')){
			const id = query.get("collection");
			setCollectionId(id);
		}

		if(query.has("listingid")){
			const id = query.get("listingid");
			setListingId(id);
			setLoading(true);
		}

		else if(lid && ldata && previewMode){
			setListingId(lid);
			setListingData(ldata); 
		}

		else{
			setShowErrorPage(true);
			console.log("no parameter in query or values in props")
		}

	}, [query]);


	const listenListingChanged= async (data)=>{
		if(!data){
			
			setShowErrorPage(true);
			return; 
		}       
		else {
			setShowErrorPage(false);
			setListingData(data); 
		}       
		setLoading(false);
	}

	useEffect(()=>{
		if(listingId && !previewMode){
			mainService.productListingService.addPublicListingListener(listingId, listenListingChanged);
		}
		return(()=>{
			if(listingId && !previewMode){
				mainService.productListingService.removePublicListingListener(listingId, listenListingChanged );
			}	
		})
	},[listingId])

	useEffect(()=>{
		if(authed)
        	mainService.addUserListner(listenUserChanged);

		return ()=> {
			if(authed)
				mainService.removeUserListner(listenUserChanged);
		}
	},[authed]);

	const renderErrorPage = () => {
		return (
			<Box sx={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", mt:"60px"}}>
				<Typography variant="h3" sx={{mb:theme.spacing(2)}}>
					Sorry we can't find the product you're looking for!
				</Typography>
				<Button variant="contained" sx={{color:"white"}} onClick={()=>{navigate("/products")}}>
					Browse all products
				</Button>
			</Box>
		)
	}

	let productImages = listingData && listingData.productImages && Object.entries(listingData.productImages).map((v)=>v[1]);

    return (
    <ThemeProvider theme={theme}>                               
		<Box sx={{position:'relative', width:'100%', height:'100%', display:'flex', flexDirection:'column'}}>
			{!hideTopBar && 
			<TopBar
				userObject={userObj}
				dashboard
                authed={authed}
            />
			}
			{showErrorPage ?
				renderErrorPage():(
				<React.Fragment>

                <Box sx={{display:'flex', flexGrow:1, justifyContent:'center',position:'relative'}}>
				{collectionId && 
				<IconButton 
					onClick={()=>{
						if(!previewMode)
							navigate(`/products?collection=${collectionId}`);
					}} 
					sx={{position:"absolute",top:"10px",left:"10px", zIndex:1,}}>
					<ArrowBackIcon/>
				</IconButton>}


				<Box sx={{position:'absolute', width:'100%', height:'100%', display:"flex",flexDirection:"column", alignItems:"center",overflowY:"overlay"}}>
					
                <Box sx={{width:'1200px', display:'flex', flexDirection:'column', backgroundColor:"white", flexGrow:1}}>

                    <Box sx={{maxWidth:'1200px', display:'flex'}}>	
                        <Box sx={{flexGrow:1, display:'flex',}}>
                            <Box sx={{flexGrow:1, display:'flex', ml:'8px', flexDirection:'column'}}>

                                <Typography gutterBottom variant='body' sx={{ fontSize:'50px', fontWeight:'600', mt:'48px', mb:'16px', maxWidth:'400px',}}>
									{loading ? <Skeleton /> : listingData.title && listingData.title}
								</Typography>



                                <Typography gutterBottom variant='body' sx={{maxWidth:'400px', mt:'16px', mb:'16px',}}>
									{loading ? <Skeleton /> : listingData.strapline && listingData.strapline}
								</Typography>
                                
								<Box sx={{display:'flex',alignItems:'flex-end'}}>
								<Box sx={{position:"relative", width:"53px", height:"53px", mt:'8px', mb:'8px', borderRadius:"8px",border: `4px solid white`}}>
									<Box sx={{width:"100%", height:"100%", backgroundColor:"white", borderRadius:"5px", border: `1px solid ${theme.palette.grey[600]}`, p:"2px"}}>
										<img style={{borderRadius:"3px",width:"100%",height:"100%",objectFit:"contain"}} 
											src={listingData.logoImage && listingData.logoImage.url}
										/>
									</Box>
								</Box>
								{ listingData?.providerName && 
									(<Typography gutterBottom variant='body2' sx={{ml:'4px', mt:'8px', mb:'10px'}} >
										{loading ? <Skeleton /> : `by ${listingData.providerName}`}
									</Typography>)}
								</Box>

								<StyledButton 
                                    onClick={()=>{
										if(!previewMode)
											window.open(`/home?module=applications&action=new&name=${encodeURIComponent(listingData.title)}&productid=${query.get("listingid")}&usertype=teacher`,'_blank');
									}} 
                                    variant="contained" 
                                    sx={{width:'180px', mt:'48px', mb:'48px', color:"white",}}
                                    text='Apply now!'
                                />

                            </Box>
                        </Box>
                        <Box sx={{position:'relative', flexGrow:1, minWidth:'700px', width:'700px', display:'flex', }}>
                            <Box sx={{flexGrow:1, position:'relative', overflow:'hidden'}}>
								<BoxSkeleton sx={{minWidth:"100%",minHeight:"100%",position:"absolute"}}/>
                                {listingData.featureImage  && 	
                                <img onLoad = {()=>{console.log("img loaded");setImgLoaded(true)}}
                                    style={{position:'absolute', width:"100%",height:"100%",objectFit:"cover"}} 
                                    src={listingData.featureImage.url}
                                />}
                            </Box>
                        </Box>
						
                    </Box>

					<Box sx={{position:'relative', maxWidth:'1200px',  display:'flex', }}>

						<Box sx={{flexGrow:1, position:'relative', display:'flex', flexDirection:'column', mt:'64px',}}>


							<Box sx={{position:'relative', height:'300px', display:'flex'}}>
								<Box sx={{position:'absolute', width:'100%', height:'100%', overflowX:'hidden'}}>
									<Box sx={{position:'absolute', height:'100%', display:'flex', transition:'transform 0.4s', transform:'translateX(-'+(productImageOffset*412)+'px)'}}>
									{productImages && productImages.map((im)=>(	
										<Box sx={{position:'relative', width:'400px', mr:'12px', mb:'12px', overflow:'hidden', borderRadius:'8px', 
												  boxShadow: "rgb(95 116 141 / 3%) 0px 2px 1px -1px, rgb(95 116 141 / 4%) 0px 1px 1px 0px, rgb(95 116 141 / 8%) 0px 1px 3px 0px", }}>
											<img style={{position:'absolute', width:"100%", height:"100%", objectFit:"cover"}} 
												src={im.url}
											/>
										</Box>
									))}
									</Box>
									
								</Box>
								{productImages && productImageOffset < productImages.length-1 &&
								<ButtonBase sx={{position:'absolute', right:0, top:'50%', width:'64px', height:'64px', transform:'translate(50%, -50%)', 
										backgroundColor:'#ffffff', border:'1px solid #808080;', borderRadius:'32px', display:'flex', alignItems:'center', justifyContent:'center'}}
										onClick={handleNextProductImageClick}>
									<NavigateNextIcon sx={{width:'32px', height:'32px'}}/>
								</ButtonBase>
								}
								{productImageOffset > 0 &&
								<ButtonBase sx={{position:'absolute', left:0, top:'50%', width:'64px', height:'64px', transform:'translate(-50%, -50%)', 
										backgroundColor:'#ffffff', border:'1px solid #808080;', borderRadius:'32px', display:'flex', alignItems:'center', justifyContent:'center'}}
										onClick={handlePrevProductImageClick}>
									<NavigateBeforeIcon sx={{width:'32px', height:'32px'}}/>
								</ButtonBase>
								}
							</Box>

							
							<Box sx={{mt:'32px', display:'flex', flexDirection:'column '}}>
								<Typography variant='body' sx={{ fontSize:'24px', fontWeight:'600', ml:'8px', mt:'48px', mb:'16px',}}>About this product</Typography>
								<Typography variant="body1" sx={{m:theme.spacing(1), mb:'64px'}}>
									{loading ? <Skeleton /> : listingData.description && listingData.description}
								</Typography>
							</Box>


						</Box>

						<Box sx={{position:'relative', minWidth:'250px', display:'flex', flexDirection:'column', ml:'72px', mt:'64px'}}>

							<Typography sx={{ fontSize:'24px', fontWeight:'600', mb:'16px',}}>Pricing</Typography>

							<Typography sx={{fontWeight:'600',}}>Unit price</Typography>
							<Typography sx={{mb:'8px'}}>${listingData && listingData.basePricing && listingData.basePricing.amount}</Typography>

							{listingData && listingData.basePricing && listingData.basePricing.maxQuant && 
							(<>
							<Typography sx={{fontWeight:'600',}}>Maximum quantity</Typography>
							<Typography sx={{mb:'8px'}}>{listingData.basePricing.maxQuant}</Typography>
							</>)}
							{listingData && listingData.basePricing && listingData.basePricing.minQuant && 
							(<>
							<Typography sx={{fontWeight:'600',}}>Minimum quantity</Typography>
							<Typography sx={{mb:'8px'}}>{listingData.basePricing.minQuant}</Typography>
							</>)}

							<Button onClick={()=>{
								if(!previewMode)
									window.open(`/home?module=applications&action=new&name=${encodeURIComponent(listingData.title)}&productid=${query.get("listingid")}`,'_blank')
							}}>
								Apply Now
							</Button>


							<Typography sx={{ fontSize:'24px', fontWeight:'600', mt:'48px', mb:'16px',}}>Provider Info</Typography>

							<Typography sx={{mb:'8px'}}>{listingData.providerName}</Typography>

							<Link href={listingData.websiteurl} target="_blank" sx={{fontWeight:'600', mb:'8px'}}>Website Link</Link>

							{listingData.providerTerms && <Link href={listingData.providerTerms} target="_blank" sx={{fontWeight:'600', mb:'8px'}}>Terms</Link>}
							
							{/*<Button >Contact Provider</Button>*/}

						</Box>

					</Box>

                </Box>
				</Box>

                </Box>
				</React.Fragment>
            )}
		</Box>
    </ThemeProvider>
    )
}

export default Product; 