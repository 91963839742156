import React, {useState, useEffect, useContext } from "react";

import MainContext from "../../MainContext"; 

import { Box, Typography} from "@mui/material";

import { allUSSchoolGrades, allTeacherSubjects, teacherRoleNames } from "../../Util";

import MultiTagAutoComplete from "../../components/formInputs/MultiTagAutoComplete";
import SelectFormInput from "../../components/formInputs/SelectFormInput";
import SelectRangeFormInput from "../../components/formInputs/SelectRangeFormInput";
import FormTitle from "../../components/formInputs/FormTitle";

const SchoolRoleInput = ({formData,setDisableContinueButton})=>{
    
    const mainService = useContext(MainContext);

    let userObject = formData.userObject;

    useEffect(()=>{
        let disableButton = !(userObject && userObject.teacherrole
            && userObject.teachergradehigh && userObject.teachergradelow); 
        if(setDisableContinueButton)
            setDisableContinueButton(disableButton); 
    }, [formData]);

    return(
    <React.Fragment>

        <Box sx={{flexGrow:1, maxWidth:'510px', height:'100%', display:'flex', alignItems:'stretch', flexDirection:'column',}}>
            <Box sx={{height:'10%'}}></Box>
            
            <FormTitle text='Tell us about your role within the school.'/>
            <Box sx={{display:'flex', alignItems:'left', flexDirection:'column' }}>

                <SelectFormInput 
                    title={'How would you describe your role in the school?'}
                    value={userObject.teacherrole}
                    onChange={(v)=>mainService.profileService.setTeacherRole(v)}
                    options={teacherRoleNames}
                />

                <Typography sx={{my:'16px', alignSelf:'stretch', mx:'16px',}}>Which subjects do you teach? (leave this blank if not applicable).</Typography>

                <MultiTagAutoComplete 
                    sx={{my:'16px', mx:'16px'}}
                    value={userObject.teachersubjects}
                    title='Your subjects'
                    onChange={(v)=>mainService.profileService.setTeacherSubjects(v.toString())}
                    labelName='name'
                    valueName='name'
                    freeSolo
                    options={allTeacherSubjects}
                />

                <SelectRangeFormInput
                    title={'What grades do you teach?'}
                    options={allUSSchoolGrades}
                    valueLow={userObject.teachergradelow}
                    onLowChange={(v)=>mainService.profileService.setTeacherGradeLow(v)}
                    valueHigh={userObject.teachergradehigh}
                    onHighChange={(v)=>mainService.profileService.setTeacherGradeHigh(v)}
                />
                
            </Box>

        </Box>
    </React.Fragment>
    )
}

export default SchoolRoleInput; 