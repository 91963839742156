

const allUSStates = [
    {name:'Alabama', code:'AL'},
    {name:'Alaska', code:'AK'},
    {name:'Arizona', code:'AZ'},
    {name:'Arkansas', code:'AR'},
    {name:'California', code:'CA'},
    {name:'Colorado', code:'CO'},
    {name:'Connecticut', code:'CT'},
    {name:'Delaware', code:'DE'},
    {name:'Florida', code:'FL'},
    {name:'Georgia', code:'GA'},
    {name:'Hawaii', code:'HI'},
    {name:'Idaho', code:'ID'},
    {name:'Illinois', code:'IL'},
    {name:'Indiana', code:'IN'},
    {name:'Iowa', code:'IA'},
    {name:'Kansas', code:'KS'},
    {name:'Kentucky', code:'KY'},
    {name:'Louisiana', code:'LA'},
    {name:'Maine', code:'ME'},
    {name:'Maryland', code:'MD'},
    {name:'Massachusetts', code:'MA'},
    {name:'Michigan', code:'MI'},
    {name:'Minnesota', code:'MN'},
    {name:'Mississippi', code:'MS'},
    {name:'Missouri', code:'MO'},
    {name:'Montana', code:'MT'},
    {name:'Nebraska', code:'NE'},
    {name:'Nevada', code:'NV'},
    {name:'New Hampshire', code:'NH'},
    {name:'New Jersey', code:'NJ'},
    {name:'New Mexico', code:'NM'},
    {name:'New York', code:'NY'},
    {name:'North Carolina', code:'NC'},
    {name:'North Dakota', code:'ND'},
    {name:'Ohio', code:'OH'},
    {name:'Oklahoma', code:'OK'},
    {name:'Oregon', code:'OR'},
    {name:'Pennsylvania', code:'PA'},
    {name:'Rhode Island', code:'RI'},
    {name:'South Carolina', code:'SC'},
    {name:'South Dakota', code:'SD'},
    {name:'Tennessee', code:'TN'},
    {name:'Texas', code:'TX'},
    {name:'Utah', code:'UT'},
    {name:'Vermont', code:'VT'},
    {name:'Virginia', code:'VA'},
    {name:'Washington', code:'WA'},
    {name:'West Virginia', code:'WV'},
    {name:'Wisconsin', code:'WI'},
    {name:'Wyoming', code:'WY'},

    {name:'District of Columbia', code:'DC', federalDistrict:true},

    {name:'American Samoa', code:'AS', territory:true},
    {name:'Guam', code:'GU', territory:true},
    {name:'Northern Mariana Islands', code:'MP', territory:true},
    {name:'Puerto Rico', code:'PR', territory:true},
    {name:'U.S. Virgin IslandsU.S. Virgin Islands', code:'VI', territory:true},
];

const allUSSchoolGrades = [
    {code:'pk', name:'Pre School'},
    {code:'kg', name:'Kinder Garten'},
    {code:'g1', name:'Grade 1'},
    {code:'g2', name:'Grade 2'},
    {code:'g3', name:'Grade 3'},
    {code:'g4', name:'Grade 4'},
    {code:'g5', name:'Grade 5'},
    {code:'g6', name:'Grade 6'},
    {code:'g7', name:'Grade 7'},
    {code:'g8', name:'Grade 8'},
    {code:'g9', name:'Grade 9'},
    {code:'g10', name:'Grade 10'},
    {code:'g11', name:'Grade 11'},
    {code:'g12', name:'Grade 12'},
    {code:'al', name:'Adult Learning'},
];

const allUSSchoolTypes = [
    {code:'traditional', name:'Traditional School'},
    {code:'magnet', name:'Magnet School'},
    {code:'charter', name:'Charter School'},
    {code:'private', name:'Private School'},
    {code:'religious', name:'Religious School'},
    {code:'special', name:'Special Education School'},
    {code:'other', name:'Other'},
];

const allUSSchoolIncomeDemos = [
    {code:'low', name:'Low'},
    {code:'midLow', name:'Mid-low'},
    {code:'midHigh', name:'Mid-high'},
    {code:'high', name:'High'},
    {code:'dk', name:"Don't know"},
];

//formula reference: https://nces.ed.gov/programs/coe/indicator/clb/free-or-reduced-price-lunch#fn3
//may change in future
const calculateSchoolIncomeDemo = (numStudents,numFL=0,numRPL=0) => {
    const percentageFRPL = (numFL + numRPL)/numStudents;

    let incomeDemo = 'low';
    if(percentageFRPL>0.75)
        incomeDemo = 'high';
    else if(percentageFRPL<= 0.75 && percentageFRPL>0.5)
        incomeDemo = 'midHigh';
    else if(percentageFRPL<= 0.5 && percentageFRPL>0.25)
        incomeDemo = 'midLow';  

    return incomeDemo;
}

const allUSSchoolTitle1Programs = [
    {code:'none', name:'None'},
    {code:'targeted', name:'Targeted assistance'},
    {code:'schoolwide', name:'School wide assistance'},
    {code:'dk', name:"Don't know"},
];

const teacherRoleNames = [
    {code:'elemSchoolTeacher',name:'Elementary school teacher'},
    {code:'midSchoolTeacher', name:'Middle school teacher'},
    {code:'highSchoolTeacher', name:'High school teacher'},
    {code:'specialEdTeacher', name:'Special education teacher'},
    {code:'eslTeacher',name:'ESL teacher'},
    {code:'teachingAide', name:'Teaching aide'},
    {code:'sportsCoach', name:'Sports coach'},
    {code:'counselor', name:'Counselor'},
    {code:'principle',name:'Principle'}
];

const outcomeMeasureOptions = [
    {code:'writtenReports',name:'written reports'},
    {code:'formAssessments',name:'formative assessments'},
    {code:'summAssessments',name:'summative assessments'},
    {code:'feedbackSurveys',name:'feedback surveys'}
];

/*const allUSSchoolLocales = [
    {code:'cityLarge', name:'City: Large'},
    {code:'cityMidsize', name:'City: Midsize'},
    {code:'citySmall', name:'City: Small'},
    {code:'ruralDistant', name:'Rural: Distant'},
    {code:'ruralFringe', name:'Rural: Fringe'},
    {code:'ruralRemote', name:'Rural: Remote'},
    {code:'suburbLarge', name:'Suburb: Large'},
    {code:'suburbMidsize', name:'Suburb: Midsize'},
    {code:'suburbSmall', name:'Suburb: Small'},
    {code:'townDistant', name:'Town: Distant'},
    {code:'townFringe', name:'Town: Fringe'},
    {code:'townRemote', name:'Town: Remote'},
];*/

const allUSSchoolLocales = [
    {code:'city', name:'City'},
    {code:'rural', name:'Rural'},
    {code:'suburb', name:'Suburb'},
    {code:'town', name:'Town'},
];

const orgMemberships = [
    {name:'NEA'},
];

const allTeacherSubjects = [
    {name:'Math'},
    {name:'Science'},
    {name:'English'},
    {name:'IT'},
    {name:'Geography'},
    {name:'Computer Science'},
    {name:'Design/Technology'},
    {name:'Spanish'},
    {name:'French'},
    {name:'Art'},
    {name:'Physical Education'},
    {name:'Drama'},
    {name:'Preschool'},
];

const allSubjectFoci = [
    {name:'Math'},
    {name:'Science'},
    {name:'Chemistry'},
    {name:'Geography'},
    {name:'English'},
    {name:'IT'},
    {name:'Computer Science'},
    {name:'Design/Technology'},
    {name:'Spanish'},
    {name:'French'},
    {name:'Art'},
    {name:'Physical Education'},
    {name:'Drama'},
    {name:'Preschool'},
    {name:'Media Studies'}
];

const allProjectFoci = [
    {name:'After School'},
    {name:'Manufacturing'},
    {name:'Aerospace'},
    {name:'Laboratory'},
    {name:'Chemistry'},
    {name:'Oil and gas'},
    {name:'Technology'},
    {name:'Digital'},
    {name:'Connectivity'},
    {name:'Programming'},
    {name:'Robotics'}
];

const allProjectSkills = [
    {name:'programming'},
    {name:'engineering'},
    {name:'problem solving'},
    {name:'critical thinking'},
    {name:'numeracy'},
    {name:'literacy'},
    {name:'spelling'},
    {name:'leadership'},
    {name:'team work'},
    {name:'digital literacy'},
    {name:'story telling'},
    {name:'public speaking'},
    {name:'robotics'},
    {name:'manufacturing'},
    {name:'electronics'},
    {name:'design thinking'},
    {name:'design'},
    {name:'creativity'},
    {name:'collaboration'}
];

const allProjectSettings = [
    {code:'in class', name:'in class'},
    {code: 'after school', name:'after school'}
];

const allProjectStyles = [
    {name:'innovative'}, 
    {name:'creative'}, 
    {name:'practical'},
    {name:'experimental'},
    {name:'novel'},
    {name:'awesome'},
    {name:'delightful'},
    {name:'problem-solving'}
];

const allStudentFoci = [
    {name:'Low income'},
    {name:'Minority'},
    {name:'Disabled'},
    {name:'Neurodiverse'},
    {name:'Female'},
    {name:'Marginalized'}
];

const grantRequirementOrderedSectionLabels = 
new Map()
.set('organization', 'Organization Information')
.set('contact', 'Contact Information')
.set('project', 'Project Details')
.set('funding', 'Funding and Budget')
.set('application', 'Application Extras')
.set('admin', 'Administrative Items')
.set('references', 'References');

const grantRequirementOrderedQuestionLabels = 
new Map()

.set('orgName', 'Name')
.set('orgLegalName', 'Legal Name')
.set('orgAddress1', 'Address 1')
.set('orgAddress2', 'Address 2')
.set('orgCity', 'City')
.set('orgState', 'State')
.set('orgZip', 'Zip')
.set('orgCountry', 'Country')
.set('orgPhone', 'Phone number')
.set('orgEmail', 'Email')
.set('orgWebsite', 'Website')
.set('schoolDistrictId', 'School District ID')
.set('orgMissionStatement', 'Mission statement')
.set('orgTaxStatus', 'Tax status')
.set('schoolDemographics', 'School demographics')
.set('orgSummary', 'Organization summary')
.set('schoolBrochure', 'School brochure')

.set('contactFirstName', 'First Name')
.set('contactLastName', 'Last Name')
.set('contactTitle', 'Title')
.set('contactSchoolEmail', 'School email')
.set('contactEmail', 'Personal email')
.set('contactAddress1', 'Address 1')
.set('contactAddress2', 'Address 2')
.set('contactPersonalTitle', 'Personal title (Mr,Ms..)')
.set('contactPhone', 'Phone number')
.set('contactBio', 'Contact bio')


const grantRequirementNames = {
    organization:[
        {value:'orgName',label:'Name'},
        {value:'orgLegalName',label:'Legal name'},
        {value:'orgPaymentName',label:'Payment name'},
        {value:'orgAddress1',label:'Address 1'},
        {value:'orgAddress2',label:'Address 2'},
        {value:'orgCity',label:'City'},
        {value:'orgState',label:'State'},
        {value:'orgZip',label:'ZIP'},
        {value:'orgCountry',label:'Country'},
        {value:'orgPhone',label:'Phone number'},
        {value:'orgEmail',label:'Email'},
        {value:'orgWebsite',label:'Website'},
        {value:'orgFacebook',label:'Org Facebook'},
        {value:'orgTwitter',label:'Org Twitter'},
        {value:'schoolDistrictId',label:"School District ID"},
        {value:'orgMissionStatement',label:"Mission statement"},
        {value:'orgTaxStatus',label:'Tax status'},
        {value:'orgTaxExemptNo',label:'Tax Exempt Number'},
        {value:'schoolDemographics',label:'School demographics'},
        {value:'orgSummary',label:'Organization summary'},
        {value:'schoolBrochure',label:'School brochure'},
        {value:'orgAddress',label:'Address'},
        {value:'percentageRPFLunch',label:'Percetage Free/Reduced Lunch'}

    ],
    contact:[
        {value:"contactFirstName",label:"First Name"},
        {value:"contactLastName",label:"Last Name"},
        {value:'contactTitle',label:'Title'},
        {value:'contactSchoolEmail',label:'School email'},
        {value:'contactEmail',label:'Personal email'},
        {value:'contactAddress1',label:'Address 1'},
        {value:'contactAddress2',label:'Address 2'},
        {value:'contactPersonalTitle',label:'Personal title (Mr,Ms..)'},
        {value:'contactPhone',label:'Phone number'},
        {value:'contactBio',label:'Contact bio'}
    ],
    project:[
        {value:'projectTitle',label:'Project title'},
        {value:'projectCategory',label:'Project category'},
        {value:'prjectGrades',label:'Project grades'},
        {value:'projectStart',label:'Project start date'},
        {value:'projectSummary',label:'Project summary'},
        {value:'projectMaterials',label:'Project materials'},
        {value:'totalStudents',label:'Total students'},
        {value:'projectImpact', label:'Impact Statement'},
        {value:'whoBenefits', label:'Who benefits'},
        {value:'donorRecognition', label:'Donor recognition'},
        {value:'projectKPIs',label:'Project KPIS'},
        {value:'SDG', label:'Sustainable Development Goals'},
        {value:'outputDescription',label:'Output description'},
        {value:'projectPlan',label:'Project plan'},
        {value:'projectWebsite',label:'Project website'},
        {value:'projectImage',label:'Project image'},
        {value:'projectTimeline',label:'Project timeline'},
        {value:'projectBrochure', label:'Project brochure'},
        
    ],
    funding:[
        {value:'totalCost',label:'Total project cost'},
        {value:'extraFunding', label:'Extra funding source'},
        {value:'projectBudget',label:'Project budget'},
        {value:'budgetPlan',label:'Budget plan'}
    ],
    application:[
        {value:'coverLetter',label:'Cover letter'},
    ],
    references:[
        {value:'academicReference',label:'Academic reference'},
    ],
    admin:[
        {value:'applicantEsig',label:'Applicant E-signature'},
        {value:'principalEsig',label:'Principal E-signature'},
        {value:'date',label:'Date'},
        {value:'declaration',label:'Declaration'},
        {value:'taxStatusProof',label:'Proof of tax status'}
    ],

};

//unit options for budget creation
const unitOptions = [
    {name:'unit'},
    {name:'hour'},
    {name:'user'},
    {name:'day'},
    {name:'month'},
    {name:'year'},
    {name:'week'},
    {name:'license'},
    {name:'pack'},
    {name:'bundle'},
    {name:'course'},
    {name:'kit'},
    {name:'shipment'}
];


//given a list of submission ranges return the nearest deadline as a date object
const getNextDeadline = (ranges) => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentDay = today.getDate();
    const currentYear = today.getFullYear();

    let endDates = [];
    for (const r of ranges) {
        if(r['end']){
            let split = r['end'].split('-')
            let d = parseInt(split[1]);
            let m = parseInt(split[0])/*-1*/;
            let y = currentYear;
            //if this year's window has passed increase year
            if(m<currentMonth || (m===currentMonth && d < currentDay)){
                y++;
            }

            //push new date
            endDates.push(new Date(y,m,d))
        }
    }

    endDates.sort((a, b) => {
        return Math.abs(today - a) - Math.abs(today - b); // sort a before b when the distance is smaller
    });
    const futureDates = endDates.filter((d)=> d - today > 0)
    return futureDates[0]
}

const getNextDateOccurrence = (monthDayString) => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentDay = today.getDate();

    let arr = monthDayString.split('-');
    let m = arr[0];
    let d = arr[1];
    let y = today.getFullYear();
    if(m<currentMonth || (m===currentMonth && d < currentDay)){
        y++;
    }
    return(new Date(y,m,d));
}

//absolute days between two dates not inclusive of end date
const daysBetweenTwoDates = (date1,date2) => {
    const daysd1 = date1.getTime() / (1000 * 3600 * 24);
    const daysd2 = date2.getTime() / (1000 * 3600 * 24);
    return Math.ceil(Math.abs((daysd1-daysd2)));
}

let isValidZip = (zip)=>{
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip);
}

//NOTE need to localise this for countries that use different delimiters
const getFormattedNumberString = (num) => {
    if(typeof num == 'undefined' || typeof num == 'null')
        return '';
    //split out decimals
    let decimalSplit = num.toString().split('.');
    let whole = decimalSplit[0];
    let decimal = decimalSplit.length > 1 ? decimalSplit[1] : null;
    let numArr = whole.split('');
    if(numArr.length>3){
        const startLength = numArr.length-3;
        for(let i = startLength;i>0;i-=3){
            numArr.splice(i,0,',');
        }
    }
    let str = numArr.join('');
    if(decimal)
        str += '.' + decimal;

    return str;
}

const getCurrencyAdornment = (currencyString) => {
    if(currencyString === '£' || currencyString === 'GBP'){
        return '£';
    }
    else if(currencyString === '€' || currencyString === 'EUR'){
        return '€';
    }
    else {
        return '$';
    }
}

class WorkQueue {
    
    constructor(){
        this.p = Promise.resolve();
        this.jobs = new Set();
    }

    push(callback){
        let job = {state:"waiting", callback:callback};
        this.jobs.add(job);

        this.p = this.p
        .then(()=>{
            if(job.state==="waiting"){
                job.state="running";
                return callback(job);
            }           
        })
        .then(()=>{
            this.jobs.delete(job);
        })
    }

    cancelAll(){
        this.jobs.forEach((job)=>{
            job.state="cancelled"
        })
    }
}

function getEmbeddedYouTube(url){
    let hostnameValid = url.hostname == "youtube.com" || url.hostname == "www.youtube.com"; 
    let params = url.searchParams;
    let id = params.get('v');
    if(hostnameValid && id){
        let eurl = 'https://www.youtube.com/embed/' + id;
        return {valid: true, url: eurl, type: "YouTube"};
    }
    return {valid: false};
}

function getEmbeddedVimeo(url){
    let hostnameValid = url.hostname == "vimeo.com" || url.hostname == "www.vimeo.com"; 
    let id = url.pathname;
    if(hostnameValid && id){
        let eurl = 'https://player.vimeo.com/video' + id;
        return {valid: true, url: eurl, type: "Vimeo"};
    }
    return {valid: false};
}

function getEmbeddedLink(link){


    let url; 

    try{
        url = new URL(link); 
    }
    catch(err){
        console.log("invalid URL " + link + " err " + err); 
        return {valid: false};
    }

    let ret;
    
    ret = getEmbeddedYouTube(url); 
    if(ret.valid)
        return ret; 
    
    ret = getEmbeddedVimeo(url); 
    if(ret.valid)
        return ret; 

    return {valid:false};
}

const urlRe = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?!&//=]*)/gi;

function beautifyString(str, capAtStart=true, removeNewlines=true){
    str = str.trim(); 
    str = str.toLowerCase(); 
    if(removeNewlines){
        str = str.replace('\n', ''); 
        str = str.replace('\r', ''); 
    }
    if(capAtStart && str.length > 0){ 
        str = str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str;
}

const formatBytesToString = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const toDateString = (timestamp) => {
    return (timestamp ? new Date(timestamp).toLocaleDateString() : '')
}

const sleep = async (ms) => {
    return new Promise(resolve => setTimeout(resolve,ms));
}
/*#ffeee6 orange
#f5f0fb purple
#edfcf4 green
#f2fafd blue
#fffde2 yellow*/
const pastelColors = ['#ffeee6','#f5f0fb','#edfcf4','#f2fafd','#fffde2'];


//Intellectibase old - may delete
const projectEligibilityNames = {
    "gradeMin":[
        'pk',
        'kg',
        'g1',
        'g2',
        'g3',
        'g4',
        'g5',
        'g6',
        'g7',
        'g8',
        'g9',
        'g10',
        'g11',
        'g12', 
        'al',
    ],
    "gradeMax":[
        'pk',
        'kg',
        'g1',
        'g2',
        'g3',
        'g4',
        'g5',
        'g6',
        'g7',
        'g8',
        'g9',
        'g10',
        'g11',
        'g12', 
        'al',
    ],
    "studentFocus":[
        'lowIncome',
        'midLowIncome',
        'POC',
        'disability',
        'ND',
        'female'
    ],
    "subjectFocus":[
        'Math',
        'Science',
        'Chemistry',
        'Geography',
        'English',
        'IT',
        'Computer Science',
        'DesignTechnology',
        'Spanish',
        'French',
        'Art',
        'Physical Education',
        'Drama',
        'Preschool'
    ],
    "projectStyle":[
        'innovative', 
        'creative', 
        'practical',
        'experimental',
        'novel',
        'awesome',
        'delightful',
        'problem-solving'
    ],
    "projectFocus":[
        'afterSchool',
        'manufacturing',
        'aerospace',
        'laboratory',
        'chemistry',
        'oilAndGas',
        'technology',
        'digital',
        'connectivity',
        'programming',
        'robotics'
    ],
    "studentReachMax":[],
    "studentReachMin":[]
};

const granteeEligibilityNames = {
    "location":[],
    "demographic":[
        'low',
        'midLow',
        'midHigh',
        'high',
    ],
    "schoolType":[
        'traditional',
        'magnet',
        'charter',
        'private',
        'religious',
        'special',
        'other'
    ],
    "locale":[
        'city',
        'rural', 
        'suburb', 
        'town', 
    ],
    "membership":[
        'NEA',
        'AIAAEducatorAssociate'
    ],
    "funding":[
        'willMatch'
    ],
};

const statesCodeMap = {
    AL:'Alabama',
    AK:'Alaska',
    AZ:'Arizona',
    AR:'Arkansas',
    CA:'California',
    CO:'Colorado',
    CT:'Connecticut',
    DE:'Delaware',
    FL:'Florida',
    GA:'Georgia',
    HI:'Hawaii',
    ID:'Idaho',
    IL:'Illinois',
    IN:'Indiana',
    IA:'Iowa',
    KS:'Kansas',
    KY:'Kentucky',
    LA:'Louisiana',
    ME:'Maine',
    MD:'Maryland',
    MA:'Massachussetts',
    MI:'Michigan',
    MN:'Minnesota',
    MS:'Mississippi',
    MO:'Missouri',
    MT:'Montana',
    NE:'Nebraska',
    NV:'Nevada',
    NH:'New Hampshire',
    NJ:'New Jersey',
    NM:'New Mexico',
    NY:'New York',
    NC:'North Carolina',
    ND:'North Dakota',
    OH:'Ohio',
    OK:'Oklahoma',
    OR:'Oregon',
    PA:'Pennsylvania',
    RI:'Rhode Island',
    SC:'South Carolina',
    SD:'South Dakota',
    TN:'Tennessee',
    TX:'Texas',
    UT:'Utah',
    VT:'Vermont',
    VA:'Virginia',
    WA:'Washington',
    WV:'West Virginia',
    WI:'Wisconsin',
    WY:'Wyoming',
    DC:'District of Columbia',
    AS:'American Samoa',
    GU:'Guam',
    MP:'Northern Mariana Islands',
    PR:'Puerto Rico',
    VI:'U.S. Virgin IslandsU.S. Virgin Islands'
}

const roles = [
    'Investor',
    'Advisor',
    'VC',
    'Analyst',
    'Graduate',
    'Enthusiast',
    'Manager',
    'Grant writer',
    'Sales manager',
    'Engineer',
    'Editor',
    'Student',
    'Teacher',
    'Lecturer',
    "Academic Librarian",
    "Accountant",
    "Accounting Technician",
    "Actuary",
    "Adult Nurse",
    "Advertising Account executive",
    "Advertising Account planner",
    "Advertising Copywriter",
    "Advice Worker",
    "Aeronautical Engineer",
    "Agricultural Consultant",
    "Agricultural Manager",
    "Aid Worker/Humanitarian Worker",
    "Air Traffic Controller",
    "Airline Cabin Crew",
    "Amenity Horticulturist",
    "Analytical Chemist",
    "Animal Nutritionist",
    "Animator",
    "Archaeologist",
    "Architect",
    "Architectural Technologist",
    "Archivist",
    "Armed Forces Officer",
    "AromaTherapist",
    "Art Therapist",
    "Arts Administrator",
    "Auditor",
    "Automotive Engineer",
    "Barrister",
    "Barrister's Clerk",
    "Bid Manager",
    "Bilingual Secretary",
    "Biomedical Engineer",
    "Biomedical Scientist",
    "Biotechnologist",
    "Border Force Officer",
    "Brand Manager",
    "Broadcasting Presenter",
    "Building Control Officer/Surveyor",
    "Building Services Engineer",
    "Building Surveyor",
    "Business Analyst",
    "Camera Operator",
    "Careers Adviser (Higher Education)",
    "Careers Adviser",
    "Careers Consultant",
    "Cartographer",
    "Catering Manager",
    "Charities Administrator",
    "Charities Fundraiser",
    "Chemical (Process) Engineer",
    "Child PsychoTherapist",
    "Children's Nurse",
    "Chiropractor",
    "Civil Engineer",
    "Civil Service Administrator",
    "Clinical Biochemist",
    "Clinical Cytogeneticist",
    "Clinical Microbiologist",
    "Clinical Molecular Geneticist",
    "Clinical Research Associate",
    "Clinical Scientist",
    "Clothing Technologist",
    "Colour Technologist",
    "Commercial Airline Pilot",
    "Commercial Horticulturist",
    "Commercial/Residential Surveyor",
    "Commissioning Editor",
    "Commissioning Engineer",
    "Commodity Broker",
    "Communications Engineer",
    "Community Arts Worker",
    "Community Education Officer",
    "Community Worker",
    "Company Secretary",
    "Computer Sales Support",
    "Computer Scientist",
    "Conference Organiser",
    "Consultant",
    "Consumer Rights Adviser",
    "Control and Instrumentation Engineer",
    "Corporate Banker",
    "Corporate Treasurer",
    "Counsellor",
    "Court Reporter/Verbatim Reporter",
    "Credit Analyst",
    "Crown Prosecution Service lawyer",
    "Crystallographer",
    "Curator",
    "Customs Officer",
    "Cyber Security Specialist",
    "Dance Movement PsychoTherapist",
    "Data Analyst",
    "Data Scientist",
    "Data Visualisation Analyst",
    "Database Administrator",
    "Debt/Dinance Adviser",
    "Dental Hygienist",
    "Dentist",
    "Design Engineer",
    "Design Manager (Construction)",
    "DevOps Engineer",
    "Dietitian",
    "Diplomatic Service",
    "Doctor (GP)",
    "Doctor (Hospital)",
    "DramaTherapist",
    "Economist",
    "Editorial Assistant",
    "Education Administrator",
    "Electrical Engineer",
    "Electronics Engineer",
    "Employment Advice Worker",
    "Energy Conservation Officer",
    "Energy Consultant",
    "Engineering Geologist",
    "Environmental Education Officer",
    "Environmental Health Officer",
    "Environmental Manager",
    "Environmental Scientist",
    "Equal Opportunities Officer",
    "Equality and Diversity Officer",
    "Ergonomist",
    "Estate Agent",
    "Estimator",
    "European Commission Administrators",
    "Exhibition Display Designer",
    "Exhibition Organiser",
    "Exploration Geologist",
    "Facilities Manager",
    "Field Trials Officer",
    "Financial Manager",
    "Fire Engineer",
    "Firefighter",
    "Fisheries Enforcement Officer",
    "Fitness Centre Manager",
    "Food Scientist",
    "Food Technologist",
    "Forensic Scientist",
    "Freight Forwarder",
    "Geneticist",
    "Geographical Information Systems Manager",
    "Geomatics/Land Surveyor",
    "Government Lawyer",
    "Government Research Officer",
    "Graphic Designer",
    "Health and Safety Adviser",
    "Health and Safety Inspector",
    "Health Promotion Specialist",
    "Health Service Manager",
    "Health Visitor",
    "Herbalist",
    "Heritage Manager",
    "Higher Education Administrator",
    "Higher Education Advice Worker",
    "Homeless Support Worker",
    "Horticultural Consultant",
    "Hotel Manager",
    "Housing Adviser",
    "Human Resources Officer",
    "Hydrologist",
    "Illustrator",
    "Immigration Officer",
    "Immunologist",
    "Industrial/Product Designer",
    "Information Scientist",
    "Information Systems Manager",
    "Information Technology/Software Trainers",
    "Insurance Broker",
    "Insurance Claims Inspector",
    "Insurance Risk Surveyor",
    "Insurance Underwriter",
    "Interpreter",
    "Investment Analyst",
    "Investment Banker - Corporate Finance",
    "Investment Banker - Operations",
    "Investment FUnd Manager",
    "IT Consultant",
    "IT Support Analyst",
    "Journalist",
    "Laboratory Technician",
    "Land-based Engineer",
    "Landscape Architect",
    "Learning Disability Nurse",
    "Learning Mentor",
    "Lecturer (Adult Education)",
    "Lecturer (Further Education)",
    "Lecturer (Higher Education)",
    "Legal Executive",
    "Leisure Centre Manager",
    "Licensed Conveyancer",
    "Local Government administrator",
    "Local Government lawyer",
    "Logistics/Distribution Manager",
    "Magazine Features Editor",
    "Magazine Journalist",
    "Maintenance Engineer",
    "Management accountant",
    "Manufacturing Engineer",
    "Manufacturing Machine Operator",
    "Manufacturing Toolmaker",
    "Marine Scientist",
    "Market Research Analyst",
    "Market Research Executive",
    "Marketing Assistant",
    "Marketing Executive",
    "Marketing Manager (Direct)",
    "Marketing Manager (Social Media)",
    "Materials Engineer",
    "Materials Specialist",
    "Mechanical Engineer",
    "Media Analyst",
    "Media Buyer",
    "Media Planner",
    "Medical Physicist",
    "Medical Representative",
    "Mental Health Nurse",
    "Metallurgist",
    "Meteorologist",
    "Microbiologist",
    "Midwife",
    "Mining Engineer",
    "Mobile Developer",
    "Multimedia Programmer",
    "Multimedia Specialists",
    "Museum Education Officer",
    "Museum/Gallery Exhibition Officer",
    "Music Therapist",
    "Nanoscientist",
    "Nature Conservation Officer",
    "Naval Architect",
    "Network Administrator",
    "Nurse",
    "Nutritional Therapist",
    "Nutritionist",
    "Occupational Therapist",
    "Oceanographer",
    "Office Manager",
    "Operational Researcher",
    "Orthoptist",
    "Outdoor Pursuits Manager",
    "Packaging Technologist",
    "Paramedic",
    "Patent Attorney",
    "Patent Examiner",
    "Pension Scheme Manager",
    "Personal Assistant",
    "Petroleum Engineer",
    "Pharmacist",
    "Pharmacologist",
    "Pharmacovigilance Officer",
    "Photographer",
    "PhysioTherapist",
    "Picture Researcher",
    "Planning and Development Surveyor",
    "Planning Technician",
    "Plant Breeder",
    "Police Officer",
    "Political Party Agent",
    "Political Researcher",
    "Practice nurse",
    "Press Photographer",
    "Press Sub-editor",
    "Prison Officer",
    "Private Music Teacher",
    "Probation Officer",
    "Product Development Scientist",
    "Production Manager",
    "Programme Researcher",
    "Project Manager",
    "Psychologist (Clinical)",
    "Psychologist (Educational)",
    "PsychoTherapist",
    "Public Affairs Consultant (Lobbyist)",
    "Public Affairs Consultant (Research)",
    "Public House Manager",
    "Public Librarian",
    "Public Relations (PR) Officer",
    "QA Analyst",
    "Quality Assurance Manager",
    "Quantity Surveyor",
    "Records Manager",
    "Recruitment Consultant",
    "Recycling Officer",
    "Regulatory Affairs Officer",
    "Research Chemist",
    "Research Scientist",
    "Restaurant Manager",
    "Retail Banker",
    "Retail Buyer",
    "Retail Manager",
    "Retail Merchandiser",
    "Retail Pharmacist",
    "Sales Executive",
    "Scene of Crime Officer",
    "Secretary",
    "Seismic Interpreter",
    "Site Engineer",
    "Site Manager",
    "Social Researcher",
    "Social Worker",
    "Software Developer",
    "Software Engineer",
    "Soil Scientist",
    "Solicitor",
    "Speech and Language Therapist",
    "Sports Coach",
    "Sports Development Officer",
    "Sports Therapist",
    "Statistician",
    "Stockbroker",
    "Structural Engineer",
    "Systems Analyst",
    "Systems Developer",
    "Tax Inspector",
    "Teacher (Nursery Years)",
    "Teacher (Primary)",
    "Teacher (Secondary)",
    "Teacher (Special Educational Needs)",
    "Teaching/Classroom Assistant",
    "Technical Author",
    "Technical Sales Engineer",
    "TEFL/TESL Teacher",
    "Television Production Assistant",
    "Test Automation Developer",
    "Tour Guide",
    "Tour Operator",
    "Tour/Holiday Representative",
    "Tourism Officer",
    "Tourist Information Manager",
    "Town and Country Planner",
    "Toxicologist",
    "Trade Union Official",
    "Trade Union Research Officer",
    "Trader",
    "Trading Standards Officer",
    "Training and Development Officer",
    "Translator",
    "Transportation Planner",
    "Travel Agent",
    "TV/Film/Theatre Set Designer",
    "UX Designer",
    "Validation Engineer",
    "Veterinary Nurse",
    "Veterinary Surgeon",
    "Video Game Designer",
    "Video Game Developer",
    "Volunteer Work Organiser",
    "Waste Management Officer",
    "Water Conservation Officer",
    "Water Engineer",
    "Web Designer",
    "Web Developer",
    "Welfare Rights Adviser",
    "Writer",
    "Youth Worker",
    "Founder",
    "Start-up Founder",
    "Executive",
    "Researcher",
    "Scientist",
    "Academic",
    "Civil Servant",
    "Pilot",
    "Hobbyist",
    "Individual",
    "Lawyer"
];

const outputs = [
    'Business plan',
    'Investor pitch deck',
    'Vision statement',
    'Strategic plan',
    'Annual report',
    'Technical roadmap',
    'System architecture documentation',
    'Code documentation',
    'Operations manual',
    'Standard operating procedures',
    'Wordflow diagrams',
    'Marketing plan',
    'Campaign reports',
    'Market research documents',
    'Product roadmap',
    'Feature specification',
    'User story',
    'User guide',
    'Technical specification',
    'Bug report',
    'Sales proposal',
    'Sales report',
    'Customer contract',
    'Custmer support FAQs',
    'Customer feedback report',
    'Employee handbook',
    'HR Policy',
    'Project plan',
    'Partnership proposal',
    'Business development plan',
    'Grant application',
    'RFP',
    'Investment memorandum',
    'Report',
    'Meeting notes',
    'Agreement',
    'Statement',
    'Letter',
    'Email',
    'Pitch Deck',
    'Executive Summary',
    'Academic Paper',
    'Article',
    'Abstract',
    'Presentation',
    'Conference Paper',
    'Journal Article',
    'Feasibility Study',
    'Paper',
    'Essay',
    'Notes',
    'Lecture Notes',
    'Thesis',
    'Review',
    'Synopsis',
    'Speech',
    'Manuscript',
    'Novel',
    'Short Story',
    'Script',
    'Documentation',
    'Software Documentation',
    'Job Description',
    'Letter of Support',
    'Newsletter',
    'Marketing Material',
    'Marketing Email',
    'Book',
    'Textbook',
    'Poem',
    'Screen play',
    'Play',
    'Linkedin Bio',
    'Bio',
    'Linkedin post',
    'Tweet',
    'Social Media Post' ,
    'Professional bio',
    'Linkedin job summary',
    'Shareholder Letter',
    'Summary',
    'Description',
    'CV',
    'Contract',
    'Press Release'
]

const internalLists = {
    docTypes:outputs,
    roleTypes:roles,
}



export {
    allUSStates, 
    allUSSchoolGrades, 
    allUSSchoolTypes, 
    allUSSchoolIncomeDemos, 
    calculateSchoolIncomeDemo,
    allUSSchoolTitle1Programs, 
    allUSSchoolLocales, 
    allTeacherSubjects, 
    outcomeMeasureOptions,
    teacherRoleNames,
    allSubjectFoci, 
    allProjectFoci,
    allProjectSkills,
    allProjectSettings,
    allProjectStyles,
    allStudentFoci,
    grantRequirementOrderedSectionLabels,
    grantRequirementOrderedQuestionLabels,
    grantRequirementNames,
    getNextDeadline,
    getNextDateOccurrence,
    daysBetweenTwoDates,
    isValidZip,
    WorkQueue, 
    getEmbeddedYouTube,
    getEmbeddedVimeo,
    getEmbeddedLink,
    urlRe,
    unitOptions,
    getFormattedNumberString,
    getCurrencyAdornment, 
    beautifyString,
    formatBytesToString,
    sleep,
    pastelColors,
    toDateString,
    projectEligibilityNames,
    granteeEligibilityNames,
    statesCodeMap,
    roles,
    outputs,
    internalLists,
}

