import * as React from 'react';
import {ButtonBase} from '@mui/material'
import { alpha, styled } from '@mui/material/styles';
import Theme from '../Theme';
import AddIcon from '@mui/icons-material/Add';


const StyledSquareButton = styled(ButtonBase)(({ Theme }) => ({
  minWidth:'36px',
  minHeight:'36px',
  borderRadius:'24%',
  border :'1px solid #d3e6f3',
  '&:hover, &.Mui-focusVisible':{
      backgroundColor:'rgba(18, 31, 67, 0.04)'
  }
}));

export default StyledSquareButton;