import React, {useState, useEffect, useContext } from "react";

import { Typography,  } from "@mui/material";

const FormTitle = ({text, sx})=>{
    return(
        <Typography variant='h3' align='left' sx={{mb:'48px', mx:'16px'}}>{text}</Typography>
    )
}

export default FormTitle;

